import React from 'react'
import Card from 'components/Card'
import PaginatedList from 'components/PaginatedList'
import { ITableData, ITableMeta, IMainTableFilters } from 'components/Table'
import DownloadIcon from 'images/icons/download.svg'
import runOfShowEmptyStateImg from 'images/run_of_show_empty_state.png'
import EmptyState from 'components/EmptyState'
import RunOfShowList from './RunOfShowList'

interface RunOfShowProps {
  tableData: ITableData
  tableMeta: ITableMeta
  selectedFilters: IMainTableFilters
  timezone: string
  editUrl: string
}

/**
 * Card on activity overview page to view activity logs for event
 * Activities including edit, published, reminder sent...etc
 */
export default function RunOfShow({
  tableData,
  tableMeta,
  selectedFilters,
  timezone,
  editUrl,
}: RunOfShowProps): JSX.Element {
  const { rows } = tableData
  const { url } = tableMeta
  return (
    <Card
      title="Run of show"
      actionNode={
        !!rows?.length ? (
          <a href={url.replace('.json', '.csv')} className="link">
            <DownloadIcon />
            Export.csv
          </a>
        ) : undefined
      }>
      {!!rows?.length ? (
        <PaginatedList
          tableData={tableData}
          tableMeta={tableMeta}
          selectedFilters={selectedFilters}>
          <RunOfShowList timezone={timezone} />
        </PaginatedList>
      ) : (
        <EmptyState
          imgUrl={runOfShowEmptyStateImg}
          actionNode={
            <a
              href={editUrl + '#show-run-of-show-button'}
              className="button secondary small plus">
              add run of show
            </a>
          }>
          <p>Highlight the event&apos;s agenda by adding a run of show</p>
        </EmptyState>
      )}
    </Card>
  )
}
