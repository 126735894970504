import Input from 'components/Input'
import { TReportType } from 'types'

interface IReportsPageProps {
  children: JSX.Element
  reportTypes: TReportType[]
  onTypeChange?: (inputData: Record<string, unknown>) => void
  selectedType: string
}

export default function ReportsPage({
  children,
  reportTypes,
  onTypeChange = () => {},
  selectedType,
}: IReportsPageProps): JSX.Element {
  return (
    <div className="reports-index">
      <div className="type-block">
        <h3>Report Type</h3>
        <Input
          name="type"
          type="select"
          options={reportTypes}
          value={selectedType}
          onChange={onTypeChange}
        />
      </div>
      {children}
    </div>
  )
}
