import { Fragment, useState, useCallback, useEffect } from 'react'
import usePublishLater from './usePublishLater'
import Modal, { Size } from 'components/Modal'
import InfoText from 'components/InfoText'
import TrashIcon from 'images/icons/trash.svg'
import SchedulePublishDialogContent from './SchedulePublishDialogContent'
import { colors } from 'stylesheets/theme'

interface PublishLaterButtonProps {
  validateUrl: string
  errors: Record<string, string[]>
  initialPublishingDate: string | null
  initialPublishingTime: string | null
  initialTimezone: string | null
  isCall?: boolean
}

/**
 * "Publish Later" button that validates the page as if user is publishing now
 * If validation passes, then a dialog will appear to configure schedule for publishing
 * If validation fails, the page rerenders with errors
 */
export default function PublishLaterButton({
  validateUrl,
  errors,
  initialPublishingDate,
  initialPublishingTime,
  initialTimezone,
  isCall = false,
}: PublishLaterButtonProps): JSX.Element {
  const {
    objectType,
    timezone,
    initializeFormData,
    schedulePublishErrors,
    validateFormData,
    submitFormToValidate,
    existingSchedulePublish,
    formData,
    mindrForm,
  } = usePublishLater(
    initialPublishingDate,
    initialPublishingTime,
    errors,
    isCall,
  )

  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.href)
    const schedule = queryParameters.get('schedule')

    // Async function in useEffect https://devtrium.com/posts/async-functions-useeffect
    async function doValidateFormAndOPenSchedulePublishModal() {
      await validateFormAndOpenSchedulePublishModal()
    }

    if (!!schedule) {
      doValidateFormAndOPenSchedulePublishModal()
    }
  }, [window])

  /**
   * Remove "schedule_publish" hidden field on the form
   * Remove value attribute for "formatted_publishing_date" and "formatted_publishing_time" fields on form
   * Submit the form when removing schedule publish
   */
  function removeSchedulePublish() {
    const schedulePublishEls = document.getElementsByName(
      `${objectType}[schedule_publish]`,
    )
    const publishingDateEls = document.getElementsByName(
      `${objectType}[formatted_publishing_date]`,
    )
    const publishingTimeEls = document.getElementsByName(
      `${objectType}[formatted_publishing_time]`,
    )

    if (!!schedulePublishEls.length) {
      schedulePublishEls.forEach((el) => el.remove())
    }

    if (!!publishingDateEls.length) {
      publishingDateEls.forEach((el) => el.removeAttribute('value'))
    }

    if (!!publishingTimeEls.length) {
      publishingTimeEls.forEach((el) => el.removeAttribute('value'))
    }

    const forms = window.document.getElementsByClassName('mindr-form')
    const form = !!forms.length ? (forms[0] as HTMLFormElement) : undefined

    if (form) {
      form.requestSubmit()
    }
  }

  /**
   * Ideally we should be initializingFormData at form loads,
   * but because we do not use React to load form,
   * we have to do it here
   */
  useEffect(() => {
    window['tinymce']?.triggerSave()
    initializeFormData()
  }, [window['tinymce']])

  useEffect(() => {
    if (!!Object.keys(schedulePublishErrors).length) {
      setModalOpen(true)
    }
  }, [schedulePublishErrors])

  const validateFormAndOpenSchedulePublishModal = useCallback(async () => {
    window['tinymce']?.triggerSave() //RTE only save to form at onSubmit, manually saving content to form so that form validates correctly without submitting
    const { newForm, newFormData } = initializeFormData()

    const { data } = await validateFormData(validateUrl, newFormData)

    const messages = data.data.message

    /**
     * If there are errors, submit the page to validation endpoint as if we are publishing
     * This allows the page to rerender with errors
     */
    if (!!messages.length) {
      submitFormToValidate(validateUrl, newFormData, newForm, false)
    } else {
      setModalOpen(true)
    }
  }, [setModalOpen])

  /**
   * Executes when "Publish Later" button is clicked
   */
  const handleSchedulePublish = useCallback(
    async (e) => {
      e.preventDefault()
      await validateFormAndOpenSchedulePublishModal()
    },
    [validateFormAndOpenSchedulePublishModal],
  )

  /**
   * Executes when "Schedule Publish" button in schedule event publishing modal is clicked
   */
  const handleModalSubmit = useCallback(async () => {
    window['tinymce'].triggerSave() //RTE only save to form at onSubmit, manually saving content to form so that form validates correctly without submitting
    const { newForm, newFormData } = initializeFormData()
    const schedulePublishForm = window.document.getElementById(
      'schedule_publish_form',
    ) as HTMLFormElement
    const schedulePublishFormData = new FormData(schedulePublishForm)

    schedulePublishFormData.forEach((value, key) => {
      newFormData.set(key, value)
    })

    const { data } = await validateFormData(validateUrl, newFormData, true)

    const messages = data.data.message

    if (!!messages.length) {
      submitFormToValidate(validateUrl, newFormData, newForm, true)
    } else {
      submitFormToValidate(validateUrl, newFormData, newForm, true, true)
    }

    setModalOpen(false)
  }, [formData, mindrForm])

  return (
    <Fragment>
      <button
        className="button secondary submit-form-button"
        type="button"
        onClick={handleSchedulePublish}>
        {existingSchedulePublish ? 'Edit Schedule' : 'Schedule Publish'}
      </button>
      <Modal
        isOpen={modalOpen}
        title="Schedule Publish"
        submitButton={
          existingSchedulePublish ? 'Update and save' : 'Schedule Publish'
        }
        cancelButton={!existingSchedulePublish && 'cancel'}
        secondaryActionButton={
          existingSchedulePublish && (
            <InfoText
              button
              onClick={removeSchedulePublish}
              icon={
                <TrashIcon
                  css={{
                    '& path': {
                      stroke: colors.blue,
                    },
                  }}
                />
              }
              css={{
                color: colors.blue,
              }}>
              Remove Schedule
            </InfoText>
          )
        }
        onSave={handleModalSubmit}
        onRequestClose={() => setModalOpen(false)}
        largeTitle={true}
        size={Size.large}>
        <SchedulePublishDialogContent
          existingSchedulePublish={existingSchedulePublish}
          objectType={objectType}
          timezone={timezone || initialTimezone}
          errors={schedulePublishErrors}
          formatted_publishing_date={
            formData?.get(`${objectType}[formatted_publishing_date]`)
              ? formData
                  ?.get(`${objectType}[formatted_publishing_date]`)
                  .toString()
              : null
          }
          formatted_publishing_time={
            formData?.get(`${objectType}[formatted_publishing_time]`)
              ? formData
                  ?.get(`${objectType}[formatted_publishing_time]`)
                  .toString()
              : null
          }
        />
      </Modal>
    </Fragment>
  )
}
