import { css } from '@emotion/react'
import Container from 'components/Container/Container'
import DateBadge from 'components/DateBadge/DateBadge'
import { Paragraph } from 'components/Typography'
import { useMemo } from 'react'
import { calcGridWidth, colors, spacings } from 'stylesheets/theme'
import CalendarIcon from 'images/icons/calendar.svg'
import CloseIcon from 'images/icons/close.svg'
import moment from 'moment'
import EventAction from 'components/EventAction'
import { IEventCall } from 'types'
import CallAction from 'components/CallAction'
import {
  DATE_BADGE_WIDTH,
  PAGE_PADDING,
  PAGE_WIDTH_AND_PADDING,
} from './constants'
import { useComplexMediaQuery } from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

interface EventCallHeaderProps {
  isMobile?: boolean
  date: string
  title: string
  datetime: string
  className?: string
  data: IEventCall
  registerEvent?: boolean
  invitable?: boolean
  isCall?: boolean
  deadlineReached?: boolean
  registrationDeadline?: string
  capacityReached?: boolean
  closed?: boolean
  closedAt?: string
}

const outerContainerStyle = css({
  backgroundColor: colors.backgrounds.highlight,
  width: '100%',
})

const defaultStyle = css({
  width: '100%',
  maxWidth: '100vw',
  margin: '0 auto',
  padding: `${spacings.grid_gap_basis_num * 2}px ${PAGE_PADDING}px`,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: spacings.grid_gap_basis_num * 2,
})

const desktopStyle = css({
  flexWrap: 'nowrap',
  width: PAGE_WIDTH_AND_PADDING,
})

const mobileStyle = css({
  padding: '24px',
})

const defaultTitleContainerStyle = css({
  flexWrap: 'nowrap',
  maxWidth: calcGridWidth(5),
  alignItems: 'flex-start',
})
const desktopTitleContainerStyle = css({
  maxWidth: calcGridWidth(7),
})

const mobileTitleContainerStyle = css({
  flexWrap: 'wrap',
})

const defaultDateBadgeTitleContainerStyle = css({
  gap: spacings.grid.gap,
  alignItems: 'flex-start',
  width: '100%',
})

const mobileDateBadgeTitleContainerStyle = css({
  flexWrap: 'wrap',
})

export default function EventCallHeader({
  isMobile = false,
  date,
  title,
  datetime,
  className = '',
  data,
  registerEvent = false,
  invitable = false,
  isCall = false,
  deadlineReached = false,
  registrationDeadline,
  capacityReached = false,
  closed = false,
  closedAt,
}: EventCallHeaderProps): JSX.Element {
  const month = useMemo(() => moment(date, 'ddd, LL').format('MMM'), [date])
  const dateNum = useMemo(() => moment(date, 'ddd, LL').format('D'), [date])
  const day = useMemo(() => moment(date, 'ddd, LL').format('ddd'), [date])

  const isFullDesktop = useComplexMediaQuery({ minWidth: breakpoints.xlg })
    .meetsDetails.minWidth

  const eventClosed = useMemo(() => {
    return (
      (closed && closedAt) ||
      (deadlineReached && registrationDeadline) ||
      capacityReached
    )
  }, [closed, closedAt, deadlineReached, registrationDeadline, capacityReached])

  const eventClosedText = useMemo(() => {
    if (eventClosed) {
      if (closed && closedAt) {
        return `Registration was manually closed on ${closedAt}`
      }

      if (deadlineReached && registrationDeadline) {
        return `Registration closed on ${registrationDeadline}`
      }

      if (capacityReached) {
        return `Registration is now closed as we've reached maximum capacity`
      }
    }

    return ''
  }, [
    eventClosed,
    closed,
    closedAt,
    deadlineReached,
    registrationDeadline,
    capacityReached,
  ])

  return (
    <Container css={outerContainerStyle}>
      <Container
        css={[
          defaultStyle,
          isFullDesktop && desktopStyle,
          isMobile && mobileStyle,
        ]}>
        <Container
          direction="row"
          className={className}
          css={[
            defaultDateBadgeTitleContainerStyle,
            isMobile && mobileDateBadgeTitleContainerStyle,
          ]}>
          <div
            css={{
              width: calcGridWidth(1),
            }}>
            <DateBadge
              month={month}
              date={dateNum}
              day={day}
              css={{
                marginLeft: isMobile
                  ? 0
                  : `calc(${calcGridWidth(1)}px - ${DATE_BADGE_WIDTH}px)`,
              }}
            />
          </div>
          <Container
            css={{
              justifyContent: 'space-between',
              maxWidth: 542 + calcGridWidth(7) + spacings.grid.gap,
              flexGrow: 1,
            }}>
            <Container
              direction="column"
              css={[
                defaultTitleContainerStyle,
                isFullDesktop && desktopTitleContainerStyle,
                isMobile && mobileTitleContainerStyle,
              ]}>
              <h1
                css={{
                  color: colors.blue,
                }}>
                {title}
              </h1>
              <Container
                css={{
                  '*': {
                    color: colors.text.text_5,
                  },
                  gap: spacings.grid_gap_basis_num / 2,
                }}>
                <CalendarIcon
                  css={{
                    width: 17,
                    height: 17,
                    path: {
                      fill: colors.text.text_5,
                    },
                  }}
                />
                <Paragraph bold>{datetime}</Paragraph>
              </Container>
              {eventClosed && eventClosedText && (
                <Container>
                  <CloseIcon
                    css={{
                      width: 17,
                      height: 17,
                      path: {
                        fill: colors.text.text_5,
                      },
                    }}
                  />
                  <Paragraph bold>{eventClosedText}</Paragraph>
                </Container>
              )}
            </Container>
            {!isMobile && !isCall && (
              <Container
                alignment="start"
                justify="start"
                direction="row"
                css={{ maxWidth: calcGridWidth(5) }}>
                <EventAction
                  data={data}
                  location="banner"
                  doRegister={registerEvent}
                  invitable={invitable}
                />
              </Container>
            )}
          </Container>
        </Container>
        {!isMobile && isCall && (
          <Container>
            <CallAction
              data={data}
              location="banner"
              doMarkComplete={true} //TODO
            />
          </Container>
        )}
      </Container>
    </Container>
  )
}
