import React, { useRef, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import produce, { enableES5 } from 'immer'
enableES5()

// components
import { IMainTableFilters, ITableData, ITableProps } from 'components/Table'
import Input from 'components/Input'
import EventCall from 'components/EventCall'
import FeaturedEventCall from 'components/EventCall/FeaturedEventCall'
import EmptyPlaceholder from 'components/EmptyPlaceholder'

import { IEventCall } from 'types'

// styles
import './EventsCalls.scss'

// services
async function fetchData(url: string, params: IFilters) {
  try {
    const response = await axios.get(url, { params })
    return response.data
  } catch (error) {
    window.flash('Something went wrong!', 'alert')
  }
}

// utils
import { useEffectNotOnMount } from 'javascripts/general'

// interfaces
interface IFilters extends IMainTableFilters {
  'filter[my_communities]': boolean
  'filter[city]': string[]
  'filter[community_id]': number[]
  'filter[next_30_days]': boolean
  'filter[next_7_days]': boolean
  'filter[less_30_mins]': boolean
  'filter[less_2_hrs]': boolean
  page?: number
}

interface ICommunitiesFilter {
  id: number
  name: string
}

interface ILocationsFilter {
  city: string
  state: string
}

interface EventsCallsProps extends ITableProps {
  featured: ITableData
  isCall?: boolean
  filters: {
    communities: ICommunitiesFilter[]
    my_communities: ICommunitiesFilter[]
    locations: ILocationsFilter[]
    my_locations: ILocationsFilter[]
  }
}

const EventsCalls: React.FC<EventsCallsProps> = ({
  featured,
  tableData,
  isCall,
  tableMeta,
  filters,
  ...props
}) => {
  const [data, setData] = useState(tableData)
  const [featuredData, setFeaturedData] = useState(featured)
  const [selectedFilters, setSelectedFilters] = useState<IFilters>(
    props.selectedFilters as IFilters,
  )
  const [seeMoreGroups, setSeeMoreGroups] = useState(false)
  const [seeMoreLocations, setSeeMoreLocations] = useState(false)

  const onFilterChange = (
    object: Record<string, boolean | string | number | number[] | string[]>,
  ) => {
    setSelectedFilters((currentFiltersValue) => ({
      ...currentFiltersValue,
      ...object,
    }))
  }

  const loadData = async (filters, loadMore = false, page?: number) => {
    const normalizedFilters = { ...filters }

    if (loadMore || page) {
      normalizedFilters.page =
        (loadMore ? data.paginator.current_page : page) + 1
    }

    const fetched = await fetchData(tableMeta.url, normalizedFilters)
    setData((prev) =>
      loadMore
        ? produce(prev, (draft) => {
            draft.rows = [...draft.rows, ...fetched.data.rows]
            draft.paginator = fetched.data.paginator
          })
        : produce(prev, (draft) => {
            draft.rows = page
              ? [draft.rows[0], ...fetched.data.rows]
              : fetched.data.rows
            draft.paginator = fetched.data.paginator
          }),
    )
    if (!loadMore && !page) {
      setFeaturedData((prev) =>
        produce(prev, (draft) => {
          draft.rows = fetched.featured.rows
        }),
      )
    }
  }

  const { current: loadDataDebounced } = useRef(_.debounce(loadData, 300))

  const onCheckboxChange = (name: string, object: Record<string, boolean>) => {
    if (name === 'my_communities') {
      setSelectedFilters(
        produce((draft) => {
          if (object['filter[my_communities]']) {
            draft['filter[my_communities]'] = true
          } else {
            delete draft['filter[my_communities]']
          }
        }),
      )
      return
    }

    const selectedFilter = selectedFilters[`filter[${name}]`]?.slice() || []
    const filterString = Object.keys(object)[0]
    const filterValue =
      name === 'community_id' ? parseInt(filterString) : filterString

    if (object[filterString]) {
      onFilterChange({
        [`filter[${name}]`]: [...selectedFilter, filterValue],
      })
    } else {
      onFilterChange({
        [`filter[${name}]`]: selectedFilter.filter(
          (filter) => filter !== filterValue,
        ),
      })
    }
  }

  const onDateChange = (object: Record<string, string>) => {
    setSelectedFilters(
      produce((draft) => {
        delete draft['filter[next_7_days]']
        delete draft['filter[next_30_days]']
        delete draft['filter[less_30_mins]']
        delete draft['filter[less_2_hrs]']

        if (object.date !== 'all') {
          draft[object.date] = true
        }
      }),
    )
  }

  useEffectNotOnMount(() => {
    loadDataDebounced(selectedFilters, false)
  }, [selectedFilters])

  const communitiesFilters = selectedFilters['filter[my_communities]']
    ? filters.my_communities
    : filters.communities
  const locationsFilters = selectedFilters['filter[my_communities]']
    ? filters.my_locations
    : filters.locations

  const normalizedData = featuredData.rows.length
    ? data.rows.filter((row) => row.data.id !== featuredData.rows[0].data.id)
    : data.rows

  const numShowing = data.rows && data.rows.length ? data.rows.length - 1 : 0
  const totalEntries =
    data.paginator && data.paginator.total_entries
      ? data.paginator.total_entries - 1
      : 0
  const totalCaption = `(Showing ${numShowing} of ${totalEntries})`

  return (
    <div className="events-homepage">
      <form className="homepage-sidebar">
        <span className="filter-title">Filters</span>
        <div className="filter-section">
          <p className="filter-header">Employee Communities</p>
          <div className="switch-wrapper">
            <p className="off">All</p>
            <Input
              type="switch"
              name="filter[my_communities]"
              wrapperClassName="switch-component-wrapper"
              onChange={(data) => {
                onCheckboxChange(
                  'my_communities',
                  data as Record<string, boolean>,
                )
              }}
              checked={selectedFilters['filter[my_communities]']}
            />
            <p className="on">Mine</p>
          </div>
        </div>
        <div className="filter-section">
          <p className="filter-header">Groups</p>
          {(seeMoreGroups
            ? communitiesFilters
            : communitiesFilters.slice(0, 4)
          ).map((community) => (
            <Input
              key={community.id}
              type="checkbox"
              name={community.id.toString()}
              label={community.name}
              onChange={(data) =>
                onCheckboxChange(
                  'community_id',
                  data as Record<string, boolean>,
                )
              }
              checked={selectedFilters['filter[community_id]']?.includes(
                community.id,
              )}
            />
          ))}
          {communitiesFilters.length > 4 && (
            <button
              className="not-styled-button see-more"
              type="button"
              onClick={() => setSeeMoreGroups(!seeMoreGroups)}>
              See {seeMoreGroups ? 'less' : 'more'}
            </button>
          )}
        </div>
        <div className="filter-section">
          {isCall ? (
            <>
              <p className="filter-header">Time Commitment</p>
              <Input
                type="radio"
                name="date"
                value="all"
                onChange={onDateChange}
                checked={
                  !selectedFilters['filter[next_30_days]'] &&
                  !selectedFilters['filter[next_7_days]'] &&
                  !selectedFilters['filter[less_30_mins]'] &&
                  !selectedFilters['filter[less_2_hrs]']
                }
                label="Any"
              />
              <Input
                type="radio"
                name="date"
                value="filter[less_30_mins]"
                onChange={onDateChange}
                checked={selectedFilters['filter[less_30_mins]']}
                label="30 Minutes or Less"
              />
              <Input
                type="radio"
                name="date"
                value="filter[less_2_hrs]"
                onChange={onDateChange}
                checked={selectedFilters['filter[less_2_hrs]']}
                label="2 Hours or Less"
              />
            </>
          ) : (
            <>
              <p className="filter-header">Dates</p>
              <Input
                type="radio"
                name="date"
                value="all"
                onChange={onDateChange}
                checked={
                  !selectedFilters['filter[next_30_days]'] &&
                  !selectedFilters['filter[next_7_days]'] &&
                  !selectedFilters['filter[less_30_mins]'] &&
                  !selectedFilters['filter[less_2_hrs]']
                }
                label="All dates"
              />
              <Input
                type="radio"
                name="date"
                value="filter[next_30_days]"
                onChange={onDateChange}
                checked={selectedFilters['filter[next_30_days]']}
                label="Next 30 days"
              />
              <Input
                type="radio"
                name="date"
                value="filter[next_7_days]"
                onChange={onDateChange}
                checked={selectedFilters['filter[next_7_days]']}
                label="Next 7 days"
              />
            </>
          )}
        </div>
        {!!locationsFilters.length && (
          <div className="filter-section">
            <p className="filter-header">Locations</p>
            {(seeMoreLocations
              ? locationsFilters
              : locationsFilters.slice(0, 3)
            ).map((location) => {
              const label = location.state
                ? `${location.city}, ${location.state}`
                : location.city
              return (
                <Input
                  key={location.city}
                  type="checkbox"
                  name={location.city}
                  label={label}
                  onChange={(data) =>
                    onCheckboxChange('city', data as Record<string, boolean>)
                  }
                  checked={selectedFilters['filter[city]']?.includes(
                    location.city,
                  )}
                />
              )
            })}
            {locationsFilters.length > 3 && (
              <button
                className="not-styled-button see-more"
                type="button"
                onClick={() => setSeeMoreLocations(!seeMoreLocations)}>
                See {seeMoreLocations ? 'less' : 'more'}
              </button>
            )}
          </div>
        )}
      </form>
      <div className="events-root">
        <div className="section-header">
          <div className="header-left">
            <p className="section-title">
              {isCall ? 'Upcoming Call to Action' : 'Next Event'}
            </p>
          </div>
          <Input
            name="filter[*]"
            type="search"
            placeholder="Search Title or Location"
            wrapperClassName="search"
            value={selectedFilters['filter[*]']}
            onChange={onFilterChange}
          />
        </div>
        {data?.rows.length ? (
          <>
            {!!featuredData?.rows.length && (
              <FeaturedEventCall
                data={
                  {
                    ...featuredData.rows[0].data,
                    link: featuredData.rows[0].link,
                  } as IEventCall
                }
                isCall={isCall}
              />
            )}

            <div className="section-header mb34">
              <div className="header-left">
                {isCall ? (
                  <p className="section-title">All Current Calls to Action</p>
                ) : (
                  <p className="section-title">Upcoming Events</p>
                )}
                <p className="section-total caption">{totalCaption}</p>
              </div>
            </div>
            {normalizedData.length ? (
              <div className="events-tiles">
                {normalizedData.map((item) => {
                  return (
                    <EventCall
                      data={{ ...item.data, link: item.link } as IEventCall}
                      isCall={isCall}
                      isSmall
                      key={item.data.id as string}
                    />
                  )
                })}
              </div>
            ) : (
              <EmptyPlaceholder
                text={
                  isCall
                    ? 'No other current calls to action.'
                    : 'No other upcoming events.'
                }
              />
            )}
            {normalizedData.length
              ? data.paginator &&
                data.paginator.current_page != data.paginator.total_pages && (
                  <div className="load-button">
                    <button
                      className="button primary"
                      onClick={() => {
                        loadData(selectedFilters, true)
                      }}>
                      LOAD MORE
                    </button>
                  </div>
                )
              : null}
          </>
        ) : (
          <EmptyPlaceholder
            text={
              isCall ? 'No upcoming calls to action.' : 'No upcoming events.'
            }
          />
        )}
      </div>
    </div>
  )
}

export default EventsCalls
