import React from 'react'

import Table, { ITableProps } from 'components/Table'
import useOrganizationsState from 'state/Organizations'
import Input from 'components/Input'

import './Organizations.scss'

interface IOrganizationsProps extends ITableProps {
  url: string
  createOrganizationUrl: string
}

const Organizations: React.FC<IOrganizationsProps> = ({
  url,
  createOrganizationUrl,
  tableData: initialData,
  tableMeta: initialMeta,
}) => {
  const {
    data: tableData,
    meta: tableMeta,
    updateOrder,
    loadPage,
    filters,
    updateFilters,
  } = useOrganizationsState({
    url,
    initialData,
    initialMeta,
  })

  return (
    <>
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">Organizations</p>
        </div>
        <a href={createOrganizationUrl} className="button primary">
          Create Organization
        </a>
      </div>
      <div className="section-header">
        <div className="header-left"></div>
        <div className="header-right">
          <div className="input-component-wrapper active-only">
            <div className="switch-wrapper">
              <p className="off">All</p>
              <Input
                type="switch"
                name="show_all"
                wrapperClassName="switch-component-wrapper"
                checked={!filters.showAll}
                onChange={(inputData) => {
                  updateFilters({
                    ...filters,
                    showAll: !inputData['show_all'] as boolean,
                  })
                }}
              />
              <p className="on">Only Active</p>
            </div>
          </div>
          <Input
            name="text"
            type="search"
            placeholder="Search"
            wrapperClassName="search"
            value={filters.text}
            onChange={(data) => {
              const record = data as Record<string, string>
              updateFilters({
                text: record.text,
              })
            }}
          />
        </div>
      </div>
      <Table
        tableData={tableData}
        tableMeta={tableMeta}
        onOrderChange={({ sort_col, sort_dir }) => {
          updateOrder(
            sort_col,
            sort_dir === 'asc' || sort_dir === 'desc' ? sort_dir : 'asc',
          )
        }}
        onPageChange={loadPage}
      />
    </>
  )
}

export default Organizations
