import React from 'react'
import cx from 'classnames'
import './Typography.scss'

interface SmallTextProps {
  children: React.ReactNode | string
  className?: string
}
export default function SmallText({
  children,
  className,
}: SmallTextProps): React.ReactElement {
  return <p className={cx('small-text', className)}>{children}</p>
}
