import React from 'react'
import cx from 'classnames'

import Select, { IOption } from '../Select'
import { TTimezone } from 'types'

type TTimezoneOption = IOption & {
  abbreviation: string
}

interface ITimezonePicker {
  name: string
  defaultTimezone: string
  timezones: TTimezone[]
  id?: string
  className?: string
  errors?: string[]
  disabled?: boolean
  readOnly?: boolean
}

const TimezonePicker = ({
  id,
  name,
  defaultTimezone,
  timezones,
  className,
  errors,
  disabled = false,
  readOnly = false,
}: ITimezonePicker): React.ReactElement => (
  <Select<TTimezoneOption>
    id={id || name}
    name={name}
    defaultValue={defaultTimezone}
    options={timezones.map((timezone) => ({
      label: timezone.offset_string,
      value: timezone.tzid,
      abbreviation: timezone.abbreviation,
    }))}
    getSelectedLabel={(option) => option.abbreviation}
    styleOverrides={{
      menu: {
        width: '300px',
      },
    }}
    className={cx('timezone-picker', className)}
    errors={errors}
    disabled={disabled}
    readOnly={readOnly}
  />
)

export default TimezonePicker
