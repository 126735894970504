import classNames from 'classnames'

// contexts
import { useTableContext } from '../TableContext'

// components
import TableCell from './TableCell'
import EmptyState from 'components/EmptyState'
import { ITableRow } from 'components/Table'

export interface ITableBodyProps<T> {
  children?: (row: ITableRow<T>, index: number) => JSX.Element
}

const TableBody = <T,>({ children }: ITableBodyProps<T>): JSX.Element => {
  const {
    tableData: { rows },
    tableMeta: { columns },
    hasEmptyState,
    emptyStateImgUrl,
    object,
    emptyStateTitle,
    emptyStateSubtitle,
    emptyStateActionNode,
    emptyStateChildren,
  } = useTableContext<T>()

  return (
    <>
      <tbody>
        {rows.map((row, index) => (
          <tr
            key={index}
            className={classNames(row?.className, {
              'link-row': row && row.link,
            })}>
            {children
              ? children(row, index)
              : columns.map((column) => (
                  <TableCell
                    key={column.field || column.component}
                    column={column}
                    value={row.data ? (row.data[column.field] as string) : ''}
                    link={row.link}
                    row={row}
                  />
                ))}
          </tr>
        ))}
      </tbody>
      {!rows.length && (
        <tfoot>
          <tr>
            <td colSpan={columns.length} className="empty-records">
              <div className="GojiCustomTable__cell-value">
                {hasEmptyState ? (
                  <EmptyState
                    imgUrl={emptyStateImgUrl}
                    object={object}
                    title={emptyStateTitle}
                    subtitle={emptyStateSubtitle}
                    actionNode={emptyStateActionNode}>
                    {emptyStateChildren}
                  </EmptyState>
                ) : (
                  'No records to display'
                )}
              </div>
            </td>
          </tr>
        </tfoot>
      )}
    </>
  )
}

export default TableBody
