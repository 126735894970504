import React, { useCallback, useMemo, useRef } from 'react'

import Input from 'components/Input'
import Modal, { Size } from 'components/Modal'
import EventCallSummary from 'components/EventCallSummary'

import './RegisterConfirmationModal.scss'

import { IEventCall } from 'types'

export type TRegisterConfirmationModalType = 'register' | 'questions'

interface IRegisterConfirmationModal {
  isOpen: boolean
  type?: TRegisterConfirmationModalType
  event: IEventCall
  onSubmit: (notes: string, anonymous: boolean) => void
  onClose: () => void
  featureAlliesAndAffiliates?: boolean
}

const RegisterConfirmationModal = ({
  isOpen,
  type = 'register',
  event,
  onSubmit,
  onClose,
}: IRegisterConfirmationModal): React.ReactElement => {
  const notesRef = useRef<HTMLInputElement>()
  const anonymousRef = useRef<HTMLInputElement>()
  const closeModal = useCallback(() => {
    onClose()
  }, [onClose])

  const submitQuestions = useCallback(() => {
    const notes = (notesRef.current.value as string).trim()
    const anonymous = anonymousRef.current.checked
    if (notes) {
      onSubmit(notes, anonymous)
    } else {
      onClose()
    }
  }, [onSubmit, onClose])

  const title = useMemo(() => {
    if (type === 'questions') {
      return 'Please share any questions for this event'
    }
    return `See you at the event, ${window.currentUser.full_name}`
  }, [type])
  const subtitle = useMemo(() => {
    if (type === 'questions') {
      return 'Here are the details for this event:'
    }
    return 'A calendar invite was sent to your email. Here are the details for this event:'
  }, [type])

  return (
    <Modal
      isOpen={isOpen}
      size={Size.large}
      title={title}
      subtitle={subtitle}
      onRequestClose={closeModal}
      onSave={submitQuestions}
      className="register-confirmation-modal"
      submitButton="Submit Questions"
      cancelButton="No Questions">
      <EventCallSummary
        data={event}
        type="event"
        showHosts
        className="mindr-modal-section"
      />
      <form
        id="registration-form"
        className="mindr-modal-section"
        onSubmit={submitQuestions}>
        <Input
          type="textarea"
          label="What questions or topics would you like to be addressed at this event?"
          name="notes"
          ref={notesRef}
        />
        <Input
          type="checkbox"
          label="Make my questions anonymous"
          name="anonymous"
          value="true"
          ref={anonymousRef}
        />
      </form>
    </Modal>
  )
}

export default RegisterConfirmationModal
