import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from '../modal-style'

export default function RejectAllModal({
  ...props
}: Omit<
  IModalProps,
  'size' | 'children' | 'submitButton' | 'cancelButton' | 'title'
>): JSX.Element {
  return (
    <Modal
      {...props}
      css={modalStyle}
      size={Size.large}
      submitButton="Reject all"
      cancelButton="Cancel"
      title="Reject all proposed matches">
      <p>
        Accepted matches will not be affected by this action. Are you sure you
        want to reject all of the proposed matches? This action cannot be
        undone.
      </p>
    </Modal>
  )
}
