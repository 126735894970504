import { FilterOption, IFilterItem, ILocationObject } from './types'
import compact from 'lodash/compact'

export const DEFAULT_CSV_MESSAGING =
  'Choose a subset of users who will receive email notifications. ' +
  'You may use an advanced filter to refine the recipient list or CSV import to upload a CSV of desired recipients. ' +
  'While location, department, and title options are for community subscribers including co-hosted communities, ' +
  "you can invite non-subscribers by choosing 'Name' from the dropdown or by uploading those users through CSV import."

export function getResultingFilterGroups(
  filterGroups: IFilterItem[][],
): IFilterItem[][] {
  return filterGroups.reduce((acc: IFilterItem[][], group) => {
    const filteredGroup = group.filter(
      (item) => item.filterType && item.filterInputs?.length,
    )

    if (filteredGroup.length > 0) {
      acc.push(filteredGroup)
    }
    return acc
  }, [])
}

export function locationToString(l: ILocationObject, sep = ', '): string {
  return compact([l.city, l.state].filter((a) => !!a)).join(sep ?? ', ')
}

export function getResultingSelectedInputs(
  filterType: FilterOption,
  filterInputs: any[],
): string {
  switch (filterType) {
    case 'Name':
      return filterInputs.map((user) => `${user.full_name}`).join(', ')
    case 'Location':
      return filterInputs
        .map((location) => locationToString(location.value))
        .join(', ')
    default:
      return filterInputs.map((input) => input.value).join(', ')
  }
}
