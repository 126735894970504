import { SectionTitle } from 'components/Typography'
import { boxShadow, colors, spacings } from 'stylesheets/theme'
import { Resource, ResourceType } from 'types'
import FileIcon from 'images/icons/file.svg'
import ChainIcon from 'images/icons/chain_45.svg'

interface RecommendedResourcesProps {
  resources: Resource<ResourceType>[]
}

export default function RecommendedResources({
  resources,
}: RecommendedResourcesProps): JSX.Element {
  return (
    <div>
      <SectionTitle
        css={{
          marginBottom: spacings.grid_gap_basis_num * 2,
        }}>
        Recommended Resources
      </SectionTitle>
      <div
        css={{
          backgroundColor: colors.backgrounds.white,
          boxShadow: boxShadow.container,
          padding: 18,
          borderRadius: spacings.radius,
        }}>
        {resources.map((resource) => (
          <div key={resource.title} className="resource-wrapper">
            <a
              target="_blank"
              rel="noreferrer"
              title={resource.title}
              key={resource.title}
              className="link"
              href={resource.url}>
              {resource.document_data ? <FileIcon /> : <ChainIcon />}
              <span>{resource.title}</span>
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
