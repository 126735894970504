import { css } from '@emotion/react'

import Widget, { WidgetProps } from './Widget'

import PeopleIcon from 'images/icons/people.svg'

import { colors } from 'stylesheets/theme'

interface PeopleWidgetProps extends Omit<WidgetProps, 'number' | 'icon'> {
  numPeople: number
}

const iconStyle = css({
  path: {
    fill: colors.blue,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.people_widget_background,
  },
})

export default function PeopleWidget({
  numPeople,
  ...props
}: PeopleWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<PeopleIcon height="18px" width="18px" css={iconStyle} />}
      number={numPeople}
      css={widgetStyle}
      {...props}
    />
  )
}
