import Button, { Variant } from 'components/Button'
import Input from 'components/Input'
import Modal, { Size } from 'components/Modal'
import { Caption } from 'components/Typography'
import EyeIcon from 'images/icons/eye.svg'
import { useState } from 'react'
import { colors, spacings } from 'stylesheets/theme'

interface LeadrActionsCellProps {
  data: {
    inquiry?: string | null
    inquiry_submitted_at?: string | null
  }
}

export default function LeadrActionsCell({
  data,
}: LeadrActionsCellProps): JSX.Element {
  const [openModal, setModalOpen] = useState(false)
  if (!data.inquiry) {
    return null
  }

  return (
    <>
      <Button
        variant={Variant.LINK}
        onClick={() => setModalOpen(true)}
        startIcon={
          <EyeIcon
            css={{
              minWidth: 14,
              minHeight: 14,
              marginRight: spacings.grid_gap_basis_num / 2,
              path: {
                fill: colors.links.blue,
              },
            }}
          />
        }>
        Retake inquiry
      </Button>
      <Modal
        isOpen={openModal}
        size={Size.large}
        largeTitle
        title="Assessment retake inquiry"
        onRequestClose={() => setModalOpen(false)}
        onSave={() => {}}
        cancelButton="close">
        {data.inquiry_submitted_at && (
          <Caption
            css={{
              marginBottom: spacings.grid_gap_basis_num * 2,
            }}>
            Submitted on {data.inquiry_submitted_at}
          </Caption>
        )}
        <Input
          type="textarea"
          readOnly
          name="retake_inquiry_note"
          value={data.inquiry}
          css={{
            width: 625,
            heigth: 216,
            resize: 'none',
          }}
          hideName
        />
      </Modal>
    </>
  )
}
