import React, { useCallback, useEffect, useState } from 'react'
import Input, { AllowClearDocumentCallback } from 'components/Input'
import { TFile } from 'types'
import { css } from '@emotion/react'

export type TCsvType = 'csv-import' | 'csv-mentorship-import'

const sideBySideCss = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '3rem',
  '> .input-component-wrapper, > span': {
    display: 'inline-block',
  },
})

interface ICsvFile {
  name: string
  label?: string
  error: string
  initialValue?: TFile
  type?: TCsvType
  cachedData?: string
  readOnly?: boolean
  additionalComponentsWhenUnmodified?: React.ReactNode
  onChangeDocument?: (data: string) => void
  onConfirmDeleteDocument?: AllowClearDocumentCallback
  className?: string
  hideFileFieldName?: boolean
}

const CsvFileInput: React.FC<ICsvFile> = ({
  name,
  label,
  error,
  initialValue,
  type = 'csv-import',
  cachedData,
  readOnly,
  additionalComponentsWhenUnmodified,
  onChangeDocument = () => {},
  onConfirmDeleteDocument,
  className = '',
  hideFileFieldName = false,
}) => {
  const [filename, setFilename] = useState<string>('')
  useEffect(() => {
    setFilename(initialValue?.file_name)
  }, [initialValue])
  const [changed, setChanged] = useState<boolean>(false)
  const [cached, setCached] = useState<string>(cachedData ?? null)

  const onChange = useCallback(
    (newFile) => {
      setFilename(newFile[name])
      setChanged(true)
      if (!newFile[name]) {
        setCached(null)
        onChangeDocument(null)
      }
    },
    [setFilename, setChanged, setCached, onChangeDocument],
  )

  return (
    <div css={sideBySideCss}>
      <Input
        className={className}
        name={name}
        type={type}
        label={label}
        value={filename}
        cachedData={cached}
        error={error}
        onChange={onChange}
        onChangeDocument={onChangeDocument}
        onConfirmDeleteDocument={onConfirmDeleteDocument}
        readOnly={readOnly}
        hideFileFieldName={hideFileFieldName}
      />
      {!changed && <span>{additionalComponentsWhenUnmodified}</span>}
    </div>
  )
}

export default CsvFileInput
