import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'

export const SIdpSettingItem = css({
  backgroundColor: colors.backgrounds.gray,
  padding: 2 * spacings.grid_gap_basis_num,
  width: 580 + 4 * spacings.grid_gap_basis_num,
})

export const SIdpSettingActionRow = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
})
