import Table, { ITableProps } from 'components/Table'
import React from 'react'
import useAuthTokensState from 'state/AuthTokens'

import './OrganizationAuthTokens.scss'

interface IOrganizationAuthTokens extends ITableProps {
  url: string
}

const OrganizationAuthTokens: React.FC<IOrganizationAuthTokens> = ({
  url,
  tableData: initialData,
  tableMeta: initialMeta,
}) => {
  const { data, meta, loadPage, updateOrder, reload } = useAuthTokensState({
    url,
    initialData,
    initialMeta,
  })

  return (
    <div className="organization-auth-tokens-root">
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">Auth Tokens</p>
        </div>
        <button
          className="button primary create-organization-auth-token-button"
          data-url={url}>
          Create Auth Token
        </button>
      </div>
      <Table
        tableData={data}
        tableMeta={meta}
        onPageChange={(pageNum) => loadPage(pageNum)}
        onOrderChange={({ sort_col, sort_dir }) => {
          updateOrder(
            sort_col,
            sort_dir === 'asc' || sort_dir === 'desc' ? sort_dir : 'asc',
          )
        }}
      />
      <button className="reload-page-hack" onClick={() => reload()}>
        Reload
      </button>
    </div>
  )
}

export default OrganizationAuthTokens
