import React from 'react'
import Switch from 'components/Switch'
import { TEventCallType } from 'types'
import { FormFieldContainer } from 'components/Forms'

interface SubmitQuestionsProps {
  allowSubmit: boolean
  object: TEventCallType
  label: string
}

/**
 * Control for "Should registrants be able to submit questions?"
 */
export default function SubmitQuestions({
  allowSubmit,
  object,
  label,
}: SubmitQuestionsProps): JSX.Element {
  const controlName = `${object}[submit_questions]`
  const controlId = `${object}-submit-questions`

  return (
    <FormFieldContainer fieldId={controlId} label={label}>
      <Switch
        id={controlId}
        controlName={controlName}
        defaultChecked={!!allowSubmit}
      />
    </FormFieldContainer>
  )
}
