import Container from 'components/Container'
import ApplicationFormFieldContainer from './ApplicationFormFieldContainer'
import ImportanceRadioGroup from './ImportanceRadioGroup'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import { Paragraph } from 'components/Typography'
import ApplicationStepWrapper from './ApplicationStepWrapper'
import { Tag } from './types'
import { useMemo } from 'react'

const headingStyle = css({
  color: colors.text.text_5,
  fontSize: 18,
  fontWeight: 600,
  marginBottom: spacings.grid_gap_basis_num * 2,
})

const labelStyle = css({
  color: colors.text.text_5,
  fontSize: 15,
  fontStyle: 'normal',
  fontWeight: 800,
})

interface TopSkillProps {
  skill: string
  skillTags: Tag[]
  isGoal?: boolean
  isFirst?: boolean
}

const DEFAULT_GOAL_IMPORTANCE_LIST = [
  {
    label: 'Very important',
    value: 3,
  },
  {
    label: 'Important',
    value: 2,
  },
  {
    label: 'Neutral',
    value: 1,
  },
  {
    label: 'Unimportant',
    value: 0,
  },
]

function getTagId(skill: string, tag: Tag) {
  return `${skill}-${tag.name.replace(/\s+/g, '-').toLowerCase()}`
}

export default function TopSkill({
  skill,
  skillTags,
  isGoal = false,
  isFirst = false,
}: TopSkillProps): JSX.Element {
  const importanceList = useMemo(() => {
    if (!isGoal) {
      return undefined
    }

    return DEFAULT_GOAL_IMPORTANCE_LIST
  }, [isGoal])
  const title = useMemo(() => {
    if (isGoal && isFirst) {
      return `Let’s shift our attention now to areas of improvement: you ranked ${skill.toUpperCase()} last`
    } else if (isGoal) {
      return `You identified ${skill.toUpperCase()} as an area for growth`
    } else if (isFirst) {
      return `You selected ${skill.toUpperCase()} as your top skill`
    } else {
      return `You selected ${skill.toUpperCase()} as your next top skill`
    }
  }, [isGoal, isFirst, skill])

  const heading = useMemo(
    () =>
      isGoal ? (
        <>
          Please rate your level of interest in <mark>developing</mark> the
          following skills:
        </>
      ) : (
        <>
          Please rate your <mark>strengths</mark> in the following specific
          areas:
        </>
      ),
    [isGoal],
  )

  return (
    <ApplicationStepWrapper
      title={title}
      requiredFields={
        isFirst ? skillTags.map((tag) => getTagId(skill, tag)) : []
      }>
      <Container direction="column" css={{ alignItems: 'flex-start' }}>
        <span css={headingStyle}>{heading}</span>
        {skillTags.map((tag) => {
          const tag_id = getTagId(skill, tag)
          return (
            <ApplicationFormFieldContainer
              key={tag_id}
              label={
                <Paragraph
                  css={{
                    fontWeight: 'normal',
                  }}>
                  <span css={labelStyle}>{tag.name + ': '}</span>
                  {isGoal ? tag.goal_description : tag.strength_description}
                </Paragraph>
              }
              fieldId={tag_id}>
              <ImportanceRadioGroup
                name={tag_id}
                importanceList={importanceList}
              />
            </ApplicationFormFieldContainer>
          )
        })}
      </Container>
    </ApplicationStepWrapper>
  )
}
