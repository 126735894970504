import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { Editor } from '@tinymce/tinymce-react'
import './RichTextInput.scss'

import contentCssUrl from 'stylesheets/tinymce-content.scss?url'

declare global {
  interface Window {
    tinymceApiKey: string
  }
}

interface IRichTextInput {
  name: string
  label: string
  initialValue?: string
  error?: string
  onChange?: (value: Record<string, string>) => void
}

const RichTextInput: React.FC<IRichTextInput> = ({
  name,
  label,
  initialValue,
  error,
  onChange,
}) => {
  const handleEditorInit = useCallback((_evt, editor) => {
    editor.editorContainer.classList.add('editor-container')
  }, [])

  const [hiddenValue, setHiddenValue] = useState(initialValue ?? '')

  const handleValueChange = useCallback(
    (value) => {
      setHiddenValue(value)
      onChange && onChange({ [name]: value })
    },
    [onChange],
  )

  return (
    <div className={cx('rich-text-input', { 'has-error': !!error })}>
      {label && (
        <label
          htmlFor={name}
          className={cx('label', { 'validation-error': !!error })}>
          {label}
        </label>
      )}
      <input type="hidden" name={name} value={hiddenValue} />
      <Editor
        apiKey={window.tinymceApiKey}
        onInit={handleEditorInit}
        initialValue={initialValue}
        init={{
          height: 500,
          menubar: false,
          browser_spellcheck: true,
          elementpath: false,
          plugins: ['lists', 'link', 'charmap'],
          toolbar:
            'undo redo | blocks | ' +
            'bold italic | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'link removeformat | help',
          link_title: false,
          content_css: contentCssUrl,
          content_css_cors: true,
          body_class: 'mindr-rich-content',
          link_default_target: '_blank',
          relative_url: false,
          remove_script_host: true,
          convert_urls: false,
        }}
        onEditorChange={handleValueChange}
      />
      {!!error && <span className="validation-error-message">{error}</span>}
    </div>
  )
}

export default RichTextInput
