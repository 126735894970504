import { useMemo, useState } from 'react'
import { Paragraph } from 'components/Typography'
import { css } from '@emotion/react'
import GuideWizard from 'components/GuideWizard'
import { colors, spacings } from 'stylesheets/theme'
import { IGuideProps, TStepMode } from './types'
import { TTStepsFunction } from 'components/GuideWizard/GuideWizard'
import getStepsFunction from './steps'

const containerWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: spacings.grid_gap_basis,
})

const wizardStyle = css({
  '.panel-header': {
    marginBottom: spacings.grid_gap_basis_num * 5,
    alignItems: 'start',
  },
  '.panel': {
    maxWidth: 1025,

    h5: {
      fontSize: 15,
      lineHeight: '25px',
      color: colors.text.text_5,
    },
  },
})

export default function SlackGuide(options: IGuideProps): JSX.Element {
  const { connector, isCohort = false } = options

  const [stepsMode, setStepsMode] = useState<TStepMode>(
    connector ? 'returning' : 'new',
  )

  const stepsFunction = useMemo<TTStepsFunction>(
    () => getStepsFunction(options, stepsMode, setStepsMode),
    [options, stepsMode, setStepsMode],
  )

  const selectedStepIndex = useMemo(() => (connector ? -1 : 0), [connector])

  return (
    <div>
      <div
        css={[
          containerWrapperStyle,
          css({
            paddingBottom: spacings.grid_gap_basis_num * 2,
          }),
        ]}>
        <h2>Slack Integration</h2>
      </div>
      <div css={containerWrapperStyle}>
        <h3>
          A step by step guide on how to integrate Slack with Mindr Connect
        </h3>
        {isCohort ? (
          <Paragraph>
            Automatically post your cohort&apos;s new events and calls to action
            in your Slack channel.
          </Paragraph>
        ) : (
          <Paragraph>
            Automatically post your community&apos;s new events and calls to
            action in your Slack channel.
          </Paragraph>
        )}
        <GuideWizard
          mode={stepsMode}
          stepsFunction={stepsFunction}
          selectedStepIndex={selectedStepIndex}
          extraStepsForHeight={stepsMode === 'returning' ? 5 : 0}
          css={wizardStyle}
          allowDisabledFutureSteps={true}
        />
      </div>
    </div>
  )
}
