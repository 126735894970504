import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'

import Button, { Variant, ButtonComponentType } from 'components/Button'
import Table, { ITableProps } from 'components/Table'

import PlusIcon from 'images/icons/plus.svg'
import noAgendaImg from 'images/run_of_show_empty_state.png'

interface MeetingAgendaTableProps extends ITableProps {
  addAgendaUrl: string
}

const emptyActionNodeIconStyle = css({
  marginRight: spacings.grid_gap_basis_num / 2,
})

const agendaTableStyle = css({
  'td:first-child .GojiCustomTable__cell-value': {
    fontWeight: 'bold',
    fontSize: '15px',
  },
})

/**
 * Shows card with registrants table
 * If there are no registrants, show empty state with invite users button to show modal
 */
export default function MeetingAgendaTable({
  addAgendaUrl,
  ...props
}: MeetingAgendaTableProps): JSX.Element {
  return (
    <Table
      {...props}
      css={agendaTableStyle}
      emptyStateImgUrl={noAgendaImg}
      emptyStateActionNode={
        <Button
          variant={Variant.SECONDARY}
          small
          as={ButtonComponentType.LINK}
          href={addAgendaUrl}
          startIcon={<PlusIcon css={emptyActionNodeIconStyle} />}>
          Add Agenda
        </Button>
      }
      emptyStateChildren={
        <p css={{ fontWeight: 'normal' }}>
          Highlight the event&apos;s schedule by adding an Agenda
        </p>
      }
    />
  )
}
