import { useContext } from 'react'
import Container from 'components/Container'
import Select, { IOption } from 'components/Inputs/Select'
import Input from 'components/Input/Input'
import ApplicationFormFieldContainer from './ApplicationFormFieldContainer'
import ApplicationStepWrapper, {
  IFieldValidation,
} from './ApplicationStepWrapper'
import ApplicationFormContext from './ApplicationFormContext'

const areas = [
  'Finance & Accounting',
  'Administrative support',
  'Business strategy',
  'Computer science and programming',
  'Customer support',
  'Human resources',
  'Information technology',
  'Legal and compliance',
  'Marketing and communications',
  'Manufacturing and distribution',
  'Design',
  'Sales and business development',
  'Scientific research',
  'Operations',
  'Leadership & management',
  'Project management',
  'Investment',
  'DEI',
  'Philanthropy',
]

const years = ['0-2', '3-5', '6-10', '11-15', '16-20', '20+']

const dropdownStyle = {
  control: {
    width: '350px',
  },
  menu: {
    width: '350px',
  },
}

const mobileDropdownStyle = {
  control: {
    maxWidth: '350px',
  },
  menu: {
    maxWidth: '350px',
  },
}

type TExpertiseOption = IOption & {
  expertise: string
}

export default function EssentialDetails(): JSX.Element {
  const { formData, getErrors, isMobile } = useContext(ApplicationFormContext)
  const errors = getErrors()
  const validations: IFieldValidation[] = []
  validations.push({
    fieldName: 'linkedin-url',
    fieldDisplayName: 'LinkedIn Profile URL',
    pattern:
      '^(|(?:https?://)?((?:[a-z0-9][\\-a-z0-9]*?)(?:\\.[a-z0-9][\\-a-z0-9]*?)+?)(/.*?)?)?$',
    patterFailureMessage: 'Please use a valid LinkedIn Profile URL.',
    prefilter: (value: string): string => {
      if (!value) {
        return value
      }

      const regex = new RegExp(
        /^(?:https?:\/\/)?((?:[a-z0-9][\-a-z0-9]*?)(?:\.[a-z0-9][\-a-z0-9]*?)+?)(\/.*?)?$/i,
      )
      const matches = regex.exec(value)
      const out = matches ? `https://${matches[1]}${matches[2]}` : value

      return out
    },
  })
  return (
    <ApplicationStepWrapper
      title="We'd like to gather some essential details about you"
      validations={validations}
      requiredFields={['expertise', 'industry-years', 'org-years']}>
      <Container direction="column" css={{ alignItems: 'flex-start' }}>
        <ApplicationFormFieldContainer
          label="What is your top area of professional expertise?"
          fieldId="expertise">
          <Select<TExpertiseOption>
            id="expertise"
            name="expertise"
            placeholder="Select area"
            defaultValue={formData['expertise'] || null}
            options={areas.sort().map((area) => ({
              label: area,
              value: area,
              expertise: area,
            }))}
            styleOverrides={isMobile ? mobileDropdownStyle : dropdownStyle}
            isSearchable={!isMobile}
          />
        </ApplicationFormFieldContainer>
        <ApplicationFormFieldContainer
          label="How many years of experience do you have?"
          fieldId="industry-years">
          <Select<TExpertiseOption>
            id="industry-years"
            name="industry-years"
            placeholder="Select year"
            defaultValue={formData['industry-years'] || null}
            options={years.map((years_experience) => ({
              label: years_experience,
              value: years_experience,
              expertise: years_experience,
            }))}
            styleOverrides={isMobile ? mobileDropdownStyle : dropdownStyle}
            isSearchable={!isMobile}
          />
        </ApplicationFormFieldContainer>
        <ApplicationFormFieldContainer
          label="How many years have you been a part of this organization?"
          fieldId="org-years">
          <Select<TExpertiseOption>
            id="org-years"
            name="org-years"
            placeholder="Select year"
            defaultValue={formData['org-years'] || null}
            options={years.map((years_experience) => ({
              label: years_experience,
              value: years_experience,
              expertise: years_experience,
            }))}
            styleOverrides={isMobile ? mobileDropdownStyle : dropdownStyle}
            isSearchable={!isMobile}
          />
        </ApplicationFormFieldContainer>
        <ApplicationFormFieldContainer
          label="Please share your LinkedIn profile"
          description="This is optional, but it can help us gain a deeper understanding of
          your professional background."
          fieldId="linkedin-url">
          <Input
            type="text"
            id="linkedin-url"
            name="linkedin-url"
            error={(errors['linkedin-url'] || []).join(' ')}
            defaultValue={formData['linkedin-url'] || null}
          />
        </ApplicationFormFieldContainer>
      </Container>
    </ApplicationStepWrapper>
  )
}
