import type { ReactElement } from 'react'
import cx from 'classnames'
import { TPillSize } from 'types'

export enum PillColor {
  GREY = 'grey',
  BLUE = 'blue',
  GREEN = 'green',
  PURPLE = 'purple',
  RED = 'red',
  YELLOW = 'yellow',
  LIGHT_YELLOW = 'light-yellow',
}

export interface IPill {
  /**
   * The size of the pill, i.e. 'small', 'large'
   */
  size: TPillSize
  className?: string | undefined
  border?: boolean
  color?: PillColor
  dark?: boolean // TODO: Add dark variant for pills
  light?: boolean // TODO: Add light variant for pills
  textTransform?: boolean // TODO: Add variant for non text-transformed pills
  bold?: boolean // TODO: Add variant for bold pills
  children: React.ReactNode
}

const defaultProps: Partial<IPill> = {
  className: undefined,
  border: false,
  color: PillColor.GREY,
  dark: false,
  light: false,
  textTransform: true,
  bold: true,
}

// TODO: rewrite this component with emotion CSS
const Pill = (props: IPill): ReactElement => {
  const {
    size,
    className,
    border,
    dark,
    light,
    textTransform,
    bold,
    color,
    children,
  } = {
    ...defaultProps,
    ...props,
  }

  return (
    <span
      className={cx(`pill pill-size-${size} pill-color-${color}`, className, {
        'pill-with-border': border,
        'pill-dark': dark,
        'pill-light': light,
        'pill-text-transform': textTransform,
        'pill-bold': bold,
      })}>
      {children}
    </span>
  )
}

export default Pill
