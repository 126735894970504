import { css } from '@emotion/react'
import Button, { ButtonComponentType, Variant } from 'components/Button/Button'
import Container from 'components/Container/Container'
import { colors, spacings } from 'stylesheets/theme'
import MentorshipApplicationWrapper from './MentorshipApplicationWrapper'
import { useContext } from 'react'
import ApplicationFormContext from './ApplicationFormContext'

const defaultParagraphStyle = css({
  color: colors.text.text_5,
  textAlign: 'center',
  fontSize: 15,
  fontStyle: 'normal',
  fontWeight: 400,
})

const mobileParagraphStyle = css({
  textAlign: 'left',
})

const defaultContainerStyle = css({
  width: 1052,
  gap: spacings.grid_gap_basis_num * 2,
})

const mobileContainerStyle = css({
  width: 'auto',
  alignItems: 'flex-start',
})

const mobileWrapperStyle = css({
  justifyContent: 'center',
})

interface ThankYouPageProp {
  alreadySubmitted?: boolean
  homepageUrl: string
}

export default function ThankYouPage({
  alreadySubmitted = false,
  homepageUrl,
}: ThankYouPageProp): JSX.Element {
  const { isMobile } = useContext(ApplicationFormContext)
  const paragraphStyle = [defaultParagraphStyle]
  const containerStyle = [defaultContainerStyle]
  if (isMobile) {
    paragraphStyle.push(mobileParagraphStyle)
    containerStyle.push(mobileContainerStyle)
  }
  return (
    <MentorshipApplicationWrapper css={isMobile ? mobileWrapperStyle : null}>
      <Container direction="column" css={containerStyle}>
        <h2>
          {alreadySubmitted
            ? 'You’ve already submitted a Mindr Mentorship Exchange form'
            : 'Thank you for applying for the Mindr Mentorship Exchange!'}
        </h2>
        <p css={paragraphStyle}>
          {alreadySubmitted
            ? 'Thank you for applying for the Mindr Mentorship Exchange!'
            : ''}{' '}
          You can expect to receive an update on your application status
          approximately six weeks after the application deadline. We appreciate
          your patience as we ensure a thorough and thoughtful matching and
          internal approval process.
        </p>
        <p css={paragraphStyle}>
          If you have any questions or would like to share any additional
          information, please reach out to{' '}
          <Button
            css={{ display: 'inline-block' }}
            href="mailto:mentorship@mindrglobal.com"
            type={ButtonComponentType.LINK}
            variant={Variant.LINK}>
            mentorship@mindrglobal.com
          </Button>
          .
        </p>
        <Button
          as={ButtonComponentType.LINK}
          variant={Variant.PRIMARY}
          href={homepageUrl}
          css={{
            marginTop: spacings.grid_gap_basis,
          }}>
          go to homepage
        </Button>
      </Container>
    </MentorshipApplicationWrapper>
  )
}
