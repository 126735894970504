import Card from 'components/Card'
import Container from 'components/Container'
import React, { useMemo } from 'react'
import { IProgramNextStep } from 'types'
import CalendarIcon from 'images/icons/calendar.svg'
import FileIcon from 'images/icons/file.svg'
import MessageIcon from 'images/icons/messages.svg'
import SessionAttendingIcon from 'images/icons/session_attending.svg'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import Button, { ButtonComponentType, Variant } from 'components/Button'

interface INextStepsCardProps {
  nextSteps: IProgramNextStep[]
  cohort_url: string
}

const iconStyle = css({
  width: 18,
  height: 18,
  fill: colors.pink,
  path: {
    fill: colors.pink,
  },
})

const iconCircleStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: 40,
  minHeight: 40,
  backgroundColor: colors.purple_light,
  marginRight: spacings.grid_gap_basis,
  borderRadius: '50%',
})

const actionStyle = css({
  justifyContent: 'flex-end',
  paddingTop: spacings.grid_gap_basis_num * 2,
})

const mobileActionStyle = css({
  justifyContent: 'center',
})

const iconNextToStepCss = css({
  alignItems: 'start',
})

const IconsMap = {
  calendar: <CalendarIcon css={iconStyle} />,
  file: <FileIcon css={iconStyle} />,
  message: <MessageIcon css={iconStyle} />,
  session_attending: <SessionAttendingIcon css={iconStyle} />,
}

const NextStepsCard: React.FC<INextStepsCardProps> = ({
  nextSteps,
  cohort_url,
}) => {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const actionStyleToUse = useMemo(
    () => (isMobile ? [actionStyle, mobileActionStyle] : actionStyle),
    [isMobile],
  )
  return (
    <Card title="Next steps">
      <>
        <Container
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: spacings.grid_gap_basis_num * 2,
          }}>
          {nextSteps.map((step, index) => (
            <Container key={index} css={iconNextToStepCss}>
              <div css={iconCircleStyle}>
                {IconsMap[step.icon]}
                {/* <img src={IconsMap[step.icon]} /> */}
              </div>
              <div dangerouslySetInnerHTML={{ __html: step.description }}></div>
            </Container>
          ))}
        </Container>
        {cohort_url ? (
          <Container css={actionStyleToUse}>
            <Button
              as={ButtonComponentType.LINK}
              variant={Variant.PRIMARY}
              href={cohort_url}>
              View Cohort
            </Button>
          </Container>
        ) : null}
      </>
    </Card>
  )
}

export default NextStepsCard
