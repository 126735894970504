import { useEffect, useState } from 'react'

interface IWindowWidthData {
  windowWidth: number
  isDesiredWidth: boolean
}

type TOrientation = 'unknown' | 'portrait' | 'landscape'

interface IUseMediaQueryProps {
  minWidth?: number
  maxWidth?: number
  minHeight?: number
  maxHeight?: number
  orientation?: TOrientation
}

interface IMediaQueryCurrentState {
  width: number
  height: number
  orientation: TOrientation
}

interface IMediaQueryMeets {
  meetsAll: boolean
  meetsDetails: {
    minWidth: boolean
    maxWidth: boolean
    minHeight: boolean
    maxHeight: boolean
    orientation: boolean
  }
  valueDetails: IMediaQueryCurrentState
}

function getCurrentTOrientation() {
  switch (screen.orientation.type) {
    default:
    case 'landscape-secondary':
    case 'landscape-primary':
      return 'landscape'

    case 'portrait-secondary':
    case 'portrait-primary':
      return 'portrait'
  }
}

export function useComplexMediaQuery(
  query: IUseMediaQueryProps,
): IMediaQueryMeets {
  const [currentState, setCurrentState] = useState<IMediaQueryCurrentState>({
    width: window.outerWidth,
    height: window.outerHeight,
    orientation: getCurrentTOrientation(),
  })

  useEffect(() => {
    const onResize = () => {
      setCurrentState({
        width: window.outerWidth,
        height: window.outerHeight,
        orientation: getCurrentTOrientation(),
      })
    }
    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [setCurrentState, window])

  const result: IMediaQueryMeets = {
    meetsAll: false,
    meetsDetails: {
      minWidth:
        query.minWidth === undefined || currentState.width >= query.minWidth,
      maxWidth:
        query.maxWidth === undefined || currentState.width <= query.maxWidth,
      minHeight:
        query.minHeight === undefined || currentState.height >= query.minHeight,
      maxHeight:
        query.maxHeight === undefined || currentState.height <= query.maxHeight,
      orientation:
        query.orientation === undefined ||
        currentState.orientation === query.orientation,
    },
    valueDetails: currentState,
  }
  result.meetsAll =
    result.meetsDetails.minWidth &&
    result.meetsDetails.maxWidth &&
    result.meetsDetails.minHeight &&
    result.meetsDetails.maxHeight &&
    result.meetsDetails.orientation

  return result
}

export default function useMediaQuery(
  maxWidth: number,
  defaultIsMobile?: boolean,
): IWindowWidthData {
  const rawResult = useComplexMediaQuery({ maxWidth: maxWidth })

  return {
    windowWidth: rawResult.valueDetails.width,
    isDesiredWidth: defaultIsMobile || rawResult.meetsDetails.maxWidth,
  }
}
