import { useMemo } from 'react'
import { TPanelNumber } from './types'

interface ITwoStatePanelProps {
  currentPanel: TPanelNumber
  panel1Content: string | React.ReactNode
  panel2Content: string | React.ReactNode
}

export default function TwoStatePanel({
  currentPanel,
  panel1Content,
  panel2Content,
}: ITwoStatePanelProps): JSX.Element {
  const panel1 = useMemo(() => {
    return <div className="two-panels panel-1">{panel1Content}</div>
  }, [panel1Content])

  const panel2 = useMemo(() => {
    return <div className="two-panels panel-2">{panel2Content}</div>
  }, [panel2Content])

  let panel = null
  if (currentPanel === 'one') {
    panel = panel1
  } else if (currentPanel === 'two') {
    panel = panel2
  }

  return <div>{panel}</div>
}
