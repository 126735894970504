import React from 'react'
import cx from 'classnames'

import ConfirmationModal from 'components/ConfirmationModal'
import ShowBadgeModal from 'components/ShowBadgeModal'
import EventCallButton from 'components/EventCallButton'

import CompleteConfirmationModal from '../CompleteConfirmationModal'

import { useCallActionContext, ModalType } from '../context'

import './CompleteCallButton.scss'

import type { IEventCall, TActionLocation } from 'types'

type CompleteCallButtonProps = {
  call: IEventCall
  location: TActionLocation
  className?: string
}

const CompleteCalButton: React.FC<CompleteCallButtonProps> = ({
  call,
  location,
  className = '',
}) => {
  const {
    completed,
    newBadges,
    modalToShow,
    onMarkComplete,
    onMarkIncomplete,
    onConfirmMarkIncomplete,
    onCancelMarkIncomplete,
    onInvite,
    onCloseConfirmation,
    onCloseBadgeModal,
  } = useCallActionContext()

  const completedEl = () => {
    if (location === 'banner') {
      return (
        <button
          className="call-complete-button button primary"
          onClick={onMarkIncomplete}
          css={{
            width: '100%',
            whiteSpace: 'nowrap',
          }}>
          Mark as Incomplete
        </button>
      )
    } else if (location === 'table-cell') {
      return (
        <a href={call.link} className="button-label">
          Completed
        </a>
      )
    }

    return <span className="button-label">Completed</span>
  }

  return (
    <>
      <ShowBadgeModal
        isOpen={modalToShow === ModalType.badges}
        badgeName={newBadges[0]}
        onClose={onCloseBadgeModal}
        achieved
        seeMore
      />
      {completed ? (
        <ConfirmationModal
          isOpen={modalToShow === ModalType.markIncompleteConfirmation}
          title="Are you sure you want to mark this call to action as incomplete?"
          onConfirm={onConfirmMarkIncomplete}
          onCancel={onCancelMarkIncomplete}>
          If you do so, you will lose any badge progress associated with
          completion of this call to action.
        </ConfirmationModal>
      ) : (
        <CompleteConfirmationModal
          isOpen={modalToShow === ModalType.markCompleteConfirmation}
          call={call}
          onSubmit={onInvite}
          onClose={onCloseConfirmation}
        />
      )}
      <div
        className={cx('call-complete-action', {
          subscribed: completed,
          [className]: !!className,
        })}>
        {completed && completedEl()}
        {!completed && (
          <EventCallButton
            type="call"
            location={location}
            onClick={onMarkComplete}
            css={{
              width: '100%',
              whiteSpace: 'nowrap',
            }}
          />
        )}
      </div>
    </>
  )
}

export default CompleteCalButton
