import Button, { Variant } from 'components/Button'
import Container from 'components/Container'
import { useCallback, useContext } from 'react'
import ApplicationFormContext from './ApplicationFormContext'
import CircleIcon from 'images/icons/circle.svg'
import Circle1Icon from 'images/icons/circle_1.svg'
import Circle2Icon from 'images/icons/circle_2.svg'
import Circle3Icon from 'images/icons/circle_3.svg'
import Circle4Icon from 'images/icons/circle_4.svg'
import Circle5Icon from 'images/icons/circle_5.svg'
import { css } from '@emotion/react'
import { Paragraph } from 'components/Typography'
import { colors, spacings } from 'stylesheets/theme'

interface IMobileLeadrComponentProps {
  characteristics: any[]
  setCharacteristics: (characteristics: any[]) => void
}

interface IIndexCircleProps {
  index?: number | null
}

const circleStyle = css({
  width: 24,
  height: 30,
})

function IndexCircle({ index }: IIndexCircleProps): JSX.Element {
  if (!index) {
    return <CircleIcon css={circleStyle} />
  }

  switch (index) {
    case 1:
      return <Circle1Icon css={circleStyle} />
    case 2:
      return <Circle2Icon css={circleStyle} />
    case 3:
      return <Circle3Icon css={circleStyle} />
    case 4:
      return <Circle4Icon css={circleStyle} />
    case 5:
      return <Circle5Icon css={circleStyle} />
    default:
      return <CircleIcon css={circleStyle} />
  }
}

export default function MobileLeadrComponent({
  characteristics,
  setCharacteristics,
}: IMobileLeadrComponentProps): JSX.Element {
  const { leadrCharacteristics } = useContext(ApplicationFormContext)
  const onCardClick = useCallback(
    (curr_characteristic) => {
      const index = characteristics.findIndex(
        (ranked_characteristic) =>
          ranked_characteristic.id == curr_characteristic.id,
      )

      const newCharacteristics = [...characteristics]
      //If an index is found, that means we are removing this characteristic from ordered list
      if (index >= 0) {
        newCharacteristics.splice(index, 1)
      } else {
        newCharacteristics.push(curr_characteristic)
      }
      setCharacteristics(newCharacteristics)
    },
    [characteristics, setCharacteristics],
  )
  return (
    <Container
      css={{
        flexDirection: 'column',
        marginTop: spacings.grid_gap_basis_num * 2,
        marginBottom: spacings.grid_gap_basis_num * 2,
      }}>
      {leadrCharacteristics.map((leadrCharacteristic) => {
        //What is the rank of the characteristic?
        const index = characteristics.findIndex(
          (ranked_characteristic) =>
            ranked_characteristic.id == leadrCharacteristic.id,
        )
        return (
          <Button
            type="Button"
            variant={Variant.UNSTYLED}
            key={leadrCharacteristic.name}
            onClick={() => onCardClick(leadrCharacteristic)}
            css={{
              width: '100%',
              minHeight: 125,
              borderRadius: '6px',
              border: `1px solid ${colors.borders.gray}`,
            }}>
            <Container
              css={{
                alignItems: 'flex-start',
                padding: spacings.grid_gap_basis,
              }}>
              <IndexCircle index={index + 1} />
              <Paragraph
                css={{
                  fontWeight: 'normal',
                  flex: '50%',
                }}>
                <b
                  css={{
                    textTransform: 'capitalize',
                  }}>
                  {leadrCharacteristic.name + ': '}
                </b>
                {leadrCharacteristic.description}
              </Paragraph>
            </Container>
          </Button>
        )
      })}
    </Container>
  )
}
