import { useCallback, useState } from 'react'

import Button, { Variant } from 'components/Button'
import ChangeLogsModal from './ChangeLogsModal'

interface ViewChangeLogButtonProps {
  changeLogPath: string
}

export default function ViewChangeLogButton({
  changeLogPath,
}: ViewChangeLogButtonProps): JSX.Element {
  const [showModal, setShowModal] = useState(false)

  const onShow = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])
  const onClose = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  return (
    <>
      {showModal && <ChangeLogsModal url={changeLogPath} onClose={onClose} />}
      <Button variant={Variant.UNSTYLED} onClick={onShow} className="menu-link">
        View Change Log
      </Button>
    </>
  )
}
