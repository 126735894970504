import { useContext } from 'react'
import cx from 'classnames'
import NavbarContext, { contextTypes, ContextType } from '../NavbarContext'
import EventsIcon from 'images/icons/events.svg'
import Call14Icon from 'images/icons/call_14.svg'
import MeetingsIcon from 'images/icons/meetings.svg'
import ReportsIcon from 'images/icons/reports.svg'
import SubscribersIcon from 'images/icons/subscribers.svg'
import LeadershipIcon from 'images/icons/leadership.svg'
import ParticipantsIcon from 'images/icons/participants.svg'
import WorldIcon from 'images/icons/world.svg'
import OrganizationIcon from 'images/icons/organization.svg'
import UserProvisionIcon from 'images/icons/user_provisioning.svg'
import UserManagementIcon from 'images/icons/user_management.svg'
import KeyIcon from 'images/icons/key.svg'
import Cohort18Icon from 'images/icons/cohort_18.svg'
import Cohort14Icon from 'images/icons/cohort_14.svg'
import MyInformationIcon from 'images/icons/my_information.svg'
import MySubscriptionIcon from 'images/icons/my_subscriptions.svg'
import UpcomingActivitiesIcon from 'images/icons/upcoming_activities.svg'
import ClockIcon from 'images/icons/clock.svg'
import BadgesIcon from 'images/icons/badges.svg'
import PeopleIcon from 'images/icons/people.svg'
import MsftIcon from 'images/icons/msft_teams.svg'
import SlackIcon from 'images/icons/slack.svg'
import MentorshipDetailsIcon from 'images/icons/mentorship_details.svg'
import LeadrIndex from 'images/icons/leadr_index.svg'
import LeadrManagement from 'images/icons/leadr_management.svg'
import { communityTypes, TCommunityType } from 'types'
import './Nav.scss'
import NavButton from './NavButton'

const iconsMap = {
  events: { icon: EventsIcon, stroke: true },
  call_14: { icon: Call14Icon, stroke: true },
  meetings: { icon: MeetingsIcon, stroke: false },
  participants: { icon: ParticipantsIcon, stroke: true },
  reports: { icon: ReportsIcon, stroke: false },
  subscribers: { icon: SubscribersIcon, stroke: true },
  leadership: { icon: LeadershipIcon, stroke: false },
  world: { icon: WorldIcon, stroke: false },
  organization: { icon: OrganizationIcon, stroke: false },
  user_provisioning: { icon: UserProvisionIcon, stroke: true },
  user_management: { icon: UserManagementIcon, stroke: true },
  key: { icon: KeyIcon, stroke: true },
  cohort_14: { icon: Cohort14Icon, stroke: true },
  cohort_18: { icon: Cohort18Icon, stroke: true },
  my_information: { icon: MyInformationIcon, stroke: true },
  my_subscriptions: { icon: MySubscriptionIcon, stroke: true },
  upcoming_activities: { icon: UpcomingActivitiesIcon, stroke: false },
  clock: { icon: ClockIcon, stroke: false },
  badges: { icon: BadgesIcon, stroke: true },
  people: { icon: PeopleIcon, stroke: false },
  msft_teams: { icon: MsftIcon, stroke: false },
  slack: { icon: SlackIcon, stroke: false },
  mentorship_details: { icon: MentorshipDetailsIcon, stroke: true },
  assessment: { icon: LeadrIndex, stroke: false },
  management: { icon: LeadrManagement, stroke: false },
}

/**
 * Returns true if nav item should be in active state
 * based on current controller name and community type
 */
function isActive(
  currentControllerName: string,
  itemKey: string,
  communityType: TCommunityType,
  alternative_controller_name?: string,
): boolean {
  let key = itemKey
  if (currentControllerName !== key) {
    const keys = itemKey.split('_')
    if (keys.length > 1) {
      key = keys[0]
      if (communityTypes.includes(keys[1] as TCommunityType)) {
        const type = keys[1]
        return currentControllerName == key && communityType == type
      }
    } else if (!!alternative_controller_name) {
      return (
        currentControllerName == key ||
        currentControllerName == alternative_controller_name
      )
    }
    return false
  }

  return true
}

const collapsedSectionKeys = {
  'community management': 'community',
  'my org': 'org',
  'admin tools': 'admin',
  'my profile': 'profile',
  'cohort activities': 'activities',
  'cohort management': 'cohort',
}

/**
 * Return the section key shown in the nav bar when it is collapsed
 */
function getCollapsedSectionKey(sectionKey: string) {
  if (sectionKey.split(' ').length > 1) {
    return collapsedSectionKeys[sectionKey]
  }

  return sectionKey
}

/**
 * A list of nav items that live inside a navbar
 */
export default function Nav(): JSX.Element {
  const { collapsed, navItems, controllerName, contextType, communityType } =
    useContext(NavbarContext)
  return (
    <nav className="nav">
      {Object.keys(navItems[contextType]).map((sectionKey) => {
        const items = navItems[contextType][sectionKey]
        return (
          <section className={cx({ collapsed: collapsed })} key={sectionKey}>
            {contextTypes.includes(contextType as ContextType) && (
              <p className={cx('caption', { collapsed: collapsed })}>
                <span className="when-collapsed anim-opacity">
                  {getCollapsedSectionKey(sectionKey.toLowerCase())}
                </span>
                <span className="when-not-collapsed anim-opacity">
                  {sectionKey}
                </span>
              </p>
            )}
            <ul>
              {Object.keys(items).map((itemKey) => {
                const {
                  icon: icon_name,
                  path,
                  alternative_controller_name,
                  name,
                } = items[itemKey]
                const IconSvg = iconsMap[icon_name]?.icon
                const stroke = iconsMap[icon_name]?.stroke || false
                return (
                  <li className={cx({ 'icon-stroke': stroke })} key={name}>
                    <NavButton
                      href={path}
                      icon={!!IconSvg && <IconSvg />}
                      title={name}
                      isActive={isActive(
                        controllerName,
                        itemKey,
                        communityType,
                        alternative_controller_name,
                      )}
                      collapsed={collapsed}
                    />
                  </li>
                )
              })}
            </ul>
          </section>
        )
      })}
    </nav>
  )
}
