import { useCallback, useMemo, useState } from 'react'
import { css } from '@emotion/react'
import FormFieldContainer from 'components/Forms/FormFieldContainer'
import Input from 'components/Input/Input'
import Button, { Variant } from 'components/Button'
import { colors, spacings } from 'stylesheets/theme'
import { Connector } from 'types'
import Modal, { Size } from 'components/Modal'
import SuccessIcon from 'images/icons/success.svg'
import connectingGif from 'images/teams_installation/connecting.gif'
import ConnectionFailedIcon from 'images/icons/connection_failed.svg'
import TrashIcon from 'images/icons/trash_red.svg'
import { Paragraph } from 'components/Typography'
import Axios from 'axios'
import Pill, { PillColor } from 'components/Pill/Pill'

const containerStyle = css({
  marginTop: spacings.grid_gap_basis_num * 4,
  display: 'flex',
  flexDirection: 'column',
  gap: spacings.grid_gap_basis_num * 12,
})

interface CreateConnectorStepProps {
  connector?: Connector | null
  updateConnectorUrl: string
  connectorsUrl: string
  removeConnectorUrl?: string
}

enum ConnectionStatus {
  Connecting,
  Successful,
  Failed,
}

enum ModalVisible {
  CONNECTION,
  REMOVE,
  NONE,
}

interface Connection {
  title: string
  image: React.ReactNode
  subtitle?: string
}

interface ConnectionModalContent {
  [status: string]: Connection
}

const gifStyle = css({
  objectFit: 'cover',
  width: '100%',
  height: 161,
  marginTop: spacings.grid_gap_basis_num * 5,
  marginBottom: spacings.grid_gap_basis_num * 5,
})

const modalStyle = css({
  '.mindr-modal-actions': {
    justifyContent: 'center',
  },
  '.mindr-modal-body': {
    marginTop: spacings.grid_gap_basis_num * 5,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
})

const modalContentStyle = css({
  width: 416,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 20,
})

const trashIconStyle = css({
  width: spacings.grid_gap_basis,
  height: spacings.grid_gap_basis,
  marginRight: spacings.grid_gap_basis_num / 2,
})

const removeButtonStyle = css({
  width: 'fit-content',
  color: colors.red,
})

export default function CreateConnectorStep({
  connector,
  updateConnectorUrl,
  connectorsUrl,
  removeConnectorUrl,
}: CreateConnectorStepProps): JSX.Element {
  const [modalVisible, setModalVisible] = useState<ModalVisible>(
    ModalVisible.NONE,
  )
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(
    ConnectionStatus.Connecting,
  )

  const connectionModalContent: ConnectionModalContent = useMemo(
    () => ({
      [ConnectionStatus.Connecting]: {
        title: connector ? 'Updating...' : 'Connecting...',
        image: <img src={connectingGif} css={gifStyle}></img>,
      },
      [ConnectionStatus.Successful]: {
        title: connector ? 'Updated Successfully!' : 'Integrated Successfully!',
        subtitle: connector
          ? "Success! You have updated the connector's name."
          : 'Please navigate back to your Teams channel where you will find a welcome post from your community.',
        image: (
          <SuccessIcon
            css={{
              width: 114,
              height: 114,
            }}
          />
        ),
      },
      [ConnectionStatus.Failed]: {
        title: 'Something went wrong',
        subtitle: connector
          ? 'Update unsuccessful. Please ensure the Name field is not empty, then try again.'
          : 'Integration unsuccessful. Please ensure the Webhook URL is correct and the fields are not empty, then try again.',
        image: (
          <ConnectionFailedIcon
            css={{
              width: 175,
              height: 130,
            }}
          />
        ),
      },
    }),
    [connector],
  )

  const onSaveConnector = useCallback(
    async (event) => {
      setConnectionStatus(ConnectionStatus.Connecting)
      event.preventDefault()
      setModalVisible(ModalVisible.CONNECTION)
      const form = window.document.getElementById('connector_form')
      const formData = new FormData(form as HTMLFormElement)
      formData.set('authenticity_token', window.authenticity_token)
      try {
        if (!connector) {
          await Axios.post(updateConnectorUrl + '.json', formData)
        } else {
          await Axios.patch(updateConnectorUrl + '.json', formData)
        }
        setConnectionStatus(ConnectionStatus.Successful)
      } catch (e) {
        setConnectionStatus(ConnectionStatus.Failed)
      }
    },
    [updateConnectorUrl],
  )

  const onRemove = useCallback(async () => {
    try {
      await Axios.delete(removeConnectorUrl + '.json', {
        headers: {
          'X-CSRF-Token': window.authenticity_token,
        },
      })
      window.location.assign(connectorsUrl)
    } catch (e) {
      window.flash('Something went wrong!', 'alert')
    }
  }, [removeConnectorUrl, connectorsUrl])

  return (
    <>
      <div css={containerStyle}>
        <form id="connector_form">
          <FormFieldContainer
            fieldId="webhook_url"
            label="Webhook URL"
            required
            css={{
              width: 594,
            }}
            statusNode={
              connector?.status ? (
                <Pill
                  size="large"
                  color={
                    connector.status === 'failed'
                      ? PillColor.RED
                      : PillColor.BLUE
                  }
                  css={{
                    height: 'fit-content',
                  }}>
                  {connector.status}
                </Pill>
              ) : undefined
            }>
            <Input
              type="text"
              id="webhook_url"
              readOnly={!!connector}
              name={
                !!connector
                  ? 'msft_teams_connector[masked_webhook_url]'
                  : 'msft_teams_connector[webhook_url]'
              }
              placeholder="Paste the webhook URL here"
              defaultValue={connector?.webhook_url}
            />
          </FormFieldContainer>
          <FormFieldContainer
            fieldId="label"
            label="Name"
            required
            css={{
              width: 594,
            }}>
            <Input
              type="text"
              id="label"
              name="msft_teams_connector[label]"
              placeholder="Teams or channel name"
              defaultValue={connector?.label}
            />
          </FormFieldContainer>
          <Button
            type="submit"
            variant={Variant.PRIMARY}
            css={{
              width: 124,
            }}
            onClick={onSaveConnector}>
            Save
          </Button>
        </form>
        {connector && (
          <Button
            variant={Variant.LINK}
            startIcon={<TrashIcon css={trashIconStyle} />}
            css={removeButtonStyle}
            onClick={() => setModalVisible(ModalVisible.REMOVE)}>
            Remove
          </Button>
        )}
      </div>
      <Modal
        isOpen={modalVisible === ModalVisible.CONNECTION}
        size={Size.small}
        title={connectionModalContent[connectionStatus].title}
        onRequestClose={() => setModalVisible(ModalVisible.NONE)}
        onSave={() => {
          if (connectionStatus === ConnectionStatus.Failed) {
            setModalVisible(ModalVisible.NONE)
          } else {
            window.location.assign(connectorsUrl)
          }
        }}
        largeTitle
        submitButton="ok"
        isSubmitDisabled={connectionStatus === ConnectionStatus.Connecting}
        css={modalStyle}>
        <div css={modalContentStyle}>
          {connectionModalContent[connectionStatus].image}
          {connectionModalContent[connectionStatus].subtitle && (
            <Paragraph
              bold
              css={{
                textAlign: 'center',
                color: colors.text.text_3,
              }}>
              {connectionModalContent[connectionStatus].subtitle}
            </Paragraph>
          )}
        </div>
      </Modal>
      {connector && (
        <Modal
          isOpen={modalVisible === ModalVisible.REMOVE}
          size={Size.large}
          title="Are you sure?"
          onRequestClose={() => setModalVisible(ModalVisible.NONE)}
          onSave={onRemove}
          largeTitle
          submitButton="remove connector"
          cancelButton="Never mind">
          <Paragraph
            css={{
              marginTop: spacings.grid_gap_basis_num * 2,
            }}>
            Removing this connector will unconfigure {connector.label}. This
            action cannot be undone. Are you sure you want to remove this
            connector?
          </Paragraph>
        </Modal>
      )}
    </>
  )
}
