import { css } from '@emotion/react'
import { sizes, colors, zIndex, Theme, spacings } from 'stylesheets/theme'
import React from 'react'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

const headerStyle = css({
  backgroundColor: colors.backgrounds.white,
  padding: `5px ${sizes.mainHorizontalPadding}px`,
  borderBottom: `1px solid ${colors.borders.gray}`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  zIndex: zIndex.headerNav,
  minHeight: sizes.headerHeight,
  height: sizes.headerHeight,
  maxWidth: '100vw',
})

const darkStyle = css({
  'h3, a': {
    color: colors.backgrounds.white,
    '&:hover': {
      color: colors.backgrounds.white,
      opacity: 0.7,
    },
    '&:active': {
      color: colors.backgrounds.white,
      opacity: 0.5,
    },
  },
})

const mobileStyle = css({
  padding: `5px ${spacings.grid_gap_basis_num * 2}px`,
})

interface HeaderNavContainerProps {
  children: React.ReactNode
  theme?: Theme
  className?: string
}

export default function HeaderNavContainer({
  children,
  theme = Theme.Light,
  className = '',
}: HeaderNavContainerProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const style = [headerStyle]
  if (theme === Theme.Dark) {
    style.push(darkStyle)
  }

  if (isMobile) {
    style.push(mobileStyle)
  }
  return (
    <div css={style} className={className}>
      {children}
    </div>
  )
}
