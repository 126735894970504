import Button, { Variant } from 'components/Button'
import Container from 'components/Container'
import { useCallback } from 'react'
import CircleIcon from 'images/icons/circle.svg'
import Circle1Icon from 'images/icons/circle_1.svg'
import Circle2Icon from 'images/icons/circle_2.svg'
import Circle3Icon from 'images/icons/circle_3.svg'
import Circle4Icon from 'images/icons/circle_4.svg'
import Circle5Icon from 'images/icons/circle_5.svg'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'

interface IMobileDragAndDropProps {
  defaultItems: any[]
  items: any[]
  setItems: (items: any[]) => void
  component: (index: number, item: any) => JSX.Element
}

interface IIndexCircleProps {
  index?: number | null
}

const circleStyle = css({
  minWidth: 24,
  minHeight: 30,
})

function IndexCircle({ index }: IIndexCircleProps): JSX.Element {
  if (!index) {
    return <CircleIcon css={circleStyle} />
  }

  switch (index) {
    case 1:
      return <Circle1Icon css={circleStyle} />
    case 2:
      return <Circle2Icon css={circleStyle} />
    case 3:
      return <Circle3Icon css={circleStyle} />
    case 4:
      return <Circle4Icon css={circleStyle} />
    case 5:
      return <Circle5Icon css={circleStyle} />
    default:
      return <CircleIcon css={circleStyle} />
  }
}

export default function MobileDragAndDropComponent({
  defaultItems = [],
  items: rankedItems,
  setItems: setRankedItems,
  component,
}: IMobileDragAndDropProps): JSX.Element {
  const onCardClick = useCallback(
    (curr_item) => {
      const index = rankedItems.findIndex((ranked_item) => {
        return JSON.stringify(ranked_item) == JSON.stringify(curr_item)
      })

      const newItems = [...rankedItems]
      //If an index is found, that means we are removing this item from ordered list
      if (index >= 0) {
        newItems.splice(index, 1)
      } else {
        newItems.push(curr_item)
      }
      setRankedItems(newItems)
    },
    [rankedItems, setRankedItems],
  )

  return (
    <Container
      css={{
        flexDirection: 'column',
        marginTop: spacings.grid_gap_basis_num * 2,
        marginBottom: spacings.grid_gap_basis_num * 2,
      }}>
      {defaultItems.map((curr_item, item_index) => {
        //What is the rank of the item?
        const rank = rankedItems.findIndex(
          (ranked_item) =>
            JSON.stringify(ranked_item) === JSON.stringify(curr_item),
        )

        return (
          <Button
            type="Button"
            variant={Variant.UNSTYLED}
            key={`item-${item_index}`}
            onClick={() => onCardClick(curr_item)}
            css={{
              width: '100%',
              minHeight: 125,
              borderRadius: '6px',
              border: `1px solid ${colors.borders.gray}`,
            }}>
            <Container
              css={{
                alignItems: 'flex-start',
                padding: spacings.grid_gap_basis,
              }}>
              <IndexCircle index={rank + 1} />
              {component(rank, curr_item)}
            </Container>
          </Button>
        )
      })}
    </Container>
  )
}
