import React, { ReactNode } from 'react'
import ReactPaginate from 'react-paginate'
import { useTableContext } from 'components/Table/TableContext'

interface PaginateWrapperProps {
  children: ReactNode
}

export default function PaginateWrapper({
  children,
}: PaginateWrapperProps): JSX.Element {
  const {
    tableData: { paginator },
    handlePaginate,
  } = useTableContext()
  return (
    <>
      {children}
      {paginator && paginator.total_pages > 1 && (
        <div className="GojiCustomTable-pagination">
          <ReactPaginate
            previousLabel=""
            nextLabel=""
            breakLabel="..."
            breakClassName="break-me"
            pageCount={paginator.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePaginate}
            containerClassName="pagination"
            activeClassName="active"
            forcePage={paginator.current_page - 1}
          />
        </div>
      )}
    </>
  )
}
