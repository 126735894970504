import { css } from '@emotion/react'
import { colors, spacings, zIndex, Theme } from 'stylesheets/theme'
import Button, { Variant } from 'components/Button'
import { HexColorPicker } from 'react-colorful'
import { useState, useRef, useEffect, useContext } from 'react'
import { isHex } from './themeHelper'
import BrandingContext from './BrandingContext'

const colorPickerBoxStyle = css({
  display: 'flex',
  alignItems: 'center',
  border: `1px solid ${colors.borders.gray}`,
  width: 275,
  height: 39,
  padding: spacings.grid_gap_basis,
})

const prefixStyle = css({
  padding: '10px 15px',
  fontSize: 14,
  lineHeight: 20,
  color: colors.text.text_3,
  border: 'none',
  width: '100%',
  boxShadow: 'none',
  backgroundColor: colors.backgrounds.white,
  fontFamily: `'Lato', sans-serif`,
  height: 35,
})

const pickerButtonStyle = css({
  border: `1px solid ${colors.borders.gray}`,
  width: 27,
  height: 27,
})

const hashStyle = css({
  fontWeight: 700,
  lineHeight: '18px',
  fontFamily: 'Lato',
  fontSize: 13,
  color: colors.text.text_5,
})

const colorPickerWrapper = css({
  display: 'flex',
  gap: spacings.grid_gap_basis,
  position: 'absolute',
  zIndex: zIndex.colorPicker,
})

const ACCESSIBILITY_CHECK_ERROR =
  'This color will not pass accessibility contrast standards when text is overlayed, please pick another color'
const INVALID_HEX_ERROR =
  'This color is not a valid HEX color code, please pick another color from the color picker or input a valid HEX color code'

export default function ColorPicker(): JSX.Element {
  const {
    brandColor,
    setBrandColor,
    color,
    setColor,
    theme,
    setTheme,
    currentTheme,
    findTheme,
    setCurrentTheme,
  } = useContext(BrandingContext)
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)
  const inputRef = useRef(null)
  const colorButtonRef = useRef<HTMLButtonElement>(null)

  /**
   * Handles editing color input or using color picker.
   * When user is editing color, then clicks or focus off of the input or color picker,
   * the color is evaluated for contrast, and if it is a valid HEX color code.
   * If the color is not a valid HEX code, an alert will be shown, color is reverted back to the last valid color.
   * If the color does not pass accessibility check, an alert will be shown, color is reverted back to the last valid color.
   * Otherwise, brand color in the context is set, and when user clicks on save, the color will be persisted to the DB.
   */
  useEffect(() => {
    function handleClickOrFocusOutside(event) {
      if (
        (inputRef.current || wrapperRef.current) &&
        // not validating when user clicks or focus on input
        !inputRef.current.contains(event.target) &&
        // not validing when user clicks or focus on the color picker
        !wrapperRef.current?.contains(event.target) &&
        // ensure that we are not validating when user clicks or focus on colorpicker button
        !colorButtonRef.current?.contains(event.target)
      ) {
        if (!isHex(color)) {
          setColor(brandColor) //Revert color back to the last viable color
          window.flash(INVALID_HEX_ERROR, 'alert')
        } else if (currentTheme === Theme.Unknown) {
          setColor(brandColor) //Revert color back to the last viable color
          window.flash(ACCESSIBILITY_CHECK_ERROR, 'alert')
        } else {
          setBrandColor(color)
        }
        setOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOrFocusOutside)
    if (!!document.forms.length) {
      document.forms[0].addEventListener(
        'focus',
        handleClickOrFocusOutside,
        true,
      )
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOrFocusOutside)
      if (!!document.forms.length) {
        document.forms[0].removeEventListener(
          'focus',
          handleClickOrFocusOutside,
          true,
        )
      }
    }
  }, [
    inputRef,
    wrapperRef,
    colorButtonRef,
    color,
    brandColor,
    setOpen,
    setTheme,
    setBrandColor,
    currentTheme,
  ])

  return (
    <div css={colorPickerWrapper}>
      <div css={colorPickerBoxStyle}>
        <span css={hashStyle}>#</span>
        <input
          id="organization-brand-color"
          name="organization[brand_color]"
          ref={inputRef}
          placeholder="FFFFFF"
          maxLength={6}
          css={prefixStyle}
          value={
            color === null || color === undefined
              ? 'FFFFFF'
              : color.toUpperCase()
          }
          onChange={(event) => {
            setColor(event.target.value)
            if (!!event.target.value && isHex(event.target.value)) {
              setCurrentTheme(findTheme('#' + event.target.value))
            }
          }}></input>
        <Button
          aria-label="Click to open color picker"
          ref={colorButtonRef}
          variant={Variant.UNSTYLED}
          css={[pickerButtonStyle, css({ backgroundColor: `#${color}` })]}
          onClick={() => setOpen(true)}
          type="Button"></Button>
      </div>
      {open && (
        <div ref={wrapperRef}>
          <HexColorPicker
            color={`#${color}`}
            onChange={(newColor) => {
              setColor(newColor.replace('#', ''))
              setCurrentTheme(findTheme(newColor))
            }}
          />
        </div>
      )}
      <input hidden={true} name="organization[theme]" defaultValue={theme} />
    </div>
  )
}
