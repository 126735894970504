import { SerializedStyles, css } from '@emotion/react'
import {
  PAGE_PADDING,
  PAGE_WIDTH_AND_PADDING,
} from 'components/EventCallPage/constants'
import { useMemo } from 'react'
import { spacings } from 'stylesheets/theme'

const gridContainerCss = css({
  position: 'absolute',
  zIndex: 1000000000,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  pointerEvents: 'none', // Do not respond to clicks
})

//Total width = 1440px (12 columns of 88px each, with 24px gap + 60px padding on each side)
const innerGridContainerCss = css({
  height: '100%',
  width: PAGE_WIDTH_AND_PADDING,
  padding: `0 ${PAGE_PADDING}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'row',
  gap: spacings.grid.gap,
})

const hasSidebarInnerGridContainerCss = css({
  padding: 0,
})

const gridColumnCss = css({
  width: spacings.grid.column_width,
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
})

interface ShowGridOverlayReturningValues {
  parentStyle: SerializedStyles
  overlay: JSX.Element | null
}

export const useShowGridOverlay = (
  hasSidebar = false,
): ShowGridOverlayReturningValues => {
  const hasGrid = window.location.search.includes('show_grid=1')

  const grid = useMemo(() => {
    if (!hasGrid) {
      return null
    }

    const columns = Array(spacings.grid.columns).fill(true)
    return (
      <>
        <div css={gridContainerCss} id="show-grid">
          <div
            css={[
              innerGridContainerCss,
              hasSidebar && hasSidebarInnerGridContainerCss,
            ]}>
            {columns.map((_, index) => {
              return <div key={`grid-${index}`} css={gridColumnCss}></div>
            })}
          </div>
        </div>
      </>
    )
  }, [hasGrid, hasSidebar])

  return {
    parentStyle: hasGrid
      ? css({
          position: 'relative',
        })
      : css({}),
    overlay: grid,
  }
}
