import { css } from '@emotion/react'
import { calcGridWidth, colors, fonts, spacings } from 'stylesheets/theme'

export const reportWrapperStyle = css({
  maxWidth: calcGridWidth(10),
})

export const narrowReportWrapperStyle = css({
  padding: spacings.grid_gap_basis_num * 2,
})

export const SSectionTitle = css({
  fontWeight: 700,
  fontFamily: fonts.lato,
  fontSize: 14,
  lineHeight: '23px',
  textTransform: 'uppercase',
  color: colors.teal_dark,
})

export const SYouAreTitle = css({
  fontFamily: fonts.poppins,
  fontSize: 32,
  lineHeight: '34px',
  fontWeight: 700,
})

export const SYouAreTitleWrapper = css({
  marginBottom: 4 * spacings.grid_gap_basis_num, // 40px
  gap: spacings.grid_gap_basis_num * 2,
})

export const SYouAreTitleWrapperMobile = css({
  gap: (3 + 1 / 3) * spacings.grid_gap_basis_num, // 40px
})

export const SSummaryTitle = css({
  textAlign: 'left',
  fontFamily: fonts.poppins,
  fontSize: 19,
  lineHeight: '30px',
  fontWeight: 700,
  marginBottom: 2 * spacings.grid_gap_basis_num,
})

export const SColumnStack = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start !important',
  justifyContent: 'start !important',
  h3: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    textTransform: 'none',
  },
  p: {
    color: colors.text.text_3,
  },
})

export const SColumns = css({
  '--columns': '2',
  display: 'grid',
  gridTemplateColumns: `1fr ${calcGridWidth(3)}px`,
  alignItems: 'start',
  columnGap: `calc(${spacings.grid.gap * 2}px + ${calcGridWidth(1)}px)`,
  justifyContent: 'start',
})

export const SColumnsNarrow = css({
  '--columns': '1',
  gap: 2 * spacings.grid_gap_basis_num,
  gridTemplateColumns: 'none',

  '> *': {
    maxWidth: 598,
  },

  '> .first': {
    order: 1,
  },

  '> .second': {
    order: 2,
  },

  '> .centered': {
    justifyContent: 'center',
    display: 'flex',
  },
})

export const SActionsFullContainer = css({
  width: '100%',
})

export const SAlreadyInquiredContainer = css({
  marginTop: 10 * spacings.grid_gap_basis_num,
  borderBottom: `1px solid ${colors.borders.gray}`,
  paddingBottom: spacings.grid_gap_basis_num * 2,
  width: '100%',
})

export const SInquireButton = css({
  display: 'inline-block',
  marginTop: 10 * spacings.grid_gap_basis_num, //
  padding: '0',
  textTransform: 'none',
  gap: spacings.grid_gap_basis_num * (2 / 3),
  borderBottom: `1px solid ${colors.borders.gray}`,
  paddingBottom: spacings.grid_gap_basis_num * 2,
  width: '100%',

  svg: {
    marginRight: 0.5 * spacings.grid_gap_basis_num,
    height: 13,
    verticalAlign: 'baseline',

    path: {
      fill: colors.links.blue,
    },
  },
})

export const SShareOnLinkedInButtonWrap = css({
  marginTop: 4 * spacings.grid_gap_basis_num,
})

export const SShareOnLinkedInIcon = css({
  height: 14,
  width: 14,
  marginRight: 0.5 * spacings.grid_gap_basis_num,
})
