import { Paragraph } from 'components/Typography'
import { IFilterItem } from './types'
import { getResultingSelectedInputs } from './helper'
import { spacings } from 'stylesheets/theme'

interface IAdvanceFilterResultsProps {
  resultingFilterGroups: IFilterItem[][]
  className?: string
}

export default function AdvanceFilterResults({
  resultingFilterGroups,
  className = '',
}: IAdvanceFilterResultsProps): JSX.Element {
  return (
    <div className={className}>
      {resultingFilterGroups?.map((filterGroup, groupIndex) => {
        return (
          <div key={`confirmation-filtergroup-${groupIndex}`}>
            {filterGroup.map((filterItem, itemIndex) => {
              return (
                <Paragraph
                  key={`confirmation-filtergroup-${groupIndex}-item-${itemIndex}`}>
                  <b>{filterItem.filterType}:</b>{' '}
                  {getResultingSelectedInputs(
                    filterItem.filterType,
                    filterItem.filterInputs,
                  )}
                </Paragraph>
              )
            })}
            {resultingFilterGroups.length !== 1 &&
              groupIndex !== resultingFilterGroups.length - 1 && (
                <Paragraph
                  css={{
                    paddingTop: spacings.grid_gap_basis,
                    paddingBottom: spacings.grid_gap_basis,
                  }}>
                  Or
                </Paragraph>
              )}
          </div>
        )
      })}
    </div>
  )
}
