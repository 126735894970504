import React from 'react'

// components
import UploadAvatar from 'components/UploadAvatar'

import './User.scss'

interface IUserProps {
  id: number
  fullName: string
  pronouns?: string
  logoUrl: string | null
  title: string | null
  leader: boolean
  uploadPhoto: boolean
}

const UserAvatar = ({ fullName, logoUrl, uploadPhoto }) => {
  const userInitials = fullName.charAt(0).toUpperCase()
  if (logoUrl) {
    return <img className="user-avatar" src={logoUrl} alt={userInitials} />
  }

  if (uploadPhoto) {
    return <UploadAvatar size={130} />
  }

  return <span className="user-avatar user-default-avatar">{userInitials}</span>
}

const User: React.FC<IUserProps> = ({
  logoUrl,
  fullName,
  pronouns,
  title,
  leader,
  uploadPhoto,
}) => {
  return (
    <div className="user-container">
      <UserAvatar
        fullName={fullName}
        logoUrl={logoUrl}
        uploadPhoto={uploadPhoto}
      />
      {leader && (
        <span className="pill leader pill-size-large pill-color-green">
          Leader
        </span>
      )}
      <span className="user-display-name">{fullName}</span>
      {pronouns && <span className="user-title">{pronouns}</span>}
      {title && <span className="user-title">{title}</span>}
    </div>
  )
}

export default User
