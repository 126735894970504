import { useCallback } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Container from 'components/Container'
import { colors, spacings } from 'stylesheets/theme'
import DragIcon from 'images/icons/drag.svg'
import { css } from '@emotion/react'
import { Paragraph } from 'components/Typography'

const labelStyle = css({
  color: colors.text.text_5,
  fontSize: 15,
  fontStyle: 'normal',
  fontWeight: 800,
  textTransform: 'capitalize',
})

const reorder = (list, startIndex: number, endIndex: number) => {
  const result: any[] = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: `${spacings.grid_gap_basis} ${spacings.grid_gap_basis_num * 2}px`,
  margin: `0 0 ${spacings.grid_gap_basis_num * 2}px 0`,
  borderRadius: spacings.draggable_radius,
  border: `1px solid ${colors.borders.gray}`,
  borderColor: isDragging ? colors.teal : colors.borders.gray,
  width: 'fit-content',
  height: '46px',
  backgroundColor: colors.backgrounds.white,
  display: 'flex',
  ...draggableStyle,
})

const getListStyle = () => ({
  width: '100%',
  minHeight: '300px',
})

interface IDragAndDropLeadrComponentProps {
  characteristics: any[]
  setCharacteristics: (characteristics: any[]) => void
}

export default function DragAndDropLeadrComponent({
  characteristics,
  setCharacteristics,
}: IDragAndDropLeadrComponentProps): JSX.Element {
  const onDragEnd = useCallback(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const items = reorder(
        characteristics,
        result.source.index,
        result.destination.index,
      )

      setCharacteristics(items)
    },
    [setCharacteristics, characteristics],
  )

  return (
    <Container
      css={{ paddingTop: spacings.grid_gap_basis_num * 2, gap: '21px' }}>
      <Container
        direction="column"
        css={{
          gap: spacings.grid_gap_basis_num * 2,
        }}>
        {[1, 2, 3, 4, 5].map((index) => (
          <h3
            key={`question-index-${index}`}
            css={{
              height: '46px',
            }}>
            {index}
          </h3>
        ))}
      </Container>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle()}>
              {characteristics.map((item, index) => (
                <Draggable
                  draggableId={item.name}
                  index={index}
                  key={item.name}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}>
                      <Container
                        css={{
                          gap: spacings.grid_gap_basis,
                        }}>
                        <DragIcon />
                        <Container
                          css={{
                            gap: spacings.grid_gap_basis_num / 2,
                          }}>
                          <span css={labelStyle}>{item.name + ': '}</span>
                          <Paragraph>{item.description}</Paragraph>
                        </Container>
                      </Container>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}
