import { css } from '@emotion/react'
import { Paragraph } from 'components/Typography'
import { boxShadow, colors, spacings } from 'stylesheets/theme'
import Container from 'components/Container/Container'
import Avatar from 'components/Avatar'
import EyeIcon from 'images/icons/eye.svg'
import Button, { Variant } from 'components/Button'
import { ISpaeker } from 'types'
import Modal, { Size } from 'components/Modal'
import { useMemo, useState } from 'react'

const deafultSpeakerContainerStyle = css({
  // width: 310,
  height: 166,
  margin: '0 12px 7px',
  padding: '16px 16px',
  backgroundColor: colors.backgrounds.white,
  boxShadow: boxShadow.container,
  alignItems: 'flex-start',
  borderRadius: spacings.radius,

  // Fixes for drop shadow alignments
  overflowY: 'hidden',
  marginBottom: 2,
  position: 'relative',
  left: -1,
})

const LINE_HEIGHT = 25
const LINES = 3
const paragraphStyle = css({
  '--lines': LINES,
  maxHeight: LINE_HEIGHT * LINES, // calc() does not work here for some reason
  '-webkit-line-clamp': 'var(--lines)',
  '-webkit-box-orient': 'vertical',
  overflowY: 'hidden',
})

const mobileSpeakerContainerStyle = css({
  width: `calc(100% - ${spacings.grid_gap_basis})`,
  margin: '0 0 7px',
  height: 200,
})

const defaultBioModalStyle = css({
  minWidth: 300,
  maxWidth: 696,
  margin: 'auto',
})

const mobileBioModalStyle = css({
  marginLeft: spacings.grid_gap_basis_num * 2,
  marginRight: spacings.grid_gap_basis_num * 2,
})

interface SpeakerCardProps {
  isMobile?: boolean
  speaker: ISpaeker
  className?: string
}

export default function SpeakerCard({
  isMobile = false,
  speaker,
  className = '',
}: SpeakerCardProps): JSX.Element {
  const [openBioModal, setOpenBioModal] = useState(false)
  const title = useMemo(() => {
    const subtitle = []
    if (speaker.title) subtitle.push(speaker.title)
    if (speaker.company) subtitle.push(speaker.company)
    return subtitle.length > 0 ? subtitle.join(', ') : undefined
  }, [speaker])
  return (
    <Container
      direction="column"
      key={speaker.name}
      css={[
        deafultSpeakerContainerStyle,
        isMobile && mobileSpeakerContainerStyle,
      ]}
      className={className}>
      <Container
        css={{
          alignItems: 'flex-start',
        }}>
        <Avatar
          size={60}
          avatarUrl={speaker.avatar}
          userInitial={speaker.name}
        />
        <Container
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginLeft: spacings.grid_gap_basis,
            gap: 2,
          }}>
          <h3
            css={{
              color: colors.text.text_5,
            }}>
            {speaker.name}
          </h3>
          {title && <Paragraph css={paragraphStyle}>{title}</Paragraph>}
        </Container>
      </Container>
      {speaker.bio && (
        <>
          <Button
            variant={Variant.LINK}
            startIcon={
              <EyeIcon
                css={{
                  width: 18,
                  height: 18,
                  marginRight: spacings.grid_gap_basis_num / 2,
                  path: {
                    fill: colors.blue,
                  },
                }}
              />
            }
            onClick={() => setOpenBioModal(true)}
            css={{
              marginLeft: 80,
            }}>
            View Bio
          </Button>
          <Modal
            css={[defaultBioModalStyle, isMobile && mobileBioModalStyle]}
            avatarImg={speaker.avatar || null}
            title={speaker.name}
            subtitle={title}
            isOpen={openBioModal}
            onRequestClose={() => setOpenBioModal(false)}
            onSave={() => setOpenBioModal(false)}
            size={Size.small}
            addLineAfterSubtitle>
            <Paragraph>{speaker.bio}</Paragraph>
          </Modal>
        </>
      )}
    </Container>
  )
}
