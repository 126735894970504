import { useRef, useEffect } from 'react'
import Button, { Variant } from 'components/Button'
import cx from 'classnames'

interface ImagePreviewProps {
  logoUrl?: string
  onLogoChange?: (logoUrl) => void
}

export default function ImagePreview({
  logoUrl,
  onLogoChange,
}: ImagePreviewProps): JSX.Element {
  const fileRef = useRef(null)

  function onAddImage() {
    fileRef.current.click()
  }

  /**
   * THIS IS TEMPORARY until we refactor crop.ts to get rid of jQuery!!
   */
  useEffect(() => {
    const img = document.getElementById('logo-img')
    const observer = new MutationObserver((changes) => {
      changes.forEach((change) => {
        if (change.attributeName.includes('src')) {
          onLogoChange((change.target as HTMLElement).getAttribute('src'))
        }
      })
    })

    if (img) {
      observer.observe(img, { attributes: true })
    }
  }, [])

  return (
    <>
      <div className="to-be-cropped-wrapper file-input-wrapper round">
        <div
          className={cx('preview', {
            hidden: !logoUrl,
          })}>
          <div className="image-container">
            <img aria-label="logo image preview" id="logo-img" src={logoUrl} />
            <button
              aria-label="remove image"
              className="not-styled-button remove absolute"
              type="button"
            />
          </div>
        </div>
        <label htmlFor="organization-logo">
          <Button
            className={cx({ hidden: logoUrl })}
            variant={Variant.LINK}
            onClick={onAddImage}
            type="Button"
            id="add-image-button">
            + Add Image
          </Button>
          <input
            className="destroy-field"
            name="organization[remove_logo]"
            type="hidden"></input>
          <input
            className="hidden-file-field"
            type="hidden"
            name="organization[logo]"
            id="organization-logo"></input>
          <input
            ref={fileRef}
            type="file"
            value={undefined}
            className="to-be-cropped"
            style={{ display: 'none' }}
            tabIndex={-1}
            accept="image/png, image/jpeg, image/gif"
          />
        </label>
      </div>
    </>
  )
}
