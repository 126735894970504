import React, { FC } from 'react'
import UploadAvatar from 'components/UploadAvatar'

const UploadAvatarWelcomeModal: FC = () => {
  return (
    <div className={'modal-content'}>
      <p>To start, set up your profile photo.</p>
      <UploadAvatar size={204} />
    </div>
  )
}

export default UploadAvatarWelcomeModal
