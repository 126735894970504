export enum CircleColor {
  'green' = 'green',
}
export type TCircleColor = keyof typeof CircleColor

export interface INumberedCircleItem {
  color?: TCircleColor
  light?: boolean
  dark?: boolean
  content: string | React.ReactNode
}

export interface INumberedCircleList {
  className?: string
  items: INumberedCircleItem[]
}
