import Container from 'components/Container'
import { Paragraph } from 'components/Typography'
import Input from 'components/Input'
import { spacings } from 'stylesheets/theme'
import { useState } from 'react'

const TITLE = 'title'
const DEPARTMENT = 'department'
const LOCATION = 'location'
const EMAIL = 'email'

const detailPageOptions = [
  { name: TITLE, label: 'Title' },
  { name: DEPARTMENT, label: 'Business unit' },
  { name: LOCATION, label: 'Location' },
  { name: EMAIL, label: 'Email' },
]

const defaultOptionCheckValues = {
  title: true,
  department: true,
  location: true,
  email: true,
}

interface IMentorshipDetailPageRadioGroupProps {
  groupName: string
  defaultChecked?: Record<string, boolean>
}

export default function MentorshipDetailPageRadioGroup({
  groupName,
  defaultChecked = defaultOptionCheckValues,
}: IMentorshipDetailPageRadioGroupProps): JSX.Element {
  const [checked, setChecked] =
    useState<Record<string, boolean>>(defaultChecked)
  return (
    <Container direction="column" alignment="start">
      <input type="hidden" name={groupName} value={JSON.stringify(checked)} />
      {detailPageOptions.map((option) => {
        const name = option.name

        return (
          <label key={name}>
            <Container
              css={{
                gap: spacings.grid_gap_basis_num / 2,
              }}>
              <Input
                type="checkbox"
                checked={checked[name]}
                name={name}
                onChange={(changes) => {
                  setChecked({
                    ...checked,
                    ...(changes as Record<string, boolean>),
                  })
                }}
              />
              <Paragraph>{option.label}</Paragraph>
            </Container>
          </label>
        )
      })}
    </Container>
  )
}
