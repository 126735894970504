import { css } from '@emotion/react'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import { useOutsideAlerter } from 'hooks/useOutsideAlerter'

import Icon from 'images/icons/dots_vert.svg'
import { useEffect, useMemo, useRef, useState } from 'react'
import { colors, zIndex } from 'stylesheets/theme'

const BUTTON_SIZE = 40

const menuWrapperCss = css({
  position: 'relative',
  width: BUTTON_SIZE,
  height: BUTTON_SIZE,
  overflow: 'visible',
  flexGrow: 0,
  flexShrink: 0,
  '.menu-list': {
    zIndex: zIndex.menu,
    position: 'absolute',
    top: 'calc(100% + 10px)',
    right: 0,
    overflow: 'hidden',
    transition: 'height 0.3s',
    height: 0,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
  },
  '.menu-item': {
    zIndex: zIndex.menu,
    display: 'block',
    padding: '14px 25px', // copied from legacy style
    fontSize: 14,
    lineHeight: '16px',
    backgroundColor: colors.backgrounds.white,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    border: '1px solid #eaeaea',
    textDecoration: 'none',
    color: colors.text.text_3,
    minWidth: 200,

    '&:hover, &:focus, &:active': {
      backgroundColor: colors.green_bg_very_light,
    },
  },
})

const buttonCss = css({
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: BUTTON_SIZE,
})

export interface IDotMenuItem {
  label: string
  url?: string
  onClick?: (e) => void
  disableCloseMenuOnClick?: boolean
}

interface IDotMenuProps {
  className?: string
  menuItems: IDotMenuItem[]
}

const DotMenu: React.FC<IDotMenuProps> = ({
  className,
  menuItems,
}): React.ReactElement => {
  const componentId = useMemo(() => {
    return `dots-${Math.floor(Math.random() * 100000000)}`
  }, [menuItems])

  const [expandedHeight, setExpandedHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const useCss = [menuWrapperCss]
  if (isOpen) {
    useCss.push(
      css({
        '.menu-list': {
          height: expandedHeight,
        },
      }),
    )
  }

  const openMenu = () => {
    setIsOpen(!isOpen)
  }
  const componentRef = useRef(null)
  useOutsideAlerter(componentRef, () => {
    setIsOpen(false)
  })

  useEffect(() => {
    let height = 0
    Array.from(
      document.querySelectorAll(`#${componentId} .menu-list .menu-item`),
    ).forEach((menuItem: HTMLElement) => {
      height += menuItem.offsetHeight
    })
    setExpandedHeight(height)
  }, [setExpandedHeight])

  return (
    <div id={componentId} css={useCss} className={className} ref={componentRef}>
      <Button
        as={ButtonComponentType.LINK}
        css={buttonCss}
        variant={Variant.SECONDARY}
        onClick={openMenu}>
        <Icon height={16} />
      </Button>
      <div className="menu-list">
        {menuItems.map((menuItem: IDotMenuItem, index: number) => {
          const onClick = (e) => {
            e.nativeEvent &&
              e.nativeEvent.stopImmediatePropagation &&
              e.nativeEvent.stopImmediatePropagation()
            menuItem.onClick && menuItem.onClick(e)
          }
          return (
            <Button
              key={`item-${index}`}
              className="menu-item"
              as={ButtonComponentType.LINK}
              variant={Variant.UNSTYLED}
              href={menuItem.url}
              onClick={onClick}>
              {menuItem.label}
            </Button>
          )
        })}
      </div>
    </div>
  )
}

export default DotMenu
