import React from 'react'
import Table, { ITableProps } from 'components/Table'
import noRegistrantsImg from 'images/people_empty_state.png'

type RegistrantsTableProps = ITableProps & {
  objectData: string
  invitable: boolean
}

/**
 * Shows card with registrants table
 * If there are no registrants, show empty state with invite users button to show modal
 */
export default function RegistrantsTable({
  invitable,
  objectData,
  ...props
}: RegistrantsTableProps): JSX.Element {
  return (
    <Table
      {...props}
      emptyStateImgUrl={noRegistrantsImg}
      object="registrants"
      emptyStateSubtitle="Registrants can submit questions and invite colleagues to join the event"
      emptyStateActionNode={
        !!invitable ? (
          <button
            data-event-info={objectData}
            id="empty-registrants-share-btn"
            className="button secondary small plus">
            invite users
          </button>
        ) : undefined
      }
    />
  )
}
