import Input from 'components/Input/Input'
import React, { useState } from 'react'
import { css } from '@emotion/react'
import cx from 'classnames'
import { colors } from 'stylesheets/theme'

const placeholderCss = css({
  '& .react-select [class*="-placeholder"]': {
    color: colors.text.text_9,
  },
})

const noMarginCss = css({
  '.mindr-form section & .input-component-wrapper': {
    margin: 0,
  },
})

const MONTHS = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
]
const CURRENT_YEAR = new Date().getFullYear()
const CURRENT_MONTH = new Date().getMonth()

function monthAllowsCurrentYear(month: string) {
  const monthIndex = MONTHS.map((pair) => pair.value).indexOf(month)
  return monthIndex >= CURRENT_MONTH
}

interface IMonthYearPickerProps {
  fieldName: string
  id?: string
  monthClassName?: string
  yearClassName?: string
  month?: string
  year?: string
  errors?: string[]
}

const MonthYearPicker: React.FC<IMonthYearPickerProps> = ({
  fieldName,
  id = 'month_year_' + Math.round(Math.random() * 10000000),
  monthClassName = '',
  yearClassName = '',
  month = '',
  year = '',
  errors = [],
}): React.ReactElement => {
  const name = (keys: string[] = []) => {
    return keys.length ? fieldName + '[' + keys.join('][') + ']' : fieldName
  }

  const [currentYear, setCurrentYear] = useState(year)
  const onChangeYear = (value: Record<string, string>) => {
    setCurrentYear(value['year'])
  }

  const [currentMonth, setCurrentMonth] = useState(month)
  const onChangeMonth = (value: Record<string, string>) => {
    setCurrentMonth(value['month'])
    // Correct for dates in the past
    const index = MONTHS.map((m) => m.value).indexOf(value['month'])
    if (index < CURRENT_MONTH && parseInt(currentYear) === CURRENT_YEAR) {
      setCurrentYear(`${CURRENT_YEAR + 1}`)
    }
  }

  const yearList = (
    monthAllowsCurrentYear(currentMonth)
      ? Array.from({ length: 10 }, (_, i) => i + CURRENT_YEAR)
      : Array.from({ length: 9 }, (_, i) => i + CURRENT_YEAR + 1)
  ).map((year) => ({ label: `${year}`, value: `${year}` }))

  return (
    <>
      <div
        id={`${id}_month`}
        className={cx(monthClassName, 'month-field')}
        css={[noMarginCss, placeholderCss]}>
        <Input
          type="select"
          name="month"
          hideName
          options={MONTHS}
          value={currentMonth}
          onChange={onChangeMonth}
          css={noMarginCss}
          placeholder="- Month -"
          error={errors.length ? errors.join('') : null}
        />
        <Input
          type="hidden"
          name={name(['month'])}
          value={currentMonth}
          css={noMarginCss}
        />
      </div>
      <div
        id={`${id}_year`}
        className={cx(yearClassName, 'year-field')}
        css={[noMarginCss, placeholderCss]}>
        <Input
          type="select"
          name="year"
          hideName
          options={yearList}
          value={`${currentYear}`}
          onChange={onChangeYear}
          css={noMarginCss}
          placeholder="- Year -"
          indicateErrorWithoutText={!!errors.length}
        />
        <Input
          type="hidden"
          name={name(['year'])}
          value={currentYear}
          css={noMarginCss}
        />
      </div>
    </>
  )
}

export default MonthYearPicker
