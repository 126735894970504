import React from 'react'
import cx from 'classnames'

import { convertUTCToLocalRange } from 'javascripts/general'

import { IEventCall, IEventCallHost, TEventCallType } from 'types'

interface IEventCallSummary {
  data: IEventCall
  type: TEventCallType
  showHosts?: boolean
  className?: string
}

const hostList = (hosts: IEventCallHost[], showLimit = 3) => {
  const extraCount = hosts.length - showLimit

  return (
    <div className="hosts">
      <ul>
        {hosts.slice(0, showLimit).map((host) => (
          <li key={`host-info-${host.id}`}>
            <img className="host-logo" src={host.logo} />
            <span className="host-name">
              By <strong>{host.name}</strong>
            </span>
          </li>
        ))}
      </ul>
      {extraCount > 0 && (
        <span className="has-more">
          + {extraCount} {extraCount > 1 ? 'communities' : 'community'}
        </span>
      )}
    </div>
  )
}

const EventCallSummary = ({
  data: event,
  type,
  showHosts,
  className,
}: IEventCallSummary): React.ReactElement => (
  <div className={cx(`${type}-summary`, 'register-modal-body', className)}>
    <h1 className={`${type}-title`}>{event.title}</h1>
    {event.datetime && (
      <h2 className={`${type}-time`}>
        {convertUTCToLocalRange(event.datetime, event.duration)}
      </h2>
    )}
    <div className="info">
      {event.location_in_person && (
        <div className="location location-in-person">
          <p className="text" title={event.location_in_person}>
            <span className="label">Address:</span>
            <span>{event.location_in_person}</span>
          </p>
        </div>
      )}
      {event.location_virtual && (
        <div className="location location-virtual">
          <p className="text">
            <span className="label">Dial-in link:</span>
            <a
              href={event.location_virtual}
              className="link"
              // eslint-disable-next-line react/jsx-no-target-blank
              target="_blank"
              rel="noreferrer">
              {event.location_virtual}
            </a>
          </p>
        </div>
      )}
    </div>
    {showHosts && hostList(event.hosts)}
  </div>
)

export default EventCallSummary
