import React from 'react'

import { CallActionContext } from './context'

import CallActionView, { ICallActionProps } from './CallAction'

const CallAction: React.FC<ICallActionProps> = (props) => (
  <CallActionContext call={props.data}>
    <CallActionView {...props} />
  </CallActionContext>
)

export default CallAction
