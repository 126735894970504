import {
  ILeadrCumulativeReportPageAjaxStrings,
  ILeadrCumulativeReportPageUrls,
  IReportResults,
} from './LeadrCumulativeReport.types'
import axios, { AxiosResponse } from 'axios'
import { TUserFilters } from 'state/Users'

interface IUpdateFiltersProps {
  strings: ILeadrCumulativeReportPageAjaxStrings
  urls: ILeadrCumulativeReportPageUrls
  userFilters: Partial<TUserFilters>
  onSuccess?: (report: IReportResults) => void
  onError?: (userFilters: Partial<TUserFilters>, e: any) => void
}

interface IUpdateFiltersResponse {
  results: IReportResults
}

export function ajaxUpdateUserFilters({
  strings,
  urls,
  userFilters: filtersFromComponent,
  onSuccess,
  onError,
}: IUpdateFiltersProps): void {
  const url = new URL(urls.filter_url)
  url.search = new URLSearchParams({
    filters: JSON.stringify(filtersFromComponent),
  }).toString()

  axios
    .get(url.toString())
    .catch((e: any) => {
      window.flash(strings.errors.filter_results.could_not_filter, 'error')
      onError && onError(filtersFromComponent, e)
    })
    .then(({ data }: AxiosResponse<IUpdateFiltersResponse>) => {
      onSuccess && onSuccess(data.results)
    })
}
