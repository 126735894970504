import React from 'react'
import cx from 'classnames'

import EventCallButton from 'components/EventCallButton'

import { useEventActionContext } from '../context'

import './RegisterButton.scss'

import type { IEventCall, TActionLocation } from 'types'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

type RegisterEventButtonProps = {
  event: IEventCall
  location: TActionLocation
  className?: string
}

const RegisterEventButton: React.FC<RegisterEventButtonProps> = ({
  event,
  location,
  className = '',
}) => {
  const { registered, onRegister, onUnregister } = useEventActionContext()
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  const registeredEl = () => {
    if (location === 'banner') {
      if (isMobile) {
        return (
          <button
            className={cx('event-register-button link', className)}
            onClick={onUnregister}>
            Cancel registration
          </button>
        )
      }
      return (
        <button
          className={'event-register-button button secondary'}
          onClick={onUnregister}>
          Cancel Registration
        </button>
      )
    } else if (location === 'table-cell') {
      return (
        <a href={event.link} className={'button-label'}>
          Registered
        </a>
      )
    }

    return <span className={'button-label'}>Registered</span>
  }

  return (
    <div
      className={cx('event-register-action', {
        subscribed: registered,
        [className]: !!className,
      })}>
      {registered && registeredEl()}
      {!registered && (
        <EventCallButton
          type="event"
          location={location}
          onClick={onRegister}
          css={{
            width: '100%',
          }}
        />
      )}
    </div>
  )
}

export default RegisterEventButton
