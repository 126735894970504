import { css } from '@emotion/react'

import Widget, { WidgetProps } from './Widget'

import MySubscriptionIcon from 'images/icons/my_subscriptions.svg'

import { colors } from 'stylesheets/theme'

interface AcceptedMatchWidgetProps
  extends Omit<WidgetProps, 'number' | 'icon'> {
  matches: number
}

const iconStyle = css({
  path: {
    stroke: colors.teal_dark,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.green_bg_light,
  },
})

export default function AcceptedMatchWidget({
  matches,
  ...props
}: AcceptedMatchWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<MySubscriptionIcon height="18px" width="18px" css={iconStyle} />}
      number={matches}
      css={widgetStyle}
      {...props}
    />
  )
}
