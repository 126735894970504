import { css } from '@emotion/react'
import { FormFieldContainer, FormFieldsRow } from 'components/Forms'
import Input from 'components/Input'
import { IOption } from 'components/Inputs/Select'
import _ from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

const UNITS: IOption[] = ['seconds', 'minutes', 'hours', 'days', 'weeks'].map(
  (unit) => ({ label: unit, value: unit }),
)

type TTimeUnits =
  | 'second'
  | 'seconds'
  | 'minute'
  | 'minutes'
  | 'hour'
  | 'hours'
  | 'day'
  | 'days'
  | 'week'
  | 'weeks'

interface IAutoAnonymizeSettings {
  enabled: boolean
  num: number
  unit: TTimeUnits
}

interface IAutoAnonymizeSettingsProps {
  settings?: IAutoAnonymizeSettings
}

const defaultSettings: IAutoAnonymizeSettings = {
  enabled: false,
  num: 7,
  unit: 'days',
}

const fieldWrapStyle = css({
  '.input-component-wrapper.checkbox-component-wrapper': {
    alignItems: 'center',
    justifyContent: 'start',
    minHeight: 42,
  },
})

export default function AutoAnonymizeSettings({
  settings,
}: IAutoAnonymizeSettingsProps): JSX.Element {
  // State-track and load the settings for Auto Anonymization
  const [value, setValue] = useState<IAutoAnonymizeSettings>(defaultSettings)
  useEffect(() => {
    setValue(_.merge(_.cloneDeep(defaultSettings), settings))
  }, [settings])

  // Handle settings updates
  const onChange = useCallback(
    (updated: Record<string, any>) => {
      setValue(_.merge(_.cloneDeep(value), updated))
    },
    [value],
  )

  // Helper functions for name and id
  const name = useCallback(
    (segments: string[]) => {
      return `organization${segments.map((segment) => `[${segment}]`).join('')}`
    },
    [settings],
  )
  const id = useCallback(
    (segments: string[]) => {
      return `organization${segments.map((segment) => `-${segment}`).join('')}`
    },
    [settings],
  )

  // Field names and ids
  const enabledName = useMemo(() => name(['auto_anonymize', 'enabled']), [])
  const enabledId = useMemo(() => id(['auto_anonymize', 'enabled']), [])
  const numName = useMemo(() => name(['auto_anonymize', 'num']), [])
  const numId = useMemo(() => id(['auto_anonymize', 'num']), [])
  const unitName = useMemo(() => name(['auto_anonymize', 'unit']), [])
  const unitId = useMemo(() => id(['auto_anonymize', 'unit']), [])

  return (
    <div>
      <FormFieldContainer
        fieldId={`container-${enabledId}`}
        label="Auto-anonymize"
        css={fieldWrapStyle}>
        <Input
          name={enabledName}
          type="checkbox"
          label="Automatically anonymize users who are suspended, after a specified amount of time."
          checked={value.enabled}
          onChange={(changes) => {
            onChange({ enabled: !!changes[enabledName] })
          }}
        />
      </FormFieldContainer>

      {value.enabled ? (
        <FormFieldsRow>
          <FormFieldContainer
            fieldId={`container-${numId}`}
            label="Auto-anonymize timeline settings">
            <Input
              name={numName}
              type="number"
              placeholder="Enter a number"
              value={value.num}
              onChange={(changes) => {
                onChange({ num: parseInt(changes[numName] as string) })
              }}
            />
          </FormFieldContainer>

          <FormFieldContainer fieldId={`container-${unitId}`} label="&nbsp;">
            <Input
              name={unitName}
              hideName
              type="select"
              placeholder="Choose a unit"
              options={UNITS}
              value={value.unit}
              onChange={(changes) => {
                onChange({ unit: changes[unitName] })
              }}
            />
            <input type="hidden" name={unitName} defaultValue={value.unit} />
          </FormFieldContainer>
        </FormFieldsRow>
      ) : (
        <>
          <input type="hidden" name={numName} defaultValue={value.num} />
          <input type="hidden" name={unitName} defaultValue={value.unit} />
        </>
      )}
    </div>
  )
}
