import Card from 'components/Card'
import EmptyState from 'components/EmptyState'
import FileIcon from 'images/icons/file.svg'
import ChainIcon from 'images/icons/chain_45.svg'
import resourceEmptyStateImg from 'images/resource_empty_state.png'
import { Resource, ResourceType } from 'types'
import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'
import { CardProps } from 'components/Card/Card'

interface ResourcesCardProps extends Omit<CardProps, 'children'> {
  resources: Resource<ResourceType>[]
  title?: string
  emptyStateCTA?: string
  emptyStateDescription?: string
  editUrl?: string
  viewOnly?: boolean
}

const resourceWrapperStyle = css({
  padding: `calc(${spacings.grid_gap_basis} / 2) 0`,
})

/**
 * Card that contains a list of recommended resources set for the event / cta
 * a resource can either be a document or a link
 */
export default function ResourcesCard({
  resources = [],
  title = 'Recommended Resources',
  emptyStateCTA = '',
  emptyStateDescription = '',
  editUrl = null,
  viewOnly = false,
  className = '',
  ...props
}: ResourcesCardProps): JSX.Element {
  return (
    <Card title={title} className={className} {...props}>
      {!!resources.length ? (
        <>
          {resources.map((resource) => (
            <div key={resource.id} css={resourceWrapperStyle}>
              <a
                target="_blank"
                rel="noreferrer"
                title={resource.title}
                key={resource.title}
                className="link"
                href={resource.url}>
                {resource.document_data ? <FileIcon /> : <ChainIcon />}
                <span>{resource.title}</span>
              </a>
            </div>
          ))}
        </>
      ) : (
        !viewOnly &&
        editUrl &&
        emptyStateCTA &&
        emptyStateDescription && (
          <EmptyState
            imgUrl={resourceEmptyStateImg}
            actionNode={
              <a
                href={editUrl + '#show-resources-button'}
                className="button secondary small plus">
                {emptyStateCTA}
              </a>
            }>
            <p>{emptyStateDescription}</p>
          </EmptyState>
        )
      )}
    </Card>
  )
}
