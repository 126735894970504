import { useContext } from 'react'
import {
  AudienceContext,
  initialFilterGroups,
} from './LeadrAssessmentInviteModalContext'
import AudienceComponentView from 'components/Audience/AudienceComponentView'

interface ILeadrAssessmentInviteModalProps {
  onClose: () => void
  urls: Record<string, string>
}

export default function LeadrAssessmentInviteModalComponent({
  onClose,
  urls,
}: ILeadrAssessmentInviteModalProps): JSX.Element {
  const {
    filterGroups,
    isOpen,
    isCsvImport,
    setCsvImport,
    audienceCount,
    resetFilterGroups,
    shouldConfirmClose,
    updateEstimatedAudienceCount,
    hasPositiveAudienceCount,
    setOpenConfirmationModal,
    openConfirmationModal,
    csvImportFile,
    setCsvImportFile,
    resultingFilterGroups,
    audienceImportTable,
    addNewFilterItem,
    removeFilterItem,
    addFilterGroup,
    removeFilterGroup,
    onFilterItemChange,
    removeCsvImport,
    uploadDetails,
    excludeInvited,
    setExcludeInvited,
    onConfirmationRequestClose,
    setSaveAndConfirm,
    saveAndConfirm,
    onConfirmationSave,
    onModalClose,
    onSendToAllUsers,
    sendToAllUsers,
    showZeroAudienceError,
    setShowZeroAudienceError,
  } = useContext(AudienceContext)
  return (
    <AudienceComponentView
      object="leadr"
      subtitle="Choose a subset of users who will receive email notifications. You may use an advanced filter to refine the recipient list or CSV import to upload a CSV of desired recipients. "
      isOpen={isOpen}
      onClose={onClose}
      onConfirmationSave={onConfirmationSave}
      urls={urls}
      isCsvImport={isCsvImport}
      setCsvImport={setCsvImport}
      filterGroups={filterGroups}
      initialFilterGroups={initialFilterGroups}
      audienceCount={audienceCount}
      resetFilterGroups={resetFilterGroups}
      hasPositiveAudienceCount={hasPositiveAudienceCount}
      shouldConfirmClose={shouldConfirmClose}
      setSaveAndConfirm={setSaveAndConfirm}
      updateEstimatedAudienceCount={updateEstimatedAudienceCount}
      setShowZeroAudienceError={setShowZeroAudienceError}
      onConfirmationRequestClose={onConfirmationRequestClose}
      csvImportFile={csvImportFile}
      setCsvImportFile={setCsvImportFile}
      resultingFilterGroups={resultingFilterGroups}
      audienceImportTable={audienceImportTable}
      exampleCsvUrl={urls.exampleCsvUrl}
      addNewFilterItem={addNewFilterItem}
      removeFilterItem={removeFilterItem}
      addFilterGroup={addFilterGroup}
      removeFilterGroup={removeFilterGroup}
      onFilterItemChange={onFilterItemChange}
      saveAndConfirm={saveAndConfirm}
      showZeroAudienceError={showZeroAudienceError}
      removeCsvImport={removeCsvImport}
      uploadDetails={uploadDetails}
      setOpenConfirmationModal={setOpenConfirmationModal}
      openConfirmationModal={openConfirmationModal}
      submitButton="Send to selected users"
      excludeInvited={excludeInvited}
      setExcludeInvited={setExcludeInvited}
      onModalClose={onModalClose}
      onSendToAllUsers={onSendToAllUsers}
      sendToAllUsers={sendToAllUsers}
    />
  )
}
