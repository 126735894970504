import { useState } from 'react'

export type TTableFormat = 'tiles' | 'list'

interface ITableFormatState {
  isTilesFormat: boolean
  isListFormat: boolean
  updateTableFormat: (tableFormat: TTableFormat) => void
}

const useTableFormat = (
  defaultTableFormat: TTableFormat,
): ITableFormatState => {
  const [tableFormat, setTableFormat] = useState<TTableFormat>(
    defaultTableFormat,
  )

  return {
    isTilesFormat: tableFormat === 'tiles',
    isListFormat: tableFormat === 'list',
    updateTableFormat: setTableFormat,
  }
}

export default useTableFormat
