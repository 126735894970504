import Button, { ButtonComponentType, Variant } from 'components/Button'
import { Connector } from 'types'

interface EditButtonProps {
  connector: Connector
}

export default function EditButton({
  connector,
}: EditButtonProps): JSX.Element {
  return (
    <Button
      href={connector.edit_url}
      as={ButtonComponentType.LINK}
      variant={Variant.LINK}
      className="menu-link">
      Edit
    </Button>
  )
}
