import React, { useEffect, useState, useCallback, useRef } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content'
import { defaultSwalParams } from 'javascripts/general'

import BatchEmailModal, { SetErrorsHandle } from './BatchEmailModal'
import type { IUserTableFilters } from 'state/Users'

const SwalReact = withReactContent(Swal)

interface IBatchEmailProps {
  communityType: 'cohort' | 'community'
  isMembers?: boolean
  disabled?: boolean
  url: string
  filters: IUserTableFilters
  totalRecipients: number
  btnClassName?: string
  btnText: string
  modalTitle?: string
}

const BatchEmail: React.FC<IBatchEmailProps> = ({
  isMembers,
  communityType,
  url,
  filters: rawFilters,
  totalRecipients,
  disabled,
  btnClassName,
  btnText,
  modalTitle,
}) => {
  const modalRef = useRef<SetErrorsHandle>()
  const bodyRef = useRef<Record<string, string>>({})
  const [filters, setFilters] = useState({})
  const [hasFilters, setHasFilters] = useState(false)

  useEffect(() => {
    const filters = JSON.parse(JSON.stringify(rawFilters || {}))

    setFilters(filters)
    setHasFilters(
      Object.keys(filters)
        .filter((key) => !key.startsWith('sort'))
        .some((key) => typeof filters[key] === 'number' || filters[key].length),
    )
  }, [rawFilters])

  const typeString = isMembers
    ? communityType === 'cohort'
      ? 'cohort participants'
      : 'community subscribers'
    : 'leaders'

  const filterString = hasFilters
    ? `${totalRecipients} filtered ${typeString}`
    : `All ${totalRecipients} ${typeString}`

  const showRecipients = !!totalRecipients

  const validateForm = useCallback((body) => {
    if (body.subject && body.notes) {
      modalRef.current && modalRef.current.setErrors(null)
      return true
    }

    const errors: Record<string, string> = {}
    if (!body.subject) {
      errors.subject = "Can't be blank"
    }
    if (!body.notes) {
      errors.notes = "Can't be blank"
    }

    modalRef.current && modalRef.current.setErrors(errors)
    return false
  }, [])

  const uploadAttachments = useCallback(async (attachments) => {
    const formData = new FormData()

    attachments.forEach((attachment) => {
      formData.append('files[]', attachment.file, attachment.file_name)
    })
    formData.append('authenticity_token', window.authenticity_token)

    const {
      data: { uploads },
    } = await axios.post('/attachments/upload', formData)

    return uploads
  }, [])

  const handleFormChange = useCallback((body) => {
    bodyRef.current = body
  }, [])

  const handleFormSubmit = useCallback(async () => {
    const body = bodyRef.current

    if (validateForm(body)) {
      if (body.attachments?.length) {
        try {
          const uploads = await uploadAttachments(body.attachments)
          body.attachments = uploads
        } catch (uploadError) {
          modalRef.current &&
            modalRef.current.setErrors({
              attachments:
                uploadError.response?.data?.message || 'Something went wrong',
            })

          return false
        }
      }

      try {
        const {
          data: {
            data: { message },
          },
        } = await axios.post(url, {
          authenticity_token: window.authenticity_token,
          ...body,
          filter: {
            city: filters['filter[city]'],
            title: filters['filter[title]'],
            department: filters['filter[department]'],
            new_subscribers: filters['filter[new_subscribers]'],
            '*': filters['filter[*]'],
          },
        })

        if (message) {
          window.flash(message)
          return true
        }
      } catch (e) {
        window.flash('Something went wrong!', 'alert')
      }
    }
    return false
  }, [filters])

  const handleBtnClick = () => {
    SwalReact.fire({
      ...defaultSwalParams,
      title: modalTitle || 'Batch Email',
      confirmButtonText: 'Send',
      preConfirm: handleFormSubmit,
      html: (
        <BatchEmailModal
          ref={modalRef}
          showRecipients={showRecipients}
          filterString={filterString}
          onFormChange={handleFormChange}
        />
      ),
    })
  }

  return (
    <button
      className={btnClassName}
      disabled={disabled}
      onClick={handleBtnClick}>
      {btnText}
    </button>
  )
}

export default BatchEmail
