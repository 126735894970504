import React, { useMemo } from 'react'
import { TMentorshipExchangeMatchingStatus } from 'types'
import MatchingIcon from 'images/icons/matching_in_progress.svg'
import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'

const ICON_SIZE = 18

const absoluteStyle = css({
  position: 'absolute',
  left: -(ICON_SIZE + spacings.grid_gap_basis_num / 2),
  top: '50%',
  transform: 'translateY(-50%)',
})

const iconStyle = css({
  display: 'inline-block',
  width: ICON_SIZE,
  height: ICON_SIZE,
  verticalAlign: 'middle',
  marginRight: spacings.grid_gap_basis_num / 2,
  svg: {
    height: '100%',
    width: '100%',
  },
})

interface MentorshipExchangeStatusPillProps {
  inline?: boolean
  data: {
    matching_status: TMentorshipExchangeMatchingStatus | null
  }
}

const MentorshipExchangeMatchingStatusPill = ({
  inline = false,
  data,
}: MentorshipExchangeStatusPillProps): React.ReactElement => {
  const { matching_status } = data

  const icon = useMemo(() => {
    if (matching_status == 'not_started') {
      return <span css={[absoluteStyle, iconStyle]}></span>
    }

    const styles = [iconStyle]
    if (!inline) {
      styles.push(absoluteStyle)
    }

    return (
      <span title="Matching in Progress" css={styles}>
        <MatchingIcon />
      </span>
    )
  }, [matching_status, inline])

  return icon
}

export default MentorshipExchangeMatchingStatusPill
