import { useCallback, useMemo, useState } from 'react'
import Axios from 'axios'
import _ from 'lodash'

import { ITableData, ITableMeta } from 'components/Table'
import { useEffectNotOnMount } from 'javascripts/general'
import useOrderState, { TOrderDirection } from 'state/common/Order'

interface IAuthTokensStateProps {
  url: string
  initialData: ITableData
  initialMeta: ITableMeta
}

interface IAuthTokensState {
  loading: boolean
  data: ITableData
  meta: ITableMeta
  loadPage: (page: number) => void
  updateOrder: (column: string, direction: TOrderDirection) => void
  reload: () => void
}

const useAuthTokensState = ({
  url,
  initialData,
  initialMeta,
}: IAuthTokensStateProps): IAuthTokensState => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [data, setData] = useState(initialData)
  const { order, updateOrder } = useOrderState()

  const requestParams = useMemo(
    () => ({
      page,
      sort_col: order?.column || undefined,
      sort_dir: order?.direction || undefined,
    }),
    [order, page],
  )

  const loadData = useCallback(
    async (params) => {
      try {
        setLoading(true)
        const response = await Axios.get(url, {
          params,
        })
        setLoading(false)

        setData(response.data.data)
      } catch (error) {
        window.flash('Something went wrong!', 'alert')
      }
    },
    [setLoading, setData],
  )

  const loadDataDebounced = useMemo(
    () =>
      _.debounce((params) => {
        loadData(params)
      }, 200),
    [loadData],
  )

  useEffectNotOnMount(() => {
    loadDataDebounced(requestParams)
  }, [loadDataDebounced, requestParams])

  const loadPage = useCallback(
    (requestedPage) => {
      setPage(requestedPage)
    },
    [setPage],
  )

  const reload = useCallback(() => {
    loadData(page)
  }, [loadData, page])

  return {
    loading,
    data,
    meta: initialMeta,
    loadPage,
    updateOrder,
    reload,
  }
}

export default useAuthTokensState
