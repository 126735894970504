import { useFeatureFlagsContext } from './FeatureFlagsContext'
import FeatureGroup from './FeatureGroup'
import { flagsStyle } from './styles'

interface IFeatureFlagsComponentProps {
  className?: string
}

export function FeatureFlagsComponent({
  ...props
}: IFeatureFlagsComponentProps): JSX.Element {
  const { groupIds } = useFeatureFlagsContext()
  return (
    <div {...props} css={flagsStyle}>
      {groupIds?.map((groupId) =>
        groupId !== '_' ? (
          <FeatureGroup key={groupId} groupId={groupId}></FeatureGroup>
        ) : null,
      )}
    </div>
  )
}
