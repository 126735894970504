import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'

export const SMaybeSkipAheadActionsRow = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: spacings.grid_gap_basis_num * 2,

  button: {
    width: 232,
  },
})

export const SMaybeSkipAheadContent = css({
  padding: `0 ${spacings.grid_gap_basis_num}px ${spacings.grid_gap_basis_num}px`,
  textAlign: 'center',
  marginBottom: spacings.grid_gap_basis_num * 5.25,

  h3: {
    color: colors.text.text_5,
    marginBottom: spacings.grid_gap_basis_num * 2,
  },

  p: {
    color: colors.text.text_3,
    fontWeight: 400,
    lineHeight: '22px',
  },
})

export const SMaybeSkipAheadIcon = css({
  margin: '0 auto',
  marginBottom: spacings.grid_gap_basis_num * 2,
  height: 38,
  width: 38,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
})

export const SMaybeSkipAheadHeroImage = css({
  maxWidth: '100%',
  overflow: 'hidden',
  marginBottom: spacings.grid_gap_basis_num * 5.25,
  textAlign: 'center',

  img: {
    maxWidth: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
  },
})
