import React from 'react'

import { Caption, SectionTitle } from 'components/Typography'

interface IFormSection {
  id?: string
  title: string | JSX.Element
  description?: string
  className?: string
  children: React.ReactNode
}

const FormSection = ({
  id,
  title,
  description,
  className,
  children,
}: IFormSection): React.ReactElement => (
  <section className={className} id={id}>
    <div className="section-header">
      <div className="header-left">
        {title instanceof String || typeof title === 'string' ? (
          <SectionTitle>{title}</SectionTitle>
        ) : (
          title
        )}
      </div>
    </div>
    {description && <Caption>{description}</Caption>}
    {children}
  </section>
)

export default FormSection
