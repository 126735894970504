import { useEffect } from 'react'
import cx from 'classnames'

import CompleteCallButton from './CompleteCallButton'

import { IEventCall, TActionLocation } from 'types'
import { useCallActionContext } from './context'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import Container from 'components/Container'

export interface ICallActionProps {
  data: IEventCall
  doMarkComplete?: boolean
  location?: TActionLocation
  className?: string
  features?: Record<string, boolean>
  invitable?: boolean
}

const defaultProps: Partial<ICallActionProps> = {
  location: 'table-cell',
  doMarkComplete: false,
  invitable: false,
}

const CallAction = (props: ICallActionProps): JSX.Element => {
  const {
    data: call,
    doMarkComplete,
    location,
    className,
    invitable,
  } = {
    ...defaultProps,
    ...props,
  }

  const { onMarkComplete } = useCallActionContext()

  useEffect(() => {
    if (doMarkComplete && !call.registered) {
      onMarkComplete()
    }
  }, [])

  return (
    <Container
      className={cx('event-call-action', className, {
        'on-card': location === 'card',
      })}>
      <CompleteCallButton
        call={call}
        location={location}
        aria-label="complete call to action"
        css={{
          maxWidth: '100%',
          flex: 1,
          minWidth: '50%',
        }}
      />
      {invitable && (
        <Button
          css={{
            flex: 1,
            whiteSpace: 'nowrap',
          }}
          className="share-event-call-btn"
          data-call-info={JSON.stringify(call)}
          as={ButtonComponentType.BUTTON}
          variant={Variant.SECONDARY}>
          Invite Others
        </Button>
      )}
    </Container>
  )
}

export default CallAction
