import { css } from '@emotion/react'
import React, { useCallback, useEffect } from 'react'
import ReactModal from 'react-modal'
import cx from 'classnames'

import CloseIcon from 'images/icons/close.svg'

import './Modal.scss'
import Container from 'components/Container'
import { colors, spacings } from 'stylesheets/theme'
import Avatar from 'components/Avatar/Avatar'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

ReactModal.setAppElement('body')

const hasActionsBothSidesCss = css({
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  width: '100%',
})

export enum Size {
  wide = 'wide',
  large = 'large',
  small = 'small',
}

export interface IModalProps {
  isOpen: boolean
  isSubmitDisabled?: boolean
  isCancelDisabled?: boolean
  size: Size
  onRequestClose: (fromModalX?: boolean) => void
  onSave: (e?: React.MouseEvent<HTMLButtonElement>) => void
  title?: string | React.ReactNode
  avatarImg?: string
  largeTitle?: boolean
  subtitle?: string | React.ReactNode
  cancelButton?: string
  submitButton?: string
  className?: string
  children: React.ReactNode
  actionButtonPosition?: 'left' | 'right'
  secondaryActionButton?: React.ReactNode
  actionRowLeftElement?: React.ReactNode
  addLineAfterSubtitle?: boolean
}

const defaultProps: Partial<IModalProps> = {
  largeTitle: false,
  subtitle: undefined,
  cancelButton: undefined,
  submitButton: undefined,
  secondaryActionButton: undefined,
  actionRowLeftElement: undefined,
  isSubmitDisabled: false,
  isCancelDisabled: false,
  className: '',
}

const mobileButtonsContainerStyle = css({
  width: '100%',
  '*': {
    flex: 1,
    '&:last-child': {
      marginRight: 0,
    },
  },
})

const Modal = (props: IModalProps): React.ReactElement => {
  const {
    isOpen,
    onRequestClose,
    title,
    avatarImg,
    largeTitle,
    subtitle,
    submitButton,
    cancelButton,
    secondaryActionButton,
    actionButtonPosition = 'left',
    actionRowLeftElement,
    onSave,
    size,
    className,
    children,
    isSubmitDisabled,
    isCancelDisabled,
    addLineAfterSubtitle = false,
  } = { ...defaultProps, ...props }

  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  //prevent background from scrolling when in Modal
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
    return () => {
      document.body.style.overflow = ''
    }
  })

  const onClickSubmit = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      onSave(e)
    },
    [onSave],
  )

  const onClickClose = useCallback(() => {
    onRequestClose(false)
  }, [onRequestClose])

  const onClickCloseX = useCallback(() => {
    onRequestClose(true)
  }, [onRequestClose])

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={`mindr-modal ${size} ${className}`}
      overlayClassName="mindr-modal-overlay"
      css={{
        overflowY: 'auto',
        margin: isMobile ? spacings.grid_gap_basis_num * 2 : 'auto',
      }}>
      {(avatarImg || title || subtitle) && (
        <Container
          css={{
            alignItems: 'flex-start',
            marginBottom: spacings.grid_gap_basis_num * 2,
            borderBottom:
              addLineAfterSubtitle &&
              subtitle &&
              '1px solid ' + colors.borders.gray,
          }}>
          {avatarImg && <Avatar avatarUrl={avatarImg} size={60} />}
          <Container
            direction="column"
            css={{
              alignItems: 'flex-start',
            }}>
            {title && (
              <h3 className={cx('mindr-modal-title', { large: largeTitle })}>
                {title}
              </h3>
            )}
            {subtitle && <p className="mindr-modal-subtitle">{subtitle}</p>}
          </Container>
        </Container>
      )}
      {!isMobile && (
        <button
          aria-label="close modal"
          type="button"
          className="mindr-modal-close not-styled-button"
          onClick={onClickCloseX}>
          <CloseIcon />
        </button>
      )}
      <div className="mindr-modal-body">{children}</div>
      {(submitButton || cancelButton || secondaryActionButton) && (
        <Container
          className="mindr-modal-actions"
          css={{
            justifyContent: 'space-between',
          }}>
          {cancelButton && actionButtonPosition == 'right' && (
            <button
              className="button cancel"
              type="button"
              onClick={onClickClose}
              disabled={isCancelDisabled}>
              {cancelButton}
            </button>
          )}
          <div css={hasActionsBothSidesCss}>
            <Container
              className="buttons-container"
              css={[isMobile && mobileButtonsContainerStyle]}>
              {secondaryActionButton &&
                actionButtonPosition == 'right' &&
                secondaryActionButton}
              {cancelButton && actionButtonPosition == 'left' && (
                <button
                  className="button cancel"
                  type="button"
                  onClick={onClickClose}
                  disabled={isCancelDisabled}>
                  {cancelButton}
                </button>
              )}
              {submitButton && (
                <button
                  type="button"
                  className="primary button"
                  onClick={onClickSubmit}
                  disabled={isSubmitDisabled}>
                  {submitButton}
                </button>
              )}
            </Container>
            {actionRowLeftElement && (
              <Container>{actionRowLeftElement}</Container>
            )}
            {secondaryActionButton &&
              actionButtonPosition == 'left' &&
              secondaryActionButton}
          </div>
        </Container>
      )}
    </ReactModal>
  )
}

export default Modal
