import { css } from '@emotion/react'

import Button, { Variant } from 'components/Button'

import { colors } from 'stylesheets/theme'

import MenuButton from 'images/icons/dots_vert.svg'
import TableCellWrapper from './TableCellWrapper'

interface MoreMenuTableCellProps {
  keyPrefix: string
  children: React.ReactNode[]
}

const menuStyles = css({
  display: 'flex',
  justifyContent: 'end',
  zIndex: 'auto',

  '.menu-toggler': {
    border: 'none',

    '&:hover': {
      border: 'none',
      background: colors.borders.gray,
    },
    '&:focus, &:active': {
      border: 'none',

      'svg path': {
        fill: colors.text.text_9,
      },
    },

    'svg path': {
      fill: colors.text.text_3,
    },
  },

  '.menu-body': {
    top: '100%',
    zIndex: 100,
  },
})

const MoreMenuTableCell = ({
  keyPrefix,
  children,
}: MoreMenuTableCellProps): JSX.Element => {
  return (
    <TableCellWrapper>
      <div css={menuStyles} className="menu-wrapper">
        <Button
          variant={Variant.UNSTYLED}
          className="menu-toggler"
          aria-label="menu">
          <MenuButton />
        </Button>
        <ul className="menu-body">
          {children.map((item, idx) => (
            <li className="menu-item" key={`menu-item-${keyPrefix}-${idx}`}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </TableCellWrapper>
  )
}

export default MoreMenuTableCell
