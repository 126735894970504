import useMediaQuery, { useComplexMediaQuery } from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { css } from '@emotion/react'
import { calcGridWidth, colors, sizes, spacings } from 'stylesheets/theme'
import Container from 'components/Container/Container'
import RecommendedResources from '../EventCallPage/RecommendedResources'
import NavigationTabs from 'components/NavigationTabs/NavigationTabs'
import {
  ICommunity,
  ITeamMember,
  IYearbookMember,
  Resource,
  ResourceType,
} from 'types'
import {
  DEFAULT_NAVIGATION_STYLE,
  DESKTOP_NAVIGATION_STYLE,
  PAGE_PADDING,
  PAGE_WIDTH,
  PAGE_WIDTH_AND_PADDING,
} from '../EventCallPage/constants'
import { useShowGridOverlay } from 'hooks/useShowGridOverlay'
import CommunityHeader from './CommunityHeader'
import CommunityContent from './CommunityContent'
import UpcomingActivities from './UpcomingActivities'
import ImageCarousel from './ImageCarousel'
import Button, { ButtonComponentType, Variant } from 'components/Button/Button'
import LeadershipTeam from './LeadershipTeam'
import Yearbook from './Yearbook'

const defaultContentWrapper = css({
  padding: `${spacings.grid_gap_basis_num * 2}px 0`,
  paddingLeft: calcGridWidth(1, 1) + PAGE_PADDING,
  paddingRight: PAGE_PADDING,
  paddingBottom: spacings.grid_gap_basis_num * 4,
  width: '100%',
  maxWidth: '100vw',
  marginLeft: 'auto',
  marginRight: 'auto',
})

const mobileContentWrapper = css({
  padding: `${spacings.grid_gap_basis_num * 2}px`,
  paddingBottom: spacings.grid_gap_basis_num * 4,
})

const desktopContentWrapper = css({
  width: PAGE_WIDTH_AND_PADDING,
})

const defaultDetailContainer = css({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  gap: spacings.grid_gap_basis_num * 2,
})

const mobileDetailContainer = css({
  // flexWrap: 'wrap-reverse',
  justifyContent: 'center',
  flexDirection: 'column-reverse',
  width: '100%',
})

const defaultResourcesContainer = css({
  padding: `${spacings.grid_gap_basis_num * 2}px ${PAGE_PADDING}px ${
    spacings.grid_gap_basis_num * 2
  }px ${calcGridWidth(1, 1) + PAGE_PADDING}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  gap: spacings.grid_gap_basis_num * 2,
  justifyContent: 'flex-start',
  width: '100%',
  maxWidth: '100%',
  span: {
    whiteSpace: 'unset',
  },
})

const desktopResourcesContainer = css({
  width: PAGE_WIDTH,
  maxWidth: `calc(100% - ${PAGE_PADDING * 2}px)`,
  paddingLeft: calcGridWidth(1, 1),
  paddingRight: 0,
})

const mobileResourcesContainer = css({
  padding: `0px calc(${spacings.grid_gap_basis_num}px * 2)`,
  maxWidth: '100vw',
})

const linkContainer = css({
  paddingTop: 30,
  paddingBottom: `calc(40px - ${spacings.grid_gap_basis})`,
})

const defaultPageStyle = css({ maxWidth: '100vw' })

const desktopPageStyle = css({
  marginBottom: `calc(${sizes.footerHeight} + ${
    spacings.grid_gap_basis_num * 4
  }px)`,
})

interface ICohort extends ICommunity {
  logo_url: string
  banner_url: string
}

interface IYearbook {
  total_count: number
  showing_count: number
  members: IYearbookMember[]
  view_all_url: string
}

interface EventPageProps {
  cohort: ICohort
  missionStatementHtml: string
  viewAllCohortsUrl: string
  images?: any[]
  upcomingActivities?: any[]
  leaders?: ITeamMember[]
  cohortTabVisible?: boolean
  yearbook?: IYearbook
  resources?: Resource<ResourceType>[]
}

export default function CohortPage({
  cohort,
  missionStatementHtml,
  viewAllCohortsUrl,
  images = [],
  upcomingActivities = [],
  leaders = [],
  yearbook,
  resources = [],
  cohortTabVisible = false,
}: EventPageProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const isFullDesktop = useComplexMediaQuery({ minWidth: breakpoints.xlg })
    .meetsDetails.minWidth
  return (
    <div css={[defaultPageStyle, !isMobile && desktopPageStyle]}>
      {useShowGridOverlay().overlay}
      {!isMobile && (
        <NavigationTabs
          cohortTabVisible={cohortTabVisible}
          activeTab="cohorts"
          css={[
            DEFAULT_NAVIGATION_STYLE,
            isFullDesktop && DESKTOP_NAVIGATION_STYLE,
          ]}
        />
      )}
      <CommunityHeader
        communityName={cohort.name}
        logoUrl={cohort.logo_url}
        bannerUrl={cohort.banner_url}
        isCohort
      />
      <Container
        css={[
          defaultContentWrapper,
          isFullDesktop && desktopContentWrapper,
          isMobile && mobileContentWrapper,
        ]}>
        <Container
          alignment="start"
          direction="column"
          css={{
            width: '100%',
          }}>
          <Container
            css={[defaultDetailContainer, isMobile && mobileDetailContainer]}>
            {isMobile && (
              <Button
                as={ButtonComponentType.LINK}
                variant={Variant.LINK}
                href={viewAllCohortsUrl}
                css={[
                  linkContainer,
                  css({
                    padding: 0,
                  }),
                ]}>
                View All Cohorts
              </Button>
            )}
            <Container direction="column" alignment="start">
              <CommunityContent
                css={{
                  maxWidth: isMobile ? '100%' : calcGridWidth(5),
                  flex: isMobile ? 1 : undefined,
                }}
                subtitle={cohort.subtitle}
                missionStatementHtml={missionStatementHtml}
                isCohort
              />
              {!isMobile && (
                <Button
                  as={ButtonComponentType.LINK}
                  variant={Variant.LINK}
                  href={viewAllCohortsUrl}
                  css={[
                    linkContainer,
                    css({
                      padding: 0,
                    }),
                  ]}>
                  View All Cohorts
                </Button>
              )}
            </Container>
            <ImageCarousel images={images} />
          </Container>
        </Container>
      </Container>
      {!!upcomingActivities.length && (
        <div
          css={{
            backgroundColor: colors.backgrounds.gray,
            marginBottom: 56,
            padding: `${spacings.grid_gap_basis_num * 2}px 0`,
          }}>
          <UpcomingActivities upcomingActivities={upcomingActivities} />
        </div>
      )}
      {!!leaders.length && <LeadershipTeam leaders={leaders} />}
      {!!yearbook && yearbook.total_count > 0 && (
        <Yearbook
          yearbookMembers={yearbook.members}
          viewAllUrl={yearbook.view_all_url}
          showingCount={yearbook.showing_count}
          totalCount={yearbook.total_count}
        />
      )}
      {!!resources.length && (
        <Container
          css={{
            width: '100%',
            backgroundColor: colors.backgrounds.page,
          }}>
          <Container
            css={[
              defaultResourcesContainer,
              isFullDesktop && desktopResourcesContainer,
              isMobile && mobileResourcesContainer,
            ]}>
            {!!resources.length && (
              <div
                css={{
                  position: 'relative',
                  left: isMobile ? 1 : 0,
                  flex: 1,
                  minWidth: isMobile ? '100%' : 335,
                  maxWidth: isMobile ? undefined : '50%',
                }}>
                <RecommendedResources resources={resources} />
              </div>
            )}
          </Container>
        </Container>
      )}
    </div>
  )
}
