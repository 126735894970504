import { css } from '@emotion/react'
import Container from 'components/Container'
import Modal, { IModalProps, Size } from 'components/Modal'
import { Caption, SectionTitle } from 'components/Typography'
import { colors, sizes, spacings } from 'stylesheets/theme'
import AdvanceFilterResults from './AdvanceFilterResults'
import { ICsvHistoryTable, IFilterItem } from './types'
import Table, { ITableProps } from 'components/Table'
import InfoIcon from 'images/icons/info.svg'
import { useMemo } from 'react'

const containerStyle = css({
  marginTop: spacings.grid_gap_basis_num * 3,
  backgroundColor: colors.backgrounds.page,
  width: '100%',
  minHeight: 360,
  padding: spacings.grid_gap_basis_num * 2,
  display: 'flex',
  gap: spacings.grid_gap_basis_num * 2,
  flexDirection: 'column',
  overflowY: 'scroll',
  maxHeight: 600,
})

const csvImportContainerStyle = css({
  minHeight: 300,
})

const advancedFiltersResultsBoxStyle = css({
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: spacings.grid_gap_basis_num * 2,
  gap: 0,
  backgroundColor: colors.backgrounds.white,
  boxShadow: '1px 1px 6px 0px #0000001A',
  marginBottom: spacings.grid_gap_basis_num * 3,
})

const infoIconStyle = css({
  display: 'inline-block',
  width: 14,
  height: 14,
  marginRight: spacings.grid_gap_basis,
  path: {
    fill: colors.states.warning,
  },
})

interface IInvitedSubscribersModalProps
  extends Omit<
    IModalProps,
    'largeTitle' | 'title' | 'size' | 'onSave' | 'cancelButton' | 'children'
  > {
  title?: string
  isSubsetAudience?: boolean
  isCsvImport?: boolean
  audienceCount?: number
  invitationReceivedDateTime: string
  audienceFilters?: IFilterItem[][]
  recipientsTableTitle?: string
  recipientsTableProps?: ITableProps
  importHistoryTableProps?: ICsvHistoryTable
  initiated?: boolean
  isRegisteredUsers?: boolean
  messageGenerator?: (data: Record<string, any>) => string
}

const emptyTableProps = {
  tableData: {
    rows: [],
    paginator: {
      current_page: 0,
      total_pages: 0,
      total_entries: 0,
      per_page: 6,
    },
  },
  tableMeta: {
    columns: [],
    url: '',
  },
}

export default function InvitedSubscribersModal({
  title = 'Invited users',
  isSubsetAudience = false,
  isCsvImport = false,
  audienceCount = 0,
  invitationReceivedDateTime,
  audienceFilters,
  isOpen,
  recipientsTableTitle = 'Invitation recipients',
  recipientsTableProps = emptyTableProps,
  importHistoryTableProps = emptyTableProps,
  initiated = false,
  isRegisteredUsers = false,
  messageGenerator,
  ...props
}: IInvitedSubscribersModalProps): JSX.Element {
  const style = [containerStyle]
  if (isCsvImport) {
    style.push(csvImportContainerStyle)
  }

  const message = useMemo(() => {
    if (messageGenerator) {
      const str: string | null = messageGenerator({
        audienceCount,
        isRegisteredUsers,
        initiated,
        invitationReceivedDateTime,
      })
      if (str !== null) {
        return str
      }
    }

    if (audienceCount === 1) {
      if (isRegisteredUsers) {
        if (initiated) {
          return 'user is being added to the events as of'
        } else {
          return 'user has been added to the events on'
        }
      } else {
        if (initiated) {
          return 'invitation is in the process of being sent out as of'
        } else {
          return 'user received an invitation on'
        }
      }
    } else {
      if (isRegisteredUsers) {
        if (initiated) {
          return 'users are being added to the events as of'
        } else {
          return 'users have been added to the events on'
        }
      } else {
        if (initiated) {
          return 'invitations are in the process of being sent out as of'
        } else {
          return 'users received invitations on'
        }
      }
    }
  }, [isRegisteredUsers, initiated, audienceCount, invitationReceivedDateTime])

  const caption = useMemo(() => {
    return (
      <>
        <b>{audienceCount}</b> {message} {invitationReceivedDateTime}
      </>
    )
  }, [audienceCount, invitationReceivedDateTime, message])

  return (
    <Modal
      isOpen={isOpen}
      largeTitle
      title={title}
      size={Size.wide}
      css={{
        '&.wide': {
          maxWidth: 1172,
          maxHeight: '90%',
        },
      }}
      onSave={() => {}}
      cancelButton="Close"
      {...props}>
      <div css={style}>
        <div>
          {!isCsvImport &&
          !importHistoryTableProps?.tableData?.rows &&
          !audienceFilters ? null : (
            <>
              <Container
                css={{
                  justifyContent: 'space-between',
                  marginBottom: spacings.grid_gap_basis_num * 3,
                }}>
                {isSubsetAudience && (
                  <SectionTitle
                    css={{
                      color: colors.text.text_5,
                    }}>
                    {isCsvImport ? 'Uploaded csv files' : 'Specified Filters'}
                  </SectionTitle>
                )}
                <Caption>{caption}</Caption>
              </Container>
              <div
                css={{
                  marginBottom: spacings.grid_gap_basis_num * 3,
                }}>
                {isSubsetAudience &&
                  (isCsvImport ? (
                    <Table
                      css={{
                        tbody: {
                          tr: {
                            height: sizes.tableRowMinHeight,
                          },
                        },
                        tfoot: {
                          tr: {
                            height: sizes.tableRowMinHeight,
                          },
                        },
                      }}
                      {...importHistoryTableProps}
                    />
                  ) : (
                    <AdvanceFilterResults
                      css={advancedFiltersResultsBoxStyle}
                      resultingFilterGroups={
                        audienceFilters
                      }></AdvanceFilterResults>
                  ))}
              </div>
            </>
          )}
          <Table title={recipientsTableTitle} {...recipientsTableProps} />
          <div
            css={{
              marginTop: spacings.grid_gap_basis_num * 3,
            }}>
            <InfoIcon css={infoIconStyle} />
            <Caption
              css={{
                display: 'inline-block',
              }}>
              These location and department fields may be left empty due to
              privacy reasons or an incomplete data source. Please reach out to
              your organization admin if you have any questions.
            </Caption>
          </div>
        </div>
      </div>
    </Modal>
  )
}
