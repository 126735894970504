import React, { useState, useRef, useEffect } from 'react'
import produce, { enableES5 } from 'immer'
enableES5()
import axios from 'axios'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'

// styles
import './MeetingFollowups.scss'

// components
import Input from 'components/Input'

// icons
import DeleteIcon from '../../images/icons/delete.svg'

// utils
import { IUser, useAdditionalSectionRows } from 'javascripts/general'

interface IMeetingFollowup {
  id?: number
  name?: string
  responsible_user?: IUser
  notes?: number
  _destroy?: boolean
  errors?: Record<string, string[]>
}

interface IMeetingFollowups {
  initialValues: IMeetingFollowup[]
  rootEntity: string
  leadersUrl: string
}

const MeetingFollowups: React.FC<IMeetingFollowups> = ({
  initialValues,
  rootEntity,
  leadersUrl,
}) => {
  const [meetingFollowups, changeMeetingFollowups] = useState(
    initialValues.map((meetingFollowup) => ({
      ...meetingFollowup,
      fake_id: uuidv4(),
    })),
  )

  const [initialResponsibleUser, setInitialResponsibleUser] = useState<IUser[]>(
    [],
  )

  useEffect(() => {
    loadOptions('', setInitialResponsibleUser)
  }, [])

  const componentRef = useRef()

  const addRow = () => {
    changeMeetingFollowups(
      produce((draft) => {
        draft.push({ fake_id: uuidv4() })
      }),
    )
  }

  const cleanRows = () => {
    changeMeetingFollowups(
      produce((draft) => {
        draft.forEach((row) => (row._destroy = true))
      }),
    )
  }

  const loadOptions = _.debounce((inputValue, callback) => {
    axios
      .get(leadersUrl, {
        params: {
          'filter[*]': inputValue,
        },
      })
      .then(({ data: { data } }) => {
        callback(data.rows.map((row) => row.data))
      })
  }, 400)

  const renderedComponentIndex = meetingFollowups.findIndex(
    (meetingFollowup) => !meetingFollowup._destroy,
  )

  useAdditionalSectionRows(
    renderedComponentIndex !== -1,
    componentRef,
    addRow,
    cleanRows,
  )

  return (
    <div
      className="meeting-minutes additional-section-component"
      ref={componentRef}>
      {meetingFollowups.map(
        (
          { id, responsible_user, name, notes, fake_id, _destroy, errors },
          index,
        ) => {
          const onInputChange = (editedObj) => {
            changeMeetingFollowups(
              produce((draft) => {
                draft[index] = { ...draft[index], ...editedObj }
              }),
            )
          }

          const showLabels = renderedComponentIndex === index

          return (
            <div
              className={classNames(
                'meeting-minutes-item-row additional-section-row',
                {
                  hidden: _destroy,
                },
              )}
              key={id || fake_id}>
              {!!id && (
                <input
                  type="hidden"
                  name={`${rootEntity}[followups_attributes][${index}][id]`}
                  value={id}
                />
              )}
              {_destroy && (
                <input
                  type="hidden"
                  name={`${rootEntity}[followups_attributes][${index}][_destroy]`}
                  value="true"
                />
              )}
              <Input
                name="name"
                inputName={`${rootEntity}[followups_attributes][${index}][name]`}
                value={name}
                label={showLabels && 'Follow-up Item'}
                onChange={onInputChange}
                error={errors?.name?.join(', ')}
                wrapperClassName="one"
              />
              <Input<IUser>
                name="responsible_user"
                inputName={`${rootEntity}[followups_attributes][${index}][responsible_user_id]`}
                selectValue={responsible_user?.id && responsible_user}
                label={showLabels && 'Person Responsible'}
                type="async_select"
                loadOptions={loadOptions}
                getOptionLabel={(option) => option.full_name}
                getOptionValue={(option) => option.id}
                onChange={onInputChange}
                noOptionsMessage="Select Employee"
                wrapperClassName="two"
                defaultOptions={initialResponsibleUser}
              />
              <Input
                name="notes"
                inputName={`${rootEntity}[followups_attributes][${index}][notes]`}
                value={notes}
                label={showLabels && 'Notes'}
                onChange={onInputChange}
                wrapperClassName="three"
              />
              <button
                className={classNames('not-styled-button delete', {
                  first: showLabels,
                })}
                type="button"
                onClick={() => {
                  onInputChange({ _destroy: true })
                }}>
                <DeleteIcon />
              </button>
            </div>
          )
        },
      )}
      <button className="link" type="button" onClick={addRow}>
        + Add Item
      </button>
    </div>
  )
}

export default MeetingFollowups
