import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'

const buttonsContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  '& > *': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: spacings.grid_gap_basis_num * 2,
    fontWeight: 700,
    fontSize: 15,
    lineHeight: '22px',
    letterSpacing: 0.5,
    textDecoration: 'none',
    color: colors.text.text_3,
    '&:hover': {
      opacity: 0.8,
      color: colors.text.text_5,
    },
  },
})

interface ButtonsContainerProps {
  children: React.ReactNode
  className?: string
}

export default function ButtonsContainer({
  children,
  className = '',
}: ButtonsContainerProps): JSX.Element {
  return (
    <div css={buttonsContainerStyle} className={className}>
      {children}
    </div>
  )
}
