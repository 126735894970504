import React from 'react'
import cx from 'classnames'

//assets
import CalendarIcon from '../../images/icons/event_14.svg'
import ClockIcon from '../../images/icons/clock.svg'
import LocationIcon from '../../images/icons/location.svg'
import GlobeIcon from '../../images/icons/globe.svg'
import CohortIcon from '../../images/icons/cohort_14.svg'
import CallToActionIcon from '../../images/icons/call_18.svg'
import RecurringIcon from '../../images/icons/recurring_14.svg'

import CohortPill from 'components/CohortPill'
import EventAction from 'components/EventAction'
import CallAction from 'components/CallAction'

//styles
import './EventCall.scss'

//utils
import { convertUTCToLocalRange } from 'javascripts/general'

import type { IEventCall, IEventCallHost } from 'types'

export type { IEventCall, IEventCallHost }

type EventCallProps = {
  data: IEventCall
  isCall?: boolean
  isSmall?: boolean
}

const EventCall: React.FC<EventCallProps> = ({ data, isCall, isSmall }) => {
  const dateForBadge = data
    ? (isCall
        ? data.date
        : convertUTCToLocalRange(data.datetime, data.duration)
      ).split(',')
    : null

  const [nextCohost, ...remainingCohosts] = data.cohosts || []

  return (
    <div
      className={cx('event-call-div', {
        small: isSmall,
      })}>
      <a
        href={data.link}
        className={cx('event content-type', {
          action: isCall,
          subscribed: data.registered,
        })}>
        {isCall ? (
          <div className="call-avatar">
            <CallToActionIcon />
          </div>
        ) : (
          <div className="date-badge">
            <p className="day">
              {dateForBadge && dateForBadge[1].trim().split(' ')[0]}
            </p>
            <h1 className="date">
              {dateForBadge &&
                dateForBadge[1].trim().split(' ')[1].slice(0, -2)}
            </h1>
            <p className="day">{dateForBadge && dateForBadge[0]}</p>
          </div>
        )}
        <div className="content">
          <div
            className="event-logo"
            style={{ backgroundImage: `url(${data.logo})` }}
          />
          <div className="content-text">
            <p className="type">
              <span className="primary">
                {isCall ? 'Call to Action' : 'Event'}
              </span>
              {data.community_type === 'cohort' && <CohortPill size="small" />}
            </p>
            <h3 className="title">{data.title}</h3>
            <div className="detail">
              {isCall ? (
                <>
                  <ClockIcon />
                  <p className="caption">{data.time}</p>
                </>
              ) : (
                <>
                  {data.is_recurring ? (
                    <RecurringIcon className="icon-stroke" />
                  ) : (
                    <CalendarIcon className="icon-stroke" />
                  )}
                  <p className="caption">
                    {convertUTCToLocalRange(data.datetime, data.duration)}
                  </p>
                </>
              )}
            </div>
            {data.location !== 'None' && (
              <div className="detail">
                <LocationIcon />
                <p className="caption">{data.location || 'Virtual'}</p>
              </div>
            )}
            <div className="detail">
              {data.community_type === 'cohort' ? (
                <CohortIcon className="icon-stroke" />
              ) : (
                <GlobeIcon />
              )}
              <p className="caption">{data.community}</p>
            </div>
            {nextCohost && remainingCohosts.length === 0 && (
              <div className="detail">
                <span className="empty-icon"></span>
                <p className="cohost caption">{nextCohost.name}</p>
              </div>
            )}
            {nextCohost && remainingCohosts.length > 0 && (
              <div className="detail">
                <span className="empty-icon"></span>
                <p className="cohost caption">
                  +{remainingCohosts.length + 1} more communities
                </p>
              </div>
            )}
          </div>
        </div>
      </a>
      {isCall ? (
        <CallAction data={data} location="card" />
      ) : (
        <EventAction data={data} location="card" />
      )}
    </div>
  )
}

export default EventCall
