import { ITableProps } from 'components/Table'

export const filterOptions = [
  '',
  'Location',
  'Department',
  'Title',
  'Name',
] as const
export type FilterOption = (typeof filterOptions)[number]

export const filterTypes = ['locations', 'titles', 'departments'] as const
export type FilterType = (typeof filterTypes)[number]

export interface IFilterItem {
  filterType: FilterOption
  filterInputs: any[]
}

export interface ILocationObject {
  city: string
  state: string
}

export interface IAllFilterOptions {
  locations: ILocationObject[]
  titles: string[]
  departments: string[]
}
export const defaultAllFilterOptions: IAllFilterOptions = {
  locations: [],
  titles: [],
  departments: [],
}

export interface ICsvHistoryTable extends ITableProps {
  exampleCsvUrl?: string
}
