import Container from 'components/Container/Container'
import { spacings } from 'stylesheets/theme'
import { IProgramSummary } from 'types'
import ParticipantInformationCard from './ParticipantInformationCard'
import LeadershipCard from './LeadershipCard'
import { SectionTitle } from 'components/Typography'
import NextStepsCard from './NextStepsCard'
import ResourcesCard from 'components/ResourcesCard/ResourcesCard'

export interface MentorshipDetailsSummaryProps {
  programSummary: IProgramSummary
  isMobile?: boolean
}

export const mobileMinWidth = 335
// the max width of the content area in the mentorship details page
// Sum of the widths of the two cards in the mentorship details page + gap
const maxContentWidth = 614 + 3 * spacings.grid_gap_basis_num + 614

export default function MentorshipDetailsSummary({
  programSummary,
  isMobile = false,
}: MentorshipDetailsSummaryProps): JSX.Element {
  const {
    current_program,
    me,
    my_partner,
    additional_leaders,
    cohort,
    reasoning,
    program_display_detail_options,
  } = programSummary

  return (
    <>
      <Container
        css={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: spacings.grid_gap_basis,
          maxWidth: maxContentWidth,
          marginBottom: spacings.grid_gap_basis_num * 3,
        }}>
        <h1>{current_program.full_name}</h1>
        <p>
          Welcome to the Mindr Mentorship Exchange. You have been selected and
          paired with a specific colleague for this program due to your unique
          ability to drive forward each other&apos;s measurable goals.
          Participation in the program requires a 5-hour time commitment, split
          over 5 mentorship sessions.
        </p>
      </Container>
      <SectionTitle>overview</SectionTitle>
      <Container
        css={{
          alignItems: 'flex-start',
          flexWrap: isMobile ? 'wrap' : 'nowrap',
          width: '100%',
          maxWidth: '100%',
          flexGrow: 1,
          gap: 2 * spacings.grid_gap_basis_num,
        }}>
        <ParticipantInformationCard
          me={me}
          partner={my_partner}
          reasoning={reasoning}
          css={{
            width: '100%',
            minWidth: isMobile ? undefined : mobileMinWidth,
            maxWidth: 614,
          }}
          isMobile={isMobile}
          displayDetailOptions={program_display_detail_options}
        />
        <Container
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
            minWidth: isMobile ? undefined : mobileMinWidth,
            maxWidth: 614,
            gap: 2 * spacings.grid_gap_basis_num,
            flexShrink: 0,
            flexGrow: 1,
            flexBasis: '0px',
          }}>
          <LeadershipCard
            leaders={programSummary?.cohort?.leaders ?? []}
            additionalLeaders={additional_leaders}
          />
          <NextStepsCard
            nextSteps={programSummary?.next_steps}
            cohort_url={cohort?.link}
          />
          <ResourcesCard
            resources={cohort?.resources}
            subtitle="Get the most out of the program with these helpful resources"
            css={{
              width: '100%',
              span: {
                whiteSpace: 'unset',
              },
            }}
          />
        </Container>
      </Container>
    </>
  )
}
