import Container from 'components/Container/Container'
import { Paragraph } from 'components/Typography'
import { ICommunity } from 'types'
import CohortIcon from 'images/icons/cohort_18.svg'
import WorldIcon from 'images/icons/world.svg'
import LocationIcon from 'images/icons/location.svg'
import VideoCallIcon from 'images/icons/video_call.svg'
import AlliesWelcomeIcon from 'images/icons/allies.svg'
import CalendarIcon from 'images/icons/calendar.svg'
import LinkIcon from 'images/icons/chain_45.svg'
import { css } from '@emotion/react'
import Button, { ButtonComponentType, Variant } from 'components/Button/Button'
import { colors, spacings } from 'stylesheets/theme'

interface EventCallContentProps {
  subtitle: string
  host: ICommunity
  cohosts?: ICommunity[]
  isCohort?: boolean
  className?: string
  locationInPerson?: boolean
  location?: string
  locationVirtual?: boolean
  locationUrl?: string
  alliesWelcome?: boolean
  isCall?: boolean
  openUntilDate?: string
  descriptionHtml?: string
}

const iconStyle = css({
  minWidth: 18,
  minHeight: 18,
  path: {
    fill: colors.blue,
  },
})

const callIconStyle = css({
  path: {
    fill: colors.orange_dark,
  },
})

export default function EventCallContent({
  subtitle,
  host,
  cohosts = [],
  isCohort = false,
  className = '',
  locationInPerson = false,
  location,
  locationVirtual = false,
  locationUrl,
  alliesWelcome = false,
  isCall = false,
  openUntilDate,
  descriptionHtml,
}: EventCallContentProps): JSX.Element {
  return (
    <Container
      direction="column"
      css={{ alignItems: 'flex-start', gap: spacings.grid_gap_basis_num / 2 }}
      className={className}>
      <h2>{subtitle}</h2>
      <div
        className="mindr-rich-content"
        dangerouslySetInnerHTML={{ __html: descriptionHtml }}></div>
      <Container direction="column" css={{ alignItems: 'flex-start' }}>
        <Container
          css={{
            alignItems: 'flex-start',
            flexWrap: 'wrap',
          }}>
          <Container>
            {isCohort ? (
              <CohortIcon css={[iconStyle, isCall && callIconStyle]} />
            ) : (
              <WorldIcon css={[iconStyle, isCall && callIconStyle]} />
            )}
            <Paragraph>{isCall ? 'Posted by' : 'Hosted by'}</Paragraph>
          </Container>
          <Container
            direction="column"
            css={{
              alignItems: 'flex-start',
            }}>
            <Button
              as={ButtonComponentType.LINK}
              variant={Variant.LINK}
              href={host.link}>
              {host.name}
            </Button>
            {!!cohosts.length &&
              cohosts.map((cohost, index) => (
                <Button
                  key={index}
                  as={ButtonComponentType.LINK}
                  variant={Variant.LINK}
                  href={cohost.link}>
                  {cohost.name}
                </Button>
              ))}
          </Container>
        </Container>
        {locationInPerson && (
          <Container
            css={{
              alignItems: 'flex-start',
            }}>
            <LocationIcon css={[iconStyle, isCall && callIconStyle]} />
            <Paragraph>{location}</Paragraph>
          </Container>
        )}
        {locationVirtual && (
          <Container
            css={{
              flexWrap: 'wrap',
            }}>
            {isCall ? (
              <LinkIcon css={[iconStyle, isCall && callIconStyle]} />
            ) : (
              <VideoCallIcon css={[iconStyle, isCall && callIconStyle]} />
            )}
            <Paragraph>{isCall ? 'Link:' : 'Meeting link:'}</Paragraph>
            <Button
              as={ButtonComponentType.LINK}
              variant={Variant.LINK}
              href={locationUrl}>
              {locationUrl}
            </Button>
          </Container>
        )}
        {openUntilDate && (
          <Container
            css={{
              flexWrap: 'wrap',
            }}>
            <CalendarIcon css={[iconStyle, isCall && callIconStyle]} />
            <Paragraph>Open until: {openUntilDate}</Paragraph>
          </Container>
        )}
      </Container>
      {alliesWelcome && (
        <AlliesWelcomeIcon
          css={{
            marginTop:
              spacings.grid_gap_basis_num * 2 - spacings.grid_gap_basis_num / 2,
            width: 171,
            height: 16,
          }}
        />
      )}
    </Container>
  )
}
