import { colors } from 'stylesheets/theme'
import Button, {
  ButtonComponentType,
  Variant,
  ButtonComponentProps,
} from './Button'
import CopyIcon from 'images/icons/copy.svg'
import { useCallback } from 'react'

export default function CopyLinkButton({
  ...props
}: ButtonComponentProps<ButtonComponentType.LINK>): JSX.Element {
  const handleOnCopy = useCallback(
    (event) => {
      event.preventDefault()
      navigator.clipboard.writeText(event.target.href)
      window.flash('Link copied to clipboard', 'success')
    },
    [props.href],
  )
  return (
    <Button
      id="copy-survey-link-button"
      as={ButtonComponentType.LINK}
      variant={Variant.LINK}
      onClick={handleOnCopy}
      {...props}>
      <CopyIcon
        css={{
          width: 20,
          height: 20,
          marginRight: 6,
          path: {
            fill: colors.links.blue,
          },
        }}
      />{' '}
      Copy survey link
    </Button>
  )
}
