import cx from 'classnames'
import {
  SCircleItemStyle,
  SCircleListStyle,
  SItemCircleStyle,
} from './NumberedCircleList.style'
import { INumberedCircleList } from './NumberedCircleList.types'

export default function NumberedCircleList({
  items = [],
  className = '',
}: INumberedCircleList): JSX.Element {
  return (
    <div css={SCircleListStyle} className={className}>
      {items.map((item, index) => {
        const { color = 'green', light = true, dark = false, content } = item
        const className = cx(
          { [color]: true },
          { light: light },
          { dark: dark },
        )
        return (
          <div key={`list-item-${index}`} css={SCircleItemStyle}>
            <div css={SItemCircleStyle} className={className}>
              {index + 1}
            </div>
            <div>{content}</div>
          </div>
        )
      })}
    </div>
  )
}
