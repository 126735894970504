import { useMemo, useState, useEffect, useCallback } from 'react'
import cx from 'classnames'

import { useWindowDimensions } from 'hooks/useDimensions'
import { useEffectNotOnMount } from 'javascripts/general'
import { setBoolean, getBoolean } from 'javascripts/storage'

import NavbarContext, { ContextType } from './NavbarContext'
import BasicInfo from './BasicInfo'
import Nav from './Nav/Nav'

import CollapseIcon from 'images/icons/left_arrow.svg'
import ExpandIcon from 'images/icons/right_arrow.svg'
import LogoutIcon from 'images/icons/logout.svg'
import CloseIcon from 'images/icons/close.svg'

import { breakpoints } from 'stylesheets/breakpoints'
import './Navbar.scss'

import { TCommunityType, User, Context } from 'types'
import { css } from '@emotion/react'
import { colors, sizes, spacings, zIndex } from 'stylesheets/theme'
import { useComplexMediaQuery } from 'hooks/useMediaQuery'

export interface Nav {
  name: string
  path: string
  icon: string
}

export interface NavSection {
  [itemKey: string]: Nav
}

const SIDEBAR_STATE_STORAGE_KEY = 'mindrSidebarStateIsCollapsed'

interface NavItems {
  community: { [sectionKey: string]: NavSection }
  organization: { [sectionKey: string]: NavSection }
  other: { [sectionKey: string]: NavSection }
}

interface Path {
  [path_name: string]: string
}

interface NavbarProps {
  currentUser: User
  context: Context
  contextType: ContextType | string
  isAdmin: boolean
  titles: any
  navItems: NavItems
  paths: Path
  controllerName: string
  communityType: TCommunityType
  logoUrl: string
  isResponsivePage?: boolean
  showDesktopSidebar?: boolean
}

const mobileWidthStyles = css({
  width: '100%',
})

const basicInfoSectionStyles = css({
  width: sizes.navBarWidth,
  overflow: 'hidden',
  padding: `${sizes.mainVerticalPadding}px ${
    sizes.navBarHorizontalRightPadding
  }px ${spacings.grid_gap_basis_num * 2}px ${
    sizes.navBarHorizontalLeftPadding
  }px`,
  borderBottom: `1px solid ${colors.borders.gray}`,
})

const animBasicInfoStyles = css({
  transition: 'padding-left 0.5s ease-in, width 0.5s ease-in',
})

const basicInfoSectionCollapsedStyles = css({
  width: sizes.navBarCollapsedWidth,
  paddingLeft: (sizes.navBarCollapsedWidth - sizes.avatarSize) / 2,
  paddingRight: (sizes.navBarCollapsedWidth - sizes.avatarSize) / 2,
})

const collapseButtonSize = 32

const collapseButtonStyles = css({
  position: 'fixed',
  top: `calc(${sizes.headerHeight} + ${sizes.mainVerticalPadding}px)`,
  left: sizes.navBarWidth - collapseButtonSize / 2,
  width: collapseButtonSize,
  height: collapseButtonSize,
  borderRadius: '50%',
  border: 'none',
  backgroundColor: colors.backgrounds.white,
  cursor: 'pointer',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
  zIndex: zIndex.sidebar,

  '&:hover': {
    backgroundColor: colors.backgrounds.gray,
  },

  svg: {
    height: 14,
    width: 14,
    verticalAlign: 'middle',
  },
})

const animCollapseButtonStyles = css({
  transition: 'left 0.5s ease-in',
})

const expandButtonStyles = css({
  left: sizes.navBarCollapsedWidth - collapseButtonSize / 2,
})

const closeMenuIconStyles = css({
  height: 18,
  width: 18,
  position: 'absolute',
  top: (1 + 2 / 3) * spacings.grid_gap_basis_num,
  right: 2 * spacings.grid_gap_basis_num,

  path: {
    fill: colors.text.text_5,
  },
})

const mobileNavTriggerStyles = css({
  position: 'fixed',
  zIndex: 6,
  top: 0,
  right: 20,
  width: 40,
  height: 40,
  backgroundColor: 'rgba(0, 0, 0, 0.01)', // Hack to consume taps even though invisible.
})

/**
 * collapsible navigation bar on the left of the viewport
 */
export default function Navbar({
  currentUser,
  context,
  contextType,
  isAdmin,
  titles,
  navItems,
  paths,
  controllerName,
  communityType,
  logoUrl,
  isResponsivePage = false,
  showDesktopSidebar = false,
}: NavbarProps): JSX.Element {
  const mqResult = useComplexMediaQuery({
    maxWidth: 430,
    orientation: 'portrait',
  })
  const isMobile = mqResult.meetsAll
  const showNavbar = useMemo(() => {
    return showDesktopSidebar || isMobile
  }, [showDesktopSidebar, isMobile])
  const showOnTheSide = useMemo(() => {
    return !(isResponsivePage && isMobile) && showNavbar //Show sidebar at the top
  }, [isResponsivePage, isMobile, showNavbar])
  useEffect(() => {
    const element = document.querySelector('#main.main')
    if (!showOnTheSide) {
      element.classList.add('without-sidebar')
      element.classList.remove('with-sidebar')
    } else {
      element.classList.add('with-sidebar')
      element.classList.remove('without-sidebar')
    }
  }, [showOnTheSide])

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false)
  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }, [isMobileMenuOpen])

  const closestBreakpoint = useWindowDimensions(breakpoints.lg, breakpoints.xs)

  const [fakeCollapsed, setCollapsed] = useState(
    isMobile ||
      isResponsivePage ||
      getBoolean(
        SIDEBAR_STATE_STORAGE_KEY,
        closestBreakpoint === breakpoints.lg,
      ),
  )
  const collapsed = !showOnTheSide ? false : fakeCollapsed
  const [fullBackText, setFullBackText] = useState<string | null>(null)
  const [backLink, setBackLink] = useState<string | null>(null)

  const toggleCollapsed = useCallback(
    (state: boolean) => {
      setCollapsed(state)
      setBoolean(SIDEBAR_STATE_STORAGE_KEY, state)
    },
    [setCollapsed],
  )

  // Only run this once at first render to work out what the
  // initial collapsed state should be.
  useEffect(() => {
    const isCollapsedStored = getBoolean(
      SIDEBAR_STATE_STORAGE_KEY,
      closestBreakpoint === breakpoints.lg,
    )

    setCollapsed(isCollapsedStored)
  }, [])

  const [firstRender, setFirstRender] = useState(true)
  // Collapses #main.main.with-sidebar container padding-left value
  useEffect(() => {
    const main = document.querySelector('#main.main.with-sidebar')
    if (!main) return

    if (collapsed) {
      if (firstRender) {
        main.classList.add('no-anim')
      } else {
        main.classList.remove('no-anim')
      }
      main.classList.add('collapsed')
    } else {
      main.classList.remove('collapsed')
      main.classList.remove('no-anim')
    }
    setTimeout(() => {
      setFirstRender(false)
    }, 500)
  }, [collapsed, firstRender])

  /**
   * Set "back" path and text
   */
  useEffect(() => {
    if (contextType === 'community') {
      if (isAdmin) {
        setFullBackText(titles[communityType].back)
      } else {
        setFullBackText('Back to Leadership Dashboards')
      }
    } else {
      if (!!context) {
        setFullBackText(titles.default.back)
      }
    }

    // These links should apply, even when collapsed
    if (contextType === 'community') {
      if (isAdmin) {
        setBackLink(paths[`dashboard_${communityType}_path`])
      } else {
        setBackLink(paths.dashboard_leaderships_path)
      }
    } else if (contextType === 'mentorship') {
      if (isAdmin) {
        setBackLink(paths.mentorship)
      }
    } else if (contextType === 'leadr') {
      setBackLink(paths.leadr_management_path)
    } else {
      if (!!context) {
        setBackLink(paths.root_path)
      }
    }
  }, [contextType, isAdmin, collapsed, titles, communityType, paths, context])

  /**
   * Dynamically collapses navbar based on viewport width
   */
  useEffectNotOnMount(() => {
    toggleCollapsed(closestBreakpoint === breakpoints.lg)
  }, [closestBreakpoint])

  const contextName = useMemo(() => context?.name, [context])
  const currentUserName = useMemo(
    () => currentUser.first_name + ' ' + currentUser.last_name,
    [currentUser],
  )
  const currentUserPronouns = useMemo(() => currentUser.pronouns, [currentUser])
  const currentUserTitle = useMemo(() => currentUser.title, [currentUser])

  if (!showNavbar) {
    return null
  }

  return (
    <NavbarContext.Provider
      value={{
        collapsed,
        contextType,
        isAdmin,
        contextName,
        currentUserName,
        currentUserPronouns,
        currentUserTitle,
        logoUrl,
        titles,
        navItems,
        paths,
        controllerName,
        communityType,
      }}>
      <div
        className={cx('navbar-container', {
          'mobile-nav': !showOnTheSide,
          'mobile-nav-open': isMobileMenuOpen,
          collapsed: !showOnTheSide ? false : collapsed,
          'no-anim': firstRender || !showOnTheSide,
        })}
        id="navbar-container">
        {!showOnTheSide ? (
          <CloseIcon onClick={toggleMobileMenu} css={closeMenuIconStyles} />
        ) : null}
        {!showOnTheSide ? null : (
          <button
            css={[
              collapseButtonStyles,
              !firstRender && animCollapseButtonStyles,
              collapsed && expandButtonStyles,
            ].filter(Boolean)}
            onClick={() => toggleCollapsed(!collapsed)}>
            {collapsed ? (
              <span title={titles?.sidebar?.expand ?? 'Expand'}>
                <ExpandIcon />
              </span>
            ) : (
              <span title={titles?.sidebar?.collapse ?? 'Collapse'}>
                <CollapseIcon />
              </span>
            )}
          </button>
        )}
        <section
          css={[
            basicInfoSectionStyles,
            !showOnTheSide && mobileWidthStyles,
            !firstRender && animBasicInfoStyles,
            collapsed && basicInfoSectionCollapsedStyles,
          ].filter(Boolean)}>
          <div className="header">
            <a
              className={cx('link', { collapsed: collapsed })}
              title={fullBackText}
              href={backLink}>
              <span className="when-collapsed anim-opacity">Back</span>
              <span className="when-not-collapsed anim-opacity">
                {fullBackText}
              </span>
            </a>
          </div>
          <BasicInfo />
        </section>
        <aside className={cx('navbar', { collapsed: collapsed })} id="sidebar">
          <Nav />
          <div className="bottom">
            {!collapsed ? (
              <a className="link logout" href={paths.destroy_user_session_path}>
                Log out
              </a>
            ) : (
              <a className="logout-icon" href={paths.destroy_user_session_path}>
                <LogoutIcon />
              </a>
            )}
          </div>
        </aside>
      </div>
      {!showOnTheSide ? (
        <div
          className="mobile-nav-trigger"
          css={mobileNavTriggerStyles}
          onClick={toggleMobileMenu}></div>
      ) : null}
    </NavbarContext.Provider>
  )
}
