import { css } from '@emotion/react'
import { colors } from 'stylesheets/theme'

const defaultAvatarSize = 30

const avatarStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: colors.backgrounds.white,
  backgroundColor: colors.teal,
  borderRadius: '50%',
})

interface DefaultAvatarProps {
  userInitial: string
  size?: number
}

export default function DefaultAvatar({
  userInitial,
  size = defaultAvatarSize,
}: DefaultAvatarProps): JSX.Element {
  return (
    <div
      css={[
        avatarStyle,
        css({
          lineHeight: `${size}px`,
          width: size,
          height: size,
        }),
      ]}>
      {userInitial}
    </div>
  )
}
