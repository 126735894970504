import { TUserFilters } from 'state/Users'
import {
  ILeadrCumulativeReportProps,
  IReportResults,
} from './LeadrCumulativeReport.types'
import React, { useContext, useEffect, useState } from 'react'
import {
  DEFAULT_FILTER_OPTIONS,
  TFilterOptions,
} from 'components/MembershipFilters'

interface ILeadrCumulativeReportContext extends ILeadrCumulativeReportProps {
  setUserFilters: (filters: Partial<TUserFilters>) => void
  setFilterOptions: (options: TFilterOptions) => void
  setResults: (results: IReportResults) => void
}

interface ILeadrCumulativeReportContextProps
  extends ILeadrCumulativeReportProps {
  children: React.ReactNode
}

const LeadrCumulativeReportContext = React.createContext(
  {} as ILeadrCumulativeReportContext,
)

const DEFAULT_USER_FILTERS: TUserFilters = {
  newSubscribers: false,
  text: undefined,
  city: undefined,
  title: undefined,
  department: undefined,
}

export function LeadrCumulativeReportContextProvider({
  strings,
  urls,
  userFilters,
  filterOptions,
  archetypes,
  reportResults,
  children,
}: ILeadrCumulativeReportContextProps): JSX.Element {
  const [results, setResults] = useState<IReportResults>()
  const [useUserFilters, setUserFilters] = useState<Partial<TUserFilters>>()
  const [useFilterOptions, setUseFilterOptions] = useState<TFilterOptions>()

  useEffect(() => {
    setResults(reportResults)
    setUserFilters(userFilters ?? DEFAULT_USER_FILTERS)
    setUseFilterOptions(filterOptions ?? DEFAULT_FILTER_OPTIONS)
  }, [])

  return (
    <LeadrCumulativeReportContext.Provider
      value={{
        strings: strings,
        urls: urls,
        archetypes: archetypes,
        reportResults: results,
        setResults: setResults,
        userFilters: useUserFilters,
        setUserFilters: setUserFilters,
        filterOptions: useFilterOptions,
        setFilterOptions: setUseFilterOptions,
      }}>
      {children}
    </LeadrCumulativeReportContext.Provider>
  )
}

export function useLeadrCumulativeReportContext(): ILeadrCumulativeReportContext {
  return useContext(LeadrCumulativeReportContext)
}
