import moment from 'moment-timezone'
import { ShowMoreParagraph } from 'components/Typography'
import { useTableContext } from 'components/Table/TableContext'
import { PaginatedListItem } from 'components/PaginatedList'
import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'

interface RunOfShowListProps {
  timezone: string
}

const runOfShowItem = css({
  display: 'flex',
  flexDirection: 'column',

  p: {
    padding: spacings.grid_gap_basis_num / 2,
    overflow: 'hidden',
    flex: '0 1 auto',
    overflowWrap: 'break-word',

    '&.notes': {
      flex: '1 1 auto',
    },
  },
})

/**
 * A list of Run of show given table rows
 */
export default function RunOfShowList({
  timezone,
}: RunOfShowListProps): JSX.Element {
  const {
    tableData: { rows },
  } = useTableContext()
  return (
    <div>
      {rows.map((runOfShow) => {
        const startTime = moment
          .tz(runOfShow.data.datetime.toString(), timezone)
          .format('h:mma')
        const endTime = moment
          .tz(runOfShow.data.datetime.toString(), timezone)
          .add(runOfShow.data.duration as number, 'seconds')
          .format('h:mma z')
        return (
          <PaginatedListItem
            small
            css={runOfShowItem}
            key={runOfShow.data.name.toString()}>
            <p className="caption">
              {startTime} - {endTime}
            </p>
            <p className="bold">{runOfShow.data.name}</p>
            {runOfShow.data.notes && (
              <ShowMoreParagraph>
                {runOfShow.data.notes.toString()}
              </ShowMoreParagraph>
            )}
          </PaginatedListItem>
        )
      })}
    </div>
  )
}
