import AudienceModal from 'components/Audience'
import { IAudiencesUrls } from 'components/Audience/AudienceContext'
import { DEFAULT_CSV_MESSAGING } from 'components/Audience/helper'
import { useState } from 'react'
import { Audience, TEventCallType } from 'types'

interface IAdditionalAudienceProps {
  object: TEventCallType
  objectId: number
  urls: IAudiencesUrls
  initialAudience?: Audience
  cohostIds?: number[]
}

export default function AdditionalAudience({
  object,
  objectId,
  urls,
  initialAudience,
  cohostIds,
}: IAdditionalAudienceProps): JSX.Element {
  const [openAudienceModal, setOpenAudienceModal] = useState(false)

  return (
    <>
      <button
        className="menu-link not-styled-button"
        onClick={() => setOpenAudienceModal(true)}>
        Send to Additional Audience
      </button>
      <AudienceModal
        title="Send to additional audience"
        subtitle={`${DEFAULT_CSV_MESSAGING} Note that we omit users who have received invitations from the audience results.`}
        object={object}
        objectId={objectId}
        cohostIds={cohostIds}
        isOpen={openAudienceModal}
        onClose={() => setOpenAudienceModal(false)}
        onSave={() => {}}
        urls={urls}
        isAdditionalUsers
        initialAudience={
          initialAudience
            ? {
                //Stripping attributes of audience object so that the modal does not show saved filters
                id: initialAudience.id,
                object_type: initialAudience.object_type,
                object_id: initialAudience.object_id,
              }
            : null
        }
      />
    </>
  )
}
