import React, { useContext, useState } from 'react'
import {
  IAssessmentResults,
  ILeadrIndexReportProps,
  IPartialAssessment,
} from './LeadrIndexReport.types'
import { IArchetype } from 'components/LeadrArchetype/LeadrArchetype.types'

interface ILeadrIndexReportContext extends ILeadrIndexReportProps {
  setAssessmentDetails: (value: IPartialAssessment) => void
  setAssessmentResults: (value: IAssessmentResults) => void
  setArchetypeDetails: (value: IArchetype) => void
}

interface ILeadrIndexReportContextProps extends ILeadrIndexReportProps {
  children: React.ReactNode
}

const LeadrIndexReportContext = React.createContext(
  {} as ILeadrIndexReportContext,
)

export function LeadrIndexReportContextProvider({
  urls,
  strings,
  archetypes,
  assessmentDetails,
  assessmentResults,
  archetypeDetails,
  children,
}: ILeadrIndexReportContextProps): JSX.Element {
  const [currentAssessmentDetails, setAssessmentDetails] =
    useState<IPartialAssessment>(assessmentDetails)
  const [currentAssessmentResults, setAssessmentResults] =
    useState<IAssessmentResults>(assessmentResults)
  const [currentArchetypeDetails, setArchetypeDetails] =
    useState<IArchetype>(archetypeDetails)

  return (
    <LeadrIndexReportContext.Provider
      value={{
        urls,
        strings,
        archetypes,
        assessmentDetails: currentAssessmentDetails,
        assessmentResults: currentAssessmentResults,
        archetypeDetails: currentArchetypeDetails,
        setAssessmentDetails,
        setAssessmentResults,
        setArchetypeDetails,
      }}>
      {children}
    </LeadrIndexReportContext.Provider>
  )
}

export function useLeadrIndexReportContext(): ILeadrIndexReportContext {
  return useContext(LeadrIndexReportContext)
}
