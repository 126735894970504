import { ITableProps } from 'components/Table'
import ApplicantsTable from './ApplicantsTable'
import { colors, spacings } from 'stylesheets/theme'
import { Caption } from 'components/Typography'
import Button, { Variant } from 'components/Button'

import CloseIcon from 'images/icons/close.svg'
import CheckedIcon from 'images/icons/checked_blue.svg'
import ManuallyMatchedIcon from 'images/icons/manually_matched.svg'
import { useState } from 'react'
import {
  AcceptSelectedMatchesModal,
  RejectAllModal,
  RejectSelectedMatchesModal,
} from './Modals'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'

interface IMatchesForReviewTableProps {
  matchesReviewTableProps: ITableProps
  onAction: (url: string, params?: Record<string, any>) => Promise<void>
  rejectAllMatchesUrl: string
  rejectSelectedMatchesUrl: string
  acceptSelectedMatchesUrl: string
}

export default function MatchesForReviewTable({
  matchesReviewTableProps,
  onAction,
  rejectAllMatchesUrl,
  rejectSelectedMatchesUrl,
  acceptSelectedMatchesUrl,
}: IMatchesForReviewTableProps): JSX.Element {
  const { updateTableData, updateTableFilters } =
    useMentorshipExchangeDetailsContext()
  const [openRejectAllModal, setOpenRejectAllModal] = useState(false)
  const [openRejectSelectedMatchesModal, setOpenRejectSelectedMatchesModal] =
    useState(false)
  const [openAcceptSelectedMatchesModal, setOpenAcceptSelectedMatchesModal] =
    useState(false)

  return (
    <ApplicantsTable
      {...matchesReviewTableProps}
      css={{
        '.GojiCustomTable thead tr th:first-of-type': {
          width: `calc(${spacings.grid_gap_basis} * 2 + 15px + ${spacings.grid_gap_basis} * 2)`,
        },
      }}
      tableDescription="matches"
      showSelectedRowsCount
      selectable
      afterUpdateTableData={(data) => updateTableData('matches_review', data)}
      afterUpdateSelectedFilters={(data) =>
        updateTableFilters('matches_review', data)
      }
      infoNode={
        <div
          css={{
            marginTop: spacings.grid_gap_basis_num * 2,
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
          <ManuallyMatchedIcon
            css={{
              marginRight: spacings.grid_gap_basis_num / 2,
            }}
          />
          <Caption>= Manually matched</Caption>
        </div>
      }
      secondaryActionNode={({ selectedRows }) => (
        <>
          <Button
            startIcon={
              <CheckedIcon
                css={{
                  marginRight: spacings.grid_gap_basis_num / 2,
                }}
              />
            }
            onClick={() => {
              setOpenAcceptSelectedMatchesModal(true)
            }}
            variant={Variant.LINK}
            css={{
              color: colors.links.blue,
            }}>
            Accept selected matches
          </Button>
          <Button
            startIcon={
              <CloseIcon
                css={{
                  path: {
                    fill: colors.red,
                  },
                  marginRight: spacings.grid_gap_basis_num / 2,
                }}
              />
            }
            onClick={() => {
              setOpenRejectSelectedMatchesModal(true)
            }}
            variant={Variant.LINK}
            css={{
              color: colors.red,
              '& :hover': {
                color: colors.red,
              },
            }}>
            Reject selected matches
          </Button>
          <Button
            startIcon={
              <CloseIcon
                css={{
                  path: {
                    fill: colors.red,
                  },
                  marginRight: spacings.grid_gap_basis_num / 2,
                }}
              />
            }
            onClick={() => {
              setOpenRejectAllModal(true)
            }}
            variant={Variant.LINK}
            css={{
              color: colors.red,
              '& :hover': {
                color: colors.red,
              },
            }}>
            Reject all
          </Button>
          <RejectAllModal
            isOpen={openRejectAllModal}
            onSave={() => {
              onAction(rejectAllMatchesUrl)
              setOpenRejectAllModal(false)
            }}
            onRequestClose={() => setOpenRejectAllModal(false)}
          />
          <RejectSelectedMatchesModal
            isOpen={openRejectSelectedMatchesModal}
            onSave={() => {
              onAction(rejectSelectedMatchesUrl, {
                ids: selectedRows.map((row) => row.id),
              })
              setOpenRejectSelectedMatchesModal(false)
            }}
            onRequestClose={() => setOpenRejectSelectedMatchesModal(false)}
          />
          <AcceptSelectedMatchesModal
            isOpen={openAcceptSelectedMatchesModal}
            onSave={() => {
              onAction(acceptSelectedMatchesUrl, {
                ids: selectedRows.map((row) => row.id),
              })
              setOpenAcceptSelectedMatchesModal(false)
            }}
            onRequestClose={() => setOpenAcceptSelectedMatchesModal(false)}
          />
        </>
      )}
      emptyStateChildren={
        <p css={{ fontWeight: 'normal' }}>No matches left to be reviewed</p>
      }
    />
  )
}
