import React from 'react'
import cx from 'classnames'
import { css } from '@emotion/react'

import { SmallText } from 'components/Typography'

import './FormFieldContainer.scss'
import { spacings } from 'stylesheets/theme'

export enum LabelVisibility {
  SHOW,
  HIDE,
  HIDE_KEEP_SPACE,
}

export interface IFormFieldContainer {
  fieldId: string
  label: string | React.ReactNode
  statusNode?: React.ReactNode
  description?: string | React.ReactNode
  required?: boolean
  error?: boolean
  className?: string
  labelVisibility?: LabelVisibility
  children: React.ReactNode
}

const labelWithChipWrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  gap: spacings.grid_gap_basis,
})

export default function FormFieldContainer({
  fieldId,
  label,
  statusNode,
  description,
  required = false,
  error = false,
  labelVisibility = LabelVisibility.SHOW,
  className,
  children,
}: IFormFieldContainer): React.ReactElement {
  return (
    <div className={cx('input-component-wrapper', className)}>
      <div css={labelWithChipWrapperStyle}>
        <label
          htmlFor={fieldId}
          className={cx('label', {
            'validation-error': error,
            hide: labelVisibility === LabelVisibility.HIDE,
            'hide-keep-space':
              labelVisibility === LabelVisibility.HIDE_KEEP_SPACE,
          })}>
          {label}
          {required ? '*' : ''}
        </label>
        {statusNode}
      </div>
      {children}
      {description && (
        <SmallText className="field-description">{description}</SmallText>
      )}
    </div>
  )
}
