import { useEffect } from 'react'
import cx from 'classnames'

import ShowBadgeModal from 'components/ShowBadgeModal'
import ConfirmationModal from 'components/ConfirmationModal'
import RegisterConfirmationModal from './RegisterConfirmationModal'

import JoinWaitlistButton from './JoinWaitlistButton'
import RegisterEventButton from './RegisterEventButton'
import { useEventActionContext, ModalType } from './context'

import { IEventCall, TActionLocation } from 'types'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import Container from 'components/Container'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

export interface IEventActionProps {
  data: IEventCall
  location?: TActionLocation
  doRegister?: boolean
  invitable?: boolean
  className?: string
  features?: Record<string, boolean>
}

const defaultProps: Partial<IEventActionProps> = {
  location: 'table-cell',
  doRegister: false,
}

const EventAction = (props: IEventActionProps): JSX.Element => {
  const {
    data: event,
    location,
    className,
    doRegister,
    invitable,
  } = {
    ...defaultProps,
    ...props,
  }

  const {
    registered,
    modalToShow,
    badge,
    onRegister,
    onConfirmUnregister,
    onCancelUnregister,
    onCloseBadgeModal,
    onSubmitQuestions,
    onCloseConfirmation,
    onCloseSubmitQuestionsConfirmation,
    onShowSubmitQuestions,
  } = useEventActionContext()

  function BannerButtons() {
    if (registrationClosed) {
      return (
        <JoinWaitlistButton
          eventLink={event.link}
          waitlistOpen={event.waitlist_open}
          location={location}
        />
      )
    }

    if (isMobile) {
      if (registered && invitable) {
        return (
          <Container direction="column">
            <Container>
              <Button
                css={{
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                }}
                as={ButtonComponentType.BUTTON}
                variant={Variant.PRIMARY}
                onClick={onShowSubmitQuestions}>
                Submit Questions
              </Button>
              <Button
                css={{
                  whiteSpace: 'nowrap',
                  flexGrow: 1,
                }}
                className="share-event-call-btn"
                data-event-info={JSON.stringify(event)}
                as={ButtonComponentType.BUTTON}
                variant={Variant.SECONDARY}>
                Invite Others
              </Button>
            </Container>
            <RegisterEventButton
              css={{
                display: 'flex',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                flex: 1,
              }}
              event={event}
              location={location}
              aria-label="register event"
            />
          </Container>
        )
      } else if (registered) {
        // not invitable
        return (
          <Container>
            <Button
              css={{
                whiteSpace: 'nowrap',
                flexGrow: 1,
              }}
              as={ButtonComponentType.BUTTON}
              variant={Variant.PRIMARY}
              onClick={onShowSubmitQuestions}>
              Submit Questions
            </Button>
            <RegisterEventButton
              css={{
                flexGrow: 1,
                whiteSpace: 'nowrap',
              }}
              event={event}
              location={location}
              aria-label="register event"
            />
          </Container>
        )
      } else if (!registered && invitable) {
        return (
          <Container
            css={{
              width: '100%',
            }}>
            <RegisterEventButton
              css={{
                flexGrow: 1,
                whiteSpace: 'nowrap',
              }}
              event={event}
              location={location}
              aria-label="register event"
            />
            <Button
              css={{
                flexGrow: 1,
                whiteSpace: 'nowrap',
              }}
              className="share-event-call-btn"
              data-event-info={JSON.stringify(event)}
              as={ButtonComponentType.BUTTON}
              variant={Variant.SECONDARY}>
              Invite Others
            </Button>
          </Container>
        )
      } else if (!registered && !invitable) {
        return (
          <RegisterEventButton
            css={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'nowrap',
              flex: 1,
            }}
            event={event}
            location={location}
            aria-label="register event"
          />
        )
      }
    } else {
      return (
        <Container>
          {registered && (
            <Button
              css={{
                whiteSpace: 'nowrap',
              }}
              as={ButtonComponentType.BUTTON}
              variant={Variant.PRIMARY}
              onClick={onShowSubmitQuestions}>
              Submit Questions
            </Button>
          )}
          {!registered && (
            <RegisterEventButton
              css={{
                display: 'flex',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                flex: 1,
              }}
              event={event}
              location={location}
              aria-label="register event"
            />
          )}
          {registered && (
            <RegisterEventButton
              css={{
                display: 'flex',
                justifyContent: 'center',
                whiteSpace: 'nowrap',
                flex: 1,
              }}
              event={event}
              location={location}
              aria-label="register event"
            />
          )}
          {invitable && (
            <Button
              css={{
                whiteSpace: 'nowrap',
              }}
              className="share-event-call-btn"
              data-event-info={JSON.stringify(event)}
              as={ButtonComponentType.BUTTON}
              variant={Variant.SECONDARY}>
              Invite Others
            </Button>
          )}
        </Container>
      )
    }
  }

  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  const registrationClosed = !registered && !event.registerable

  useEffect(() => {
    if (doRegister && event.registerable && !event.registered) {
      onRegister()
    }
  }, [])

  return (
    <>
      <ShowBadgeModal
        isOpen={modalToShow === ModalType.badges}
        badgeName={badge}
        onClose={onCloseBadgeModal}
        achieved
        seeMore
      />
      <ConfirmationModal
        isOpen={modalToShow === ModalType.leaveConfirmation}
        title="Are you sure you want to leave this event?"
        onConfirm={onConfirmUnregister}
        onCancel={onCancelUnregister}>
        If you leave, you will no longer be marked as attending and will not
        receive any updates about this event.
      </ConfirmationModal>
      <RegisterConfirmationModal
        isOpen={modalToShow === ModalType.registerConfirmation}
        event={event}
        onSubmit={onSubmitQuestions}
        onClose={onCloseConfirmation}
      />
      <RegisterConfirmationModal
        isOpen={modalToShow === ModalType.submitQuestions}
        type="questions"
        event={event}
        onSubmit={onSubmitQuestions}
        onClose={onCloseSubmitQuestionsConfirmation}
      />
      <div
        className={cx('event-call-action', {
          'on-card': location === 'card',
          [className]: !!className,
        })}
        css={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: isMobile ? 'center' : 'start',
          alignItems: isMobile ? 'center' : 'start',
        }}>
        {location === 'banner' ? (
          <BannerButtons />
        ) : registrationClosed ? (
          <JoinWaitlistButton
            eventLink={event.link}
            waitlistOpen={event.waitlist_open}
            location={location}
          />
        ) : (
          <RegisterEventButton
            css={{
              display: 'flex',
              justifyContent: 'center',
              whiteSpace: 'nowrap',
              flex: 1,
              width: 'auto',
              order: 2,
              flexShrink: 0,
              flexGrow: 0,
            }}
            event={event}
            location={location}
            aria-label="register event"
          />
        )}
      </div>
    </>
  )
}

export default EventAction
