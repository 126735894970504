import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react'
import Input from 'components/Input'
import RichTextInput from 'components/RichTextInput'
import Attachments, { TAttachment } from '../Attachments'

type ModalFormValues = {
  subject?: string
  notes?: string
  attachments?: TAttachment[]
}

interface IBatchEmailModalProps {
  showRecipients: boolean
  filterString: string
  onFormChange: (body: ModalFormValues) => void
}

export type SetErrorsHandle = {
  setErrors: (errors: Record<string, string>) => void
}

const BatchEmailModal = (
  { showRecipients, filterString, onFormChange }: IBatchEmailModalProps,
  ref,
) => {
  const [body, setBody] = useState<ModalFormValues>({})
  const [errors, setErrors] = useState(null)

  useImperativeHandle(ref, () => ({
    setErrors,
  }))

  const handleInputChange = useCallback(
    (updateBody) => {
      const newBody = {
        ...body,
        ...updateBody,
      }

      setBody(newBody)
      onFormChange(newBody)
    },
    [body, setBody, onFormChange],
  )

  const handleAttachments = useCallback(
    (attachments) => {
      const newBody = {
        ...body,
        attachments,
      }

      setBody(newBody)
      onFormChange(newBody)
    },
    [body, setBody, onFormChange],
  )

  return (
    <div className="simple-modal-body batch-email-modal">
      {showRecipients && (
        <div className="input-component-wrapper">
          <div className="label">Send To</div>
          <div className="input-wrapper">
            <div className="fake-input">{filterString}</div>
          </div>
        </div>
      )}

      <form id="batch-email-form">
        <Input
          name="subject"
          label="Subject*"
          value={body['subject']}
          error={errors?.subject}
          onChange={handleInputChange}
        />
        <div className="input-component-wrapper">
          <RichTextInput
            name="notes"
            label="Notes*"
            error={errors?.notes}
            onChange={handleInputChange}
          />
        </div>

        <Attachments error={errors?.attachments} onChange={handleAttachments} />
      </form>
    </div>
  )
}

export default forwardRef<SetErrorsHandle, IBatchEmailModalProps>(
  BatchEmailModal,
)
