import { useMemo } from 'react'
import { Paragraph, SectionTitle } from 'components/Typography'
import { css } from '@emotion/react'
import GuideWizard, { TStep } from 'components/GuideWizard'
import step1ImgMedium from 'images/teams_installation/step_1_medium.png'
import step1ImgNarrow from 'images/teams_installation/step_1_narrow.png'
import step1ImgWide from 'images/teams_installation/step_1_wide.png'
import step1ImgExtraWide from 'images/teams_installation/step_1_extrawide.png'
import step2ImgMedium from 'images/teams_installation/step_2_medium.png'
import step2ImgNarrow from 'images/teams_installation/step_2_narrow.png'
import step2ImgWide from 'images/teams_installation/step_2_wide.png'
import step2ImgExtraWide from 'images/teams_installation/step_2_extrawide.png'
import step3ImgMedium from 'images/teams_installation/step_3_medium.png'
import step3ImgNarrow from 'images/teams_installation/step_3_narrow.png'
import step3ImgWide from 'images/teams_installation/step_3_wide.png'
import step3ImgExtraWide from 'images/teams_installation/step_3_extrawide.png'
import step4ImgMedium from 'images/teams_installation/step_4_medium.png'
import step4ImgNarrow from 'images/teams_installation/step_4_narrow.png'
import step4ImgWide from 'images/teams_installation/step_4_wide.png'
import step4ImgExtraWide from 'images/teams_installation/step_4_extrawide.png'
import step5ImgMedium from 'images/teams_installation/step_5_medium.png'
import step5ImgNarrow from 'images/teams_installation/step_5_narrow.png'
import step5ImgWide from 'images/teams_installation/step_5_wide.png'
import step5ImgExtraWide from 'images/teams_installation/step_5_extrawide.png'
import step6ImgMedium from 'images/teams_installation/step_6_medium.png'
import step6ImgNarrow from 'images/teams_installation/step_6_narrow.png'
import step6ImgWide from 'images/teams_installation/step_6_wide.png'
import step6ImgExtraWide from 'images/teams_installation/step_6_extrawide.png'
import step8ImgMedium from 'images/teams_installation/step_8_medium.png'
import step8ImgNarrow from 'images/teams_installation/step_8_narrow.png'
import step8ImgWide from 'images/teams_installation/step_8_wide.png'
import step8ImgExtraWide from 'images/teams_installation/step_8_extrawide.png'
import step9ImgMedium from 'images/teams_installation/step_9_medium.png'
import step9ImgNarrow from 'images/teams_installation/step_9_narrow.png'
import step9ImgWide from 'images/teams_installation/step_9_wide.png'
import step9ImgExtraWide from 'images/teams_installation/step_9_extrawide.png'
import step10ImgMedium from 'images/teams_installation/step_10_medium.png'
import step10ImgNarrow from 'images/teams_installation/step_10_narrow.png'
import step10ImgWide from 'images/teams_installation/step_10_wide.png'
import step10ImgExtraWide from 'images/teams_installation/step_10_extrawide.png'
import CreateConnectorStep from './CreateConnectorStep'
import DownloadMindrLogoStep from './DownloadMindrLogoStep'
import { Connector } from 'types'
import { spacings } from 'stylesheets/theme'
import WarningBanner from './WarningBanner'

interface MsftTeamsGuideProps {
  connector?: Connector | null
  updateConnectorUrl?: string
  connectorsUrl: string
  removeConnectorUrl?: string
  isCohort?: boolean
  mindrLogoUrl: string
}

const containerWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: spacings.grid_gap_basis_num * 2,
})

export default function MsftTeamsGuide({
  connector,
  updateConnectorUrl,
  connectorsUrl,
  removeConnectorUrl,
  isCohort = false,
  mindrLogoUrl,
}: MsftTeamsGuideProps): JSX.Element {
  const steps: TStep[] = useMemo(
    () => [
      {
        title: 'Select “Connectors” from the dropdown menu',
        description:
          'Click into the team channel settings and select the "Connectors" option',
        images: {
          narrow: step1ImgNarrow,
          medium: step1ImgMedium,
          wide: step1ImgWide,
          extraWide: step1ImgExtraWide,
        },
      },
      {
        title: 'Search “Incoming webhook” and click on the “Add” button',
        description:
          'Using the search bar, type "Incoming Webhook" into the field and then click the "Add" button next to the connector',
        caption:
          'Note: If you have already added the connector, then this button will say "Configure"',
        images: {
          narrow: step2ImgNarrow,
          medium: step2ImgMedium,
          wide: step2ImgWide,
          extraWide: step2ImgExtraWide,
        },
      },
      {
        title: 'Click on the “Add” button to confirm',
        description: 'Click on the “Add” button to confirm',
        images: {
          narrow: step3ImgNarrow,
          medium: step3ImgMedium,
          wide: step3ImgWide,
          extraWide: step3ImgExtraWide,
        },
      },
      {
        title: 'Select “Connectors” from the dropdown menu again',
        description:
          'Navigate back into the team channel settings and select the "Connectors" option',
        images: {
          narrow: step4ImgNarrow,
          medium: step4ImgMedium,
          wide: step4ImgWide,
          extraWide: step4ImgExtraWide,
        },
      },
      {
        title: 'Locate “Incoming webhook” and click on the “Configure” button',
        description:
          'Using the search bar, type "Incoming Webhook" into the field and then click the "Configure" button next to the connector',
        images: {
          narrow: step5ImgNarrow,
          medium: step5ImgMedium,
          wide: step5ImgWide,
          extraWide: step5ImgExtraWide,
        },
      },
      {
        title: 'Enter the name as “Mindr Connect”',
        description: 'Enter the name as “Mindr Connect”',
        images: {
          narrow: step6ImgNarrow,
          medium: step6ImgMedium,
          wide: step6ImgWide,
          extraWide: step6ImgExtraWide,
        },
      },
      {
        title: 'Download the Mindr logo',
        description: 'Download the Mindr logo',
        content: <DownloadMindrLogoStep mindrLogoUrl={mindrLogoUrl} />,
      },
      {
        title: 'Click on the “Upload Image” button',
        description:
          'Click on the “Upload Image” and upload the Mindr logo button',
        images: {
          narrow: step8ImgNarrow,
          medium: step8ImgMedium,
          wide: step8ImgWide,
          extraWide: step8ImgExtraWide,
        },
      },
      {
        title: 'Click on the “Create” button to create the webhook URL',
        description: 'Click on the “Create” button to create the webhook URL',
        images: {
          narrow: step9ImgNarrow,
          medium: step9ImgMedium,
          wide: step9ImgWide,
          extraWide: step9ImgExtraWide,
        },
      },
      {
        title: 'Copy this URL',
        description: 'Copy this URL',
        images: {
          narrow: step10ImgNarrow,
          medium: step10ImgMedium,
          wide: step10ImgWide,
          extraWide: step10ImgExtraWide,
        },
      },
      {
        title: 'Paste the URL in this text box!',
        description: 'Paste the URL and name it after your Teams or channel',
        content: (
          <CreateConnectorStep
            connector={connector}
            updateConnectorUrl={updateConnectorUrl}
            connectorsUrl={connectorsUrl}
            removeConnectorUrl={removeConnectorUrl}
          />
        ),
      },
    ],
    [connector, updateConnectorUrl, connectorsUrl, removeConnectorUrl],
  )

  const selectedStepIndex = useMemo(
    () => (connector ? steps.length - 1 : 0),
    [steps, connector],
  )

  return (
    <div>
      <div
        css={[
          containerWrapperStyle,
          css({
            paddingBottom: spacings.grid_gap_basis_num * 2,
          }),
        ]}>
        <h2>Microsoft Teams Integration</h2>
        <Paragraph bold>
          Automatically post your community’s new events and call-to-actions in
          your Teams channel
        </Paragraph>
      </div>
      <WarningBanner />
      <div css={containerWrapperStyle}>
        <SectionTitle>Step-by-step guide</SectionTitle>
        <h3>How to integrate Teams and Mindr Connect</h3>
        {isCohort ? (
          <Paragraph>
            To get started, please open up Teams and locate your cohort&apos;s
            Teams channel. If you <b>don&apos;t</b> already have one, please
            create a new Teams channel for your cohort before proceeding with
            these steps.
          </Paragraph>
        ) : (
          <Paragraph>
            To get started, please open up Teams and locate your
            community&apos;s Teams channel. If you <b>don&apos;t</b> already
            have one, please create a new Teams channel for your community
            before proceeding with these steps.
          </Paragraph>
        )}
        <GuideWizard steps={steps} selectedStepIndex={selectedStepIndex} />
      </div>
    </div>
  )
}
