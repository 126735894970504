import step1ImgWide from 'images/slack_installation/step_1_wide.png'
import step2ImgWide from 'images/slack_installation/step_2_wide.png'
import step3ImgWide from 'images/slack_installation/step_3_wide.png'
import step4ImgWide from 'images/slack_installation/step_4_wide.png'
import step5ImgWide from 'images/slack_installation/step_5_wide.png'
import step6ImgWide from 'images/slack_installation/step_6_wide.png'
import step7ImgWide from 'images/slack_installation/step_7_wide.png'
import step8ImgWide from 'images/slack_installation/step_8_wide.png'
import step10ImgWide from 'images/slack_installation/step_10_wide.png'
import step11ImgWide from 'images/slack_installation/step_11_wide.png'
import step12ImgWide from 'images/slack_installation/step_12_wide.png'
import step13ImgWide from 'images/slack_installation/step_13_wide.png'
import DownloadMindrLogoStep from './DownloadMindrLogoStep'
import { IGuideProps, TStepMode } from './types'
import DecisionPanel from './DecisionPanel'
import { Paragraph } from 'components/Typography'
import QuestionIcon from 'images/slack_installation/question.png'
import { css } from '@emotion/react'
import { colors } from 'stylesheets/theme'
import {
  IStepFunctions,
  TStep,
  TTStepsFunction,
} from 'components/GuideWizard/GuideWizard'
import NewStep9 from './NewStep9'

type SetStepsModeFunction = React.Dispatch<React.SetStateAction<TStepMode>>

export default function getStepsFunction(
  options: IGuideProps,
  stepsMode: TStepMode,
  setStepsMode: SetStepsModeFunction,
): TTStepsFunction {
  return (funcs: IStepFunctions): TStep[] => {
    if (stepsMode === 'new') {
      return getNewSteps(options, funcs, setStepsMode)
    } else if (stepsMode === 'returning') {
      return getReturningSteps(options, funcs, setStepsMode)
    }

    return []
  }
}

function getStep1(
  slackAppsLink: React.ReactNode,
  { nextStep }: IStepFunctions,
  setStepsMode: SetStepsModeFunction,
): TStep {
  const step1Title =
    'Does your workspace require apps to be approved by your admins?'

  return {
    title: step1Title,
    description: '',
    content: (
      <DecisionPanel
        onYes={() => {
          setStepsMode('returning')
          nextStep && nextStep()
        }}
        onNo={() => {
          setStepsMode('new')
          nextStep && nextStep()
        }}
        title={step1Title}
        iconUrl={QuestionIcon}
        heroImage={step1ImgWide}>
        <Paragraph>
          Visit {slackAppsLink} to check whether you have already created it.
          <br />
          <br />
          If you haven&apos;t created a &apos;Mindr Connect&apos; app yet,
          please select &apos;NO,&apos; and you&apos;ll be guided through the
          first-time app creation process.
        </Paragraph>
      </DecisionPanel>
    ),
    removeBottomNav: true,
  }
}

function getStep9({ nextStep }: IStepFunctions): TStep {
  const title =
    'Does your workspace require apps to be approved by your admins?'
  return {
    title: title,
    description: '',
    content: <NewStep9 nextStep={nextStep}></NewStep9>,
    removeBottomNav: true,
  }
}

function getNewSteps(
  options: IGuideProps,
  { nextStep }: IStepFunctions,
  setStepsMode: SetStepsModeFunction,
): TStep[] {
  const slackAppsLink = (
    <a href="https://api.slack.com/apps" title="Visit the Slack App Dashboard">
      https://api.slack.com/apps
    </a>
  )

  return [
    // step 1
    getStep1(slackAppsLink, { nextStep }, setStepsMode),
    // step 2
    {
      title: 'Visit link and sign in ',
      description: (
        <>
          Visit {slackAppsLink} and sign into your Slack account. If you have
          already signed in, you can skip to the next step.
        </>
      ),
      oneImage: step2ImgWide,
    },
    // step 3
    {
      title: 'Revisit the link, then click on "Create an App"',
      description: (
        <>
          If this page is not showing, please visit this {slackAppsLink} again.
          Then, click on “Create an App.”
        </>
      ),
      oneImage: step3ImgWide,
    },
    // step 4
    {
      title: 'Select "From scratch"',
      description: 'Select "From scratch"',
      oneImage: step4ImgWide,
    },
    // step 5
    {
      title: 'Name the app as "Mindr Connect"',
      description: (
        <>
          Name the app as &quot;Mindr Connect&quot;
          <br />
          <br />
          If your workspace requires app approval by admins, please continue
          following the steps. Once you reach Step 9, you may select &apos;Yes,
          I need approval.&apos;
        </>
      ),
      oneImage: step5ImgWide,
    },
    // step 6
    {
      title: 'Select your workspace, then click on "Create App"',
      description: 'Select your workspace, then click on "Create App"',
      oneImage: step6ImgWide,
    },
    // step 7
    {
      title: 'Click on "Incoming Webhooks"',
      description: 'Click on "Incoming Webhooks"',
      oneImage: step7ImgWide,
    },
    // step 8
    {
      title: 'Toggle to activate the incoming webhooks',
      description:
        'Toggle to activate the incoming webhooks. If you encounter a message requiring admin approval, please proceed to the next step after toggling the activation switch.',
      oneImage: step8ImgWide,
    },
    // step 9
    getStep9({ nextStep }),
    // step 10
    {
      title: 'Click on "Add New Webhook to Workspace"',
      description: 'Scroll down and click on "Add New Webhook to Workspace"',
      oneImage: step10ImgWide,
    },
    // step 11
    {
      title: 'Select a channel from the dropdown',
      description: 'Select a channel from the dropdown',
      oneImage: step11ImgWide,
    },
    // step 12
    {
      title: 'Click on "Allow"',
      description: 'Click on "Allow"',
      oneImage: step12ImgWide,
    },
    // step 13
    {
      title: 'Copy webhook URL',
      description: 'Click on the “Add” button to confirm',
      oneImage: step13ImgWide,
    },
    // step 14
    {
      title: 'Click on the “Add” button to confirm',
      description: 'Click on the “Add” button to confirm',
    },
  ]
}

function getReturningSteps(
  options: IGuideProps,
  { nextStep }: IStepFunctions,
  setStepsMode: SetStepsModeFunction,
): TStep[] {
  const { mindrLogoUrl } = options

  const slackAppsLink = (
    <a href="https://api.slack.com/apps" title="Visit the Slack App Dashboard">
      https://api.slack.com/apps
    </a>
  )

  return [
    // step 1
    getStep1(slackAppsLink, { nextStep }, setStepsMode),
    // step 2
    {
      title: 'Visit link and sign in ',
      description: (
        <>
          Visit {slackAppsLink} and sign into your Slack account. If you have
          already signed in, you can skip to the next step.
        </>
      ),
      oneImage: step2ImgWide,
      style: css({
        h5: {
          color: colors.text.text_5,
        },
      }),
    },
    // step 3
    {
      title: 'Click on the “Add” button to confirm',
      description: 'Click on the “Add” button to confirm',
      images: {
        wide: step3ImgWide,
      },
    },
    // step 4
    {
      title: 'Select “Connectors” from the dropdown menu again',
      description:
        'Navigate back into the team channel settings and select the "Connectors" option',
      images: {
        wide: step4ImgWide,
      },
    },
    // step 5
    {
      title: 'Locate “Incoming webhook” and click on the “Configure” button',
      description:
        'Using the search bar, type "Incoming Webhook" into the field and then click the "Configure" button next to the connector',
      images: {
        wide: step5ImgWide,
      },
    },
    // step 6
    {
      title: 'Enter the name as “Mindr Connect”',
      description: 'Enter the name as “Mindr Connect”',
      images: {
        wide: step6ImgWide,
      },
    },
    // step 7
    {
      title: 'Download the Mindr logo',
      description: 'Download the Mindr logo',
      content: <DownloadMindrLogoStep mindrLogoUrl={mindrLogoUrl} />,
    },
    // step 8
    {
      title: 'Click on the “Upload Image” button',
      description:
        'Click on the “Upload Image” and upload the Mindr logo button',
      images: {
        wide: step8ImgWide,
      },
    },
    // step 9
    {
      title: 'Click on the “Create” button to create the webhook URL',
      description: 'Click on the “Create” button to create the webhook URL',
    },
  ]
}
