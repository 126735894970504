import { MoreMenuTableCell } from 'components/Table'

import EditButton from './EditButton'
import ViewChangeLogButton from './ViewChangeLogButton'
import ViewOperationalLogButton from './ViewOperationalLogButton'

import type { Connector } from 'types'

interface ConnectorActionsProps {
  data: Connector
}

export default function ConnectorActions({
  data,
}: ConnectorActionsProps): JSX.Element {
  return (
    <MoreMenuTableCell keyPrefix={`connector-${data.uuid}`}>
      <EditButton connector={data} />
      <ViewChangeLogButton changeLogPath={data.change_logs_url} />
      <ViewOperationalLogButton
        operationalLogsPath={data.operational_logs_url}
      />
    </MoreMenuTableCell>
  )
}
