import ApplicationFormFieldContainer from './ApplicationFormFieldContainer'
import { useContext, useState, useEffect, useCallback } from 'react'
import { colors, spacings } from 'stylesheets/theme'
import { Caption } from 'components/Typography'
import Input from 'components/Input'
import ApplicationFormContext from './ApplicationFormContext'
import ApplicationStepWrapper from './ApplicationStepWrapper'
import DragAndDropLeadrComponent from './DragAndDropLeadrComponent'
import MobileLeadrComponent from './MobileLeadrComponent'

export default function LEADR(): JSX.Element {
  const [characteristics, setCharacteristics] = useState([])

  const {
    setStrengthCategories,
    setGoalCategories,
    leadrCharacteristics: defaultLeadrCharacteristics,
    formData,
    isMobile,
  } = useContext(ApplicationFormContext)

  useEffect(() => {
    if (
      formData['strength_categories']?.length &&
      formData['goal_categories']?.length
    ) {
      setCharacteristics([
        ...formData['strength_categories'],
        ...formData['goal_categories'].reverse(),
      ])
    } else if (defaultLeadrCharacteristics.length && !isMobile) {
      setCharacteristics(defaultLeadrCharacteristics)
    }
  }, [defaultLeadrCharacteristics, formData, isMobile])

  const onNext = useCallback(() => {
    setStrengthCategories(characteristics.slice(0, 2))
    setGoalCategories(
      characteristics.slice(2, characteristics.length).reverse(),
    )
  }, [characteristics, setStrengthCategories, setGoalCategories])

  const validateCharacteristics = useCallback(() => {
    if (characteristics.length < 5) {
      return 'Please complete ranking all of the leadership characteristics before proceeding to the next step.'
    }
  }, [characteristics])

  return (
    <ApplicationStepWrapper
      title={
        <h2>
          Understanding <mark>your</mark> unique strengths and approach
        </h2>
      }
      description="Mindr is built on the philosophy that everyone can be a leader, irrespective of title or tenure. The following questions will help us analyze your strengths and opportunities for growth, in accordance with Mindr’s LEADR Index™."
      onNextStep={onNext}
      onValidate={validateCharacteristics}>
      <div>
        <ApplicationFormFieldContainer
          label="Please rank the following leadership characteristics, from the one you’re best at in first place, to the one you’re least confident with in last place:"
          fieldId="leadr-order">
          <Caption>
            {isMobile
              ? 'Tap in specific order to rank leadership characteristics, and tap again to undo your selection'
              : 'Drag and drop to rearrange the leadership characteristics'}
          </Caption>
          {isMobile ? (
            <MobileLeadrComponent
              characteristics={characteristics}
              setCharacteristics={setCharacteristics}
            />
          ) : (
            <DragAndDropLeadrComponent
              characteristics={characteristics}
              setCharacteristics={setCharacteristics}
            />
          )}
        </ApplicationFormFieldContainer>
        <h4
          css={{
            marginBottom: spacings.grid_gap_basis,
            color: colors.text.text_5,
          }}>
          Tell us more about why you ranked them in this way
        </h4>
        <Input
          type="textarea"
          name="leadr_notes"
          css={{
            minHeight: '100px !important',
            resize: 'none',
          }}
          defaultValue={formData['leadr_notes'] || ''}
        />
        <input
          hidden
          name="strength_categories"
          value={JSON.stringify(characteristics?.slice(0, 2))}></input>
        <input
          hidden
          name="goal_categories"
          value={JSON.stringify(
            characteristics?.slice(2, characteristics.length).reverse(),
          )}></input>
      </div>
    </ApplicationStepWrapper>
  )
}
