import React from 'react'
import moment from 'moment'
import SwitcherWithConfirmation, {
  ISwitcherWithConfirmation,
} from 'components/SwitcherWithConfirmation'
import { TEventCallType, TTimezone } from 'types'
import { DatePicker, TimePicker, TimezonePicker } from 'components/Inputs'
import { FormFieldContainer, FormFieldLabelVisibility } from 'components/Forms'

interface RegistrationDeadlineProps {
  hasDeadline: ISwitcherWithConfirmation['defaultValue']
  deadlineDate?: string
  deadlineTime?: string
  deadlineTimezone?: string
  userTimezone?: string
  timezones?: TTimezone[]
  object: TEventCallType
  label: ISwitcherWithConfirmation['label']
  errors: {
    formatted_deadline_date: string[]
    formatted_deadline_time: string[]
    deadline_timezone: string[]
  }
  dateLabel: string | React.ReactNode
  timeLabel: string | React.ReactNode
}

/**
 * Control to set registration deadline with date, time and timezone
 */
export default function RegistrationDeadline({
  hasDeadline,
  deadlineDate,
  deadlineTime,
  deadlineTimezone,
  userTimezone,
  timezones = [],
  object,
  label,
  errors,
  dateLabel,
  timeLabel,
}: RegistrationDeadlineProps): React.ReactElement {
  return (
    <>
      <SwitcherWithConfirmation
        name={`${object}[deadline]`}
        label={label}
        defaultValue={hasDeadline}
        toggleTarget={'.event-deadline-fields'}
      />
      <div className="event-deadline-fields preference-details">
        <div className="date-fields">
          <FormFieldContainer
            label={dateLabel}
            fieldId="event-deadline-date"
            error={!!errors.formatted_deadline_date.length}>
            <DatePicker
              id="event-deadline-date"
              name={`${object}[formatted_deadline_date]`}
              defaultValue={
                deadlineDate
                  ? moment(deadlineDate).format('ddd, MMM Do, YYYY')
                  : undefined
              }
              errors={errors.formatted_deadline_date}
            />
          </FormFieldContainer>
        </div>
        <div className="time-fields">
          <FormFieldContainer
            fieldId="event-deadline-time"
            label={timeLabel}
            error={!!errors.formatted_deadline_time.length}>
            <TimePicker
              id="event-deadline-time"
              name={`${object}[formatted_deadline_time]`}
              defaultValue={
                deadlineTime
                  ? moment.utc(deadlineTime).format('h:mma')
                  : undefined
              }
              errors={errors.formatted_deadline_time}
            />
          </FormFieldContainer>
          <FormFieldContainer
            label={timeLabel}
            fieldId="event-deadline-timezone"
            className="timezone-component-field"
            labelVisibility={FormFieldLabelVisibility.HIDE_KEEP_SPACE}
            error={!!errors.deadline_timezone.length}>
            <TimezonePicker
              name={`${object}[deadline_timezone]`}
              defaultTimezone={deadlineTimezone || userTimezone}
              timezones={timezones}
              errors={errors.deadline_timezone}
            />
          </FormFieldContainer>
        </div>
      </div>
    </>
  )
}
