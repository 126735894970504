import { useState, useMemo, useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import { TEventCallType } from 'types'
import { SchedulePublishErrorKey } from './SchedulePublishDialogContent'

interface usePublishLateValues {
  objectType: TEventCallType
  timezone: string | null
  mindrForm: HTMLFormElement | null
  formData: FormData | null
  initializeFormData: () => { newForm: HTMLFormElement; newFormData: FormData }
  schedulePublishErrors: Record<SchedulePublishErrorKey, string[]>
  validateFormData: (
    validateUrl: any,
    formData: FormData,
    schedule_publish?: boolean,
  ) => Promise<AxiosResponse<any>>
  submitFormToValidate: (
    validateUrl: any,
    formData: FormData,
    form: HTMLFormElement,
    schedulePublish: boolean,
    submitForm?: boolean,
  ) => void
  existingSchedulePublish: boolean
}

export default function usePublishLater(
  initialPublishingDate: string | null,
  initialPublishingTime: string | null,
  errors?: Record<string, string[]>,
  isCall?: boolean,
): usePublishLateValues {
  const [timezone, setTimezone] = useState<string | null>(null)
  const [mindrForm, setMindrForm] = useState<HTMLFormElement>(null)
  const [formData, setFormData] = useState<FormData>(null)

  const objectType = useMemo(() => (isCall ? 'call' : 'event'), [isCall])

  const existingSchedulePublish = useMemo(
    () => !!initialPublishingDate && !!initialPublishingTime,
    [initialPublishingDate, initialPublishingTime],
  )

  /**
   * Takes current formData state and send it to /validate endpoint to
   * validate form for publishing
   */
  async function validateFormData(
    validateUrl,
    formData: FormData,
    schedule_publish = false,
  ) {
    if (schedule_publish) {
      formData.append(`${objectType}[schedule_publish]`, 'true')
    }
    formData.append(`${objectType}[published]`, 'true')
    formData.set('authenticity_token', window.authenticity_token)
    return await axios.post(validateUrl + '.json', formData)
  }

  /**
   * Attach schedule publishing date and time as hidden fields to the main form along with other object
   * information, like title, subtitle...etc
   * @param schedulePublish true if object wants to be published on a schedule
   * @param form the current object form that will be submitted
   * @param publishingDate the date object will be published in formatted style
   * @param publishingTime the time object will be published in formatted style
   */
  function attachSchedulePublishHiddenFields(
    schedulePublish: boolean,
    form: HTMLFormElement,
    publishingDate?: string,
    publishingTime?: string,
  ) {
    if (schedulePublish) {
      const schedulePublishEls = document.querySelectorAll(
        `form.mindr-form [name="${objectType}[schedule_publish]"]`,
      )
      let schedulePublishEl = null
      if (!schedulePublishEls.length) {
        schedulePublishEl = document.createElement('input')
        schedulePublishEl.setAttribute('type', 'hidden')
        schedulePublishEl.setAttribute(
          'name',
          `${objectType}[schedule_publish]`,
        )

        form.appendChild(schedulePublishEl)
      } else {
        schedulePublishEl = schedulePublishEls[0]
      }
      schedulePublishEl.setAttribute('value', 'true')
    }

    //Append schedule publish datetime to form to submit
    if (!!publishingDate) {
      const publishingDateEls = document.querySelectorAll(
        `form.mindr-form [name="${objectType}[formatted_publishing_date]"]`,
      )
      let publishingDateEl = null
      if (!publishingDateEls.length) {
        publishingDateEl = document.createElement('input')
        publishingDateEl.setAttribute('type', 'hidden')
        publishingDateEl.setAttribute(
          'name',
          `${objectType}[formatted_publishing_date]`,
        )
        form.appendChild(publishingDateEl)
      } else {
        publishingDateEl = publishingDateEls[0]
      }

      publishingDateEl.setAttribute('value', publishingDate)
    }

    if (!!publishingTime) {
      const publishingTimeEls = document.querySelectorAll(
        `form.mindr-form [name="${objectType}[formatted_publishing_time]"]`,
      )
      let publishingTimeEl = null

      if (!publishingTimeEls.length) {
        publishingTimeEl = document.createElement('input')
        publishingTimeEl.setAttribute('type', 'hidden')
        publishingTimeEl.setAttribute(
          'name',
          `${objectType}[formatted_publishing_time]`,
        )
        form.appendChild(publishingTimeEl)
      } else {
        publishingTimeEl = publishingTimeEls[0]
      }

      publishingTimeEl.setAttribute('value', publishingTime)
    }
  }

  /**
   * Submit main object form to trigger rerender of page for validation errors to show on page
   * @param validateUrl "/validate" endpoint to rerender edit page
   * @param formData the latest form data along with publishing datetime if any
   * @param form the main object form to be submitted
   * @param schedulePublish true if object is intended to be schedule published
   * @param submitForm true if submitting form to save rather than submitting form for validation
   */
  function submitFormToValidate(
    validateUrl,
    formData: FormData,
    form: HTMLFormElement,
    schedulePublish: boolean,
    submitForm = false,
  ) {
    if (!submitForm) {
      form.action = validateUrl
      const publishedEl = document.createElement('input')
      publishedEl.setAttribute('type', 'hidden')
      publishedEl.setAttribute('name', `${objectType}[published]`)

      publishedEl.setAttribute('value', 'true')
      form.appendChild(publishedEl)
    }

    attachSchedulePublishHiddenFields(
      schedulePublish,
      form,
      formData?.get(`${objectType}[formatted_publishing_date]`)?.toString(),
      formData?.get(`${objectType}[formatted_publishing_time]`)?.toString(),
    )

    form.requestSubmit()
  }

  /**
   * Initialize form data and form when page loads with existing object data
   * @returns an object with the initialized form and formData
   */
  function initializeFormData() {
    const forms = window.document.getElementsByClassName('mindr-form')
    const newForm = !!forms.length ? (forms[0] as HTMLFormElement) : undefined
    if (!newForm) {
      return //something is wrong if there's no form. This would never happen.
    }

    const authenticityTokenEls =
      document.getElementsByName('authenticity_token')
    let authenticityTokenEl = null
    if (!authenticityTokenEls.length) {
      authenticityTokenEl = document.createElement('input')
      authenticityTokenEl.setAttribute('type', 'hidden')
      authenticityTokenEl.setAttribute('name', 'authenticity_token')
      mindrForm.appendChild(authenticityTokenEl)
    } else {
      authenticityTokenEl = authenticityTokenEls[0]
    }
    authenticityTokenEl.setAttribute('value', window.authenticity_token)

    attachSchedulePublishHiddenFields(
      !!initialPublishingDate && !!initialPublishingTime,
      newForm,
      initialPublishingDate
        ? moment(initialPublishingDate.toString()).format('ddd, MMM Do, YYYY')
        : undefined,
      initialPublishingTime
        ? moment.utc(initialPublishingTime.toString()).format('h:mma')
        : undefined,
    )

    setMindrForm(newForm)
    const newFormData = new FormData(newForm)
    setFormData(newFormData)

    return { newForm, newFormData }
  }

  /**
   * Pick formatted_publishing_date and formatted_publishing_time errors from a list of errors
   * These errors are then passed to SchedulePublishDialogContent to show errors in modal
   */
  const schedulePublishErrors: Record<SchedulePublishErrorKey, string[]> =
    useMemo(
      () => ({
        ...(!!errors?.formatted_publishing_date && {
          formatted_publishing_date: errors.formatted_publishing_date,
        }),
        ...(!!errors?.formatted_publishing_time && {
          formatted_publishing_time: errors.formatted_publishing_time,
        }),
      }),
      [errors],
    )

  /**
   * Set timezone when formData's timezone changes so that it can be passed to
   * SchedulePublishDialogContent to show disabled timezone in modal
   */
  useEffect(() => {
    setTimezone(formData?.get(`${objectType}[timezone]`)?.toString() || null)
  }, [formData])

  return {
    objectType,
    timezone,
    mindrForm,
    formData,
    initializeFormData,
    schedulePublishErrors,
    validateFormData,
    submitFormToValidate,
    existingSchedulePublish,
  }
}
