import React, { useEffect, useCallback, useState } from 'react'
import $ from 'jquery'
import Switch from 'components/Switch'
import { FormFieldContainer } from 'components/Forms'

export interface ISwitcherWithConfirmation {
  name: string
  label: string
  errors?: string[]
  defaultValue: string | boolean | null
  id?: string
  confirmationTitle?: string | null
  confirmationDescription?: string | null
  toggleTarget?: string | null
  readOnly?: boolean
  className?: string
  onUpdate?: (value: boolean) => void
}

export default function SwitcherWithConfirmation({
  id,
  name,
  label,
  errors = [],
  defaultValue,
  confirmationTitle,
  confirmationDescription,
  toggleTarget,
  readOnly = false,
  className,
  onUpdate = () => {},
}: ISwitcherWithConfirmation): JSX.Element {
  const [checked, setChecked] = useState(!!defaultValue)

  useEffect(() => {
    onUpdate(checked)
    if (checked) {
      $(toggleTarget).removeClass('hidden')
    } else {
      $(toggleTarget).addClass('hidden')
    }
  }, [checked])

  const onChange = useCallback(
    (inputData) => {
      const newValue = inputData.target?.checked
      if (newValue && confirmationTitle) {
        // HACK - Executing sweetalert2 from a new bundle breaks the styling
        // so we use the instance from the application bundle instead.
        window
          .showConfirmation(confirmationTitle, confirmationDescription)
          .then((isConfirmed) => {
            setChecked(isConfirmed)
          })
      } else {
        setChecked(newValue)
      }
    },
    [setChecked, confirmationTitle, confirmationDescription],
  )

  return (
    <FormFieldContainer
      className={className}
      fieldId={id}
      label={label}
      error={errors.length > 0}>
      <Switch
        id={id || name}
        controlName={name}
        onChange={onChange}
        checked={checked}
        error={errors.join(', ')}
        readOnly={readOnly}
      />
    </FormFieldContainer>
  )
}
