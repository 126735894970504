import colorContrastChecker from 'color-contrast-checker'

import { colors, Theme } from 'stylesheets/theme'

export function findTheme(color: string): Theme {
  const ccc = new colorContrastChecker()

  const light_font = colors.backgrounds.white //white
  const dark_font = colors.text.text_5 //black

  if (ccc.isLevelAA(dark_font, color, 18)) {
    return Theme.Light
  } else if (ccc.isLevelAA(light_font, color, 18)) {
    return Theme.Dark
  } else {
    return Theme.Unknown
  }
}

export function isHex(color: string): boolean {
  const reg = /^#([0-9a-f]{3}){1,2}$/i
  return reg.test('#' + color)
}
