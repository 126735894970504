import { css } from '@emotion/react'

import Widget, { WidgetProps } from './Widget'

import Icon from 'images/icons/remove_applicant.svg'

import { colors } from 'stylesheets/theme'

interface RemovedApplicantProps extends Omit<WidgetProps, 'number' | 'icon'> {
  numPeople: number
}
const iconStyle = css({
  path: {
    stroke: colors.red,
    strokeWidth: 1.88,
  },
  'path[fill-rule="evenodd"]': {
    strokeWidth: 0.1,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.red_light,
  },
})

export default function RemovedApplicantWidget({
  numPeople,
  ...props
}: RemovedApplicantProps): JSX.Element {
  return (
    <Widget
      icon={<Icon height="18px" width="18px" css={iconStyle} />}
      number={numPeople}
      css={widgetStyle}
      {...props}
    />
  )
}
