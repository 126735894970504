import React from 'react'

import './EventAction.scss'

import { EventActionContext } from './context'

import EventActionView, { IEventActionProps } from './EventAction'

const EventAction: React.FC<IEventActionProps> = (props) => (
  <EventActionContext event={props.data}>
    <EventActionView {...props} />
  </EventActionContext>
)

export default EventAction
