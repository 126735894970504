import Input from 'components/Input'
import { Caption } from 'components/Typography'

interface IOrganizationGetInvolvedProps {
  errors: Record<string, string[]>
  currentValue: string
}

export default function OrganizationGetInvolved({
  errors,
  currentValue,
}: IOrganizationGetInvolvedProps): JSX.Element {
  return (
    <section>
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">GET INVOLVED</p>
        </div>
      </div>

      <Input
        type="text"
        defaultValue={currentValue}
        name="organization[get_involved_email]"
        label="Get Involved Email"
        error={errors['get_involved_email']?.join(' ')}
      />
      <Caption>
        If the &quot;Get Involved Email&quot; field is left blank, a Mindr will
        accept these requests and cooridinate with your team.
      </Caption>
    </section>
  )
}
