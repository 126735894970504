import Logo from 'components/HeaderNav/Logo'
import { css } from '@emotion/react'
import { spacings, colors, boxShadow, Theme } from 'stylesheets/theme'
import Line from 'images/preview_branding/line.svg'
import { useContext } from 'react'
import BrandingContext from './BrandingContext'

const previewEmailWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  height: 42 + 53,
  boxShadow: boxShadow.preview,
})

const logoSectionStyle = css({
  borderTopRightRadius: spacings.preview_radius,
  borderTopLeftRadius: spacings.preview_radius,
  width: 437,
  height: 42,
  display: 'flex',
  alignItems: 'center',
  padding: spacings.grid_gap_basis,
  backgroundColor: colors.backgrounds.white,
})

const logoStyle = css({
  h3: {
    fontSize: 10,
    lineHeight: '10px',
  },
  img: {
    width: 30,
    height: 30,
  },
})

const darkLogoStyle = css({
  h3: {
    color: colors.backgrounds.white,
    '&:hover': {
      color: colors.backgrounds.white,
      opacity: 0.7,
    },
  },
})

const underEmailHeaderSpaceStyle = css({
  backgroundColor: colors.backgrounds.white,
  height: 53,
  padding: spacings.grid_gap_basis,
})

const linesWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
})

export default function PreviewEmailHeader(): JSX.Element {
  const { logo, color, organization, currentHigherContrastTheme } =
    useContext(BrandingContext)
  return (
    <div css={previewEmailWrapper}>
      <div
        css={[
          logoSectionStyle,
          css({
            backgroundColor: `#${color}`,
          }),
        ]}>
        <Logo
          organization={organization}
          viewOnly
          css={[
            logoStyle,
            ...(currentHigherContrastTheme === Theme.Dark
              ? [darkLogoStyle]
              : []),
          ]}
          logoUrl={logo}
        />
      </div>
      <div css={underEmailHeaderSpaceStyle}>
        <div css={linesWrapperStyle}>
          <Line
            css={{
              stroke: colors.text.text_9,
              width: 344,
            }}
          />
          <Line
            css={{
              width: 223,
              path: {
                stroke: colors.borders.gray,
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}
