import useMentorshipApplication from './useMentorshipApplication'
import ApplicationFormContext from './ApplicationFormContext'
import Step from './Step'
import ThankYouPage from './ThankYouPage'
import { Tag, TagCategory } from './types'
import { IOption } from 'components/Inputs/Select'

interface MentorshipApplicationProps {
  orgName: string
  programName: string
  submitApplicationUrl: string
  maxSteps: number
  returnHomeUrl: string
  submitted?: boolean
  tag_categories: TagCategory[]
  tags: Tag[]
  boostList: IOption[]
  requiresManager?: boolean
  requiresBudget?: boolean
}

export default function MentorshipApplication({
  orgName,
  programName,
  submitApplicationUrl,
  maxSteps,
  returnHomeUrl,
  submitted = false,
  tag_categories,
  tags,
  boostList,
  requiresManager = false,
  requiresBudget = false,
}: MentorshipApplicationProps): JSX.Element {
  const {
    steps,
    onBackStep,
    currentStep,
    updateRawFormData,
    setStrengthCategories,
    setGoalCategories,
    leadrCharacteristics,
    incrementStep,
    formData,
    setErrors,
    getErrors,
    isMobile,
    loadMangerOptions,
  } = useMentorshipApplication(
    tag_categories,
    tags,
    boostList,
    '/users',
    requiresManager,
    requiresBudget,
  ) //TODO: change this to the correct url

  return (
    <ApplicationFormContext.Provider
      value={{
        orgName,
        programName,
        steps: steps,
        currentStep: currentStep,
        maxSteps,
        updateRawFormData,
        setStrengthCategories,
        setGoalCategories,
        leadrCharacteristics,
        incrementStep,
        onBackStep,
        formData,
        submitApplicationUrl,
        returnHomeUrl,
        setErrors,
        getErrors,
        isMobile,
        loadMangerOptions,
      }}>
      {submitted ? (
        <ThankYouPage homepageUrl={returnHomeUrl} alreadySubmitted />
      ) : (
        <Step currentStep={currentStep} />
      )}
    </ApplicationFormContext.Provider>
  )
}
