import Avatar from 'components/Avatar/Avatar'
import Button, { ButtonComponentType, Variant } from 'components/Button/Button'
import Card from 'components/Card'
import Container from 'components/Container/Container'
import { ITeamMember } from 'types'
import { colors, spacings } from 'stylesheets/theme'
import { Paragraph } from 'components/Typography'

interface ILeadershipRowProps {
  leader: ITeamMember
  isMindr?: boolean
}

interface ILeadershipCardProps {
  leaders: ITeamMember[]
  additionalLeaders: ITeamMember[]
}

function LeadershipRow({ leader, isMindr }: ILeadershipRowProps): JSX.Element {
  return (
    <Container>
      <Avatar
        size={60}
        avatarUrl={leader.user?.avatar_url}
        userInitial={leader.user?.first_name?.toUpperCase()?.charAt(0)}
      />
      <Container
        css={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginLeft: spacings.grid_gap_basis,
          gap: 2,
        }}>
        <h4
          css={{
            color: colors.text.text_5,
          }}>
          {leader.user?.full_name}
        </h4>
        {isMindr && <Paragraph>{leader.role ?? leader.user.title}</Paragraph>}
        <Button
          as={ButtonComponentType.LINK}
          variant={Variant.LINK}
          href={`mailto:${leader.user.email}`}>
          Email
        </Button>
      </Container>
    </Container>
  )
}

export default function LeadershipCard({
  leaders,
  additionalLeaders,
}: ILeadershipCardProps): JSX.Element {
  return (
    <Card
      title="Leadership team"
      css={{
        width: '100%',
      }}>
      <Container
        css={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: spacings.grid_gap_basis_num * 2,
        }}>
        {leaders.map((leader) => (
          <LeadershipRow key={leader.id} leader={leader} />
        ))}
        {additionalLeaders.map((leader) => (
          <LeadershipRow key={leader.id} leader={leader} isMindr />
        ))}
      </Container>
    </Card>
  )
}
