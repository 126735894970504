import { css } from '@emotion/react'
import { colors, fonts } from 'stylesheets/theme'

export const STooltipBackdropStyle = css({
  position: 'fixed',
  zIndex: 100000,
  backgroundColor: 'rgba(0, 0, 0, 0.01)', // css trick to gobble click events
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  opacity: 1.0,
  transition: 'opacity 0.3s, visibility 0.0s 0.0s',
})

export const STooltipBackdropClickStyle = css({
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
})

export const STooltipBackdropHoverStyle = css({
  pointerEvents: 'none',
})

export const STooltipIconWrapStyle = css({
  lineHeight: 0,
  display: 'inline-block',
  verticalAlign: 'middle',
})

export const STooltipNotShownStyle = css({
  pointerEvents: 'none',
  visibility: 'hidden',
  opacity: 0.0,
  transition: 'opacity 0.3s, visibility 0.0s 0.3s',
})

export const STooltipBoxStyle = css({
  position: 'absolute',
  top: 'auto',
  bottom: 'auto',
  left: 'auto',
  right: 'auto',
  backgroundColor: colors.text.text_3,
  maxHeight: '90vh',
  maxWidth: '90vw',
  width: 335,
  borderRadius: 6,
  padding: '8px 10px',
  boxShadow: `0 0 10px ${colors.backgrounds.black}`,
})

export const STooltipParagraphStyle = css({
  color: colors.backgrounds.white,
  fontWeight: 'normal',
  fontFamily: fonts.lato,
  lineHeight: '22px',
})
