import axios, { AxiosResponse } from 'axios'
import {
  ILeadrSubmitInquiryStrings,
  ISubmitInquiryAjaxUrl,
  ISubmitInquiryResponse,
} from './LeadrIndexReport.types'

interface ISubmitInquiryProps {
  strings: ILeadrSubmitInquiryStrings
  urls: ISubmitInquiryAjaxUrl
  inquiryText: string
  onSuccess?: (data: ISubmitInquiryResponse) => void
  onError?: (e: any) => void
}

export function submitInquiry({
  strings,
  urls,
  inquiryText = '',
  onSuccess = () => {},
  onError = () => {},
}: ISubmitInquiryProps): void {
  const data = {
    inquiry_text: inquiryText,
  }

  axios
    .post(urls.submit_inquiry, {
      authenticity_token: window.authenticity_token,
      params: data,
    })
    .catch((e: any) => {
      window.flash(strings.errors.inquiry.unable_to_submit, 'error')
      onError && onError(e)
    })
    .then(({ data }: AxiosResponse<ISubmitInquiryResponse>) => {
      window.flash(strings.messages.inquiry.successfully_submitted, 'success')
      onSuccess && onSuccess(data)
    })
}
