import { css } from '@emotion/react'
import cx from 'classnames'

interface ITableCellWrapperProps {
  columnType: string
  className?: string
  link?: string
  children: React.ReactNode
}

const cellPadding = css({
  paddingTop: '10px',
  paddingBottom: '10px',
})

const TableCellWrapper = ({
  columnType,
  link,
  className,
  children,
}: ITableCellWrapperProps): JSX.Element => (
  <td css={cellPadding} className={cx(`column-type-${columnType}`, className)}>
    {link ? (
      <a href={link} className="GojiCustomTable__cell-value">
        {children}
      </a>
    ) : (
      <div className="GojiCustomTable__cell-value">{children}</div>
    )}
  </td>
)

TableCellWrapper.defaultProps = {
  columnType: 'react',
}

export default TableCellWrapper
