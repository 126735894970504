import React, { useEffect, useRef, useState } from 'react'
import produce, { enableES5 } from 'immer'
enableES5()
import { v4 as uuidv4 } from 'uuid'

// styles
import './Attachments.scss'

// components
import Input from 'components/Input'

// icons
import DeleteIcon from '../../../images/icons/delete.svg'

// utils
import { useAdditionalSectionRows } from 'javascripts/general'

export type TAttachment = {
  fake_id: string | undefined
  file_name?: string
  cached_document_data?: string
  errors?: Record<string, string[]>
}

interface IAttachmentsProps {
  error?: string
  onChange: (attachments: TAttachment[]) => void
}

const Attachments: React.FC<IAttachmentsProps> = ({ error, onChange }) => {
  const [attachments, setAttachments] = useState<TAttachment[]>([])
  const componentRef = useRef()

  useEffect(() => {
    onChange(attachments.filter((attachment) => !!attachment.file_name))
  }, [attachments])

  const addRow = () => {
    setAttachments(
      produce((draft) => {
        draft.push({
          fake_id: uuidv4(),
        })
      }),
    )
  }

  const deleteAttachment = (id) => {
    setAttachments(
      attachments.filter((attachment) => attachment.fake_id !== id),
    )
  }

  const cleanRows = () => {
    setAttachments([])
  }

  useAdditionalSectionRows(true, componentRef, addRow, cleanRows)

  return (
    <div
      className="attachments additional-section-component"
      ref={componentRef}>
      <label className="block-label">Attachments</label>

      {attachments.map((attachment, index) => {
        const onInputChange = (editedObj) => {
          setAttachments(
            produce((draft) => {
              draft[index] = { ...draft[index], ...editedObj }
            }),
          )
        }

        const namePrefix = `attachments[${index}]`

        return (
          <div key={index} className="attachment-row additional-section-row">
            <Input
              name="file_name"
              fileFieldName="file"
              inputName={`${namePrefix}[document]`}
              deleteInputName={`${namePrefix}[remove_document]`}
              value={attachment.file_name}
              type="document"
              cachedData={attachment.cached_document_data}
              onChange={onInputChange}
              manualUpload
              error={attachment.errors?.document?.join(', ')}
              wrapperClassName="files-component-wrapper one"
            />
            <button
              className="not-styled-button delete"
              type="button"
              onClick={() => {
                deleteAttachment(attachment.fake_id)
              }}>
              <DeleteIcon />
            </button>
          </div>
        )
      })}
      <button className="link" type="button" onClick={addRow}>
        + Add Item
      </button>

      {!!error && (
        <div className="input-component-wrapper">
          <div className="validation-error-message">{error}</div>
        </div>
      )}
    </div>
  )
}

export default Attachments
