import { colors, spacings } from 'stylesheets/theme'
import { IApplicant, INotYetMatchedApplicant } from 'types'
import { MatchManuallyModal, MatchResultParticipantRemoveModal } from './Modals'
import { useState } from 'react'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import IconEditUnderline from '../../images/icons/edit_with_underline.svg'
import { css } from '@emotion/react'
import Spinner from 'images/icons/spinner.svg'
import { TableCellSecondaryButton } from 'components/Table/components/TableCell'
import Container from 'components/Container'

const boostIconCss = css({
  marginRight: spacings.grid_gap_basis_num / 2,
  path: {
    fill: colors.teal_dark,
  },
})

interface NotYetMatchedActionsCellProps {
  data: INotYetMatchedApplicant
}

export default function NotYetMatchedActionsCell({
  data,
}: NotYetMatchedActionsCellProps): JSX.Element {
  const { setBoostEditModalObject, tables, ajaxWithUpdates } =
    useMentorshipExchangeDetailsContext()

  const [loading, setLoading] = useState<boolean>(false)

  const [openMatchManuallyModal, setOpenMatchManuallyModal] = useState(false)
  const [openRemovalModal, setOpenRemovalModal] = useState(false)
  return (
    <div
      key={`not-yet-matched-${data.id}`}
      className={`not-yet-matched-${data.id}`}>
      {loading ? (
        <Spinner
          css={{
            width: 32,
            height: 32,
          }}
        />
      ) : (
        <Container
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <TableCellSecondaryButton
            key={`boost-${data.id}`}
            onClick={() => setBoostEditModalObject(data)}
            className="ignore-goji">
            {data.boost_field ? <IconEditUnderline css={boostIconCss} /> : null}
            Boost
          </TableCellSecondaryButton>
          <TableCellSecondaryButton
            key={`match-${data.id}`}
            onClick={() => setOpenMatchManuallyModal(true)}
            className="ignore-goji">
            Match
          </TableCellSecondaryButton>
          <TableCellSecondaryButton
            key={`remove-${data.id}`}
            onClick={() => setOpenRemovalModal(true)}
            className="ignore-goji"
            css={{
              color: colors.red,
              borderColor: colors.red,
              '&:not([disabled]):focus, &:not([disabled]):hover': {
                color: colors.red,
                borderColor: colors.red,
              },
            }}>
            Remove
          </TableCellSecondaryButton>
        </Container>
      )}
      <MatchManuallyModal
        key={`match-manually-modal-${data.id}`}
        isOpen={openMatchManuallyModal}
        onRequestClose={() => setOpenMatchManuallyModal(false)}
        onSave={(selectedParticipant, matchReasoning) => {
          setLoading(true)
          ajaxWithUpdates(
            data.manually_match_url,
            {
              application_id: selectedParticipant.application_id,
              match_reasons: matchReasoning,
            },
            'post',
            () => {
              setLoading(false)
              setOpenMatchManuallyModal(false)
            },
            () => {
              setLoading(false)
              setOpenMatchManuallyModal(false)
            },
          )
        }}
        participants={tables.all_not_yet_matched.tableData.rows
          .map((row) => {
            return row.data.id != data.id
              ? ({
                  id: row.data.id,
                  first_name: row.data.first_name,
                  last_name: row.data.last_name,
                  application_id: row.data.id,
                  email: '',
                  logo_url: '',
                } as IApplicant)
              : null
          })
          .filter((a) => !!a)}
        participant={data}
      />
      <MatchResultParticipantRemoveModal
        key={`match-result-participant-remove-modal-${data.id}`}
        isOpen={openRemovalModal}
        onRequestClose={() => setOpenRemovalModal(false)}
        onSave={(removed_note) => {
          setLoading(true)
          ajaxWithUpdates(
            data.remove_url,
            { removed_note },
            'post',
            () => {
              setLoading(false)
              setOpenRemovalModal(false)
            },
            () => {
              setLoading(false)
              setOpenRemovalModal(false)
            },
          )
        }}
      />
    </div>
  )
}
