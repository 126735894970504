import { useCallback, useContext } from 'react'
import { css } from '@emotion/react'
import Container from 'components/Container'
import { spacings } from 'stylesheets/theme'
import { Paragraph } from 'components/Typography'
import ApplicationFormContext from './ApplicationFormContext'

export interface Importance {
  label: string
  value: number
}

interface ImportanceRadioGroupProps {
  name: string
  importanceList?: Importance[]
}

const DEFAULT_IMPORTANCE_LIST = [
  {
    label: 'Always',
    value: 3,
  },
  {
    label: 'Sometimes',
    value: 2,
  },
  {
    label: 'Rarely',
    value: 1,
  },
  {
    label: 'Never',
    value: 0,
  },
]

export default function ImportanceRadioGroup({
  name,
  importanceList = DEFAULT_IMPORTANCE_LIST,
}: ImportanceRadioGroupProps): JSX.Element {
  const { formData, isMobile } = useContext(ApplicationFormContext)
  const getId = useCallback(
    (importance) =>
      `${name}-${importance.label.replace(/\s+/g, '-').toLowerCase()}-${
        importance.value
      }`,
    [name],
  )

  const radioContainerStyle = css({
    gap: spacings.grid_gap_basis,
    flex: 1,
    justifyContent: 'center',
  })

  const defaultRadiosContainerStyle = css({
    gap: spacings.grid_gap_basis_num * 2,
    alignItems: 'stretch',
    '&.input-component-wrapper': {
      width: '100%',
    },
  })

  const mobileRadiosContainerStyle = css({
    flexDirection: 'column',
  })

  const firstRadioContainerStyle = [
    radioContainerStyle,
    css({
      justifyContent: 'flex-start',
    }),
  ]

  const endRadioContainerStyle = [
    radioContainerStyle,
    css({
      justifyContent: 'flex-end',
    }),
  ]

  const radiosContainerStyle = [defaultRadiosContainerStyle]
  if (isMobile) {
    radiosContainerStyle.push(mobileRadiosContainerStyle)
  }

  return (
    <Container css={radiosContainerStyle}>
      {importanceList.map((importance, index) => {
        const id = getId(importance)

        let containerStyle: any = radioContainerStyle
        if (index === 0) {
          containerStyle = firstRadioContainerStyle
        } else if (index === importanceList.length - 1) {
          containerStyle = endRadioContainerStyle
        }

        if (isMobile) {
          containerStyle = firstRadioContainerStyle
        }

        return (
          <Container css={containerStyle} key={id}>
            <label key={id}>
              <Container>
                <input
                  type="radio"
                  id={id}
                  name={name}
                  value={importance.value}
                  defaultChecked={formData[name] === importance.value || null}
                />
                <Paragraph>{importance.label}</Paragraph>
              </Container>
            </label>
          </Container>
        )
      })}
    </Container>
  )
}
