import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import { FormFieldContainer } from 'components/Forms'
import { Select } from 'components/Inputs'
import { IMentorshipExchange } from 'types'
import { useMemo, useState } from 'react'
import { css } from '@emotion/react'
import Button, { ButtonComponentType, Variant } from 'components/Button'

export interface IOption {
  label: string
  value: string
}

const downloadLogCsvStyle = css({
  fontSize: '13px',
  lineHeight: '18px',
  textAlign: 'left',
})

interface ITransferApplicantsModalProps
  extends Omit<
    IModalProps,
    | 'size'
    | 'children'
    | 'submitButton'
    | 'cancelButton'
    | 'title'
    | 'isSubmitDisabled'
    | 'onSave'
  > {
  selectedCount: number
  programs: IMentorshipExchange[]
  errors?: string[]
  downloadLogCsv?: string
  onSave?(selectedProgram: IMentorshipExchange): void
}

export default function TransferApplicantsModal({
  selectedCount,
  programs,
  errors = [],
  downloadLogCsv,
  onSave,
  ...props
}: ITransferApplicantsModalProps): JSX.Element {
  const [selectedProgramOption, setSelectedProgramOption] =
    useState<IOption | null>(null)
  const [selectedProgram, setSelectedProgram] =
    useState<IMentorshipExchange | null>(null)
  const options = useMemo(() => {
    return programs.map(
      (program) =>
        ({
          label: program.full_name_with_index,
          value: program.id?.toString(),
        } as IOption),
    )
  }, [programs])
  return (
    <Modal
      {...props}
      onSave={() => onSave(selectedProgram)}
      css={modalStyle}
      size={Size.large}
      submitButton="Transfer"
      cancelButton="cancel"
      title="Transfer applicants"
      isSubmitDisabled={!selectedProgramOption}>
      <p>
        Please select the program these applicants should be transferred to.{' '}
        {selectedCount} applicant{selectedCount > 1 ? 's' : ''} will be copied
        to your selected program upon confirmation.
      </p>
      <FormFieldContainer required label="Program" fieldId={'program'}>
        <Select<IOption>
          name={'program'}
          defaultValue={null}
          options={options}
          onChange={(data: Record<string, string>) => {
            const selectedProgram = programs.find(
              (program) => program.id === parseInt(data['program']),
            )
            setSelectedProgram(selectedProgram)
            setSelectedProgramOption({
              label: selectedProgram?.full_name_with_index,
              value: data['program'],
            })
          }}
          errors={errors}
          subtextNode={
            downloadLogCsv ? (
              <Button
                as={ButtonComponentType.LINK}
                variant={Variant.LINK}
                href={downloadLogCsv}
                css={downloadLogCsvStyle}>
                Download log
              </Button>
            ) : null
          }
        />
      </FormFieldContainer>
    </Modal>
  )
}
