import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import FeaturedEventCall from 'components/EventCall/ResponsiveEventCall'
import { css } from '@emotion/react'
import Slider from './Slider'

const cardWrapperStyle = css({
  padding: '0 12px 7px',
  height: '100%',
})

const defaultSliderStyle = css({
  '.slick-slider-wrapper': { marginLeft: -11, marginRight: -11 },
  '&.slider-outer-container': {
    marginBottom: 0,
  },
})

interface IImageCarouselProps {
  upcomingActivities?: any[]
}

export default function UpcomingActivities({
  upcomingActivities = [],
}: IImageCarouselProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  return (
    <Slider
      css={[
        defaultSliderStyle,
        isMobile &&
          css({
            '.slider-container': {
              padding: '36px 24px',
            },
          }),
      ]}
      sectionName="upcoming activities"
      overrideSliderOptions={{
        infinite: true,
        responsive: undefined,
        slidesToScroll: 1,
        slidesToShow: 1,
      }}
      components={upcomingActivities.map((activity, index) => {
        return (
          <div css={cardWrapperStyle} key={'upcoming-activity-' + index}>
            <FeaturedEventCall
              key={activity.id}
              data={activity}
              isCall={activity.isCall}
            />
          </div>
        )
      })}
    />
  )
}
