import React from 'react'
import Table, { ITableProps } from 'components/Table'
import noWaitlistImg from 'images/waitlist_empty_state.png'

export default function WaitlistTable(props: ITableProps): JSX.Element {
  return (
    <Table
      {...props}
      emptyStateImgUrl={noWaitlistImg}
      object="waitlisted users"
      emptyStateSubtitle="Waitlisted users can automatically or manually be admitted as a registrant"
    />
  )
}
