import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from '../modal-style'

export default function StartMatchingModal({
  ...props
}: Omit<
  IModalProps,
  'size' | 'children' | 'submitButton' | 'cancelButton' | 'title'
>): JSX.Element {
  return (
    <Modal
      {...props}
      css={modalStyle}
      size={Size.large}
      submitButton="Start matching"
      cancelButton="Cancel"
      title="Are you sure you want to begin automatic matching?">
      <p>
        Please make sure you have completed any manually chosen pairs before
        proceeding with this action. All resulting matches will be moved to the
        &quot;Matches for Review&quot; table.
      </p>
    </Modal>
  )
}
