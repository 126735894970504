import Modal, { Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import { IMentorshipExchangeApplication } from 'types'

interface DeprioritizeModalProps {
  object: IMentorshipExchangeApplication
  isOpen?: boolean
  onClose: (id: number) => void
  onSave: (id: number) => void
}

export default function DeprioritizeModal({
  object,
  isOpen = false,
  onClose,
  onSave,
}: DeprioritizeModalProps): JSX.Element {
  const onCloseModal = () => {
    onClose(object?.id ?? 0)
  }
  const onSaveModal = () => {
    onSave(object?.id ?? 0)
  }
  return (
    <Modal
      css={modalStyle}
      isOpen={isOpen}
      size={Size.large}
      onRequestClose={onCloseModal}
      onSave={onSaveModal}
      submitButton="Deprioritize applicant"
      cancelButton="Cancel"
      title="Deprioritize this applicant">
      <p>
        Deprioritizing this applicant means the applicant will no longer
        necessarily be matched early in the process. They will still be regarded
        as eligible to be matched for this program. This action can be undone
        any time before matching starts, by clicking the star again in the
        &quot;Eligible Applicants&quot; table.
      </p>
    </Modal>
  )
}
