import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'

export const SInfoIcon = css({
  margin: '0 auto',
  marginBottom: spacings.grid_gap_basis_num * 2,
  height: 38,
  width: 38,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: 'cover',
})

export const SNumberedList = css({
  width: 440,
  margin: '0 auto',
})

export const SInfoWrapper = css({
  width: 655,
  margin: '0 auto',
})

export const SInfoCentered = css({
  textAlign: 'center',
  marginBottom: 24,
})
