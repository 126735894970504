import { ReactNode } from 'react'
import { css } from '@emotion/react'

interface ParagraphProps {
  children: ReactNode
  className?: string
  bold?: boolean
}

const BoldStyle = css({
  fontWeight: 700,
})

export default function Paragraph({
  children,
  className = '',
  bold = false,
}: ParagraphProps): JSX.Element {
  return (
    <p css={[bold && BoldStyle].filter(Boolean)} className={className}>
      {children}
    </p>
  )
}
