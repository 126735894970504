import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import axios from 'axios'

// styles
import './Cohorts.scss'

// components
import CommunitiesTiles, { LoadMoreButton } from 'components/CommunitiesTiles'
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import Input from 'components/Input'
import { ITableData } from 'components/Table'

// interfaces
import { useEffectNotOnMount } from 'javascripts/general'

interface ICohorts {
  initialData: ITableData
}

interface IFilters {
  'filter[*]': string
}

const fetchData = _.debounce(async (url, params, callback) => {
  try {
    const {
      data: { data },
    } = await axios.get(url, {
      params,
    })

    callback(data)
  } catch (e) {
    window.flash('Something went wrong!', 'alert')
  }
}, 400)

const Cohorts: React.FC<ICohorts> = ({ initialData }) => {
  const [communitiesData, setCommunitiesData] = useState(initialData)
  const [filters, setFilters] = useState({} as IFilters)

  const onFilterChange = useCallback(
    (obj) => {
      setFilters((currentValue) => ({ ...currentValue, ...obj }))
    },
    [setFilters],
  )

  const loadData = useCallback(
    (nextPage = false) => {
      fetchData(
        '/cohorts.json',
        nextPage
          ? { ...filters, page: communitiesData.paginator.current_page + 1 }
          : filters,
        (data) => {
          setCommunitiesData((currentData) => ({
            paginator: data.paginator,
            rows: nextPage ? [...currentData.rows, ...data.rows] : data.rows,
          }))
        },
      )
    },
    [filters, communitiesData, setCommunitiesData],
  )

  const nextPage = useCallback(() => loadData(true), [loadData])

  useEffectNotOnMount(() => {
    loadData()
  }, [filters])

  return (
    <div className="communities container">
      <div className="search-bar">
        <p>You can only see cohorts that you are a participant in.</p>
        <Input
          name="filter[*]"
          type="search"
          placeholder="Search by Title"
          wrapperClassName="search"
          value={filters['filter[*]']}
          onChange={onFilterChange}
        />
      </div>
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">Cohorts</p>
          <p className="section-total caption">{`( Total ${communitiesData.paginator?.total_entries} )`}</p>
        </div>
      </div>
      <CommunitiesTiles communitiesData={communitiesData} />
      {!communitiesData.rows.length && filters['filter[*]'] && (
        <EmptyPlaceholder text="No results. Try searching for the name of another cohort" />
      )}
      {!communitiesData.rows.length && !filters['filter[*]'] && (
        <EmptyPlaceholder text="You are not a part of any cohorts yet." />
      )}
      {!!communitiesData.rows.length &&
        communitiesData.paginator.current_page !=
          communitiesData.paginator.total_pages && (
          <LoadMoreButton onClick={nextPage} />
        )}
    </div>
  )
}

export default Cohorts
