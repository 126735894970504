import cx from 'classnames'
import { css } from '@emotion/react'
import Table, { ITableProps } from 'components/Table'

import emptyStateImage from 'images/people_empty_state.png'

const emptyStateTableCss = css({
  '.empty-state-wrapper': {
    paddingTop: 89,
    paddingBottom: 89,
  },
})

export default function ApplicantsTable({
  title,
  tableData,
  className,
  ...tableProps
}: ITableProps): JSX.Element {
  const classes = cx(
    {
      'applicants-table': true,
      'no-pagination': !!tableData?.paginator,
    },
    className,
  )

  return (
    <div className={classes}>
      <Table
        {...tableProps}
        tableData={tableData}
        title={title ?? 'Applicants'}
        emptyStateImgUrl={emptyStateImage}
        css={emptyStateTableCss}
      />

      <div className="applicants-table-pagination"></div>
    </div>
  )
}
