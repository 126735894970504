import React, { useRef, useState } from 'react'
import produce, { enableES5 } from 'immer'
enableES5()

import axios from 'axios'
import _ from 'lodash'

// components
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import Input from 'components/Input'
import EventCall from 'components/EventCall'
import { IMainTableFilters, ITableProps } from 'components/Table'

import type { IEventCall } from 'types'

// utils
import { useEffectNotOnMount } from 'javascripts/general'

// services
async function fetchData(url: string, params: IFilters) {
  try {
    const response = await axios.get(url, { params })
    return response.data.data
  } catch (error) {
    window.flash('Something went wrong!', 'alert')
  }
}

interface IFilters extends IMainTableFilters {
  page?: number
}

const UpcomingActivities: React.FC<ITableProps> = ({
  tableData,
  tableMeta,
  ...props
}) => {
  const [data, setData] = useState(tableData)

  const [selectedFilters, setSelectedFilters] = useState<IFilters>(
    props.selectedFilters as IFilters,
  )
  const onFilterChange = (
    object: Record<string, string | string[] | number | boolean>,
  ) => {
    setSelectedFilters((currentFiltersValue) => ({
      ...currentFiltersValue,
      ...object,
    }))
  }

  const loadData = async (filters, loadMore = false, page?: number) => {
    const normalizedFilters = { ...filters }
    if (loadMore) {
      normalizedFilters.page = data.paginator.current_page + 1
    } else if (page) {
      normalizedFilters.page = page + 1
    }

    const fetched = await fetchData(tableMeta.url, normalizedFilters)

    setData((prev) =>
      loadMore
        ? produce(prev, (draft) => {
            draft.rows = [...draft.rows, ...fetched.rows]
            draft.paginator = fetched.paginator
          })
        : produce(prev, (draft) => {
            draft.rows = fetched.rows
            draft.paginator = fetched.paginator
          }),
    )
  }

  const { current: loadDataDebounced } = useRef(_.debounce(loadData, 300))

  useEffectNotOnMount(() => {
    loadDataDebounced(selectedFilters, false)
  }, [selectedFilters])

  const totalCaption = `(Showing ${data.rows.length} of ${data.paginator.total_entries})`

  return (
    <div className="upcoming-activities">
      <div className="header">
        <h2>Upcoming Activities</h2>
        <Input
          name="filter[*]"
          type="search"
          placeholder="Search Title or Location"
          wrapperClassName="search"
          value={selectedFilters['filter[*]']}
          onChange={onFilterChange}
        />
      </div>
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">Your Upcoming Events</p>
          <p className="section-title">{totalCaption}</p>
        </div>
      </div>
      {data.rows.length ? (
        <div className="tiles">
          {data.rows.map((item) => {
            return (
              <EventCall
                data={{ ...item.data, link: item.link } as IEventCall}
                isCall={false}
                isSmall
                key={item.data.id as number}
              />
            )
          })}
        </div>
      ) : (
        <EmptyPlaceholder
          text={'You have not registered for any upcoming events.'}
          link={{
            href: '/events',
            label: 'Explore ALL Events',
          }}
        />
      )}
      {data.rows.length
        ? data.paginator &&
          data.paginator.current_page != data.paginator.total_pages && (
            <div className="load-button">
              <button
                className="button primary"
                onClick={() => {
                  loadData(selectedFilters, true)
                }}>
                LOAD MORE
              </button>
            </div>
          )
        : null}
    </div>
  )
}

export default UpcomingActivities
