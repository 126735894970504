import { css } from '@emotion/react'
import { colors, fonts, spacings } from 'stylesheets/theme'

export const SModal = css({
  '&.large': {
    maxWidth: '100vw',
    width: 696,
  },

  '.mindr-modal-title': {
    color: colors.text.text_5,
  },
})

export const SModalHeading = css({
  color: colors.text.text_5,
  fontSize: 24,
  lineHeight: '30px',
  fontFamily: fonts.poppins,
  fontWeight: 700,
})

export const SParagraph = css({
  marginTop: 2 * spacings.grid_gap_basis_num,
})
