import React from 'react'
import _ from 'lodash'

import Input from 'components/Input'

import { IEventCallHost } from 'types'

interface ICheckboxProps {
  hosts: IEventCallHost[]
  checkboxValues: Record<string, boolean>
  selectAll: boolean
  toggleSelectAll: (value: boolean) => void
  toggleCohost: (cohostId: string, value: boolean) => void
}

const SendReminderForm: React.FC<ICheckboxProps> = ({
  hosts,
  checkboxValues,
  selectAll,
  toggleSelectAll,
  toggleCohost,
}) => {
  const handleCheckboxChange = (data) => {
    const hostId = _.keys(data)[0]
    toggleCohost(hostId.substring(5), data[hostId])
  }

  const handleSelectAllChange = (data) => {
    toggleSelectAll(data.selectAll)
  }

  const hostsList = (hosts: IEventCallHost[]) => {
    const visibleHostElems = hosts.map((host) => {
      return (
        <li className="host-item" key={`host-item-${host.id}`}>
          <Input
            type="checkbox"
            name={`host-${host.id}`}
            checked={checkboxValues[host.id]}
            onChange={handleCheckboxChange}
            label={
              <>
                <img className="host-logo" src={host.logo} />
                <span className="host-name">{host.name}</span>
              </>
            }
          />
        </li>
      )
    })

    return <ul className="hosts-list">{visibleHostElems}</ul>
  }

  return (
    <div className="select-communities-container mindr-modal-section">
      {hosts.length === 1 ? (
        <>
          <p className="select-communities-description">
            Send a reminder to all subscribers of the community:
          </p>
          <div className="single-host-container">
            <img className="host-logo" src={hosts[0].logo_large} />
            <div>
              <h2 className="host-name">{hosts[0].name}</h2>
              <p className="host-subtitle">{hosts[0].subtitle}</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="select-communities-description">
            Choose communities to send reminders
          </p>
          <Input
            type="checkbox"
            onChange={handleSelectAllChange}
            checked={selectAll}
            name="selectAll"
            label={`Select all ${hosts.length} communities`}
          />
          {hostsList(hosts)}
        </>
      )}
    </div>
  )
}

export default SendReminderForm
