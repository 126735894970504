import { Paragraph } from 'components/Typography'
import {
  STooltipBackdropClickStyle,
  STooltipBackdropHoverStyle,
  STooltipBackdropStyle,
  STooltipBoxStyle,
  STooltipIconWrapStyle,
  STooltipNotShownStyle,
  STooltipParagraphStyle,
} from './Tooltip.styles'
import { css } from '@emotion/react'
import { CSSInterpolation } from '@storybook/theming/dist/ts3.9/_modules/@emotion-react-node_modules-@emotion-serialize-types-index'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLockpage } from 'hooks/useLockpage'
import { spacings } from 'stylesheets/theme'
import { ETooltipTriggerType, ICoords } from './Tolltip.types'

type THorizontal = 'left' | 'right' | 'center'
// type TVertical = 'top' | 'bottom' | 'center'

interface ITooltipProps {
  show?: boolean
  overrideCoords?: ICoords
  triggerElement?: React.ReactNode
  triggerType?: ETooltipTriggerType
  text: string
  className?: string
  // overlap: example - the left side of the Tooltip is aligned with the left side of the anchor element.
  // no-overlap: example - the left side of the Tooltip is aligned with the right side of the anchor element.
  alignment?: 'overlap' | 'no-overlap'
}

export default function Tooltip({
  show,
  overrideCoords,
  triggerElement = null,
  triggerType = ETooltipTriggerType.HOVER,
  text,
  className = '',
  alignment = 'no-overlap',
}: ITooltipProps): JSX.Element {
  const boxRef = useRef(null)
  const iconRef = useRef(null)
  const [isShown, setShown] = useState<boolean>(false)
  useEffect(() => {
    show !== undefined && setShown(show)
  }, [show])

  const onOpen = useCallback(() => {
    setShown(true)
  }, [iconRef])

  const onClose = useCallback(() => {
    setShown(false)
  }, [iconRef])

  const events = useMemo(() => {
    const ons = {
      onClick: onOpen,
      onMouseOver: undefined,
      onMouseOut: undefined,
    }
    if (triggerType === ETooltipTriggerType.HOVER) {
      ons.onMouseOver = onOpen
      ons.onMouseOut = onClose
    }
    return ons
  }, [triggerType])

  useLockpage(isShown)

  const positioning = useMemo<CSSInterpolation>(() => {
    const pos = {
      top: undefined,
      bottom: undefined,
      left: undefined,
      right: undefined,
    }

    if (overrideCoords) {
      pos.top = overrideCoords.y
      pos.left = overrideCoords.x
      return css(pos)
    }

    if (!iconRef?.current || !boxRef?.current) return null

    const anchorRect = iconRef?.current?.getBoundingClientRect()
    const boxRect = boxRef?.current?.getBoundingClientRect()

    const maxHeight = window.innerHeight
    const maxWidth = window.innerWidth

    const verticalSpaceNeeded = boxRect.height + spacings.grid_gap_basis_num
    const horizontalSpaceNeeded = boxRect.width + spacings.grid_gap_basis_num

    let horizontal: THorizontal = 'right'
    // let vertical: TVertical = 'top'

    // Try putting above first, then below, then page-centered
    if (anchorRect.top > verticalSpaceNeeded) {
      // vertical = 'top'
      // if there is enough space above, put it above
      pos.top = anchorRect.top - boxRect.height
    } else if (maxHeight - anchorRect.bottom > verticalSpaceNeeded) {
      // vertical = 'bottom'
      // if there is enough space below, put it below
      pos.top = anchorRect.bottom
    } else {
      // vertical = 'center'
      // otherwise, center it on screen vertically
      pos.top = Math.round((maxHeight - boxRect.height) / 2)
    }

    // Try putting to the right first, then to the left, then page-centered
    if (maxWidth - anchorRect.right > horizontalSpaceNeeded) {
      horizontal = 'right'
      // if there is enough space to the right, put it to the right
      pos.left = anchorRect.right
    } else if (anchorRect.left > horizontalSpaceNeeded) {
      horizontal = 'left'
      // if there is enough space to the left, put it to the left
      pos.left = anchorRect.left - boxRect.width
    } else {
      horizontal = 'center'
      // otherwise, center it on screen horizontally
      pos.left = Math.round((maxWidth - boxRect.width) / 2)
    }

    // The above calculation is for `no-overlap` alignment. Adjust if needed for `overlap`.
    if (alignment === 'overlap') {
      if (horizontal === 'right') {
        pos.left -= anchorRect.width
      } else if (horizontal === 'left') {
        pos.left += anchorRect.width
      }
    }

    return css(pos)
  }, [iconRef, isShown, overrideCoords])

  return (
    <>
      <span
        ref={iconRef}
        css={STooltipIconWrapStyle}
        className={className}
        {...events}>
        {triggerElement}
      </span>
      <div
        css={[
          STooltipBackdropStyle,
          isShown ? null : STooltipNotShownStyle,
          triggerType === ETooltipTriggerType.CLICK
            ? STooltipBackdropClickStyle
            : STooltipBackdropHoverStyle,
        ]}
        onClick={onClose}>
        <div ref={boxRef} css={[STooltipBoxStyle, positioning]}>
          <Paragraph css={STooltipParagraphStyle}>{text}</Paragraph>
        </div>
      </div>
    </>
  )
}
