import LeadrArchetype from 'components/LeadrArchetype'
import LeadrResultChart from './LeadrResultChart'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  SActionsFullContainer,
  SAlreadyInquiredContainer,
  SColumnStack,
  SColumns,
  SColumnsNarrow,
  SInquireButton,
  SSectionTitle,
  SShareOnLinkedInButtonWrap,
  SShareOnLinkedInIcon,
  SYouAreTitleWrapper,
  SYouAreTitleWrapperMobile,
  narrowReportWrapperStyle,
  reportWrapperStyle,
} from './LeadrIndexReport.styles'
import Container from 'components/Container'
import { Caption, Paragraph, SectionTitle } from 'components/Typography'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import LeadrAlreadySubmittedModal from './LeadrAlreadySubmittedModal'
import FeedbackIcon from 'images/icons/feedback.svg'
import pngLinkedInIcon from 'images/icons/linkedin_14.png'
import LeadrInquiryModal from './LeadrInquiryModal'
import { submitInquiry } from './LeadrIndexReport.ajax'
import { useLeadrIndexReportContext } from './LeadrIndexReport.context'
import { ISubmitInquiryResponse } from './LeadrIndexReport.types'
import { translateAllEnLiterals } from 'utils/translateEnLiterals'
import LeadrShareOnLinkedInModal from 'components/LeadrShareOnLinkedInModal'
import { calcGridWidth, spacings } from 'stylesheets/theme'
import CheckedIcon from 'images/icons/checked.svg'
import { colors } from 'stylesheets/theme'
import { useShowGridOverlay } from 'hooks/useShowGridOverlay'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

export default function LeadrIndexReportComponent(): JSX.Element {
  const {
    urls,
    strings,
    assessmentDetails,
    assessmentResults,
    archetypeDetails,
    setAssessmentDetails,
  } = useLeadrIndexReportContext()

  useEffect(() => {
    document.documentElement.style.backgroundColor = '#ffffff'
    document.body.style.backgroundColor = '#ffffff'
  }, [])

  if (!archetypeDetails || !assessmentDetails || !assessmentResults) {
    return <h1>No assessment</h1>
  }

  const [isInquiryModalOpen, setIsInquiryModalOpen] = useState<boolean>(false)
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)
  const [isAlreadySubmittedModalOpen, setIsAlreadySubmittedModalOpen] =
    useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  const isNarrow = isMobile

  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const already_submitted = urlParams.get('already_submitted')
    if (already_submitted) {
      setIsAlreadySubmittedModalOpen(true)
    }
  }, [window.location.search])

  const style = useMemo(() => {
    return isNarrow ? SColumnsNarrow : null
  }, [isNarrow])

  const linkedInIcon14 = useMemo(() => {
    return (
      <img
        aria-label={strings.buttons.share_on_linkedin}
        src={pngLinkedInIcon}
        css={SShareOnLinkedInIcon}></img>
    )
  }, [])

  const onShareModalOpen = useCallback(() => {
    setIsShareModalOpen(true)
  }, [archetypeDetails])

  const onShareModalClose = useCallback(() => {
    setIsShareModalOpen(false)
  }, [archetypeDetails])

  const onInquireModalOpen = useCallback(() => {
    setIsInquiryModalOpen(true)
  }, [assessmentDetails])

  const onInquireModalSave = useCallback(
    (modalText: string) => {
      setSubmitting(true)
      submitInquiry({
        strings,
        urls,
        inquiryText: modalText,
        onSuccess: (value: ISubmitInquiryResponse) => {
          setAssessmentDetails(value.assessment)
          setIsInquiryModalOpen(false)
          setSubmitting(false)
        },
        onError: () => {
          setSubmitting(false)
        },
      })
    },
    [assessmentDetails],
  )

  const onInquireModalClose = useCallback(() => {
    setIsInquiryModalOpen(false)
  }, [assessmentDetails])

  const { parentStyle, overlay } = useShowGridOverlay(true)

  return (
    <div
      className="report-wrapper"
      css={[
        parentStyle,
        reportWrapperStyle,
        isNarrow && narrowReportWrapperStyle,
      ]}>
      {overlay}
      <Container className="header" css={SColumnStack}>
        <h3 css={SSectionTitle}>{strings.titles.page}</h3>
      </Container>

      <div css={[SColumns, style]}>
        <div className="column second">
          <Container
            direction="column"
            alignment="start"
            className="summary-header"
            css={[
              SYouAreTitleWrapper,
              isNarrow ? SYouAreTitleWrapperMobile : null,
            ]}>
            <LeadrArchetype archetype={archetypeDetails} />
            <Container direction="column" alignment="start">
              <h1>
                {translateAllEnLiterals(strings.titles.archetype, {
                  archetype: archetypeDetails.name,
                  archetype_article: archetypeDetails.article,
                })}
              </h1>
              <Caption>
                Based on your responses to the LEADR™ Assessment on{' '}
                {assessmentDetails.created_at_date}
              </Caption>
            </Container>
          </Container>

          <Container className="summary" css={SColumnStack}>
            <SectionTitle
              css={{
                marginBottom: spacings.grid_gap_basis_num,
              }}>
              {strings.titles.understanding}
            </SectionTitle>
            <Paragraph>{archetypeDetails.description}</Paragraph>
            {archetypeDetails.competencies.map((desc, index) => {
              return <Paragraph key={`competency-${index}`}>{desc}</Paragraph>
            })}
          </Container>
        </div>

        <div className="column first centered">
          <LeadrResultChart
            results={assessmentResults.leadr}
            width={calcGridWidth(3)}
            height={calcGridWidth(3)}
          />
        </div>
      </div>

      <div css={SShareOnLinkedInButtonWrap}>
        <Button
          as={ButtonComponentType.BUTTON}
          variant={Variant.SECONDARY}
          onClick={onShareModalOpen}>
          {linkedInIcon14}
          {strings.buttons.share_on_linkedin}
        </Button>
      </div>

      <Container
        direction="column"
        alignment="start"
        css={{
          gap: spacings.grid_gap_basis_num * 2,
        }}>
        <div className="actions full" css={SActionsFullContainer}>
          {assessmentDetails.inquiry && assessmentDetails.inquiry_at ? (
            <Container css={SAlreadyInquiredContainer}>
              <CheckedIcon
                css={{
                  minWidth: 14,
                  minHeight: 14,
                  path: {
                    stroke: colors.text.text_5,
                  },
                }}
              />
              <Paragraph bold>
                You&apos;ve submitted a retake inquiry on{' '}
                {assessmentDetails.inquiry_at}
              </Paragraph>
            </Container>
          ) : (
            <Button
              as={ButtonComponentType.LINK}
              variant={Variant.LINK}
              css={SInquireButton}
              onClick={onInquireModalOpen}>
              <FeedbackIcon />
              {strings.buttons.inquire}
            </Button>
          )}
        </div>
        <Caption>
          {strings.descriptions.footer}{' '}
          {strings.descriptions.learn_more.description}{' '}
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            href={urls.leadr_index_mindr_url}
            css={{
              display: 'inline',
              fontSize: '13px',
              lineHeight: '18px',
            }}>
            {strings.descriptions.learn_more.link}
          </Button>
        </Caption>
      </Container>
      <LeadrAlreadySubmittedModal
        isOpen={isAlreadySubmittedModalOpen}
        onRequestClose={() => setIsAlreadySubmittedModalOpen(false)}
      />
      <LeadrShareOnLinkedInModal
        isOpen={isShareModalOpen}
        strings={strings.modals.share_on_linkedin}
        archetype={archetypeDetails}
        onRequestClose={onShareModalClose}
        onSave={onShareModalClose}
      />
      <LeadrInquiryModal
        strings={strings}
        isOpen={isInquiryModalOpen}
        onModalClose={onInquireModalClose}
        onModalSave={onInquireModalSave}
        isCancelDisabled={submitting}
        isSubmitDisabled={submitting}
      />
    </div>
  )
}
