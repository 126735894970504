import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from '../modal-style'

export default function AcceptSelectedMatchesModal({
  ...props
}: Omit<
  IModalProps,
  'size' | 'children' | 'submitButton' | 'cancelButton' | 'title'
>): JSX.Element {
  return (
    <Modal
      {...props}
      css={modalStyle}
      size={Size.large}
      submitButton="Accept matches"
      cancelButton="Cancel"
      title="Are you sure you want to accept the selected matches? ">
      <p>
        Upon confirmation, your selected matches will be accepted and
        transferred to the &apos;Accepted matches&apos; table. Please note that
        this action cannot be undone.
      </p>
    </Modal>
  )
}
