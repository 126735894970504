import { css } from '@emotion/react'
import { OrganizationSwitch } from './types'
import Logo, { logoHeight } from './Logo'
import { colors, spacings } from 'stylesheets/theme'

interface OrganizationSwitcherProps {
  organizations: OrganizationSwitch[]
}

const switcherListStyle = css({
  display: 'flex',
  flexDirection: 'row',
  listStyle: 'none',
  margin: `0 0 0 ${spacings.grid_gap_basis}`,
  padding: 0,
  borderLeft: `1px solid ${colors.borders.gray}`,
})

const organizationItemStyle = css({
  marginLeft: spacings.grid_gap_basis_num,
  a: {
    display: 'block',
    position: 'relative',
    height: logoHeight,
    '&:hover::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'block',
      backgroundColor: colors.backgrounds.black,
      opacity: 0.1,
      height: logoHeight,
      width: logoHeight,
      borderRadius: '50%',
    },
  },
  img: {
    margin: 0,
  },
})

export default function OrganizationSwitcher({
  organizations,
}: OrganizationSwitcherProps): JSX.Element {
  return (
    <ul css={switcherListStyle}>
      {organizations.map((organization) => (
        <li key={organization.public_title} css={organizationItemStyle}>
          <Logo organization={organization} isInactive />
        </li>
      ))}
    </ul>
  )
}
