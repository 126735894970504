import React, { useRef, useState } from 'react'
import produce, { enableES5 } from 'immer'
enableES5()
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'

// styles
import './Resources.scss'

// components
import Input from 'components/Input'

// icons
import DeleteIcon from '../../images/icons/delete.svg'

// utils
import { useAdditionalSectionRows } from 'javascripts/general'
import DragAndDrop from 'components/DragAndDrop/DragAndDrop'

// constants
const resourceTypes = [
  {
    label: 'Link',
    value: 'link',
  },
  {
    label: 'Document',
    value: 'document',
  },
]

// interfaces
interface IDocument {
  fake_id: string | undefined
  id?: number
  sort_order?: number
  type: string
  file_name?: string
  title?: string
  cached_document_data?: string
  _destroy?: boolean
  errors?: Record<string, string[]>
  hidden?: boolean
}

interface ILink {
  fake_id: string | undefined
  id?: number
  sort_order?: number
  type: string
  url?: string
  title?: string
  _destroy?: boolean
  errors?: Record<string, string[]>
  hidden?: boolean
}

interface IResources {
  initialValues: Array<IDocument | ILink>
  rootEntity: string
  readOnly: boolean
}

const Resources: React.FC<IResources> = ({
  initialValues,
  rootEntity,
  readOnly,
}) => {
  const [resources, changeResources] = useState(
    initialValues.map((resource) => ({
      ...resource,
      fake_id: uuidv4(),
      name: `${rootEntity}[${
        resource.type === 'document'
          ? 'documents_attributes'
          : 'links_attributes'
      }]`,
    })),
  )
  const componentRef = useRef()

  const addRow = () => {
    changeResources(
      produce((draft) => {
        const id = uuidv4()
        draft.push({
          fake_id: id,
          type: 'link',
          name: `${rootEntity}[links_attributes]`,
        })
      }),
    )
  }

  const cleanRows = () => {
    changeResources(
      produce((draft) => {
        draft.forEach((row) => {
          row._destroy = true
          row.hidden = true
        })
      }),
    )
  }

  const renderedComponentIndex = resources.findIndex(
    (resource) => !resource._destroy,
  )

  useAdditionalSectionRows(
    renderedComponentIndex !== -1,
    componentRef,
    addRow,
    cleanRows,
  )

  const onInputChange = (editedObj, index) => {
    changeResources(
      produce((draft) => {
        draft[index] = { ...draft[index], ...editedObj }
      }),
    )
  }

  return (
    <div className="resources additional-section-component" ref={componentRef}>
      <DragAndDrop
        items={resources}
        setItems={changeResources}
        component={(index, item) => (
          <div
            className={classNames('resource-row additional-section-row', {
              hidden: item._destroy,
              first: true,
            })}
            key={index}>
            {!!item.id && (
              <input
                type="hidden"
                name={`${item.name}[${index}][id]`}
                value={item.id}
              />
            )}
            {item._destroy && (
              <input
                type="hidden"
                name={`${item.name}[${index}][_destroy]`}
                value="true"
              />
            )}
            <Input
              name="type"
              hideName
              label="Resource Type"
              type="select"
              value={item.type}
              options={resourceTypes}
              onChange={(inputData) =>
                onInputChange(
                  {
                    ...inputData,
                    name: `${rootEntity}[${
                      inputData.type === 'document'
                        ? 'documents_attributes'
                        : 'links_attributes'
                    }]`,
                  },
                  index,
                )
              }
              wrapperClassName="one"
              readOnly={readOnly}
            />
            <Input
              name="title"
              inputName={
                item.type ? `${item.name}[${index}][title]` : undefined
              }
              label="Name*"
              value={item.title}
              maxLength="100"
              onChange={(inputData) => onInputChange(inputData, index)}
              error={item.errors?.title?.join(', ')}
              wrapperClassName="two"
              readOnly={readOnly}
            />
            {item.type === 'document' && (
              <Input
                name="file_name"
                inputName={`${item.name}[${index}][document]`}
                deleteInputName={`${item.name}[${index}][remove_document]`}
                value={(item as IDocument).file_name}
                label="Document (File)*"
                type="document"
                cachedData={
                  (item as IDocument).cached_document_data || item.documentData
                }
                onChange={(inputData) => onInputChange(inputData, index)}
                onChangeDocument={(documentData) => {
                  onInputChange({ documentData }, index)
                }}
                error={item.errors?.document?.join(', ')}
                wrapperClassName="files-component-wrapper three"
                readOnly={readOnly}
              />
            )}
            {item.type === 'link' && (
              <Input
                name="url"
                inputName={`${item.name}[${index}][url]`}
                value={(item as ILink).url}
                label="Link (URL)*"
                onChange={(inputData) => onInputChange(inputData, index)}
                error={item.errors?.url?.join(', ')}
                mask="https-url"
                wrapperClassName="three"
                readOnly={readOnly}
              />
            )}
            {!item.type && (
              <Input
                name="placeholder"
                hideName
                value="Select Resource Type First"
                label="URL or File*"
                readOnly
                wrapperClassName="three"
              />
            )}
            {!readOnly && (
              <button
                aria-label="remove resource"
                className={classNames('not-styled-button delete', {
                  first: true,
                })}
                type="button"
                onClick={() => {
                  onInputChange({ _destroy: true, hidden: true }, index)
                }}>
                <DeleteIcon />
              </button>
            )}
          </div>
        )}></DragAndDrop>
      {!readOnly && (
        <button className="link" type="button" onClick={addRow}>
          + Add Item
        </button>
      )}
    </div>
  )
}

export default Resources
