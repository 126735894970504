import { css } from '@emotion/react'

import Widget from './Widget'

import PeopleIcon from 'images/icons/declined.svg'

import { colors } from 'stylesheets/theme'

interface PeopleWidgetProps {
  numDeclined: number
  title: string
}

const iconStyle = css({
  'path.declined_svg__calendar': {
    fill: colors.red,
  },

  'path.declined_svg__cross': {
    stroke: colors.red,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.declined_widget_background,
  },
})

export default function PeopleWidget({
  numDeclined,
  title,
}: PeopleWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<PeopleIcon height="18px" width="18px" css={iconStyle} />}
      number={numDeclined}
      title={title}
      css={widgetStyle}
    />
  )
}
