import { useState, useEffect, useMemo } from 'react'
import find from 'lodash/find'

enum Target {
  WINDOW,
  CONTENT,
}

function getWidth(target: Target): number {
  if (target === Target.WINDOW) {
    return window.innerWidth
  }

  const contentEl = document.querySelector('.content')
  return contentEl
    ? (contentEl as HTMLDivElement).offsetWidth
    : window.innerWidth
}

function useDimensions(
  target: Target,
  ...breakpoints: number[]
): number | undefined {
  const [width, setWindowWidth] = useState(getWidth(target))

  const sortedBreakpoints = useMemo(() => breakpoints.sort(), [breakpoints])
  const closestBreakpoint = useMemo(() => {
    return find(sortedBreakpoints, (bp) => bp > width)
  }, [sortedBreakpoints, width])

  useEffect(() => {
    function handleResize() {
      const width = getWidth(target)
      setWindowWidth(width)
    }

    const contentArea = document.querySelector('.content')
    if (target === Target.CONTENT && contentArea) {
      const observer = new ResizeObserver(() => {
        handleResize()
      })
      observer.observe(contentArea)
    }

    window.addEventListener('resize', handleResize)
  }, [])

  return closestBreakpoint
}

/**
 * Returns the closest breakpoint, that is not lower than the current
 * width of the .content element. If no breakpoint is higher than
 * the current width, null is returned.
 */
export const useContentDimensions = (
  ...breakpoints: number[]
): number | undefined => useDimensions(Target.CONTENT, ...breakpoints)

/**
 * Returns the closest breakpoint, that is not lower than the current
 * width of the window. If no breakpoint is higher than
 * the current width, null is returned.
 */
export const useWindowDimensions = (
  ...breakpoints: number[]
): number | undefined => useDimensions(Target.WINDOW, ...breakpoints)
