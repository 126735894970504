import { css } from '@emotion/react'
import Button, { Variant } from 'components/Button'

import multiverseCommunitiesEmptyStateUrl from 'images/multiverse_communities_empty_state.png'
import communitiesEmptyStateUrl from 'images/communities_empty_state.png'

import { colors, spacings } from 'stylesheets/theme'
import { useMemo } from 'react'

export enum PromotionCardType {
  GET_MINDR = 'get_mindr',
  MORE_COMMUNITIES = 'more_communities',
}

interface PromotionCardProps {
  orgName: string
  type: PromotionCardType
  className?: string
  promotionEmail?: string
}

const imgStyle = css({
  width: 80,
  height: 80,
})

const textStyles = css({
  marginBottom: spacings.grid_gap_basis_num,
  textAlign: 'center',
})

const titleStyle = css({
  fontWeight: 'bold',
})

const defaultCardWrapperStyle = css({
  backgroundColor: colors.backgrounds.white,
  borderRadius: spacings.radius,
  boxShadow: '0px 2px 6px rgba(0,0,0,.1)',
  padding: spacings.grid_gap_basis_num * 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  maxWidth: 360,
  height: '100%',

  ':hover': {
    boxShadow:
      '0px 12px 17px rgba(0,0,0,.06), 0px 5px 22px rgba(0,0,0,.06), 0px 7px 8px rgba(0,0,0,.1)',
    p: {
      color: colors.text.text_3,
    },
  },
})

const GET_INVOLVED_MINDR_EMAIL = 'getstarted@mindrglobal.com'

export default function PromotionCard({
  orgName,
  type,
  className,
  promotionEmail,
}: PromotionCardProps): JSX.Element {
  const getInvolvedEmailSubject = useMemo(
    () => `Idea for a new community at ${orgName}`,
    [orgName],
  )

  const promotionalData = useMemo(
    () => ({
      [PromotionCardType.GET_MINDR]: {
        link: `mailto:${promotionEmail || GET_INVOLVED_MINDR_EMAIL}?subject=${
          encodeURIComponent(getInvolvedEmailSubject) || ''
        }`,
        buttonText: 'Get Started',
        title: 'Ready to build belonging in your workplace?',
        subtitle: null,
        imgUrl: multiverseCommunitiesEmptyStateUrl,
        buttonVariant: Variant.SECONDARY,
      },
      [PromotionCardType.MORE_COMMUNITIES]: {
        link: `mailto:${promotionEmail || GET_INVOLVED_MINDR_EMAIL}?subject=${
          encodeURIComponent(getInvolvedEmailSubject) || ''
        }`,
        buttonText: 'Get Involved',
        title: 'Ready to lead the way?',
        subtitle:
          'Submit your idea for a new community by clicking the button below.',
        imgUrl: communitiesEmptyStateUrl,
        buttonVariant: Variant.PRIMARY,
      },
    }),
    [getInvolvedEmailSubject],
  )

  const { link, title, imgUrl, subtitle, buttonText, buttonVariant } = useMemo(
    () => promotionalData[type],
    [promotionalData],
  )
  promotionalData[type]

  return (
    <a
      href={link}
      css={[defaultCardWrapperStyle]}
      className={className}
      target="_blank"
      rel="noreferrer">
      <img css={imgStyle} src={imgUrl} />

      <p css={[textStyles, titleStyle]}>{title}</p>
      {!!subtitle && <p css={textStyles}>{subtitle}</p>}
      <Button variant={buttonVariant}>{buttonText}</Button>
    </a>
  )
}
