import React from 'react'
import classNames from 'classnames'

//components
import Table, { ITableProps } from 'components/Table'
import BatchEmail from 'components/BatchEmail'
//services
import useUsersState from 'state/Users'
// assets
import DownloadIcon from '../../images/icons/download.svg'
//styles
import './LeadershipMembers.scss'
import MembershipTabs from 'components/MembershipTabs'
import MembershipFilters, { TFilterOptions } from 'components/MembershipFilters'

//interfaces
interface IComponentProps extends ITableProps {
  communityType: 'cohort' | 'community'
  isMembers?: boolean
  isExportable?: boolean
  emailUrl: string
  counters?: {
    total: number
    new: number
  }
  filters: TFilterOptions
}

const LeadershipMembers: React.FC<IComponentProps> = ({
  tableData: initialData,
  tableMeta: initialMeta,
  counters: initialCounters,
  filters,
  isMembers,
  communityType,
  isExportable,
  emailUrl,
}) => {
  const {
    data,
    meta,
    counters,
    filters: selectedFilters,
    tableFilters,
    exportCsvUrl,
    loadPage,
    updateOrder,
    updateFilters,
  } = useUsersState({
    url: initialMeta.url,
    initialData,
    initialMeta,
    initialCounters,
    initialFilters: {}, //TODO
  })

  return (
    <div className="leadership-root">
      {isMembers && (
        <div className="section-header">
          <div className="header-left">
            <p className="section-title">Community Subscribers</p>
          </div>
        </div>
      )}
      <div className="section-header">
        {isMembers ? (
          <MembershipTabs
            className="header-left"
            totalTitle="Total Community Subscribers"
            counters={counters}
            newSubscribers={selectedFilters.newSubscribers}
            updateNewSubscribersFilter={(value) => {
              updateFilters({ newSubscribers: value })
            }}
          />
        ) : (
          <div className="header-left">
            <p className="section-title">Leadership</p>
          </div>
        )}
        <div className={classNames('right', { bottom: isMembers })}>
          {isExportable && (
            <a href={exportCsvUrl} className="link">
              <DownloadIcon />
              Export.csv
            </a>
          )}
          {!isMembers && (
            <a
              href={`${meta.url
                .replace('.json', '')
                .replace('leaders', 'details')}#leaders`}
              className="button secondary">
              Edit Leaders
            </a>
          )}
          <BatchEmail
            isMembers={isMembers}
            communityType={communityType}
            url={emailUrl}
            filters={tableFilters}
            totalRecipients={data.paginator.total_entries}
            disabled={data.paginator.total_entries === 0}
            btnText="Batch Email"
            btnClassName="button primary"
          />
        </div>
      </div>
      <div className={classNames('data', { subscribers: isMembers })}>
        <MembershipFilters
          searchPlaceholder={`Search ${isMembers ? 'Subscribers' : 'Leaders'}`}
          filters={selectedFilters}
          filterOptions={filters}
          updateFilters={updateFilters}
        />
        <Table
          tableData={data}
          tableMeta={meta}
          onPageChange={loadPage}
          selectedFilters={tableFilters}
          onOrderChange={({ sort_col, sort_dir }) => {
            updateOrder(
              sort_col,
              sort_dir === 'asc' || sort_dir === 'desc' ? sort_dir : 'asc',
            )
          }}
        />
      </div>
    </div>
  )
}

export default LeadershipMembers
