import React from 'react'
import cx from 'classnames'

import './InputWrapper.scss'
import Container from 'components/Container'

interface IInputWrapperProps {
  className?: string
  withIcon?: boolean
  errors?: string[]
  subtextNode?: React.ReactNode
  children: React.ReactNode
}

const InputWrapper = ({
  className,
  withIcon = false,
  errors = [],
  subtextNode = null,
  children,
}: IInputWrapperProps): React.ReactElement => {
  return (
    <div className={cx('input-wrapper', className, { 'with-icon': withIcon })}>
      {children}
      <Container
        css={{
          gap: '4px',
        }}>
        {errors.length ? (
          <span className="validation-error-message">{errors.join(', ')}</span>
        ) : null}
        {subtextNode}
      </Container>
    </div>
  )
}

export default InputWrapper
