import { css } from '@emotion/react'
import Slider from 'react-slick'
import { SectionTitle } from 'components/Typography'
import { calcGridWidth, colors, spacings } from 'stylesheets/theme'
import { useMemo } from 'react'
import Container from 'components/Container/Container'
import { SlickArrow } from 'components/HomePage/SlickArrow'
import SpeakerCard from './SpeakerCard'
import { ISpaeker } from 'types'
import { DATE_BLOCK_AND_MARGIN, PAGE_PADDING, PAGE_WIDTH } from './constants'
import { useComplexMediaQuery } from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

const ARROW_SIZE = 30

const sliderStyle = css({})

const outerContainerStyle = css({
  backgroundColor: colors.backgrounds.gray,
  width: '100%',
})

const defaultContainerStyle = css({
  padding: `${spacings.grid_gap_basis_num * 4}px 0`,
  paddingLeft: DATE_BLOCK_AND_MARGIN + PAGE_PADDING,
  paddingRight: PAGE_PADDING,
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '100vw',
})

const mobileContainerStyle = css({
  padding: '36px 24px',
})

const desktopContainerStyle = css({
  width: PAGE_WIDTH,
  maxWidth: `calc(100% - ${PAGE_PADDING * 2}px)`,
  paddingLeft: calcGridWidth(1, 1),
  paddingRight: 0,
})

const defaultArrowStyle = css({
  position: 'absolute',
  top: 'calc(50% - 15px)',
  transform: 'translateY(-50%)',
  width: ARROW_SIZE,
  height: ARROW_SIZE,
  cursor: 'pointer',
})

const defaultPrevArrowStyle = css({
  // ARROW_SIZE = width of the arrow icon
  // calcGridWidth(0, 1) = 1 grid gap
  // -11 = the negative margin from defaultSliderStyle
  left: -(ARROW_SIZE + calcGridWidth(0, 1) - 11),
})

const defaultNextArrowStyle = css({
  // ARROW_SIZE = width of the arrow icon
  // calcGridWidth(0, 1) = 1 grid gap
  // -11 = the negative margin from defaultSliderStyle
  right: -(ARROW_SIZE + calcGridWidth(0, 1) - 11),
})

const mobileArrowStyle = css({
  display: 'none !important',
})

const defaultSliderStyle = css({
  margin: '0 auto',
  position: 'relative',
  left: 1,
  padding: 0,
  marginRight: -11,
  marginLeft: -11,
})

const mobileSliderStyle = css({
  marginRight: 0,
  marginLeft: 0,
})

interface SpeakersSliderProps {
  isMobile?: boolean
  speakers: ISpaeker[]
}

export default function SpeakersSlider({
  isMobile = false,
  speakers = [],
}: SpeakersSliderProps): JSX.Element {
  const isFullDesktop = useComplexMediaQuery({ minWidth: breakpoints.xlg })
    .meetsDetails.minWidth

  const sliderOptions = useMemo(
    () => ({
      draggable: false,
      nextArrow: (
        <SlickArrow
          isNext
          aria-label="next"
          css={[
            defaultArrowStyle,
            defaultNextArrowStyle,
            isMobile && mobileArrowStyle,
          ]}
        />
      ),
      prevArrow: (
        <SlickArrow
          isNext={false}
          aria-label="prev"
          css={[
            defaultArrowStyle,
            defaultPrevArrowStyle,
            isMobile && mobileArrowStyle,
          ]}
        />
      ),
      slidesToScroll: 1,
      slidesToShow: 3,
      infinite: speakers.length > 3,
      autoplay: false,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1175,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 860,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [isMobile, speakers],
  )

  return (
    <Container css={outerContainerStyle}>
      <Container
        direction="column"
        css={[
          defaultContainerStyle,
          isFullDesktop && desktopContainerStyle,
          isMobile && mobileContainerStyle,
        ]}>
        <div
          css={{
            margin: '0 auto',
            width: '100%',
            maxWidth: PAGE_WIDTH,
          }}>
          <SectionTitle
            css={{
              marginBottom: 24,
            }}>
            Featured Speakers
          </SectionTitle>
          <div css={[defaultSliderStyle, isMobile && mobileSliderStyle]}>
            <Slider {...sliderOptions} css={sliderStyle}>
              {speakers.map((speaker) => (
                <SpeakerCard
                  key={speaker.name}
                  isMobile={isMobile}
                  speaker={speaker}
                />
              ))}
            </Slider>
          </div>
        </div>
      </Container>
    </Container>
  )
}
