import { css } from '@emotion/react'

import Widget, { WidgetProps } from './Widget'

import HourGlassIcon from 'images/icons/hourglass.svg'

import { colors } from 'stylesheets/theme'

interface NotYetMatchedWidgetProps
  extends Omit<WidgetProps, 'number' | 'icon'> {
  numPeople: number
}

const iconStyle = css({
  path: {
    fill: colors.orange_dark,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.not_yet_matched_widget_background,
  },
})

export default function NotYetMatchedWidget({
  numPeople,
  ...props
}: NotYetMatchedWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<HourGlassIcon height="18px" width="18px" css={iconStyle} />}
      number={numPeople}
      css={widgetStyle}
      {...props}
    />
  )
}
