import { css } from '@emotion/react'
import HeaderNavContainer from './HeaderNavContainer'
import { colors } from 'stylesheets/theme'
import authLogo from 'images/logo_auth.svg?url'
import ProvidedByLogo from './ProvidedByLogo'
import ButtonsContainer from './ButtonsContainer'
import Button, { ButtonComponentType } from 'components/Button'

const rootLogoStyle = css({
  display: 'block',
  img: {
    height: 17,
    display: 'block',
  },
  '&:focus': {
    outlineColor: colors.backgrounds.white,
  },
})

interface HeaderNavProps {
  rootPath: string
  signedIn?: boolean
  loginPath: string
}

export default function HeaderNav({
  rootPath,
  signedIn = false,
  loginPath,
}: HeaderNavProps): JSX.Element {
  return (
    <HeaderNavContainer>
      <a css={rootLogoStyle} href={rootPath}>
        <img src={authLogo}></img>
      </a>
      <ProvidedByLogo />
      {!signedIn && (
        <ButtonsContainer>
          <Button as={ButtonComponentType.LINK} href={loginPath}>
            Log In
          </Button>
        </ButtonsContainer>
      )}
    </HeaderNavContainer>
  )
}
