import Container from 'components/Container'
import ApplicationFormFieldContainer from './ApplicationFormFieldContainer'
import ImportanceRadioGroup, { Importance } from './ImportanceRadioGroup'
import ApplicationStepWrapper from './ApplicationStepWrapper'
import { spacings } from 'stylesheets/theme'

const IMPORTANCE_LIST: Importance[] = [
  {
    label: 'Very important',
    value: 3,
  },
  {
    label: 'Somewhat important',
    value: 2,
  },
  {
    label: 'Less important',
    value: 1,
  },
  {
    label: 'Does not matter',
    value: 0,
  },
]

export default function ImportantConsiderations(): JSX.Element {
  return (
    <ApplicationStepWrapper title="How important are the following considerations to you?">
      <Container
        direction="column"
        css={{
          alignItems: 'flex-start',
          width: '100%',
          gap: spacings.grid_gap_basis_num * 2,
        }}>
        <ApplicationFormFieldContainer
          label="Having a match who is very senior or tenured:"
          fieldId="tenure-select">
          <ImportanceRadioGroup
            name="important-tenure"
            importanceList={IMPORTANCE_LIST}
          />
        </ApplicationFormFieldContainer>
        <ApplicationFormFieldContainer
          label="Having a match who is located in the same geographic location as me:"
          fieldId="location-select">
          <ImportanceRadioGroup
            name="important-location"
            importanceList={IMPORTANCE_LIST}
          />
        </ApplicationFormFieldContainer>
        <ApplicationFormFieldContainer
          label="Having a match from the same department or professional field as me:"
          fieldId="department-profession-select">
          <ImportanceRadioGroup
            name="important-department-profession"
            importanceList={IMPORTANCE_LIST}
          />
        </ApplicationFormFieldContainer>
      </Container>
    </ApplicationStepWrapper>
  )
}
