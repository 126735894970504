import OrganizationHeaderNavWrapper, {
  OrganizationHeaderNavWrapperProps,
} from './OrganizationHeaderNavWrapper'
import Button, { ButtonComponentType } from 'components/Button'
import Avatar from 'components/Avatar'
import { Theme } from 'stylesheets/theme'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { useMemo } from 'react'

interface SignedInHeaderNavProps
  extends Omit<OrganizationHeaderNavWrapperProps, 'signedIn' | 'children'> {
  showSettings?: boolean
  settingsPath?: string
  showLeadership?: boolean
  leadershipPath?: string
  badgesPath: string
  profilePath: string
  userInitial?: string
  avatarUrl?: string
  showExitOrg?: boolean
  ejectOrgPath?: string
  showEndMimic?: boolean
  endMimicPath?: string
  isResponsivePage?: boolean
}

export default function SignedInHeaderNav({
  organization,
  rootPath,
  backgroundColor = null,
  theme = Theme.Light,
  otherOrgs = [],
  showSettings = false,
  settingsPath,
  showLeadership = false,
  leadershipPath,
  badgesPath,
  profilePath,
  userInitial,
  avatarUrl = null,
  showExitOrg = false,
  ejectOrgPath = null,
  showEndMimic = false,
  endMimicPath = null,
  isResponsivePage = false,
}: SignedInHeaderNavProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const { isDesiredWidth: isSmallerScreen } = useMediaQuery(breakpoints.md)
  const showDesktopNav = useMemo(
    () =>
      (!isSmallerScreen && !isMobile && isResponsivePage) || !isResponsivePage,
    [isSmallerScreen, isMobile, isResponsivePage],
  )

  return (
    <OrganizationHeaderNavWrapper
      organization={organization}
      rootPath={rootPath}
      backgroundColor={backgroundColor}
      theme={theme}
      otherOrgs={otherOrgs}>
      {showDesktopNav && (
        <>
          {showSettings && (
            <Button as={ButtonComponentType.LINK} href={settingsPath}>
              Settings
            </Button>
          )}
          {showLeadership && (
            <Button as={ButtonComponentType.LINK} href={leadershipPath}>
              Leadership Dashboard
            </Button>
          )}
          <Button as={ButtonComponentType.LINK} href={badgesPath}>
            Badges
          </Button>
        </>
      )}
      <Avatar
        profilePath={profilePath}
        avatarUrl={avatarUrl}
        userInitial={userInitial}
      />
      {showDesktopNav && (
        <>
          {showExitOrg && (
            <Button
              as={ButtonComponentType.LINK}
              css={{ height: '100%', whiteSpace: 'nowrap' }}
              href={ejectOrgPath}>
              Exit Org View
            </Button>
          )}
          {showEndMimic && (
            <Button
              as={ButtonComponentType.LINK}
              css={{ height: '100%', whiteSpace: 'nowrap' }}
              href={endMimicPath}>
              End Mimic
            </Button>
          )}
        </>
      )}
    </OrganizationHeaderNavWrapper>
  )
}
