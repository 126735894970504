import { useState, useCallback, useEffect, useMemo } from 'react'
import axios from 'axios'
import _ from 'lodash'

import { IEventCallHost } from 'types'

interface ISendReminderFormProps {
  eventId: number
  hosts: IEventCallHost[]
}

interface ISendReminderFormState {
  loading: boolean
  done: boolean
  checkboxValues: Record<string, boolean>
  toggleCohost: (cohostId: string, value: boolean) => void
  selectAll: boolean
  toggleSelectAll: (value: boolean) => void
  submitDisabled: boolean
  sendReminder: () => void
}

const useSendReminderFormState = ({
  eventId,
  hosts,
}: ISendReminderFormProps): ISendReminderFormState => {
  const setAllHosts = useCallback(
    (value) => {
      const initialValues = {}
      hosts.forEach((host) => {
        initialValues[host.id] = value
      })
      return initialValues
    },
    [hosts],
  )

  const [checkboxValues, setCheckboxValues] = useState(setAllHosts(true))
  const [selectAll, setSelectAll] = useState(true)
  const [loading, setLoading] = useState(false)
  const [done, setDone] = useState(false)

  const submitDisabled = useMemo(
    () => loading || _.every(checkboxValues, (value) => !value),
    [loading, checkboxValues],
  )

  const toggleSelectAll = useCallback(
    (value: boolean) => {
      setSelectAll(value)
      setCheckboxValues(setAllHosts(value))
    },
    [selectAll, setSelectAll, setCheckboxValues, setAllHosts],
  )

  const toggleCohost = useCallback(
    (cohostId: string, value: boolean) => {
      setCheckboxValues({
        ...checkboxValues,
        [cohostId]: value,
      })
    },
    [checkboxValues, setCheckboxValues],
  )

  useEffect(() => {
    setSelectAll(_.every(checkboxValues, Boolean))
  }, [checkboxValues, setSelectAll])

  const sendReminder = useCallback(async () => {
    if (submitDisabled) {
      return
    }

    setLoading(true)

    try {
      const {
        data: {
          data: { redirect_to },
        },
      } = await axios.post(`${eventId}/reminder.json`, {
        authenticity_token: window.authenticity_token,
        hostIds: Object.keys(_.pickBy(checkboxValues, Boolean)),
      })
      if (redirect_to) {
        window.location.href = redirect_to
      }
    } catch (e) {
      window.flash('Something went wrong!', 'alert')
    } finally {
      setLoading(false)
      setDone(true)
    }
  }, [checkboxValues, submitDisabled, setLoading])

  return {
    loading,
    done,
    checkboxValues,
    toggleCohost,
    selectAll,
    toggleSelectAll,
    submitDisabled,
    sendReminder,
  }
}

export default useSendReminderFormState
