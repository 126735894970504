import { css } from '@emotion/react'
import { colors, fonts, mqDesktop, spacings } from 'stylesheets/theme'

export const SPanel = css({
  backgroundColor: colors.backgrounds.white,
  boxShadow: '1px 2px 8px 0px rgba(0, 0, 0, 0.1)',
  padding: 3 * spacings.grid_gap_basis_num,
  paddingBottom: 6 * spacings.grid_gap_basis_num,
  gap: 0,
})

export const SLink = css({
  display: 'inline',
})

export const SSectionWrap = css({
  height: '100%',
  width: '100%',
})

export const SSectionTitles = css({
  color: colors.text.text_5,
  fontFamily: fonts.lato,
  textTransform: 'uppercase',
  lineHeight: '23px',
  fontSize: 14,
  marginBottom: spacings.grid_gap_basis_num,
})

export const SColumns = css({
  '--columns': 1,
  display: 'grid',
  gridTemplateColumns: 'repeat(var(--columns), 1fr)',
  gap: 3 * spacings.grid_gap_basis_num,

  [mqDesktop]: {
    '--columns': 2,
  },
})

export const SColumnsNarrow = css({
  '--columns': 1,

  [mqDesktop]: {
    '--columns': 1,
  },
})

export const SColumnsSmallGap = css([
  SColumns,
  { gap: 2 * spacings.grid_gap_basis_num },
])

export const SSeparator = css({
  borderColor: 'transparent',
  borderBottomColor: colors.borders.gray,
  marginTop: 2 * spacings.grid_gap_basis_num,
  marginBottom: 3 * spacings.grid_gap_basis_num,
  width: '100%',
})

export const SArchetypeTrigger = css({
  cursor: 'pointer',
})

export const SUpdateButton = css({
  marginTop: spacings.grid_gap_basis_num,
})
