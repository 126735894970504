import Button, { ButtonComponentType, Variant } from 'components/Button'
import InvitedSubscribersModal from './InvitedSubscribersModal'
import { useMemo, useState } from 'react'
import { IFilterItem } from './types'
import { Caption } from 'components/Typography'
import { serverUTCTimeFormat } from 'javascripts/general'
import moment from 'moment'

interface IActivityLogActionsCellProps {
  data: {
    full_name: string
    activity_raw: string
    activity: string
    acted_at: string
    details?: any
  }
}

export default function ActivityLogActionsCell({
  data,
}: IActivityLogActionsCellProps): JSX.Element {
  const [open, setOpen] = useState(false)
  const { activity_raw, activity } = data

  let audience = null
  if (data.details?.audience) {
    audience = JSON.parse(data.details?.audience)
  }

  const action = useMemo(() => {
    switch (activity_raw) {
      case 'applications_transferred_in':
      case 'applications_transferred_out':
        if (!data.details?.url || !data.details?.count) {
          return <Caption>{activity}</Caption>
        }

        return (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            className="link ignore-goji"
            href={data.details.url}>
            {`${data.details.count} applicants transferred`}
          </Button>
        )

      case 'registered_users':
        return (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            className="link ignore-goji"
            onClick={() => {
              setOpen(true)
            }}>
            {data.details.is_subset_audience
              ? 'Registered ' + data.details?.audience_count + ' users'
              : 'Registered all subscribers'}
          </Button>
        )

      case 'auto_registering_users':
        return (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            className="link ignore-goji"
            onClick={() => {
              setOpen(true)
            }}>
            {data.details.is_subset_audience
              ? `Auto-registering ${data.details?.audience_count} users`
              : `Auto-registering all users`}
          </Button>
        )
      case 'assessment_invites_sent':
        return (
          <Caption>
            Invitations sent to {data.details?.invite_count} users
          </Caption>
        )
      case 'event_registered':
        const ids = data.details?.user_ids ?? []
        const countBlock =
          ids.length > 0
            ? `(${ids.length} user${ids.length > 1 ? 's' : ''})`
            : ''
        return <Caption>{`${activity} ${countBlock}`}</Caption>
      default:
        return <Caption>{activity}</Caption>
    }
  }, [data, activity, activity_raw])
  return (
    <>
      {action}
      {data.details && Object.keys(data.details).length !== 0 && (
        <InvitedSubscribersModal
          title="Automatically registered users"
          isRegisteredUsers={true}
          isOpen={open}
          onRequestClose={() => setOpen(false)}
          isSubsetAudience={data.details.is_subset_audience}
          isCsvImport={audience?.is_csv_import ?? false}
          audienceCount={data.details.audience_count}
          audienceFilters={
            audience
              ? (JSON.parse(audience.audience_filters) as IFilterItem[][])
              : null
          }
          invitationReceivedDateTime={moment(
            (data.acted_at as string).replace('UTC', '+0000'),
            serverUTCTimeFormat,
          ).format('ddd, MMM Do, YYYY [at] h:mma')}
          recipientsTableProps={{
            ...data.details.audience_user_table,
            title: 'Registered Users',
          }}
          importHistoryTableProps={data.details.csv_import_history_table}
          initiated={activity_raw === 'auto_registering_users'}
        />
      )}
    </>
  )
}
