import Input from 'components/Input'
import { SIdpSettingActionRow, SIdpSettingItem } from './IdpSetting.styles'
import { IIdpSettingProps } from './IdpSetting.types'
import { useEffect, useMemo, useState } from 'react'
import { IOption } from 'javascripts/general'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import Container from 'components/Container'
import { SPreviewImageWrapper } from './IdpSettings.styles'

export default function IdpSetting({
  strings,
  prefix,
  settings = {},
  onDelete,
}: IIdpSettingProps): JSX.Element {
  const destroyName = `${prefix}[_destroy]`
  const idName = `${prefix}[id]`
  const displayNameName = `${prefix}[display_name]`
  const imageFakeName = `${prefix}[_fake_image]`
  const imageName = `${prefix}[logo]`
  const imageDeleteName = `${prefix}[remove_logo]`
  const metadataUrlName = `${prefix}[idp_metadata_url]`
  const isAutoPopped = !!settings.idp_metadata_url
  const ssoUrlName = `${prefix}[idp_sso_service_url]`
  const sloUrlName = `${prefix}[idp_slo_service_url]`
  const entityName = `${prefix}[idp_entity_id]`
  const bindingName = `${prefix}[idp_sso_service_binding]`
  const authnName = `${prefix}[idp_sso_authn_context]`
  const certName = `${prefix}[idp_cert]`

  const bindingOptions: IOption[] = useMemo(() => {
    const list: IOption[] = []
    Object.keys(strings.options.idp_sso_service_binding).forEach((key) => {
      list.push({
        label: strings.options.idp_sso_service_binding[key],
        value: strings.options.idp_sso_service_binding[key],
      })
    })
    return list
  }, [strings])

  const [basename, setBasename] = useState<string>()
  const [previewUrl, setPreviewUrl] = useState<string>()
  const [rawFileData, setRawFileData] = useState<string>()
  useEffect(() => {
    setBasename(settings?.logo_file_name ?? '')
    setPreviewUrl(settings?.logo_url ?? '')
    setRawFileData(settings?.logo_file_data ?? '')
  }, [])

  return (
    <div css={SIdpSettingItem}>
      {settings.id ? (
        <input name={idName} type="hidden" value={settings.id} />
      ) : null}

      {settings._destroy ? (
        <>
          <h3>
            Item has been removed{' '}
            {settings.idp_entity_id ? `(${settings.idp_entity_id})` : ''}
          </h3>
          <input name={destroyName} type="hidden" value="1" />
        </>
      ) : (
        <>
          <Input
            label={strings.fields.display_name}
            name={displayNameName}
            type="textbox"
            defaultValue={settings?.display_name ?? ''}
          />

          <Container direction="row" alignment="start">
            <div css={SPreviewImageWrapper}>
              {previewUrl ? <img src={previewUrl} /> : null}
            </div>
            <Input
              label={strings.fields.file_data}
              hideName
              name={imageFakeName}
              inputName={imageName}
              deleteInputName={imageDeleteName}
              type="image"
              onChange={(result: {
                fileName: string
                imageData: string
                errors: { image: [] }
              }) => {
                setBasename(result.fileName)
                setRawFileData(result.imageData)
                const data = JSON.parse(result.imageData)
                setPreviewUrl(data?.preview ?? '')
              }}
              imageData={rawFileData}
              value={basename}
              imageType="round"
            />
            <input type="hidden" name={imageName} defaultValue={rawFileData} />
          </Container>

          <Input
            label={strings.fields.idp_metadata_url}
            name={metadataUrlName}
            type="textbox"
            defaultValue={settings?.idp_metadata_url ?? ''}
          />

          <Input
            label={strings.fields.idp_sso_service_url}
            name={ssoUrlName}
            type="textbox"
            readOnly={isAutoPopped}
            defaultValue={settings?.idp_sso_service_url ?? ''}
          />

          <Input
            label={strings.fields.idp_slo_service_url}
            name={sloUrlName}
            type="textbox"
            readOnly={isAutoPopped}
            defaultValue={settings?.idp_slo_service_url ?? ''}
          />

          <Input
            label={strings.fields.idp_entity_id}
            name={entityName}
            type="textbox"
            readOnly={isAutoPopped}
            defaultValue={settings?.idp_entity_id ?? ''}
          />

          <Input
            label={strings.fields.idp_sso_service_binding}
            name={bindingName}
            type="select"
            readOnly={isAutoPopped}
            value={settings?.idp_sso_service_binding ?? ''}
            options={bindingOptions}
          />

          <Input
            label={strings.fields.idp_sso_authn_context}
            name={authnName}
            type="slide_switch"
            readOnly={isAutoPopped}
            value={settings?.idp_sso_authn_context ?? ''}
            checked={!!settings?.idp_sso_authn_context}
          />

          <Input
            label={strings.fields.idp_cert}
            name={certName}
            type="textarea"
            readOnly={isAutoPopped}
            disabled={isAutoPopped}
            defaultValue={settings?.idp_cert ?? ''}
          />

          <div css={SIdpSettingActionRow}>
            <Button
              as={ButtonComponentType.LINK}
              variant={Variant.SECONDARY}
              onClick={onDelete}>
              {strings.buttons.delete_sso}
            </Button>
          </div>
        </>
      )}
    </div>
  )
}
