import { OperationalLogCardType, OperationalLogType } from 'types'

export interface IOperationalLogTypeCellProps {
  operationType: OperationalLogType
  cardType: OperationalLogCardType
}

const OperationalLogTypeCell = ({
  operationType,
  cardType,
}: IOperationalLogTypeCellProps): JSX.Element => {
  if (operationType == OperationalLogType.MSFT_SEND_CARD) {
    switch (cardType) {
      case OperationalLogCardType.CALL:
        return <>Send Call To Action notification</>
      case OperationalLogCardType.EVENT:
        return <>Send Event notification</>
      default:
        return <>Send welcome message</>
    }
  }

  return <>{operationType}</>
}

export default OperationalLogTypeCell
