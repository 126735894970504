import React from 'react'
import cx from 'classnames'

import InputWrapper from '../InputWrapper/InputWrapper'
import {
  IControlledInputBaseProps,
  IUncontrolledInputBaseProps,
} from '../types'

interface INumberFieldBaseProps {
  min?: number
  max?: number
}

type IControlledNumberFieldProps = INumberFieldBaseProps &
  IControlledInputBaseProps<number>

type IUncontrolledNumberFieldProps = INumberFieldBaseProps &
  IUncontrolledInputBaseProps<number>

const NumberFieldWithForwardedRef = (
  {
    name,
    onChange,
    id,
    errors = [],
    ...props
  }: IControlledNumberFieldProps | IUncontrolledNumberFieldProps,
  ref?: React.ForwardedRef<HTMLInputElement>,
): React.ReactElement => {
  const { value } = props as IControlledNumberFieldProps
  const { defaultValue } = props as IUncontrolledNumberFieldProps

  return (
    <InputWrapper errors={errors}>
      <input
        type="number"
        name={name}
        id={id || name}
        defaultValue={defaultValue || undefined}
        value={value || undefined}
        onChange={(e) => {
          const value = e.target.value
          onChange && onChange({ [name]: parseInt(value) })
        }}
        className={cx('react-input', { 'validation-error': errors?.length })}
        ref={ref}
        {...props}
      />
    </InputWrapper>
  )
}

const NumberField = React.forwardRef(NumberFieldWithForwardedRef) as (
  props: (IControlledNumberFieldProps | IUncontrolledNumberFieldProps) & {
    ref?: React.ForwardedRef<HTMLInputElement>
  },
) => ReturnType<typeof NumberFieldWithForwardedRef>

export default NumberField
