type Literal = {
  [key: string]: string | number
}

/**
 * This is a temporary solution to get en.yml translated
 * TODO: use something like https://www.i18next.com/overview/getting-started to handle en.yml
 * https://mindr.atlassian.net/browse/MC-2513
 * @param literal string from en.yml
 * @param values a list of keys and values to replace '%{value}'
 * @returns literal replaced with values
 */
export default function translateEnLiterals(
  literal: string,
  values: Literal,
): string {
  const regexp = /%\{[^}]*\}/g
  let str = literal
  let match

  while ((match = regexp.exec(str)) !== null) {
    const key = match[0].slice(2, -1)
    const value = values[key]
    str = str.replace(match[0], `${value}`)
  }

  return str
}

export function translateAllEnLiterals(
  format: string,
  replacements: Record<string, string | number>,
): string {
  let output = format

  Object.keys(replacements).forEach((key: string) => {
    const regex = new RegExp(`%\{${key}\}`, 'gi')
    output = output.replace(regex, replacements[key].toString())
  })

  return output
}
