import React, { useState } from 'react'

import SwitcherWithConfirmation from 'components/SwitcherWithConfirmation'

import Allies from './Allies'
import SubmitQuestions from './SubmitQuestions'
import RegistrationLimit from './RegistrationLimit'
import RegistrationDeadline from './RegistrationDeadline'

import { translateEnliterals } from 'utils'

import {
  TEventCallType,
  TCommunitiesType,
  AudienceNotificationScope,
} from 'types'

import './EventCallPreferences.scss'
import { FormSection } from 'components/Forms'
import { Label } from 'components/Typography'
import Container from 'components/Container'
import AudienceNotifications, {
  IAudienceNotificationsUpdateResponse,
} from './AudienceNotifications'
import { IAudiencesUrls } from 'components/Audience/AudienceContext'
import { ICsvHistoryTable } from 'components/Audience/types'

export interface IAudienceSizes {
  current: number
  defaults: {
    all: number
    subset: number
    nobody: number
  }
}

export const defaultAudienceSizes: IAudienceSizes = {
  current: 0,
  defaults: {
    all: 0,
    subset: 0,
    nobody: 0,
  },
}

interface EventCallPreferencesProps {
  initialValues: Record<string, any>
  audienceImportsTable: ICsvHistoryTable
  errors: Record<string, string[]>
  titles: Record<string, any>
  object: TEventCallType
  communityType: TCommunitiesType
  audienceSize: IAudienceSizes
  audienceNotificationsReadOnly?: boolean
  userTimezone?: string
  urls?: IAudiencesUrls
}

/**
 * Renders a list of preference controls for the event / cta
 */
export default function EventCallPreferences({
  initialValues,
  audienceImportsTable,
  errors,
  titles,
  object,
  communityType,
  audienceSize,
  audienceNotificationsReadOnly = false,
  userTimezone,
  urls = {},
}: EventCallPreferencesProps): React.ReactElement {
  const {
    id,
    allies_welcome,
    submit_questions,
    mandatory,
    deadline,
    deadline_date,
    deadline_time,
    deadline_timezone,
    registration_limit,
    registration_limit_size,
    audience,
  } = initialValues

  const [currentAudienceState, setCurrentAudienceState] = useState(
    audience?.audience_scope ?? AudienceNotificationScope.ALL_SUBSCRIBERS,
  )

  const [isAutoSub, setIsAutoSub] = useState(mandatory)

  const [audienceCount, setAudienceCount] = useState<number>(
    audienceSize.current,
  )

  return (
    <Container
      css={{ flexDirection: 'column', gap: 40, alignItems: 'flex-start' }}>
      <FormSection
        title={<Label>Audience</Label>}
        className="eventcall-preferences">
        <AudienceNotifications
          onUpdate={(value: IAudienceNotificationsUpdateResponse) => {
            setCurrentAudienceState(value.scope)
            setAudienceCount(value.count)
          }}
          audienceSize={audienceSize}
          object={object}
          objectId={id}
          readOnly={audienceNotificationsReadOnly}
          initialAudience={audience}
          importHistory={audienceImportsTable}
          urls={urls}
        />
        {object == 'event' && (
          <SwitcherWithConfirmation
            css={{ label: { whiteSpace: 'nowrap' } }}
            onUpdate={(value: boolean) => setIsAutoSub(value)}
            readOnly={
              audienceNotificationsReadOnly ||
              currentAudienceState === AudienceNotificationScope.NOBODY
            }
            key="eventcall-preferences-mandatory"
            id={`${object}-mandatory`}
            name={`${object}[mandatory]`}
            label={titles.dashboard[communityType].events.form.mandatory.label}
            defaultValue={mandatory as boolean}
            confirmationTitle={
              titles.dashboard[communityType].events.form.mandatory.confirmation
                .title
            }
            confirmationDescription={translateEnliterals(
              titles.dashboard[communityType].events.form.mandatory.confirmation
                .description,
              { new_registrants: audienceCount },
            )}
            errors={errors?.mandatory}
          />
        )}
      </FormSection>
      {/* 
      TODO: Move post survey from additional details to here
      <FormSection
        title={<Label>Communication</Label>}
        className="eventcall-preferences">
        hello
      </FormSection> */}
      {object == 'event' && (
        <FormSection
          title={<Label>Registration</Label>}
          className="eventcall-preferences">
          <RegistrationDeadline
            key="eventcall-preferences-deadline"
            hasDeadline={deadline as boolean}
            deadlineDate={deadline_date as string}
            deadlineTime={deadline_time as string}
            deadlineTimezone={deadline_timezone as string}
            userTimezone={userTimezone}
            timezones={window.timezones}
            object={object}
            label={titles.dashboard.events.form.deadline.switch}
            errors={{
              formatted_deadline_date: errors?.formatted_deadline_date || [],
              formatted_deadline_time: errors?.formatted_deadline_time || [],
              deadline_timezone: errors?.deadline_timezone || [],
            }}
            dateLabel={titles.dashboard.events.form.deadline.date}
            timeLabel={titles.dashboard.events.form.deadline.time}
          />
          <RegistrationLimit
            readOnly={isAutoSub}
            key="eventcall-preferences-limit"
            hasLimit={registration_limit as boolean}
            limitSize={registration_limit_size as number}
            object={object}
            label={titles.dashboard.events.form.registration_limit.title}
            errors={errors?.registration_limit_size}
            limitSizeLabel={
              titles.dashboard.events.form.registration_limit.label
            }
          />
        </FormSection>
      )}
      <FormSection
        title={<Label>Engagement</Label>}
        className="eventcall-preferences">
        {communityType == 'communities' && (
          <Allies
            key="eventcall-preferences-allies"
            alliesParticipate={allies_welcome as boolean}
            label={titles.common.form.allies_welcome}
            object={object}
          />
        )}
        {object == 'event' && (
          <SubmitQuestions
            key="eventcall-preferences-questions"
            allowSubmit={submit_questions as boolean}
            object={object}
            label={titles.common.form.submit_questions}
          />
        )}
      </FormSection>
    </Container>
  )
}
