import { css } from '@emotion/react'

import Widget, { WidgetProps } from './Widget'

import Icon from 'images/icons/checklist.svg'

import { colors } from 'stylesheets/theme'

interface PendingApprovalWidgetProps
  extends Omit<WidgetProps, 'number' | 'icon'> {
  numPending: number
}

const iconStyle = css({
  path: {
    fill: colors.text.text_5,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.backgrounds.gray,
  },
})

export default function PendingApprovalWidget({
  numPending,
  ...props
}: PendingApprovalWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<Icon height="18px" width="18px" css={iconStyle} />}
      number={numPending}
      css={widgetStyle}
      {...props}
    />
  )
}
