import { useContext } from 'react'
import CommunityBasicInfo from './CommunityBasicInfo'
import NavbarContext from '../NavbarContext'
import UploadAvatar from 'components/UploadAvatar'

import './BasicInfo.scss'
import { sizes } from 'stylesheets/theme'

export default function BasicInfo(): JSX.Element {
  const {
    collapsed,
    contextType,
    currentUserName,
    currentUserPronouns,
    currentUserTitle,
    logoUrl,
    paths,
  } = useContext(NavbarContext)

  if (contextType === 'community') {
    return <CommunityBasicInfo />
  } else {
    return (
      <div className="basic-info">
        <UploadAvatar
          avatar_url={logoUrl}
          className={collapsed ? 'avatar collapsed' : ''}
          size={sizes.avatarSize}
          removeDescription
          userFullName={currentUserName}
          showErrorAsFlash
        />
        <div className="profile">
          <div className="profile-inner when-not-collapsed anim-opacity">
            <a href={paths.edit_dashboard_profile_path}>
              <h3>{currentUserName}</h3>
            </a>
            {currentUserPronouns && (
              <p className="pronoun">{currentUserPronouns}</p>
            )}
            {currentUserTitle && <p>{currentUserTitle}</p>}
          </div>
        </div>
      </div>
    )
  }
}
