import Container from 'components/Container/Container'
import Button, { Variant, ButtonComponentType } from 'components/Button'
import mindrLogoImg from 'images/teams_installation/mindr_logo.png'
import { colors, spacings } from 'stylesheets/theme'
import DownloadIcon from 'images/icons/download.svg'
import { css } from '@emotion/react'

const downloadLogoWrapperStyle = css({
  marginTop: spacings.grid_gap_basis_num * 10,
  justifyContent: 'center',
  flexDirection: 'column',
  gap: spacings.grid_gap_basis_num * 4,
  path: {
    fill: colors.backgrounds.white,
  },
})

const downloadButtonStyle = css({
  // 240px so that text does not need to be wrapped to next line, and there's enough gap between icon and text
  width: 240,
  height: spacings.grid_gap_basis_num * 3.5,
})

interface DownloadMindrLogoStepProps {
  mindrLogoUrl: string
}

export default function DownloadMindrLogoStep({
  mindrLogoUrl,
}: DownloadMindrLogoStepProps): JSX.Element {
  return (
    <Container css={downloadLogoWrapperStyle}>
      <img src={mindrLogoImg}></img>
      <Button
        download="mindr_logo.png"
        href={mindrLogoUrl}
        as={ButtonComponentType.LINK}
        variant={Variant.PRIMARY}
        startIcon={<DownloadIcon />}
        css={downloadButtonStyle}>
        Download Mindr Logo
      </Button>
    </Container>
  )
}
