import { useCallback, useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import Container from 'components/Container'
import { colors, spacings } from 'stylesheets/theme'
import DragIcon from 'images/icons/drag.svg'
import { css } from '@emotion/react'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

import MobileDragAndDrag from './MobileDragAndDrop'

const reorder = (list, startIndex: number, endIndex: number) => {
  const result: any[] = [...list]
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getItemStyle = (isDragging, draggableStyle, hidden) => ({
  display: hidden ? 'none' : 'flex',
  ...draggableStyle,
})

const getListStyle = (numbered = false) => {
  if (numbered) {
    return {
      width: '100%',
      gridTemplateColumns: '40px 1fr',
      gridTemplateRows: 'min-content',
      display: 'grid',
    }
  }
  return {
    width: '100%',
  }
}

const draggableContainerStyle = css({
  userSelect: 'none',
  maxWidth: 900,
  gap: spacings.grid_gap_basis,
  padding: spacings.grid_gap_basis_num * 2,
  margin: `0 0 ${spacings.grid_gap_basis_num * 2}px 0`,
  backgroundColor: colors.backgrounds.gray,
  '&:hover': {
    '& .drag-icon': {
      backgroundColor: colors.borders.gray,
    },
  },
  '&:active': {
    '& .drag-icon': {
      backgroundColor: colors.disabled.gray,
    },
  },
})

const dragIconStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  backgroundColor: colors.backgrounds.white,
  minWidth: 33,
  minHeight: 33,
})

export interface IDragAndDropProps {
  name?: string
  items: any[]
  // These are the items that have not been ranked, which are needed for mobile to display the options
  defaultItems?: any[]
  setItems: (items: any[]) => void
  component: (index: number, item: any) => JSX.Element
  className?: string
  defaultIsMobile?: boolean
  numbered?: boolean
  draggableByIcon?: boolean
}

function draggables(items, component, name, draggableByIcon = false) {
  return items.map((item, index) => {
    const draggableId = `temp-${index}`
    return (
      <Draggable draggableId={draggableId} index={index} key={draggableId}>
        {(provided, snapshot) => {
          const iconDragHandleProps = draggableByIcon
            ? { ...provided.dragHandleProps }
            : {}
          const boxDragHandleProps = draggableByIcon
            ? {}
            : { ...provided.dragHandleProps }
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...boxDragHandleProps}
              style={getItemStyle(
                snapshot.isDragging,
                provided.draggableProps.style,
                item.hidden,
              )}
              css={draggableContainerStyle}
              className="draggable-container">
              <Container>
                <div
                  className="drag-icon"
                  css={dragIconStyle}
                  {...iconDragHandleProps}>
                  <DragIcon />
                </div>
                {component(index, item)}
                <input
                  type="hidden"
                  name={`${name || item.name}[${index}][sort_order]`}
                  value={index}
                />
              </Container>
            </div>
          )
        }}
      </Draggable>
    )
  })
}

export default function DragAndDrop({
  name,
  items,
  defaultItems,
  setItems,
  component,
  className = '',
  defaultIsMobile = false,
  numbered = false,
  draggableByIcon,
}: IDragAndDropProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(
    breakpoints.xs,
    defaultIsMobile,
  )

  const onDragEnd = useCallback(
    (result) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }

      const newItems = reorder(
        items,
        result.source.index,
        result.destination.index,
      )

      setItems(newItems)
    },
    [setItems, items],
  )

  useEffect(() => {
    /**
     * If the component is not mobile and there are no items,
     * set the items to the default items (if there are any) so that there are options in the drag and drop
     */
    if (!isMobile && items.length == 0 && defaultItems && defaultItems.length) {
      setItems(defaultItems)
    }
  }, [isMobile, defaultIsMobile, defaultItems])

  if (isMobile) {
    return (
      <MobileDragAndDrag
        defaultItems={defaultItems}
        items={items}
        setItems={setItems}
        component={(index, item) => component(index, item)}
      />
    )
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={className}
            style={getListStyle(numbered)}>
            {numbered &&
              items.map((item, index) => (
                <div
                  key={index}
                  css={{
                    gridColumn: 1,
                    gridRow: index + 1,
                    paddingBottom: spacings.grid_gap_basis_num * 2,
                    alignSelf: 'center',
                  }}>
                  <h3 key={`question-index-${index}`} css={{}}>
                    {index + 1}
                  </h3>
                </div>
              ))}
            {draggables(items, component, name, draggableByIcon)}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
