import { ITeamMember } from 'types'
import Slider from './Slider'
import LeaderCard from './LeaderCard'

interface ILeadershipTeamProps {
  leaders: ITeamMember[]
}

export default function LeadershipTeam({
  leaders,
}: ILeadershipTeamProps): JSX.Element {
  return (
    <Slider
      sectionName="Leadership team"
      caption={`(Total ${leaders.length})`}
      components={leaders.map((leader) => (
        <LeaderCard leader={leader} key={leader.id} />
      ))}
    />
  )
}
