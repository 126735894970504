import Modal, { Size, IModalProps } from 'components/Modal'
import Table, { ITableProps } from 'components/Table'

interface IActivityLogModalProps
  extends Omit<IModalProps, 'size' | 'children' | 'title'> {
  tableComponentProps: ITableProps
}

export default function ActivityLogModal({
  tableComponentProps,
  ...props
}: IActivityLogModalProps): JSX.Element {
  return (
    <Modal {...props} size={Size.small} title="Activity log">
      {tableComponentProps.tableData && tableComponentProps.tableMeta && (
        <Table {...tableComponentProps} />
      )}
    </Modal>
  )
}
