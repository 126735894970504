import { PaginatedListItem } from 'components/PaginatedList'
import { useTableContext } from 'components/Table/TableContext'
import MeetingMinuteItem from './MeetingMinuteItem'

import { TMeetingMinute } from './types'

const MeetingMinutesList = (): JSX.Element => {
  const { tableData } = useTableContext<TMeetingMinute>()

  if (!tableData.rows || tableData.rows.length === 0) {
    return null
  }

  return (
    <div>
      {tableData.rows.map((row, idx) => {
        return (
          <PaginatedListItem key={`meeting-minute-${idx}`}>
            <MeetingMinuteItem meetingMinute={row.data} />
          </PaginatedListItem>
        )
      })}
    </div>
  )
}

export default MeetingMinutesList
