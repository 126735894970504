import { colors, spacings } from 'stylesheets/theme'
import ScheduleIcon from 'images/icons/publish_schedule.svg'
import ClockIcon from 'images/icons/clock.svg'
import Container from 'components/Container'
import InfoText from 'components/InfoText'
import Banner from 'components/Banner'
import { css } from '@emotion/react'

export interface SchedulePublishInfoBannerProps {
  formattedPublishingDate: string
  formattedPublishingTime: string
  timezone: string
}

const ContainerStyle = css({
  '&:last-child': {
    gap: `calc(${spacings.grid_gap_basis} * 4)`,
  },
  '&:only-child': {
    gap: spacings.grid_gap_basis,
  },
})

export default function SchedulePublishInfoBanner({
  formattedPublishingDate,
  formattedPublishingTime,
  timezone,
}: SchedulePublishInfoBannerProps): JSX.Element {
  return (
    <Banner>
      <Container css={ContainerStyle}>
        <p>Scheduled to publish on</p>
        <InfoText icon={<ScheduleIcon />} css={ContainerStyle}>
          {formattedPublishingDate}
        </InfoText>
        <InfoText
          icon={
            <ClockIcon
              css={{
                '& path': {
                  fill: colors.blue,
                },
              }}
            />
          }>
          {formattedPublishingTime} {timezone}
        </InfoText>
      </Container>
    </Banner>
  )
}
