import { ReactNode } from 'react'
import { css } from '@emotion/react'

import { sizes, spacings } from 'stylesheets/theme'

interface HomeSectionProps {
  children: ReactNode
  className?: string
}

const homeSectionStyle = css({
  margin: '0 auto',
  padding: `0 ${spacings.grid_gap_basis_num * 5}px`,
  maxWidth: sizes.pageWidth,
})

const HomeSection = ({
  children,
  className = '',
}: HomeSectionProps): JSX.Element => {
  return (
    <div css={homeSectionStyle} className={className}>
      {children}
    </div>
  )
}

export default HomeSection
