import { Paragraph } from 'components/Typography'
import { colors, sizes, spacings } from 'stylesheets/theme'
import { FormFieldContainer, LabelVisibility } from 'components/Forms'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import CsvFileInput from 'components/CsvFileInput'
import Table from 'components/Table'
import { ITableProps } from 'components/Table'
import { useCallback, useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { TFile } from 'types'
import Modal, { Size } from 'components/Modal'

const buttonWrapCss = css({
  marginTop: spacings.grid_gap_basis_num,
})

const newRowHighlightCss = css({
  'tr.new td': {
    background: colors.green_bg_very_light,
  },
})

interface ICsvImportComponentProps extends ITableProps {
  exampleCsvUrl: string
  audienceImportTable: ITableProps
  setCsvImportFile: (data: string) => void
  updateEstimatedAudienceCount: () => void
  removeCsvImport: () => void
  uploadDetails: any
  csvImportFile: any
  selectedUsersCount: number
}

export default function CsvImportComponent({
  exampleCsvUrl,
  audienceImportTable,
  setCsvImportFile,
  updateEstimatedAudienceCount,
  removeCsvImport,
  uploadDetails,
  csvImportFile,
  selectedUsersCount,
  ...props
}: ICsvImportComponentProps): JSX.Element {
  const { tableData, tableMeta, selectedFilters } = audienceImportTable

  const [selectedFile, setSelectedFile] = useState<TFile>(null)
  useEffect(() => {
    if (!!csvImportFile) {
      try {
        const fileData = JSON.parse(csvImportFile)
        setSelectedFile({
          id: fileData?.id ?? 'file-id',
          file_name: fileData?.metadata?.filename ?? 'selected-file',
        })
      } catch (e) {
        console.error('ERROR:', e)
        setSelectedFile(null)
      }
    }
  }, [csvImportFile])

  const [summedCounts, setSummedCounts] = useState(selectedUsersCount)
  useEffect(() => {
    let current = 0
    if (tableData && tableData.rows && Array.isArray(tableData.rows)) {
      tableData.rows.forEach(
        (row: { data: { subscribers: number } }) =>
          (current += row?.data?.subscribers ?? 0),
      )
    }
    setSummedCounts(current)
  }, [tableData])

  useEffect(() => {
    setSummedCounts(selectedUsersCount)
  }, [selectedUsersCount])

  // similar to when Advanced Filters change, recalculate the audience size upon file change
  const [fileChanged, setFileChanged] = useState<number>(0)
  useEffect(() => {
    updateEstimatedAudienceCount()
  }, [fileChanged])

  const [confirmDeleteModal, setConfirmDeleteModal] =
    useState<React.ReactNode>(null)
  const onConfirmDeleteDocument = useCallback(
    (onAllow: () => void) => {
      setConfirmDeleteModal(
        <Modal
          isOpen={true}
          size={Size.small}
          largeTitle
          title="Replace this file with the updated version"
          submitButton="Confirm"
          cancelButton="Cancel"
          onRequestClose={() => {
            setConfirmDeleteModal(null)
          }}
          onSave={() => {
            onAllow()
            setConfirmDeleteModal(null)
          }}>
          <Paragraph>
            Are you sure you want to replace this file? Note that the new file
            you upload will overwrite the existing one, and this action cannot
            be undone.
          </Paragraph>
        </Modal>,
      )
    },
    [confirmDeleteModal],
  )

  const onChangeDocument = useCallback(
    (data: string | null) => {
      if (!data) {
        removeCsvImport()
      }
      setCsvImportFile(data)
      setFileChanged(fileChanged + 1)
    },
    [uploadDetails, fileChanged],
  )

  return (
    <div
      css={{
        maxHeight: '60vh',
        overflowY: 'auto',
        '.GojiCustomTable': {
          tbody: {
            tr: {
              height: sizes.tableRowMinHeight,
            },
          },
          tfoot: {
            tr: {
              height: sizes.tableRowMinHeight,
            },
          },
        },
      }}>
      <Paragraph>
        Upload a CSV of desired recipients using the sample format provided.
        Download{' '}
        <Button
          href={exampleCsvUrl}
          target="_blank"
          as={ButtonComponentType.LINK}
          variant={Variant.LINK}
          css={{ display: 'inline-block' }}>
          this example
        </Button>{' '}
        to see the required format. Users who have previously been imported will
        be consolidated into a single entry, rather than being flagged as
        duplicates.
      </Paragraph>
      <FormFieldContainer
        css={buttonWrapCss}
        label="Audience"
        labelVisibility={LabelVisibility.HIDE}
        fieldId={'audience[file]'}>
        <CsvFileInput
          name={'audience[file]'}
          error={''}
          cachedData={csvImportFile}
          initialValue={selectedFile}
          additionalComponentsWhenUnmodified={null}
          onChangeDocument={onChangeDocument}
          onConfirmDeleteDocument={onConfirmDeleteDocument}
        />
        <Paragraph
          css={{
            color: colors.text.text_3,
          }}>
          <b>{summedCounts}</b> selected users
        </Paragraph>
      </FormFieldContainer>
      <Table
        css={newRowHighlightCss}
        tableData={tableData}
        tableMeta={tableMeta}
        selectedFilters={selectedFilters}
        {...props}
      />
      {confirmDeleteModal}
    </div>
  )
}
