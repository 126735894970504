import React from 'react'
import Pill, { PillColor } from '../Pill'

interface ICohortPillProps {
  size: 'small' | 'large'
  className?: string
}

const CohortPill: React.FC<ICohortPillProps> = ({ className, size }) => {
  const cohortClassName = className ? `cohort-pill ${className}` : 'cohort-pill'
  return (
    <Pill size={size} color={PillColor.PURPLE} className={cohortClassName}>
      Cohort
    </Pill>
  )
}

export default CohortPill
