import React, { ReactNode } from 'react'
import './EmptyState.scss'

export interface EmptyStateProps {
  imgUrl: string
  title?: string
  object?: string
  subtitle?: string
  actionNode?: JSX.Element
  children?: ReactNode
}

export default function EmptyState({
  imgUrl,
  title,
  object,
  subtitle,
  actionNode,
  children,
}: EmptyStateProps): JSX.Element {
  let titleStr = null
  if (title) {
    titleStr = title
  } else if (object) {
    titleStr = `There are no ${object} for this event`
  }

  return (
    <div className="empty-state-wrapper">
      <img src={imgUrl} aria-label="empty state" />

      {titleStr && <p className="title">{titleStr}</p>}
      {!!subtitle && <p className="caption">{subtitle}</p>}
      {!!children && children}
      {!!actionNode && <div className="action">{actionNode}</div>}
    </div>
  )
}
