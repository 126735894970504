import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import axios from 'axios'

// styles
import './Communities.scss'

// components
import EmptyPlaceholder from 'components/EmptyPlaceholder'
import CommunitiesTiles, { LoadMoreButton } from 'components/CommunitiesTiles'
import Input from 'components/Input'
import { ITableData } from 'components/Table'

// interfaces
import { useEffectNotOnMount } from 'javascripts/general'

interface ICommunities {
  initialData: ITableData
  communityType: 'Cohort' | 'Community'
}

interface IFilters {
  subscriptions: boolean
  'filter[*]': string
}

const fetchData = _.debounce(async (url, params, callback) => {
  try {
    const {
      data: { data },
    } = await axios.get(url, {
      params,
    })

    callback(data)
  } catch (e) {
    window.flash('Something went wrong!', 'alert')
  }
}, 400)

const Communities: React.FC<ICommunities> = ({ initialData }) => {
  const [communitiesData, setCommunitiesData] = useState(initialData)
  const [filters, setFilters] = useState({} as IFilters)

  const onFilterChange = (obj) => {
    setFilters((currentValue) => ({ ...currentValue, ...obj }))
  }

  const loadData = useCallback(
    (nextPage = false) => {
      fetchData(
        '/communities.json',
        nextPage
          ? { ...filters, page: communitiesData.paginator.current_page + 1 }
          : filters,
        (data) => {
          setCommunitiesData((currentData) => ({
            paginator: data.paginator,
            rows: nextPage ? [...currentData.rows, ...data.rows] : data.rows,
          }))
        },
      )
    },
    [filters, communitiesData, setCommunitiesData],
  )

  const nextPage = useCallback(() => {
    loadData(true)
  }, [loadData])

  useEffectNotOnMount(() => {
    loadData()
  }, [filters])

  return (
    <div className="communities container">
      <div className="search-bar">
        <Input
          name="filter[*]"
          type="search"
          placeholder="Search by Title"
          wrapperClassName="search"
          value={filters['filter[*]']}
          onChange={onFilterChange}
        />
      </div>
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">COMMUNITIES</p>
          <p className="section-total caption">{`( Total ${communitiesData.paginator?.total_entries} )`}</p>
        </div>
      </div>
      <CommunitiesTiles communitiesData={communitiesData} />
      {!communitiesData.rows.length && filters['filter[*]'] && (
        <EmptyPlaceholder
          text="No results. Try searching for the name of another employee
          community."
        />
      )}
      {!communitiesData.rows.length &&
        !filters['filter[*]'] &&
        filters['filter[my_communities]'] && (
          <EmptyPlaceholder
            text="You are not currently subscribed to any communities."
            link={{ href: '/communities', label: 'Explore All Communities' }}
          />
        )}
      {!communitiesData.rows.length &&
        !filters['filter[*]'] &&
        !filters['filter[my_communities]'] && (
          <EmptyPlaceholder text="No communities created yet." />
        )}
      {!!communitiesData.rows.length &&
        communitiesData.paginator.current_page !=
          communitiesData.paginator.total_pages && (
          <LoadMoreButton onClick={nextPage} />
        )}
    </div>
  )
}

export default Communities
