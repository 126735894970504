import Container from 'components/Container'
import { FormFieldContainer } from 'components/Forms'
import Button, { Variant } from 'components/Button'
import {
  IFilterItem,
  FilterOption,
  IAllFilterOptions,
  defaultAllFilterOptions,
  filterOptions,
} from './types'
import FilterItem from './FilterItem'
import { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { getResultingFilterGroups } from './helper'

interface IFilterGroupProps {
  groupIndex: number
  filterGroup: IFilterItem[]
  addNewFilterItem?: () => void
  removeFilterItem?: (itemIndex: number) => void
  onFilterItemChange?: (
    itemIndex: number,
    filterType: FilterOption,
    filterInputs: any,
  ) => void
  subscribersUrl?: string
  filtersUrl?: string
  isOpen: boolean
  getCohostIds?: () => string[]
  excludeInvited?: boolean
}

export default function FilterGroup({
  groupIndex,
  filterGroup,
  addNewFilterItem = () => {},
  removeFilterItem = () => {},
  onFilterItemChange = () => {},
  subscribersUrl,
  filtersUrl,
  isOpen,
  getCohostIds = () => [],
  excludeInvited = false,
}: IFilterGroupProps): JSX.Element {
  const [allOptions, setAllOptions] = useState<IAllFilterOptions>(
    defaultAllFilterOptions,
  )

  const loadFilterOptions = useCallback(
    _.debounce((inputData, callback) => {
      const resultingFilterGroup = getResultingFilterGroups([filterGroup])
      axios
        .post(`${filtersUrl}.json`, {
          authenticity_token: window.authenticity_token,
          filters: resultingFilterGroup,
          cohost_ids: getCohostIds(),
          exclude_already_invited: excludeInvited,
        })
        .then(({ data: { data } }) => {
          if (isOpen) {
            callback && callback(data)
          }
        })
    }, 400),
    [filtersUrl, filterGroup, isOpen],
  )
  useEffect(() => {
    loadFilterOptions('', setAllOptions)
  }, [filterGroup])

  const allFilterTypes = useMemo(() => {
    const obj = {}
    filterOptions.forEach((option) => {
      obj[option] = 1
    })
    return obj
  }, [])

  const availableFilterTypes = _.cloneDeep(allFilterTypes)

  return (
    <FormFieldContainer
      key={`filter-group-${groupIndex}`}
      label="Filter group"
      fieldId={`filter-group-${groupIndex}`}>
      <Container
        css={{
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
        {filterGroup.map((filterItem, itemIndex) => {
          const { filterType, filterInputs } = filterItem
          const output = (
            <FilterItem
              isOpen={isOpen}
              key={`filter-group-${groupIndex}-item-${itemIndex}`}
              groupIndex={groupIndex}
              itemIndex={itemIndex}
              groupFilterItemsCount={filterGroup.length}
              filterType={filterType ?? null}
              filterInputs={filterInputs ?? null}
              removeFilterItem={removeFilterItem}
              onFilterItemChange={(filterType, filterInputs) =>
                onFilterItemChange(itemIndex, filterType, filterInputs)
              }
              subscribersUrl={subscribersUrl}
              filtersUrl={filtersUrl}
              allOptions={allOptions}
              availableOptions={Object.keys(availableFilterTypes)}
            />
          )

          delete availableFilterTypes[filterType]

          return output
        })}
      </Container>
      <Button variant={Variant.LINK} onClick={addNewFilterItem} type="Button">
        + Add filter item
      </Button>
    </FormFieldContainer>
  )
}
