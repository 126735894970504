import { css } from '@emotion/react'
import axios from 'axios'
import Input from 'components/Input'
import Modal, { Size, IModalProps } from 'components/Modal'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { spacings } from 'stylesheets/theme'
import { IUser } from 'types'

const modalStyle = css({
  '.mindr-modal-body': {
    marginTop: Math.round(spacings.grid_gap_basis_num / 2),
  },
})

const helperCss = css({
  marginBottom: spacings.grid_gap_basis_num + 2,
})

interface IInviteUsersModalProps
  extends Omit<IModalProps, 'size' | 'children' | 'title' | 'onSave'> {
  title?: string
  size?: Size
  children?: React.ReactNode
  searchHelperText?: string
  searchPlaceholderText?: string
  onSave?: (users: IUser[]) => void
}

export default function InviteUsersModal({
  title = 'Find users to invite',
  size = Size.small,
  children = null,
  searchHelperText = 'Invite individuals to apply to this program',
  searchPlaceholderText = 'Start typing their name or email',
  onSave,
  ...props
}: IInviteUsersModalProps): JSX.Element {
  const [initialUsers, setInitialUsers] = useState<IUser[]>([])
  const [selectedUsers, setSelectedUsers] = useState<IUser[]>([])

  const loadOptions = _.debounce((inputValue, callback) => {
    axios
      .get('/users.json', {
        params: {
          'filter[*]': inputValue,
          per_page: !!inputValue ? undefined : 3,
          active_only: true,
        },
      })
      .then(({ data: { data } }) => {
        callback(data.rows.map((row) => row.data))
      })
  }, 400)

  useEffect(() => {
    loadOptions('', setInitialUsers)
  }, [])

  const handleOnChange = (inputData: Record<string, unknown>) => {
    try {
      const toInvite = inputData['to_invite'] as IUser[]
      if (toInvite && Array.isArray(toInvite)) {
        setSelectedUsers(toInvite)
      }
    } catch (e) {
      console.log('AJAX ERROR: User lookup failed. Unknown user:', e, inputData)
    }
  }

  const onSaveModal = () => {
    onSave && onSave(selectedUsers)
    setSelectedUsers([])
  }

  return (
    <Modal
      {...props}
      largeTitle
      size={size}
      title={title}
      css={modalStyle}
      submitButton="Send invitations"
      cancelButton="No invitations"
      onSave={onSaveModal}>
      {children}
      <p css={helperCss}>{searchHelperText}</p>
      <Input
        name="to_invite"
        type="async_select"
        isMulti={true}
        selectValue={selectedUsers}
        getOptionLabel={(option) => option.full_name}
        getOptionValue={(option) => option.id}
        loadOptions={loadOptions}
        defaultOptions={initialUsers}
        onChange={handleOnChange}
        placeholder={searchPlaceholderText}
      />
    </Modal>
  )
}
