import React from 'react'
import cx from 'classnames'
import './Typography.scss'

interface SectionTitleProps {
  children: JSX.Element | string
  className?: string
}
export default function SectionTitle({
  children,
  className,
}: SectionTitleProps): React.ReactElement {
  return <h4 className={cx('section-title', className)}>{children}</h4>
}
