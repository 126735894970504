import { enableES5 } from 'immer'

enableES5()
// assets
import DownloadIcon from 'images/icons/download.svg'

//styles
import './Reports.scss'

// components
import Table, { IMainTableFilters, ITableMeta, ITableData } from '../Table'
import Input from 'components/Input'
import { TReportType } from 'types'

interface IFilters extends IMainTableFilters {
  type: string
  community_type: 'Cohort' | 'Community' | null
  'filter[active_only]': string | undefined
  'filter[include_without_surveys]': boolean | undefined
  page?: number
}

interface IReportTableProps {
  selectedReportType: TReportType
  tableMeta: ITableMeta
  tableData: ITableData
  loadData: (filters: Partial<IFilters>, page?: number) => void
  changeFilters: (filters: Partial<IFilters>) => void
  selectedFilters: IFilters
  exportLink?: string
}

export default function ReportTable({
  selectedReportType,
  tableMeta: meta,
  tableData: data,
  loadData,
  changeFilters,
  selectedFilters,
  exportLink,
}: IReportTableProps): JSX.Element {
  return (
    <>
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">{selectedReportType?.label}</p>
          {meta.exportable && (
            <>
              <a href={exportLink} className="link">
                <DownloadIcon />
                Export Table
              </a>
              {selectedReportType?.exportAllAvailable && (
                <a href={`${exportLink}&export_all=true`} className="link">
                  <DownloadIcon />
                  Export All Survey Data
                </a>
              )}
            </>
          )}
        </div>
        <div className="header-right">
          {meta.togglable_filter === 'active_only' && (
            <div className="input-component-wrapper active-only togglable-filter">
              <div className="switch-wrapper">
                <p className="off">All Users</p>
                <Input
                  type="switch"
                  name="filter[active_only]"
                  wrapperClassName="switch-component-wrapper"
                  checked={!!selectedFilters['filter[active_only]']}
                  onChange={(inputData) => {
                    changeFilters({
                      type: selectedFilters.type,
                      ...inputData,
                    } as IFilters)
                  }}
                />
                <p className="on">Only Active Users</p>
              </div>
            </div>
          )}
          {meta.togglable_filter === 'survey_only' && (
            <div className="input-component-wrapper surveys-only togglable-filter">
              <div className="switch-wrapper">
                <p className="off">All Events</p>
                <Input
                  type="switch"
                  name="surveys_only"
                  wrapperClassName="switch-component-wrapper"
                  checked={!selectedFilters['filter[include_without_surveys]']}
                  onChange={(inputData) => {
                    changeFilters({
                      type: selectedFilters.type,
                      'filter[include_without_surveys]':
                        !inputData['surveys_only'],
                    } as IFilters)
                  }}
                />
                <p className="on">Only With Surveys</p>
              </div>
            </div>
          )}
          {meta.searchable && (
            <Input
              name="filter[*]"
              type="search"
              wrapperClassName="search"
              onChange={(searchField) =>
                changeFilters({
                  type: selectedFilters.type,
                  ...searchField,
                } as IFilters)
              }
              value={selectedFilters['filter[*]']}
              placeholder={
                selectedFilters.type === 'events' ||
                selectedFilters.type === 'calls'
                  ? 'Search Titles'
                  : 'Search Report'
              }
            />
          )}
        </div>
      </div>
      {selectedReportType?.subTitle && (
        <p className="subtitle">{selectedReportType?.subTitle}</p>
      )}
      <Table
        tableData={data}
        tableMeta={meta}
        onPageChange={(page: number) => {
          loadData(selectedFilters, page)
        }}
        onOrderChange={changeFilters}
        selectedFilters={selectedFilters}
      />
      {['events', 'calls', 'events_cohorts', 'community_past_events'].includes(
        selectedFilters.type,
      ) && (
        <p className="comment">
          * Satisfaction rates are from 2 (very satisfied) to -2 (very
          dissatisfied)
        </p>
      )}
    </>
  )
}
