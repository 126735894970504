import HeaderNavContainer from './HeaderNavContainer'
import OrganizationSwitcher from './OrganizationSwitcher'
import Logo from './Logo'
import { OrganizationSwitch } from './types'
import ProvidedByLogo from './ProvidedByLogo'
import ButtonsContainer from './ButtonsContainer'
import { spacings, Theme } from 'stylesheets/theme'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { css } from '@emotion/react'

export interface OrganizationHeaderNavWrapperProps {
  organization: OrganizationSwitch
  rootPath: string
  backgroundColor?: string
  theme?: Theme
  otherOrgs?: OrganizationSwitch[]
  children: React.ReactNode
}

const rightBoxStyle = css({
  flexGrow: 0,
  flexShrink: 0,
  width: 'calc((100% - 220px) / 2)',
  display: 'flex',
  justifyContent: 'end',
  boxSizing: 'border-box',
  paddingLeft: spacings.grid_gap_basis_num,
})

const leftBoxStyle = css({
  flexGrow: 0,
  flexShrink: 0,
  width: 'calc((100% - 220px) / 2)',
  display: 'flex',
  justifyContent: 'start',
  boxSizing: 'border-box',
  paddingRight: spacings.grid_gap_basis_num,
  h3: {
    maxHeight: 38,
    overflow: 'hidden',
  },
})

const middleBoxStyle = css({
  flexGrow: 0,
  flexShrink: 0,
  width: 220,
  display: 'flex',
  justifyContent: 'center',
})

const logoStyle = css({
  position: 'static',
  transform: 'none',
})

export default function OrganizationHeaderNavWrapper({
  organization,
  rootPath,
  backgroundColor = 'FFFFFF',
  theme,
  otherOrgs = [],
  children,
}: OrganizationHeaderNavWrapperProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  return (
    <HeaderNavContainer
      theme={theme}
      css={{ backgroundColor: `#${backgroundColor}` }}>
      <div css={leftBoxStyle}>
        <div css={{ display: 'flex' }}>
          <Logo
            organization={organization}
            path={rootPath}
            isMobile={isMobile}
          />
          {!!otherOrgs.length && (
            <OrganizationSwitcher organizations={otherOrgs} />
          )}
        </div>
      </div>
      <div css={middleBoxStyle}>
        <ProvidedByLogo
          theme={theme}
          color={backgroundColor}
          isMobile={isMobile}
          css={logoStyle}
        />
      </div>
      <div css={rightBoxStyle}>
        <ButtonsContainer>{children}</ButtonsContainer>
      </div>
    </HeaderNavContainer>
  )
}
