import {
  SetStateAction,
  createContext,
  useCallback,
  useState,
  Dispatch,
  useMemo,
  useEffect,
} from 'react'
import { IFilterItem, filterOptions, ICsvHistoryTable } from './types'
import { Audience, TEventCallType } from 'types'
import _ from 'lodash'
import axios from 'axios'
import { getResultingFilterGroups } from './helper'

export interface IAudiencesUrls {
  subscribersUrl?: string
  filtersUrl?: string
  estimateAudienceUrl?: string
  exampleCsvUrl?: string
  createAudienceCsvUrl?: string
  deleteAudienceImportUrl?: string
  addAdditionalAudienceUrl?: string
}

export interface IAudienceCount {
  audienceCount: {
    matched: number
    not_matched: number
  }
}

export interface IAudienceCountsByType {
  filters: IAudienceCount
  csvFile: IAudienceCount
}

const defaultAudienceCountByType: IAudienceCountsByType = {
  filters: {
    audienceCount: {
      matched: 0,
      not_matched: 0,
    },
  },
  csvFile: {
    audienceCount: {
      matched: 0,
      not_matched: 0,
    },
  },
}

const defaultAudienceImportTable: ICsvHistoryTable = {
  tableData: {
    rows: [],
    paginator: {
      current_page: 0,
      total_pages: 0,
      total_entries: 0,
      per_page: 6,
    },
  },
  tableMeta: {
    url: '',
    columns: [
      {
        label: 'File Name',
        field: 'file_name',
        type: 'string',
      },
      {
        label: 'Date uploaded',
        field: 'date_uploaded',
        type: 'date_utc',
      },
      {
        label: 'Users',
        field: 'subscribers',
        type: 'number',
      },
      {
        label: 'Errors',
        field: 'errors',
        type: 'number',
      },
    ],
  },
  exampleCsvUrl: '',
}

interface IUpdateCsvHistoryTableResponse extends ICsvHistoryTable {
  audienceId: number
  importId: number
  deleteImportUrl: string
}

interface IEstimateCountsRequest {
  is_csv_import: boolean
  authenticity_token: string
  audience_id: number
  csv_import_file?: string
  csv_import_id?: number
  filters?: any[]
  cohost_ids?: string[]
  additional_users?: boolean
}

interface IEstimateCountsResponse {
  data: {
    data: IAudienceCount
  }
}

export interface ISavedValues {
  isCsvImport?: boolean
  csvImportFile?: string
  filterGroups?: IFilterItem[][]
}

interface IUploadDetails {
  id: number
  deleteUrl: string
}

interface IChangedValues {
  filters?: boolean
  csvFile?: boolean
  audienceMethod?: boolean
}

export interface ShouldConfirmClose {
  value: boolean
  reasons: IChangedValues
}

const defaultShouldConfirmClose: ShouldConfirmClose = {
  value: false,
  reasons: {
    filters: false,
    csvFile: false,
    audienceMethod: false,
  },
}

const defaultUploadDetails: IUploadDetails = {
  id: 0,
  deleteUrl: '',
}

interface AudienceContextValues {
  object: TEventCallType | 'leadr'
  addNewFilterItem: (filterGroupIndex: number) => void
  removeFilterItem: (filterGroupIndex: number, itemIndex: number) => void
  addFilterGroup: () => void
  removeFilterGroup: (groupIndex: any) => void
  onFilterItemChange: (
    groupIndex: any,
    itemIndex: any,
    filterType: any,
    filterInputs: any,
  ) => void
  isCsvImport: boolean
  setCsvImport: Dispatch<SetStateAction<boolean>>
  csvImportFile: string | null
  uploadDetails: IUploadDetails
  setCsvImportFile: Dispatch<SetStateAction<string | null>>
  filterGroups: IFilterItem[][]
  resultingFilterGroups: IFilterItem[][]
  audienceImportTable: ICsvHistoryTable
  audienceCount: IAudienceCountsByType
  setAdditionalAudienceMandatory: (value: boolean) => void
  onSaveAudience?: () => ISavedValues
  resetFilterGroups: () => void
  resetCsvImport: () => void
  updateEstimatedAudienceCount: () => void
  initialAudience?: Audience
  isOpen: boolean
  isAdditionalUsers: boolean
  removeCsvImport: (callback?: () => void) => void
  shouldConfirmClose: () => ShouldConfirmClose
  hasSavedAudience: (currentSavedValues?: ISavedValues) => boolean
  getCohostIds: () => string[]
}

export const AudienceContext = createContext<AudienceContextValues>({
  object: 'event',
  addNewFilterItem: () => {},
  removeFilterItem: () => {},
  addFilterGroup: () => {},
  removeFilterGroup: () => {},
  onFilterItemChange: () => {},
  isCsvImport: false,
  setCsvImport: () => {},
  csvImportFile: null,
  uploadDetails: defaultUploadDetails,
  setCsvImportFile: () => {},
  filterGroups: [],
  resultingFilterGroups: [],
  audienceImportTable: defaultAudienceImportTable,
  audienceCount: defaultAudienceCountByType,
  setAdditionalAudienceMandatory: () => {},
  onSaveAudience: () => null,
  resetFilterGroups: () => {},
  resetCsvImport: () => {},
  updateEstimatedAudienceCount: () => {},
  isOpen: false,
  isAdditionalUsers: false,
  removeCsvImport: () => {},
  shouldConfirmClose: () => defaultShouldConfirmClose,
  hasSavedAudience: () => false,
  getCohostIds: () => {
    return []
  },
})

interface IAudienceContextProviderProps {
  isOpen: boolean
  object: TEventCallType | 'leadr'
  objectId: number
  initialAudience?: Audience
  importHistory?: ICsvHistoryTable
  children: JSX.Element
  urls: IAudiencesUrls
  isAdditionalUsers?: boolean
  cohostIds?: number[]
}

export const initialFilterGroups: IFilterItem[][] = [
  [
    {
      filterType: null,
      filterInputs: [],
    },
  ],
]

const addElement = ({
  id,
  value,
  name,
  parent,
  type = 'hidden',
  elementType = 'input',
}: {
  id: string
  value: string
  name: string
  parent: HTMLElement
  type?: string
  elementType?: string
}) => {
  const element = document.createElement(elementType ?? 'input')
  const tempId = `audience_element_${Math.random() * 100000}`
  element.setAttribute('type', type ?? 'hidden')
  element.setAttribute('id', id ?? tempId)
  element.setAttribute('value', value ?? '')
  element.setAttribute('name', name ?? tempId)
  parent.appendChild(element)
  return element
}

export default function AudienceContextProvider({
  isOpen,
  object,
  objectId,
  initialAudience,
  importHistory,
  children,
  urls = {},
  isAdditionalUsers = false,
  cohostIds,
}: IAudienceContextProviderProps): JSX.Element {
  // The core "audience" object details
  const [audience, setAudience] = useState<Audience>(initialAudience)

  // State of the switcher, determining "audience mode"
  const [isCsvImport, setCsvImport] = useState(
    initialAudience?.is_csv_import || false,
  )

  // File data for the csv file uploaded this modal session
  const [csvImportFile, setCsvImportFile] = useState<string | null>(
    initialAudience?.csv_import_file || null,
  )

  // When a new file is uploaded, store the details so we can reference it for removal if needed.
  const [uploadDetails, setUploadDetails] = useState<IUploadDetails>(
    initialAudience?.curr_csv_file_upload_details
      ? JSON.parse(initialAudience?.curr_csv_file_upload_details)
      : defaultUploadDetails,
  )

  // The current state of the Advanced Filters grouping and values
  const [filterGroups, setFilterGroups] = useState<IFilterItem[][]>(
    initialAudience?.audience_filters
      ? JSON.parse(initialAudience.audience_filters)
      : initialFilterGroups,
  )

  // The estimated audience counts, broken down by "audience mode"
  const [audienceCnt, setAudienceCnt] = useState<IAudienceCountsByType>(
    defaultAudienceCountByType,
  )

  const [additionalAudienceMandatory, setAdditionalAudienceMandatory] =
    useState(false)

  const [savedValues, setSavedValues] = useState<ISavedValues>(null)

  /**
   * Return true if there is actually saved audience, audience will remain at "Only a subset of audience"
   * if return false, that means we want to revert audience scope back to "all subscribers".
   * If csv is selected when "Save" is clicked, then check if there is any uploaded csv import file from this session,
   *  if there is, then savedValues.csvImportFile should not be empty, OR uploadDetails.id should not be empty.
   *  Otherwise, check if previously uploaded CSVs have added any users to audience by checking audienceCnt.csvFile.audienceCount.matched.
   *  This number should be updated at each estimateAudienceCount.
   */
  const hasSavedAudience = useCallback(
    (currentSavedValues?: ISavedValues) => {
      const thisSavedValues = currentSavedValues || savedValues

      if (thisSavedValues) {
        if (
          thisSavedValues?.isCsvImport &&
          (thisSavedValues?.csvImportFile ||
            uploadDetails?.id ||
            audienceCnt?.csvFile?.audienceCount?.matched > 0)
        ) {
          return true
        }

        if (thisSavedValues?.filterGroups) {
          const resulting = getResultingFilterGroups(
            thisSavedValues?.filterGroups,
          )
          if (resulting?.length > 0) {
            return true
          }
        }

        return false
      }

      if (
        initialAudience?.is_csv_import &&
        (initialAudience?.csv_import_file || uploadDetails.id)
      ) {
        return false
      }

      if (initialAudience?.audience_filters) {
        const resulting = getResultingFilterGroups(
          JSON.parse(initialAudience?.audience_filters),
        )
        if (resulting.length > 0) {
          return true
        }
      }

      return false
    },
    [initialAudience, uploadDetails, audienceCnt],
  )

  useEffect(() => {
    if (!isAdditionalUsers) {
      onSaveAudience()
    }
  }, [isAdditionalUsers])

  useEffect(() => {
    if (!savedValues) {
      const filters = initialAudience?.audience_filters
      const useFilters =
        typeof filters === 'string' ? JSON.parse(filters) : filters
      setSavedValues({
        isCsvImport: initialAudience?.is_csv_import ?? false,
        filterGroups: _.isEmpty(useFilters)
          ? _.cloneDeep(initialFilterGroups)
          : useFilters,
        csvImportFile: initialAudience?.csv_import_file,
      })
    } else {
      setCsvImport(savedValues.isCsvImport ?? false)
      setCsvImportFile(savedValues.csvImportFile ?? '')
      setFilterGroups(
        savedValues.filterGroups ?? _.cloneDeep(initialFilterGroups),
      )
    }
  }, [isOpen])

  const resultingFilterGroups = useMemo(() => {
    const out = getResultingFilterGroups(filterGroups)
    return out.length ? out : _.cloneDeep(initialFilterGroups)
  }, [filterGroups, initialFilterGroups])

  const areFiltersEmpty = useCallback(
    () => _.isEqual(filterGroups, initialFilterGroups),
    [filterGroups, resultingFilterGroups],
  )

  useEffect(() => {
    const arr = savedValues?.filterGroups
    if (arr) {
      setFilterGroups(
        Array.isArray(arr) && arr.length
          ? arr
          : _.cloneDeep(initialFilterGroups),
      )
      updateEstimatedAudienceCount()
    }
  }, [savedValues, initialFilterGroups])

  const [audienceImportTable, setAudienceImportTable] =
    useState<ICsvHistoryTable>({
      tableData:
        importHistory?.tableData ?? defaultAudienceImportTable.tableData,
      tableMeta:
        importHistory?.tableMeta ?? defaultAudienceImportTable.tableMeta,
      selectedFilters: importHistory?.selectedFilters ?? {},
      exampleCsvUrl: urls.exampleCsvUrl,
    })

  const addNewFilterItem = useCallback(
    (filterGroupIndex) => {
      let filterGroup = filterGroups[filterGroupIndex]
      filterGroup = [...filterGroup, { filterType: null, filterInputs: [] }]
      const newFilterGroups = [...filterGroups]
      newFilterGroups[filterGroupIndex] = filterGroup
      setFilterGroups(newFilterGroups)
    },
    [filterGroups, setFilterGroups],
  )

  const removeFilterItem = useCallback(
    (filterGroupIndex, itemIndex) => {
      const newFilterGroup = [...filterGroups[filterGroupIndex]]
      if (newFilterGroup.length > 1) {
        // If there are 2 or more items, remove the selected one.
        newFilterGroup.splice(itemIndex, 1)
      } else {
        // If there are 0 or 1 items, reset the first item to the default empty settings.
        newFilterGroup[0] = _.cloneDeep(initialFilterGroups[0][0])
      }
      const newFilterGroups = [...filterGroups]
      newFilterGroups[filterGroupIndex] = newFilterGroup
      setFilterGroups(newFilterGroups)
    },
    [filterGroups, setFilterGroups],
  )

  const addFilterGroup = useCallback(() => {
    setFilterGroups([...filterGroups, [{ filterType: null, filterInputs: [] }]])
  }, [filterGroups, setFilterGroups])

  const removeFilterGroup = useCallback(
    (groupIndex) => {
      const newFilterGroups = [...filterGroups]
      if (newFilterGroups.length > 1) {
        // If there are 2 or more groups, remove the selected group.
        newFilterGroups.splice(groupIndex, 1)
      } else {
        // If there are 0 or 1 groups, reset the first group to the default group.
        newFilterGroups[0] = _.cloneDeep(initialFilterGroups[0])
      }
      setFilterGroups(newFilterGroups)
    },
    [filterGroups, setFilterGroups],
  )

  const onFilterItemChange = useCallback(
    (groupIndex, itemIndex, filterType, filterInputs) => {
      const newFilterGroup = [...filterGroups[groupIndex]]
      newFilterGroup[itemIndex] = { filterType, filterInputs }
      const newFilterGroups = [...filterGroups]
      newFilterGroups[groupIndex] = newFilterGroup
      setFilterGroups(newFilterGroups)
    },
    [filterGroups, setFilterGroups],
  )

  const shouldConfirmClose = useCallback((): ShouldConfirmClose => {
    const result = _.cloneDeep(defaultShouldConfirmClose)

    const filters = initialAudience?.audience_filters ?? initialFilterGroups
    const useFilters: IFilterItem[][] =
      typeof filters === 'string' ? JSON.parse(filters) : filters
    if (
      !_.isEmpty(resultingFilterGroups) &&
      !_.isEqual(useFilters, resultingFilterGroups)
    ) {
      result.value = true
      result.reasons.filters = true
    }

    if (
      !_.isEmpty(csvImportFile) &&
      !_.isEqual(initialAudience?.csv_import_file ?? null, csvImportFile)
    ) {
      result.value = true
      result.reasons.csvFile = true
    }

    return result
  }, [
    initialAudience,
    initialFilterGroups,
    resultingFilterGroups,
    csvImportFile,
    isCsvImport,
  ])

  const onSaveAudience = useCallback(() => {
    let currentSavedValues = savedValues
    const objectForms = window.document.getElementsByClassName('mindr-form')
    let objectForm = !!objectForms?.length
      ? (objectForms[0] as HTMLFormElement)
      : undefined

    if (isAdditionalUsers) {
      objectForm = window.document.getElementById(
        'audience-form',
      ) as HTMLFormElement
    }

    const findAndRemove = [
      '#audience_filters_input',
      '#audience_csv_file_input',
      '#audience_is_csv_import',
      '#audience_id',
    ]
    document
      .querySelectorAll(findAndRemove.join(','))
      .forEach((e) => e.remove())

    let newValues: ISavedValues = {
      isCsvImport: null,
      csvImportFile: null,
      filterGroups: null,
    }

    if (savedValues) {
      newValues = _.cloneDeep(savedValues)
    }

    if (isCsvImport) {
      const filters =
        initialAudience?.audience_filters ?? _.cloneDeep(initialFilterGroups)
      newValues.filterGroups =
        typeof filters === 'string' ? JSON.parse(filters) : filters
      newValues.csvImportFile = csvImportFile
    } else {
      newValues.filterGroups = filterGroups
      newValues.csvImportFile = null
      // remove current uploaded csv file
      if (savedValues) {
        resetCsvImport()
      }
    }

    newValues.isCsvImport = isCsvImport
    currentSavedValues = newValues
    setSavedValues(newValues)

    if (audience?.id) {
      // Always save the audience_id, in case we had to create one via ajax request.
      addElement({
        id: 'audience_id',
        value: `${audience.id}`,
        name: `${object}[audience_id]`,
        parent: objectForm,
        type: 'hidden',
        elementType: 'input',
      })
    }

    addElement({
      id: 'audience_is_csv_import',
      value: isCsvImport ? '1' : '0',
      name: `${object}[audience_is_csv_import]`,
      parent: objectForm,
      type: 'hidden',
      elementType: 'input',
    })

    if (!isCsvImport) {
      // When we are using Advanced Filters, only send those when saving the event.
      addElement({
        id: 'audience_filters_input',
        value: JSON.stringify(resultingFilterGroups),
        name: `${object}[audience_filters]`,
        parent: objectForm,
        type: 'hidden',
        elementType: 'input',
      })
    } else {
      // When we are using CSV Upload, only send the csv file information when saving the event.
      addElement({
        id: 'audience_csv_file_input',
        value: csvImportFile,
        name: `${object}[audience_csv_file]`,
        parent: objectForm,
        type: 'hidden',
        elementType: 'input',
      })

      addElement({
        id: 'audience_csv_file_upload_details_input',
        value: JSON.stringify(uploadDetails),
        name: `${object}[audience_csv_file_upload_details]`,
        parent: objectForm,
        type: 'hidden',
        elementType: 'input',
      })
    }

    if (isAdditionalUsers) {
      addElement({
        id: 'authenticity_token',
        value: window.authenticity_token,
        name: 'authenticity_token',
        parent: objectForm,
        type: 'hidden',
        elementType: 'input',
      })

      addElement({
        id: 'audience_additional_audience_mandatory',
        value: additionalAudienceMandatory.toString(),
        name: `${object}[additional_audience_mandatory]`,
        parent: objectForm,
        type: 'hidden',
        elementType: 'input',
      })

      const formData = new FormData(objectForm)

      axios.post(`${urls.addAdditionalAudienceUrl}`, formData).then(() => {
        window.location.reload()
      })
    }
    return currentSavedValues
  }, [
    resultingFilterGroups,
    csvImportFile,
    audience,
    isCsvImport,
    uploadDetails,
    savedValues,
    isAdditionalUsers,
    additionalAudienceMandatory,
  ])

  const updateEstimatedAudienceCount = useCallback(
    _.debounce(() => {
      const usingCsv = isCsvImport
      const params: IEstimateCountsRequest = {
        is_csv_import: isCsvImport,
        authenticity_token: window.authenticity_token,
        audience_id: audience?.id ?? 0,
        cohost_ids: getCohostIds(),
        additional_users: isAdditionalUsers,
      }
      if (usingCsv) {
        if (!!uploadDetails.id) {
          params.csv_import_id = uploadDetails.id
        } else {
          params.csv_import_file = csvImportFile
        }
      } else {
        // Only recalc if the filters have value.
        if (areFiltersEmpty()) {
          const counts = _.cloneDeep(audienceCnt)
          counts.filters = defaultAudienceCountByType.filters
          setAudienceCnt(counts)
          return
        }
        params.filters = resultingFilterGroups
      }

      axios
        .post(`${urls.estimateAudienceUrl}`, params)
        .then(({ data: { data } }: IEstimateCountsResponse) => {
          const counts = _.cloneDeep(audienceCnt)
          if (usingCsv) {
            counts.csvFile = data
          } else {
            counts.filters = data
          }
          setAudienceCnt(counts)
        })
    }, 400),
    [
      resultingFilterGroups,
      csvImportFile,
      isCsvImport,
      uploadDetails,
      isAdditionalUsers,
    ],
  )
  useEffect(() => {
    updateEstimatedAudienceCount()
  }, [
    filterOptions,
    filterGroups,
    resultingFilterGroups,
    csvImportFile,
    isCsvImport,
    uploadDetails,
  ])

  const updateAudienceImportTableList = useCallback(
    _.debounce(() => {
      if (!isCsvImport) {
        return
      }
      if (!csvImportFile) {
        return
      }

      let csv_import_id = null
      const csv_import_file = csvImportFile

      if (!!uploadDetails.id) {
        csv_import_id = uploadDetails.id
      }

      axios
        .post(`${urls.createAudienceCsvUrl}`, {
          authenticity_token: window.authenticity_token,
          csv_import_file,
          audience_id: audience?.id || 0,
          object_type: object,
          object_id: objectId,
          cohost_ids: getCohostIds(),
          additional_users: isAdditionalUsers,
          csv_import_id,
        })
        .then(
          ({
            data: { data },
          }: {
            data: { data: IUpdateCsvHistoryTableResponse }
          }) => {
            const newTable = { ...audienceImportTable }
            newTable.tableData = data?.tableData ?? newTable.tableData
            newTable.tableMeta = data?.tableMeta ?? newTable.tableMeta
            setAudienceImportTable(newTable)

            if (data?.audienceId) {
              const newAudience = { ...audience }
              newAudience.id = data.audienceId
              setAudience(newAudience)
            }

            if (!_.isNil(data?.importId)) {
              const newDetails = _.cloneDeep(defaultUploadDetails)
              newDetails.id = data?.importId || 0

              if (!_.isNil(data?.deleteImportUrl)) {
                newDetails.deleteUrl =
                  data?.deleteImportUrl || newDetails.deleteUrl
              }

              setUploadDetails(newDetails)
            }
          },
        )
    }, 400),
    [csvImportFile, isCsvImport, uploadDetails],
  )
  useEffect(() => {
    updateAudienceImportTableList()
  }, [csvImportFile, isCsvImport])

  const removeCsvImport = useCallback(
    (callback) => {
      try {
        if (!uploadDetails.deleteUrl) {
          return
        }

        axios
          .delete(`${uploadDetails.deleteUrl}`, {
            params: {
              authenticity_token: window.authenticity_token,
              audience_id: audience?.id || 0,
              additional_users: isAdditionalUsers,
              object_type: object,
              object_id: objectId || 0,
            },
          })
          .then(
            ({
              data: { data },
            }: {
              data: { data: IUpdateCsvHistoryTableResponse }
            }) => {
              const newTable = { ...audienceImportTable }
              newTable.tableData = data.tableData
              newTable.tableMeta = data.tableMeta
              setAudienceImportTable(newTable)

              if (data?.audienceId == 0) {
                const newAudience = { ...audience }
                newAudience.id = data?.audienceId
                setAudience(newAudience)
              }

              setUploadDetails(_.cloneDeep(defaultUploadDetails))
              setCsvImportFile(null)
            },
          )
      } catch (e) {
        console.error('ERROR:', e)
      } finally {
        callback && callback()
      }
    },
    [
      uploadDetails,
      audience,
      audienceImportTable,
      updateAudienceImportTableList,
      isCsvImport,
      setAudienceImportTable,
      setUploadDetails,
      setAudience,
    ],
  )

  const resetFilterGroups = useCallback(() => {
    const filters = savedValues.filterGroups
    // Only use the saved filters list if it is an array that has some entries.
    // Empty array will cause an empty screen.
    if (filters && Array.isArray(filters) && filters.length > 0) {
      setFilterGroups(filters)
    } else {
      setFilterGroups(_.cloneDeep(initialFilterGroups))
    }
  }, [audience, savedValues])

  const resetCsvImport = useCallback(() => {
    removeCsvImport(() => {
      setCsvImport(savedValues.isCsvImport)
      setCsvImportFile(savedValues.csvImportFile)
    })
  }, [audience, isCsvImport, csvImportFile, uploadDetails])

  const getCohostIds = useCallback((): string[] => {
    if (Array.isArray(cohostIds) && cohostIds.length) {
      return cohostIds.map((c) => c.toString())
    }

    const list: string[] = []

    document
      .querySelectorAll(
        '[name^="event[cohosts_attributes]"][name$="[community_id]"]',
      )
      .forEach((element: HTMLInputElement) => {
        const value = element.value
        if (value.length) {
          list.push(value)
        }
      })

    return list
  }, [cohostIds])

  return (
    <AudienceContext.Provider
      value={{
        object,
        addNewFilterItem,
        removeFilterItem,
        addFilterGroup,
        removeFilterGroup,
        onFilterItemChange,
        isCsvImport,
        setCsvImport,
        csvImportFile,
        uploadDetails,
        setCsvImportFile,
        filterGroups,
        audienceImportTable: audienceImportTable,
        audienceCount: audienceCnt,
        onSaveAudience,
        setAdditionalAudienceMandatory,
        resultingFilterGroups,
        resetFilterGroups,
        resetCsvImport,
        updateEstimatedAudienceCount,
        initialAudience,
        isOpen,
        isAdditionalUsers,
        removeCsvImport,
        shouldConfirmClose,
        hasSavedAudience,
        getCohostIds,
      }}>
      {children}
    </AudienceContext.Provider>
  )
}
