import { Paragraph } from 'components/Typography'
import { ILeadrArchetypeModalProps } from './LeadrArchetypeModal.types'
import Modal from 'components/Modal'
import { SModalHeading, SModal, SParagraph } from './LeadrArchetypeModal.styles'

export default function LeadrArchetypeModal({
  strings,
  archetype,
  ...props
}: ILeadrArchetypeModalProps): JSX.Element {
  return (
    <Modal
      css={SModal}
      title={strings.modals.archetype.title}
      cancelButton={strings.modals.archetype.cancel_button}
      {...props}>
      <h2 css={SModalHeading}>{archetype?.name ?? ''}</h2>
      <Paragraph css={SParagraph}>{archetype?.description ?? ''}</Paragraph>
      {archetype?.competencies.map((competency, index) => {
        return (
          <Paragraph key={`competency-${index}`} css={SParagraph}>
            {competency}
          </Paragraph>
        )
      })}
    </Modal>
  )
}
