import { css } from '@emotion/react'
import Container from 'components/Container/Container'
import { calcGridWidth, colors, spacings } from 'stylesheets/theme'
import { IEventCall } from 'types'
import CallAction from 'components/CallAction'
import CallIcon from 'images/icons/call_18.svg'
import ClockIcon from 'images/icons/clock.svg'
import { Paragraph } from 'components/Typography'
import { PAGE_PADDING, PAGE_WIDTH_AND_PADDING } from './constants'
import { useComplexMediaQuery } from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

interface CallHeaderProps {
  isMobile?: boolean
  title: string
  className?: string
  data: IEventCall
  timeToComplete: string
  doMarkComplete?: boolean
  invitable?: boolean
}

const outerContainerStyle = css({
  backgroundColor: colors.backgrounds.highlight,
  width: '100%',
})

const ICON_SIZE = 67
const callIconStyle = css({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.orange_dark,
  minWidth: ICON_SIZE,
  minHeight: ICON_SIZE,
  marginLeft: calcGridWidth(1) - ICON_SIZE,
  svg: {
    width: 27,
    height: 25,
    path: {
      stroke: colors.backgrounds.white,
    },
  },
})

const mobileCallIconStyle = css({
  marginLeft: 0,
})

const defaultStyle = css({
  width: '100%',
  maxWidth: '100vw',
  margin: '0 auto',
  padding: `${spacings.grid_gap_basis_num * 2}px ${PAGE_PADDING}px`,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: spacings.grid_gap_basis_num * 2,
})

const desktopStyle = css({
  flexWrap: 'nowrap',
  width: PAGE_WIDTH_AND_PADDING,
})

const mobileStyle = css({
  padding: '24px',
})

const defaultTitleContainerStyle = css({
  flexWrap: 'nowrap',
  maxWidth: calcGridWidth(5),
  alignItems: 'flex-start',
})
const desktopTitleContainerStyle = css({
  maxWidth: calcGridWidth(7),
})

const mobileTitleContainerStyle = css({
  flexWrap: 'wrap',
})

const defaultDateBadgeTitleContainerStyle = css({
  gap: spacings.grid.gap,
  alignItems: 'flex-start',
  width: '100%',
})

const mobileDateBadgeTitleContainerStyle = css({
  flexWrap: 'wrap',
})

export default function CallHeader({
  isMobile = false,
  title,
  className = '',
  data,
  timeToComplete,
  doMarkComplete = false,
  invitable = false,
}: CallHeaderProps): JSX.Element {
  const isFullDesktop = useComplexMediaQuery({ minWidth: breakpoints.xlg })
    .meetsDetails.minWidth

  return (
    <Container css={outerContainerStyle}>
      <Container
        css={[
          defaultStyle,
          isFullDesktop && desktopStyle,
          isMobile && mobileStyle,
        ]}>
        <Container
          direction="row"
          className={className}
          css={[
            defaultDateBadgeTitleContainerStyle,
            isMobile && mobileDateBadgeTitleContainerStyle,
          ]}>
          <div css={[callIconStyle, isMobile ? mobileCallIconStyle : null]}>
            <CallIcon />
          </div>
          <Container
            css={{
              justifyContent: 'space-between',
              maxWidth: 542 + calcGridWidth(7) + spacings.grid.gap,
              flexGrow: 1,
            }}>
            <Container
              direction="column"
              css={[
                defaultTitleContainerStyle,
                isFullDesktop && desktopTitleContainerStyle,
                isMobile && mobileTitleContainerStyle,
              ]}>
              <h1
                css={{
                  color: colors.orange_dark,
                }}>
                {title}
              </h1>
              <Container
                css={{
                  justifyContent: 'space-between',
                  maxWidth: 542 + calcGridWidth(7) + spacings.grid.gap,
                  flexGrow: 1,
                }}>
                <ClockIcon
                  css={{
                    width: 17,
                    height: 17,
                    path: {
                      fill: colors.text.text_5,
                    },
                  }}
                />
                <Paragraph bold>
                  Estimated time commitment: {timeToComplete}
                </Paragraph>
              </Container>
            </Container>
            {!isMobile && (
              <Container>
                <CallAction
                  data={data}
                  location="banner"
                  doMarkComplete={doMarkComplete} //TODO
                  invitable={invitable}
                />
              </Container>
            )}
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
