import Axios, { AxiosResponse } from 'axios'
import Button, { Variant } from 'components/Button'
import ConfirmationModal from 'components/ConfirmationModal'
import Link from 'components/Link'
import { useCallback, useEffect, useState } from 'react'

interface IUserTableActionsCellProps {
  data: {
    edit_url: string
    mimic_url: string | null
    suspend_url: string | null
    resume_url: string | null
    delete_url: string | null
    anonymize_url: string | null
  }
}

enum Action {
  MIMIC,
  SUSPEND,
  RESUME,
  DELETE,
  ANONYMIZE,
}

const UserTableActionsCell = ({
  data: {
    edit_url: editUrl,
    mimic_url: mimicUrl,
    suspend_url: suspendUrl,
    resume_url: resumeUrl,
    delete_url: deleteUrl,
    anonymize_url: anonymizeUrl,
  },
}: IUserTableActionsCellProps): React.ReactElement => {
  const [showConfirmation, setShowConfirmation] = useState<Action | null>(null)
  const [requestAction, setRequestAction] = useState<Action | null>(null)
  const [actionSuccess, setActionSuccess] = useState<string | null>(null)
  const [actionError, setActionError] = useState<string | null>(null)

  const onConfirm = useCallback(() => {
    setShowConfirmation(null)
    setRequestAction(showConfirmation)
  }, [setShowConfirmation, setRequestAction, showConfirmation])

  useEffect(() => {
    let response: Promise<AxiosResponse> = null
    if (requestAction == Action.ANONYMIZE) {
      response = Axios.request({
        url: anonymizeUrl,
        method: 'POST',
        data: {
          authenticity_token: window.authenticity_token,
        },
      })
    } else if (requestAction == Action.DELETE) {
      response = Axios.request({
        url: deleteUrl,
        method: 'DELETE',
        data: {
          authenticity_token: window.authenticity_token,
        },
      })
    }

    if (response) {
      response
        .then((r) => {
          const {
            data: {
              data: { message },
            },
          } = r

          setActionSuccess(message || 'Success!')
          setRequestAction(null)
        })
        .catch((e) => {
          if (e.response) {
            const { message } = e.response.data.data || {}

            setActionError(message || 'Something went wrong')
          } else {
            setActionError('Something went wrong')
          }

          setRequestAction(null)
        })
    }
  }, [
    requestAction,
    anonymizeUrl,
    deleteUrl,
    setRequestAction,
    setActionSuccess,
    setActionError,
  ])

  useEffect(() => {
    if (actionSuccess) {
      window.flash(actionSuccess)
      location.reload()
    }
  }, [actionSuccess])

  useEffect(() => {
    if (actionError) {
      window.flash(actionError, 'alert')
    }
  }, [actionError])

  const CONFIRMATION_TEXT = {
    [Action.ANONYMIZE]:
      "This action will transform this user's personal information so that the user can no longer be identified. This action cannot be undone.",
    [Action.DELETE]:
      "This action will delete all of this user's data from Mindr Connect. This action cannot be undone.",
  }

  return (
    <>
      <ConfirmationModal
        isOpen={showConfirmation != null}
        title="Are you sure?"
        onConfirm={onConfirm}
        onCancel={() => setShowConfirmation(null)}>
        {CONFIRMATION_TEXT[showConfirmation]}
      </ConfirmationModal>
      {mimicUrl && (
        <Link href={mimicUrl} className="ignore-goji">
          Mimic
        </Link>
      )}
      {suspendUrl && (
        <Link href={suspendUrl} className="ignore-goji">
          Suspend
        </Link>
      )}
      {resumeUrl && (
        <Link href={resumeUrl} className="ignore-goji">
          Resume
        </Link>
      )}
      {deleteUrl && (
        <Button
          variant={Variant.LINK}
          onClick={() => setShowConfirmation(Action.DELETE)}>
          Delete
        </Button>
      )}
      {anonymizeUrl && (
        <Button
          variant={Variant.LINK}
          onClick={() => setShowConfirmation(Action.ANONYMIZE)}>
          Anonymize
        </Button>
      )}
      <Link href={editUrl} className="ignore-goji">
        Edit
      </Link>
    </>
  )
}

export default UserTableActionsCell
