import { useContext, useMemo } from 'react'
import ApplicationStepWrapper from './ApplicationStepWrapper'
import Select, { IOption } from 'components/Inputs/Select'
import ApplicationFormContext from './ApplicationFormContext'
import OptionalHeading from './OptionalHeading'
import ApplicationFormFieldContainer from './ApplicationFormFieldContainer'
import { LabelVisibility } from 'components/Forms'
import { colors, spacings } from 'stylesheets/theme'
import './boost_prioritization.scss'

interface BoostPrioritizationProps {
  name?: string
  boostList?: IOption[]
}

const dropdownStyle = {
  control: {
    width: '335px',
  },
  menu: {
    margin: 0,
    width: '335px',
  },
  menuList: {
    padding: 0,
    'max-height': `calc(97px * 2.75)`, // 97px per option, 2.75 options
    'overflow-y': 'scroll !important',
  },
  option: {
    display: 'flex',
    'flex-direction': 'column',
    gap: spacings.grid_gap_basis,
    'flex-wrap': 'wrap',
    padding: spacings.grid_gap_basis,
    'white-space': 'normal',
    'border-bottom': `0.5px solid ${colors.borders.gray}`,
  },
}

export default function BoostPrioritization({
  name,
  boostList,
}: BoostPrioritizationProps): JSX.Element {
  const { formData, updateRawFormData, isMobile } = useContext(
    ApplicationFormContext,
  )

  const title = useMemo(() => {
    return (
      <OptionalHeading>
        Identify what matters most to you in your mentor match
      </OptionalHeading>
    )
  }, [])

  return (
    <ApplicationStepWrapper
      title={title}
      description="We want to understand what you value most in an ideal match. Please indicate which of the following factors is most important to you."
      css={
        isMobile && {
          '.content-box': {
            '.step-question': {
              marginBottom: spacings.grid_gap_basis,
            },
          },
        }
      }>
      <ApplicationFormFieldContainer
        labelVisibility={LabelVisibility.HIDE}
        label="Prioritization"
        fieldId={name}>
        <Select<IOption>
          name={name}
          defaultValue={formData[name] || null}
          options={boostList}
          showDescription
          styleOverrides={{
            ...dropdownStyle,
          }}
          onChange={(data) => {
            const newFormData = new FormData()
            newFormData.append(name, data[name] as string)
            updateRawFormData(newFormData)
          }}
          css={{
            resize: 'none',
            width: isMobile ? '100%' : 463,
          }}
          isSearchable={!isMobile}
        />
      </ApplicationFormFieldContainer>
    </ApplicationStepWrapper>
  )
}
