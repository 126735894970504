import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import InputWrapper from '../InputWrapper/InputWrapper'
import './TimePicker.scss'

// TODO - MC-2575 replace this with a react library
import $ from 'jquery'
import 'jquery-datetimepicker'
import 'jquery-datetimepicker/jquery.datetimepicker.css'
import moment from 'moment'
import {
  IControlledInputBaseProps,
  IUncontrolledInputBaseProps,
} from '../types'

interface ITimePickerBaseProps {
  maxTime?: string
  minTime?: string
}

type IControlledTimePicker = ITimePickerBaseProps & IControlledInputBaseProps

type IUncontrolledTimePicker = ITimePickerBaseProps &
  IUncontrolledInputBaseProps

$.datetimepicker.setDateFormatter({
  parseDate: function (date, format) {
    const d = moment(date, format)
    return d.isValid() ? d.toDate() : false
  },

  formatDate: function (date, format) {
    return moment(date).format(format)
  },

  formatMask: function (format) {
    return format
      .replace(/Y{4}/g, '9999')
      .replace(/Y{2}/g, '99')
      .replace(/M{2}/g, '19')
      .replace(/D{2}/g, '39')
      .replace(/H{2}/g, '29')
      .replace(/m{2}/g, '59')
      .replace(/s{2}/g, '59')
  },
})
$.datetimepicker.setLocale('en')

const TimePicker = ({
  name,
  onChange,
  id,
  maxTime = '11:59pm',
  minTime = '12:00am',
  errors = [],
  placeholder = '',
  className,
  ...props
}: IControlledTimePicker | IUncontrolledTimePicker): React.ReactElement => {
  const [value, setValue] = useState((props as IControlledTimePicker).value)
  const { defaultValue } = props as IUncontrolledTimePicker

  const inputEl = useRef()

  useEffect(() => {
    const $inputEl = $(inputEl.current)
    $inputEl.datetimepicker({
      datepicker: false,
      formatTime: 'h:mma',
      format: 'h:mma',
      maxTime,
      minTime,
      onChangeDateTime: (_, input) => {
        onChange &&
          onChange({
            [name]: input.val(),
          })
      },
    })
  }, [inputEl])

  return (
    <InputWrapper
      className={cx('react-time-picker', className)}
      withIcon
      errors={errors}>
      <input
        type="text"
        name={name}
        id={id || name}
        defaultValue={defaultValue || undefined}
        value={value || undefined}
        onChange={
          value
            ? (data) => {
                setValue(data[name] as string)
              }
            : undefined
        }
        className={cx('react-input', { 'validation-error': errors?.length })}
        placeholder={placeholder}
        ref={inputEl}
        {...props}
      />
    </InputWrapper>
  )
}

export default TimePicker
