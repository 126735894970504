import React, { useMemo, useState, useCallback } from 'react'
import { spacings, colors } from 'stylesheets/theme'
import Step from './Step'
import { css } from '@emotion/react'
import Pill, { PillColor } from 'components/Pill/Pill'
import Container from 'components/Container/Container'
import Button, { Variant } from 'components/Button/Button'
import RightArrowWhite from 'images/icons/arrow_right_w.svg'
import LeftArrow from 'images/icons/arrow_left.svg'
import { Caption } from 'components/Typography'

import { useContentDimensions } from 'hooks/useDimensions'
import { CSSInterpolation } from '@storybook/theming/dist/ts3.9/_modules/@emotion-react-node_modules-@emotion-serialize-types-index'

export enum Breakpoints {
  NARROW = 1200,
  MEDIUM = 1300,
  WIDE = 1530,
}
export interface TStep {
  style?: CSSInterpolation | CSSInterpolation[] | string
  title: string
  description: string | React.ReactNode
  caption?: string
  oneImage?: string
  images?: {
    narrow?: string
    medium?: string
    wide?: string
    extraWide?: string
  }
  content?: React.ReactNode
  removeBottomNav?: boolean
}

export interface IStepFunctions {
  nextStep?: () => void
  prevStep?: () => void
  setStep?: (stepIndex: number) => void
}

export type TTStepsFunction = (funcs: IStepFunctions) => TStep[]

interface GuideWizardProps {
  className?: string
  steps?: TStep[]
  stepsFunction?: TTStepsFunction
  selectedStepIndex?: number
  mode?: string
  extraStepsForHeight?: number
  allowDisabledFutureSteps?: boolean
}

const captionStyle = css({
  marginBottom: spacings.grid_gap_basis_num / 2,
  fontStyle: 'italic',
})

const defaultPanelStyle = css({
  height: '100%',
  alignSelf: 'flex-start',
  borderRadius: spacings.large_radius,
  backgroundColor: colors.backgrounds.white,
  display: 'flex',
  flexDirection: 'column',
})

const leftPanelStyle = css({
  width: '412px',
  alignItems: 'center',
  padding: spacings.grid_gap_basis_num * 2,
  gap: spacings.grid_gap_basis,
})

const rightPanelStyle = css({
  width: '100%',
  padding: spacings.grid_gap_basis_num * 3,
  paddingBottom: spacings.grid_gap_basis_num * 2,
  gap: spacings.grid_gap_basis_num * 2,
  justifyContent: 'space-between',
})

const defaultButtonStyle = css({
  height: spacings.grid_gap_basis_num * 3.5,
  position: 'absolute',
})

const previousStepButtonStyle = css({
  minWidth: '170px',
  left: 0,
})

const nextStepButtonStyle = css({
  minWidth: '140px',
  right: 0,
})

const buttonsBoxWrapperStyle = css({
  width: '100%',
  height: spacings.grid_gap_basis_num * 5.5,
  position: 'sticky',
  bottom: 60, // accounts for the footer height of 60px
  left: 0,
  right: 0,
  paddingBottom: spacings.grid_gap_basis_num,
  paddingTop: spacings.grid_gap_basis_num,
  backgroundColor: colors.backgrounds.white,
})

const stepImageStyle = css({
  border: `1px solid ${colors.borders.gray}`,
  borderRadius: 6,
  objectFit: 'cover',
  width: '100%',
  height: 750,

  img: {
    marginRight: 'auto',
    marginLeft: 'auto',
  },
})

const stepOneImageStyle = css({
  border: `1px solid ${colors.borders.gray}`,
  borderRadius: 6,
  width: '100%',
})

export default function GuideWizard({
  className,
  steps = [],
  stepsFunction,
  selectedStepIndex = 0,
  extraStepsForHeight = 0,
  mode = '',
  allowDisabledFutureSteps = false,
}: GuideWizardProps): JSX.Element {
  const closestBreakpoint = useContentDimensions(
    ...(Object.values(Breakpoints).filter(Number.isInteger) as number[]),
  )

  const [currentStepIndex, setCurrentStepIndex] =
    useState<number>(selectedStepIndex)
  const onNextStep = useCallback(() => {
    setCurrentStepIndex(currentStepIndex + 1)
  }, [currentStepIndex])
  const onPreviousStep = useCallback(() => {
    setCurrentStepIndex(currentStepIndex - 1)
  }, [currentStepIndex])
  const onSelectStep = useCallback(
    (stepIndex: number) => {
      setCurrentStepIndex(stepIndex)
    },
    [currentStepIndex],
  )

  const stepsFromFunc = useMemo(() => {
    return stepsFunction
      ? stepsFunction({
          nextStep: onNextStep,
          prevStep: onPreviousStep,
          setStep: onSelectStep,
        })
      : []
  }, [currentStepIndex, stepsFunction, mode])

  const useSteps = steps?.length ? steps : stepsFromFunc

  const currentStep = useSteps?.length
    ? useSteps[(currentStepIndex + useSteps.length) % useSteps.length]
    : null

  const currentStepImage: string = useMemo(() => {
    if (!currentStep) {
      return null
    }

    if (currentStep.oneImage) {
      return currentStep.oneImage
    }

    if (!currentStep.images) {
      return null
    }

    switch (closestBreakpoint) {
      case Breakpoints.NARROW:
        return currentStep.images.narrow
      case Breakpoints.MEDIUM:
        return currentStep.images.medium
      case Breakpoints.WIDE:
        return currentStep.images.wide
      default:
        return currentStep.images.extraWide
    }
  }, [currentStepIndex, closestBreakpoint])

  const height = useMemo(() => {
    const len = (useSteps ?? []).length + extraStepsForHeight
    const padding = spacings.grid_gap_basis_num * 4
    const buttonHeights = len * 74
    const spacingHeights = (len - 1) * spacings.grid_gap_basis_num
    return (
      padding /* top + bottom padding */ +
      buttonHeights /* the aggregate height of ALL sidebar nav buttons */ +
      spacingHeights /* the aggregate height of ALL space between nav buttons */
    )
  }, [useSteps, extraStepsForHeight])

  if (!currentStep) return null

  return (
    <Container
      css={[
        {
          gap: '30px',
          height: height,
        },
        currentStep.style,
      ]}
      className={className}>
      {/* left panel */}
      <div css={[defaultPanelStyle, leftPanelStyle]}>
        {useSteps.map((step, index) => (
          <Step
            key={index}
            disabled={allowDisabledFutureSteps && index > currentStepIndex}
            stepNumber={index + 1}
            title={step.title}
            selected={currentStepIndex === index}
            onClick={() => onSelectStep(index)}
          />
        ))}
      </div>
      {/* right panel */}
      <div className="panel" css={[defaultPanelStyle, rightPanelStyle]}>
        <Container className="panel-header">
          <Pill color={PillColor.GREEN} dark size="large">
            Step {currentStepIndex + 1}
          </Pill>
          <h5>{currentStep.description}</h5>
        </Container>
        <div
          css={{
            flexGrow: 1,
          }}>
          {currentStep.caption && (
            <Caption css={captionStyle}>{currentStep.caption}</Caption>
          )}
          {currentStep.content ? (
            currentStep.content
          ) : (
            <img
              src={currentStepImage}
              alt={currentStep.title}
              css={
                currentStep.oneImage ? stepOneImageStyle : stepImageStyle
              }></img>
          )}
        </div>
        {!currentStep.removeBottomNav ? (
          <div css={buttonsBoxWrapperStyle}>
            {currentStepIndex !== 0 && (
              <Button
                variant={Variant.TERTIARY}
                startIcon={<LeftArrow />}
                css={[defaultButtonStyle, previousStepButtonStyle]}
                onClick={onPreviousStep}>
                Previous Step
              </Button>
            )}
            {currentStepIndex !== useSteps.length - 1 && (
              <Button
                variant={Variant.PRIMARY}
                endIcon={<RightArrowWhite />}
                css={[defaultButtonStyle, nextStepButtonStyle]}
                onClick={onNextStep}>
                Next Step
              </Button>
            )}
          </div>
        ) : null}
      </div>
    </Container>
  )
}
