import Button, { ButtonComponentType, Variant } from 'components/Button'
import InvitedSubscribersModal from './InvitedSubscribersModal'
import { useMemo, useState } from 'react'
import { IFilterItem } from './types'
import { Caption } from 'components/Typography'
import { serverUTCTimeFormat } from 'javascripts/general'
import moment from 'moment'

interface ICommunicationLogActionsCellProps {
  data: {
    full_name: string
    communication:
      | 'invitation_sent'
      | 'invitation_initiated'
      | 'reminder_sent' // MME reminder
      | 'reminders_sent' // Event reminders
      | 'initiated_reminders_sent' // Initiated event reminders
      | 'assessment_invites_initiated' // leadr assessment invites initiated
      | 'assessment_invites_sent' // leadr assessment invites sent
    communication_humanized: string
    acted_at: string
    details?: any
  }
}

function plural(count, single, nonSingle) {
  return count === 1 ? single : nonSingle
}

export default function CommunicationLogActionsCell({
  data,
}: ICommunicationLogActionsCellProps): JSX.Element {
  const [open, setOpen] = useState(false)
  const { communication, communication_humanized } = data

  let audience = null
  if (data.details?.audience) {
    audience = JSON.parse(data.details?.audience)
  }

  const action = useMemo(() => {
    let cnt
    switch (communication) {
      case 'invitation_sent':
        cnt = data.details?.audience_count ?? 0
        return (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            className="link ignore-goji"
            onClick={() => {
              setOpen(true)
            }}>
            {data.details.is_subset_audience
              ? 'Invitation sent to ' + cnt + plural(cnt, ' user', ' users')
              : 'Invitation sent to all subscribers'}
          </Button>
        )

      case 'invitation_initiated':
        cnt = data.details?.audience_count ?? 0
        return (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            className="link ignore-goji"
            onClick={() => {
              setOpen(true)
            }}>
            {`${cnt} ${plural(cnt, 'invitation', 'invitations')} initiated`}
          </Button>
        )

      case 'initiated_reminders_sent':
        cnt = data.details?.count ?? 0
        return (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            className="link ignore-goji"
            onClick={() => {
              setOpen(true)
            }}>
            {`${cnt} ${plural(cnt, 'reminder', 'reminders')} initiated`}
          </Button>
        )

      case 'reminders_sent':
        cnt = data.details?.count ?? 0
        return (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            className="link ignore-goji"
            onClick={() => {
              setOpen(true)
            }}>
            {`${plural(cnt, 'Reminder', 'Reminders')} sent to ${cnt} ${plural(
              cnt,
              'user',
              'users',
            )}`}
          </Button>
        )
      case 'assessment_invites_initiated':
        cnt = data.details.audience_count ?? 0
        return (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            className="link ignore-goji"
            onClick={() => {
              setOpen(true)
            }}>
            {cnt + plural(cnt, ' invitation', ' invitations') + ' initiated'}
          </Button>
        )
      case 'assessment_invites_sent':
        cnt = data.details.audience_count ?? 0
        return (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            className="link ignore-goji"
            onClick={() => {
              setOpen(true)
            }}>
            {plural(cnt, 'Invitation', 'Invitations') +
              ' sent to ' +
              cnt +
              plural(cnt, ' user', ' users')}
          </Button>
        )
      default:
        return <Caption>{communication_humanized}</Caption>
    }
  }, [data, communication])

  const isSubset = useMemo(() => {
    switch (communication) {
      case 'invitation_sent':
      case 'invitation_initiated':
      case 'assessment_invites_initiated':
      case 'assessment_invites_sent':
        return data.details?.is_subset_audience ?? false
      case 'initiated_reminders_sent':
      case 'reminders_sent':
      default:
        return false
    }
  }, [data, communication])

  const isCsvImport = useMemo(() => {
    switch (communication) {
      case 'invitation_sent':
      case 'invitation_initiated':
        return audience?.is_csv_import ?? false
      case 'assessment_invites_initiated':
      case 'assessment_invites_sent':
        return data.details?.is_csv_import ?? false
      default:
        return false
    }
  }, [data, communication, audience])

  const audienceCount = useMemo(() => {
    switch (communication) {
      case 'invitation_sent':
      case 'invitation_initiated':
      case 'assessment_invites_initiated':
      case 'assessment_invites_sent':
        return data.details?.audience_count ?? 0
      case 'initiated_reminders_sent':
      case 'reminders_sent':
        return data.details?.count ?? 0
      default:
        return 0
    }
  }, [data, communication])

  const audienceFilters = useMemo(() => {
    switch (communication) {
      case 'invitation_sent':
      case 'invitation_initiated':
        return (
          (JSON.parse(audience?.audience_filters ?? '[]') as IFilterItem[][]) ??
          null
        )
      case 'assessment_invites_initiated':
      case 'assessment_invites_sent':
        return ((data.details?.filters ?? []) as IFilterItem[][]) ?? null

      default:
        return null
    }
  }, [communication, audience, data])

  const recipientsTableTitle = useMemo(() => {
    switch (communication) {
      case 'invitation_sent':
      case 'invitation_initiated':
      case 'assessment_invites_initiated':
      case 'assessment_invites_sent':
        return 'Invitation recipients'
      case 'initiated_reminders_sent':
      case 'reminders_sent':
        return 'Reminder recipients'

      default:
        return null
    }
  }, [data, communication])

  const recipientsTable = useMemo(() => {
    switch (communication) {
      case 'invitation_sent':
      case 'invitation_initiated':
      case 'assessment_invites_initiated':
      case 'assessment_invites_sent':
        return data.details?.audience_user_table
      case 'initiated_reminders_sent':
      case 'reminders_sent':
        return data.details?.table
      default:
        return null
    }
  }, [data, communication])

  const csvTable = useMemo(() => {
    switch (communication) {
      case 'invitation_sent':
      case 'invitation_initiated':
      case 'assessment_invites_initiated':
      case 'assessment_invites_sent':
        return data.details?.csv_import_history_table
      case 'initiated_reminders_sent':
      case 'reminders_sent':
      default:
        return null
    }
  }, [data, communication])

  const title = useMemo(() => {
    switch (communication) {
      case 'invitation_sent':
      case 'invitation_initiated':
        return 'Invited users'
      case 'initiated_reminders_sent':
        return 'Initiated reminders for users'
      case 'reminders_sent':
        return 'Reminded users'
      case 'assessment_invites_initiated':
      case 'assessment_invites_sent':
        return 'Invited users'
      default:
        return null
    }
  }, [data, communication])

  const messageGenerator = useMemo(() => {
    switch (communication) {
      case 'reminders_sent':
        return (data: Record<string, any>) => {
          const { audienceCount = 0 } = data
          if (audienceCount === 1) {
            return 'user reminded to register on'
          } else {
            return 'users reminded to register on'
          }
        }

      case 'initiated_reminders_sent':
        return (data: Record<string, any>) => {
          const { audienceCount = 0 } = data
          if (audienceCount === 1) {
            return 'reminder is in the process of being sent out as of'
          } else {
            return 'reminders are in the process of being sent out as of'
          }
        }

      default:
        return null
    }
  }, [data, communication])

  return (
    <>
      {action}
      {data.details && Object.keys(data.details).length !== 0 && (
        <InvitedSubscribersModal
          isOpen={open}
          title={title}
          onRequestClose={() => setOpen(false)}
          isSubsetAudience={isSubset}
          isCsvImport={isCsvImport}
          audienceCount={audienceCount}
          audienceFilters={audienceFilters}
          invitationReceivedDateTime={moment(
            (data.acted_at as string).replace('UTC', '+0000'),
            serverUTCTimeFormat,
          ).format('ddd, MMM Do, YYYY [at] h:mma')}
          recipientsTableTitle={recipientsTableTitle}
          recipientsTableProps={recipientsTable}
          importHistoryTableProps={csvTable}
          initiated={
            communication === 'invitation_initiated' ||
            communication === 'assessment_invites_initiated'
          }
          messageGenerator={messageGenerator}
        />
      )}
    </>
  )
}
