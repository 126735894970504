import React from 'react'

import JoinWaitlistButtonView from './view'
import { useEventActionContext } from '../context'

import './JoinWaitlistButton.scss'

import type { TActionLocation } from 'types'

interface IJoinWaitlistButton {
  eventLink: string
  waitlistOpen: boolean
  location: TActionLocation
}

const JoinWaitlistButton: React.FC<IJoinWaitlistButton> = ({
  eventLink,
  waitlistOpen,
  location,
}) => {
  const { waitlisted, loading, onJoinWaitlist, onLeaveWaitlist } =
    useEventActionContext()

  return (
    <JoinWaitlistButtonView
      eventLink={eventLink}
      waitlisted={waitlisted}
      waitlistOpen={waitlistOpen}
      loading={loading}
      onJoin={onJoinWaitlist}
      onLeave={onLeaveWaitlist}
      location={location}
    />
  )
}

export default JoinWaitlistButton
