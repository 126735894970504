import React from 'react'
import ReactPaginate from 'react-paginate'

import Table, { ITableProps } from 'components/Table'

// assets
import useTableFormat from 'state/common/TableFormat'
import User from 'components/User'

import './Yearbook.scss'
import useTableState from 'state/Table'
import Input from 'components/Input'
import EmptyPlaceholder from 'components/EmptyPlaceholder'

interface IYearbook extends ITableProps {
  url: string
  backUrl: string
  currentUserId: number
}

const Yearbook: React.FC<IYearbook> = ({
  url,
  tableData: initialData,
  tableMeta: initialMeta,
  backUrl,
}) => {
  const { isTilesFormat } = useTableFormat('tiles')
  const {
    data: tableData,
    meta: tableMeta,
    loadPage,
    updateOrder,
    filters,
    updateFilters,
  } = useTableState({
    url,
    initialData,
    initialMeta,
  })
  const { paginator } = tableData
  return (
    <div id="yearbook-root" className="details-container">
      <div className="link-container">
        <a href={backUrl} className="link">
          Back to Cohort
        </a>
        <Input
          name="text"
          type="search"
          placeholder="Search"
          wrapperClassName="search"
          value={filters.text}
          onChange={(data) => {
            const record = data as Record<string, string>
            updateFilters({
              text: record.text,
            })
          }}
        />
      </div>
      <div className="section-header">
        <div className="header-left">Cohort Participants</div>
      </div>
      {isTilesFormat ? (
        <>
          {tableData.rows.length ? (
            <div className="members-tiles">
              {tableData.rows.map((member) => {
                const {
                  id,
                  logo_url,
                  full_name,
                  pronouns,
                  title,
                  leader,
                  upload_photo,
                } = member.data
                return (
                  <User
                    key={`user-${id}`}
                    id={id as number}
                    logoUrl={logo_url as string}
                    fullName={full_name as string}
                    pronouns={pronouns as string}
                    title={title as string}
                    leader={leader as boolean}
                    uploadPhoto={upload_photo as boolean}
                  />
                )
              })}
            </div>
          ) : (
            <EmptyPlaceholder text="No participants found." />
          )}
          {paginator && paginator.total_pages > 1 && (
            <div className="GojiCustomTable-pagination">
              <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={paginator.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={({ selected }) => loadPage(selected)}
                containerClassName={'pagination'}
                activeClassName={'active'}
                forcePage={paginator.current_page - 1}
              />
            </div>
          )}
        </>
      ) : (
        <Table
          tableData={tableData}
          tableMeta={tableMeta}
          onOrderChange={({ sort_col, sort_dir }) => {
            updateOrder(
              sort_col,
              sort_dir === 'asc' || sort_dir === 'desc' ? sort_dir : 'asc',
            )
          }}
          onPageChange={loadPage}
        />
      )}
    </div>
  )
}

export default Yearbook
