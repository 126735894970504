import Modal, { Size } from 'components/Modal'
import React from 'react'

interface IConfirmationModal {
  isOpen: boolean
  title: string
  children: React.ReactNode
  submitButton?: string
  cancelButton?: string
  onConfirm: () => void
  onCancel: () => void
  className?: string
}

const defaultProps: Partial<IConfirmationModal> = {
  submitButton: 'Confirm',
  cancelButton: 'Cancel',
}

const ConfirmationModal = (props: IConfirmationModal): React.ReactElement => {
  const {
    isOpen,
    title,
    children,
    submitButton,
    cancelButton,
    onConfirm,
    onCancel,
    className,
  } = {
    ...defaultProps,
    ...props,
  }

  return (
    <Modal
      largeTitle
      isOpen={isOpen}
      title={title}
      size={Size.small}
      submitButton={submitButton}
      cancelButton={cancelButton}
      onSave={onConfirm}
      onRequestClose={onCancel}
      className={className}>
      <p className="confirmation-modal">{children}</p>
    </Modal>
  )
}

export default ConfirmationModal
