import Container from 'components/Container'
import { css } from '@emotion/react'
import { calcGridWidth, colors, spacings } from 'stylesheets/theme'
import {
  PAGE_PADDING,
  PAGE_WIDTH_AND_PADDING,
} from '../EventCallPage/constants'
import useMediaQuery, { useComplexMediaQuery } from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import Avatar from 'components/Avatar/Avatar'
import Pill, { PillColor } from 'components/Pill'

const outerContainerStyle = css({
  backgroundColor: colors.backgrounds.highlight,
  width: '100%',
})

const defaultStyle = css({
  width: '100%',
  maxWidth: '100vw',
  margin: '0 auto',
  padding: `${spacings.grid_gap_basis_num * 3}px ${PAGE_PADDING}px`,
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: spacings.grid_gap_basis_num * 2,
})

const desktopStyle = css({
  flexWrap: 'nowrap',
  width: PAGE_WIDTH_AND_PADDING,
})

const mobileStyle = css({
  padding: '24px',
})

const defaultBannerContainerStyle = css({
  gap: spacings.grid.gap,
  alignItems: 'center',
  width: '100%',
})

const mobileBannerContainerStyle = css({
  flexWrap: 'wrap',
})

interface ICommunityHeaderProps {
  className?: string
  communityName: string
  isCohort?: boolean
  bannerUrl: string
  logoUrl: string
}

export default function CommunityHeader({
  className = '',
  communityName,
  isCohort = false,
  bannerUrl,
  logoUrl,
}: ICommunityHeaderProps): JSX.Element {
  const isFullDesktop = useComplexMediaQuery({ minWidth: breakpoints.xlg })
    .meetsDetails.minWidth
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  return (
    <Container
      css={[
        outerContainerStyle,
        css({
          backgroundImage: `linear-gradient(rgba(70, 70, 70, 0.65), rgba(70, 70, 70, 0.65)), url(${bannerUrl})`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }),
      ]}>
      <Container
        css={[
          defaultStyle,
          isFullDesktop && desktopStyle,
          isMobile && mobileStyle,
        ]}>
        <Container
          direction="row"
          className={className}
          css={[
            defaultBannerContainerStyle,
            isMobile && mobileBannerContainerStyle,
          ]}>
          <Container
            alignment="start"
            justify="space-between"
            css={{
              width: isMobile && '100%',
            }}>
            <Avatar avatarUrl={logoUrl} size={84} />
            {isMobile && (
              <Pill
                size="large"
                textTransform={false}
                bold={false}
                color={PillColor.PURPLE}>
                Cohort
              </Pill>
            )}
          </Container>
          <Container
            css={{
              maxWidth: 542 + calcGridWidth(7) + spacings.grid.gap,
              flexGrow: 1,
            }}>
            <h1
              css={{
                color: colors.backgrounds.white,
              }}>
              {communityName}
            </h1>
            {!isMobile && isCohort && (
              <Pill
                size="large"
                textTransform={false}
                bold={false}
                color={PillColor.PURPLE}>
                Cohort
              </Pill>
            )}
          </Container>
        </Container>
      </Container>
    </Container>
  )
}
