import { useCallback, useMemo, useState } from 'react'
import Axios from 'axios'
import _ from 'lodash'

import { IMainTableFilters, ITableData, ITableMeta } from 'components/Table'
import useOrderState, { TOrderDirection } from 'state/common/Order'
import { useEffectNotOnMount } from 'javascripts/general'
import usePageState from 'state/common/Page'

interface IOrganizationsStateProps {
  url: string
  initialData: ITableData
  initialMeta: ITableMeta
}

export interface IOrganizationsFilters {
  text: string | undefined
  showAll: boolean
}

interface IOrganizationsTableFilters extends IMainTableFilters {
  page: number
}

interface IOrganizationsState {
  loading: boolean
  data: ITableData
  meta: ITableMeta
  filters: IOrganizationsFilters
  tableFilters: IOrganizationsTableFilters
  loadPage: (page: number) => void
  reload: () => void
  updateFilters: (newFilters: Partial<IOrganizationsFilters>) => void
  updateOrder: (column: string, direction: TOrderDirection) => void
}

const useOrganizationsState = ({
  url,
  initialData,
  initialMeta,
}: IOrganizationsStateProps): IOrganizationsState => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(initialData)
  const [filters, setFilters] = useState<IOrganizationsFilters>({
    text: undefined,
    showAll: false,
  })

  const { order, updateOrder } = useOrderState()
  const { page, loadPage } = usePageState()

  const tableFilters: IOrganizationsTableFilters = useMemo(
    () => ({
      'filter[*]': filters.text || undefined,
      'filter[show_all]': filters.showAll,
      sort_col: order.column || undefined,
      sort_dir: order.direction || undefined,
      page: page + 1,
    }),
    [page, filters, order],
  )

  const loadData = useCallback(
    async (filters: IOrganizationsTableFilters) => {
      try {
        setLoading(true)
        const response = await Axios.get(url, {
          params: filters,
        })
        setLoading(false)
        setData(response.data.data)
      } catch (error) {
        window.flash('Something went wrong!', 'alert')
      }
    },
    [setLoading, setData],
  )

  const loadDataDebounced = useMemo(
    () =>
      _.debounce((tableFilters: IOrganizationsTableFilters) => {
        loadData(tableFilters)
      }, 200),
    [loadData],
  )

  useEffectNotOnMount(() => {
    loadDataDebounced(tableFilters)
  }, [loadDataDebounced, tableFilters])

  const reload = useCallback(() => {
    loadData(tableFilters)
  }, [loadData, tableFilters])

  const updateFilters = useCallback(
    (newFilters: Partial<IOrganizationsFilters>) => {
      loadPage(0)
      setFilters({
        ...filters,
        ...newFilters,
      })
    },
    [filters, setFilters],
  )

  return {
    loading,
    data,
    meta: initialMeta,
    filters,
    tableFilters,
    loadPage,
    reload,
    updateFilters,
    updateOrder,
  }
}

export default useOrganizationsState
