import React from 'react'
import axios from 'axios'
import _ from 'lodash'

import Input from 'components/Input'

import './UserPicker.scss'

import { IUser } from 'types'
import User from 'components/User'

interface IUserPicker {
  name: string
  label: string
  placeholder: string
  isMulti?: boolean
  url?: string
  value: IUser | IUser[]
  onChange: (value: Record<string, IUser>) => void
}

const defaultProps: Partial<IUserPicker> = {
  url: '/users.json',
  isMulti: false,
}

const UserPicker = (props: IUserPicker): React.ReactElement => {
  const { url, name, label, placeholder, isMulti, value, onChange } = {
    ...defaultProps,
    ...props,
  }
  const loadOptions = _.debounce((inputValue, callback) => {
    axios
      .get(url, {
        params: {
          'filter[*]': inputValue,
          active_only: true,
          exclude_self: true,
        },
      })
      .then(({ data: { data } }) => {
        callback(data.rows.map((row) => row.data))
      })
  }, 400)

  // React Select types don't allow React option labels even though it is supported
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const userOption = (user: IUser): any => (
    <div className="user-select-item">
      <User
        id={user.id}
        fullName={user.full_name}
        logoUrl={user.logo_url}
        title={null}
        leader={false}
        uploadPhoto={false}
      />
    </div>
  )

  return (
    <Input<IUser>
      type="async_select"
      label={label}
      name={name}
      placeholder={placeholder}
      loadOptions={loadOptions}
      isMulti={isMulti}
      selectValue={value}
      onChange={onChange}
      getOptionLabel={userOption}
      getOptionValue={userOption}
      getOptionKey={(o) => o.id}
      wrapperClassName="user-picker-field"
    />
  )
}

export default UserPicker
