import Modal, { Size } from 'components/Modal'
import { useEffect } from 'react'

import Table, {
  DateTableCell,
  DateTableCellVariant,
  TableCellWrapper,
} from 'components/Table'
import useTableState from 'state/Table'

import OperationalLogTypeCell from './OperationalLogTypeCell'
import OperationalLogStatusPill from './OperationalLogStatusPill'

import type {
  OperationalLogStatus,
  OperationalLogCardType,
  OperationalLogType,
} from 'types'

interface IOperationalLogsModalProps {
  url: string
  onClose: () => void
}

interface IOperationaLogsData {
  operation_type: OperationalLogType
  status: OperationalLogStatus | null
  details: {
    card_type: OperationalLogCardType
    response: number
  }
  acted_at: string
}

const OperationalLogsModal = ({
  url,
  onClose,
}: IOperationalLogsModalProps): JSX.Element => {
  const { data, meta, loadPage, updateOrder } =
    useTableState<IOperationaLogsData>({ url })

  useEffect(() => {
    loadPage(0)
  }, [loadPage])

  return (
    <Modal
      isOpen={true}
      size={Size.small}
      onRequestClose={onClose}
      onSave={onClose}
      title="Operational Logs">
      {data && meta && (
        <Table
          tableData={data}
          tableMeta={meta}
          onOrderChange={({ sort_col, sort_dir }) => {
            updateOrder(
              sort_col,
              sort_dir === 'asc' || sort_dir === 'desc' ? sort_dir : 'asc',
            )
          }}
          onPageChange={loadPage}>
          {(row) => {
            const {
              data: {
                acted_at,
                operation_type,
                status,
                details: { card_type },
              },
            } = row
            return (
              <>
                <TableCellWrapper>
                  <OperationalLogTypeCell
                    operationType={operation_type}
                    cardType={card_type}
                  />
                </TableCellWrapper>
                <TableCellWrapper>
                  <OperationalLogStatusPill status={status} />
                </TableCellWrapper>
                <DateTableCell
                  variant={DateTableCellVariant.DateTimeUTC}
                  value={acted_at}
                />
              </>
            )
          }}
        </Table>
      )}
    </Modal>
  )
}

export default OperationalLogsModal
