import React, { useCallback, useState, useEffect } from 'react'
import classNames from 'classnames'
import Input from 'components/Input'
import './PronounsInput.scss'

const pronounsTypes = [
  {
    label: 'He/him',
    value: 'He/him',
  },
  {
    label: 'She/her',
    value: 'She/her',
  },
  {
    label: 'They/them',
    value: 'They/them',
  },
  {
    label: 'Not listed',
    value: 'custom',
  },
]

interface IPronounsInput {
  name: string
  label: string
  initialValue: string
}

const PronounsInput: React.FC<IPronounsInput> = ({
  name,
  label,
  initialValue,
}) => {
  const [value, setValue] = useState<string>(initialValue)
  const [type, setType] = useState<string>(null)

  useEffect(() => {
    if (initialValue) {
      if (pronounsTypes.map((type) => type.value).includes(initialValue)) {
        setType(initialValue)
      } else {
        setType('custom')
      }
    }
  }, [])

  const handleTypeChange = useCallback(
    (payload) => {
      const selectedType = payload['pronouns_type']

      if (selectedType === 'custom') {
        setValue('')
      } else {
        setValue(selectedType)
      }

      setType(selectedType)
    },
    [setValue, setType],
  )

  const handleCustomInput = useCallback(
    (payload) => {
      const newInput = payload[name]
      setValue(newInput)
    },
    [setValue],
  )

  return (
    <>
      <Input
        name="pronouns_type"
        label={label}
        type="select"
        value={type}
        options={pronounsTypes}
        onChange={handleTypeChange}
      />
      <Input
        name={name}
        wrapperClassName={classNames({ invisible: type !== 'custom' })}
        value={value}
        onChange={handleCustomInput}
        maxLength="25"
      />
    </>
  )
}

export default PronounsInput
