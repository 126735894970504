import { Styles } from 'react-select'
import { colors } from 'stylesheets/theme'

export const selectStyles: Styles<any, false> = {
  control: (provided, { isFocused }) => {
    return {
      ...provided,
      borderColor: isFocused ? '#3C9F95' : '#e7e7e7',
      borderRadius: 1,
      borderWidth: 1,
      boxShadow: 'none',
      minHeight: 42,
      transition: 'none',
      padding: '0 7px',
      color: '#999999',
      '&:hover': {
        borderColor: '#e7e7e7',
        color: '#555555',
      },
    }
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#555555',
      fontSize: '15px',
      lineHeight: '25px',
    }
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontSize: 14,
      lineHeight: '20px',
      color: colors.text.text_3,
    }
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    }
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: '#999999',
      '&:hover': {
        color: '#555555',
      },
    }
  },
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: '#e8e8e8',
      borderRadius: 5,
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontSize: 14,
      lineHeight: '20px',
      color: '#555',
    }
  },
  menu: (provided) => {
    return {
      ...provided,
      borderRadius: 1,
      zIndex: 1000,
    }
  },
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    fontFamily: 'inherit',
    fontSize: '15px',
    lineHeight: '19px',
    backgroundColor: isSelected || isFocused ? '#f5fafd' : '#fff',
    color: isSelected || isFocused ? '#495c68' : '#303030',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    ':active': {
      ...provided[':active'],
      backgroundColor: '#f5fafd',
      color: '#495c68',
    },
  }),
}

export const homepageSliderOptions = {
  dots: false,
  autoplay: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  infinite: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 690,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}

export const formFieldName = (
  fieldName: string,
  keys: string[] = [],
): string => {
  return keys.length ? fieldName + '[' + keys.join('][') + ']' : fieldName
}

interface IMonthYearFromNameResult {
  month: string
  year: number
  index: string
}
export const monthYearFromName = (
  name = '',
  defMonth = '',
  defYear = new Date().getFullYear(),
  defIndex = '',
): IMonthYearFromNameResult => {
  const split = (name || '').split(/\s+/)

  let month = defMonth
  let year = defYear
  let index = defIndex

  if (split[0] && split[0].match(/^\w+$/)) {
    month = split[0]
  }

  if (split[1]) {
    if (split[1].match(/^\d+/)) {
      year = parseInt(split[1])
    } else if (split[1].match(/^\(\d+\)$/)) {
      index = split[1]
    }
  }

  if (split[2] && !index) {
    index = split[2]
  }

  if (split[1] === 'NaN') split[1] = ''
  return {
    month: month,
    year: year,
    index: index,
  }
}

const withSuffix = (num: string | number) => {
  const str = `${num}`
  switch (str[str.length - 1]) {
    case '1':
      return `${num}st`

    case '2':
      return `${num}nd`

    case '3':
      return `${num}rd`

    default:
      return `${num}th`
  }
}

export const formattedDate = (datestamp: string): string => {
  if (!datestamp) {
    return ''
  }

  const date = new Date(datestamp)
  return [
    `${date.toLocaleDateString('en-US', {
      weekday: 'short',
      timeZone: 'UTC',
    })},`,
    date.toLocaleDateString('en-US', { month: 'short', timeZone: 'UTC' }),
    `${withSuffix(
      date.toLocaleDateString('en-US', { day: 'numeric', timeZone: 'UTC' }),
    )},`,
    date.toLocaleDateString('en-US', { year: 'numeric', timeZone: 'UTC' }),
  ].join(' ')
}

export const hourOnly = (timestamp: string): string => {
  if (!timestamp) {
    return ''
  }

  const date = new Date(timestamp)
  if (isNaN(date.getTime())) {
    return ''
  }

  const hour = date.getUTCHours()
  const minute = `:${String(date.getUTCMinutes()).padStart(2, '0')}`
  if (hour < 12) {
    return hour === 0 ? `12${minute}am` : `${hour}${minute}am`
  } else {
    return hour === 12 ? `12${minute}pm` : `${hour - 12}${minute}pm`
  }
}

export function isNullish(obj: any) {
  return obj === null || obj === undefined
}
