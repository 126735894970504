import { css } from '@emotion/react'

import Widget from './Widget'

import PeopleIcon from 'images/icons/people.svg'

import { colors } from 'stylesheets/theme'

interface RegistrantWidgetProps {
  registrantSize: number
  title: string
}

const iconStyle = css({
  path: {
    fill: colors.blue,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.registrant_widget_background,
  },
})

export default function RegistrantWidget({
  registrantSize,
  title,
}: RegistrantWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<PeopleIcon height="18px" width="18px" css={iconStyle} />}
      number={registrantSize}
      title={title}
      css={widgetStyle}
    />
  )
}
