import React, { useContext } from 'react'
import cx from 'classnames'

import NavbarContext from '../../NavbarContext'
import './CommunityBasicInfo.scss'

export default function CommunityBasicInfo(): JSX.Element {
  const { collapsed, contextName, logoUrl } = useContext(NavbarContext)
  return (
    <div className="community-basic-info">
      <img
        aria-label="user avatar"
        className={cx('avatar', { collapsed: collapsed })}
        src={logoUrl}></img>
      {!collapsed && <div className="title">{contextName}</div>}
    </div>
  )
}
