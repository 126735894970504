import { css } from '@emotion/react'

import Widget from './Widget'

import RequestIcon from 'images/icons/request.svg'

import { colors } from 'stylesheets/theme'

interface InquiriesWidgetProps {
  requestSize: number
  title: string
}

const iconStyle = css({
  path: {
    fill: colors.orange_dark,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.waitlist_widget_background,
  },
})

export default function InquiriesWidget({
  requestSize,
  title,
}: InquiriesWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<RequestIcon height="18px" width="18px" css={iconStyle} />}
      number={requestSize}
      title={title}
      css={widgetStyle}
    />
  )
}
