import React from 'react'
import cx from 'classnames'

import Modal, { Size } from 'components/Modal'

import Supporter from 'images/icons/badges/supporter.svg'
import Advocate from 'images/icons/badges/advocate.svg'
import Volunteer from 'images/icons/badges/volunteer.svg'
import Delegate from 'images/icons/badges/delegate.svg'
import ConversationStarter from 'images/icons/badges/conversation_starter.svg'
import ThoughtLeader from 'images/icons/badges/thought_leader.svg'
import Connector from 'images/icons/badges/connector.svg'
import Ambassador from 'images/icons/badges/ambassador.svg'
import CommunityLeader from 'images/icons/badges/community_leader.svg'
import IntersectionalLeader from 'images/icons/badges/intersectional_leader.svg'
import linkedinLogo from 'images/icons/linkedin_14.png'

import type { TBadge } from 'types'

type TBadgeInfo = {
  title: string
  description: string
  Icon: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  url: string
}

type TBadgesInfo = {
  [key in TBadge]: TBadgeInfo
}

const badgesInfo: TBadgesInfo = {
  supporter: {
    title: 'Supporter: 5 Events',
    description: 'You earned this badge by registering for 5 different events.',
    Icon: Supporter,
    url: 'https://www.mindrglobal.com/badges/supporter',
  },
  advocate: {
    title: 'Advocate: 10 Events',
    description:
      'You earned this badge by registering for 10 different events.',
    Icon: Advocate,
    url: 'https://www.mindrglobal.com/badges/advocate',
  },
  conversation_starter: {
    title: 'Conversation Starter: 5 Suggestions',
    description: 'You earned this badge by submitting 5 suggestions to events.',
    Icon: ConversationStarter,
    url: 'https://www.mindrglobal.com/badges/conversation-starter',
  },
  thought_leader: {
    title: 'Thought Leader: 10 Suggestions',
    description:
      'You earned this badge by submitting 10 suggestions to events.',
    Icon: ThoughtLeader,
    url: 'https://www.mindrglobal.com/badges/thought-leader',
  },
  volunteer: {
    title: 'Volunteer: 5 Calls to Action',
    description:
      'You earned this badge by registering for 5 different calls to action.',
    Icon: Volunteer,
    url: 'https://www.mindrglobal.com/badges/volunteer',
  },
  delegate: {
    title: 'Delegate: 10 Calls to Action',
    description:
      'You earned this badge by registering for 10 different calls to action.',
    Icon: Delegate,
    url: 'https://www.mindrglobal.com/badges/delegate',
  },
  connector: {
    title: 'Connector: Invite Others to 5 Events or Calls to Action',
    description:
      'You earned this badge by inviting people to 5 events or calls to action.',
    Icon: Connector,
    url: 'https://www.mindrglobal.com/badges/connector',
  },
  ambassador: {
    title: 'Ambassador: Invite Others to 10 Events or Calls to Action',
    description:
      'You earned this badge by inviting people to 10 events or calls to action.',
    Icon: Ambassador,
    url: 'https://www.mindrglobal.com/badges/ambassador',
  },
  community_leader: {
    title: 'Community Leader: Lead a Community',
    description: 'You earned this badge by leading a community.',
    Icon: CommunityLeader,
    url: 'https://www.mindrglobal.com/badges/community-leader',
  },
  intersectional_leader: {
    title: 'Intersectional Leader: Lead 2 or More Communities',
    description: 'You earned this badge by leading two communities.',
    Icon: IntersectionalLeader,
    url: 'https://www.mindrglobal.com/badges/intersectional-leader',
  },
}

interface IShowBadgeModal {
  isOpen: boolean
  badgeName: TBadge | undefined
  achieved?: boolean
  seeMore?: boolean
  onClose: () => void
}

const ShowBadgeModal = ({
  isOpen,
  badgeName,
  achieved,
  seeMore,
  onClose,
}: IShowBadgeModal): React.ReactElement => {
  if (!badgeName) {
    return null
  }

  const { Icon, title, description, url } = badgesInfo[badgeName]

  return (
    <Modal
      isOpen={isOpen}
      size={Size.wide}
      title={`Congratulations, ${window.currentUser.first_name}, your hard work is being noticed.`}
      onRequestClose={onClose}
      onSave={onClose}
      submitButton={seeMore ? 'Close' : undefined}
      className="show-badge-modal">
      <div
        className={cx('badges-modal-body', 'mindr-modal-section', {
          'no-background': !achieved,
        })}>
        <Icon width="280" height="280" />
        <h2>{title}</h2>
        {achieved && (
          <>
            <p>{description}</p>
            <a
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                url,
              )}`}
              className="button secondary share-button"
              target="_blank"
              rel="noopener noreferrer">
              <img className="share-logo" src={linkedinLogo} />
              Share
            </a>
          </>
        )}
        {seeMore && (
          <a href="/dashboard/badges" className="link">
            See all of the other badges you can earn.
          </a>
        )}
      </div>
    </Modal>
  )
}

export default ShowBadgeModal
