import React from 'react'

import Pill, { PillColor } from 'components/Pill'

import { ConnectorStatus } from 'types'

interface ConnectorStatusPillProps {
  data: {
    status: ConnectorStatus | null
  }
}

export default function ConnectorStatusPill({
  data,
}: ConnectorStatusPillProps): React.ReactElement {
  const { status } = data

  switch (status) {
    case ConnectorStatus.Active:
      return (
        <Pill color={PillColor.BLUE} size="small" border>
          Active
        </Pill>
      )
    case ConnectorStatus.Failed:
      return (
        <Pill color={PillColor.RED} size="small" border>
          Failed
        </Pill>
      )
    default:
      return null
  }
}
