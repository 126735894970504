import { css } from '@emotion/react'
import Input from 'components/Input'
import Modal, { IModalProps, Size } from 'components/Modal'
import { Paragraph } from 'components/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { colors, spacings } from 'stylesheets/theme'
import { ICommunity } from 'types'

const modalCss = css({ '&.mindr-modal.small': { width: 696, maxWidth: 696 } })

const hrCss = css({
  marginTop: 2 * spacings.grid_gap_basis_num,
  marginBottom: 2 * spacings.grid_gap_basis_num,
  borderColor: 'transparent',
  borderBottomColor: colors.borders.gray,
})

const midTitleCss = css({
  fontSize: 15,
  lineHeight: '25px',
  marginBottom: spacings.grid_gap_basis_num,
})

const selectCss = css({ marginTop: spacings.grid_gap_basis_num, maxWidth: 400 })

interface INotifyParticipantsModalProps
  extends Omit<IModalProps, 'onSave' | 'onRequestClose' | 'children' | 'size'> {
  previouslyNotified?: boolean
  readOnly?: boolean
  count?: number
  onRequestClose: (fromModalX?: boolean) => void
  onSave: (e?: React.MouseEvent<HTMLButtonElement>) => void
  initialCohort: number
  possibleCohorts: Partial<ICommunity>[]
  onUpdateCohort: (cohortId: number) => void
}

export default function NotifyParticipantsModal({
  previouslyNotified = false,
  readOnly = false,
  count = 0,
  initialCohort,
  possibleCohorts,
  onUpdateCohort,
  ...props
}: INotifyParticipantsModalProps): JSX.Element {
  const fieldName = 'cohort'
  const [useCohort, setUseCohort] = useState<number>()

  useEffect(() => {
    setUseCohort(initialCohort)
  }, [initialCohort])

  const options = useMemo(() => {
    return possibleCohorts.map((cohort) => ({
      label: cohort.name,
      value: `${cohort.id}`,
    }))
  }, [possibleCohorts])

  const onChangeCohort = useCallback(
    (changes) => {
      setUseCohort(changes[fieldName])
      onUpdateCohort(changes[fieldName])
    },
    [possibleCohorts, onUpdateCohort],
  )

  return (
    <Modal
      css={modalCss}
      title={
        previouslyNotified ? 'Notify new matches' : 'Notify accepted matches'
      }
      size={Size.small}
      largeTitle
      isSubmitDisabled={!(readOnly || useCohort)}
      submitButton={'Confirm'}
      cancelButton={'Cancel'}
      {...props}>
      {previouslyNotified ? (
        <>
          <Paragraph>
            We currently have <b>{count}</b> matches with a &#39;Pending&#39;
            status who have been rematched but have not yet received their match
            email. Would you like to notify these participants? This action
            cannot be undone.
          </Paragraph>
          <hr css={hrCss} />
          <h3 css={midTitleCss}>Cohort</h3>
          <Input
            css={selectCss}
            type="select"
            hideName
            readOnly={readOnly}
            name={fieldName}
            options={options}
            value={`${useCohort}`}
          />
        </>
      ) : (
        <>
          <Paragraph>
            Once confirmed, participants will receive notifications of their
            matches and can review important documents to prepare for the
            sessions. Are you certain you want to proceed with notifying the
            participants? This action cannot be undone.
          </Paragraph>
          <hr css={hrCss} />
          <h3 css={midTitleCss}>Cohort</h3>
          <Paragraph>
            Please add an active cohort. If a specific cohort for this program
            hasn&apos;t been created, please create one in &apos;Cohort
            Management&apos; before notifying matches. An active cohort is
            required in this email to help our participants prepare for their
            upcoming sessions.
          </Paragraph>
          <Input
            css={selectCss}
            type="select"
            hideName
            readOnly={readOnly}
            name={fieldName}
            options={options}
            value={`${useCohort}`}
            onChange={onChangeCohort}
          />
        </>
      )}
    </Modal>
  )
}
