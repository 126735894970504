import Modal, { Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import { useMemo, useState } from 'react'
import { Paragraph } from 'components/Typography'
import { useMentorshipExchangeDetailsContext } from '../MentorshipExchangeDetailsContext'
import { IOption } from 'components/Inputs/Select'
import _ from 'lodash'
import Input from 'components/Input'
import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'
import { IBoostableObject } from 'types'

const specialModalCss = css({
  '&.small': {
    width: 696,
  },
})

const fieldCss = css({
  width: 272,
  '&.input-component-wrapper': {
    marginTop: 0,
  },
})

const fieldWrap = css({
  marginTop: spacings.grid_gap_basis_num * 2,
})

interface BoostEditModalProps {
  object: IBoostableObject
  isOpen?: boolean
  onClose: () => void
  onSave: (id: number, boostField: string) => void
}

export default function BoostEditModal({
  object,
  isOpen = false,
  onClose,
  onSave,
}: BoostEditModalProps): JSX.Element {
  const fieldName = 'new_boost'
  const { boostList } = useMentorshipExchangeDetailsContext()

  const possibleBoosts = useMemo(() => {
    return !!(object?.location ?? '')
      ? boostList
      : boostList.filter((item: IOption) => item.value !== 'important-location')
  }, [object])

  const [boostField, setBoostField] = useState<string>(
    object?.boost_field ?? '',
  )
  const applicantSelected = useMemo<string>(() => {
    const matched = _.cloneDeep(possibleBoosts)
      .filter(
        (option: IOption) =>
          option.value?.toLocaleLowerCase() ===
          (object?.user_boost_field?.toLocaleLowerCase() ?? ''),
      )
      .shift()
    return matched?.label ?? 'Any'
  }, [object])
  const current = useMemo<string>(() => {
    const matched = _.cloneDeep(possibleBoosts)
      .filter(
        (option: IOption) =>
          option.value?.toLocaleLowerCase() ===
          (object?.boost_field?.toLocaleLowerCase() ?? ''),
      )
      .shift()
    return matched?.label ?? 'Any'
  }, [object])

  const onChangeBoost = (input: Record<string, any>) => {
    setBoostField(input[fieldName])
  }
  const onSaveModal = () => {
    onSave(object.id ?? 0, boostField)
  }

  return (
    <Modal
      css={[modalStyle, specialModalCss]}
      isOpen={isOpen}
      size={Size.small}
      onRequestClose={onClose}
      onSave={onSaveModal}
      submitButton="Confirm"
      cancelButton="Cancel"
      title="Boost applicant">
      <Paragraph>
        Boosting an applicant in their preferred focus area helps streamlining
        our matching process. If they&apos;ve previously self-boosted, the new
        boost replaces their selection.
      </Paragraph>

      <div css={fieldWrap}>
        <Paragraph>Applicant&apos;s selected boost</Paragraph>
        <Input
          readOnly
          value={applicantSelected}
          name="current_boost"
          hideName
          css={fieldCss}
        />
      </div>

      <div css={fieldWrap}>
        <Paragraph>New boost</Paragraph>
        <Input
          type="select"
          defaultValue={current}
          name={fieldName}
          hideName
          options={possibleBoosts}
          onChange={onChangeBoost}
          css={fieldCss}
        />
      </div>
    </Modal>
  )
}
