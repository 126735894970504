import moment from 'moment'

import { serverUTCTimeFormat } from 'javascripts/general'
import { useMemo } from 'react'
import TableCellWrapper from './TableCellWrapper'

export enum DateTableCellVariant {
  DateUTC = 'date_utc',
  DateTimeUTC = 'datetime_utc',
  DateNoYear = 'date_no_year',
}

interface DateTableCellProps {
  variant: DateTableCellVariant
  value: string
  link?: string
  className?: string
}

const DateTableCell = ({
  variant,
  value,
  link,
  className,
}: DateTableCellProps): JSX.Element => {
  const dateString = useMemo(() => {
    if (!value) {
      return ''
    }

    switch (variant) {
      case DateTableCellVariant.DateUTC:
        return moment(
          value.replace('UTC', '+0000'),
          serverUTCTimeFormat,
        ).format('ddd, MMM Do, YYYY')
      case DateTableCellVariant.DateTimeUTC:
        return moment(
          value.replace('UTC', '+0000'),
          serverUTCTimeFormat,
        ).format('ddd, MMM Do, YYYY, h:mma')
      case DateTableCellVariant.DateNoYear:
        return moment((value as string).replace('UTC', '+0000')).format(
          'ddd, MMM Do, h:mma',
        )
      default:
        return value
    }
  }, [value, variant])

  return (
    <TableCellWrapper columnType={variant} link={link} className={className}>
      {dateString}
    </TableCellWrapper>
  )
}

export default DateTableCell
