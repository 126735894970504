import React from 'react'
import Card from 'components/Card'
import EmptyState from 'components/EmptyState'
import FileIcon from 'images/icons/file.svg'
import ChainIcon from 'images/icons/chain_45.svg'
import resourceEmptyStateImg from 'images/resource_empty_state.png'
import { Resource, ResourceType } from 'types'
import './RecommendedResources.scss'

interface RecommendedResourcesProps {
  resources: Resource<ResourceType>[]
  editUrl: string
}

/**
 * Card that contains a list of recommended resources set for the event / cta
 * a resource can either be a document or a link
 */
export default function RecommendedResources({
  resources = [],
  editUrl,
}: RecommendedResourcesProps): JSX.Element {
  return (
    <Card title="Recommended Resources">
      {!!resources.length ? (
        <>
          {resources.map((resource) => (
            <div key={resource.title} className="resource-wrapper">
              <a
                target="_blank"
                rel="noreferrer"
                title={resource.title}
                key={resource.title}
                className="link"
                href={resource.url}>
                {resource.document_data ? <FileIcon /> : <ChainIcon />}
                <span>{resource.title}</span>
              </a>
            </div>
          ))}
        </>
      ) : (
        <EmptyState
          imgUrl={resourceEmptyStateImg}
          actionNode={
            <a
              href={editUrl + '#show-resources-button'}
              className="button secondary small plus">
              add resources
            </a>
          }>
          <p>Increase engagement by adding relevant links and documents</p>
        </EmptyState>
      )}
    </Card>
  )
}
