import { css } from '@emotion/react'
import { colors } from 'stylesheets/theme'
import { FormFieldContainer, IFormFieldContainer } from 'components/Forms'

const applicationFormFieldContainerStyle = css({
  '&.input-component-wrapper': {
    width: '100%',
  },
  '&.input-component-wrapper .label': {
    fontWeight: 700,
    color: colors.text.text_5,
  },
})

export default function ApplicationFormFieldContainer(
  props: IFormFieldContainer,
): JSX.Element {
  return (
    <FormFieldContainer {...props} css={applicationFormFieldContainerStyle}>
      {props.children}
    </FormFieldContainer>
  )
}
