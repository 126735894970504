import { createContext } from 'react'
import { OrganizationSwitch } from 'components/HeaderNav/types'
import { Theme } from 'stylesheets/theme'

export type BrandingContextValue = {
  /**
   * Organization that this branding section is for
   */
  organization: OrganizationSwitch
  /**
   * A valid color that passes accessibility check
   */
  brandColor: string
  setBrandColor: (color: string) => void
  /**
   * Color that gets changed at onChange in color picker
   */
  color: string
  setColor: (color: string) => void
  /**
   * Theme that gets changed with brandColor changes
   */
  theme: Theme
  setTheme: (theme: Theme) => void
  /**
   * current Logo URL that gets uploaded for branding logo
   */
  logo: string
  setLogo: (logo: string) => void
  /**
   * Function that takes in a color hex code and returns either `light`, `dark` or `unknown` based on
   * accessibilty check
   * Theme.Unknown means it does not pass accessibility check
   * @param color
   * @returns Theme - Theme.Light, Theme.Dark, Theme.Unkonwn
   */
  findTheme: (color: string) => Theme
  /**
   * This gets changed whenever color changes
   */
  currentTheme: Theme
  /**
   * Sets theme whenever color changes
   */
  setCurrentTheme: (theme: Theme) => void
  /**
   * Theme that returns the bigger contrast ratio. This theme is reflected in the previews.
   * This does not mean the current color is valid. This is only used in the preview so that
   * an invalid color can still look decent in the preview banner with a higher contrasting logo/text.
   */
  currentHigherContrastTheme: Theme
}

export const BrandingContext = createContext<BrandingContextValue>({
  organization: null,
  brandColor: 'FFFFFF',
  setBrandColor: () => {},
  color: 'FFFFFF',
  setColor: () => {},
  theme: Theme.Light,
  setTheme: () => {},
  logo: null,
  setLogo: () => {},
  findTheme: () => Theme.Unknown,
  currentTheme: Theme.Light,
  setCurrentTheme: () => Theme.Light,
  currentHigherContrastTheme: Theme.Light,
})

export default BrandingContext
