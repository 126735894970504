import { css } from '@emotion/react'
import { colors } from 'stylesheets/theme'

interface ProgressBarProps {
  completed: number
  className?: string
}

const containerStyle = css({
  height: 14,
  width: '100%',
  backgroundColor: colors.borders.gray,
  borderRadius: 50,
  marginLeft: 50,
  marginRight: 50,
})

const barStyle = css({
  height: '100%',
  backgroundColor: colors.teal_dark,
  borderRadius: 'inherit',
  textAlign: 'right',
})

export default function ProgressBar({
  completed,
  className = '',
}: ProgressBarProps): JSX.Element {
  return (
    <div css={containerStyle} className={className}>
      <div
        css={[
          barStyle,
          css({
            width: `${completed}%`,
          }),
        ]}></div>
    </div>
  )
}
