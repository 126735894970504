import { ReactNode } from 'react'
import Container from 'components/Container'
import Button, { Variant as ButtonVariant } from 'components/Button'
import { Paragraph } from 'components/Typography'
import { spacings } from 'stylesheets/theme'
import { css } from '@emotion/react'

interface InfoTextProps {
  icon: ReactNode
  button?: boolean
  children: ReactNode
  className?: string
  onClick?: never
}

interface InfoTextButtonProps extends Omit<InfoTextProps, 'onClick'> {
  onClick?: () => void
}

const ContainerStyle = css({
  gap: `calc(${spacings.grid_gap_basis} / 2)`,
})

function Info({
  icon,
  className = '',
  children,
}: Omit<InfoTextProps, 'button'>) {
  return (
    <Container css={ContainerStyle}>
      {icon}
      <Paragraph bold className={className}>
        {children}
      </Paragraph>
    </Container>
  )
}

export default function InfoText({
  icon,
  button = false,
  children,
  className = '',
  onClick = () => {},
}: InfoTextProps | InfoTextButtonProps): JSX.Element {
  if (button) {
    return (
      <Button type="button" variant={ButtonVariant.UNSTYLED} onClick={onClick}>
        <Info icon={icon} className={className}>
          {children}
        </Info>
      </Button>
    )
  }

  return (
    <Info icon={icon} className={className}>
      {children}
    </Info>
  )
}
