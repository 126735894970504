import React from 'react'

import Pill, { PillColor } from 'components/Pill'

import { TStatus } from 'types'

interface RegistrationStatusPillProps {
  data: {
    status: TStatus | null
    status_pretty: string
  }
}

const RegistrationStatusPill = ({
  data,
}: RegistrationStatusPillProps): React.ReactElement => {
  const { status, status_pretty } = data

  switch (status) {
    case 'closed':
      return (
        <Pill color={PillColor.BLUE} size="small" border>
          Watilist open
        </Pill>
      )
    case 'deadline':
    case 'spots':
      return (
        <Pill color={PillColor.YELLOW} size="small" border>
          {status_pretty}
        </Pill>
      )
    default:
      return (
        <Pill color={PillColor.BLUE} size="small" border>
          Open
        </Pill>
      )
  }
}

export default RegistrationStatusPill
