import React from 'react'

//assets
import cx from 'classnames'
import ChevronLeftIcon from '../../images/icons/chevron_left.svg'
import ChevronRightIcon from '../../images/icons/chevron_right.svg'

interface ISlickArrow {
  isNext?: boolean
  currentSlide?: number
  slideCount?: number
  className?: string
}

export const SlickArrow: React.FC<ISlickArrow> = ({
  isNext,
  className = '',
  ...props
}) => {
  const clearedProps = {
    ...props,
  }

  delete clearedProps.currentSlide
  delete clearedProps.slideCount
  return (
    <button
      {...clearedProps}
      className={cx(
        `not-styled-button slick-arrow ${isNext ? 'slick-next' : 'slick-prev'}`,
        className,
      )}>
      {isNext ? (
        <ChevronRightIcon width="30px" height="30px" />
      ) : (
        <ChevronLeftIcon width="30px" height="30px" />
      )}
    </button>
  )
}
