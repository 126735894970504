import { spacings } from 'stylesheets/theme'
import Container from 'components/Container'
import Tag from './Tag'
import { Tag as ITag } from './types'
import ApplicationStepWrapper from './ApplicationStepWrapper'
import { useCallback, useContext, useEffect, useState } from 'react'
import ApplicationFormContext from './ApplicationFormContext'
import OptionalHeading from './OptionalHeading'
import { css } from '@emotion/react'

interface LifeExperiencesProps {
  lifeExperiences: ITag[]
  isGoal?: boolean
}

const defaultContainerStyle = css({
  gap: spacings.grid_gap_basis_num * 2,
  flexWrap: 'wrap',
})

const mobileContainerStyle = css({
  flexDirection: 'column',
  alignItems: 'flex-start',
})

export default function LifeExperiences({
  lifeExperiences,
  isGoal = false,
}: LifeExperiencesProps): JSX.Element {
  const { formData, isMobile } = useContext(ApplicationFormContext)
  const [tags, setTags] = useState<ITag[]>([])

  useEffect(() => {
    setTags(formData[`life-experience-${isGoal ? 'goals' : 'strengths'}`] || [])
  }, [setTags, formData])

  const isSelected = useCallback(
    (tag: ITag) => {
      return !!tags.find((curr_tag) => curr_tag.id === tag.id)
    },
    [tags],
  )

  const onTagClick = useCallback(
    (tag: ITag) => {
      if (isSelected(tag)) {
        setTags(tags.filter((curr_tag) => curr_tag.id !== tag.id))
      } else {
        setTags([...tags, tag])
      }
    },
    [isSelected, setTags, tags],
  )

  const containerStyle = [defaultContainerStyle]

  if (isMobile) {
    containerStyle.push(mobileContainerStyle)
  }

  return (
    <ApplicationStepWrapper
      title={
        <OptionalHeading>
          {isGoal
            ? 'Tell us about the life experiences you’re interested in learning more about'
            : 'Tell us about your life experiences'}
        </OptionalHeading>
      }
      description={
        isGoal
          ? 'If you would like, we welcome you to select up to 3 life experiences on which you would like to learn from others’ insights and perspectives'
          : 'Participants in this program are often interested in connecting with others who have had particular life experiences, and can provide related guidance and insights. If you would like, we welcome you to select life experiences below on which you could provide helpful perspectives.'
      }>
      <div>
        <h4
          css={{
            marginBottom: spacings.grid_gap_basis_num * 2,
          }}>
          Please select all that apply:
        </h4>
        <Container css={containerStyle}>
          {lifeExperiences.map((experienceTag) => (
            <Tag
              key={experienceTag.name}
              selected={isSelected(experienceTag)}
              onClick={() => onTagClick(experienceTag)}>
              {experienceTag.name}
            </Tag>
          ))}
          <input
            hidden
            name={`life-experience-${isGoal ? 'goals' : 'strengths'}`}
            value={JSON.stringify(tags)}></input>
        </Container>
      </div>
    </ApplicationStepWrapper>
  )
}
