import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'

interface ILabelProps {
  children: string | JSX.Element
}

const labelStyle = css({
  color: colors.text.text_3,
  fontFamily: 'Lato',
  fontSize: spacings.grid_gap_basis,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '16px',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
})

export default function Label({ children }: ILabelProps): JSX.Element {
  return <p css={labelStyle}>{children}</p>
}
