import { css } from '@emotion/react'
import Banner from 'components/Banner'
import Button, { ButtonComponentType, Variant } from 'components/Button'

import LightBulbIcon from 'images/icons/light_bulb.svg'
import { spacings } from 'stylesheets/theme'

const TEXT_HEIGHT = 24

const iconStyles = css({
  height: TEXT_HEIGHT,
  width: TEXT_HEIGHT,
  verticalAlign: 'top',
  marginRight: spacings.grid_gap_basis_num / 2,
})

const textStyles = css({
  display: 'inline-block',
  lineHeight: `${TEXT_HEIGHT}px`,
})

const SandboxBanner = (): JSX.Element => (
  <Banner>
    <div>
      <LightBulbIcon css={iconStyles} />
      <span css={textStyles}>Ready to build belonging in your workplace?</span>
    </div>
    <Button
      as={ButtonComponentType.LINK}
      variant={Variant.SECONDARY}
      href="https://www.mindrglobal.com/mindrconnect#form"
      target="_blank"
      rel="noopener"
      small>
      Get Started
    </Button>
  </Banner>
)

export default SandboxBanner
