import cx from 'classnames'
import { SectionTitle } from 'components/Typography'
import './SectionHeader.scss'

interface SectionHeaderProps {
  children: JSX.Element | string
  className?: string
}

export default function SectionHeader({
  children,
  className = '',
}: SectionHeaderProps): JSX.Element {
  return (
    <div className={cx('section-header', className)}>
      <div className="header-left">
        <SectionTitle>{children}</SectionTitle>
      </div>
    </div>
  )
}
