import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useMemo,
  useEffect,
} from 'react'
import axios from 'axios'
import { noop } from 'lodash'

import { IEventCall, TBadge, IUser } from 'types'

export enum ModalType {
  badges,
  markIncompleteConfirmation,
  markCompleteConfirmation,
}

interface ICallActionProps {
  call: IEventCall
  children: React.ReactNode
}

interface ICallActionState {
  loading: boolean
  completed: boolean
  newBadges: TBadge[]
  usersToInvite: IUser[]
  modalToShow: ModalType | null
  setUsersToInvite: (users: IUser[]) => void
  onMarkComplete: () => void
  onMarkIncomplete: () => void
  onConfirmMarkIncomplete: () => void
  onCancelMarkIncomplete: () => void
  onInvite: () => void
  onCloseConfirmation: () => void
  onCloseBadgeModal: () => void
}

const CallAction = createContext<ICallActionState>({
  loading: false,
  completed: false,
  newBadges: [],
  usersToInvite: [],
  modalToShow: null,
  setUsersToInvite: noop,
  onMarkComplete: noop,
  onMarkIncomplete: noop,
  onConfirmMarkIncomplete: noop,
  onCancelMarkIncomplete: noop,
  onInvite: noop,
  onCloseConfirmation: noop,
  onCloseBadgeModal: noop,
})

export const CallActionContext = ({
  call,
  children,
}: ICallActionProps): React.ReactElement => {
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(call.registered)
  const [newBadges, setNewBadges] = useState<TBadge[]>([])
  const [usersToInvite, setUsersToInvite] = useState<IUser[]>([])
  const [showConfirmation, setShowConfirmation] = useState(false)

  const baseUrl = `/calls/${call.id}`

  useEffect(() => {
    setCompleted(call.registered)
  }, [call])

  const onMarkComplete = useCallback(async () => {
    setLoading(true)

    try {
      const {
        data: { data },
      } = await axios.post(`${baseUrl}/register.json`, {
        authenticity_token: window.authenticity_token,
        // update_recurring_events,
      })
      setNewBadges(data.new_badges)
      setShowConfirmation(true)
    } catch (error) {
      const {
        data: { message },
      } = error.response.data
      window.flash(message || 'Something went wrong!', 'alert')
    } finally {
      setLoading(false)
    }
  }, [setNewBadges, setShowConfirmation])

  const onMarkIncomplete = useCallback(() => {
    setShowConfirmation(true)
  }, [setShowConfirmation])

  const onConfirmMarkIncomplete = useCallback(async () => {
    setLoading(true)
    try {
      const {
        data: {
          data: { message },
        },
      } = await axios.post(`${baseUrl}/unregister.json`, {
        authenticity_token: window.authenticity_token,
        // update_recurring_events,
      })

      if (message) {
        window.flash(message)
      }

      setShowConfirmation(false)
      setCompleted(false)
    } catch {
      window.flash('Something went wrong!', 'alert')
    } finally {
      setLoading(false)
    }
  }, [setCompleted])

  const onCancelMarkIncomplete = useCallback(() => {
    setShowConfirmation(false)
  }, [setShowConfirmation])

  const onCloseConfirmation = useCallback(() => {
    window.flash(`Success! You have now marked ${call.title} as complete.`)
    setShowConfirmation(false)
    setCompleted(true)
  }, [setCompleted, setShowConfirmation])

  const onInvite = useCallback(async () => {
    if (!usersToInvite.length) {
      onCloseConfirmation()
      return
    }

    setLoading(true)

    try {
      await axios.post(`${baseUrl}/share.json`, {
        authenticity_token: window.authenticity_token,
        user_ids: usersToInvite.map((user) => user.id),
      })
      window.flash(
        `Success! You have now marked ${call.title} as complete and invited ${usersToInvite.length} people.`,
      )
      setShowConfirmation(false)
      setCompleted(true)
    } catch {
      window.flash('Something went wrong!', 'alert')
    } finally {
      setLoading(false)
    }
  }, [
    call,
    usersToInvite,
    setShowConfirmation,
    setCompleted,
    onCloseConfirmation,
  ])

  const onCloseBadgeModal = useCallback(() => {
    setNewBadges(newBadges.slice(1))
  }, [newBadges, setNewBadges])

  const modalToShow: ModalType | null = useMemo(() => {
    if (newBadges.length > 0) {
      return ModalType.badges
    }

    if (showConfirmation) {
      if (completed) {
        return ModalType.markIncompleteConfirmation
      }

      return ModalType.markCompleteConfirmation
    }

    return null
  }, [showConfirmation, completed, newBadges])

  return (
    <CallAction.Provider
      value={{
        loading,
        completed,
        newBadges,
        modalToShow,
        usersToInvite,
        setUsersToInvite,
        onMarkComplete,
        onMarkIncomplete,
        onConfirmMarkIncomplete,
        onCancelMarkIncomplete,
        onInvite,
        onCloseConfirmation,
        onCloseBadgeModal,
      }}>
      {children}
    </CallAction.Provider>
  )
}

export const useCallActionContext = (): ICallActionState =>
  useContext(CallAction)
