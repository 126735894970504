import {
  SetStateAction,
  createContext,
  useCallback,
  useState,
  Dispatch,
  useMemo,
  useEffect,
} from 'react'
import {
  Audience,
  IAudienceCount,
  IAudienceCountsByType,
  TEventCallType,
} from 'types'
import _ from 'lodash'
import axios from 'axios'
import {
  ICsvHistoryTable,
  IFilterItem,
  filterOptions,
} from 'components/Audience/types'
import { getResultingFilterGroups } from 'components/Audience/helper'
import { IMainTableFilters, ITableData, ITableMeta } from 'components/Table'

export interface IAudiencesUrls {
  subscribersUrl?: string
  filtersUrl?: string
  estimateAudienceUrl?: string
  exampleCsvUrl?: string
  createAudienceCsvUrl?: string
  deleteAudienceImportUrl?: string
  addAdditionalAudienceUrl?: string
  inviteUsersUrl?: string
}

const defaultAudienceCountByType: IAudienceCountsByType = {
  filters: {
    audienceCount: {
      matched: 0,
      not_matched: 0,
    },
  },
  csvFile: {
    audienceCount: {
      matched: 0,
      not_matched: 0,
    },
  },
  sendToAll: {
    audienceCount: {
      matched: 0,
      not_matched: 0,
    },
  },
}

const defaultAudienceImportTable: ICsvHistoryTable = {
  tableData: {
    rows: [],
    paginator: {
      current_page: 0,
      total_pages: 0,
      total_entries: 0,
      per_page: 6,
    },
  },
  tableMeta: {
    url: '',
    columns: [
      {
        label: 'File Name',
        field: 'file_name',
        type: 'string',
      },
      {
        label: 'Date uploaded',
        field: 'date_uploaded',
        type: 'date_utc',
      },
      {
        label: 'Users',
        field: 'subscribers',
        type: 'number',
      },
      {
        label: 'Errors',
        field: 'errors',
        type: 'number',
      },
    ],
  },
  exampleCsvUrl: '',
}

interface IUpdateCsvHistoryTableResponse {
  import_id: number
  destroy_import_url: string
  import_history?: ICsvHistoryTable
}

interface IEstimateCountsResponse {
  data: {
    data: IAudienceCount
  }
}

export interface ISavedValues {
  isCsvImport?: boolean
  csvImportFile?: string
  filterGroups?: IFilterItem[][]
}

interface IUploadDetails {
  id: number
  deleteUrl: string
}

interface IChangedValues {
  filters?: boolean
  csvFile?: boolean
  audienceMethod?: boolean
}

export interface ShouldConfirmClose {
  value: boolean
  reasons: IChangedValues
}

const defaultShouldConfirmClose: ShouldConfirmClose = {
  value: false,
  reasons: {
    filters: false,
    csvFile: false,
    audienceMethod: false,
  },
}

const defaultUploadDetails: IUploadDetails = {
  id: 0,
  deleteUrl: '',
}

interface AudienceContextValues {
  object: TEventCallType | 'leadr'
  addNewFilterItem: (filterGroupIndex: number) => void
  removeFilterItem: (filterGroupIndex: number, itemIndex: number) => void
  addFilterGroup: () => void
  removeFilterGroup: (groupIndex: any) => void
  onFilterItemChange: (
    groupIndex: any,
    itemIndex: any,
    filterType: any,
    filterInputs: any,
  ) => void
  isCsvImport: boolean
  setCsvImport: Dispatch<SetStateAction<boolean>>
  csvImportFile: string | null
  uploadDetails: IUploadDetails
  setCsvImportFile: Dispatch<SetStateAction<string | null>>
  filterGroups: IFilterItem[][]
  resultingFilterGroups: IFilterItem[][]
  audienceImportTable: ICsvHistoryTable
  audienceCount: IAudienceCountsByType
  hasPositiveAudienceCount: () => boolean
  onSaveAudience?: () => ISavedValues
  resetFilterGroups: () => void
  onModalClose: () => void
  updateEstimatedAudienceCount: () => void
  initialAudience?: Audience
  isOpen: boolean
  removeCsvImport: (callback?: () => void) => void
  shouldConfirmClose: () => ShouldConfirmClose
  setOpenConfirmationModal: Dispatch<SetStateAction<boolean>>
  openConfirmationModal: boolean
  excludeInvited: boolean
  setExcludeInvited: Dispatch<SetStateAction<boolean>>
  onConfirmationRequestClose: (fromModalX?: boolean) => void
  setSaveAndConfirm: (saveAndConfirm: boolean) => void
  saveAndConfirm: boolean
  onConfirmationSave: () => void
  onSendToAllUsers: () => void
  sendToAllUsers?: boolean
  showZeroAudienceError: boolean
  setShowZeroAudienceError: Dispatch<SetStateAction<boolean>>
}

export const AudienceContext = createContext<AudienceContextValues>({
  object: 'event',
  addNewFilterItem: () => {},
  removeFilterItem: () => {},
  addFilterGroup: () => {},
  removeFilterGroup: () => {},
  onFilterItemChange: () => {},
  isCsvImport: false,
  setCsvImport: () => {},
  csvImportFile: null,
  uploadDetails: defaultUploadDetails,
  setCsvImportFile: () => {},
  filterGroups: [],
  resultingFilterGroups: [],
  audienceImportTable: defaultAudienceImportTable,
  audienceCount: defaultAudienceCountByType,
  hasPositiveAudienceCount: () => false,
  onSaveAudience: () => null,
  resetFilterGroups: () => {},
  onModalClose: () => {},
  updateEstimatedAudienceCount: () => {},
  isOpen: false,
  removeCsvImport: () => {},
  shouldConfirmClose: () => defaultShouldConfirmClose,
  setOpenConfirmationModal: () => {},
  openConfirmationModal: false,
  excludeInvited: true,
  setExcludeInvited: () => {},
  onConfirmationRequestClose: () => {},
  setSaveAndConfirm: () => {},
  saveAndConfirm: false,
  onConfirmationSave: () => {},
  onSendToAllUsers: () => {},
  sendToAllUsers: false,
  showZeroAudienceError: false,
  setShowZeroAudienceError: () => {},
})

interface IAudienceContextProviderProps {
  isOpen: boolean
  object: TEventCallType | 'leadr'
  objectId: number
  importHistory?: ICsvHistoryTable
  children: JSX.Element
  urls: IAudiencesUrls
  onClose?: () => void
  setActivityLogsTableData?: (data: ITableData) => void
  setActivityLogsTableMeta?: (meta: ITableMeta) => void
  setActivityLogsTableSelectedFilters?: (
    selectedFilters: IMainTableFilters,
  ) => void
}

export const initialFilterGroups: IFilterItem[][] = [
  [
    {
      filterType: null,
      filterInputs: [],
    },
  ],
]

export default function AudienceContextProvider({
  isOpen,
  object,
  objectId,
  importHistory,
  children,
  urls = {},
  onClose = () => {},
  setActivityLogsTableData,
  setActivityLogsTableMeta,
  setActivityLogsTableSelectedFilters,
}: IAudienceContextProviderProps): JSX.Element {
  // State of the switcher
  const [isCsvImport, setCsvImport] = useState(false)

  // File data for the csv file uploaded this modal session
  const [csvImportFile, setCsvImportFile] = useState<string | null>(null)

  // When a new file is uploaded, store the details so we can reference it for removal if needed.
  const [uploadDetails, setUploadDetails] =
    useState<IUploadDetails>(defaultUploadDetails)

  // The current state of the Advanced Filters grouping and values
  const [filterGroups, setFilterGroups] =
    useState<IFilterItem[][]>(initialFilterGroups)

  // The estimated audience counts, broken down by "audience mode"
  const [audienceCnt, setAudienceCnt] = useState<IAudienceCountsByType>(
    defaultAudienceCountByType,
  )

  const [showZeroAudienceError, setShowZeroAudienceError] =
    useState<boolean>(false)

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)

  // Show "save and confirm" in the confirmation modal as submit button text
  const [saveAndConfirm, setSaveAndConfirm] = useState<boolean>(false)

  // Are we excluding already invited users?
  const [excludeInvited, setExcludeInvited] = useState<boolean>(true)

  // Has "send to all users" been clicked?
  const [sendToAllUsers, setSendToAllUsers] = useState(false)
  const resultingFilterGroups = useMemo(() => {
    const out = getResultingFilterGroups(filterGroups)
    return out.length ? out : _.cloneDeep(initialFilterGroups)
  }, [filterGroups, initialFilterGroups])

  const areFiltersEmpty = useCallback(
    () => _.isEqual(filterGroups, initialFilterGroups),
    [filterGroups, resultingFilterGroups],
  )

  const [audienceImportTable, setAudienceImportTable] =
    useState<ICsvHistoryTable>({
      tableData:
        importHistory?.tableData ?? defaultAudienceImportTable.tableData,
      tableMeta:
        importHistory?.tableMeta ?? defaultAudienceImportTable.tableMeta,
      selectedFilters: importHistory?.selectedFilters ?? {},
      exampleCsvUrl: urls.exampleCsvUrl,
    })

  const addNewFilterItem = useCallback(
    (filterGroupIndex) => {
      let filterGroup = filterGroups[filterGroupIndex]
      filterGroup = [...filterGroup, { filterType: null, filterInputs: [] }]
      const newFilterGroups = [...filterGroups]
      newFilterGroups[filterGroupIndex] = filterGroup
      setFilterGroups(newFilterGroups)
    },
    [filterGroups, setFilterGroups],
  )

  const removeFilterItem = useCallback(
    (filterGroupIndex, itemIndex) => {
      const newFilterGroup = [...filterGroups[filterGroupIndex]]
      if (newFilterGroup.length > 1) {
        // If there are 2 or more items, remove the selected one.
        newFilterGroup.splice(itemIndex, 1)
      } else {
        // If there are 0 or 1 items, reset the first item to the default empty settings.
        newFilterGroup[0] = _.cloneDeep(initialFilterGroups[0][0])
      }
      const newFilterGroups = [...filterGroups]
      newFilterGroups[filterGroupIndex] = newFilterGroup
      setFilterGroups(newFilterGroups)
    },
    [filterGroups, setFilterGroups],
  )

  const addFilterGroup = useCallback(() => {
    setFilterGroups([...filterGroups, [{ filterType: null, filterInputs: [] }]])
  }, [filterGroups, setFilterGroups])

  const removeFilterGroup = useCallback(
    (groupIndex) => {
      const newFilterGroups = [...filterGroups]
      if (newFilterGroups.length > 1) {
        // If there are 2 or more groups, remove the selected group.
        newFilterGroups.splice(groupIndex, 1)
      } else {
        // If there are 0 or 1 groups, reset the first group to the default group.
        newFilterGroups[0] = _.cloneDeep(initialFilterGroups[0])
      }
      setFilterGroups(newFilterGroups)
    },
    [filterGroups, setFilterGroups],
  )

  const onFilterItemChange = useCallback(
    (groupIndex, itemIndex, filterType, filterInputs) => {
      const newFilterGroup = [...filterGroups[groupIndex]]
      newFilterGroup[itemIndex] = { filterType, filterInputs }
      const newFilterGroups = [...filterGroups]
      newFilterGroups[groupIndex] = newFilterGroup
      setFilterGroups(newFilterGroups)
    },
    [filterGroups, setFilterGroups],
  )

  const shouldConfirmClose = useCallback((): ShouldConfirmClose => {
    const result = _.cloneDeep(defaultShouldConfirmClose)

    const filters = initialFilterGroups
    const useFilters: IFilterItem[][] =
      typeof filters === 'string' ? JSON.parse(filters) : filters
    if (
      !_.isEmpty(resultingFilterGroups) &&
      !_.isEqual(useFilters, resultingFilterGroups)
    ) {
      result.value = true
      result.reasons.filters = true
    }

    if (!_.isEmpty(csvImportFile)) {
      result.value = true
      result.reasons.csvFile = true
    }

    return result
  }, [initialFilterGroups, resultingFilterGroups, csvImportFile, isCsvImport])

  const updateEstimatedAudienceCount = useCallback(
    _.debounce(() => {
      const usingCsv = isCsvImport
      const params: any = {
        is_csv_import: isCsvImport,
        send_to_all: sendToAllUsers,
        authenticity_token: window.authenticity_token,
        exclude_already_invited: excludeInvited,
      }
      if (usingCsv && !sendToAllUsers) {
        if (!!uploadDetails.id) {
          params.csv_import_id = uploadDetails.id
        } else {
          if (!!csvImportFile) {
            params.csv_import_file = csvImportFile
          }
        }
      } else if (!usingCsv && !sendToAllUsers) {
        // Only recalc if the filters have value.
        if (areFiltersEmpty()) {
          const counts = _.cloneDeep(audienceCnt)
          counts.filters = defaultAudienceCountByType.filters
          setAudienceCnt(counts)
          return
        }
        params.filters = resultingFilterGroups
      }
      if (
        usingCsv &&
        !params.csv_import_file &&
        !params.csv_import_id &&
        !sendToAllUsers
      ) {
        return
      }

      axios
        .post(`${urls.estimateAudienceUrl}`, params)
        .then(({ data: { data } }: IEstimateCountsResponse) => {
          const counts = _.cloneDeep(audienceCnt)
          if (sendToAllUsers) {
            counts.sendToAll = data
          } else if (usingCsv) {
            counts.csvFile = data
          } else {
            counts.filters = data
          }
          setAudienceCnt(counts)
        })
    }, 400),
    [
      resultingFilterGroups,
      csvImportFile,
      isCsvImport,
      uploadDetails,
      sendToAllUsers,
      excludeInvited,
    ],
  )
  useEffect(() => {
    updateEstimatedAudienceCount()
  }, [
    filterOptions,
    filterGroups,
    resultingFilterGroups,
    csvImportFile,
    isCsvImport,
    uploadDetails,
    sendToAllUsers,
    updateEstimatedAudienceCount,
    excludeInvited,
  ])

  const updateAudienceImportTableList = useCallback(() => {
    if (!isCsvImport || !csvImportFile) {
      return
    }

    let csv_import_id = null
    const csv_import_file = csvImportFile

    if (!!uploadDetails.id) {
      csv_import_id = uploadDetails.id
    }

    axios
      .post(`${urls.createAudienceCsvUrl}`, {
        authenticity_token: window.authenticity_token,
        csv_import_file,
        csv_import_id,
        exclude_already_invited: excludeInvited,
      })
      .then(
        ({
          data: { data },
        }: {
          data: { data: IUpdateCsvHistoryTableResponse }
        }) => {
          const newTable = { ...audienceImportTable }
          newTable.tableData =
            data?.import_history?.tableData ?? newTable.tableData
          newTable.tableMeta =
            data?.import_history?.tableMeta ?? newTable.tableMeta
          setAudienceImportTable(newTable)

          if (!_.isNil(data?.import_id)) {
            const newDetails = _.cloneDeep(defaultUploadDetails)
            newDetails.id = data?.import_id || 0

            if (!_.isNil(data?.destroy_import_url)) {
              newDetails.deleteUrl =
                data?.destroy_import_url || newDetails.deleteUrl
            }

            setUploadDetails(newDetails)
          }
        },
      )
  }, [csvImportFile, isCsvImport, uploadDetails, excludeInvited])
  useEffect(() => {
    updateAudienceImportTableList()
  }, [csvImportFile, isCsvImport, excludeInvited])

  const removeCsvImport = useCallback(() => {
    try {
      if (!uploadDetails.deleteUrl) {
        return
      }

      axios
        .delete(`${uploadDetails.deleteUrl}`, {
          params: {
            authenticity_token: window.authenticity_token,
            object_type: object,
            object_id: objectId || 0,
          },
        })
        .then(() => {
          setAudienceImportTable({
            tableData: defaultAudienceImportTable.tableData,
            tableMeta: defaultAudienceImportTable.tableMeta,
            selectedFilters: {},
            exampleCsvUrl: urls.exampleCsvUrl,
          })
          setUploadDetails(_.cloneDeep(defaultUploadDetails))
          setCsvImportFile(null)
        })
    } catch (e) {
      console.error('ERROR:', e)
    }
  }, [
    uploadDetails,
    setAudienceImportTable,
    setUploadDetails,
    setCsvImportFile,
    urls,
  ])

  const resetFilterGroups = useCallback(() => {
    setFilterGroups(_.cloneDeep(initialFilterGroups))
  }, [setFilterGroups, initialFilterGroups])

  const hasPositiveAudienceCount = useCallback(() => {
    if (isCsvImport) {
      return audienceCnt.csvFile.audienceCount.matched > 0
    } else {
      return audienceCnt.filters.audienceCount.matched > 0
    }
  }, [audienceCnt, isCsvImport, filterGroups, csvImportFile])

  /**
   * When user clicks on "save and confirm" on confirmation modal to actually send the invites to selected users
   */
  const onConfirmationSave = useCallback(() => {
    // Send the saved values to backend
    const params = {
      import_id: null,
      filters: null,
      authenticity_token: window.authenticity_token,
      invite_all: sendToAllUsers,
      exclude_already_invited: excludeInvited,
      is_csv_import: isCsvImport,
    }

    if (isCsvImport) {
      params['import_id'] = uploadDetails.id
    } else {
      params['filters'] = resultingFilterGroups
    }
    axios.post(`${urls.inviteUsersUrl}`, params).then(({ data: { data } }) => {
      const { status, activity_logs, invited_user_count } = data

      if (status == 'ok' && !!activity_logs) {
        setActivityLogsTableData(activity_logs.data)
        setActivityLogsTableMeta(activity_logs.meta)
        setActivityLogsTableSelectedFilters(activity_logs.selectedFilters)
        if (sendToAllUsers) {
          setSendToAllUsers(false)
        }
        // Reset everything
        setCsvImportFile(null)
        setUploadDetails(defaultUploadDetails)
        setAudienceCnt(defaultAudienceCountByType)
        setAudienceImportTable(defaultAudienceImportTable)
        setFilterGroups(_.cloneDeep(initialFilterGroups))
        setExcludeInvited(true)
        setSaveAndConfirm(false)
        setShowZeroAudienceError(false)
        setCsvImport(false)

        setOpenConfirmationModal(false)
        onClose()
        window.flash(
          `Successfully sent invitations to ${invited_user_count} users. Check out invitation details in the \‘Activity log.\’`,
          'success',
        )
      } else {
        window.flash('Something went wrong while sending invites', 'alert')
      }
    })
  }, [
    uploadDetails,
    resultingFilterGroups,
    isCsvImport,
    setOpenConfirmationModal,
    onClose,
    sendToAllUsers,
    excludeInvited,
  ])

  const onConfirmationRequestClose = useCallback(() => {
    if (sendToAllUsers) {
      setSendToAllUsers(false)
      setOpenConfirmationModal(false)
      return
    }

    if (saveAndConfirm) {
      resetFilterGroups()
      removeCsvImport()
      setOpenConfirmationModal(false)
      setUploadDetails(defaultUploadDetails)
      onClose()
    } else {
      setOpenConfirmationModal(false)
    }
  }, [saveAndConfirm, onClose, resetFilterGroups, sendToAllUsers])

  /**
   * Close invite leadr assessment modal
   */
  const onModalClose = useCallback(() => {
    setSaveAndConfirm(false)
    const shouldConfirm = shouldConfirmClose()
    if (hasPositiveAudienceCount() && shouldConfirm.value) {
      setSaveAndConfirm(true)
      setOpenConfirmationModal(true)
      return
    } else {
      onClose()
      removeCsvImport()
      resetFilterGroups()
    }
  }, [
    onClose,
    removeCsvImport,
    resetFilterGroups,
    setSaveAndConfirm,
    shouldConfirmClose,
    setOpenConfirmationModal,
    hasPositiveAudienceCount,
  ])

  /**
   * user has clicked on "send to all users"
   */
  const onSendToAllUsers = useCallback(() => {
    setSendToAllUsers(true)
    setOpenConfirmationModal(true)
  }, [setOpenConfirmationModal, setSendToAllUsers])

  return (
    <AudienceContext.Provider
      value={{
        object,
        addNewFilterItem,
        removeFilterItem,
        addFilterGroup,
        removeFilterGroup,
        onFilterItemChange,
        isCsvImport,
        setCsvImport,
        csvImportFile,
        uploadDetails,
        setCsvImportFile,
        filterGroups,
        audienceImportTable: audienceImportTable,
        audienceCount: audienceCnt,
        hasPositiveAudienceCount,
        resultingFilterGroups,
        resetFilterGroups,
        onModalClose,
        updateEstimatedAudienceCount,
        isOpen,
        removeCsvImport,
        shouldConfirmClose,
        setOpenConfirmationModal,
        openConfirmationModal,
        excludeInvited,
        setExcludeInvited,
        onConfirmationRequestClose,
        setSaveAndConfirm,
        saveAndConfirm,
        onConfirmationSave,
        onSendToAllUsers,
        sendToAllUsers,
        showZeroAudienceError,
        setShowZeroAudienceError,
      }}>
      {children}
    </AudienceContext.Provider>
  )
}
