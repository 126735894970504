import { useMemo, ReactElement } from 'react'
import { css } from '@emotion/react'

import EventCall from 'components/EventCall'
import Link from 'components/Link'
import HomeSection from 'components/HomeSection'
import { Caption, SectionTitle } from 'components/Typography'

import { spacings } from 'stylesheets/theme'
import { IconStrokeGrey } from 'stylesheets/mixins'

import EmptyState from './/EmptyState'

//assets
import CallToActionIcon from '../../images/icons/call_14.svg'
import CalendarIcon from '../../images/icons/event_14.svg'

//interfaces
import type { ITableData } from 'components/Table'
import type { IEventCall } from 'types'

interface IActivitiesProps {
  eventsData: ITableData
  callsData: ITableData
  subscriptions: boolean
}

const activitiesContainerStyle = css({
  display: 'grid',
  gridTemplateColumns: `calc(50% - ${spacings.grid_gap_basis}) calc(50% - ${spacings.grid_gap_basis})`,
  gridGap: spacings.grid_gap_basis_num * 2,
  marginTop: spacings.grid_gap_basis_num * 2,
  marginBottom: spacings.grid_gap_basis_num * 5,
})

const activitiesHeadersStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginBottom: 0,
})

const activitiesHeaderLeft = css({
  display: 'flex',
  alignItems: 'center',
})

const headerIconStyle = css({
  width: '20px',
  height: '20px',
  marginRight: spacings.grid_gap_basis_num / 2,
})

const viewAllLinkStyle = css({
  fontSize: '14px',
  marginLeft: spacings.grid_gap_basis_num / 2,
})

/**
 * The list of upcoming events and current ctas, to be shown on the Home page.
 * Designed so that the same number of event and cta cards are shown, regardless
 * of the total.
 *
 * The cards are organized into a grid so that the rows of cards are always
 * the same height and aligned.
 */
const Activities = ({
  eventsData,
  callsData,
  subscriptions,
}: IActivitiesProps): ReactElement => {
  const {
    numActivitiesToShow,
    numEventToShow,
    numCallsToShow,
    eventsToShow,
    callsToShow,
  } = useMemo(() => {
    const events = eventsData.rows || []
    const calls = callsData.rows || []
    const numActivitiesToShow = Math.min(events.length, calls.length) || 1

    const eventsToShow = events.slice(0, numActivitiesToShow)
    const callsToShow = calls.slice(0, numActivitiesToShow)
    return {
      numActivitiesToShow,
      numEventToShow: eventsToShow.length,
      numCallsToShow: callsToShow.length,
      eventsToShow: eventsToShow.length
        ? eventsToShow.map((item) => (
            <EventCall
              data={{ ...item.data, link: item.link } as IEventCall}
              key={`events-${item.data.id}`}
            />
          ))
        : [
            <EmptyState
              key="events-empty"
              type="events"
              subscriptions={subscriptions}
            />,
          ],
      callsToShow: callsToShow.length
        ? callsToShow.map((item) => (
            <EventCall
              data={{ ...item.data, link: item.link } as IEventCall}
              isCall
              key={`calls-${item.data.id}`}
            />
          ))
        : [
            <EmptyState
              key="calls-empty"
              type="calls"
              subscriptions={subscriptions}
            />,
          ],
    }
  }, [eventsData, callsData, subscriptions])

  return (
    <HomeSection css={activitiesContainerStyle}>
      <div css={activitiesHeadersStyle}>
        <div css={activitiesHeaderLeft}>
          <CalendarIcon css={[headerIconStyle, IconStrokeGrey]} />
          <SectionTitle>Upcoming Events</SectionTitle>
          <Link href="/events" css={viewAllLinkStyle}>
            View all
          </Link>
        </div>
        <Caption>
          {`(Showing ${numEventToShow} of ${eventsData.paginator.total_entries})`}
        </Caption>
      </div>
      <div css={activitiesHeadersStyle}>
        <div css={activitiesHeaderLeft}>
          <CallToActionIcon css={[headerIconStyle, IconStrokeGrey]} />
          <SectionTitle>Current Calls To Action</SectionTitle>
          <Link href="/calls" css={viewAllLinkStyle}>
            View all
          </Link>
        </div>
        <Caption>
          {`(Showing ${numCallsToShow} of ${callsData.paginator.total_entries})`}
        </Caption>
      </div>
      {Array.from(Array(numActivitiesToShow).keys()).map((index) => (
        <>
          {eventsToShow[index]}
          {callsToShow[index]}
        </>
      ))}
    </HomeSection>
  )
}

export default Activities
