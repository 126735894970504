import Input from 'components/Input'
import Modal, { Size, IModalProps } from 'components/Modal'
import { modalStyle } from '../modal-style'
import { useState } from 'react'

interface IRemoveModalProps
  extends Omit<
    IModalProps,
    'size' | 'children' | 'submitButton' | 'cancelButton' | 'title' | 'onSave'
  > {
  onSave?: (remove_note: string) => void
}

export default function RemoveModal({
  onSave,
  ...props
}: IRemoveModalProps): JSX.Element {
  const fieldName = 'remove_note'
  const [noteText, setNoteText] = useState('')

  return (
    <Modal
      {...props}
      css={modalStyle}
      size={Size.large}
      onSave={() => onSave(noteText)}
      submitButton="Confirm"
      cancelButton="Cancel"
      title="Remove this participant">
      <p>
        Removing this applicant means the applicant will not be matched or
        included in the upcoming cohort. This action can be undone any time
        before matching starts, by clicking on &quot;Restore&quot; in the
        &quot;Removed applicants&quot; table.
      </p>
      <Input
        type="textarea"
        label="Optional"
        name={fieldName}
        value={noteText}
        hideName
        caption="Please leave a note explaining the reason for removal, to assist the mentor matching review team."
        captionPosition="between"
        onChange={(value: { [fieldName]: string }) =>
          setNoteText(value[fieldName])
        }
      />
    </Modal>
  )
}
