import React from 'react'
import { css } from '@emotion/react'

import Table, {
  IMainTableFilters,
  ITableData,
  ITableMeta,
} from 'components/Table'
import Button, { ButtonComponentType } from 'components/Button'

import PlusIcon from 'images/icons/plus.svg'
import { IMentorshipExchange } from 'types'
import emptyStateImage from 'images/mentorship_programs_zero_state.png'

const headerButtonCss = css({
  paddingLeft: 20,
  paddingRight: 20,
  svg: {
    marginRight: 10,
    path: {
      stroke: 'currentColor',
    },
  },
})

const tableContainerCss = css({
  marginTop: '1rem',
  '.GojiCustomTable thead tr th:first-of-type': {
    width: 400,
  },
})

interface IMentorshipExchangesProps<T = Record<string, IMentorshipExchange>> {
  page_title: string
  new_link: string
  tableData: ITableData<T>
  tableMeta: ITableMeta
  title?: string
  searchPlaceholder?: string
  selectedFilters?: IMainTableFilters
}

const MentorshipExchanges: React.FC<IMentorshipExchangesProps> = <T,>({
  page_title = '',
  new_link = '',
  tableData,
  tableMeta,
  title = '',
  searchPlaceholder = '',
  selectedFilters = {},
}: IMentorshipExchangesProps<T>) => {
  return (
    <>
      <div className="header">
        <h1>{page_title}</h1>
        <Button
          as={ButtonComponentType.LINK}
          className="primary button"
          css={headerButtonCss}
          href={new_link}
          startIcon={<PlusIcon />}>
          New Program
        </Button>
      </div>

      <div className="mentorship-exchanges" css={tableContainerCss}>
        <Table
          title={title}
          tableData={tableData}
          tableMeta={tableMeta}
          searchPlaceholder={searchPlaceholder}
          selectedFilters={selectedFilters}
          emptyStateImgUrl={emptyStateImage}
          emptyStateChildren={
            <p css={{ fontWeight: 'normal' }}>
              Invite users to apply for the mentorship program by creating a new
              program
            </p>
          }
          emptyStateActionNode={
            <Button
              as={ButtonComponentType.LINK}
              className="secondary button"
              css={headerButtonCss}
              href={new_link}
              startIcon={<PlusIcon />}>
              Add Program
            </Button>
          }
        />
      </div>
    </>
  )
}

export default MentorshipExchanges
