import React, { useCallback, useEffect, useRef, useState } from 'react'
import produce, { enableES5 } from 'immer'
enableES5()
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'
import _ from 'lodash'

// styles
import './EventTimelines.scss'

// components
import Input from 'components/Input'

// icons
import DeleteIcon from '../../images/icons/delete.svg'

//utils
import { useAdditionalSectionRows } from 'javascripts/general'

interface IEventTimeline {
  fake_id: string | undefined
  id?: number
  name?: string
  formatted_time?: string
  formatted_time_to?: string
  show_on_invitation?: boolean
  notes?: string
  _destroy?: boolean
  errors?: Record<string, string[]>
}

interface IEventTimelines {
  initialValues: IEventTimeline[]
  rootEntity: string
}

const EventTimelines: React.FC<IEventTimelines> = ({
  initialValues,
  rootEntity,
}) => {
  const startTimeField = useRef<HTMLInputElement>(null)
  const [startTimeValue, setStartTimeValue] = useState(null)

  const onStartTimeChange = useCallback(
    (value) => {
      setStartTimeValue(value)
    },
    [setStartTimeValue],
  )

  useEffect(() => {
    if (!startTimeField.current) {
      const element = document.getElementById(
        'event_formatted_time',
      ) as HTMLInputElement
      startTimeField.current = element
      element.addEventListener('change', (event) => {
        onStartTimeChange((event.target as HTMLInputElement).value)
      })
      if (element.value) {
        setStartTimeValue(startTimeField.current.value)
      }
    }
  }, [onStartTimeChange, setStartTimeValue])

  const [eventTimelines, changeEventTimeline] = useState(
    initialValues.map((eventTimeline) => ({
      ...eventTimeline,
      fake_id: uuidv4(),
    })),
  )

  const [lastTimeValue, setLastTimeValue] = useState(null)

  useEffect(() => {
    const activeEventTimelines = _.reject(eventTimelines, { _destroy: true })
    if (activeEventTimelines.length) {
      const lastActiveItem = _.last(activeEventTimelines)
      setLastTimeValue(lastActiveItem.formatted_time_to)
    } else {
      setLastTimeValue(startTimeValue)
    }
  }, [startTimeValue, eventTimelines, setLastTimeValue])

  const componentRef = useRef()

  const addRow = () => {
    changeEventTimeline(
      produce((draft) => {
        draft.push({ fake_id: uuidv4(), formatted_time: lastTimeValue })
      }),
    )
  }

  const cleanRows = () => {
    changeEventTimeline(
      produce((draft) => {
        draft.forEach((row) => (row._destroy = true))
      }),
    )
  }

  const renderedComponentIndex = eventTimelines.findIndex(
    (eventTimeline) => !eventTimeline._destroy,
  )

  useAdditionalSectionRows(
    renderedComponentIndex !== -1,
    componentRef,
    addRow,
    cleanRows,
  )

  return (
    <div
      className="event-timelines additional-section-component"
      ref={componentRef}>
      {eventTimelines.map(
        (
          {
            id,
            fake_id,
            _destroy,
            name,
            show_on_invitation,
            formatted_time,
            formatted_time_to,
            notes,
            errors,
          },
          index,
        ) => {
          const onInputChange = (editedObj) => {
            changeEventTimeline(
              produce((draft) => {
                draft[index] = { ...draft[index], ...editedObj }
              }),
            )
          }

          const showLabels = renderedComponentIndex === index

          return (
            <div
              className={classNames(
                'event-timeline-row additional-section-row',
                {
                  hidden: _destroy,
                  first: showLabels,
                },
              )}
              key={id || fake_id}>
              {!!id && (
                <input
                  type="hidden"
                  name={`${rootEntity}[timelines_attributes][${index}][id]`}
                  value={id}
                />
              )}
              {_destroy && (
                <input
                  type="hidden"
                  name={`${rootEntity}[timelines_attributes][${index}][_destroy]`}
                  value="true"
                />
              )}
              <Input
                name="name"
                inputName={`${rootEntity}[timelines_attributes][${index}][name]`}
                value={name}
                label={showLabels && 'Item'}
                onChange={onInputChange}
                error={errors?.name?.join(', ')}
                wrapperClassName="one"
                maxLength="48"
              />
              <Input
                name="formatted_time"
                inputName={`${rootEntity}[timelines_attributes][${index}][formatted_time]`}
                inputTimeToName={`${rootEntity}[timelines_attributes][${index}][formatted_time_to]`}
                timeToName="formatted_time_to"
                value={formatted_time}
                timeToValue={formatted_time_to}
                type="timerange"
                label={showLabels && 'Time'}
                wrapperClassName="time-range two"
                onChange={onInputChange}
                timeFromError={errors?.formatted_time?.join(', ')}
                error={errors?.formatted_time_to?.join(', ')}
              />
              <Input
                name="show_on_invitation"
                inputName={`${rootEntity}[timelines_attributes][${index}][show_on_invitation]`}
                checked={show_on_invitation}
                type="checkbox"
                label={showLabels && 'Show on Invitation'}
                wrapperClassName="vertical-checkbox three"
                onChange={onInputChange}
              />
              <Input
                name="notes"
                inputName={`${rootEntity}[timelines_attributes][${index}][notes]`}
                value={notes}
                label={showLabels && 'Notes'}
                onChange={onInputChange}
                wrapperClassName="four"
              />
              <button
                className={classNames('not-styled-button delete', {
                  first: showLabels,
                })}
                type="button"
                onClick={() => {
                  onInputChange({ _destroy: true })
                }}>
                <DeleteIcon />
              </button>
            </div>
          )
        },
      )}
      <button className="link" type="button" onClick={addRow}>
        + Add Item
      </button>
    </div>
  )
}

export default EventTimelines
