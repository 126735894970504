import Modal, { IModalProps, Size } from 'components/Modal'
import { css } from '@emotion/react'
import { modalStyle } from './modal-style'
import { DatePicker, TimePicker, TimezonePicker } from 'components/Inputs'
import { useMemo, useState } from 'react'
import { spacings } from 'stylesheets/theme'
import { FormFieldContainer, FormFieldLabelVisibility } from 'components/Forms'
import moment from 'moment'

const MINDR_DATE_FORMAT = 'ddd, MMM Do, YYYY [at] h:mma zz'

interface IExtendDeadlineModalProps
  extends Omit<
    IModalProps,
    'size' | 'children' | 'submitButton' | 'cancelButton' | 'title' | 'onSave'
  > {
  originalDeadline: string
  onExtendDeadline: (deadlineDate, deadlineTime) => void
  timezone: string
  errors: string[]
}

const timeAndTimezoneFieldsWrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  gap: spacings.grid_gap_basis,
  width: 300,
})

export default function ExtendDeadlineModal({
  originalDeadline,
  onExtendDeadline,
  timezone,
  errors,
  ...props
}: IExtendDeadlineModalProps): JSX.Element {
  const [deadlineDate, setDeadlineDate] = useState(null)
  const [deadlineTime, setDeadlineTime] = useState(null)

  const tzAbbr = useMemo(() => {
    let abbr = ''

    for (const tz of window.timezones) {
      if (tz.tzid === timezone) {
        abbr = tz.abbreviation
        break
      }
    }

    return abbr
  }, [timezone])
  const newStringDate = `${deadlineDate} at ${deadlineTime} ${tzAbbr}`

  const origDate = useMemo(() => {
    return moment(originalDeadline, MINDR_DATE_FORMAT)
  }, [originalDeadline])
  const newDate = useMemo(() => {
    if (!deadlineDate || !deadlineTime) return origDate
    return moment(newStringDate, MINDR_DATE_FORMAT)
  }, [deadlineDate, deadlineTime])

  return (
    <Modal
      {...props}
      onSave={() => onExtendDeadline(deadlineDate, deadlineTime)}
      css={[
        modalStyle,
        css({
          '.input-component-wrapper': {
            maxWidth: 300,
          },
        }),
      ]}
      size={Size.large}
      submitButton="Extend Deadline"
      cancelButton="Cancel"
      title="Extend deadline"
      isSubmitDisabled={!(origDate.valueOf() < newDate.valueOf())}>
      <p>
        Please specify the date and time you want to extend this application to.
        An email will be sent to all of the potential candidates who have not
        yet applied, letting them know about this extended opportunity.
      </p>
      <p
        css={{
          marginTop: spacings.grid_gap_basis_num * 2,
        }}>
        The current deadline is {originalDeadline}.
      </p>
      <FormFieldContainer label="Date" fieldId="deadline_date">
        <DatePicker
          name="deadline_date"
          id="deadline_date"
          value={deadlineDate}
          onChange={(value) => setDeadlineDate(value['deadline_date'])}
          placeholder="Set date"
          errors={errors}
          autoComplete="off"
          css={{
            input: {
              width: 300,
            },
          }}
        />
      </FormFieldContainer>
      <div css={timeAndTimezoneFieldsWrapperStyle}>
        <FormFieldContainer fieldId="deadline_time" label="Time">
          <TimePicker
            css={{
              width: '100%',
              marginRight: spacings.grid_gap_basis,
            }}
            name="deadline_time"
            placeholder="Set time"
            value={deadlineTime}
            onChange={(value) => setDeadlineTime(value['deadline_time'])}
          />
        </FormFieldContainer>
        <FormFieldContainer
          fieldId="extend-deadline-timezone-field"
          label="Timezone"
          labelVisibility={FormFieldLabelVisibility.HIDE_KEEP_SPACE}>
          <TimezonePicker
            css={{
              width: '75px',
            }}
            name={'extend-deadline-timezone-field'}
            defaultTimezone={timezone}
            timezones={window.timezones}
            disabled
          />
        </FormFieldContainer>
      </div>
    </Modal>
  )
}
