import Input from 'components/Input'
import Modal, { Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import { useState } from 'react'
import { IMentorshipExchangeApplication } from 'types'

interface PrioritizeModalProps {
  object: IMentorshipExchangeApplication
  isOpen?: boolean
  onClose: (id: number) => void
  onSave: (id: number, note: string) => void
}

export default function PrioritizeModal({
  object,
  isOpen = false,
  onClose,
  onSave,
}: PrioritizeModalProps): JSX.Element {
  const fieldName = 'prioritize_note'
  const [noteText, setNoteText] = useState('')
  const onChangeText = (value: { [fieldName]: string }) => {
    setNoteText(value[fieldName])
  }
  const onCloseModal = () => {
    onClose(object?.id ?? 0)
    setNoteText('')
  }
  const onSaveModal = () => {
    onSave(object?.id ?? 0, noteText)
    setNoteText('')
  }
  return (
    <Modal
      css={modalStyle}
      isOpen={isOpen}
      size={Size.large}
      onRequestClose={onCloseModal}
      onSave={onSaveModal}
      submitButton="Confirm"
      cancelButton="Cancel"
      title="Prioritize this applicant">
      <p>
        Prioritizing this applicant means the applicant will be among the
        earlier candidates to be matched, in an effort to ensure the highest
        possible quality match. This action can be undone any time before
        matching starts, by clicking the star in the &quot;Eligible
        Applicants&quot; table.
      </p>
      <Input
        type="textarea"
        label="Optional"
        name={fieldName}
        value={noteText}
        hideName
        caption="Please leave a note explaining the reason for prioritization, to assist the mentor matching review team."
        captionPosition="between"
        onChange={onChangeText}
      />
    </Modal>
  )
}
