import React from 'react'
import cx from 'classnames'

//styles
import './EmptyPlaceholder.scss'

//interfaces
interface IEmptyPlaceholderProps {
  text: string
  link?: {
    href: string
    label: string
  }
  className?: string
}

const EmptyPlaceholder: React.FC<IEmptyPlaceholderProps> = ({
  text,
  link,
  className,
}) => {
  return (
    <div className={cx('empty-root', className)}>
      <p className="caption">{text}</p>
      {link && (
        <div className="button-wrapper">
          <a href={link.href} className="button secondary" tabIndex={0}>
            {link.label || link.href}
          </a>
        </div>
      )}
    </div>
  )
}

export default EmptyPlaceholder
