import LeadrIndexReportComponent from './LeadrIndexReport.component'
import { LeadrIndexReportContextProvider } from './LeadrIndexReport.context'
import { ILeadrIndexReportProps } from './LeadrIndexReport.types'

export default function LeadrIndexReport({
  urls,
  strings,
  archetypes,
  assessmentDetails,
  assessmentResults,
  archetypeDetails,
}: ILeadrIndexReportProps): JSX.Element {
  return (
    <LeadrIndexReportContextProvider
      urls={urls}
      strings={strings}
      archetypes={archetypes}
      assessmentDetails={assessmentDetails}
      assessmentResults={assessmentResults}
      archetypeDetails={archetypeDetails}>
      <LeadrIndexReportComponent></LeadrIndexReportComponent>
    </LeadrIndexReportContextProvider>
  )
}
