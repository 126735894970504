import React from 'react'
import classNames from 'classnames'

// import { TUserCounters } from 'state/Users'

interface IMembershipFiltersProps {
  totalTitle: string
  newSubscribers: boolean
  updateNewSubscribersFilter: (newSubscribers: boolean) => void
  counters: { [counterKey: string]: number }
  className?: string
}

const MembershipTabs: React.FC<IMembershipFiltersProps> = ({
  totalTitle,
  newSubscribers,
  updateNewSubscribersFilter,
  counters,
  className,
}) => (
  <div className={`membership-tabs ${className}`}>
    <>
      <button
        className={classNames('info-panel not-styled-button', {
          selected: !newSubscribers,
        })}
        onClick={() => {
          updateNewSubscribersFilter(false)
        }}>
        <h1>{counters.total}</h1>
        <p className="caption">{totalTitle}</p>
      </button>
      <button
        className={classNames('info-panel not-styled-button', {
          selected: newSubscribers,
        })}
        onClick={() => {
          updateNewSubscribersFilter(true)
        }}>
        <h1>{counters.new}</h1>
        <p className="caption">New in Past 2 Weeks</p>
      </button>
    </>
  </div>
)

export default MembershipTabs
