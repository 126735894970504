import Input from 'components/Input'
import Modal, { Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import { useEffect, useState } from 'react'
import Pill, { PillColor } from 'components/Pill'
import { ITruncatedMentorshipApplication } from '../types'

interface RemoveNoteModalProps {
  object: ITruncatedMentorshipApplication
  isOpen?: boolean
  onClose: (id: number) => void
  onSave: (id: number, note: string) => void
}

export default function RemoveNoteModal({
  object,
  isOpen = false,
  onClose,
  onSave,
}: RemoveNoteModalProps): JSX.Element {
  const fieldName = 'remove_note'
  const [noteText, setNoteText] = useState(object?.removed_note ?? '')
  useEffect(() => {
    setNoteText(object?.removed_note ?? '')
  }, [object?.removed_note])
  const onChangeText = (value: { [fieldName]: string }) => {
    setNoteText(value[fieldName])
  }
  const onCloseModal = () => {
    onClose(object?.id ?? 0)
    setNoteText('')
  }
  const onSaveModal = () => {
    onSave(object?.id ?? 0, noteText)
    setNoteText('')
  }
  const initiatorPill = object?.removed_by?.full_name ? (
    <Pill
      size="large"
      color={PillColor.GREEN}
      light
      textTransform={false}
      bold={false}>
      Removed by {object?.removed_by?.full_name}
    </Pill>
  ) : null
  return (
    <Modal
      css={modalStyle}
      isOpen={isOpen}
      actionRowLeftElement={initiatorPill}
      size={Size.large}
      onRequestClose={onCloseModal}
      onSave={onSaveModal}
      submitButton="Save"
      cancelButton="Cancel"
      title="Removal notes">
      <Input
        type="textarea"
        name={fieldName}
        value={noteText}
        hideName
        onChange={onChangeText}
      />
    </Modal>
  )
}
