import React, { InputHTMLAttributes } from 'react'

interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  controlName: string
  defaultChecked?: boolean
  error?: string
  uncheckedText?: string | null
  checkedText?: string | null
}

/**
 * Renders a toggle with Yes / No
 */
export default function Switch({
  controlName,
  defaultChecked,
  error,
  uncheckedText = 'No',
  checkedText = 'Yes',
  ...inputProps
}: SwitchProps): JSX.Element {
  return (
    <div className="switch-wrapper">
      {uncheckedText && <p className="off">{uncheckedText}</p>}
      <div className="input-component-wrapper switch-component-wrapper">
        <label className="input-wrapper" htmlFor={controlName} tabIndex={0}>
          <label className="switch" tabIndex={0}>
            <input name={controlName} value={0} type="hidden" />
            <input
              {...inputProps}
              name={controlName}
              defaultChecked={defaultChecked}
              type="checkbox"
              tabIndex={-1}></input>
            <span className="back" />
            <i className="indicator" />
          </label>
        </label>
        {error && <span className="validation-error-message">{error}</span>}
      </div>
      {checkedText && <p className="on">Yes</p>}
    </div>
  )
}
