import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from '../modal-style'
import Input from 'components/Input'
import { IOption } from 'components/Inputs/Select'
import { FormFieldsRow } from 'components/Forms'
import { useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'

const fieldCss = css({
  width: '50%',
})

interface IParticipantNames {
  participant_1?: string
  participant_1_location?: string
  participant_2?: string
  participant_2_location?: string
  boost_1?: string
  boost_2?: string
}
interface IRejectModalProps
  extends Omit<
    IModalProps,
    'size' | 'children' | 'submitButton' | 'cancelButton' | 'title' | 'onSave'
  > {
  possibleBoostOptions: IOption[]
  participantNames: IParticipantNames
  onReject: (params: Record<string, unknown>) => void
}

export default function RejectConfirmationModal({
  ...props
}: IRejectModalProps): JSX.Element {
  const [boosts, setBoosts] = useState<Record<string, unknown>>({
    partner1Boost: '',
    partner2Boost: '',
  })
  useEffect(() => {
    setBoosts({
      partner1Boost: props?.participantNames?.boost_1 ?? '',
      partner2Boost: props?.participantNames?.boost_2 ?? '',
    })
  }, [props.participantNames])
  const updatePartner1Boost = (inputData: Record<string, unknown>) => {
    setBoosts({ ...boosts, ...inputData })
  }
  const updatePartner2Boost = (inputData: Record<string, unknown>) => {
    setBoosts({ ...boosts, ...inputData })
  }

  const onSave = () => {
    props.onReject(boosts)
  }

  const participant1Name = useMemo(() => {
    return props?.participantNames?.participant_1 ?? 'Partner #1'
  }, [props.participantNames])
  const participant2Name = useMemo(() => {
    return props?.participantNames?.participant_2 ?? 'Partner #2'
  }, [props.participantNames])

  const p1Options = props.participantNames.participant_1_location
    ? props.possibleBoostOptions
    : props.possibleBoostOptions.filter(
        (value) => value.value != 'important-location',
      )

  const p2Options = props.participantNames.participant_2_location
    ? props.possibleBoostOptions
    : props.possibleBoostOptions.filter(
        (value) => value.value != 'important-location',
      )

  return (
    <Modal
      {...props}
      onSave={onSave}
      css={modalStyle}
      size={Size.large}
      submitButton="Reject match"
      cancelButton="Cancel"
      title="Reject this match">
      <p>
        Are you sure you want to reject this match? This will separate the
        participants, and the action cannot be undone. These participants can
        then be rematched with other participants in the &quot;Not yet
        matched&quot; table.
      </p>

      <FormFieldsRow>
        <div css={fieldCss}>
          <Input
            type="select"
            options={p1Options}
            label={`${participant1Name} Boost`}
            name="partner1Boost"
            value={boosts.partner1Boost.toString()}
            onChange={updatePartner1Boost}
          />
        </div>
        <div css={fieldCss}>
          <Input
            type="select"
            options={p2Options}
            label={`${participant2Name} Boost`}
            name="partner2Boost"
            value={boosts.partner2Boost.toString()}
            onChange={updatePartner2Boost}
          />
        </div>
      </FormFieldsRow>
    </Modal>
  )
}
