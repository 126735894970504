import { css } from '@emotion/react'
import { Paragraph } from 'components/Typography'
import { boxShadow, colors, spacings } from 'stylesheets/theme'
import Container from 'components/Container/Container'
import Avatar from 'components/Avatar'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import { ITeamMember } from 'types'
import MailIcon from 'images/icons/mail.svg'

const deafultLeaderContainerStyle = css({
  minHeight: 144,
  height: '100%',
  margin: '0 12px 7px',
  padding: '16px 16px',
  backgroundColor: colors.backgrounds.white,
  boxShadow: boxShadow.container,
  alignItems: 'flex-start',
  borderRadius: spacings.radius,

  // Fixes for drop shadow alignments
  overflowY: 'hidden',
  marginBottom: 2,
  position: 'relative',
  left: -1,
})

const LINE_HEIGHT = 25
const LINES = 3
const paragraphStyle = css({
  '--lines': LINES,
  maxHeight: LINE_HEIGHT * LINES, // calc() does not work here for some reason
  '-webkit-line-clamp': 'var(--lines)',
  '-webkit-box-orient': 'vertical',
  overflowY: 'hidden',
})

const mobileLeaderContainerStyle = css({
  width: `calc(100% - ${spacings.grid_gap_basis})`,
  margin: '0 0 7px',
  height: 200,
})

const cardWrapperStyle = css({
  padding: '0 12px 7px',
})

interface LeaderCardProps {
  isMobile?: boolean
  leader: ITeamMember
  className?: string
}

export default function LeaderCard({
  isMobile = false,
  leader,
  className = '',
}: LeaderCardProps): JSX.Element {
  return (
    <div css={cardWrapperStyle}>
      <Container
        direction="column"
        key={leader.user.full_name}
        css={[
          deafultLeaderContainerStyle,
          isMobile && mobileLeaderContainerStyle,
        ]}
        className={className}>
        <Container
          css={{
            alignItems: 'flex-start',
          }}>
          <Avatar
            size={60}
            avatarUrl={leader.avatar_url}
            userInitial={leader.initial}
          />
          <Container
            css={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginLeft: spacings.grid_gap_basis,
              gap: 2,
            }}>
            <h3
              css={{
                color: colors.text.text_5,
              }}>
              {leader.user.full_name}
            </h3>
            {leader.user.pronouns && (
              <Paragraph css={paragraphStyle}>{leader.user.pronouns}</Paragraph>
            )}
            {leader.role && (
              <Paragraph css={paragraphStyle}>{leader.role}</Paragraph>
            )}
          </Container>
        </Container>
        <Button
          variant={Variant.LINK}
          as={ButtonComponentType.LINK}
          href={`mailto:${leader.user.email}`}
          startIcon={
            <MailIcon
              css={{
                width: 10,
                height: 14,
                marginRight: spacings.grid_gap_basis_num / 2,
                path: {
                  fill: colors.blue,
                },
              }}
            />
          }
          css={{
            marginLeft: 80,
          }}>
          Email
        </Button>
      </Container>
    </div>
  )
}
