import { useContext } from 'react'
import ApplicationStepWrapper from './ApplicationStepWrapper'
import Input from 'components/Input'
import ApplicationFormContext from './ApplicationFormContext'

interface AdditionalDetailsProps {
  title?: string | React.ReactNode
  name?: string
}

export default function AdditionalDetails({
  title,
  name,
}: AdditionalDetailsProps): JSX.Element {
  const { formData } = useContext(ApplicationFormContext)
  return (
    <ApplicationStepWrapper title={title}>
      <Input
        type="textarea"
        name={name}
        defaultValue={formData[name] || null}
        css={{
          resize: 'none',
        }}
      />
    </ApplicationStepWrapper>
  )
}
