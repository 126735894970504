import { createContext } from 'react'

interface Step {
  title: string
  description?: string
  contentNode: JSX.Element
}

export type ApplicationFormContextValue = {
  orgName: string
  programName: string
  steps: Step[]
  currentStep: number
  maxSteps: number
  updateRawFormData: (formData: FormData) => any
  setStrengthCategories: (strengths: any[]) => void
  setGoalCategories: (goals: any[]) => void
  leadrCharacteristics: any[]
  incrementStep: () => void
  onBackStep: () => void
  formData: any
  submitApplicationUrl: string
  returnHomeUrl: string
  setErrors: (errors: Record<string, string[]>) => void
  getErrors: () => Record<string, string[]>
  isMobile: boolean
  loadMangerOptions: (
    inputValue: string,
    callback: (options: any) => void,
  ) => void
}

export const ApplicationFormContext =
  createContext<ApplicationFormContextValue>({
    orgName: '',
    programName: '',
    steps: [],
    currentStep: 0,
    maxSteps: 0,
    updateRawFormData: () => {},
    setStrengthCategories: () => {},
    setGoalCategories: () => {},
    leadrCharacteristics: [],
    incrementStep: () => {},
    onBackStep: () => {},
    formData: {},
    submitApplicationUrl: '',
    returnHomeUrl: '',
    setErrors: (errors: Record<string, string[]>) => {
      console.log('ERRORS:', errors)
    },
    getErrors: () => {
      return {}
    },
    isMobile: false,
    loadMangerOptions: () => {},
  })

export default ApplicationFormContext
