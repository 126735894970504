import { useState, useEffect } from 'react'
import Card from 'components/Card'
import Table, {
  IMainTableFilters,
  ITableData,
  ITableMeta,
} from 'components/Table'
import './ActivityLog.scss'
import { css } from '@emotion/react'

interface ActivityLogProps {
  tableData: ITableData
  tableMeta: ITableMeta
  selectedFilters?: IMainTableFilters
  subtitle?: string
}

interface IActivityLogFilters extends IMainTableFilters {
  'filter[exclude_subscriptions]': boolean
}

const activityLogSearchStyle = css({
  '.secondary-nodes, .secondary-nodes .search': {
    width: '100%',
  },
})

/**
 * Card on activity overview page to view activity logs for event
 * Activities including edit, published, reminder sent...etc
 */
export default function ActivityLog({
  tableData,
  tableMeta,
  selectedFilters,
  subtitle,
}: ActivityLogProps): JSX.Element {
  const [modifiedMeta, setModifiedMeta] = useState(tableMeta)
  /**
   * Work around while this feature has not been enabled and we are still using the menu drop down to view edit log
   * https://mindr.atlassian.net/browse/MC-2478
   */
  useEffect(() => {
    const columns = tableMeta.columns
    if (columns) {
      const acted_at_col = {
        ...columns[2],
        type: 'date_no_year',
      }
      columns[2] = acted_at_col
    }
    setModifiedMeta({ ...tableMeta, searchable: true, columns })
  }, [tableMeta])
  return (
    <Card title="Activity Log" subtitle={subtitle}>
      <div css={activityLogSearchStyle} className="edit-log-card">
        <Table
          alwaysShowSearch={true}
          tableData={tableData}
          tableMeta={modifiedMeta}
          selectedFilters={
            {
              ...selectedFilters,
              'filter[exclude_subscriptions]': true,
            } as IActivityLogFilters
          }
        />
      </div>
    </Card>
  )
}
