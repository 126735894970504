import { spacings } from 'stylesheets/theme'
import { TMeetingMinute } from './types'
import { Caption, Paragraph } from 'components/Typography'
import { css } from '@emotion/react'

interface IMeetingMinuteItemProps {
  meetingMinute: TMeetingMinute
}

const itemHeader = css({
  display: 'flex',
  justifyContent: 'space-between',
})

const itemName = css({
  fontSize: '15px',
  lineHeight: '25px',
  fontWeight: 'normal',
})

const assignee = css({
  textTransform: 'uppercase',
  fontWeight: 700,
  fontSize: '13px',
  lineHeight: 25.0 / 13.0,
  letterSpacing: '0.5px',
})

const notes = css({
  marginTop: spacings.grid_gap_basis_num * 2,
})

const MeetingMinuteItem = ({
  meetingMinute,
}: IMeetingMinuteItemProps): JSX.Element => (
  <>
    <div css={itemHeader}>
      <h5 css={itemName}>{meetingMinute.name}</h5>
      <Caption css={assignee}>Assigned to {meetingMinute.full_name}</Caption>
    </div>
    {meetingMinute.notes && (
      <Paragraph css={notes}>{meetingMinute.notes}</Paragraph>
    )}
  </>
)

export default MeetingMinuteItem
