import { css } from '@emotion/react'
import { Paragraph } from 'components/Typography'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { colors, spacings } from 'stylesheets/theme'

const tagStyle = css({
  padding: `${spacings.grid_gap_basis} ${spacings.grid_gap_basis_num * 2}px`,
  borderRadius: spacings.tag_radius,
  border: `1px solid ${colors.blue}`,
  background: colors.backgrounds.white,
  p: { color: colors.blue },
})

const desktopTagHoverStyle = css({
  '&:hover': {
    background: colors.backgrounds.blue,
    p: { color: colors.blue },
  },
})

const selectedStyle = css({
  background: colors.blue,
  p: {
    color: colors.backgrounds.white,
  },
})

const mobileTagStyle = css({
  p: {
    textAlign: 'left',
  },
})

interface TagProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: JSX.Element | string
  selected?: boolean
}

export default function Tag({
  children,
  selected = false,
  ...props
}: TagProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const style = [tagStyle]

  if (isMobile) {
    style.push(mobileTagStyle)
  } else {
    style.push(desktopTagHoverStyle)
  }

  if (selected) {
    style.push(selectedStyle)
  }

  return (
    <button css={style} {...props} type="button">
      <Paragraph bold>{children}</Paragraph>
    </button>
  )
}
