import { css } from '@emotion/react'
import { ReactNode } from 'react'
import { colors, spacings } from 'stylesheets/theme'

interface IPaginatedListItemProps {
  children: ReactNode
  small?: boolean
  className?: string
}

const itemContainer = css({
  backgroundColor: colors.backgrounds.page,
  borderRadius: spacings.radius,
  padding: spacings.grid_gap_basis_num * 2,
  marginBottom: spacings.grid_gap_basis_num * 2,

  '&:last-child': {
    marginBottom: 0,
  },
})

const smallContainer = css({
  padding: spacings.grid_gap_basis_num / 2,
  marginBottom: spacings.grid_gap_basis_num,
})

const PaginatedListItem = ({
  children,
  small,
  className,
}: IPaginatedListItemProps): JSX.Element => (
  <div
    css={[itemContainer, small && smallContainer].filter(Boolean)}
    className={className}>
    {children}
  </div>
)

export default PaginatedListItem
