import React from 'react'
import Select, { IOption } from 'components/Inputs/Select'
import {
  ManagerResponse,
  managerResponseOptions,
  useMentorshipExchangeDetailsContext,
} from './MentorshipExchangeDetailsContext'

type TResponseOption = IOption & {
  response: string
}

interface ApplicantRowActionsProps {
  data: any //TODO: Change this
}

const PendingManagerApprovalRowActions: React.FC = ({
  data,
}: ApplicantRowActionsProps) => {
  const { onSetManagerApprovalResponse } = useMentorshipExchangeDetailsContext()
  return (
    <Select<TResponseOption>
      css={{
        label: {
          display: 'none',
        },
      }}
      key={`manager_approval_response-${data.id}`}
      id={`manager_approval_response-${data.id}`}
      name="manager_approval_response"
      value={managerResponseOptions[data.manager_approval_status || 0]}
      options={managerResponseOptions.map((response) => ({
        label: response,
        value: response,
        response: response,
      }))}
      styleOverrides={{
        control: {
          width: '158px',
        },
        menu: {
          width: '158px',
        },
      }}
      isSearchable={false}
      onChange={(option: Record<string, ManagerResponse>) => {
        onSetManagerApprovalResponse(
          data.id,
          managerResponseOptions.indexOf(option['manager_approval_response']),
        )
      }}
    />
  )
}

export default PendingManagerApprovalRowActions
