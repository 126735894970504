import React from 'react'

import './CommunitiesTiles.scss'

import Community from 'components/Community'
import { ITableData } from 'components/Table'
import type { ICommunity } from 'types'

interface ILoadMoreButton {
  onClick: () => void
}

export const LoadMoreButton: React.FC<ILoadMoreButton> = ({ onClick }) => (
  <div className="load-button">
    <button className="button primary" onClick={onClick}>
      LOAD MORE
    </button>
  </div>
)

interface ICommunitiesTiles {
  communitiesData: ITableData
  hideSubscribeButton?: boolean
}

const CommunitiesTiles: React.FC<ICommunitiesTiles> = ({
  communitiesData,
  hideSubscribeButton,
}) =>
  communitiesData.rows.length ? (
    <div className="communities-tiles">
      {communitiesData.rows.map((row) => (
        <Community
          community={{ ...row.data, link: row.link } as ICommunity}
          key={row.data.id as string}
          hideSubscribeButton={hideSubscribeButton}
        />
      ))}
    </div>
  ) : null

export default CommunitiesTiles
