import React from 'react'

import Pill, { PillColor } from 'components/Pill'

import { UserImportStatus } from 'types'

interface UserImportStatusPillProps {
  data: {
    status: UserImportStatus | null
  }
}

export default function UserImportStatusPill({
  data,
}: UserImportStatusPillProps): React.ReactElement {
  const { status } = data

  switch (status) {
    case UserImportStatus.Error:
      return (
        <Pill color={PillColor.RED} size="small" border>
          error
        </Pill>
      )
    case UserImportStatus.Completed:
      return (
        <Pill color={PillColor.BLUE} size="small" border>
          completed
        </Pill>
      )
    case UserImportStatus.In_Progress:
      return (
        <Pill color={PillColor.YELLOW} size="small" border>
          in progress
        </Pill>
      )
    default:
      return null
  }
}
