import { useContext } from 'react'
import ApplicationFormContext from './ApplicationFormContext'
import GetStartedPage from './GetStartedPage'
import ThankYouPage from './ThankYouPage'

interface StepProps {
  currentStep: number
}

export default function Step({ currentStep }: StepProps): JSX.Element {
  const {
    steps,
    maxSteps,
    orgName,
    programName,
    incrementStep,
    returnHomeUrl,
  } = useContext(ApplicationFormContext)

  if (currentStep === 0) {
    return (
      <GetStartedPage
        orgName={orgName}
        programName={programName}
        onGetStarted={incrementStep}
      />
    )
  }

  if (currentStep > maxSteps) {
    return <ThankYouPage homepageUrl={returnHomeUrl} key="thank-you" />
  }

  return <>{steps[currentStep - 1]}</>
}
