import { createContext } from 'react'
import { TCommunityType } from 'types'

export const contextTypes = ['community', 'organization'] as const

export type ContextType = (typeof contextTypes)[number]

export type NavbarContextValue = {
  collapsed: boolean
  contextType: ContextType | string
  isAdmin: boolean
  contextName?: string | null
  currentUserName?: string | null
  currentUserPronouns?: string | null
  currentUserTitle?: string | null
  logoUrl?: string
  titles: any
  navItems: any
  paths: any
  controllerName: string
  communityType: TCommunityType
}

export const NavbarContext = createContext<NavbarContextValue>({
  collapsed: false,
  contextType: 'community',
  isAdmin: false,
  contextName: null,
  currentUserName: null,
  currentUserPronouns: null,
  currentUserTitle: null,
  logoUrl: null,
  titles: {},
  navItems: {},
  paths: {},
  controllerName: '',
  communityType: null,
})

export default NavbarContext
