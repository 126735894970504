import Button, { ButtonComponentType, Variant } from 'components/Button'
import Container from 'components/Container'
import DotMenu from 'components/DotMenu/DotMenu'
import MembershipFilters from 'components/MembershipFilters'
import Table, { ITableProps } from 'components/Table'
import { PeopleWidget } from 'components/Widget'
import InquiriesWidget from 'components/Widget/InquiriesWidget'
import { useMemo, useState } from 'react'
import useUsersState from 'state/Users'
import { colors, spacings } from 'stylesheets/theme'
import EyeIcon from 'images/icons/eye.svg'
import LeadrAssessmentInviteModal from './LeadrAssessmentInviteModal'
import ActivityLogModal from 'components/MentorshipExchangeDetails/Modals/ActivityLogModal'
import Switch from 'components/Switch'
import _ from 'lodash'
import { css } from '@emotion/react'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'

export type TFilterOptions = {
  locations: {
    city: string
    state: string
  }[]
  titles: string[]
  departments: string[]
}

interface ILeadrIndexOverviewProps
  extends Omit<ITableProps, 'selectedFilters'> {
  counters: {
    participants: number
    retake_inquiries: number
  }
  tableFilters: ITableProps['selectedFilters']
  filters: TFilterOptions
  cumulativeReportUrl: string
  assessmentUrl: string
  uploadInviteCsvUrl: string
  // table data that contains no import history data, this is used to display an empty import history table in modal
  emptyImportHistoryTableData: ITableProps
  inviteUrl: string
  usersNotSubmittedAssessmentCount: number
  activityLogsTableProps: {
    data: ITableProps['tableData']
    meta: ITableProps['tableMeta']
    selectedFilters: ITableProps['selectedFilters']
  }
  exampleUrl: string
  urls: Record<string, string>
}

const narrowWidgetsStyle = css({
  gap: spacings.widget_gap,
  marginBottom: spacings.grid_gap_basis_num * 3,
  width: '100%',
  '> div.widget': {
    flexGrow: 1,
    maxWidth: `100%`,
    width: '100%',
  },
})
const fullWidgetsStyle = css({
  gap: spacings.widget_gap,
  marginBottom: spacings.grid_gap_basis_num * 3,
  width: '100%',
  '> div.widget': {
    flexGrow: 1,
    maxWidth: `calc((100% - ${spacings.widget_gap * 2}px) / 3)`,
  },
})

export default function LeadrIndexOverview({
  tableData: initialData,
  tableMeta: initialMeta,
  counters: initialCounters,
  filters,
  cumulativeReportUrl,
  assessmentUrl,
  activityLogsTableProps: {
    data: initialActivityLogsTableData,
    meta: initialActivityLogsTableMeta,
    selectedFilters: initialActivityLogsTableFilters,
  },
  urls,
}: ILeadrIndexOverviewProps): JSX.Element {
  const {
    data,
    meta,
    counters,
    filters: selectedFilters,
    tableFilters,
    loadPage,
    updateOrder,
    updateFilters,
  } = useUsersState({
    url: initialMeta.url,
    initialData,
    initialMeta,
    initialFilters: {},
    initialCounters,
  })

  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  const isNarrow = isMobile

  //TODO: Set activity logs after invites have been sent
  const [activityLogsTableData, setActivityLogsTableData] = useState(
    initialActivityLogsTableData,
  )
  //TODO: Set activity logs after invites have been sent
  const [activityLogsTableMeta, setActivityLogsTableMeta] = useState(
    initialActivityLogsTableMeta,
  )

  const [
    activityLogsTableSelectedFilters,
    setActivityLogsTableSelectedFilters,
  ] = useState(initialActivityLogsTableFilters)
  const [openInviteModal, setOpenInviteModal] = useState(false)
  const [openActivityLogModal, setOpenActivityLogModal] = useState(false)

  /**
   * Adds the selected filters to the cumulative report URL as query params
   */
  const cumulativeReportUrlWithParams = useMemo(() => {
    const url = new URL(cumulativeReportUrl, window.location.origin)
    url.search = new URLSearchParams({
      filters: JSON.stringify(selectedFilters),
    }).toString()
    return url.toString()
  }, [cumulativeReportUrl, selectedFilters])

  return (
    <Container
      direction="column"
      css={
        // Fixes page padding on mobile.
        isNarrow
          ? null
          : {
              padding: spacings.main_padding,
              alignItems: 'flex-start',
            }
      }>
      <Container
        direction={`${isNarrow ? 'column' : 'row'}`}
        alignment={`${isNarrow ? 'start' : 'center'}`}
        css={{
          justifyContent: 'space-between',
          width: '100%',
          marginBottom: spacings.grid_gap_basis_num * 3,
        }}>
        <h2>LEADR™ Index Overview</h2>
        <Container
          css={
            isNarrow
              ? { width: '100%', position: 'relative' }
              : { position: 'relative' }
          }
          direction={`${isNarrow ? 'column' : 'row'}`}
          alignment={`${isNarrow ? 'start' : 'center'}`}>
          <Button
            as={ButtonComponentType.LINK}
            target="_blank"
            variant={Variant.LINK}
            href={assessmentUrl}
            startIcon={
              <EyeIcon
                css={{
                  minWidth: 14,
                  minHeight: 14,
                  marginRight: spacings.grid_gap_basis_num / 2,
                  path: { fill: colors.links.blue },
                }}
              />
            }>
            LEADR™ Assessment preview
          </Button>
          <Button
            variant={Variant.PRIMARY}
            onClick={() => setOpenInviteModal(true)}>
            Send Invites
          </Button>
          <DotMenu
            css={isNarrow ? { right: 0, position: 'absolute' } : null}
            menuItems={[
              {
                label: 'View activity log',
                onClick: () => setOpenActivityLogModal(true),
              },
            ]}></DotMenu>
        </Container>
      </Container>
      <Container
        direction={`${isNarrow ? 'column' : 'row'}`}
        css={isNarrow ? narrowWidgetsStyle : fullWidgetsStyle}>
        <PeopleWidget
          numPeople={initialCounters.participants}
          title="Assessment participants"
        />
        <InquiriesWidget
          requestSize={initialCounters.retake_inquiries}
          title="Retake inquiries"
        />
      </Container>
      <MembershipFilters
        css={{
          width: '100%',
        }}
        hideSearch
        filters={selectedFilters}
        filterOptions={filters}
        updateFilters={updateFilters}
        showAllFilters
        currentCount={counters.participants}
        initialCount={initialCounters.participants}
        displayCount
        actionNode={
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.SECONDARY}
            href={cumulativeReportUrlWithParams}
            disabled={!counters.participants}
            css={{
              marginTop: 16,
            }}>
            View LEADR™ results
          </Button>
        }
      />
      <Table
        css={{
          width: '100%',
          // Fixes janky experience when sorting to few results.
          minHeight: isNarrow ? '100vh' : 0,
          // Makes the table controls stacked on mobile
          '.GojiCustomTable-header .section-header, .GojiCustomTable-header .section-header .secondary-nodes':
            isNarrow
              ? {
                  flexDirection: 'column',
                  alignItems: 'start',
                  gap: spacings.grid_gap_basis_num,
                }
              : { flexDirection: 'row' },
        }}
        title="Assessment participants"
        tableData={data}
        tableMeta={meta}
        onPageChange={loadPage}
        selectedFilters={tableFilters}
        onOrderChange={({ sort_col, sort_dir }) => {
          updateOrder(
            sort_col,
            sort_dir === 'asc' || sort_dir === 'desc' ? sort_dir : 'asc',
          )
        }}
        secondaryActionNode={() => (
          <Switch
            css={{
              gap: spacings.grid_gap_basis,
            }}
            controlName="inquiry_toggle"
            defaultChecked={false}
            uncheckedText="All"
            checkedText="Only with inquiries"
            onChange={(e) => {
              const onlyInquiries = e.target.checked
              const updatedFilters = _.cloneDeep(selectedFilters)
              updatedFilters.show = onlyInquiries ? 'inquiry' : null
              updateFilters(updatedFilters)
            }}
          />
        )}
      />
      <LeadrAssessmentInviteModal
        isOpen={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
        urls={urls}
        setActivityLogsTableData={setActivityLogsTableData}
        setActivityLogsTableMeta={setActivityLogsTableMeta}
        setActivityLogsTableSelectedFilters={
          setActivityLogsTableSelectedFilters
        }
      />
      <ActivityLogModal
        largeTitle
        css={{
          '&.small': {
            maxWidth: 696,
            width: 696,
            backgroundColor: colors.backgrounds.page,
          },
        }}
        isOpen={openActivityLogModal}
        onRequestClose={() => setOpenActivityLogModal(false)}
        onSave={() => setOpenActivityLogModal(false)}
        tableComponentProps={{
          tableData: activityLogsTableData,
          tableMeta: activityLogsTableMeta,
          selectedFilters: activityLogsTableSelectedFilters,
        }}
        cancelButton="close"
      />
    </Container>
  )
}
