import { css } from '@emotion/react'

import Widget from './Widget'

import TentativeIcon from 'images/icons/tentative.svg'

import { colors } from 'stylesheets/theme'

interface TentativeWidgetProps {
  numPeople: number
  title: string
}

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.backgrounds.orange,
  },
})

export default function TentativeWidget({
  numPeople,
  title,
}: TentativeWidgetProps): JSX.Element {
  return (
    <Widget
      icon={
        <TentativeIcon
          css={{
            width: 18,
            height: 18,
          }}
        />
      }
      number={numPeople}
      title={title}
      css={widgetStyle}
    />
  )
}
