import { IAudiencesUrls } from 'components/Audience/AudienceContext'
import AudienceModal from 'components/Audience/AudienceModal'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import Container from 'components/Container'
import { FormFieldContainer } from 'components/Forms'
import Input from 'components/Input'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { TEventCallType, AudienceNotificationScope, Audience } from 'types'
import EditIcon from 'images/icons/edit_with_underline.svg'
import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'
import Modal, { Size } from 'components/Modal'
import { Paragraph } from 'components/Typography'
import { IAudienceSizes, defaultAudienceSizes } from './EventCallPreferences'
import { ICsvHistoryTable } from 'components/Audience/types'

const editIconCss = css({
  marginRight: spacings.grid_gap_basis_num / 2,
  textDecoration: 'underline',
})

const confirmationModalCss = css({
  'body &.mindr-modal.small': {
    width: 696,
  },
})

export interface IAudienceNotificationsUpdateResponse {
  scope: AudienceNotificationScope
  count: number
}

interface IAudienceNotificationsProps {
  onUpdate: (value: IAudienceNotificationsUpdateResponse) => void
  audienceSize: IAudienceSizes
  initialAudience?: Audience
  importHistory?: ICsvHistoryTable
  object: TEventCallType
  objectId: number
  readOnly?: boolean
  urls?: IAudiencesUrls
}

export default function AudienceNotifications({
  onUpdate = () => {},
  audienceSize = defaultAudienceSizes,
  initialAudience,
  importHistory,
  object,
  objectId,
  readOnly = false,
  urls = {},
}: IAudienceNotificationsProps): JSX.Element {
  const [openAudienceModal, setOpenAudienceModal] = useState(false)
  const [audienceNotificationScope, setAudienceNotificationScope] = useState(
    initialAudience?.audience_scope ||
      AudienceNotificationScope.ALL_SUBSCRIBERS,
  )
  const [audienceCount, setAudienceCount] = useState<number>(
    audienceSize.current,
  )
  useEffect(() => {
    onUpdate({ scope: audienceNotificationScope, count: audienceCount })
  }, [audienceNotificationScope, audienceCount])

  const inputName = useMemo(() => `${object}[audience_notifications]`, [object])

  const onRadioButtonChange = useCallback(
    (inputData) => {
      if (inputData[inputName] == '') {
        setAudienceNotificationScope(AudienceNotificationScope.ALL_SUBSCRIBERS)
      } else {
        setAudienceNotificationScope(parseInt(inputData[inputName], 10))
      }

      if (
        inputData[inputName] == AudienceNotificationScope.SUBSET_SUBSCRIBERS
      ) {
        setOpenAudienceModal(true)
      }

      if (inputData[inputName] == AudienceNotificationScope.ALL_SUBSCRIBERS) {
        setAudienceCount(audienceSize.defaults.all)
      }
    },
    [audienceNotificationScope, openAudienceModal],
  )

  const [isNobodyConfirmationOpen, setIsNobodyConfirmationOpen] =
    useState<boolean>(false)
  const onNobodyButtonChange = useCallback(
    (inputData) => {
      if (inputData[inputName] == AudienceNotificationScope.NOBODY) {
        setIsNobodyConfirmationOpen(true)
        setAudienceCount(audienceSize.defaults.nobody)
      }
    },
    [audienceNotificationScope],
  )

  return (
    <FormFieldContainer
      label="Who should we notify?"
      fieldId={`${object}-audience_notifications`}>
      <Container
        key={`${object}-audience_notifications`}
        css={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          marginTop: 16,
        }}>
        <Input
          type="radio"
          name={inputName}
          value={AudienceNotificationScope.ALL_SUBSCRIBERS}
          label="All subscribers"
          checked={
            audienceNotificationScope ===
            AudienceNotificationScope.ALL_SUBSCRIBERS
          }
          onChange={onRadioButtonChange}
          readOnly={readOnly}
        />
        <Container
          css={{
            alignItems: 'baseline',
          }}>
          <Input
            type="radio"
            name={inputName}
            value={AudienceNotificationScope.SUBSET_SUBSCRIBERS}
            checked={
              audienceNotificationScope ===
              AudienceNotificationScope.SUBSET_SUBSCRIBERS
            }
            label="Only a subset of users"
            onChange={onRadioButtonChange}
            readOnly={readOnly}
          />
          {audienceNotificationScope ===
            AudienceNotificationScope.SUBSET_SUBSCRIBERS && (
            <Button
              as={ButtonComponentType.BUTTON}
              variant={Variant.LINK}
              type="Button"
              disabled={readOnly}
              onClick={() => {
                setOpenAudienceModal(true)
              }}>
              <EditIcon css={editIconCss} />
              Edit
            </Button>
          )}
        </Container>
        <Input
          type="radio"
          name={inputName}
          value={AudienceNotificationScope.NOBODY}
          checked={
            audienceNotificationScope === AudienceNotificationScope.NOBODY
          }
          label="Nobody"
          onChange={onNobodyButtonChange}
          readOnly={readOnly}
        />
      </Container>
      <AudienceModal
        object={object}
        objectId={objectId}
        isOpen={openAudienceModal}
        onClose={(hasFiltersOrCsv) => {
          if (!hasFiltersOrCsv) {
            setAudienceNotificationScope(
              AudienceNotificationScope.ALL_SUBSCRIBERS,
            )
          }
          setOpenAudienceModal(false)
        }}
        onSave={(hasFiltersOrCsv, audienceUserCount) => {
          setAudienceCount(audienceUserCount)
          if (!hasFiltersOrCsv) {
            setAudienceNotificationScope(
              AudienceNotificationScope.ALL_SUBSCRIBERS,
            )
          } else {
            setAudienceNotificationScope(
              AudienceNotificationScope.SUBSET_SUBSCRIBERS,
            )
          }
          setOpenAudienceModal(false)
        }}
        initialAudience={initialAudience}
        importHistory={importHistory}
        urls={urls}
      />
      <Modal
        css={confirmationModalCss}
        largeTitle
        isOpen={isNobodyConfirmationOpen}
        title="Are you sure you want to proceed with this selection?"
        submitButton="Confirm"
        cancelButton="Cancel"
        onRequestClose={() => {
          setIsNobodyConfirmationOpen(false)
        }}
        onSave={() => {
          setAudienceNotificationScope(AudienceNotificationScope.NOBODY)
          setIsNobodyConfirmationOpen(false)
        }}
        size={Size.small}>
        <Paragraph>
          If you choose this option, nobody will receive an email inviting them
          to this {object == 'event' ? 'event' : 'call to action'}. If you do
          want email invitations to go out, please select one of the other
          options.
        </Paragraph>
      </Modal>
    </FormFieldContainer>
  )
}
