import { useEffect, useState } from 'react'

interface IUseShiftKeysProps {
  onDown: () => void
  onUp: () => void
}

export function useShiftKeys({ onDown, onUp }: IUseShiftKeysProps): void {
  const [isDown, setIsDown] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('keydown', (e) => {
      setIsDown(e.shiftKey)
    })
    window.addEventListener('keyup', (e) => {
      setIsDown(e.shiftKey)
    })
  })

  useEffect(() => {
    isDown && onDown && onDown()
    !isDown && onUp && onUp()
  }, [isDown])
}
