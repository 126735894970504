import { css } from '@emotion/react'
import { Paragraph } from 'components/Typography'
import { boxShadow, colors, spacings } from 'stylesheets/theme'
import Container from 'components/Container/Container'
import Avatar from 'components/Avatar'
import { IYearbookMember } from 'types'

const deafultYearbookContainerStyle = css({
  minHeight: 144,
  height: '100%',
  margin: '0 12px 7px',
  padding: '16px 16px',
  backgroundColor: colors.backgrounds.white,
  boxShadow: boxShadow.container,
  alignItems: 'flex-start',
  borderRadius: spacings.radius,

  // Fixes for drop shadow alignments
  overflowY: 'hidden',
  marginBottom: 2,
  position: 'relative',
  left: -1,
})

const LINE_HEIGHT = 25
const LINES = 3
const paragraphStyle = css({
  '--lines': LINES,
  maxHeight: LINE_HEIGHT * LINES, // calc() does not work here for some reason
  '-webkit-line-clamp': 'var(--lines)',
  '-webkit-box-orient': 'vertical',
  overflowY: 'hidden',
})

const mobileYearbookContainerStyle = css({
  width: `calc(100% - ${spacings.grid_gap_basis})`,
  margin: '0 0 7px',
  height: 200,
})

const cardWrapperStyle = css({
  padding: '0 12px 7px',
})

interface YearbookCardProps {
  isMobile?: boolean
  yearbookMember: IYearbookMember
  className?: string
}

export default function YearbookCard({
  isMobile = false,
  yearbookMember,
  className = '',
}: YearbookCardProps): JSX.Element {
  return (
    <div css={cardWrapperStyle}>
      <Container
        direction="column"
        key={yearbookMember.full_name}
        css={[
          deafultYearbookContainerStyle,
          isMobile && mobileYearbookContainerStyle,
        ]}
        className={className}>
        <Container
          css={{
            alignItems: 'flex-start',
          }}>
          <Avatar
            size={60}
            avatarUrl={yearbookMember.avatar_url}
            userInitial={yearbookMember.initial}
          />
          <Container
            css={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginLeft: spacings.grid_gap_basis,
              gap: 2,
            }}>
            <h3
              css={{
                color: colors.text.text_5,
              }}>
              {yearbookMember.full_name}
            </h3>
            {yearbookMember.pronouns && (
              <Paragraph css={paragraphStyle}>
                {yearbookMember.pronouns}
              </Paragraph>
            )}
            {yearbookMember.title && (
              <Paragraph css={paragraphStyle}>{yearbookMember.title}</Paragraph>
            )}
          </Container>
        </Container>
      </Container>
    </div>
  )
}
