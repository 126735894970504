// import { css } from '@emotion/react'
import React, { useMemo } from 'react'
import cx from 'classnames'
import { css } from '@emotion/react'

import './index.scss'
import { colors, spacings, sizes } from '../../../stylesheets/theme'
import { FormFieldContainer, FormFieldsRow } from 'components/Forms'
import { DatePicker, TimePicker, TimezonePicker } from 'components/Inputs'
import { formattedDate, hourOnly } from 'components/helper-functions'

interface IDateTimeTZPickerRowProps {
  disabled?: boolean
  fieldName: string
  labelDate: string
  labelTime: string
  currentDate?: string
  currentTime?: string
  currentTimeTo?: string // only for date ranges
  currentTimezone?: string
  currentUserTimezone?: string
  useRange?: boolean
  className?: string
  timeErrors?: string[]
  dateErrors?: string[]
}

const placeholderCss = css({
  '& .input-component-wrapper .input-wrapper > input:not([type=checkbox]):not([type=radio]):not([type=file])':
    {
      '&::-webkit-input-placeholder, &::placeholder': {
        color: colors.text.text_9,
      },
    },
})

const rowCss = css({
  marginTop: spacings.grid_gap_basis_num,
  gap: spacings.grid_gap_basis_num * 2,
  '.mindr-form section & .input-component-wrapper:not(.files-component-wrapper):not(.recurring)':
    {
      marginRight: 0,
    },
})

const datePickerCss = css({
  '.mindr-form section .row &.input-component-wrapper:not(.files-component-wrapper):not(.recurring)':
    {
      minWidth: sizes.wideFieldWidth,
      maxWidth: sizes.wideFieldWidth,
      width: '100%',
    },
})

const timezonePickerCss = css({
  '.mindr-form section .row &.input-component-wrapper:not(.files-component-wrapper):not(.recurring)':
    {
      minWidth: sizes.smallFieldWidth,
      maxWidth: sizes.smallFieldWidth,
      width: '100%',
      flexGrow: 0,
    },
})

const timePickerCss = css({
  gap: spacings.grid_gap_basis_num,
  '.mindr-form section & .input-component-wrapper': {
    maxWidth: sizes.fieldWidth,
    minWidth: sizes.fieldWidth,
  },
})

const timeRangePickerCss = css({
  display: 'flex',
  flexDirection: 'row',
  gap: spacings.rowFieldGap,
  minWidth: sizes.doubleFieldWidth,
  maxWidth: sizes.doubleFieldWidth,
  width: '100%',
})

const DateTimeTZPickerRow: React.FC<IDateTimeTZPickerRowProps> = ({
  disabled = false,
  fieldName,
  labelDate,
  labelTime,
  useRange = false,
  currentDate = '',
  currentTime = '',
  currentTimeTo = '',
  currentTimezone = '',
  currentUserTimezone,
  className,
  timeErrors = [],
  dateErrors = [],
}): React.ReactElement => {
  const name = (keys: string[] = []) => {
    return keys.length ? fieldName + '[' + keys.join('][') + ']' : fieldName
  }
  const id = (keys: string[] = []) =>
    name(keys)
      .replace(/[\[\]]+/gm, '-')
      .replace(/-+$/gm, '')

  const dateFieldName = useMemo(() => name(['date']), [fieldName])
  const dateFieldId = useMemo(() => id(['date']), [fieldName])
  const timeFieldId = useMemo(() => id(['time']), [fieldName])
  const timeFromFieldName = useMemo(() => name(['time_from']), [fieldName])
  const timeToFieldName = useMemo(() => name(['time_to']), [fieldName])
  const timeToFieldId = useMemo(() => id(['time_to']), [fieldName])
  const tzFieldName = useMemo(() => name(['tz']), [fieldName])
  const tzFieldId = useMemo(() => id(['tz']), [fieldName])

  const date = formattedDate(currentDate)
  const time = hourOnly(currentTime)
  const timeTo = hourOnly(currentTimeTo)

  return (
    <FormFieldsRow className={className} css={[rowCss, placeholderCss]}>
      <FormFieldContainer
        label={labelDate}
        fieldId={id(['date'])}
        css={datePickerCss}>
        <DatePicker
          name={dateFieldName}
          id={dateFieldId}
          defaultValue={date}
          placeholder="Select date"
          errors={dateErrors}
          disabled={disabled}
          autoComplete="off"
        />
      </FormFieldContainer>

      <FormFieldsRow
        className={cx({ 'time-field': true, 'is-range': useRange })}
        css={timePickerCss}>
        <FormFieldContainer label={labelTime} fieldId={timeFieldId}>
          {useRange ? (
            <div css={timeRangePickerCss}>
              <TimePicker
                name={timeFromFieldName}
                id={timeFieldId}
                defaultValue={time}
                placeholder="Set time"
                disabled={disabled}
                errors={timeErrors}
              />
              <TimePicker
                name={timeToFieldName}
                id={timeToFieldId}
                defaultValue={timeTo}
                placeholder="Set time"
                disabled={disabled}
                errors={timeErrors}
              />
            </div>
          ) : (
            <TimePicker
              name={timeFromFieldName}
              id={timeFieldId}
              defaultValue={time}
              placeholder="Set time"
              disabled={disabled}
              errors={timeErrors}
            />
          )}
        </FormFieldContainer>

        <FormFieldContainer
          label="&nbsp;"
          fieldId={tzFieldId}
          css={timezonePickerCss}>
          <TimezonePicker
            name={tzFieldName}
            id={tzFieldId}
            defaultTimezone={
              currentTimezone ? currentTimezone : currentUserTimezone
            }
            timezones={window.timezones}
            disabled={disabled}
          />
        </FormFieldContainer>
      </FormFieldsRow>
    </FormFieldsRow>
  )
}

export default DateTimeTZPickerRow
