import { useState, useCallback } from 'react'

interface IPageState {
  page: number
  loadPage: (page) => void
}

const usePageState = (): IPageState => {
  const [page, setPage] = useState<number>(0)

  const loadPage = useCallback(
    (requestedPage) => {
      setPage(requestedPage)
    },
    [setPage],
  )

  return {
    page,
    loadPage,
  }
}

export default usePageState
