import cx from 'classnames'
import { css } from '@emotion/react'
import SlickSlider from 'react-slick'
import { Caption, SectionTitle } from 'components/Typography'
import { calcGridWidth, spacings } from 'stylesheets/theme'
import { useMemo } from 'react'
import Container from 'components/Container/Container'
import { SlickArrow } from 'components/HomePage/SlickArrow'
import {
  DATE_BLOCK_AND_MARGIN,
  PAGE_PADDING,
  PAGE_WIDTH,
} from '../EventCallPage/constants'
import useMediaQuery, { useComplexMediaQuery } from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { arrowStyle, slickDotStyle } from './slickStyle'

const ARROW_SIZE = 30

const outerContainerStyle = css({
  width: '100%',
  marginBottom: 40,
})

const defaultContainerStyle = css({
  padding: `${spacings.grid_gap_basis_num * 2}px 0`,
  paddingLeft: DATE_BLOCK_AND_MARGIN + PAGE_PADDING,
  paddingRight: PAGE_PADDING,
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '100vw',
})

const mobileContainerStyle = css({
  padding: '0px 24px',
})

const desktopContainerStyle = css({
  width: PAGE_WIDTH,
  maxWidth: `calc(100% - ${PAGE_PADDING * 2}px)`,
  paddingLeft: calcGridWidth(1, 1),
  paddingRight: 0,
})

const defaultArrowStyle = css({
  position: 'absolute',
  top: 'calc(50% - 15px)',
  transform: 'translateY(-50%)',
  width: ARROW_SIZE,
  height: ARROW_SIZE,
  cursor: 'pointer',
})

const defaultPrevArrowStyle = css({
  // ARROW_SIZE = width of the arrow icon
  // calcGridWidth(0, 1) = 1 grid gap
  // -11 = the negative margin from defaultSliderStyle
  left: -(ARROW_SIZE + calcGridWidth(0, 1) - 11),
})

const defaultNextArrowStyle = css({
  // ARROW_SIZE = width of the arrow icon
  // calcGridWidth(0, 1) = 1 grid gap
  // -11 = the negative margin from defaultSliderStyle
  right: -(ARROW_SIZE + calcGridWidth(0, 1) - 11),
})

const mobileArrowStyle = css({
  display: 'none !important',
})

const defaultSliderStyle = css({
  margin: '0 auto',
  position: 'relative',
  left: 1,
  padding: 0,
  marginRight: -24,
  marginLeft: -24,
  '.slick-arrow': arrowStyle,
})

const mobileSliderStyle = css({
  '.slick-dots': slickDotStyle,
})

interface SliderProps {
  sectionName: string
  caption?: string
  bottomCaption?: string
  actionNode?: JSX.Element
  components: JSX.Element[]
  overrideSliderOptions?: Record<string, unknown>
  className?: string
}

export default function Slider({
  sectionName,
  caption = '',
  bottomCaption = '',
  actionNode,
  components = [],
  overrideSliderOptions = {},
  className = '',
}: SliderProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const isFullDesktop = useComplexMediaQuery({ minWidth: breakpoints.xlg })
    .meetsDetails.minWidth

  const sliderOptions = useMemo(
    () => ({
      draggable: false,
      nextArrow: (
        <SlickArrow
          isNext
          aria-label="next"
          css={[
            defaultArrowStyle,
            defaultNextArrowStyle,
            isMobile && mobileArrowStyle,
          ]}
        />
      ),
      prevArrow: (
        <SlickArrow
          isNext={false}
          aria-label="prev"
          css={[
            defaultArrowStyle,
            defaultPrevArrowStyle,
            isMobile && mobileArrowStyle,
          ]}
        />
      ),
      infinite: components.length > 3,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      swipeToSlide: true,
      dots: isMobile,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            infinite: components.length > 3,
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1175,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 860,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
      ...overrideSliderOptions,
    }),
    [isMobile, components, overrideSliderOptions],
  )

  return (
    <Container
      css={outerContainerStyle}
      className={cx('slider-outer-container', className)}>
      <Container
        className="slider-container"
        direction="column"
        css={[
          defaultContainerStyle,
          isFullDesktop && desktopContainerStyle,
          isMobile && mobileContainerStyle,
        ]}>
        <div
          css={{
            margin: '0 auto',
            width: '100%',
            maxWidth: PAGE_WIDTH,
          }}>
          <Container justify="space-between" alignment="start">
            <Container alignment="start">
              <SectionTitle
                css={{
                  marginBottom: 24,
                }}>
                {sectionName}
              </SectionTitle>
              {actionNode}
            </Container>
            {caption && <Caption>{caption}</Caption>}
          </Container>
          <div
            className="slick-slider-wrapper"
            css={[defaultSliderStyle, isMobile && mobileSliderStyle]}>
            <SlickSlider {...sliderOptions}>{components}</SlickSlider>
          </div>
          {bottomCaption && (
            <Caption
              css={{
                marginTop: spacings.grid_gap_basis,
                textAlign: 'end',
              }}>
              {bottomCaption}
            </Caption>
          )}
        </div>
      </Container>
    </Container>
  )
}
