import React, { useState } from 'react'
import './ShowMoreParagraph.scss'

interface SeeMoreProps {
  showSeeMore: boolean
  onSeeMore?: () => void
  seeMoreText?: string
  seeLessText?: string
}

function SeeMore({
  showSeeMore,
  onSeeMore = () => {},
  seeMoreText = 'See More',
  seeLessText = 'See Less',
}: SeeMoreProps) {
  return (
    <a className="caption link bold show-more-btn-link" onClick={onSeeMore}>
      {showSeeMore ? seeMoreText : seeLessText}
    </a>
  )
}

interface ShowMoreParagraphProps {
  char?: number
  children: string
  seeMoreText?: SeeMoreProps['seeMoreText']
  seeLessText?: SeeMoreProps['seeLessText']
}

export default function ShowMoreParagraph({
  char = 185,
  children = '',
  seeMoreText,
  seeLessText,
}: ShowMoreParagraphProps): JSX.Element {
  const [seeMore, setSeeMore] = useState(true)

  return (
    <p className="caption notes">
      {children.length > char ? (
        <>
          {seeMore ? children.substring(0, char) + '...' : children + ' '}
          <SeeMore
            onSeeMore={() => setSeeMore(!seeMore)}
            showSeeMore={seeMore}
            seeMoreText={seeMoreText}
            seeLessText={seeLessText}
          />
        </>
      ) : (
        children
      )}
    </p>
  )
}
