import Card from 'components/Card'
import Container from 'components/Container'
import MembershipFilters, {
  IMembershipFiltersProps,
} from 'components/MembershipFilters'
import { Caption, Paragraph } from 'components/Typography'
import { useCallback, useMemo, useState } from 'react'
import { TUserFilters } from 'state/Users'
import CohortIcon from 'images/icons/cohort_14.svg'
import MentorshipIcon from 'images/icons/mentorship.svg'
import WorldIcon from 'images/icons/world.svg'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import Pill, { PillColor } from 'components/Pill'
import InformationIcon from 'images/icons/info.svg'
import SectionHeader from 'components/SectionHeader/SectionHeader'
import moment from 'moment'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import Spinner from 'images/icons/spinner.svg'
import DownloadIcon from 'images/icons/download.svg'

interface IStatData {
  rate: string
  description: string
}

interface IAnalyticalReportProps extends Partial<IMembershipFiltersProps> {
  setLoading?: (loading: boolean) => void
  loading?: boolean
  reportName: string
  mentorshipStatData: IStatData
  communitiesStatData?: IStatData
  mentorshipCommunitiesStatData?: IStatData
  loadData: (filters: any) => void
  understandingRatesTitle: string
  understandingRatesDescription: string
  warning?: string
  queryDates?: boolean
  exportLink?: string
}

const ICON_SIZE = 56

const defaultIconStyle = css({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: ICON_SIZE,
  minHeight: ICON_SIZE,
  svg: {
    width: 18,
    height: 18,
  },
})

const mentorshipIconStyle = css({
  backgroundColor: colors.purple_light,
  svg: {
    path: {
      stroke: colors.purple_report,
    },
  },
})

const communitiesIconStyle = css({
  backgroundColor: colors.blue_mid,
  svg: {
    path: {
      fill: colors.blue,
    },
  },
})

const mentorshipCommunitiesIconStyle = css({
  backgroundColor: colors.backgrounds.teal,
  svg: {
    path: {
      stroke: colors.green_dark,
    },
  },
})

const cardStyle = css({
  '.card-child': {
    height: '100%',
  },
})

const cardContentContainerStyle = css({
  height: '100%',
  justifyContent: 'space-between',
})

const gridStyle = css({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
  columnGap: spacings.grid_gap_basis_num * 2,
  marginBottom: spacings.grid_gap_basis_num * 3,
})

const rateStyle = css({
  fontFamily: 'Poppins',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '30px',
  textAlign: 'left',
  color: colors.text.text_5,
})

export default function AnalyticalReport({
  setLoading = () => {},
  loading = false,
  queryDates = false,
  filterOptions,
  reportName,
  mentorshipStatData,
  communitiesStatData,
  mentorshipCommunitiesStatData,
  loadData,
  understandingRatesTitle,
  understandingRatesDescription,
  warning,
  exportLink = '',
}: IAnalyticalReportProps): JSX.Element {
  const [filters, setFilters] = useState<TUserFilters>({
    show: null,
    text: undefined,
    city: undefined,
    title: undefined,
    department: undefined,
    start_date: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  })

  const updateFilters = useCallback(
    (newFilters: Partial<TUserFilters>) => {
      setFilters({
        ...filters,
        ...newFilters,
      })
    },
    [filters, setFilters],
  )

  // A year from today
  const defaultStartDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
  // Today
  const defaultEndDate = moment().format('YYYY-MM-DD')

  const tableFilters = useMemo(
    () => ({
      'filter[show]': filters.show ? filters.show : undefined,
      'filter[*]': filters.text || undefined,
      'filter[city]': filters.city || undefined,
      'filter[title]': filters.title || undefined,
      'filter[department]': filters.department || undefined,
      'filter[active_only]': false,
      'filter[end_date]': filters.end_date || undefined,
      'filter[start_date]': filters.start_date || undefined,
    }),
    [filters],
  )

  /**
   * Append filter params to exportLink
   * i.e. exportLink = '/api/v1/reports/export?type=retention'
   * return '/api/v1/reports/export?type=retention&filter[end_date]=2021-01-01&filter[start_date]=2020-01-01'
   */
  const exportCsvLink = useMemo(() => {
    const params = new URLSearchParams()
    let exportLinkWithFilterParams = exportLink
    if (filters.end_date) {
      params.append('filter[end_date]', filters.end_date)
    }
    if (filters.start_date) {
      params.append('filter[start_date]', filters.start_date)
    }

    if (params.toString()) {
      exportLinkWithFilterParams += `&${params.toString()}`
    }

    return exportLinkWithFilterParams
  }, [exportLink, filters])

  const updateReport = useCallback(() => {
    setLoading(true)
    loadData(tableFilters)
  }, [tableFilters, setLoading])

  const resetAllFilters = useCallback(() => {
    setFilters({
      show: null,
      text: undefined,
      city: undefined,
      title: undefined,
      department: undefined,
      start_date: defaultStartDate,
      end_date: defaultEndDate,
    })
  }, [setFilters])

  return (
    <>
      <MembershipFilters
        css={{
          width: '100%',
        }}
        hideSearch
        filters={filters}
        filterOptions={filterOptions}
        updateFilters={updateFilters}
        showAllFilters
        queryDates={queryDates}
        defaultStartDate={defaultStartDate}
        defaultEndDate={defaultEndDate}
        showResetAllButton
        resetFilters={resetAllFilters}
        actionNode={
          <Button
            as={ButtonComponentType.BUTTON}
            variant={Variant.PRIMARY}
            css={{
              marginTop: spacings.grid_gap_basis_num,
            }}
            onClick={updateReport}>
            Update
          </Button>
        }
      />
      <Container>
        <SectionHeader
          css={{
            width: 'auto',
          }}>
          {reportName}
        </SectionHeader>
        <a
          href={exportCsvLink}
          className="link"
          css={{
            alignSelf: 'flex-start',
          }}>
          <DownloadIcon />
          Export.csv
        </a>
      </Container>
      {loading ? (
        <Card
          css={{
            height: 216,
            '.card-child': {
              height: '100%',
            },
            marginBottom: spacings.grid_gap_basis_num * 3,
          }}>
          <Container
            css={{
              height: '100%',
              justifyContent: 'center',
            }}>
            <Spinner
              css={{
                width: spacings.grid_gap_basis_num * 10,
                height: spacings.grid_gap_basis_num * 10,
              }}
            />
          </Container>
        </Card>
      ) : (
        <div css={gridStyle}>
          <Card css={cardStyle}>
            <Container
              alignment="start"
              css={{
                height: '100%',
              }}>
              <div css={[defaultIconStyle, communitiesIconStyle]}>
                <WorldIcon />
              </div>
              <Container css={cardContentContainerStyle} direction="column">
                <Container direction="column" alignment="start">
                  <span css={rateStyle}>{communitiesStatData.rate}%</span>
                  <Paragraph>
                    {communitiesStatData.description} for employees{' '}
                    <b>subscribed to at least 1 community</b> via Mindr Connect,
                    relative to those not subscribed
                  </Paragraph>
                </Container>
                <Pill
                  size="small"
                  color={PillColor.BLUE}
                  css={{
                    alignSelf: 'flex-end',
                  }}>
                  Communities
                </Pill>
              </Container>
            </Container>
          </Card>
          <Card css={cardStyle}>
            <Container
              alignment="start"
              css={{
                height: '100%',
              }}>
              <div css={[defaultIconStyle, mentorshipIconStyle]}>
                <MentorshipIcon />
              </div>
              <Container css={cardContentContainerStyle} direction="column">
                <Container direction="column" alignment="start">
                  <span css={rateStyle}>{mentorshipStatData.rate}%</span>
                  <Paragraph>
                    {mentorshipStatData.description} for employees who have{' '}
                    <b>participated in the Mindr Mentorship Exchange</b> program
                    compared to those who have not
                  </Paragraph>
                </Container>
                <Pill
                  size="small"
                  color={PillColor.PURPLE}
                  css={{
                    alignSelf: 'flex-end',
                  }}>
                  Mindr mentorship exchange
                </Pill>
              </Container>
            </Container>
          </Card>
          <Card css={cardStyle}>
            <Container
              alignment="start"
              css={{
                height: '100%',
              }}>
              <div css={[defaultIconStyle, mentorshipCommunitiesIconStyle]}>
                <CohortIcon />
              </div>
              <Container css={cardContentContainerStyle} direction="column">
                <Container direction="column" alignment="start">
                  <span css={rateStyle}>
                    {mentorshipCommunitiesStatData.rate}%
                  </span>
                  <Paragraph>
                    {mentorshipCommunitiesStatData.description} for employees
                    who are <b>subscribed to at least 1 community</b> and who
                    have{' '}
                    <b>ever participated in the Mindr Mentorship Exchange</b>{' '}
                    program, relative to those not engaged in either
                  </Paragraph>
                </Container>
                <Container
                  css={{
                    gap: spacings.grid_gap_basis_num / 2,
                    flexWrap: 'wrap',
                    justifyContent: 'flex-end',
                    alignSelf: 'flex-end',
                  }}>
                  <Pill
                    size="small"
                    color={PillColor.BLUE}
                    css={{
                      alignSelf: 'flex-end',
                    }}>
                    Communities
                  </Pill>
                  <Pill
                    size="small"
                    color={PillColor.PURPLE}
                    css={{
                      alignSelf: 'flex-end',
                    }}>
                    Mindr mentorship exchange
                  </Pill>
                </Container>
              </Container>
            </Container>
          </Card>
        </div>
      )}
      <Paragraph
        bold
        css={{
          marginBottom: spacings.grid_gap_basis_num * 2,
        }}>
        {understandingRatesTitle}
      </Paragraph>
      <Caption>{understandingRatesDescription}</Caption>
      {warning && (
        <Container
          alignment="start"
          css={{
            marginTop: spacings.grid_gap_basis_num * 5,
            gap: spacings.grid_gap_basis_num / 2,
          }}>
          <InformationIcon
            css={{
              minWidth: '14px',
              minHeight: '14px',
              path: {
                fill: colors.states.warning,
              },
            }}
          />
          <Caption>{warning}</Caption>
        </Container>
      )}
    </>
  )
}
