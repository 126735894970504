import { useCallback, useState } from 'react'

import Button, { Variant } from 'components/Button'
import OperationalLogsModal from './OperationalLogsModal'

interface ViewOperationalLogButtonProps {
  operationalLogsPath: string
}

export default function ViewOperationalLogButton({
  operationalLogsPath,
}: ViewOperationalLogButtonProps): JSX.Element {
  const [showModal, setShowModal] = useState(false)

  const onShow = useCallback(() => {
    setShowModal(true)
  }, [setShowModal])
  const onClose = useCallback(() => {
    setShowModal(false)
  }, [setShowModal])

  return (
    <>
      {showModal && (
        <OperationalLogsModal url={operationalLogsPath} onClose={onClose} />
      )}
      <Button variant={Variant.UNSTYLED} onClick={onShow} className="menu-link">
        View Operational Log
      </Button>
    </>
  )
}
