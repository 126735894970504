import React, { useCallback, useMemo, useRef, useState } from 'react'
import _ from 'lodash'

import './Cohosts.scss'
import CohostRow, { ICohost } from './CohostRow'
import { useAdditionalSectionRows } from 'javascripts/general'

interface ICohosts {
  communitiesUrl: string
  initialValues: ICohost[]
  rootEntity: string
  communityId: number
}

const Cohosts: React.FC<ICohosts> = ({
  communitiesUrl,
  initialValues,
  rootEntity,
  communityId,
}) => {
  const [cohosts, setCohosts] = useState<ICohost[]>(
    initialValues.map((v) => ({
      id: v.id,
      name: v.name,
      community_id: v.community_id,
      _destroy: false,
      errors: v.errors,
    })),
  )

  const firstRenderedComponentIndex = useMemo(
    () => _.findIndex(cohosts, (c) => !c._destroy),
    [],
  )

  const componentRef = useRef()

  const onChange = useCallback(
    (index: number, value: ICohost) => {
      const newCohosts = [...cohosts]
      newCohosts.splice(index, 1, value)
      setCohosts(newCohosts)
    },
    [cohosts, setCohosts],
  )

  const addRow = useCallback(() => {
    setCohosts([
      ...cohosts,
      {
        id: undefined,
        community_id: undefined,
        name: undefined,
        _destroy: false,
        errors: undefined,
      },
    ])
  }, [cohosts, setCohosts])

  const cleanRows = useCallback(() => {
    setCohosts(
      cohosts.map((c) => ({
        ...c,
        _destroy: true,
      })),
    )
  }, [cohosts, setCohosts])

  useAdditionalSectionRows(
    firstRenderedComponentIndex !== -1,
    componentRef,
    addRow,
    cleanRows,
  )

  return (
    <div className="cohosts additional-section-component" ref={componentRef}>
      {cohosts.map((c, index) => {
        return (
          <CohostRow
            key={`cohost-row-${index}`}
            index={index}
            isFirst={firstRenderedComponentIndex === index}
            communitiesUrl={communitiesUrl}
            onChange={onChange}
            rootEntity={rootEntity}
            value={c}
            communityId={communityId}
          />
        )
      })}
      <button className="link" type="button" onClick={addRow}>
        + Add Cohost
      </button>
    </div>
  )
}

export default Cohosts
