import React from 'react'
import cx from 'classnames'
import { css } from '@emotion/react'

import './index.scss'

const rowCss = css({
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '100%',
  gap: 16,

  '.w50': {
    maxWidth: '50% !important',

    '.input-component-wrapper:not(.tz-select)': {
      minWidth: '0 !important',
    },
  },
})

const centerCss = css({
  alignItems: 'center',
})

interface IFormFieldsRowProps {
  className?: string
  verticalCenter?: boolean
  children: React.ReactNode
}

const FormFieldsRow: React.FC<IFormFieldsRowProps> = ({
  className,
  verticalCenter = false,
  children,
}): React.ReactElement => {
  return (
    <div
      className={cx(className, 'row')}
      css={[rowCss, verticalCenter ? centerCss : '']}>
      {children}
    </div>
  )
}

export default FormFieldsRow
