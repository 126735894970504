import Modal, { Size } from 'components/Modal'
import { Paragraph } from 'components/Typography'
import _ from 'lodash'
import { IAudiencesUrls } from './AudienceContext'
import Container from 'components/Container'
import Switch from 'components/Switch'
import Button, { Variant } from 'components/Button'
import { colors, spacings } from 'stylesheets/theme'
import { css } from '@emotion/react'
import CsvImportComponent from './CsvImportComponent'
import { ITableProps } from 'components/Table'
import AdvancedFilterComponent from './AdvancedFilterComponent'
import AudienceConfirmationModal from './AudienceConfirmationModal'
import { DEFAULT_CSV_MESSAGING } from './helper'
import Input from 'components/Input'

interface AudienceComponentViewProps {
  object: string
  isOpen: boolean
  isCsvImport: boolean
  setCsvImport: (isCsvImport: boolean) => void
  filterGroups: any // Replace `any` with the appropriate type
  initialFilterGroups: any // Replace `any` with the appropriate type
  audienceCount: any // Replace `any` with the appropriate type
  title?: string
  subtitle?: string
  size?: Size
  onClose: (savedAudience: boolean) => void
  resetFilterGroups: () => void
  hasPositiveAudienceCount: () => boolean
  shouldConfirmClose: () => { value: boolean }
  setSaveAndConfirm: (saveAndConfirm: boolean) => void
  setOpenConfirmationModal: (openConfirmationModal: boolean) => void
  updateEstimatedAudienceCount: () => void
  setShowZeroAudienceError: (showZeroAudienceError: boolean) => void
  onConfirmationRequestClose: () => void
  onConfirmationSave: () => void
  csvImportFile: any // Replace `any` with the appropriate type
  setCsvImportFile: (csvImportFile: any) => void
  resultingFilterGroups: any // Replace `any` with the appropriate type
  isAdditionalUsers?: boolean
  audienceImportTable: ITableProps
  exampleCsvUrl: string
  addNewFilterItem: (filterGroupIndex: number) => void
  removeFilterItem: (filterGroupIndex: number, filterItemIndex: number) => void
  addFilterGroup: () => void
  removeFilterGroup: (filterGroupIndex: number) => void
  onFilterItemChange: (
    groupIndex: number,
    itemIndex: number,
    filterType: '' | 'Location' | 'Department' | 'Title' | 'Name',
    filterInputs: any,
  ) => void
  urls: IAudiencesUrls
  saveAndConfirm: boolean
  openConfirmationModal: boolean
  showZeroAudienceError: boolean
  removeCsvImport: () => void
  uploadDetails: any
  submitButton?: string
  excludeInvited: boolean
  setExcludeInvited: (excludeInvited: boolean) => void
  onModalClose: () => void
  onSendToAllUsers: () => void
  sendToAllUsers: boolean
}

const containerStyle = css({
  marginTop: spacings.grid_gap_basis_num * 3,
  backgroundColor: colors.backgrounds.page,
  width: '100%',
  minHeight: 360,
  padding: spacings.grid_gap_basis_num * 2,
  display: 'flex',
  gap: spacings.grid_gap_basis_num * 2,
  flexDirection: 'column',
  overflow: 'hidden',
})

export default function AudienceComponentView({
  object,
  isOpen,
  isCsvImport,
  setCsvImport,
  filterGroups,
  initialFilterGroups,
  audienceCount,
  title,
  subtitle,
  size,
  hasPositiveAudienceCount,
  updateEstimatedAudienceCount,
  setShowZeroAudienceError,
  onConfirmationRequestClose,
  onConfirmationSave,
  csvImportFile,
  setCsvImportFile,
  resultingFilterGroups,
  audienceImportTable,
  exampleCsvUrl,
  addNewFilterItem,
  removeFilterItem,
  addFilterGroup,
  removeFilterGroup,
  onFilterItemChange,
  urls,
  saveAndConfirm,
  showZeroAudienceError,
  removeCsvImport,
  uploadDetails,
  setOpenConfirmationModal,
  openConfirmationModal,
  submitButton,
  excludeInvited,
  onModalClose,
  onSendToAllUsers,
  sendToAllUsers,
  setExcludeInvited,
}: AudienceComponentViewProps): JSX.Element {
  return (
    <Modal
      isSubmitDisabled={
        (!isCsvImport && _.isEqual(filterGroups, initialFilterGroups)) ||
        (isCsvImport && audienceCount.csvFile.audienceCount.matched <= 0)
      }
      isOpen={isOpen}
      largeTitle
      title={title || 'Who should we notify?'}
      size={size || Size.wide}
      css={{
        '&.wide': {
          maxWidth: 1172,
        },
      }}
      onRequestClose={onModalClose}
      onSave={() => {
        if (!hasPositiveAudienceCount()) {
          setShowZeroAudienceError(true)
          return
        }
        updateEstimatedAudienceCount()
        setOpenConfirmationModal(true)
      }}
      submitButton={submitButton || 'Send'}
      actionButtonPosition="right"
      secondaryActionButton={
        <Button variant={Variant.SECONDARY} onClick={onSendToAllUsers}>
          send to all users
        </Button>
      }
      cancelButton={'Close'}>
      <Paragraph
        css={{
          marginBottom: spacings.grid_gap_basis_num * 2,
        }}>
        {subtitle || DEFAULT_CSV_MESSAGING}
      </Paragraph>
      <Container
        css={{
          gap: spacings.grid_gap_basis_num * 0.5,
        }}>
        <Input
          type="checkbox"
          checked={excludeInvited}
          name="exclude-invited-checkbox"
          className="exclude-invited-checkbox"
          onChange={(changes: Record<string, boolean>) => {
            setExcludeInvited(changes['exclude-invited-checkbox'])
          }}
          css={{
            marginBottom: '0 !important',
            width: 20,
          }}
        />
        <Paragraph>
          Exclude users who have already received invitations from the results
          to prevent duplicate invitations.
        </Paragraph>
      </Container>
      <form id="audience-form">
        <div css={containerStyle}>
          <Container css={{ justifyContent: 'space-between' }}>
            <h4
              css={{
                color: colors.text.text_5,
              }}>
              {isCsvImport ? 'CSV Import' : 'Advanced filter'}
            </h4>
            <Switch
              controlName={`${object}[audience_filter]`}
              value="true"
              checked={isCsvImport}
              checkedText="CSV import"
              uncheckedText="Advanced filter"
              onChange={() => {
                setCsvImport(!isCsvImport)
              }}
            />
          </Container>
          {isCsvImport ? (
            <CsvImportComponent
              tableData={audienceImportTable.tableData}
              tableMeta={audienceImportTable.tableMeta}
              exampleCsvUrl={exampleCsvUrl}
              audienceImportTable={audienceImportTable}
              setCsvImportFile={setCsvImportFile}
              updateEstimatedAudienceCount={updateEstimatedAudienceCount}
              removeCsvImport={removeCsvImport}
              uploadDetails={uploadDetails}
              csvImportFile={csvImportFile}
              selectedUsersCount={audienceCount.csvFile.audienceCount.matched}
            />
          ) : (
            <AdvancedFilterComponent
              filterGroups={filterGroups}
              addNewFilterItem={addNewFilterItem}
              removeFilterItem={removeFilterItem}
              addFilterGroup={addFilterGroup}
              removeFilterGroup={removeFilterGroup}
              audienceCount={audienceCount}
              onFilterItemChange={onFilterItemChange}
              subscribersUrl={urls.subscribersUrl}
              filtersUrl={urls.filtersUrl}
              isOpen={isOpen}
              excludeInvited={excludeInvited}
            />
          )}
        </div>
      </form>
      <AudienceConfirmationModal
        saveAndConfirm={saveAndConfirm}
        isOpen={openConfirmationModal}
        csvImportFile={csvImportFile}
        onRequestClose={onConfirmationRequestClose}
        onSave={onConfirmationSave}
        audienceCount={audienceCount}
        size={Size.large}
        filterGroups={filterGroups}
        isCsvImport={isCsvImport}
        resultingFilterGroups={resultingFilterGroups}
        isAdditionalAudience={false}
        sendToAll={sendToAllUsers}
        customEnding={{
          csvImport: 'Users will receive the invitations upon confirmation.',
          filters: '',
        }}
      />
      <Modal
        largeTitle
        isOpen={showZeroAudienceError}
        title={
          isCsvImport
            ? 'No users have been uploaded'
            : 'No users have been selected'
        }
        submitButton="Ok"
        size={Size.small}
        onRequestClose={() => setShowZeroAudienceError(false)}
        onSave={() => setShowZeroAudienceError(false)}>
        <Paragraph>
          {isCsvImport
            ? 'Please upload a file containing valid user data.'
            : 'Please adjust the filters to display more selected user results.'}
        </Paragraph>
      </Modal>
    </Modal>
  )
}
