import { IYearbookMember } from 'types'
import Slider from './Slider'
import YearbookCard from './YearbookCard'
import Link from 'components/Link'

export interface IYearbookProps {
  yearbookMembers: IYearbookMember[]
  viewAllUrl: string
  showingCount: number
  totalCount: number
}

export default function Yearbook({
  yearbookMembers,
  viewAllUrl,
  showingCount,
  totalCount,
}: IYearbookProps): JSX.Element {
  return (
    <Slider
      sectionName="In this cohort"
      caption={`(Showing ${showingCount} of ${totalCount})`}
      actionNode={<Link href={viewAllUrl}>View all</Link>}
      components={yearbookMembers.map((member) => (
        <YearbookCard key={member.id} yearbookMember={member} />
      ))}
    />
  )
}
