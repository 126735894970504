import FeatureFlag from './FeatureFlag'
import { HTMLAttributes, useMemo } from 'react'
import { useFeatureFlagsContext } from './FeatureFlagsContext'
import Input from 'components/Input'
import {
  checkboxWithLabelStyle,
  featureFlagListStyle,
  groupCheckboxWithLabelStyle,
  groupIconStyle,
  groupStyle,
} from './styles'
import { Caption } from 'components/Typography'
import ExclamationIcon from 'images/icons/exclamation.svg'
import CloseIcon from 'images/icons/close-dark.svg'

interface IFeatureGroupProps extends HTMLAttributes<HTMLDivElement> {
  groupId: string
}

export default function FeatureGroup({
  groupId,
  ...props
}: IFeatureGroupProps): JSX.Element {
  const { getGroup, updateAllFeaturesInGroup } = useFeatureFlagsContext()
  const group = getGroup(groupId)
  const checkboxName = useMemo(() => {
    return `__group_checkbox[${groupId}]`
  }, [groupId])
  const restricted = useMemo(() => {
    return ['data_related'].indexOf(groupId) >= 0
  }, [groupId])

  return group ? (
    <div {...props} css={groupStyle}>
      <div css={[checkboxWithLabelStyle, groupCheckboxWithLabelStyle]}>
        <div className="checkbox-wrap">
          {!group.features?.length ? (
            <CloseIcon />
          ) : restricted ? (
            <ExclamationIcon css={groupIconStyle} />
          ) : (
            <Input
              type="checkbox"
              checked={group.all_checked}
              name={checkboxName}
              onChange={(changes) => {
                updateAllFeaturesInGroup(
                  groupId,
                  changes[checkboxName] as boolean,
                )
              }}
            />
          )}
        </div>
        {group.description}
      </div>
      <div css={featureFlagListStyle}>
        {group.features?.length ? (
          group.features?.map((feature) => (
            <FeatureFlag key={feature.name} featureId={feature.name} />
          ))
        ) : (
          <div className="checkbox-wrap" css={checkboxWithLabelStyle}>
            <Caption>
              <i>{group.missing_message ?? 'No features yet'}</i>
            </Caption>
          </div>
        )}
      </div>
    </div>
  ) : null
}
