import React, { useState } from 'react'
import cx from 'classnames'

import type { TActionLocation } from 'types'

import './JoinWaitlistButton.scss'
import { breakpoints } from 'stylesheets/breakpoints'
import useMediaQuery from 'hooks/useMediaQuery'
import ConfirmationModal from 'components/ConfirmationModal'

interface IJoinWaitlistButtonView {
  eventLink: string
  waitlistOpen: boolean
  waitlisted: boolean
  loading: boolean
  onJoin: () => void
  onLeave: () => void
  location: TActionLocation
}

const JoinWaitlistButtonView: React.FC<IJoinWaitlistButtonView> = ({
  eventLink,
  waitlistOpen,
  waitlisted,
  loading,
  onJoin,
  onLeave,
  location,
}) => {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const [openRemoveFromWaitlistModal, setOpenRemoveFromWaitlistModal] =
    useState(false)

  if (!waitlistOpen) {
    if (location === 'banner') {
      return null
    } else if (location === 'table-cell') {
      return (
        <a href={eventLink} className="button-label">
          Closed
        </a>
      )
    }

    return <span className="button-label">Closed</span>
  } else if (waitlisted) {
    if (location === 'banner') {
      return (
        <div
          className="join-waitlist-action"
          css={{
            position: isMobile ? 'unset' : 'relative',
          }}>
          <button
            css={{
              width: isMobile && '100%',
            }}
            className="button primary"
            disabled={loading}
            onClick={() => setOpenRemoveFromWaitlistModal(true)}>
            Remove from Waitlist
          </button>
          <ConfirmationModal
            title="Are you sure you want to remove yourself from the waitlist?"
            isOpen={openRemoveFromWaitlistModal}
            onConfirm={onLeave}
            onCancel={() => setOpenRemoveFromWaitlistModal(false)}>
            By removing yourself from the waitlist, you will no longer receive
            notifications when registration spots become available. Are you sure
            you want to proceed?
          </ConfirmationModal>
        </div>
      )
    } else if (location === 'table-cell') {
      return (
        <a href={eventLink} className="button-label">
          Joined Waitlist
        </a>
      )
    }

    return (
      <div className="join-waitlist-action">
        <div className="button-label small">Joined Waitlist</div>
      </div>
    )
  }

  return (
    <div
      className="join-waitlist-action"
      css={{
        position: isMobile ? 'unset' : 'relative',
      }}>
      <button
        className={cx('button', {
          primary: location === 'banner',
          secondary: location !== 'banner',
          small: location !== 'banner',
          'light with-icon waitlist-oncard': location === 'card',
        })}
        css={{
          width: isMobile && '100%',
        }}
        disabled={loading}
        onClick={onJoin}>
        Join Waitlist
      </button>
    </div>
  )
}

export default JoinWaitlistButtonView
