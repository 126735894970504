import { css } from '@emotion/react'
import cx from 'classnames'
import { InputHTMLAttributes } from 'react'

interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  controlName: string
  defaultChecked?: boolean
  error?: string
  uncheckedText?: string | null
  checkedText?: string | null
  offValue?: string | number
  onValue?: string | number
}

const disabledCss = css({
  opacity: 0.5,
})

/**
 * Renders a toggle with Yes / No
 */
export default function Switch({
  controlName,
  defaultChecked,
  error,
  uncheckedText = 'No',
  checkedText = 'Yes',
  className = '',
  offValue = '0',
  onValue = '1',
  ...inputProps
}: SwitchProps): JSX.Element {
  const classes = []
  if (inputProps.readOnly) {
    classes.push(disabledCss)
  }

  return (
    <div className={cx('switch-wrapper', className)} css={classes}>
      {uncheckedText && <p className="off">{uncheckedText}</p>}
      <div className="input-component-wrapper switch-component-wrapper">
        <label className="input-wrapper" htmlFor={controlName} tabIndex={0}>
          <label className="switch" tabIndex={0}>
            <input
              name={controlName}
              value={offValue}
              type="hidden"
              disabled={inputProps.readOnly}
            />
            <input
              {...inputProps}
              value={inputProps?.value ?? onValue}
              name={controlName}
              defaultChecked={defaultChecked}
              type="checkbox"
              disabled={inputProps.readOnly}
              tabIndex={-1}></input>
            <span className="back" />
            <i className="indicator" />
          </label>
        </label>
        {error && <span className="validation-error-message">{error}</span>}
      </div>
      {checkedText && <p className="on">{checkedText}</p>}
    </div>
  )
}
