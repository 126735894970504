export const LEARNING_TAGS = [
  {
    id: 0,
    category_id: 0,
    name: 'Cross-functional learning',
    strength_description:
      'I seek to learn from colleagues in different types of roles and functions than my own',
    goal_description:
      'I would like to learn from colleagues in different types of roles and functions than my own',
  },
  {
    id: 1,
    category_id: 0,
    name: 'Commitment to feedback',
    strength_description: 'I seek out constructive feedback to help me grow',
    goal_description:
      'I want to get better at seeking out constructive feedback to help me grow, and acting on it',
  },
  {
    id: 2,
    category_id: 0,
    name: 'Intercultural understanding',
    strength_description:
      'I am interested in developing my understanding of cultures and experiences that are different from my own',
    goal_description:
      'I want to better understand cultures and experiences that are different from my own',
  },
  {
    id: 3,
    category_id: 0,
    name: 'Big picture thinking',
    strength_description:
      'I consider how my role and deliverables fit into the bigger picture',
    goal_description:
      'I want to better understand how my role and deliverables fit into the bigger picture',
  },
  {
    id: 4,
    category_id: 0,
    name: 'Technical skills',
    strength_description: 'I work actively on improving my technical skillset',
    goal_description: 'I want to improve my technical skillset',
  },
]

export const EMPATHY_TAGS = [
  {
    id: 5,
    category_id: 1,
    name: 'Team building',
    strength_description:
      'I am able to build strong relationships among members of my team',
    goal_description:
      'I want to be better at building strong relationships among members of my team',
  },
  {
    id: 6,
    category_id: 1,
    name: 'Internal relationships',
    strength_description:
      'I am able to foster meaningful connections with colleagues across my organization',
    goal_description:
      'I want to foster more meaningful connections with other colleagues across my organization',
  },
  {
    id: 7,
    category_id: 1,
    name: 'External relationships',
    strength_description:
      'I am able to foster meaningful connections with stakeholders external to my organization (such as clients, customers, investors, or other stakeholders)',
    goal_description:
      'I want to foster more meaningful connections with stakeholders external to my organization (such as clients, customers, investors, or other stakeholders)',
  },
  {
    id: 8,
    category_id: 1,
    name: 'Underrepresented voices',
    strength_description:
      'I consider the needs of underrepresented colleagues, and how to elevate them',
    goal_description:
      'I want to be better at considering the needs of underrepresented colleagues, and how to elevate them',
  },
  {
    id: 9,
    category_id: 1,
    name: 'Conflict management',
    strength_description:
      'I am able to navigate difficult conversations and de-escalate conflicts when they arise',
    goal_description:
      'I want to be better at navigating difficult conversations and de-escalating conflicts when they arise',
  },
]

export const ACTION_TAGS = [
  {
    id: 10,
    category_id: 2,
    name: 'Self advocacy',
    strength_description:
      'I speak up or take action when my needs are not being met',
    goal_description:
      'I want to be better at speaking up or taking action when my needs are not being met',
  },
  {
    id: 11,
    category_id: 2,
    name: 'Advocacy for others',
    strength_description:
      'I speak up or take action on behalf of others whose needs are not being met',
    goal_description:
      'I want to be better at speaking up or taking action on behalf of others whose needs are not being met',
  },
  {
    id: 12,
    category_id: 2,
    name: 'Time management',
    strength_description:
      'I manage my commitments thoughtfully so that I can get everything done, even when I’m busy',
    goal_description:
      'I want to be better at managing my commitments thoughtfully so that I can get everything done, even when I’m busy',
  },
  {
    id: 13,
    category_id: 2,
    name: 'Decision making',
    strength_description:
      'I am able to make a decision and take appropriate action even in circumstances of ambiguity',
    goal_description:
      'I want to be better at making decisions and taking appropriate actions in circumstances of ambiguity',
  },
  {
    id: 14,
    category_id: 2,
    name: 'Goal setting',
    strength_description:
      'I set clear goals and execute on them in accordance with a plan',
    goal_description:
      'I want to be better at setting clear goals and executing on them in accordance with a plan',
  },
]

export const DIRECTION_TAGS = [
  {
    id: 15,
    category_id: 3,
    name: 'Project management',
    strength_description:
      'I am the person others look to for guidance on how to complete a project successfully and on time',
    goal_description:
      'I want to be better at project planning to complete projects successfully and on time',
  },
  {
    id: 16,
    category_id: 3,
    name: 'Direct management',
    strength_description:
      'I operate successfully as a direct manager to others',
    goal_description:
      'I want to learn how to be a better direct manager to others',
  },
  {
    id: 17,
    category_id: 3,
    name: 'Professional presence',
    strength_description:
      'I project authority, credibility, gravitas, and influence',
    goal_description:
      'I want to project authority, credibility, gravitas, and influence',
  },
  {
    id: 18,
    category_id: 3,
    name: 'Public speaking',
    strength_description:
      'I am a strong presenter and communicator, with a clear and compelling message',
    goal_description:
      'I want to be a strong presenter and communicator, with a clear and compelling message',
  },
  {
    id: 20,
    category_id: 3,
    name: 'Leading large teams',
    strength_description: 'I lead large teams, and/or lead managers of teams',
    goal_description:
      'I want to learn how to lead large teams, and/or lead managers of teams',
  },
]

export const RESILIENCE_TAGS = [
  {
    id: 21,
    category_id: 4,
    name: 'Adaptability to change',
    strength_description:
      'I can navigate changing circumstances and continue to succeed',
    goal_description:
      'I want to be better at navigating changing circumstances while continuing to succeed',
  },
  {
    id: 22,
    category_id: 4,
    name: 'Mental and physical wellbeing',
    strength_description:
      'I look after the wellbeing of myself and others',
    goal_description:
      'I want to be better at looking after the wellbeing of myself and others',
  },
  {
    id: 23,
    category_id: 4,
    name: 'Work-life balance',
    strength_description:
      'Even when I am busy, I find ways to balance the competing priorities in my life',
    goal_description:
      'I want to learn how to better balance the competing priorities in my life, especially when things are busy',
  },
  {
    id: 24,
    category_id: 4,
    name: 'Facing failure',
    strength_description:
      'When I fail or receive negative feedback, I take stock, learn from the experience, and bounce back stronger',
    goal_description:
      'When I fail or receive negative feedback, I want to be better at taking stock, learning from the experience, and bouncing back stronger',
  },
  {
    id: 25,
    category_id: 4,
    name: 'Working under pressure',
    strength_description: 'I navigate high pressure situations with poise',
    goal_description:
      'I want to be better at navigating high pressure situations with poise',
  },
]
