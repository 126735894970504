import LeadrCumulativeReportComponent from './LeadrCumulativeReport.component'
import { LeadrCumulativeReportContextProvider } from './LeadrCumulativeReport.context'
import { ILeadrCumulativeReportProps } from './LeadrCumulativeReport.types'

export default function LeadrCumulativeReport({
  ...props
}: ILeadrCumulativeReportProps): JSX.Element {
  return (
    <LeadrCumulativeReportContextProvider {...props}>
      <LeadrCumulativeReportComponent></LeadrCumulativeReportComponent>
    </LeadrCumulativeReportContextProvider>
  )
}
