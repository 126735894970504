import React, { useCallback, useState } from 'react'
import axios from 'axios'

interface IRegisterToggleButton {
  isRegistered: boolean
  registerLabel: string
  unregisterLabel: string
  registerEndpoint: string
  unregisterEndpoint: string
  unregisterConfirmationTitle: string
  unregisterConfirmationDesc: string
}

const RegisterToggleButton: React.FC<IRegisterToggleButton> = ({
  isRegistered: initialRegistered,
  registerLabel,
  unregisterLabel,
  registerEndpoint,
  unregisterEndpoint,
  unregisterConfirmationTitle,
  unregisterConfirmationDesc,
}) => {
  const [registered, setRegistered] = useState(initialRegistered)
  const [loading, setLoading] = useState(false)

  const register = useCallback(async () => {
    setLoading(true)

    try {
      const {
        data: {
          data: { message },
        },
      } = await axios.post(registerEndpoint, {
        authenticity_token: window.authenticity_token,
      })

      if (message) {
        window.flash(message)
      }

      setRegistered(true)
    } catch (e) {
      window.flash('Something went wrong!', 'alert')
    } finally {
      setLoading(false)
    }
  }, [setLoading, setRegistered])

  const unregister = useCallback(async () => {
    const confirmUnregister = await window.showConfirmation(
      unregisterConfirmationTitle,
      unregisterConfirmationDesc,
    )
    if (!confirmUnregister) return

    setLoading(true)

    try {
      const {
        data: {
          data: { message },
        },
      } = await axios.post(unregisterEndpoint, {
        authenticity_token: window.authenticity_token,
      })

      if (message) {
        window.flash(message)
      }

      setRegistered(false)
    } catch (e) {
      window.flash('Something went wrong!', 'alert')
    } finally {
      setLoading(false)
    }
  }, [setLoading, setRegistered])

  return (
    <button
      className="button primary"
      disabled={loading}
      onClick={registered ? unregister : register}>
      {registered ? unregisterLabel : registerLabel}
    </button>
  )
}

export default RegisterToggleButton
