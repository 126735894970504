import { Theme } from 'stylesheets/theme'
import OrganizationHeaderNavWrapper, {
  OrganizationHeaderNavWrapperProps,
} from './OrganizationHeaderNavWrapper'
import Button, { ButtonComponentType } from 'components/Button'

interface AnonymousHeaderNavProps
  extends Omit<OrganizationHeaderNavWrapperProps, 'signedIn' | 'children'> {
  loginPath: string
}

export default function AnonymousHeaderNav({
  organization,
  rootPath,
  backgroundColor = null,
  theme = Theme.Light,
  otherOrgs = [],
  loginPath,
}: AnonymousHeaderNavProps): JSX.Element {
  return (
    <OrganizationHeaderNavWrapper
      organization={organization}
      rootPath={rootPath}
      backgroundColor={backgroundColor}
      theme={theme}
      otherOrgs={otherOrgs}>
      <Button as={ButtonComponentType.LINK} href={loginPath}>
        Log In
      </Button>
    </OrganizationHeaderNavWrapper>
  )
}
