import React from 'react'
import cx from 'classnames'

import './index.scss'

interface IFormHeaderProps {
  className?: string
  title: string
  description?: string | React.ReactNode
  children?: React.ReactNode
}

const FormHeader: React.FC<IFormHeaderProps> = ({
  className,
  title,
  description = <div className="required-mark">Required*</div>,
  children,
}): React.ReactElement => {
  return (
    <div className={cx(className, 'header')}>
      <div className="title">
        <h2>{title}</h2>
        {description}
      </div>
      <div className="buttons-container">{children}</div>
    </div>
  )
}

export default FormHeader
