import cx from 'classnames'

interface NavigationTabsProps {
  activeTab: 'homepage' | 'communities' | 'events' | 'calls' | 'cohorts'
  cohortTabVisible?: boolean
  className?: string
}

export default function NavigationTabs({
  className = '',
  activeTab,
  cohortTabVisible = false,
}: NavigationTabsProps): JSX.Element {
  return (
    <div className="navigation-tabs-wrapper">
      <ul className={`navigation-tabs container ${className}`}>
        <li className={cx('tab', { active: activeTab == 'homepage' })}>
          <a href="/">My Dashboard</a>
        </li>
        <li className={cx('tab', { active: activeTab == 'communities' })}>
          <a href="/communities">Communities</a>
        </li>
        {cohortTabVisible && (
          <li className={cx('tab', { active: activeTab == 'cohorts' })}>
            <a href="/cohorts">Cohorts</a>
          </li>
        )}
        <li className={cx('tab', { active: activeTab == 'events' })}>
          <a href="/events">Events</a>
        </li>
        <li className={cx('tab', { active: activeTab == 'calls' })}>
          <a href="/calls">Calls to Action</a>
        </li>
      </ul>
    </div>
  )
}
