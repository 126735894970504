import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'

export const SIdpSettingsList = css({
  display: 'flex',
  flexDirection: 'column',
  gap: 2 * spacings.grid_gap_basis_num,
})

export const SIdpSettingsListActionRow = css({
  marginTop: 2 * spacings.grid_gap_basis_num,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
})

export const SPreviewImageWrapper = css({
  width: 64,
  height: 64,
  borderRadius: 64,
  backgroundColor: colors.backgrounds.white,
  overflow: 'hidden',
  img: {
    maxHeight: '100%',
    maxWidth: '100%',
  },
})
