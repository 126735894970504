import { css } from '@emotion/react'

import EmptyPlaceholder from 'components/EmptyPlaceholder'
import { spacings } from 'stylesheets/theme'

interface IEmptyStateProps {
  type: 'events' | 'calls'
  subscriptions: boolean
}

const emptyStateStyle = css({
  height: 'auto',
  minHeight: spacings.grid_gap_basis_num * 21,
})

const EmptyState = ({
  type,
  subscriptions,
}: IEmptyStateProps): React.ReactElement => {
  switch (type) {
    case 'events':
      return subscriptions ? (
        <EmptyPlaceholder
          text="No upcoming events in your communities."
          link={{ href: '/events', label: 'Explore ALL Events' }}
          css={emptyStateStyle}
        />
      ) : (
        <EmptyPlaceholder text="No upcoming events." css={emptyStateStyle} />
      )
    case 'calls':
      return subscriptions ? (
        <EmptyPlaceholder
          text="No current calls to action in your communities."
          link={{ href: '/calls', label: 'Explore ALL Calls to Action' }}
          css={emptyStateStyle}
        />
      ) : (
        <EmptyPlaceholder
          text="No current calls to action."
          css={emptyStateStyle}
        />
      )
  }
}

export default EmptyState
