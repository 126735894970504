import { css } from '@emotion/react'
import { SectionTitle } from 'components/Typography'
import { boxShadow, colors, spacings } from 'stylesheets/theme'
import { AgendaItem } from 'types'

interface AgendaProps {
  timelines: AgendaItem[]
}

const tableStyle = css({
  backgroundColor: colors.backgrounds.white,
  boxShadow: boxShadow.container,
  padding: 18,
  tableLayout: 'auto',
  borderRadius: spacings.radius,
  width: '100%',
  td: {
    verticalAlign: 'top',
  },
})

const agendaItemTimeStyle = css({
  display: 'table-cell',
  color: colors.green_dark,
  fontWeight: '700',
  whiteSpace: 'nowrap',
  minWidth: 'fit-content',
  paddingRight: spacings.grid_gap_basis_num / 2,
})

const agendaItemNameStyle = css({
  display: 'table-cell',
  color: colors.text.text_3,
  maxWidth: 342,
  width: '99%',
})

export default function Agenda({ timelines }: AgendaProps): JSX.Element {
  return (
    <div>
      <SectionTitle
        css={{
          marginBottom: spacings.grid_gap_basis_num * 2,
        }}>
        Agenda
      </SectionTitle>
      <table css={tableStyle}>
        {timelines.map((agendaItem) => (
          <tr key={agendaItem.name}>
            <p css={agendaItemTimeStyle}>{agendaItem.test}</p>
            <p css={agendaItemNameStyle}>{agendaItem.name}</p>
          </tr>
        ))}
      </table>
    </div>
  )
}
