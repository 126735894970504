import React, { useCallback, useState } from 'react'
import { capitalize } from 'lodash'

import ConfirmationModal from 'components/ConfirmationModal'
import { FormFieldContainer, FormSection } from 'components/Forms'
import { Select, TimePicker, TimezonePicker } from 'components/Inputs'

import './OrganizationDigestFields.scss'

import type { TTimezone } from 'types'

export enum TDigestFrequencyType {
  OFF = '100',
  WEEKLY = '0',
  MONTHLY = '1',
}

export enum TWeeksInMonth {
  First = '0',
  Second = '1',
  Third = '2',
  Fourth = '3',
}

export enum TDays {
  Sunday = '0',
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
}

interface IOrganizationDigestFields {
  defaultFrequency?: TDigestFrequencyType
  defaultMonthWeek: TWeeksInMonth
  defaultWeekDay: TDays
  defaultTime: string
  defaultTimezone: string
  timezones: TTimezone[]
  errors: Record<string, string[]>
}

const FREQUENCY_OPTIONS = Object.keys(TDigestFrequencyType)
  .filter((f) => Number.isNaN(parseInt(f)))
  .map((f) => ({
    label: capitalize(f),
    value: TDigestFrequencyType[f],
  }))

const WEEKS_IN_MONTH_OPTIONS = Object.keys(TWeeksInMonth)
  .filter((f) => Number.isNaN(parseInt(f)))
  .map((f) => ({
    label: f,
    value: TWeeksInMonth[f],
  }))

const DAYS_IN_WEEK_OPTIONS = Object.keys(TDays)
  .filter((f) => Number.isNaN(parseInt(f)))
  .map((f) => ({
    label: f,
    value: TDays[f],
  }))

const OrganizationDigestFields = ({
  defaultFrequency,
  defaultMonthWeek,
  defaultWeekDay,
  defaultTime,
  defaultTimezone,
  timezones,
  errors = {},
}: IOrganizationDigestFields): JSX.Element => {
  const [frequency, setFrequency] = useState(
    defaultFrequency || TDigestFrequencyType.OFF,
  )
  const [nextValue, setNextValue] = useState<TDigestFrequencyType | null>(null)

  const onFrequencyChange = useCallback(
    (data) => {
      const newValue = data[
        'organization[digest_frequency]'
      ] as TDigestFrequencyType
      if (frequency !== newValue) {
        if (frequency === TDigestFrequencyType.OFF) {
          setNextValue(newValue)
        } else {
          setFrequency(newValue)
        }
      }
    },
    [frequency],
  )

  return (
    <FormSection title="Digest Emails" className="organization-digest-fields">
      <>
        <ConfirmationModal
          isOpen={nextValue !== null}
          title="Should all users receive a digest?"
          onConfirm={() => {
            setFrequency(nextValue || TDigestFrequencyType.OFF)
            setNextValue(null)
          }}
          onCancel={() => {
            setNextValue(null)
          }}>
          Digests include active calls to actions and upcoming events.
          Individual users will be able to unsubscribe from the digest emails.
        </ConfirmationModal>
        <FormFieldContainer
          label="Frequency"
          fieldId="organization-digest-frequency"
          className="organization-digest-frequency"
          required
          error={!!errors['digest_frequency']}>
          <Select
            id="organization-digest-frequency"
            name="organization[digest_frequency]"
            value={frequency}
            options={FREQUENCY_OPTIONS}
            className="digest-frequency"
            onChange={onFrequencyChange}
            errors={errors['digest_frequency']}
          />
        </FormFieldContainer>
        <div className="digest-time-row">
          {frequency === TDigestFrequencyType.MONTHLY && (
            <FormFieldContainer
              label="Send on"
              fieldId="organization-digest-month-week"
              className="organization-digest-month-week"
              required
              error={!!errors['digest_month_week']}>
              <Select
                id="organization-digest-month-week"
                name="organization[digest_month_week]"
                defaultValue={defaultMonthWeek}
                options={WEEKS_IN_MONTH_OPTIONS}
                className="digest-month-week"
                errors={errors['digest_month_week']}
              />
            </FormFieldContainer>
          )}
          {frequency !== TDigestFrequencyType.OFF && (
            <FormFieldContainer
              label="Send on"
              fieldId="organization-digest-week-day"
              className="organization-digest-week-day"
              required
              error={!!errors['digest_week_day']}>
              <Select
                id="organization-digest-week-day"
                name="organization[digest_week_day]"
                defaultValue={defaultWeekDay}
                options={DAYS_IN_WEEK_OPTIONS}
                className="digest-week-day"
                errors={errors['digest_week_day']}
              />
            </FormFieldContainer>
          )}
          {frequency !== TDigestFrequencyType.OFF && (
            <>
              <FormFieldContainer
                label="At"
                fieldId="organization-digest-time"
                className="organization-digest-time"
                required
                error={!!errors['formatted_digest_time']}>
                <TimePicker
                  id="organization-digest-time"
                  name="organization[formatted_digest_time]"
                  defaultValue={defaultTime}
                  errors={errors['formatted_digest_time']}
                />
              </FormFieldContainer>
              <FormFieldContainer
                label="At"
                fieldId="organization-digest-timezone"
                className="organization-digest-timezone"
                required
                error={!!errors['digest_timezone']}>
                <TimezonePicker
                  id="organization-digest-timezone"
                  name="organization[digest_timezone]"
                  defaultTimezone={defaultTimezone}
                  timezones={timezones}
                  className="digest-timezone"
                  errors={errors['digest_timezone']}
                />
              </FormFieldContainer>
            </>
          )}
        </div>
      </>
    </FormSection>
  )
}

export default OrganizationDigestFields
