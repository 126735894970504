import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from '../modal-style'
import Input from 'components/Input'
import Pill, { PillColor } from 'components/Pill'
import { css } from '@emotion/react'
import { useCallback, useEffect, useState } from 'react'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import { FormFieldsRow } from 'components/Forms'
import Axios, { AxiosResponse } from 'axios'

import RedoIcon from 'images/icons/redo.svg'
import { colors, spacings } from 'stylesheets/theme'

const textareaCss = css({
  '.input-wrapper textarea': {
    minHeight: 250,
    marginBottom: 0,
  },
  '.input-wrapper p.caption': {
    marginBottom: 0.5 * spacings.grid_gap_basis_num,
  },
})

interface IEditMatchReasoningModalProps
  extends Omit<
    IModalProps,
    'size' | 'children' | 'submitButton' | 'cancelButton' | 'title' | 'onSave'
  > {
  manuallyMatched?: boolean
  matchedBy?: string
  matchReasoning?: string
  regenerateReasonUrl?: string
  partner1Name?: string
  partner2Name?: string
  updateReasoning?: (reason: string) => void
  onSave?: (matchReasoning: string) => void
}

interface IAjaxResponse {
  reason: string
}

export default function EditMatchReasoningModal({
  manuallyMatched = false,
  matchedBy,
  matchReasoning,
  regenerateReasonUrl,
  partner1Name,
  partner2Name,
  updateReasoning,
  onSave,
  ...props
}: IEditMatchReasoningModalProps): JSX.Element {
  // const [openRegenerateReasonModal, setOpenRegenerateReasonModal] =
  //   useState(false)
  const [currentReasoning, setCurrentReasoning] = useState(matchReasoning)
  useEffect(() => {
    setCurrentReasoning(matchReasoning)
  }, [matchReasoning])

  const fieldName = 'match_reasoning'
  const MatchedByPill = () => (
    <Pill
      size="large"
      color={PillColor.GREEN}
      light
      textTransform={false}
      bold={false}>
      Manually matched by {matchedBy}
    </Pill>
  )

  const regenReason = useCallback(() => {
    Axios.post(regenerateReasonUrl, {
      authenticity_token: window.authenticity_token,
    })
      .catch(() => {
        window.flash('Could not regenerate that match reason.', 'error')
      })
      .then(({ data: { reason } }: AxiosResponse<IAjaxResponse>) => {
        if (reason) {
          setCurrentReasoning(reason)
        } else {
          window.flash('Could not regenerate that match reason.', 'error')
        }
      })
  }, [regenerateReasonUrl, matchReasoning])

  return (
    <>
      <Modal
        {...props}
        css={modalStyle}
        actionRowLeftElement={
          <Button
            onClick={regenReason}
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            startIcon={
              <RedoIcon
                css={{
                  marginRight: spacings.grid_gap_basis_num / 2,
                }}
              />
            }
            title="Regenerates the match reason based on the raw matching data. This overwrites any previously entered match reason.">
            regenerate
          </Button>
        }
        size={Size.large}
        submitButton="Save"
        cancelButton="Cancel"
        title="Match reasoning"
        onSave={() => {
          onSave(currentReasoning.trim())
          updateReasoning(currentReasoning.trim())
        }}
        onRequestClose={() => {
          setCurrentReasoning(matchReasoning)
          onSave(matchReasoning)
        }}>
        <Input
          css={textareaCss}
          type="textarea"
          name={fieldName}
          value={currentReasoning ?? ''}
          onChange={(value: { [fieldName]: string }) => {
            setCurrentReasoning(value[fieldName])
          }}
          hideName
        />
        <span
          css={{
            color: colors.text.text_9,
            fontStyle: 'italic',
            fontSize: 13,
            marginTop: -24,
            display: 'block',
          }}>
          Between <b css={{ color: colors.text.text_7c }}>{partner1Name}</b> and{' '}
          <b css={{ color: colors.text.text_7c }}>{partner2Name}</b>
        </span>
        <FormFieldsRow>
          {manuallyMatched && matchedBy ? <MatchedByPill /> : undefined}
        </FormFieldsRow>
      </Modal>
      {/* Possibly enabling this confirmation modal in the future for regenerating reasoning
        <Modal
          isOpen={openRegenerateReasonModal}
          size={Size.large}
          title="Are you sure you want to regenerate the reasoning?"
          submitButton="Confirm"
          cancelButton="Cancel"
          onSave={() => {
            regenReason()
            setOpenRegenerateReasonModal(false)
          }}
          onRequestClose={() => setOpenRegenerateReasonModal(false)}
          largeTitle
          css={{
            '.mindr-modal-body': {
              color: colors.text.text_3,
            },
          }}>
          This action will restore the match reasoning, overwriting any previously
          entered text. This action cannot be undone.
        </Modal> 
      */}
    </>
  )
}
