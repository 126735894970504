import { css } from '@emotion/react'
import { FormFieldContainer, FormSection } from 'components/Forms'
import { colors, spacings } from 'stylesheets/theme'
import { useState, useEffect, useMemo } from 'react'
import ColorPicker from './ColorPicker'
import PreviewOrganizationHeaderNav from './PreviewOrganizationHeaderNav'
import PreviewEmailHeader from './PreviewEmailHeader'
import { findTheme, isHex } from './themeHelper'
import BrandingContext from './BrandingContext'
import BrandingImagePreview from './BrandingImagePreview'
import { OrganizationSwitch } from 'components/HeaderNav/types'
import { Theme } from 'stylesheets/theme'
import getContrastRatio from 'get-contrast-ratio'

interface BrandingProps {
  organization: OrganizationSwitch
  brandColor?: string
  avatarUrl?: string
  userInitial?: string
  defaultTheme: Theme
}

const previewWrapper = css({
  display: 'flex',
  padding: '18px 57px 0px 60px',
  justifyContent: 'center',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: 6,
  border: `1px solid ${colors.borders.gray}`,
  backgroundColor: colors.backgrounds.gray,
  width: '100%',
  overflowY: 'hidden',
  overflowX: 'hidden',
})

const formWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  marginTop: spacings.grid_gap_basis_num * 2,
  background: colors.backgrounds.white,
  padding: spacings.grid_gap_basis_num * 2,
})

const logoAndColorWrapper = css({
  display: 'flex',
  justifyContent: 'flex-start',
  width: '100%',
})

export default function Branding({
  brandColor = 'FFFFFF',
  organization,
  avatarUrl = null,
  userInitial = null,
  defaultTheme,
}: BrandingProps): JSX.Element {
  const [currentBrandColor, setCurrentBrandColor] = useState(
    brandColor || 'FFFFFF',
  )
  const [currentColor, setCurrentColor] = useState(brandColor || 'FFFFFF')
  const [currentTheme, setCurrentTheme] = useState(Theme.Light)
  const [theme, setTheme] = useState(defaultTheme)
  const [logo, setLogo] = useState(null)

  const DARK_FONT = '#555555'
  const LIGHT_FONT = '#FFFFFF'

  useEffect(() => {
    setCurrentTheme(isHex(currentColor) ? findTheme('#' + currentColor) : theme)
  }, [currentColor, theme])

  /**
   * Sets the theme in the preview. This is NOT the actual theme for the color.
   * This returns a theme that produce a higher contrast. It is only used in the preview as user is
   * using the color picker to pick a color, so that it is easier on the eyes. The color being picked can
   * very well be an invalid color.
   */
  const currentHigherContrastTheme = useMemo(() => {
    if (currentColor && isHex(currentColor)) {
      return getContrastRatio(LIGHT_FONT, '#' + currentColor) >
        getContrastRatio(DARK_FONT, '#' + currentColor)
        ? Theme.Dark
        : Theme.Light
    }

    return Theme.Light
  }, [currentColor])

  useEffect(() => {
    if (organization) {
      setLogo(organization.logo_url)
    }
  }, [organization])

  useEffect(() => {
    if (currentBrandColor && isHex(currentBrandColor)) {
      const theme = findTheme('#' + currentBrandColor)
      setTheme(theme)
    }
  }, [currentBrandColor])

  return (
    <BrandingContext.Provider
      value={{
        organization,
        brandColor: currentBrandColor,
        setBrandColor: setCurrentBrandColor,
        color: currentColor,
        setColor: setCurrentColor,
        theme,
        setTheme,
        logo,
        setLogo,
        findTheme,
        currentTheme,
        setCurrentTheme,
        currentHigherContrastTheme,
      }}>
      <FormSection
        title="Organization Branding"
        description="Upload a logo and select a color to represent your organization, then use the preview below to see your selections in real time. These colors will be showcased on Mindr Connect email's and the top navigation bar."
        css={{
          width: '928px',
          '&&': {
            padding: 0,
            margin: '40px 60px 0',
          },
        }}
        id="branding-form-section">
        <div css={formWrapper}>
          <div css={logoAndColorWrapper}>
            <FormFieldContainer
              label="Logo*"
              fieldId="organization-logo"
              css={{ marginRight: 220 }}>
              <BrandingImagePreview />
            </FormFieldContainer>
            <FormFieldContainer
              label="Color"
              fieldId="organization-brand-color">
              <ColorPicker />
            </FormFieldContainer>
          </div>
          <FormFieldContainer
            label="Top navigation bar and email header"
            fieldId=""
            css={{
              '#branding-form-section &': {
                maxWidth: 'none',
              },
            }}>
            <div
              css={[
                previewWrapper,
                css({
                  height: spacings.grid_gap_basis_num * 3 + 42 + 20,
                }),
              ]}>
              <PreviewOrganizationHeaderNav
                avatarUrl={avatarUrl}
                userInitial={userInitial}
              />
            </div>
            <div
              css={[
                previewWrapper,
                css({
                  marginTop: spacings.grid_gap_basis_num * 2,
                  height: 113,
                }),
              ]}>
              <PreviewEmailHeader />
            </div>
          </FormFieldContainer>
        </div>
      </FormSection>
    </BrandingContext.Provider>
  )
}
