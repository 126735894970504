import React, {
  createContext,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'
import axios from 'axios'
import { noop } from 'lodash'

import type { IEventCall, TBadge } from 'types'

export enum ModalType {
  badges,
  leaveConfirmation,
  registerConfirmation,
  submitQuestions,
}

interface IEventActionProps {
  event: IEventCall
  children: React.ReactNode
}

interface IEventActionState {
  loading: boolean
  registered: boolean
  waitlisted: boolean
  badge?: TBadge
  modalToShow: ModalType | null
  onRegister: () => void
  onUnregister: () => void
  onConfirmUnregister: () => void
  onCancelUnregister: () => void
  onJoinWaitlist: () => void
  onLeaveWaitlist: () => void
  onSubmitQuestions: (notes: string, anonymous: boolean) => void
  onCloseConfirmation: () => void
  onCloseSubmitQuestionsConfirmation: () => void
  onCloseBadgeModal: () => void
  onShowSubmitQuestions: () => void
}

const EventAction = createContext<IEventActionState>({
  loading: false,
  registered: false,
  waitlisted: false,
  badge: undefined,
  modalToShow: null,
  onRegister: noop,
  onUnregister: noop,
  onConfirmUnregister: noop,
  onCancelUnregister: noop,
  onJoinWaitlist: noop,
  onLeaveWaitlist: noop,
  onSubmitQuestions: noop,
  onCloseConfirmation: noop,
  onCloseSubmitQuestionsConfirmation: noop,
  onCloseBadgeModal: noop,
  onShowSubmitQuestions: noop,
})

export const EventActionContext = ({
  event,
  children,
}: IEventActionProps): React.ReactElement => {
  const [loading, setLoading] = useState(false)
  const [registered, setRegistered] = useState(event.registered)
  const [waitlisted, setWaitlisted] = useState(event.waitlisted)
  const [newBadges, setNewBadges] = useState<TBadge[]>([])
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [showSubmitQuestions, setShowSubmitQuestions] = useState<boolean>(false)

  const baseUrl = `/events/${event.id}`

  useEffect(() => {
    setRegistered(event.registered)
    setWaitlisted(event.waitlisted)
  }, [event])

  const onJoinWaitlist = useCallback(async () => {
    setLoading(true)

    try {
      const {
        data: {
          data: { message },
        },
      } = await axios.post(`${baseUrl}/add_to_waitlist.json`, {
        authenticity_token: window.authenticity_token,
      })

      if (message) {
        window.flash(message)
      }

      setWaitlisted(true)
    } catch (e) {
      window.flash('Something went wrong!', 'alert')
    } finally {
      setLoading(false)
    }
  }, [setLoading, setWaitlisted])

  const onLeaveWaitlist = useCallback(async () => {
    setLoading(true)

    try {
      const {
        data: {
          data: { message },
        },
      } = await axios.post(`${baseUrl}/remove_from_waitlist.json`, {
        authenticity_token: window.authenticity_token,
      })

      if (message) {
        window.flash(message)
      }

      setWaitlisted(false)
    } catch (e) {
      window.flash('Something went wrong!', 'alert')
    } finally {
      setLoading(false)
    }
  }, [setLoading, setWaitlisted])

  const onRegister = useCallback(async () => {
    setLoading(true)

    try {
      const {
        data: { data },
      } = await axios.post(`${baseUrl}/register.json`, {
        authenticity_token: window.authenticity_token,
        // update_recurring_events,
      })
      setNewBadges(data.new_badges)
      setShowConfirmation(true)
    } catch (error) {
      const {
        data: { message },
      } = error.response.data
      window.flash(message || 'Something went wrong!', 'alert')
    } finally {
      setLoading(false)
    }
  }, [setLoading, setNewBadges, setShowConfirmation])

  const onUnregister = useCallback(() => {
    setShowConfirmation(true)
  }, [setShowConfirmation])

  const onConfirmUnregister = useCallback(async () => {
    setLoading(true)
    try {
      const {
        data: {
          data: { message },
        },
      } = await axios.post(`${baseUrl}/unregister.json`, {
        authenticity_token: window.authenticity_token,
        // update_recurring_events,
      })

      if (message) {
        window.flash(message)
      }

      setShowConfirmation(false)
      setRegistered(false)
    } catch {
      window.flash('Something went wrong!', 'alert')
    } finally {
      setLoading(false)
    }
  }, [setRegistered])

  const onCancelUnregister = useCallback(() => {
    setShowConfirmation(false)
  }, [setShowConfirmation])

  const onCloseConfirmation = useCallback(() => {
    window.flash(`Success! You are now registered to ${event.title}.`)
    setShowConfirmation(false)
    setRegistered(true)
  }, [event, setRegistered, setShowConfirmation])

  const onCloseSubmitQuestionsConfirmation = useCallback(() => {
    setShowSubmitQuestions(false)
  }, [setShowSubmitQuestions])

  const onSubmitQuestions = useCallback(
    async (notes: string, anonymous: boolean) => {
      setLoading(true)

      try {
        await axios.post(`${baseUrl}/share.json`, {
          authenticity_token: window.authenticity_token,
          notes: notes,
          anonymous: anonymous,
        })
        window.flash(`Success! You have submitted questions for this event.`)
        setRegistered(true)
      } catch {
        window.flash('Something went wrong!', 'alert')
      } finally {
        setShowConfirmation(false)
        setShowSubmitQuestions(false)
        setLoading(false)
      }
    },
    [event, setShowConfirmation, setRegistered],
  )

  const onCloseBadgeModal = useCallback(() => {
    setNewBadges(newBadges.slice(1))
  }, [newBadges, setNewBadges])

  const onShowSubmitQuestions = useCallback(() => {
    setShowSubmitQuestions(true)
  }, [showSubmitQuestions])

  const modalToShow: ModalType | null = useMemo(() => {
    if (newBadges.length > 0) {
      return ModalType.badges
    }

    if (showConfirmation) {
      if (registered) {
        return ModalType.leaveConfirmation
      }

      return ModalType.registerConfirmation
    }

    if (showSubmitQuestions) {
      return ModalType.submitQuestions
    }

    return null
  }, [showConfirmation, registered, newBadges, showSubmitQuestions])

  return (
    <EventAction.Provider
      value={{
        loading,
        registered,
        waitlisted,
        badge: newBadges[0],
        modalToShow,
        onRegister,
        onUnregister,
        onConfirmUnregister,
        onCancelUnregister,
        onJoinWaitlist,
        onLeaveWaitlist,
        onSubmitQuestions,
        onCloseConfirmation,
        onCloseSubmitQuestionsConfirmation,
        onCloseBadgeModal,
        onShowSubmitQuestions,
      }}>
      {children}
    </EventAction.Provider>
  )
}

export const useEventActionContext = (): IEventActionState =>
  useContext(EventAction)
