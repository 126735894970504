import { useMemo } from 'react'
import { IArchetype } from './LeadrArchetype.types'
import { SArchetypeBadgeStyle } from './LeadrArchetype.styles'

interface IArchetypeProps {
  archetype: IArchetype
  small?: boolean
}

export default function LeadrArchetype({
  archetype,
  small,
}: IArchetypeProps): JSX.Element {
  const classes = useMemo(() => {
    const cls = [`archetype-${archetype.leadr}`]
    if (small) {
      cls.push('archetype-small')
    }
    return cls.join(' ')
  }, [archetype, small])

  // Split the letters into two single-letter spans. This helps with positioning.
  // NOTE: letter-spacing does not look as nice, because it adds spacing to the end too.
  const letters = useMemo(() => {
    return archetype.leadr
      .split('')
      .map((letter, index) => <span key={`letter-${index}`}>{letter}</span>)
  }, [archetype])

  return (
    <div css={SArchetypeBadgeStyle} className={classes} title={archetype.name}>
      {letters}
    </div>
  )
}
