import { css } from '@emotion/react'
import { OrganizationSwitch } from './types'

interface LogoProps {
  organization: OrganizationSwitch
  isInactive?: boolean
  path?: string
  className?: string
  viewOnly?: boolean
  logoUrl?: string
  isMobile?: boolean
}

export const logoHeight = 32

const logoStyle = css({
  display: 'flex',
  alignItems: 'center',
  height: logoHeight,
  textDecoration: 'none',
  '&:hover': {
    opacity: 0.8,
  },
  img: {
    display: 'block',
    height: logoHeight,
    width: logoHeight,
    borderRadius: '50%',
    marginRight: 9,
  },
  span: {
    display: 'inline-block',
    textIndent: '-10000px',
  },
})

export default function Logo({
  organization,
  isInactive = false,
  path = null,
  className = null,
  viewOnly = false,
  logoUrl = null,
  isMobile = false,
}: LogoProps): JSX.Element {
  return (
    <a
      css={logoStyle}
      href={viewOnly ? undefined : path || `//${organization.host}`}
      className={className}>
      <img
        aria-label="organization logo"
        src={logoUrl || organization.logo_url}></img>
      {!isMobile &&
        (isInactive ? (
          <span>{organization.link_title}</span>
        ) : (
          <h3>{organization.public_title}</h3>
        ))}
    </a>
  )
}
