import { ReactNode } from 'react'

export interface IOptionalHeadingProps {
  children: ReactNode
}

export default function OptionalHeading({
  children,
}: IOptionalHeadingProps): JSX.Element {
  return (
    <h2>
      {children} <mark className="optional">(OPTIONAL)</mark>
    </h2>
  )
}
