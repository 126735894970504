import { css } from '@emotion/react'
import { colors, fonts } from 'stylesheets/theme'

export const SArchetypeBadgeStyle = css({
  '--size': `${70}px`,
  '--font-size': `${27}px`,
  width: 'var(--size)',
  height: 'var(--size)',
  aspectRatio: 1.0,
  flexShrink: 0,
  flexGrow: 0,
  borderRadius: 'var(--size)',
  display: 'flex',
  flexDirection: 'row',
  gap: 3,
  alignItems: 'center',
  justifyContent: 'center',
  fontFamily: fonts.poppins,
  fontSize: 'var(--font-size)',
  fontWeight: 700,
  color: colors.backgrounds.white,

  '&.archetype-small': {
    '--size': `${40}px`,
    '--font-size': `${19}px`,
  },

  '&.archetype-EL': {
    background: 'linear-gradient(90deg, #4197DD 15%, #3C9F95 85%)',
  },
  '&.archetype-EA': {
    background: 'linear-gradient(90deg, #4197DD 15%, #DE7C0D 85%)',
  },
  '&.archetype-ED': {
    background: 'linear-gradient(90deg, #4197DD 15%, #94519F 85%)',
  },
  '&.archetype-AL': {
    background: 'linear-gradient(90deg, #DE7C0D 30%, #3C9F95 85%)',
  },
  '&.archetype-DA': {
    background: 'linear-gradient(90deg, #94519F 30%, #DE7C0D 85%)',
  },
  '&.archetype-DL': {
    background: 'linear-gradient(90deg, #94519F 15%, #3C9F95 85%)',
  },
  '&.archetype-RL': {
    background: 'linear-gradient(90deg, #CC4072 25%, #3C9F95 85%)',
  },
  '&.archetype-RE': {
    background: 'linear-gradient(90deg, #CC4072 15%, #4197DD 85%)',
  },
  '&.archetype-RA': {
    background: 'linear-gradient(90deg, #CC4072 30%, #DE7C0D 85%)',
  },
  '&.archetype-RD': {
    background:
      'linear-gradient(90deg, #CC4072 15%, #94519F 85%), linear-gradient(90deg, #CC4072 30%, #94519F 85%)',
  },
})
