import Axios from 'axios'
import { ClassNames } from '@emotion/react'

async function onAdmit(url: string, user_id: number): Promise<void> {
  try {
    const {
      data: {
        data: { message },
      },
    } = await Axios.post(url, {
      authenticity_token: window.authenticity_token,
      waitlisted_user_id: user_id,
    })

    if (message) {
      window.flash(message)
    }
    location.reload()
  } catch (e) {
    window.flash('Something went wrong', 'alert')
  }
}

interface AdmitUserData {
  id: number
  full_name: string
  full_name_html: string
  created_at: string
  admit_url: string
}

interface AdmitButtonProps {
  data: AdmitUserData
  className?: string
}

export default function AdmitButton({
  data,
  className,
}: AdmitButtonProps): JSX.Element {
  return (
    <ClassNames>
      {({ cx }) => (
        <button
          className={cx('button secondary', className)}
          onClick={() => onAdmit(data.admit_url, data.id)}>
          Admit
        </button>
      )}
    </ClassNames>
  )
}
