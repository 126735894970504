import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import { ITruncatedMentorshipApplication } from './types'
import Spinner from 'images/icons/spinner.svg'
import { TableCellSecondaryButton } from 'components/Table/components/TableCell'
import Container from 'components/Container'

interface MatchesRemovedActionsCellProps {
  data: ITruncatedMentorshipApplication
}

export default function MatchesRemovedActionsCell({
  data,
}: MatchesRemovedActionsCellProps): JSX.Element {
  const { setRestoreModalObject, setRemoveNoteModalObject, matchesLoading } =
    useMentorshipExchangeDetailsContext()

  if (matchesLoading[data.id]) {
    return (
      <Spinner
        css={{
          width: 32,
          height: 32,
        }}
      />
    )
  }

  return (
    <Container
      css={{
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
      <TableCellSecondaryButton
        onClick={() => {
          setRemoveNoteModalObject(data)
        }}
        className="ignore-goji">
        Notes
      </TableCellSecondaryButton>
      <TableCellSecondaryButton
        onClick={() => {
          setRestoreModalObject(data)
        }}
        className="ignore-goji">
        Restore
      </TableCellSecondaryButton>
    </Container>
  )
}
