import { css } from '@emotion/react'
import ProvidedByLogoDefault from 'images/provided_by_logo.svg'
import ProvidedByLogoLight from 'images/provided_by_logo_light.svg'
import ProvidedByLogoDark from 'images/provided_by_logo_dark.svg'
import MobileProvidedByLogoDefault from 'images/mobile_powered_by_logo.svg'
import MobileProvidedByLogoLight from 'images/mobile_powered_by_logo_light.svg'
import MobileProvidedByLogoDark from 'images/mobile_powered_by_logo_dark.svg'

import { Theme } from 'stylesheets/theme'

const providedByLogoStyle = css({
  display: 'block',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  '&:hover': {
    opacity: 0.8,
  },
})

const providedByLogoSvgStyle = css({
  width: 220,
  height: 17,
})

const mobileProvidedByLogoSvgStyle = css({
  width: 250,
  height: 30,
})

interface ProvidedByLogoProps {
  color?: string
  theme?: Theme
  className?: string
  viewOnly?: boolean
  isMobile?: boolean
}

function Logo({
  color,
  theme,
  isMobile = false,
}: Omit<ProvidedByLogoProps, 'viewOnly'>): JSX.Element {
  if (!color || color.toUpperCase() === 'FFFFFF') {
    return isMobile ? (
      <MobileProvidedByLogoDefault css={mobileProvidedByLogoSvgStyle} />
    ) : (
      <ProvidedByLogoDefault css={providedByLogoSvgStyle} />
    )
  }

  if (theme === Theme.Light) {
    return isMobile ? (
      <MobileProvidedByLogoLight css={mobileProvidedByLogoSvgStyle} />
    ) : (
      <ProvidedByLogoLight css={providedByLogoSvgStyle} />
    )
  }

  if (theme === Theme.Dark) {
    return isMobile ? (
      <MobileProvidedByLogoDark css={mobileProvidedByLogoSvgStyle} />
    ) : (
      <ProvidedByLogoDark css={providedByLogoSvgStyle} />
    )
  }

  return isMobile ? (
    <MobileProvidedByLogoDefault css={mobileProvidedByLogoSvgStyle} />
  ) : (
    <ProvidedByLogoDefault css={providedByLogoSvgStyle} />
  )
}

export default function ProvidedByLogo({
  color = 'FFFFFF',
  theme = Theme.Light,
  className = '',
  viewOnly = false,
  isMobile = false,
}: ProvidedByLogoProps): JSX.Element {
  return (
    <a
      role="link"
      aria-label="mindr logo"
      target="_blank"
      rel="noreferrer"
      href={viewOnly ? undefined : 'https://www.mindrglobal.com/#mindr_video'}
      css={providedByLogoStyle}
      className={className}>
      <Logo color={color} theme={theme} isMobile={isMobile} />
    </a>
  )
}
