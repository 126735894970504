import Button, { ButtonComponentType, Variant } from 'components/Button'
import {
  SMaybeSkipAheadActionsRow,
  SMaybeSkipAheadContent,
  SMaybeSkipAheadHeroImage,
  SMaybeSkipAheadIcon,
} from './DecisionPanel.style'
import { css } from '@emotion/react'
import { useMemo } from 'react'

interface IMaybeSkipAheadProps {
  heroImage: string
  title: string
  iconUrl?: string
  children: React.ReactNode
  onYes?: () => void
  onNo?: () => void
}

export default function MaybeSkipAhead(
  props: IMaybeSkipAheadProps,
): JSX.Element {
  const {
    iconUrl,
    title,
    children,
    heroImage,
    onYes = () => {},
    onNo = () => {},
  } = props
  const style = useMemo(
    () => css({ backgroundImage: `url(${iconUrl});` }),
    [iconUrl],
  )
  return (
    <div>
      <div css={SMaybeSkipAheadHeroImage}>
        <img src={heroImage} />
      </div>
      <div css={SMaybeSkipAheadContent}>
        {iconUrl ? (
          <div css={[SMaybeSkipAheadIcon, style]} data-img={iconUrl}></div>
        ) : null}
        <h3>{title}</h3>
        {children}
      </div>
      <div css={SMaybeSkipAheadActionsRow}>
        <Button
          as={ButtonComponentType.BUTTON}
          variant={Variant.PRIMARY}
          onClick={onYes}>
          YES
        </Button>
        <Button
          as={ButtonComponentType.BUTTON}
          variant={Variant.SECONDARY}
          onClick={onNo}>
          NO
        </Button>
      </div>
    </div>
  )
}
