import React, { ForwardedRef, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import Select, { OptionsType, components, ActionMeta } from 'react-select'
import AsyncSelect from 'react-select/async'
import type { getOptionLabel, getOptionValue } from 'react-select/src/builtins'
import $ from 'jquery'
import 'cropperjs'
import 'jquery-cropper'
import Swal from 'sweetalert2'
import convert from 'xml-js'

// styles
import { selectStyles } from 'components/helper-functions'

//icons
import CheckedIcon from '../../images/icons/checked.svg'
import RadioIcon from '../../images/icons/radio.svg'

import {
  asyncFilesUpload,
  changeLoadingFormStatusJQuery,
  changeLoadingFormStatus,
  IOption,
  defaultSwalParams,
} from 'javascripts/general'
import { Caption } from 'components/Typography'
import styles, { TOverrideStyles } from 'components/Inputs/Select/styles'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import Switch from 'components/Switch'

export type AllowClearDocumentCallback = (onAllow: () => void) => void

interface ICropped extends HTMLElement {
  cropper: Cropper
}

const aspectRatios = {
  banner: 95 / 12,
  tile: 77 / 42,
}

export interface IInput<T> {
  label?: string | React.ReactElement
  name: string
  inputName?: string
  fileFieldName?: string
  hiddenFieldValue?: string
  hiddenFieldRef?: React.RefObject<HTMLInputElement>
  id?: string
  type?: string
  required?: boolean
  options?: IOption[]
  placeholder?: string
  isMulti?: boolean
  checked?: boolean
  defaultChecked?: boolean
  className?: string
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionsType<T>) => void,
  ) => void
  isOptionDisabled?: (option: T, options: OptionsType<T>) => boolean
  wrapperClassName?: string
  imageType?: string
  onChange?: (inputData: Record<string, unknown>) => void
  onChangeDocument?: (documentData: string) => void
  onConfirmDeleteDocument?: AllowClearDocumentCallback
  value?: string | number | readonly string[]
  defaultValue?: string | number | readonly string[]
  uncheckedValue?: string | number
  checkedText?: string
  uncheckedText?: string
  indicateErrorWithoutText?: boolean
  error?: string
  timeFromError?: string
  readOnly?: boolean
  disabled?: boolean
  manualUpload?: boolean
  getOptionLabel?: getOptionLabel
  getOptionValue?: getOptionValue
  formatOptionLabel?: (option: any) => React.ReactNode
  getOptionKey?: (option: T) => string | number
  selectValue?: T | T[]
  hideName?: boolean
  noOptionsMessage?: string
  textPreview?: boolean
  textHeader?: string
  selectFileLabel?: string
  timeToName?: string
  inputTimeToName?: string
  timeToValue?: string
  cachedData?: string
  maxLength?: string
  mask?: string
  isClearable?: boolean
  deleteInputName?: string
  defaultOptions?: Array<T> | boolean
  captionPosition?: 'above' | 'between' | 'below'
  caption?: string | React.ReactElement
  isSearchable?: boolean
  styleOverrides?: TOverrideStyles
  imageData?: string
  hideFileFieldName?: boolean
}

const UPLOAD_MAPPING = {
  'csv-import': {
    endpoints: {
      presign: '/dashboard/subscriber_imports/presign',
      upload: '/dashboard/subscriber_imports/upload',
    },
    acceptType: '.csv',
  },
  'csv-mentorship-import': {
    endpoints: {
      presign: '/dashboard/mentorship_exchanges/presign',
      upload: '/dashboard/mentorship_exchanges/upload',
    },
    acceptType: '.csv',
  },
  __default: {
    endpoints: null,
    acceptType:
      '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pages,.numbers,.key,.jpeg,.jpg,.png,.gif',
  },
}

function InputWithForwardRef<T>(
  {
    label,
    type,
    name,
    inputName,
    fileFieldName,
    id,
    required,
    options,
    placeholder,
    isMulti,
    loadOptions,
    isOptionDisabled,
    wrapperClassName,
    imageType = 'round',
    onChange,
    onChangeDocument,
    onConfirmDeleteDocument,
    value,
    defaultValue,
    defaultChecked,
    uncheckedValue,
    checked,
    indicateErrorWithoutText = false,
    error,
    readOnly,
    disabled,
    getOptionLabel,
    getOptionValue,
    getOptionKey,
    formatOptionLabel,
    selectValue,
    hideName,
    noOptionsMessage,
    textPreview,
    textHeader,
    selectFileLabel,
    timeToName,
    timeToValue,
    inputTimeToName,
    cachedData,
    maxLength,
    timeFromError,
    manualUpload,
    mask,
    isClearable,
    deleteInputName,
    defaultOptions = true,
    caption,
    captionPosition = 'between',
    className = '',
    isSearchable = true,
    styleOverrides,
    imageData,
    hideFileFieldName = false,
    uncheckedText,
    checkedText,
  }: IInput<T>,
  ref: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>,
): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  useEffect(() => {
    if (type === 'date') {
      window.initDatePicker()
    }
    if (['time', 'timerange'].includes(type)) {
      window.initTimePicker()
    }
    if (type === 'timerange') {
      window.initTimeRange()
    }
    if (!!mask) {
      setTimeout(() => {
        window.initMasks()
      })
    }

    window.initMaxLengthInputs()
  }, [])

  const baseProps = {
    type,
    name: hideName ? undefined : inputName || name,
    id: id || inputName || name,
    required,
    checked: onChange ? checked || false : checked || undefined,
    defaultChecked: defaultChecked,
    placeholder,
    onChange: (e) => {
      if (onChange) {
        if (type === 'checkbox' || type === 'switch') {
          onChange({ [name]: e.target.checked })
        } else {
          onChange({ [name]: e.target.value })
        }
      }
    },
    value: onChange
      ? value || ''
      : typeof value !== 'undefined'
      ? value
      : undefined,
    defaultValue: defaultValue,
    className: classNames(
      {
        'validation-error': !!error || !!indicateErrorWithoutText,
        'numbers-only': type === 'numbers',
        'has-max-length': !!maxLength,
        'react-select': ['select', 'async_select'].includes(type),
        mask: !!mask,
      },
      className,
    ),
    readOnly,
    disabled,
    'data-inputmask': mask ? `'alias': '${mask}'` : undefined,
    autoComplete: 'off',
    ref: ref as React.ForwardedRef<HTMLInputElement>,
  }

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {noOptionsMessage}
      </components.NoOptionsMessage>
    )
  }

  const renderInput = () => {
    switch (type) {
      case 'date':
        return <input {...baseProps} type="text" className="datepicker" />
      case 'time':
        return <input {...baseProps} type="text" className="timepicker" />
      case 'timerange':
        return (
          <>
            <div className="input-wrapper time-input-wrapper">
              <input
                {...baseProps}
                type="text"
                placeholder="Start"
                className={classNames('timepicker time-from', {
                  'validation-error': !!timeFromError,
                })}
              />
              {timeFromError && (
                <span className="validation-error-message">
                  {timeFromError}
                </span>
              )}
            </div>
            <div className="input-wrapper time-input-wrapper">
              <input
                {...baseProps}
                id={`${baseProps.id}_time_to`}
                name={inputTimeToName || timeToName}
                type="text"
                placeholder="End"
                className={classNames('timepicker time-to', {
                  'validation-error': !!error,
                })}
                value={timeToValue || ''}
                onChange={(e) => {
                  if (onChange) {
                    onChange({ [timeToName]: e.target.value })
                  }
                }}
              />
              {error && (
                <span className="validation-error-message">{error}</span>
              )}
            </div>
          </>
        )
      case 'textarea':
        return (
          <textarea
            {...baseProps}
            ref={ref as ForwardedRef<HTMLTextAreaElement>}
          />
        )
      case 'select':
        return (
          <>
            <input
              aria-label="required-hack-input"
              tabIndex={-1}
              className={'required-hack-input'}
              type="text"
              required={required}
              onChange={() => ({})}
              value={
                isMulti
                  ? !!(value as string[] | number[])?.length
                    ? 'true'
                    : ''
                  : value
                  ? `${Boolean(value)}`
                  : ''
              }
            />
            <Select
              aria-label="select dropdown"
              menuPlacement="auto"
              menuPosition="fixed"
              className={baseProps.className}
              options={options}
              placeholder={placeholder}
              styles={selectStyles}
              isMulti={isMulti}
              value={
                isMulti
                  ? options.filter((option) => {
                      if (value === undefined || value === null) return false
                      return (value as any[]).indexOf(option.value) > -1
                    })
                  : options.find((option) => {
                      return option.value === value
                    })
              }
              onChange={(selectedOption: IOption) => {
                if (onChange) {
                  if (Array.isArray(selectedOption)) {
                    onChange({ [name]: selectedOption.map((o) => o.value) })
                  } else {
                    onChange({ [name]: selectedOption.value })
                  }
                }
              }}
              isDisabled={readOnly}
              isClearable={isClearable}
              components={{
                NoOptionsMessage,
              }}
              getOptionLabel={getOptionLabel}
              formatOptionLabel={formatOptionLabel}
              isSearchable={isSearchable}
            />
          </>
        )
      case 'search':
        return (
          <>
            {value && (
              <div className="clear" onClick={() => onChange({ [name]: '' })} />
            )}
            <input {...baseProps} type="text" />
          </>
        )
      case 'async_select':
        return (
          <>
            <input
              tabIndex={-1}
              className={'required-hack-input'}
              type="text"
              required={required && !readOnly}
              onChange={() => ({})}
              value={selectValue ? `${Boolean(value)}` : ''}
            />
            <AsyncSelect
              menuPlacement="auto"
              menuPosition="fixed"
              className={baseProps.className}
              placeholder={placeholder}
              styles={styles(styleOverrides) || selectStyles}
              isMulti={isMulti}
              loadOptions={loadOptions}
              defaultOptions={defaultOptions}
              getOptionLabel={getOptionLabel}
              getOptionValue={getOptionValue}
              value={onChange ? selectValue || '' : selectValue || undefined}
              onChange={(selectedOption: T, action: ActionMeta<T>) => {
                if (onChange) {
                  if (
                    Array.isArray(selectedOption) &&
                    action.action == 'remove-value'
                  ) {
                    onChange({
                      [name]: selectedOption.filter(
                        (o) =>
                          getOptionKey(o) !== getOptionKey(action.removedValue),
                      ),
                    })
                  } else {
                    onChange({ [name]: selectedOption })
                  }
                }
              }}
              isOptionDisabled={isOptionDisabled}
              name={inputName || name}
              id={inputName || name}
              isClearable={isClearable}
              isDisabled={readOnly}
              isSearchable={isSearchable}
              components={{
                NoOptionsMessage,
                ...(isMobile && {
                  DropdownIndicator: () => null,
                }),
              }}
            />
          </>
        )
      case 'checkbox':
        return (
          <>
            <input
              type="hidden"
              value={uncheckedValue ?? ''}
              name={baseProps.name}
            />
            <input {...baseProps} value={1} tabIndex={-1} />
            <CheckedIcon viewBox="1 1 14 14" />
          </>
        )
      case 'radio':
        return (
          <>
            {/* <input type="hidden" value={0} name={baseProps.name} /> */}
            <input
              {...baseProps}
              id={value.toString()}
              disabled={baseProps.readOnly}
            />
            <RadioIcon viewBox="1 1 14 14" />
          </>
        )
      case 'document':
      case 'csv-mentorship-import':
      case 'csv-import':
        const mapping = UPLOAD_MAPPING[type] ?? UPLOAD_MAPPING.__default
        const [documentData, setDocumentData] = useState(cachedData)
        useEffect(() => {
          setDocumentData(cachedData)
        }, [cachedData])
        const [documentLoading, setDocumentLoading] = useState(false)
        const [removeDocument, setRemoveDocument] = useState('')
        const documentFieldRef = useRef(null)
        const onDocumentChange = async (e) => {
          if (onChange) {
            const file = e.target.files[0]
            onChange({ [name]: e.target.files[0]?.name })

            if (manualUpload) {
              onChange({ [fileFieldName]: file })
            }

            if (file && !manualUpload) {
              const form = $(e.target).closest('form')
              changeLoadingFormStatusJQuery(form)
              setDocumentLoading(true)
              try {
                const fileJSON = await asyncFilesUpload(
                  e.target.files[0],
                  file.name,
                  file.type,
                  mapping.endpoints,
                )

                setDocumentData(JSON.stringify(fileJSON))
                onChangeDocument && onChangeDocument(JSON.stringify(fileJSON))
                setRemoveDocument('')
              } catch (error) {
                console.error('ERROR:', error)
                onChange({ [name]: undefined })
                try {
                  window.flash(
                    JSON.parse(
                      convert.xml2json(error.response?.data, { compact: true }),
                    ).Error.Message._text,
                    'alert',
                  )
                } catch (e) {
                  window.flash(
                    'Something went wrong with images upload!',
                    'alert',
                  )
                }
              }

              changeLoadingFormStatusJQuery(form, true)
              setDocumentLoading(false)
            }
          }
        }

        const onClearDocumentClick = () => {
          onChange({ [name]: undefined })
          setRemoveDocument('true')
          setDocumentData(null)
          if (documentFieldRef?.current) {
            documentFieldRef.current.val = ''
            // These two lines are the magic that allow clearing a file, then reupload.
            documentFieldRef.current.type = 'hidden'
            documentFieldRef.current.type = 'file'
          }
        }

        const maybeConfirmClearDocument = () => {
          const onAllow = () => {
            onClearDocumentClick()
          }
          if (!!onConfirmDeleteDocument) {
            onConfirmDeleteDocument(onAllow)
          } else {
            onAllow()
          }
        }

        const accept = mapping.acceptType

        const filenameValue = value
          ? value
          : readOnly
          ? '(no file uploaded)'
          : ''

        return (
          <div className="file-input-wrapper">
            {filenameValue && (
              <div
                className={classNames('preview', { loading: documentLoading })}>
                <div className="link document">
                  <span>{filenameValue}</span>
                  {readOnly ? null : (
                    <button
                      aria-label="remove document"
                      type="button"
                      className="not-styled-button remove"
                      onClick={maybeConfirmClearDocument}
                    />
                  )}
                </div>
              </div>
            )}
            {readOnly ? null : (
              <label htmlFor={baseProps.id}>
                <input
                  className="destroy-field"
                  type="hidden"
                  name={deleteInputName}
                  value={removeDocument}
                />
                <input
                  type="hidden"
                  value={documentData || ''}
                  name={baseProps.name}
                />
                <input
                  {...baseProps}
                  value={undefined}
                  type="file"
                  onChange={onDocumentChange}
                  accept={accept}
                  ref={documentFieldRef}
                  name={
                    type === 'document' || hideFileFieldName
                      ? undefined
                      : baseProps.name
                  }
                />
                <span className={classNames('link', { hidden: value })}>
                  + {selectFileLabel || 'Select File'}
                </span>
              </label>
            )}
          </div>
        )
      case 'image':
        return (
          <div
            className={`file-input-wrapper to-be-cropped-wrapper ${imageType}`}>
            <div className="preview">
              {!!value && (
                <div className="link image">
                  <span>{value}</span>
                  <button
                    type="button"
                    className="not-styled-button remove"
                    aria-label="remove image"
                    onClick={() =>
                      onChange({
                        fileName: null,
                        imageData: null,
                        errors: { image: [] },
                      })
                    }
                  />
                </div>
              )}
            </div>
            <label htmlFor={baseProps.id}>
              <input
                className="destroy-field"
                type="hidden"
                name={deleteInputName}
              />
              <input
                type="hidden"
                defaultValue={imageData}
                name={baseProps.name}
              />
              <input
                {...baseProps}
                type="file"
                name={undefined}
                value={undefined}
                data-image-type={imageType}
                data-text-preview={textPreview}
                data-text-title={textHeader}
                tabIndex={-1}
                accept="image/png, image/jpeg, image/gif"
                onChange={async (e) => {
                  const file = e.target.files[0]
                  const form = document.getElementsByClassName(
                    'mindr-form',
                  )[0] as HTMLFormElement
                  let isCropping = false
                  const validateFile = (file) => {
                    if (
                      !['image/jpeg', 'image/png', 'image/gif'].includes(
                        file.type,
                      )
                    ) {
                      return 'Select only .jpg, .jpeg, .png, .gif files'
                    }
                    if (file.size > 10 * 1024 * 1024) {
                      return 'File too large'
                    }
                  }

                  const errors = validateFile(file)

                  if (validateFile(file)) {
                    //TODO: Set error message of the label to be this error message
                    onChange({
                      errors: { image: errors },
                    })
                    return
                  }

                  Swal.fire({
                    ...defaultSwalParams,
                    width: 572,
                    title: `${e.target.dataset.textTitle || 'Add Logo'}`,
                    confirmButtonText: `${
                      e.target.dataset.submitButton || 'Select'
                    }`,
                    allowOutsideClick: () => !isCropping,
                    html: `<div class="custom-cropper-modal">
                      <div class="custom-cropper">
                      <div class="cropper-preview ${
                        imageType || 'round'
                      }">Loading...</div>
                    </div>`,
                    didOpen: () => {
                      const reader = new FileReader()
                      reader.readAsDataURL(file)
                      reader.onload = function () {
                        $('.cropper-preview')
                          .html('')
                          .append($('<img>', { src: reader.result as string }))
                        const cropper = $('.cropper-preview img')
                        cropper.cropper({
                          viewMode: 1,
                          aspectRatio: aspectRatios[imageType] || 1,
                          autoCropArea: imageType === 'banner' ? 1 : 0.8,
                        })
                        cropper.on('cropstart', () => {
                          isCropping = true
                        })
                        cropper.on('cropend', () => {
                          setTimeout(() => {
                            isCropping = false
                          }, 0)
                        })
                      }
                    },
                    preConfirm: () => {
                      const cropper = ($('.cropper-preview img')[0] as ICropped)
                        .cropper
                      const fileName = file.name
                      const fileType = file.type
                      changeLoadingFormStatus(form)
                      cropper.getCroppedCanvas().toBlob(async (blob) => {
                        try {
                          const fileJSON = await asyncFilesUpload(
                            blob,
                            fileName,
                            fileType,
                          )

                          const fileJSONValue = JSON.stringify({
                            ...fileJSON,
                            preview: URL.createObjectURL(blob),
                          })

                          onChange({
                            fileName: file.name,
                            imageData: fileJSONValue,
                            errors: { image: [] },
                          })
                        } catch (error) {
                          try {
                            window.flash(
                              JSON.parse(
                                convert.xml2json(error.response?.data, {
                                  compact: true,
                                }),
                              ).Error.Message._text,
                              'alert',
                            )
                          } catch (e) {
                            window.flash(
                              'Something went wrong with images upload!',
                              'alert',
                            )
                          }
                        }
                        changeLoadingFormStatus(form, true)
                      }, fileType)
                      return true
                    },
                  })
                }}
              />
              <span
                className={classNames('link image', { hidden: value })}
                tabIndex={0}>
                + {selectFileLabel || 'Add Image'}
              </span>
            </label>
          </div>
        )
      case 'switch':
        return (
          <label className="switch" tabIndex={0}>
            {typeof uncheckedValue !== 'undefined' && (
              <input name={name} value={uncheckedValue} type="hidden" />
            )}
            <input {...baseProps} type="checkbox" tabIndex={-1} />
            <span className="back" />
            <i className="indicator" />
          </label>
        )
      case 'slide_switch':
        return (
          <Switch
            controlName={name}
            defaultChecked={checked}
            uncheckedText={uncheckedText ?? 'No'}
            checkedText={checkedText ?? 'Yes'}
            readOnly={readOnly}
            offValue={''}
            onValue={'1'}
          />
        )
      default:
        return (
          <input
            {...baseProps}
            disabled={readOnly}
            data-max-length={maxLength}
          />
        )
    }
  }

  const isCheckbox = ['radio', 'checkbox'].includes(type)
  const isRendersOwnError = type === 'timerange'

  const inputContent = (
    <>
      {renderInput()}
      {!isRendersOwnError && error && (
        <span className="validation-error-message">{error}</span>
      )}
    </>
  )

  const captionElement = <Caption className="caption">{caption}</Caption>
  const aboveCaption = captionPosition === 'above' ? captionElement : null
  const betweenCaption = captionPosition === 'between' ? captionElement : null
  const belowCaption = captionPosition === 'below' ? captionElement : null

  return (
    <div
      className={classNames('input-component-wrapper', {
        'checkbox-component-wrapper': isCheckbox,
        'validation-error': !!error,
        [wrapperClassName]: !!wrapperClassName,
        [className]: !!className,
      })}>
      {aboveCaption}
      {label && (
        <label
          htmlFor={type === 'radio' ? value.toString() : inputName || name}
          className={classNames('label', {
            'validation-error': !!error,
            readonly: readOnly,
          })}>
          {label}
        </label>
      )}
      {(isCheckbox && (
        <label
          className={classNames('input-wrapper', {
            radio: type === 'radio',
            readonly: readOnly,
          })}
          htmlFor={type === 'radio' ? value.toString() : inputName || name}
          tabIndex={0}>
          {betweenCaption}
          {inputContent}
          {belowCaption}
        </label>
      )) || (
        <div className="input-wrapper">
          {betweenCaption}
          {inputContent}
          {belowCaption}
        </div>
      )}
    </div>
  )
}

const Input = React.forwardRef(InputWithForwardRef) as <T = string>(
  props: IInput<T> & {
    ref?: React.ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
  },
) => ReturnType<typeof InputWithForwardRef>

export default Input
