import { IMentorshipExchangeApplication } from 'types'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import IconEditUnderline from '../../images/icons/edit_with_underline.svg'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import { TableCellSecondaryLink } from 'components/Table/components/TableCell'
import Container from 'components/Container'

const boostIconCss = css({
  marginRight: spacings.grid_gap_basis_num / 2,
  path: {
    fill: colors.teal_dark,
  },
})

interface ApplicantRowActionsProps {
  data: IMentorshipExchangeApplication
}

export default function ApplicantRowActions({
  data,
}: ApplicantRowActionsProps): JSX.Element {
  const { remove, edit_remove_notes, restore, boost_field, boost_field_edit } =
    data.actions

  const actionButtons = []
  const {
    setRemoveModalObject,
    setRemoveNoteModalObject,
    setRestoreModalObject,
    setBoostEditModalObject,
  } = useMentorshipExchangeDetailsContext()

  if (boost_field || boost_field_edit) {
    actionButtons.push(
      <TableCellSecondaryLink
        key="boost"
        className="ignore-goji"
        onClick={() => {
          setBoostEditModalObject(data)
        }}>
        {boost_field_edit ? <IconEditUnderline css={boostIconCss} /> : null}
        Boost
      </TableCellSecondaryLink>,
    )
  }

  if (remove) {
    actionButtons.push(
      <TableCellSecondaryLink
        key="remove"
        className="ignore-goji"
        onClick={() => {
          setRemoveModalObject(data)
        }}
        css={{
          color: colors.red,
          borderColor: colors.red,
          '&:not([disabled]):focus, &:not([disabled]):hover': {
            color: colors.red,
            borderColor: colors.red,
          },
        }}>
        Remove
      </TableCellSecondaryLink>,
    )
  }

  if (edit_remove_notes) {
    actionButtons.push(
      <TableCellSecondaryLink
        key="edit_remove_notes"
        className="link ignore-goji"
        onClick={() => {
          setRemoveNoteModalObject(data)
        }}>
        Notes
      </TableCellSecondaryLink>,
    )
  }

  if (restore) {
    actionButtons.push(
      <TableCellSecondaryLink
        key="restore"
        className="ignore-goji"
        onClick={() => {
          setRestoreModalObject(data)
        }}
        css={{
          color: colors.red,
          borderColor: colors.red,
          '&:not([disabled]):focus, &:not([disabled]):hover': {
            color: colors.red,
            borderColor: colors.red,
          },
        }}>
        Restore
      </TableCellSecondaryLink>,
    )
  }

  return (
    <Container
      css={{
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
      {actionButtons}
    </Container>
  )
}
