import React from 'react'

import MembershipFilters, { TFilterOptions } from 'components/MembershipFilters'
import MembershipTabs from 'components/MembershipTabs'
import Table, { ITableProps } from 'components/Table'
import BatchEmail from 'components/BatchEmail'
import useUsersState from 'state/Users'

import DownloadIcon from '../../images/icons/download.svg'
import MenuButton from '../../images/icons/dots_vert.svg'
import './Participants.scss'

interface IParticipants extends ITableProps {
  url: string
  emailUrl: string
  addUrl: string
  bulkUploadUrl: string
  removeUrl: string
  isExportable: boolean
  filterOptions: TFilterOptions
  counters: {
    total: number
    new: number
  }
}

const Particpants: React.FC<IParticipants> = ({
  url,
  emailUrl,
  addUrl,
  bulkUploadUrl,
  isExportable,
  tableData: initialData,
  tableMeta: initialMeta,
  filterOptions,
  counters: initialCounters,
}) => {
  const {
    data,
    meta,
    counters,
    filters,
    tableFilters,
    exportCsvUrl,
    loadPage,
    reload,
    updateOrder,
    updateFilters,
  } = useUsersState({
    url,
    initialData,
    initialMeta,
    initialCounters,
    initialFilters: {}, //TODO:
  })

  return (
    <div className="cohorts-participants-root">
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">Cohort Participants</p>
        </div>
      </div>
      <div className="section-header">
        <MembershipTabs
          className="header-left"
          totalTitle="Total Cohort Participants"
          counters={counters}
          newSubscribers={filters.newSubscribers}
          updateNewSubscribersFilter={(value) => {
            updateFilters({
              newSubscribers: value,
            })
          }}
        />
        <div className="right bottom">
          {isExportable && (
            <a href={exportCsvUrl} className="link">
              <DownloadIcon />
              Export.csv
            </a>
          )}
          <button
            className="button primary add-cohort-participants-button"
            data-url={addUrl}
            data-bulk-upload-url={bulkUploadUrl}>
            Add Participants
          </button>
          <div className="participants-more-menu menu-wrapper">
            <button
              className="not-styled-button menu-toggler"
              aria-label="menu">
              <MenuButton />
            </button>
            <ul className="menu-body">
              <li className="menu-item">
                <a href={bulkUploadUrl} className="menu-link">
                  Bulk upload
                </a>
              </li>
              <li className="menu-item">
                <BatchEmail
                  isMembers
                  communityType="cohort"
                  url={emailUrl}
                  filters={tableFilters}
                  totalRecipients={data.paginator.total_entries}
                  disabled={data.paginator.total_entries === 0}
                  btnText="Batch Email"
                  btnClassName="menu-link"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="data">
        <MembershipFilters
          searchPlaceholder="Search participants"
          filters={filters}
          updateFilters={updateFilters}
          filterOptions={filterOptions}
        />
        <Table
          tableData={data}
          tableMeta={meta}
          selectedFilters={tableFilters}
          onPageChange={(pageNum) => loadPage(pageNum)}
          onOrderChange={({ sort_col, sort_dir }) => {
            updateOrder(
              sort_col,
              sort_dir === 'asc' || sort_dir === 'desc' ? sort_dir : 'asc',
            )
          }}
        />
      </div>
      <button className="reload-page-hack" onClick={() => reload()}>
        Reload
      </button>
    </div>
  )
}

export default Particpants
