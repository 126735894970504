import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import Input from 'components/Input'
import Axios from 'axios'
import { TCommunityType } from 'types'

export type TCommunityPickerValue = {
  id: number | undefined
  name: string | undefined
  type?: TCommunityType
}

interface ICommunityPicker {
  value: TCommunityPickerValue
  name: string
  inputName: string
  communitiesUrl: string
  label: string | undefined
  error: string | undefined
  wrapperClassName: string | undefined
  onChange?: (inputData: Record<string, TCommunityPickerValue>) => void
  communityId: number
}

const CommunityPicker = ({
  value,
  name,
  inputName,
  communitiesUrl,
  label = undefined,
  error = undefined,
  wrapperClassName = undefined,
  onChange = undefined,
  communityId,
}: ICommunityPicker): React.ReactElement => {
  const [useValue, setUseValue] = useState<TCommunityPickerValue>()
  useEffect(() => {
    setUseValue(value)
  }, [])
  const onSelectChange = (inputData: Record<string, TCommunityPickerValue>) => {
    setUseValue(inputData[name])
    onChange && onChange(inputData)
  }

  const loadOptions = useMemo(
    () =>
      _.debounce((inputValue, callback) => {
        Axios.get(communitiesUrl, {
          params: {
            'filter[*]': inputValue,
            'filter[exclude_self]': true,
            community_id: communityId,
            sort_col: 'name',
            sort_dir: 'asc',
          },
        }).then(({ data: { data } }) => {
          callback(
            data.rows.map((row) => ({
              id: row.data.id,
              name: row.data.name,
              type: row.data?.type,
            })),
          )
        })
      }, 200),
    [communitiesUrl],
  )

  const getOptionLabel = (option) => option.name
  const getOptionValue = (option) => option.id || ''

  return (
    <Input
      name={name}
      type="async_select"
      inputName={inputName}
      selectValue={useValue}
      loadOptions={loadOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      label={label}
      onChange={onSelectChange}
      error={error}
      wrapperClassName={wrapperClassName}
      isClearable={true}
    />
  )
}

export default CommunityPicker
