import ImagePreview from 'components/ImagePreview'
import { useContext } from 'react'
import BrandingContext from './BrandingContext'

export default function BrandingImagePreview(): JSX.Element {
  const { logo, setLogo } = useContext(BrandingContext)
  return (
    <ImagePreview
      logoUrl={logo}
      onLogoChange={(logoUrl) => {
        setLogo(logoUrl)
      }}
    />
  )
}
