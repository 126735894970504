import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'

import './ReportsPieChart.scss'

interface IReportsPieChart {
  satisfaction: {
    '-2': number
    '-1': number
    '0': number
    '1': number
    '2': number
  }
}

const ReportsPieChart: React.FC<IReportsPieChart> = ({ satisfaction }) => {
  const data = [
    {
      title: 'Very Satisfied',
      value: satisfaction['2'] || 0,
      color: '#45B5AA',
    },
    { title: 'Satisfied', value: satisfaction['1'] || 0, color: '#22C0E2' },
    { title: 'Neutral', value: satisfaction['0'] || 0, color: '#F4A449' },
    { title: 'Dissatisfied', value: satisfaction['-1'] || 0, color: '#CC4072' },
    {
      title: 'Very Dissatisfied',
      value: satisfaction['-2'] || 0,
      color: '#94519F',
    },
  ]
  return (
    <div className="reports-pie-chart">
      <div className="chart-container">
        <PieChart startAngle={270} data={data} segmentsShift={1} radius={48} />
      </div>
      <div className="legend">
        {data.map((dataElement) => (
          <div key={dataElement.title}>
            <div
              className="color"
              style={{ backgroundColor: dataElement.color }}
            />
            <div className="label">
              {dataElement.title} ({dataElement.value})
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ReportsPieChart
