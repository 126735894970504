import React from 'react'
import cx from 'classnames'

import SectionTitle from 'components/Typography/SectionTitle'
import './Card.scss'
import Container from 'components/Container/Container'
import { Caption } from 'components/Typography'

export interface CardProps {
  title?: string
  subtitle?: string
  actionNode?: JSX.Element
  className?: string
  children: JSX.Element
}

/**
 * Creates a card compoenent with a title in the header and optional action element
 */
export default function Card({
  title,
  subtitle,
  actionNode,
  className,
  children,
}: CardProps): JSX.Element {
  return (
    <div className={cx('card', className)}>
      {(title || subtitle) && (
        <div className="card-header">
          <Container
            css={{
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
            {title && <SectionTitle>{title}</SectionTitle>}
            {subtitle && <Caption>{subtitle}</Caption>}
          </Container>
          {actionNode}
        </div>
      )}
      <div className="card-child">{children}</div>
    </div>
  )
}
