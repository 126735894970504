import React, { useEffect, useState } from 'react'
import produce, { enableES5 } from 'immer'
enableES5()
import axios from 'axios'
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'

// styles
import './CommunityLeaders.scss'

// icons
import DeleteIcon from '../../images/icons/delete.svg'

// components
import Input from 'components/Input'
import DragAndDrop from 'components/DragAndDrop'

// interfaces
import { IUser } from 'javascripts/general'
import { ITeamMember } from 'types'

interface ICommunityLeaders {
  initialValues: ITeamMember[]
  rootEntity: string
}

const CommunityLeaders: React.FC<ICommunityLeaders> = ({
  rootEntity,
  initialValues,
}) => {
  const [team, changeTeam] = useState(
    initialValues.map((link) => ({ ...link, fake_id: uuidv4() })),
  )

  const [initialUsers, setInitialUsers] = useState<IUser[]>([])

  useEffect(() => {
    loadOptions('', setInitialUsers)
  }, [])

  const addRow = () => {
    changeTeam(
      produce((draft) => {
        draft.push({ fake_id: uuidv4(), _destroy: false })
      }),
    )
  }

  const loadOptions = _.debounce((inputValue, callback) => {
    axios
      .get('/users.json', {
        params: {
          'filter[*]': inputValue,
          per_page: !!inputValue ? undefined : 3,
          active_only: true,
        },
      })
      .then(({ data: { data } }) => {
        callback(
          data.rows.map((row) => ({
            ...row.data,
            title: row.data.title || '',
          })),
        )
      })
  }, 400)

  const onInputChange = (editedObj, index) => {
    changeTeam(
      produce((draft) => {
        draft[index] = { ...draft[index], ...editedObj }
      }),
    )
  }

  return (
    <div className="leaders additional-section">
      <DragAndDrop
        name={`${rootEntity}[leaders_attributes]`}
        items={team}
        setItems={changeTeam}
        component={(index, item) => (
          <div
            className={classNames('person additional-section-row', {
              hidden: item._destroy,
            })}
            key={item.id || item.fake_id}>
            {!!item.id && (
              <input
                type="hidden"
                name={`${rootEntity}[leaders_attributes][${index}][id]`}
                value={item.id}
              />
            )}
            {item._destroy && (
              <input
                type="hidden"
                name={`${rootEntity}[leaders_attributes][${index}][_destroy]`}
                value="true"
              />
            )}
            <Input<IUser>
              hideName={!item.user?.id}
              name="user"
              inputName={`${rootEntity}[leaders_attributes][${index}][user_id]`}
              selectValue={item.user?.id && item.user}
              label="Name*"
              type="async_select"
              loadOptions={loadOptions}
              defaultOptions={initialUsers}
              getOptionLabel={(option) => option.full_name}
              getOptionValue={(option) => option.id}
              onChange={(inputData) => onInputChange(inputData, index)}
              noOptionsMessage="Employee not found"
              error={item.errors?.user?.join(', ')}
              isOptionDisabled={(option) =>
                team.some(
                  (member) => member.user?.id === option.id && !member._destroy,
                )
              }
              isClearable
              wrapperClassName="one"
            />
            <Input
              name="role"
              inputName={`${rootEntity}[leaders_attributes][${index}][role]`}
              maxLength="150"
              value={item.role || ''}
              label="Role"
              wrapperClassName="two"
              onChange={(inputData) => onInputChange(inputData, index)}
            />
            <Input
              name="email"
              value={item.user?.email || ''}
              label="Email"
              readOnly
              hideName
              wrapperClassName="readonly three"
            />
            <button
              aria-label="remove leader"
              className={'not-styled-button delete first'}
              type="button"
              onClick={() =>
                onInputChange({ _destroy: true, hidden: true }, index)
              }>
              <DeleteIcon />
            </button>
          </div>
        )}
      />
      <button className="link" type="button" onClick={addRow}>
        + Add Leader
      </button>
    </div>
  )
}

export default CommunityLeaders
