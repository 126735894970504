import { useCallback, useEffect, useMemo, useState } from 'react'
import EssentialDetails from './EssentialDetails'
import AdditionalDetails from './AdditionalDetails'
import ImportantConsiderations from './ImportantConsiderations'
import LEADR from './LEADR'
import TopSkill from './TopSkill'
import LifeExperiences from './LifeExperiences'
import { Tag, TagCategory } from './types'
import { IOption } from 'components/Inputs/Select'
import BoostPrioritization from './BoostPrioritization'
import OptionalHeading from './OptionalHeading'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import _ from 'lodash'
import axios from 'axios'
import ManagerApproval from './ManagerApproval'

function unrollUserResults(data) {
  const rows = []

  if (data.rows && data.rows.length) {
    data.rows.forEach((row) => {
      if (row.data && row.data.id) {
        rows.push(row.data)
      }
    })
  }

  return rows
}

interface MentorshipApplicationValues {
  steps: any[]
  currentStep: number
  onBackStep: () => void
  updateRawFormData: (newFormData: FormData) => void
  incrementStep: () => void
  setStrengthCategories: (categories: TagCategory[]) => void
  setGoalCategories: (categories: TagCategory[]) => void
  categories: TagCategory[]
  leadrCharacteristics: TagCategory[]
  formData: any
  setErrors: (errors: Record<string, string[]>) => void
  getErrors: () => Record<string, string[]>
  boostList: IOption[]
  isMobile?: boolean
  loadMangerOptions: (
    inputValue: string,
    callback: (options: any) => void,
  ) => void
}

export default function useMentorshipApplication(
  tag_categories: TagCategory[],
  tags: Tag[],
  boostList: IOption[],
  usersUrl: string,
  requiresManager: boolean,
  requiresBudget: boolean,
): MentorshipApplicationValues {
  const [steps, setSteps] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [rawFormData] = useState(new FormData())
  const [strengthCategories, setStrengthCategories] = useState<TagCategory[]>(
    [],
  )
  const [goalCategories, setGoalCategories] = useState<TagCategory[]>([])
  const [formData, setFormData] = useState({})

  const leadr_characteristics = useMemo(
    () =>
      tag_categories.filter((category) => category.name !== 'life experience'),
    [],
  )
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  const updateRawFormData = useCallback(
    (newFormData: FormData) => {
      for (const pair of newFormData.entries()) {
        rawFormData.append(pair[0], pair[1])
      }

      const newObject = Array.from(newFormData.entries()).reduce(
        (acc, [key, value]) => {
          try {
            acc[key] = JSON.parse(value.toString())
          } catch (error) {
            acc[key] = value
          }
          return acc
        },
        {},
      )

      setFormData({ ...formData, ...newObject })
      return { ...formData, ...newObject }
    },
    [formData, setFormData, rawFormData],
  )

  const incrementStep = useCallback(() => {
    setCurrentStep(currentStep + 1)
    window.scrollTo(0, 0)
  }, [setCurrentStep, currentStep])

  const onBackStep = useCallback(() => {
    setCurrentStep(currentStep - 1)
    window.scrollTo(0, 0)
  }, [setCurrentStep, currentStep])

  /**
   * Sets the what step we should show user next based on their answers from LEADR step
   * We want to show them their first two LEADR as their strengths, then show them the last
   * three LEADR in reverse order as their goals. The last in LEADR should be their
   * top goal.
   */
  useEffect(() => {
    const lifeExperienceCategoryId = tag_categories.find(
      (category) => category.name === 'life experience',
    )

    const defaultSteps = [
      <EssentialDetails key="essential-details" />,
      <ImportantConsiderations key="important-considerations" />,
      <LEADR key="leadr" />,
    ]

    let steps = defaultSteps

    if (!!strengthCategories.length && !!goalCategories.length) {
      steps = [
        ...defaultSteps,
        ...strengthCategories.map((strength_category, index) => (
          <TopSkill
            isFirst={index === 0}
            skill={strength_category.name}
            skillTags={tags.filter(
              (tag) => tag.category_id === strength_category.id,
            )}
            key={`top-skill-strength-${index}`}
          />
        )),
        <LifeExperiences
          key="life-experiences"
          lifeExperiences={tags.filter(
            (tag) => tag.category_id === lifeExperienceCategoryId.id,
          )}
        />,
        <AdditionalDetails
          key="additional-details-strengths"
          title={
            <OptionalHeading>
              What else should we know about your strengths?
            </OptionalHeading>
          }
          name="strengths-notes"
        />,
        ...goalCategories.map((goal_category, index) => (
          <TopSkill
            isFirst={index === 0}
            skill={goal_category.name}
            skillTags={tags.filter(
              (tag) => tag.category_id === goal_category.id,
            )}
            isGoal
            key={`top-skill-goal-${index}`}
          />
        )),
        <LifeExperiences
          key="life-experiences"
          lifeExperiences={tags.filter(
            (tag) => tag.category_id === lifeExperienceCategoryId.id,
          )}
          isGoal
        />,
        <AdditionalDetails
          key="additional-details-goals"
          title={
            <OptionalHeading>
              What else should we know about your goals and areas of growth?
            </OptionalHeading>
          }
          name="goals-notes"
        />,
        <BoostPrioritization
          key="boost-prioritiztion"
          name="boosted-field"
          boostList={boostList}
        />,
        <AdditionalDetails
          key="additional-details-overall"
          title={
            <OptionalHeading>
              Anything else you&apos;d like to share?
            </OptionalHeading>
          }
          name="overall-notes"
        />,
      ]
    } else {
      steps = defaultSteps
    }

    if (requiresManager) {
      steps.push(
        <ManagerApproval
          key="manager-approval"
          requiresBudget={requiresBudget}
        />,
      )
    }

    setSteps(steps)
  }, [strengthCategories, goalCategories, requiresManager])

  const [errors, setErrors] = useState<Record<string, string[]>>({})
  const getErrors = () => errors

  const loadMangerOptions = useCallback(
    _.debounce((inputValue, callback) => {
      axios
        .get(`${usersUrl}.json`, {
          params: {
            'filter[*]': inputValue,
            per_page: !!inputValue ? undefined : 8,
            active_only: true,
            exclude_self: true,
          },
        })
        .then(({ data: { data } }) => {
          callback(unrollUserResults(data))
        })
    }, 400),
    [usersUrl],
  )

  return {
    steps,
    currentStep,
    onBackStep,
    updateRawFormData,
    incrementStep,
    setStrengthCategories,
    setGoalCategories,
    categories: tag_categories,
    leadrCharacteristics: leadr_characteristics,
    formData,
    setErrors,
    getErrors,
    boostList,
    isMobile,
    loadMangerOptions,
  }
}
