import ELBadge from 'images/icons/badges/el-badge.svg'
import EABadge from 'images/icons/badges/ea-badge.svg'
import EDBadge from 'images/icons/badges/ed-badge.svg'
import ALBadge from 'images/icons/badges/al-badge.svg'
import DABadge from 'images/icons/badges/da-badge.svg'
import DLBadge from 'images/icons/badges/dl-badge.svg'
import RLBadge from 'images/icons/badges/rl-badge.svg'
import REBadge from 'images/icons/badges/re-badge.svg'
import RABadge from 'images/icons/badges/ra-badge.svg'
import RDBadge from 'images/icons/badges/rd-badge.svg'
import { SBadgeIcon } from './LeadrShareOnLinkedInModal.styles'

export const BADGE_DATA = {
  EL: {
    badge: <ELBadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/empathetic-learner',
  },
  EA: {
    badge: <EABadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/empathetic-actor',
  },
  ED: {
    badge: <EDBadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/empathetic-director',
  },
  AL: {
    badge: <ALBadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/action-oriented-learner',
  },
  DA: {
    badge: <DABadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/directive-actor',
  },
  DL: {
    badge: <DLBadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/directive-learner',
  },
  RL: {
    badge: <RLBadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/resilient-learner',
  },
  RE: {
    badge: <REBadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/resilient-empath',
  },
  RA: {
    badge: <RABadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/resilient-actor',
  },
  RD: {
    badge: <RDBadge css={SBadgeIcon} />,
    link: 'https://www.mindrglobal.com/leadr-profiles/resilient-director',
  },
}
