import { useCallback, useEffect, useMemo, useState } from 'react'
import Axios from 'axios'
import _ from 'lodash'

import { ITableData, ITableMeta } from 'components/Table'
import useOrderState, { TOrderDirection } from 'state/common/Order'
import { useEffectNotOnMount } from 'javascripts/general'

interface ITableStateProps<T> {
  url: string
  initialData?: ITableData<T>
  initialMeta?: ITableMeta
}

export interface TDefaultTableFilters {
  text: string | undefined
}

interface ISearchParams {
  'filter[*]': string
  sort_col: string | undefined
  sort_dir: TOrderDirection | undefined
  page: number
}

interface ITableState<T> {
  loading: boolean
  data: ITableData<T>
  meta: ITableMeta
  filters: TDefaultTableFilters
  searchParams: ISearchParams
  loadPage: (page: number) => void
  reload: () => void
  updateFilters: (newFilters: Partial<TDefaultTableFilters>) => void
  updateOrder: (column: string, direction: TOrderDirection) => void
}

const useTableState = <T>({
  url,
  initialData = null,
  initialMeta = null,
}: ITableStateProps<T>): ITableState<T> => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [data, setData] = useState<ITableData<T> | null>(initialData)
  const [meta, setMeta] = useState<ITableMeta | null>(initialMeta)
  const [filters, setFilters] = useState<TDefaultTableFilters>({
    text: undefined,
  })

  const { order, updateOrder } = useOrderState()

  const searchParams: ISearchParams = useMemo(
    () => ({
      'filter[*]': filters.text || undefined,
      sort_col: order.column || undefined,
      sort_dir: order.direction || undefined,
      page: page + 1,
    }),
    [page, filters, order],
  )

  const loadData = useCallback(
    async (filters: ISearchParams) => {
      try {
        setLoading(true)
        const response = await Axios.get(url, {
          params: filters,
        })
        setLoading(false)

        setMeta(response.data.meta)
        setData(response.data.data)
      } catch (error) {
        window.flash('Something went wrong!', 'alert')
      }
    },
    [setLoading, setData, setMeta],
  )

  const loadDataDebounced = useMemo(
    () =>
      _.debounce((params: ISearchParams) => {
        loadData(params)
      }, 300),
    [loadData],
  )

  useEffectNotOnMount(() => {
    loadDataDebounced(searchParams)
  }, [loadDataDebounced, searchParams])

  useEffect(() => {
    if (!initialData && !data) {
      loadData(searchParams)
    }
  }, [data, initialData, searchParams])

  const loadPage = useCallback(
    (requestedPage) => {
      setPage(requestedPage)
    },
    [setPage],
  )

  const reload = useCallback(() => {
    loadData(searchParams)
  }, [loadData, searchParams])

  const updateFilters = useCallback(
    (newFilters: Partial<TDefaultTableFilters>) => {
      setPage(0)
      setFilters({
        ...filters,
        ...newFilters,
      })
    },
    [filters, setFilters],
  )

  return {
    loading,
    data,
    meta,
    filters,
    searchParams,
    loadPage,
    reload,
    updateFilters,
    updateOrder,
  }
}

export default useTableState
