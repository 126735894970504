import Button, { ButtonComponentType, Variant } from 'components/Button'
import {
  SIdpSettingsList,
  SIdpSettingsListActionRow,
} from './IdpSettings.styles'
import { IIdpSettingsProps } from './IdpSettings.types'
import { IdpSetting as IdpSettingObject } from './IdpSetting.types'
import IdpSetting from './IdpSetting'
import { useCallback, useEffect, useState } from 'react'

export default function IdpSettings({
  strings,
  field_prefix,
  idp_settings,
}: IIdpSettingsProps): JSX.Element {
  const [settingsList, setSettingsList] = useState<IdpSettingObject[]>([])
  useEffect(() => {
    setSettingsList(idp_settings)
  }, [])
  const onAddRow = useCallback(
    (e) => {
      e.preventDefault()
      const list = [...settingsList]
      list.push({})
      setSettingsList(list)
    },
    [settingsList],
  )
  const onRemoveRow = useCallback(
    (e, index) => {
      e.preventDefault()
      const list = [...settingsList]
      if (list[index]) {
        if (Object.keys(list[index]).length === 0) {
          list.splice(index, 1)
        } else {
          list[index]._destroy = true
        }
      }
      setSettingsList(list)
    },
    [settingsList],
  )
  return (
    <>
      <div css={SIdpSettingsList}>
        {settingsList.map((idp_setting, index) => {
          const key = `idp-settings-${index}`
          const prefix = `${field_prefix}[${index}]`
          return (
            <IdpSetting
              key={key}
              strings={strings}
              prefix={prefix}
              settings={idp_setting}
              onDelete={(e: any) => {
                onRemoveRow(e, index)
              }}
            />
          )
        })}
      </div>
      <div css={SIdpSettingsListActionRow}>
        <Button
          as={ButtonComponentType.BUTTON}
          variant={Variant.SECONDARY}
          onClick={onAddRow}>
          {strings.buttons.add_sso}
        </Button>
      </div>
    </>
  )
}
