import { useEffect, useMemo } from 'react'

import Table, {
  ITableProps,
  DateTableCell,
  DateTableCellVariant,
  TextTableCell,
  TableCellWrapper,
} from 'components/Table'
import Container from 'components/Container'
import Button, { ButtonComponentType, Variant } from 'components/Button'

import ConnectorStatusPill from './ConnectorStatusPill'
import ConnectorActions from './ConnectorActions'

import { spacings } from 'stylesheets/theme'

import { Connector } from 'types'
import WarningBanner from 'components/MsftTeamsGuide/WarningBanner'

interface ApplicationIntegrationProps extends ITableProps<Connector> {
  title: string
  newConnectorUrl: string
}

export default function ApplicationIntegration({
  title,
  tableData,
  tableMeta,
  selectedFilters,
  newConnectorUrl,
}: ApplicationIntegrationProps): JSX.Element {
  const showConnectorsTable = useMemo(
    () => !!tableData.rows.length,
    [tableData],
  )

  useEffect(() => {
    if (!showConnectorsTable) {
      window.location.assign(newConnectorUrl)
    }
  }, [showConnectorsTable])

  if (showConnectorsTable) {
    return (
      <div>
        <Container
          css={{
            justifyContent: 'space-between',
            paddingBottom: spacings.grid_gap_basis_num * 2,
          }}>
          <h2>{title}</h2>

          <Button
            as={ButtonComponentType.LINK}
            href={newConnectorUrl}
            variant={Variant.PRIMARY}>
            Create Connector
          </Button>
        </Container>
        <WarningBanner />
        <div>
          <Table
            tableData={tableData}
            tableMeta={tableMeta}
            selectedFilters={selectedFilters}
            title="Connectors"
            searchPlaceholder={'Search'}
            css={{
              overflow: 'visible',
            }}>
            {(row) => (
              <>
                <TextTableCell
                  value={row.data.label || 'No channel name'}
                  bold
                  link={row.link}
                />
                <TableCellWrapper link={row.link}>
                  <ConnectorStatusPill data={row.data} />
                </TableCellWrapper>
                <DateTableCell
                  variant={DateTableCellVariant.DateNoYear}
                  value={row.data.created_at}
                  link={row.link}
                />
                <ConnectorActions data={row.data} />
              </>
            )}
          </Table>
        </div>
      </div>
    )
  }

  return null
}
