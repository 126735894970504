import { css } from '@emotion/react'
import Avatar from 'components/Avatar'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import Card from 'components/Card'
import Container from 'components/Container'
import { FormSection } from 'components/Forms'
import { Caption, Paragraph } from 'components/Typography'
import { colors, sizes, spacings } from 'stylesheets/theme'
import { MentorshipApplicant } from 'types'
import DownloadIcon from 'images/icons/download.svg'

interface IParticipantInformationCardProps {
  me: MentorshipApplicant
  partner: MentorshipApplicant
  reasoning: string
  className?: string
  isMobile?: boolean
  displayDetailOptions?: Record<string, boolean>
}

const participantInformationContainerStyle = css({
  minWidth: 271,
  minHeight: 320,
  borderRadius: spacings.radius,
  padding: spacings.grid_gap_basis_num * 2,
  gap: spacings.grid_gap_basis_num * 2,
  flexDirection: 'column',
  backgroundColor: colors.backgrounds.page,
  justifyContent: 'flex-start',
})

const matchReasonsContainerStyle = css({
  backgroundColor: colors.backgrounds.page,
  padding: '18px 17px',
})

interface IUserInformationProps {
  user: MentorshipApplicant
  displayDetailOptions?: Record<string, boolean>
}

function UserInformation({
  user,
  displayDetailOptions,
}: IUserInformationProps): JSX.Element {
  return (
    <Container css={participantInformationContainerStyle}>
      <Avatar
        avatarUrl={user.avatar_url}
        size={sizes.avatarSize}
        userInitial={user.first_name?.toUpperCase().charAt(0)}></Avatar>
      <Container
        css={{
          flexDirection: 'column',
          gap: spacings.grid_gap_basis_num / 2,
          textAlign: 'center',
        }}>
        <h3>{`${user.first_name} ${user.last_name}`}</h3>
        {(displayDetailOptions.title == true || !displayDetailOptions) && (
          <Paragraph bold>{user.title}</Paragraph>
        )}
        {(displayDetailOptions.department == true || !displayDetailOptions) && (
          <Caption>{user.department}</Caption>
        )}
        {(displayDetailOptions.location == true || !displayDetailOptions) && (
          <Caption>{user.location}</Caption>
        )}
        {(displayDetailOptions.email == true || !displayDetailOptions) && (
          <Caption>{user.email}</Caption>
        )}
      </Container>
      {user.application_csv && (
        <Button
          as={ButtonComponentType.LINK}
          href={user.application_csv}
          variant={Variant.LINK}>
          <DownloadIcon
            css={{
              marginRight: spacings.grid_gap_basis_num / 2,
            }}
          />
          My application
        </Button>
      )}
    </Container>
  )
}

export default function ParticipantInformationCard({
  me,
  partner,
  reasoning,
  displayDetailOptions,
  className = '',
}: IParticipantInformationCardProps): JSX.Element {
  return (
    <Card className={className}>
      <Container
        css={{
          flexWrap: 'wrap',
        }}>
        <div
          css={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(271px, auto))',
            width: '100%',
            gridGap: spacings.grid_gap_basis_num * 2,
            '>*': {
              width: '100%',
              boxSizing: 'border-box',
            },
          }}>
          <FormSection title="My information">
            <UserInformation
              user={me}
              displayDetailOptions={displayDetailOptions}
            />
          </FormSection>
          <FormSection title="Partner's information">
            <UserInformation
              user={partner}
              displayDetailOptions={displayDetailOptions}
            />
          </FormSection>
        </div>
        <div>
          <h4
            css={{
              color: colors.text.text_3,
              marginTop: spacings.grid_gap_basis_num * 2,
              marginBottom: spacings.grid_gap_basis_num * 2,
            }}>
            You&apos;re a great match because:
          </h4>
          <div css={matchReasonsContainerStyle}>
            <p>{reasoning}</p>
          </div>
        </div>
      </Container>
    </Card>
  )
}
