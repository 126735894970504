import Modal, { Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import { ITruncatedMentorshipApplication } from '../types'

interface RestoreModalProps {
  object: ITruncatedMentorshipApplication
  isOpen?: boolean
  onClose: (id: number) => void
  onSave: (id: number) => void
}

export default function RestoreModal({
  object,
  isOpen = false,
  onClose,
  onSave,
}: RestoreModalProps): JSX.Element {
  const onCloseModal = () => {
    onClose(object?.id ?? 0)
  }
  const onSaveModal = () => {
    onSave(object?.id ?? 0)
    onClose(object?.id ?? 0)
  }
  return (
    <Modal
      css={modalStyle}
      isOpen={isOpen}
      size={Size.large}
      onRequestClose={onCloseModal}
      onSave={onSaveModal}
      submitButton="Restore applicant"
      cancelButton="Cancel"
      title="Restore applicant">
      <p>
        Are you sure you want to restore this applicant? This will make them
        eligible to be matched in this cohort. This action can be undone by
        clicking on the &quot;Remove&quot; action again any time before matching
        commences.
      </p>
    </Modal>
  )
}
