import { FormFieldContainer, FormFieldLabelVisibility } from 'components/Forms'
import { TimePicker, DatePicker, TimezonePicker } from 'components/Inputs'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import Banner from 'components/Banner'
import InfoIcon from 'images/icons/info.svg'
import Container from 'components/Container'
import { Caption } from 'components/Typography'
import { TEventCallType } from 'types'

export type SchedulePublishErrorKey =
  | 'formatted_publishing_date'
  | 'formatted_publishing_time'

interface SchedulePublishDialogContentProps {
  existingSchedulePublish?: boolean
  objectType: TEventCallType
  timezone: string
  errors?: Record<SchedulePublishErrorKey, string[]> | null
  formatted_publishing_date?: string | null
  formatted_publishing_time?: string | null
}

const dialogContentWrapperStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: spacings.grid_gap_basis,
  '& form': {
    width: '327px',
  },
})

const timeAndTimezoneFieldsWrapperStyle = css({
  display: 'flex',
  flexDirection: 'row',
  gridGap: `calc(${spacings.grid_gap_basis} * 2)`,
})

const BannerStyle = css({
  height: '34px',
  backgroundColor: colors.backgrounds.orange,
})

/**
 * Renders inside publish later modal
 */
export default function SchedulePublishDialogContent({
  existingSchedulePublish = false,
  objectType,
  timezone,
  errors,
  formatted_publishing_date = null,
  formatted_publishing_time = null,
}: SchedulePublishDialogContentProps): JSX.Element {
  return (
    <div css={dialogContentWrapperStyle}>
      <p>
        Please specify the date and time you want this{' '}
        {objectType === 'call' ? 'call to action' : objectType} to be published.
        Notifications will not be sent until the{' '}
        {objectType === 'call' ? 'call to action' : objectType} has been
        published.
      </p>
      <form id="schedule_publish_form">
        <FormFieldContainer
          fieldId={`${objectType}[formatted_publishing_date]`}
          label="Date">
          <DatePicker
            defaultValue={formatted_publishing_date}
            placeholder="Select date"
            name={`${objectType}[formatted_publishing_date]`}
            errors={errors?.formatted_publishing_date}
          />
        </FormFieldContainer>
        <div css={timeAndTimezoneFieldsWrapperStyle}>
          <FormFieldContainer
            fieldId={`${objectType}[formatted_publishing_time]`}
            label="Time"
            css={{
              flexGrow: 1,
            }}>
            <TimePicker
              name={`${objectType}[formatted_publishing_time]`}
              defaultValue={formatted_publishing_time}
              placeholder="Set time"
              errors={errors?.formatted_publishing_time}
            />
          </FormFieldContainer>
          <FormFieldContainer
            fieldId="publish-later-timezone-field"
            label="Timezone"
            labelVisibility={FormFieldLabelVisibility.HIDE_KEEP_SPACE}>
            <TimezonePicker
              css={{
                width: '75px',
              }}
              name={'publish-later-timezone-field'}
              defaultTimezone={timezone}
              timezones={window.timezones}
              disabled
            />
          </FormFieldContainer>
        </div>
      </form>
      {existingSchedulePublish && (
        <Banner css={BannerStyle}>
          <Container>
            <InfoIcon
              css={{
                '& path': {
                  fill: colors.states.warning,
                },
              }}
            />
            <Caption>
              Clicking on the “Update and Save” button will also save any
              changes made on the form.
            </Caption>
          </Container>
        </Banner>
      )}
    </div>
  )
}
