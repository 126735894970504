import { TUserFilters } from 'state/Users'
import AnalyticalReport from './AnalyticalReport'
import { TFilterOptions } from 'components/MembershipFilters'

interface IAdvancementReportProps {
  setLoading?: (loading: boolean) => void
  loading?: boolean
  filters: TFilterOptions
  advancementResults: {
    community_impact: number
    mme_impact: number
    community_mme_impact: number
  }
  loadData: (filters: Partial<TUserFilters>) => void
  exportLink?: string
}

export default function AdvancementReport({
  setLoading = () => {},
  loading = false,
  filters,
  advancementResults,
  loadData,
  exportLink = '',
}: IAdvancementReportProps): JSX.Element {
  return (
    <AnalyticalReport
      setLoading={setLoading}
      loading={loading}
      filterOptions={filters}
      reportName="Mindr impact on advancement rate"
      exportLink={exportLink}
      mentorshipStatData={{
        rate: (advancementResults?.mme_impact * 100).toFixed(),
        description: 'more likely to receive a promotion',
      }}
      communitiesStatData={{
        rate: (advancementResults?.community_impact * 100).toFixed(),
        description: 'more likely to receive a promotion',
      }}
      mentorshipCommunitiesStatData={{
        rate: (advancementResults?.community_mme_impact * 100).toFixed(),
        description: 'more likely to receive a promotion',
      }}
      loadData={loadData}
      understandingRatesTitle="Understanding Mindr impact on advancement rates"
      understandingRatesDescription="Mindr advancement impact statistics are calculated by comparing the advancement rate amongst employees who are engaged with Mindr, versus those who are not engaged. For the Communities impact rate, engagement is defined as being a subscriber to at least one community, for the MME impact rate, engagement is defined as being an alumni of the program, and for the Communities and MME impact rate, engagement is defined as being both a subscriber and an alumni (and is compared to engagement in neither)."
      warning="Some impact statistics are unavailable due to privacy restrictions or incomplete data, leading  to missing information on titles, departments, and locations. If you have any questions, please contact your organization admin."
    />
  )
}
