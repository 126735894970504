import { TUserFilters } from 'state/Users'
import AnalyticalReport from './AnalyticalReport'
import { TFilterOptions } from 'components/MembershipFilters'

interface IRetentionReportProps {
  setLoading?: (loading: boolean) => void
  loading?: boolean
  filters: TFilterOptions
  retentionResults: {
    mme_impact: number
    community_impact: number
    community_mme_impact: number
  }
  loadData: (filters: Partial<TUserFilters>) => void
  exportLink?: string
}

export default function RetentionReport({
  setLoading = () => {},
  loading = false,
  filters,
  retentionResults,
  loadData,
  exportLink = '',
}: IRetentionReportProps): JSX.Element {
  return (
    <AnalyticalReport
      setLoading={setLoading}
      loading={loading}
      queryDates
      filterOptions={filters}
      exportLink={exportLink}
      reportName="Mindr impact on turnover rate"
      mentorshipStatData={{
        rate: (retentionResults?.mme_impact * 100).toFixed(),
        description: 'reduction in turnover',
      }}
      communitiesStatData={{
        rate: (retentionResults?.community_impact * 100).toFixed(),
        description: 'reduction in turnover',
      }}
      mentorshipCommunitiesStatData={{
        rate: (retentionResults?.community_mme_impact * 100).toFixed(),
        description: 'reduction in turnover',
      }}
      loadData={loadData}
      understandingRatesTitle="Understanding Mindr impact on turnover rates"
      understandingRatesDescription="Mindr turnover impact statistics are calculated by comparing the turnover rate amongst employees who are engaged with Mindr, versus those who are not engaged. Specifically, we follow users who are active at the start of the period, and examine their engagement with Mindr and activation status at the end of the period. For the Communities impact rate, engagement is defined as being a subscriber to at least one community, for the MME impact rate, engagement is defined as being an alumni of the program, and for the Communities and MME impact rate, engagement is defined as being both a subscriber and an alumni (and is compared to engagement in neither)."
      warning="Some impact statistics are unavailable due to privacy restrictions or incomplete data, leading to missing information on titles, departments, and locations. If you have any questions, please contact your organization admin."
    />
  )
}
