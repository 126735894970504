import React, { useEffect, useCallback, useState } from 'react'

import ImageWithTitleRow, { TImageWithTitleValue } from './ImageWithTitleRow'

import './ImagesWithTitle.scss'
import DragAndDrop from 'components/DragAndDrop'

interface IImagesWithTitleProps {
  name: string
  textHeader: string
  data: TImageWithTitleValue[]
  minimumImages: number
}

const ImagesWithTitle: React.FC<IImagesWithTitleProps> = ({
  name,
  data: originalData,
  textHeader,
  minimumImages,
}) => {
  const [data, setData] = useState(originalData || [])

  /**
   * Prevents removed image to be shown in the drag and drop list
   */
  useEffect(() => {
    setData(
      originalData.map((value) => ({
        ...value,
        hidden: value.isMarkedForDestruction,
      })),
    )
  }, [originalData])

  const addNewImage = useCallback(
    (e?) => {
      if (e) {
        e.preventDefault()
      }

      setData([
        ...data,
        {
          id: '0',
          url: undefined,
          fileName: undefined,
          title: undefined,
          errors: {},
          isMarkedForDestruction: false,
        },
      ])
    },
    [data, setData],
  )

  useEffect(() => {
    const activeImages = originalData.filter(
      (value) => !value.isMarkedForDestruction,
    )
    const newData = [...data]
    if (activeImages.length <= minimumImages) {
      for (let i = 0; i < minimumImages - activeImages.length; i++) {
        newData.push({
          id: '0',
          url: undefined,
          fileName: undefined,
          title: undefined,
          errors: {},
          isMarkedForDestruction: false,
        })
      }
    }

    setData(
      newData.map((value) => ({
        ...value,
        hidden: value.isMarkedForDestruction,
      })),
    )
  }, [originalData, setData])

  const onChange = useCallback(
    (index, newValue) => {
      const updated = { ...data[index], ...newValue }
      data.splice(index, 1, updated)
      setData([...data])
    },
    [minimumImages, data, setData],
  )

  return (
    <div className="images-with-title">
      <DragAndDrop
        name={name}
        items={data}
        setItems={setData}
        component={(index, item) => (
          <ImageWithTitleRow
            key={`image-with-title-${index}`}
            name={`${name}[${index}]`}
            value={item}
            textHeader={textHeader}
            onChange={(newValue) => onChange(index, newValue)}
            css={{
              '&.row.dragable': {
                maxWidth: 'none',
              },
            }}
          />
        )}></DragAndDrop>
      <button className="link" onClick={addNewImage}>
        + Add Image
      </button>
    </div>
  )
}

export default ImagesWithTitle
