import { colors, spacings } from 'stylesheets/theme'
import Container from 'components/Container/Container'
import Button, { ButtonComponentType, Variant } from 'components/Button/Button'
import InfoIcon from 'images/icons/info.svg'
import { css } from '@emotion/react'
import { Paragraph } from 'components/Typography'

const warningContainerStyle = css({
  backgroundColor: colors.red_light,
  border: `1px solid ${colors.red}`,
  padding: spacings.grid_gap_basis_num * 2,
  borderRadius: spacings.radius_num,
  gap: spacings.grid_gap_basis_num * 2,
  marginBottom: spacings.grid_gap_basis_num * 2,
})

export default function WarningBanner(): JSX.Element {
  return (
    <Container direction="column" alignment="start" css={warningContainerStyle}>
      <Container
        alignment="start"
        css={{
          gap: spacings.grid_gap_basis_num / 2,
        }}>
        <InfoIcon
          css={{
            minWidth: 14,
            minHeight: 22,
            path: {
              fill: colors.red,
            },
          }}
        />
        <Paragraph>
          Microsoft Teams is discontinuing the connectors feature and
          transitioning to Power Automate workflows, which has resulted in
          connectors no longer appearing in the channel menu. Existing
          connectors will continue to function until December 2025.{' '}
          <b>
            Please be patient as we update the integration guide to align with
            the current Teams version.
          </b>
        </Paragraph>
      </Container>
      <Paragraph
        css={{
          marginLeft: `calc(14px + ${spacings.grid_gap_basis_num / 2}px)`,
        }}>
        For more information, read about{' '}
        <Button
          variant={Variant.LINK}
          as={ButtonComponentType.LINK}
          href="https://devblogs.microsoft.com/microsoft365dev/retirement-of-office-365-connectors-within-microsoft-teams/#:~:text=Starting%20August%2015th%2C%202024%20we,%2C%20flexible%2C%20and%20secure%20way."
          target="_blank"
          css={{
            display: 'inline',
          }}>
          this update
        </Button>{' '}
        from Microsoft Teams or reach out to{' '}
        <Button
          variant={Variant.LINK}
          as={ButtonComponentType.LINK}
          href="mailto:support@mindrglobal.com"
          css={{
            display: 'inline',
          }}>
          support@mindrglobal.com
        </Button>
        .
      </Paragraph>
    </Container>
  )
}
