import React from 'react'
import Pill, { PillColor } from 'components/Pill'
import {
  MentorshipProgramMatchNotificationStatus,
  TMentorshipProgramMatchNotificationStatus,
  TPillSize,
} from 'types'
import { css } from '@emotion/react'

const pillWrapStyle = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
})

interface IColorAndSize {
  color: PillColor
  size: TPillSize
}

function colorAndSize(
  color: PillColor,
  size: TPillSize = 'small',
): IColorAndSize {
  return {
    color,
    size,
  }
}

const PILL_SETTINGS: Record<
  TMentorshipProgramMatchNotificationStatus,
  IColorAndSize
> = {
  pending: colorAndSize(PillColor.GREY),
  notified: colorAndSize(PillColor.GREEN),
}

interface MatchRowNotificaitonStatusPillProps {
  inline?: boolean
  data: {
    notification_status: TMentorshipProgramMatchNotificationStatus | null
  }
}

const MatchRowNotificationStatusPill = ({
  data,
}: MatchRowNotificaitonStatusPillProps): React.ReactElement => {
  const { notification_status } = data

  if (!PILL_SETTINGS[notification_status]) {
    return null
  }
  const settings: IColorAndSize = PILL_SETTINGS[notification_status]

  return (
    <span css={pillWrapStyle}>
      <Pill color={settings.color} size={settings.size} border>
        {MentorshipProgramMatchNotificationStatus[notification_status]}
      </Pill>
    </span>
  )
}

export default MatchRowNotificationStatusPill
