import { FeatureFlagsComponent } from './FeatureFlagsComponent'
import {
  FeatureFlagsContextProvider,
  IFeatureFlag,
  IFeatureFlagsContext,
  TFeatureFlagsCurrentValues,
} from './FeatureFlagsContext'

interface IFeatureFlagsProps extends IFeatureFlagsContext {
  features: IFeatureFlag[]
  currentValues: TFeatureFlagsCurrentValues
}

export default function FeatureFlags({
  groups,
  features,
  currentValues,
}: IFeatureFlagsProps): JSX.Element {
  return (
    <FeatureFlagsContextProvider
      groups={groups}
      features={features}
      currentValues={currentValues}>
      <FeatureFlagsComponent></FeatureFlagsComponent>
    </FeatureFlagsContextProvider>
  )
}
