import { css } from '@emotion/react'
import { colors } from 'stylesheets/theme'

export const arrowStyle = css({
  position: 'absolute',
  top: 'calc(50% - 15px)',
  transform: 'translateY(-50%)',
  width: 30,
  height: 30,
  cursor: 'pointer',

  '&:hover': {
    '&::before': {
      position: 'absolute',
      top: -5,
      left: -5,
      width: 40,
      height: 40,
      content: '""',
      borderRadius: 20,
      backgroundColor: colors.teal_light,
      zIndex: -3,
    },
  },

  '&.slick-prev': {
    left: -48,

    '&.img': {
      left: -48,
    },
  },

  '&.slick-next': {
    right: -48,

    '&.img': {
      right: -48,
    },
  },
})

export const slickDotStyle = css({
  display: 'flex !important',
  justifyContent: 'center',
  marginTop: 18,
  listStyle: 'none',

  button: {
    position: 'relative',
    width: 16,
    height: 16,
    borderRadius: 16,
    background: 'none',
    border: `2px solid ${colors.text.text_9}`,
    fontSize: 0,
    margin: 0,
    padding: 0,
    boxSizing: 'border-box',
  },

  li: {
    '&:not(:last-child)': {
      marginRight: 12,
    },
    '&.slick-active button:after': {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: 8,
      height: 8,
      borderRadius: 8,
      top: 2,
      left: 2,
      background: colors.text.text_5,
    },
  },
})

export const slickStyle = css({
  '.slider': {
    marginLeft: 0,
    marginRight: 0,
    '.slick-arrow': arrowStyle,
    '.slick-dots': slickDotStyle,
  },
})
