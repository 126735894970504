import Checkbox from 'images/icons/checkbox.svg'
import CheckboxChecked from 'images/icons/check_box_checked.svg'
import Button, { Variant } from 'components/Button'

export interface ICheckboxCellData {
  id?: number // This can be null as this component is used by column header to indicate that all rows are checked or unchecked
  checked: boolean
}

export interface ICheckboxCellProps {
  data: ICheckboxCellData
  onCheck: (rowData: ICheckboxCellData) => void
  onUncheck: (rowData: ICheckboxCellData) => void
}

export default function CheckboxCell({
  data,
  onCheck,
  onUncheck,
}: ICheckboxCellProps): JSX.Element {
  if (data.checked) {
    return (
      <Button
        aria-label="Uncheck row"
        variant={Variant.UNSTYLED}
        onClick={() => onUncheck(data)}>
        <CheckboxChecked
          css={{
            width: 15,
            height: 15,
          }}
        />
      </Button>
    )
  }
  return (
    <Button
      aria-label="Check row"
      variant={Variant.UNSTYLED}
      onClick={() => onCheck(data)}>
      <Checkbox
        css={{
          width: 15,
          height: 15,
        }}
      />
    </Button>
  )
}
