import { useState, useCallback } from 'react'

export type TOrderDirection = 'asc' | 'desc'
export type TOrder = {
  column: string | undefined
  direction: TOrderDirection | undefined
}

interface IOrderState {
  order: TOrder
  updateOrder: (column: string, direction: TOrderDirection) => void
}

const useOrderState = (defaultOrder?: TOrder | undefined): IOrderState => {
  const [order, setOrder] = useState<TOrder>({
    column: defaultOrder?.column,
    direction: defaultOrder?.direction,
  })

  const updateOrder = useCallback(
    (column: string, direction: TOrderDirection) => {
      setOrder({
        column,
        direction,
      })
    },
    [setOrder],
  )

  return {
    order,
    updateOrder,
  }
}

export default useOrderState
