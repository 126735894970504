import { useMemo } from 'react'
import Table, { ITableProps } from 'components/Table'
import noGuestsImg from 'images/people_empty_state.png'
import noDeclinedImg from 'images/declined_empty_state.png'
import noTentativeImg from 'images/tentative_guest_empty_state.png'

enum MeetingGuestsType {
  ATTENDING = 'attending',
  DECLINED = 'declined',
  TENTATIVE = 'tentative',
}

interface MeetingGuestsTableProps extends ITableProps {
  type: MeetingGuestsType
}

/**
 * Shows card with registrants table
 * If there are no registrants, show empty state with invite users button to show modal
 */
export default function MeetingGuestsTable({
  type,
  ...props
}: MeetingGuestsTableProps): JSX.Element {
  const emptyStateImg = useMemo(() => {
    switch (type) {
      case MeetingGuestsType.ATTENDING:
        return noGuestsImg
      case MeetingGuestsType.TENTATIVE:
        return noTentativeImg
      case MeetingGuestsType.DECLINED:
        return noDeclinedImg
      default:
        return noGuestsImg
    }
  }, [type])

  const emptyStateTitle = useMemo(() => {
    switch (type) {
      case MeetingGuestsType.ATTENDING:
        return 'There are no attendees for this meeting'
      case MeetingGuestsType.TENTATIVE:
        return 'There are no tentative guests for this meeting'
      case MeetingGuestsType.DECLINED:
        return 'No guests have declined this meeting'
      default:
        return 'There are no attendees for this meeting'
    }
  }, [type])

  return (
    <Table
      {...props}
      emptyStateImgUrl={emptyStateImg}
      emptyStateChildren={
        <p key="guest-table-empty-state-title">{emptyStateTitle}</p>
      }
    />
  )
}
