import { createContext } from 'react'

export type LeadrAssessmentContextValue = {
  isMobile: boolean
  maxSteps: number
}

export const LeadrAssessmentContext =
  createContext<LeadrAssessmentContextValue>({
    isMobile: false,
    maxSteps: 0,
  })

export default LeadrAssessmentContext
