import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'

export const SInquiryTextarea = css({
  height: 216,
  width: '100%',
  resize: 'none',
})

export const SCaption = css({
  marginBottom: 2 * spacings.grid_gap_basis_num,
})

export const SInquiryModal = css({
  width: 696,
  maxWidth: '100%',

  '.mindr-modal-body': {
    marginTop: 0,
  },

  '.mindr-modal-actions': {
    marginTop: 2 * spacings.grid_gap_basis_num,

    '.button.primary': {
      marginRight: 0,
    },
  },
})

export const SMobileInquiryModal = css({
  width: 'auto',
  marginLeft: 0,
  marginRight: 0,
  paddingLeft: 2 * spacings.grid_gap_basis_num,
  paddingRight: 2 * spacings.grid_gap_basis_num,

  // Touching bottom of screen
  marginBottom: 0,
  marginTop: '10vh',
  height: '90vh',

  '.mindr-modal-body': {
    height: '100%',
  },
})

export const SMobileModalContent = css({
  height: '100%',
  alignItems: 'flex-start',

  '.input-component-wrapper': {
    height: '100%',

    '.input-wrapper': {
      height: '100%',

      textarea: {
        minHeight: 250,
        maxHeight: '100%',
        height: '100%',
      },
    },
  },
})

export const SMobileCloseButton = css({
  position: 'static',
})

export const SMobileButtonRow = css({
  width: '100%',
  marginBottom: 1.5 * spacings.grid_gap_basis_num,
})

export const SMobileTitle = css({
  marginBottom: spacings.grid_gap_basis_num,
  width: '100%',
})
