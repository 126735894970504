import { ReactNode } from 'react'

import { ITableProps } from 'components/Table'
import { TableContextProvider } from 'components/Table/TableContext'
import PaginateWrapper from './PaginateWrapper'

type PaginatedListProps<T> = Omit<ITableProps<T>, 'children'> & {
  children: ReactNode
}

const PaginatedList = <T,>({
  children,
  ...props
}: PaginatedListProps<T>): JSX.Element => {
  return (
    <TableContextProvider {...props}>
      <PaginateWrapper>{children}</PaginateWrapper>
    </TableContextProvider>
  )
}

export default PaginatedList
