import useMediaQuery, { useComplexMediaQuery } from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { css } from '@emotion/react'
import {
  boxShadow,
  calcGridWidth,
  colors,
  sizes,
  spacings,
} from 'stylesheets/theme'
import Container from 'components/Container/Container'
import { Caption } from 'components/Typography'
import EventCallContent from './EventCallContent'
import RecommendedResources from './RecommendedResources'
import DetailsFooter from 'components/DetailsFooter'
import Footer from 'components/Footer'
import NavigationTabs from 'components/NavigationTabs/NavigationTabs'
import { ICommunity, IEventCall, Resource, ResourceType } from 'types'
import CallHeader from './CallHeader'
import CallAction from 'components/CallAction'
import {
  DEFAULT_NAVIGATION_STYLE,
  DESKTOP_NAVIGATION_STYLE,
  PAGE_PADDING,
  PAGE_WIDTH,
  PAGE_WIDTH_AND_PADDING,
} from './constants'
import { useShowGridOverlay } from 'hooks/useShowGridOverlay'

const imageStyle = css({
  width: '100%',
  height: 'auto',
  borderRadius: spacings.radius_num / 2,
  objectFit: 'cover',
})

const defaultContentWrapper = css({
  padding: `${spacings.grid_gap_basis_num * 2}px 0`,
  paddingLeft: calcGridWidth(1, 1) + PAGE_PADDING,
  paddingRight: PAGE_PADDING,
  width: '100%',
  maxWidth: '100vw',
  marginLeft: 'auto',
  marginRight: 'auto',
})

const mobileContentWrapper = css({
  padding: `${spacings.grid_gap_basis_num * 2}px`,
})

const desktopContentWrapper = css({
  width: PAGE_WIDTH_AND_PADDING,
})

const defaultDetailContainer = css({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  gap: spacings.grid_gap_basis_num * 2,
})

const mobileDetailContainer = css({
  flexWrap: 'wrap-reverse',
  justifyContent: 'center',
  width: '100%',
})

const defaultTimelineResourcesContainer = css({
  padding: `${spacings.grid_gap_basis_num * 2}px ${PAGE_PADDING}px ${
    spacings.grid_gap_basis_num * 2
  }px ${calcGridWidth(1, 1) + PAGE_PADDING}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  gap: spacings.grid_gap_basis_num * 2,
  justifyContent: 'flex-start',
  width: '100%',
  maxWidth: '100%',
  span: {
    whiteSpace: 'unset',
  },
})

const desktopTimelineResourcesContainer = css({
  width: PAGE_WIDTH,
  maxWidth: `calc(100% - ${PAGE_PADDING * 2}px)`,
  paddingLeft: calcGridWidth(1, 1),
  paddingRight: 0,
})

const mobileTimelineResourcesContainer = css({
  padding: spacings.grid_gap_basis_num * 2,
  maxWidth: '100vw',
})

const defaultPrevNextContainer = css({
  padding: `${spacings.grid_gap_basis_num * 2}px ${PAGE_PADDING}px ${
    spacings.grid_gap_basis_num * 2
  }px ${calcGridWidth(1, 1) + PAGE_PADDING}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: sizes.footerHeight,
  width: '100%',
  maxWidth: '100%',
})

const desktopPrevNextContainer = css({
  width: PAGE_WIDTH,
  maxWidth: `calc(100% - ${PAGE_PADDING * 2}px)`,
  paddingLeft: calcGridWidth(1, 1),
  paddingRight: 0,
})

const mobilePrevNextContainer = css({
  padding: spacings.grid_gap_basis_num * 2,
  marginBottom: 0,
  maxWidth: '100vw',
})

interface Call {
  title: string
  subtitle: string
  description: string
  location_virtual?: string
  location?: string
  allies_welcome?: boolean
  date: string
  time: string
  image: string
  image_alt?: string
  invitable?: boolean
}

interface CallPageProps {
  call: Call
  host: ICommunity
  cohosts?: ICommunity[]
  isCohort?: boolean
  locationInPerson?: boolean
  location?: string
  locationVirtual?: boolean
  resources?: Resource<ResourceType>[]
  prev?: IEventCall
  next?: IEventCall
  viewAllUrl?: string
  callData?: IEventCall
  doMarkComplete?: boolean
  descriptionHtml?: string
  cohortTabVisible?: boolean
}

export default function CallPage({
  call,
  host,
  cohosts,
  isCohort = false,
  locationInPerson = false,
  location,
  locationVirtual = false,
  resources,
  prev,
  next,
  viewAllUrl,
  callData,
  doMarkComplete = false,
  descriptionHtml,
  cohortTabVisible = false,
}: CallPageProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const isFullDesktop = useComplexMediaQuery({ minWidth: breakpoints.xlg })
    .meetsDetails.minWidth
  return (
    <div css={{ maxWidth: '100vw' }}>
      {useShowGridOverlay().overlay}
      {!isMobile && (
        <NavigationTabs
          cohortTabVisible={cohortTabVisible}
          activeTab="calls"
          css={[
            DEFAULT_NAVIGATION_STYLE,
            isFullDesktop && DESKTOP_NAVIGATION_STYLE,
          ]}
        />
      )}
      <CallHeader
        isMobile={isMobile}
        title={call.title}
        data={callData}
        doMarkComplete={doMarkComplete}
        timeToComplete={call.time}
        invitable={call.invitable}
      />
      <Container
        css={[
          defaultContentWrapper,
          isFullDesktop && desktopContentWrapper,
          isMobile && mobileContentWrapper,
        ]}>
        <Container
          css={[defaultDetailContainer, isMobile && mobileDetailContainer]}>
          <EventCallContent
            css={{
              flex: 1,
              maxWidth: isMobile ? '100%' : calcGridWidth(5),
            }}
            host={host}
            cohosts={cohosts}
            subtitle={call.subtitle}
            descriptionHtml={descriptionHtml}
            isCohort={isCohort}
            locationInPerson={locationInPerson}
            location={location}
            locationVirtual={locationVirtual}
            locationUrl={call.location_virtual}
            alliesWelcome={call.allies_welcome}
            openUntilDate={call.date}
            isCall
          />
          <Container
            direction="column"
            css={{
              alignItems: 'flex-start',
              width: isMobile ? '100%' : calcGridWidth(4),
            }}>
            <img css={imageStyle} src={call.image} alt={call.image_alt} />
            <Caption>{call.image_alt}</Caption>
          </Container>
        </Container>
      </Container>
      {!!resources.length && (
        <Container
          css={{
            backgroundColor: colors.backgrounds.highlight,
            width: '100%',
          }}>
          <Container
            css={[
              defaultTimelineResourcesContainer,
              isFullDesktop && desktopTimelineResourcesContainer,
              isMobile && mobileTimelineResourcesContainer,
            ]}>
            <div
              css={{
                flex: 1,
                minWidth: 335,
                maxWidth: isMobile ? undefined : '50%',
              }}>
              <RecommendedResources resources={resources} />
            </div>
          </Container>
        </Container>
      )}
      <Container css={{ wdith: '100%' }}>
        <div
          css={[
            defaultPrevNextContainer,
            isFullDesktop && desktopPrevNextContainer,
            isMobile && mobilePrevNextContainer,
          ]}>
          <DetailsFooter
            prev={prev}
            next={next}
            type="call"
            viewAllUrl={viewAllUrl}
            isMobile={isMobile}
          />
        </div>
      </Container>
      <Footer isResponsivePage />
      {isMobile && (
        <Container
          css={{
            padding: `${spacings.grid_gap_basis_num * 2}px ${
              spacings.grid_gap_basis_num
            }px`,
            bottom: 0,
            position: 'sticky',
            backgroundColor: colors.backgrounds.white,
            boxShadow: boxShadow.footerContainer,
            justifyContent: 'center',
            zIndex: 10,
          }}>
          <CallAction
            data={callData}
            doMarkComplete={doMarkComplete}
            location={'banner'}
            css={{
              width: '100%',
            }}
            invitable={call.invitable}
          />
        </Container>
      )}
    </div>
  )
}
