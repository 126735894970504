import React, { ReactNode, AnchorHTMLAttributes } from 'react'
import cx from 'classnames'
import './NavButton.scss'

interface NavButtonProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  icon?: ReactNode
  title?: string
  collapsed?: boolean
  isActive?: boolean
}

export default function NavButton({
  icon = null,
  collapsed = false,
  isActive = false,
  className,
  ...props
}: NavButtonProps): JSX.Element {
  const { title } = props
  return (
    <a
      {...props}
      className={cx('nav-button', className, {
        collapsed: collapsed,
        active: isActive,
      })}>
      {!!icon && icon}
      {<span>{title}</span>}
    </a>
  )
}
