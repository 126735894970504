import React, { FC } from 'react'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import axios from 'axios'

import TrashIcon from 'images/icons/trash.svg'
import { defaultSwalParams } from 'javascripts/general'

const ChangeAvatarModal: FC<{ avatar_url: string }> = ({ avatar_url }) => {
  const handleDeleteClick = async () => {
    const swalResponse = await Swal.fire({
      ...defaultSwalParams,
      width: 502,
      title: 'Are you sure?',
      confirmButtonText: 'delete photo',
      customClass: {
        ...defaultSwalParams.customClass,
        container: 'avatar-update-info',
      },
      html: '<p>Your profile photo helps people to recognize <br> and discover you.</p>',
    })

    if (swalResponse.isConfirmed) {
      try {
        await axios.patch('/dashboard/profile.json', {
          user: {
            remove_avatar: true,
          },
          authenticity_token: window.authenticity_token,
        })
        location.reload()
      } catch (e) {
        window.flash('Something went wrong', 'alert')
      }
    }
  }

  return (
    <div className="change-avatar-modal-body">
      <img src={avatar_url} alt="user-avatar" className="preview" />
      <button
        onClick={handleDeleteClick}
        className="button cancel delete-button">
        <TrashIcon />
      </button>
    </div>
  )
}

export default ChangeAvatarModal
