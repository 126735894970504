import Pill, { PillColor } from 'components/Pill'

import { OperationalLogStatus } from 'types'

interface OperationalLogStatusPillProps {
  status: OperationalLogStatus | null
}

export default function OperationalLogStatusPill({
  status,
}: OperationalLogStatusPillProps): JSX.Element {
  switch (status) {
    case OperationalLogStatus.SUCCESS:
      return (
        <Pill color={PillColor.GREEN} size="small" border>
          Success
        </Pill>
      )
    case OperationalLogStatus.FAILURE:
      return (
        <Pill color={PillColor.RED} size="small" border>
          Failed
        </Pill>
      )
    default:
      return (
        <Pill color={PillColor.GREY} size="small" border>
          Unknown
        </Pill>
      )
  }
}
