import Container from 'components/Container/Container'
import { FormFieldContainer } from 'components/Forms'
import { Select } from 'components/Inputs'
import { IOption } from 'components/Inputs/Select'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { sizes, spacings } from 'stylesheets/theme'
import { IProgramSummary, SimpleMentorshipProgram } from 'types'
import { useCallback, useMemo, useState } from 'react'
import axios from 'axios'

import MentorshipDetailsSummary from './MentorshipDetailsSummary'

type MentorshipProgramOption = IOption & {
  programName: string
}

export const mobileMinWidth = 335

const dropdownStyle = {
  control: {
    width: '350px',
  },
  menu: {
    width: '350px',
  },
}

const mobileDropdownStyle = {
  control: {
    width: `calc(100vw - ${spacings.grid_gap_basis_num * 4}px)`,
  },
  menu: {
    width: `calc(100vw - ${spacings.grid_gap_basis_num * 4}px)`,
  },
}

export interface MentorshipDetailsPageProps {
  participatingPrograms: SimpleMentorshipProgram[]
  defaultProgramSummary: IProgramSummary
}

export default function MentorshipDetailsPage({
  participatingPrograms,
  defaultProgramSummary,
}: MentorshipDetailsPageProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const [programSummary, setProgramSummary] = useState(defaultProgramSummary)
  const { current_program } = useMemo(() => programSummary, [programSummary])

  const fetchProgramSummary = useCallback(
    async (programId) => {
      const { details_ajax_url } = participatingPrograms.find(
        (program) => program.id.toString() == programId,
      )
      const response = await axios.get(details_ajax_url)
      setProgramSummary(response.data.summary)
    },
    [participatingPrograms],
  )
  return (
    <Container
      css={{
        flexDirection: 'column',
        alignItems: 'flex-start',
        maxWidth: '100%',
        marginBottom: isMobile ? 0 : sizes.footerHeight,
        padding: isMobile
          ? spacings.grid_gap_basis_num * 2
          : `${spacings.grid_gap_basis_num * 2}px ${
              spacings.grid_gap_basis_num * 5
            }px ${spacings.grid_gap_basis_num * 4}px ${
              spacings.grid_gap_basis_num * 5
            }px`,
      }}>
      <FormFieldContainer fieldId="timezone-picker" label="Program">
        <Select<MentorshipProgramOption>
          id="mentorship-program-select"
          name="mentorship-program-select"
          defaultValue={current_program.id.toString()}
          options={participatingPrograms.sort().map((program) => ({
            label: program.name,
            value: program.id.toString(),
            programName: program.name,
          }))}
          styleOverrides={isMobile ? mobileDropdownStyle : dropdownStyle}
          isSearchable={!isMobile}
          onChange={(selectedOption) => {
            const programId = selectedOption['mentorship-program-select']
            fetchProgramSummary(programId)
          }}
        />
      </FormFieldContainer>
      <MentorshipDetailsSummary
        programSummary={programSummary}
        isMobile={isMobile}
      />
    </Container>
  )
}
