import React, { useCallback } from 'react'

import Modal, { Size } from 'components/Modal'
import EventCallSummary from 'components/EventCallSummary'
import UserPicker from 'components/UserPicker'

import { useCallActionContext } from '../context'

import { IEventCall } from 'types'

interface ICompleteConfirmationModal {
  isOpen: boolean
  call: IEventCall
  onSubmit: () => void
  onClose: () => void
  featureAlliesAndAffiliates?: boolean
}

const CompleteConfirmationModal = ({
  isOpen,
  call,
  onSubmit,
  onClose,
}: ICompleteConfirmationModal): React.ReactElement => {
  const closeModal = useCallback(() => {
    onClose()
  }, [onClose])

  const { usersToInvite, setUsersToInvite } = useCallActionContext()

  const onChange = useCallback(
    (value) => {
      setUsersToInvite(value['colleagues-input'])
    },
    [setUsersToInvite],
  )

  return (
    <Modal
      isOpen={isOpen}
      size={Size.large}
      title={`Thanks for getting involved, ${window.currentUser.full_name}!`}
      subtitle="Here are the details for this call to action:"
      onRequestClose={closeModal}
      onSave={onSubmit}
      className="complete-confirmation-modal"
      submitButton={call.invitable ? 'Send Invitations' : 'Close'}
      cancelButton={call.invitable ? 'No Invitations' : undefined}>
      <EventCallSummary
        data={call}
        type="call"
        showHosts
        className="mindr-modal-section"
      />
      {call.invitable && (
        <form
          id="call-invitation-form"
          className="mindr-modal-section"
          onSubmit={onSubmit}>
          <UserPicker
            label="Invite colleagues to join you in participating in this call to action:"
            name="colleagues-input"
            placeholder="Start typing a colleague's name or email"
            value={usersToInvite}
            isMulti
            onChange={onChange}
          />
        </form>
      )}
    </Modal>
  )
}

export default CompleteConfirmationModal
