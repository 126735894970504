import React, { useMemo } from 'react'
import cx from 'classnames'

//assets
import CalendarIcon from '../../images/icons/event_14.svg'
import ClockIcon from '../../images/icons/clock.svg'
import LocationIcon from '../../images/icons/location.svg'
import GlobeIcon from '../../images/icons/globe.svg'
import CohortIcon from '../../images/icons/cohort_14.svg'
import CallToActionIcon from '../../images/icons/call_18.svg'
import RecurringIcon from '../../images/icons/recurring_14.svg'

//components
import CohortPill from 'components/CohortPill'
import EventAction from 'components/EventAction'
import CallAction from 'components/CallAction'

//styles
import './EventCall.scss'

//utils
import { convertUTCToLocalRange } from 'javascripts/general'

//interfaces
import type { IEventCall } from 'types'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { css } from '@emotion/react'
import { spacings } from 'stylesheets/theme'

const mobileContentStyle = css({
  flexDirection: 'column',
})

const desktopLinkContainerStyle = css({})

const mobileLinkContainerStyle = css({
  height: 390,
})

const mobileContentTextStyle = css({
  width: '100% !important',
})

const mobileEventLogoStyle = css({
  marginBottom: spacings.grid_gap_basis,
})

type EventCallProps = {
  data: IEventCall
  isCall?: boolean
  className?: string
}

const ResponsiveFeaturedEventCall: React.FC<EventCallProps> = ({
  data,
  isCall,
  className = '',
}) => {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const dateForBadge = data
    ? (isCall
        ? data.date
        : convertUTCToLocalRange(data.datetime, data.duration)
      ).split(',')
    : null

  const [nextCohost, ...remainingCohosts] = data.cohosts || []

  const decodedDesc = useMemo(() => {
    const txt = document.createElement('textarea')
    txt.innerHTML = data.description
    return txt.value
  }, [data])

  return (
    <div className={cx('event-call-div featured', className)}>
      <a
        href={data.link}
        css={[desktopLinkContainerStyle, isMobile && mobileLinkContainerStyle]}
        className={cx('event featured content-type', {
          action: isCall,
          subscribed: data.registered,
        })}>
        {isCall ? (
          <div className="call-avatar">
            <CallToActionIcon />
          </div>
        ) : (
          <div className="date-badge">
            <p className="day">
              {dateForBadge && dateForBadge[1].trim().split(' ')[0]}
            </p>
            <h1 className="date">
              {dateForBadge &&
                dateForBadge[1].trim().split(' ')[1].slice(0, -2)}
            </h1>
            <p className="day">{dateForBadge && dateForBadge[0]}</p>
          </div>
        )}
        <div className="content" css={[isMobile && mobileContentStyle]}>
          <div
            className="event-logo"
            style={{ backgroundImage: `url(${data.logo})` }}
            css={[isMobile && mobileEventLogoStyle]}
          />
          <div
            className="content-text"
            css={[isMobile && mobileContentTextStyle]}>
            <p className="type">
              <span className="primary">
                {isCall ? 'Call to Action' : 'Event'}
              </span>
              {data.community_type === 'cohort' && <CohortPill size="small" />}
            </p>
            {isMobile ? (
              <h4 className="title featured">{data.title}</h4>
            ) : (
              <h2 className="title featured">{data.title}</h2>
            )}
            <div className="detail">
              {isCall ? (
                <>
                  <ClockIcon />
                  <p className="caption">{data.time}</p>
                </>
              ) : (
                <>
                  {data.is_recurring ? (
                    <RecurringIcon className="icon-stroke" />
                  ) : (
                    <CalendarIcon className="icon-stroke" />
                  )}
                  <p className="caption">
                    {convertUTCToLocalRange(data.datetime, data.duration)}
                  </p>
                </>
              )}
            </div>
            {data.location !== 'None' && (
              <div className="detail">
                <LocationIcon />
                <p className="caption">{data.location || 'Virtual'}</p>
              </div>
            )}
            <div className="detail">
              {data.community_type === 'cohort' ? (
                <CohortIcon className="icon-stroke" />
              ) : (
                <GlobeIcon />
              )}
              <p className="caption">{data.community}</p>
            </div>
            {nextCohost && (
              <div className="detail">
                <span className="empty-icon"></span>
                <p className="cohost caption">{nextCohost.name}</p>
              </div>
            )}
            {remainingCohosts.length === 1 && (
              <div className="detail">
                <span className="empty-icon"></span>
                <p className="cohost caption">{remainingCohosts[0].name}</p>
              </div>
            )}
            {remainingCohosts.length > 1 ? (
              <div className="detail">
                <span className="empty-icon"></span>
                <p className="cohost caption">
                  +{remainingCohosts.length} more communities
                </p>
              </div>
            ) : null}
          </div>
        </div>
        {!isMobile && (
          <div className="info">
            <p className="info-text caption">{decodedDesc}</p>
          </div>
        )}
        {isMobile &&
          (isCall ? (
            <CallAction
              data={data}
              location="card"
              css={{
                a: {
                  zIndex: 10,
                },
              }}
            />
          ) : (
            <EventAction data={data} location="card" />
          ))}
      </a>
      {!isMobile &&
        (isCall ? (
          <CallAction data={data} location="card" />
        ) : (
          <EventAction data={data} location="card" />
        ))}
    </div>
  )
}

export default ResponsiveFeaturedEventCall
