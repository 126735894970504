import Modal, { Size } from 'components/Modal'
import { useEffect } from 'react'

import Table from 'components/Table'
import useTableState from 'state/Table'

interface IChangeLogsModalProps {
  url: string
  onClose: () => void
}

const ChangeLogsModal = ({
  url,
  onClose,
}: IChangeLogsModalProps): JSX.Element => {
  const { data, meta, loadPage, updateOrder } = useTableState({ url })

  useEffect(() => {
    loadPage(0)
  }, [loadPage])

  return (
    <Modal
      isOpen={true}
      size={Size.small}
      onRequestClose={onClose}
      onSave={onClose}
      title="Change Logs">
      {data && meta && (
        <Table
          tableData={data}
          tableMeta={meta}
          onOrderChange={({ sort_col, sort_dir }) => {
            updateOrder(
              sort_col,
              sort_dir === 'asc' || sort_dir === 'desc' ? sort_dir : 'asc',
            )
          }}
          onPageChange={loadPage}
        />
      )}
    </Modal>
  )
}

export default ChangeLogsModal
