import React from 'react'
import Card from 'components/Card'
import PaginatedList from 'components/PaginatedList'
import { ITableData, ITableMeta, IMainTableFilters } from 'components/Table'
import DownloadIcon from 'images/icons/download.svg'
import questionEmptyStateImg from 'images/question_empty_state.png'
import EmptyState from 'components/EmptyState'
import QuestionsList from './QuestionList'

interface QuestionsProps {
  tableData: ITableData
  tableMeta: ITableMeta
  selectedFilters: IMainTableFilters
  timezone: string
}

/**
 * Card on activity overview page to view activity logs for event
 * Activities including edit, published, reminder sent...etc
 */
export default function Questions({
  tableData,
  tableMeta,
  selectedFilters,
  timezone,
}: QuestionsProps): JSX.Element {
  const { rows } = tableData
  const { url } = tableMeta

  return (
    <Card
      title="Submitted Audience Questions"
      actionNode={
        !!rows?.length ? (
          <a href={url.replace('.json', '.csv')} className="link">
            <DownloadIcon />
            Export.csv
          </a>
        ) : undefined
      }>
      {!!rows?.length ? (
        <PaginatedList
          tableData={tableData}
          tableMeta={tableMeta}
          selectedFilters={selectedFilters}>
          <QuestionsList timezone={timezone} />
        </PaginatedList>
      ) : (
        <EmptyState imgUrl={questionEmptyStateImg}>
          <p>
            Questions from the audience will appear here after the event has
            been published
          </p>
        </EmptyState>
      )}
    </Card>
  )
}
