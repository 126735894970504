import React from 'react'
import {
  IMentorshipExchangeDetailsProps,
  MentorshipExchangeDetailsContextProvider,
} from './MentorshipExchangeDetailsContext'
import MentorshipExchangeDetailsComponent from './MentorshipExchangeDetailsComponent'
import { css } from '@emotion/react'
import { colors } from 'stylesheets/theme'

const tableCss = css({
  'table tr:hover td:not(.empty-records)': {
    backgroundColor: colors.backgrounds.gray,
  },
})

export default function MentorshipExchangeDetails({
  exchange,
  tables,
  counts,
  urls,
  boostList,
}: IMentorshipExchangeDetailsProps): JSX.Element {
  return (
    <MentorshipExchangeDetailsContextProvider
      exchange={exchange}
      tables={tables}
      counts={counts}
      boostList={boostList}
      urls={urls}>
      <MentorshipExchangeDetailsComponent css={tableCss} />
    </MentorshipExchangeDetailsContextProvider>
  )
}
