import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'

export const SCircleListStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: spacings.grid_gap_basis_num * 2,
  textAlign: 'center',
})

export const SCircleItemStyle = css({
  display: 'flex',
  flexDirection: 'row',
  gap: spacings.grid_gap_basis_num,
})

export const SItemCircleStyle = css({
  width: 42,
  height: 42,
  lineHeight: '42px',
  borderRadius: 42,
  fontSize: 15,
  border: `1px solid ${colors.text.text_3}`,
  color: colors.text.text_3,
  backgroundColor: 'transparent',
  flexShrink: 0,
  flexGrow: 0,

  '&.green': {
    border: '0 none',
    backgroundColor: colors.green,

    '&.light': {
      backgroundColor: colors.green_bg_light,
    },

    '&.dark': {
      backgroundColor: colors.green_dark,
      color: colors.backgrounds.white,
    },
  },
})
