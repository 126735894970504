import LeadrAssessmentInviteModalContextProvider from './LeadrAssessmentInviteModalContext'
import { IModalProps } from 'components/Modal'
import LeadrAssessmentInviteModalComponent from './LeadrAssessmentInviteModalComponent'
import { ITableProps } from 'components/Table'

interface ILeadrAssessmentInviteModalProps {
  isOpen: IModalProps['isOpen']
  onClose: IModalProps['onRequestClose']
  urls: Record<string, string>
  setActivityLogsTableData?: (data: ITableProps['tableData']) => void
  setActivityLogsTableMeta?: (meta: ITableProps['tableMeta']) => void
  setActivityLogsTableSelectedFilters?: (
    selectedFilters: ITableProps['selectedFilters'],
  ) => void
}

export default function LeadrAssessmentInviteModal({
  isOpen,
  onClose,
  urls,
  setActivityLogsTableData,
  setActivityLogsTableMeta,
  setActivityLogsTableSelectedFilters,
}: ILeadrAssessmentInviteModalProps): JSX.Element {
  return (
    <LeadrAssessmentInviteModalContextProvider
      isOpen={isOpen}
      object="leadr"
      objectId={0}
      urls={urls}
      onClose={onClose}
      setActivityLogsTableData={setActivityLogsTableData}
      setActivityLogsTableMeta={setActivityLogsTableMeta}
      setActivityLogsTableSelectedFilters={setActivityLogsTableSelectedFilters}>
      <LeadrAssessmentInviteModalComponent onClose={onClose} urls={urls} />
    </LeadrAssessmentInviteModalContextProvider>
  )
}
