import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from '../modal-style'

export default function RematchConfirmationModal({
  ...props
}: Omit<
  IModalProps,
  'size' | 'children' | 'submitButton' | 'cancelButton' | 'title'
>): JSX.Element {
  return (
    <Modal
      {...props}
      css={modalStyle}
      size={Size.large}
      submitButton="Rematch Participants"
      cancelButton="Cancel"
      title="Rematch this pair">
      <p>
        Are you sure you want to rematch this match? This will separate the
        participants, and the action cannot be undone. These participants can
        then be rematched with other participants in the &quot;Not yet
        matched&quot; table.
      </p>
    </Modal>
  )
}
