import React from 'react'
import Pill, { PillColor } from 'components/Pill'
import {
  MentorshipExchangeStatus,
  TMentorshipExchangeMatchingStatus,
  TMentorshipExchangeStatus,
  TPillSize,
} from 'types'
import MentorshipExchangeMatchingStatusIndicator from './MentorshipExchangeMatchingStatusIndicator'
import { css } from '@emotion/react'

const pillWrapStyle = css({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
})

interface IColorAndSize {
  color: PillColor
  size: TPillSize
}

function colorAndSize(
  color: PillColor,
  size: TPillSize = 'small',
): IColorAndSize {
  return {
    color,
    size,
  }
}

const PILL_SETTINGS: Record<TMentorshipExchangeStatus, IColorAndSize> = {
  scheduled: colorAndSize(PillColor.YELLOW),
  about_to_open: colorAndSize(PillColor.RED),
  application_open: colorAndSize(PillColor.BLUE),
  application_closed: colorAndSize(PillColor.LIGHT_YELLOW),
  application_extended: colorAndSize(PillColor.BLUE),
  application_complete: colorAndSize(PillColor.PURPLE),
}

interface MentorshipExchangeStatusPillProps {
  inline?: boolean
  data: {
    status: TMentorshipExchangeStatus | null
    matching_status: TMentorshipExchangeMatchingStatus | null
  }
}

const MentorshipExchangeStatusPill = ({
  inline = false,
  data,
}: MentorshipExchangeStatusPillProps): React.ReactElement => {
  const { status } = data

  if (!PILL_SETTINGS[status]) {
    return null
  }
  const settings: IColorAndSize = PILL_SETTINGS[status]

  return (
    <span css={pillWrapStyle}>
      <MentorshipExchangeMatchingStatusIndicator
        data={data}
        inline={inline}></MentorshipExchangeMatchingStatusIndicator>
      <Pill color={settings.color} size={settings.size} border>
        {MentorshipExchangeStatus[status]}
      </Pill>
    </span>
  )
}

export default MentorshipExchangeStatusPill
