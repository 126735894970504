import { css } from '@emotion/react'

import Widget from './Widget'

import WaitlistIcon from 'images/icons/waitlist.svg'

import { colors } from 'stylesheets/theme'

interface WaitlistWidgetProps {
  waitlistSize: number
  title: string
}

const iconStyle = css({
  path: {
    fill: colors.orange_dark,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.waitlist_widget_background,
  },
})

export default function WaitlistWidget({
  waitlistSize,
  title,
}: WaitlistWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<WaitlistIcon height="18px" width="18px" css={iconStyle} />}
      number={waitlistSize}
      title={title}
      css={widgetStyle}
    />
  )
}
