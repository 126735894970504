import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'

import InputWrapper from '../InputWrapper/InputWrapper'

// TODO - MC-2575 replace this with a react library
import $ from 'jquery'
import 'jquery-datetimepicker'
import 'jquery-datetimepicker/jquery.datetimepicker.css'
import moment from 'moment'

import './DatePicker.scss'

import {
  IControlledInputBaseProps,
  IUncontrolledInputBaseProps,
} from '../types'

type IControlledDatePicker = IControlledInputBaseProps

type IUncontrolledDatePicker = IUncontrolledInputBaseProps

$.datetimepicker.setDateFormatter({
  parseDate: function (date, format) {
    const d = moment(date, format)
    return d.isValid() ? d.toDate() : false
  },

  formatDate: function (date, format) {
    return moment(date).format(format)
  },

  formatMask: function (format) {
    return format
      .replace(/Y{4}/g, '9999')
      .replace(/Y{2}/g, '99')
      .replace(/M{2}/g, '19')
      .replace(/D{2}/g, '39')
      .replace(/H{2}/g, '29')
      .replace(/m{2}/g, '59')
      .replace(/s{2}/g, '59')
  },
})
$.datetimepicker.setLocale('en')

const DatePicker = ({
  name,
  onChange,
  id,
  errors = [],
  placeholder = '',
  ...props
}: IControlledDatePicker | IUncontrolledDatePicker): React.ReactElement => {
  const [value, setValue] = useState((props as IControlledDatePicker).value)
  const { defaultValue } = props as IUncontrolledDatePicker

  const inputEl = useRef()

  useEffect(() => {
    const $inputEl = $(inputEl.current)
    $inputEl.datetimepicker({
      scrollMonth: false,
      scrollInput: false,
      datepicker: true,
      timepicker: false,
      format: 'ddd, MMM Do, YYYY',
      formatDate: 'ddd, MMM Do, YYYY',
      onChangeDateTime: (_, input) => {
        onChange &&
          onChange({
            [name]: input.val(),
          })
      },
    })
  }, [inputEl])

  return (
    <InputWrapper className="react-date-picker" withIcon errors={errors}>
      <input
        type="text"
        name={name}
        id={id || name}
        defaultValue={defaultValue || undefined}
        value={value || undefined}
        onChange={
          value
            ? (e) => {
                setValue(e.target.value)
              }
            : undefined
        }
        className={cx('react-input', {
          'validation-error': errors?.length,
        })}
        placeholder={placeholder}
        {...props}
        ref={inputEl}
      />
    </InputWrapper>
  )
}

export default DatePicker
