import React from 'react'
import cx from 'classnames'

export interface IMindrFormProps
  extends React.FormHTMLAttributes<HTMLFormElement> {
  children?: React.ReactNode
}

const MindrForm: React.FC<IMindrFormProps> = ({
  children,
  ...props
}): React.ReactElement => {
  return (
    <form
      {...props}
      method="POST"
      className={cx(props.className, 'mindr-form check-leave-form')}>
      {children}
      <input
        type="hidden"
        name="authenticity_token"
        value={window.authenticity_token}
      />
      {props.method == 'PUT' && (
        <input
          type="hidden"
          name="_method"
          value="patch"
          autoComplete="off"></input>
      )}
    </form>
  )
}

export default MindrForm
