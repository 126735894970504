import React from 'react'
import Table, { ITableProps } from 'components/Table'
import noCohostImg from 'images/cohost_empty_state.png'

type CohostTableProps = ITableProps & { editUrl: string; cohostable: boolean }

export default function CohostTable({
  editUrl,
  cohostable,
  ...props
}: CohostTableProps): JSX.Element {
  return (
    <Table
      {...props}
      emptyStateImgUrl={noCohostImg}
      object="cohosts"
      emptyStateSubtitle="Increase outreach and attendance by collaborating with other communities as co-hosts on this event"
      emptyStateActionNode={
        cohostable ? (
          <a
            href={editUrl + '#show-cohosts-button'}
            className="button secondary small plus">
            add cohost
          </a>
        ) : undefined
      }
    />
  )
}
