import { CSSProperties } from 'react'
import type { Styles } from 'react-select'

export type TOverrideStyles = {
  [key in keyof Styles<any, false>]: CSSProperties
}

const styles = (overrides: TOverrideStyles = {}): Styles<any, false> => ({
  menuList: (provided) => {
    return {
      ...provided,
      ...overrides.menuList,
      overflowY: 'scroll',
      position: 'relative',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: '10px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#e0e0e0',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '5px',
        backgroundColor: 'rgba(255, 255, 255, .5)',
        '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
      },
    }
  },
  control: (provided, { isFocused }) => {
    return {
      ...provided,
      borderColor: isFocused ? '#3C9F95' : '#e7e7e7',
      borderRadius: 1,
      borderWidth: 1,
      boxShadow: 'none',
      minHeight: 42,
      transition: 'none',
      padding: '0 7px',
      color: '#999999',
      '&:hover': {
        borderColor: '#e7e7e7',
        color: '#555555',
      },
      ...overrides.control,
    }
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#555555',
      fontSize: '15px',
      lineHeight: '25px',
      ...overrides.placeholder,
    }
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontSize: 14,
      lineHeight: '20px',
      ...overrides.singleValue,
    }
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
      ...overrides.indicatorSeparator,
    }
  },
  dropdownIndicator: (provided) => {
    return {
      ...provided,
      color: '#999999',
      '&:hover': {
        color: '#555555',
      },
      ...overrides.dropdownIndicator,
    }
  },
  multiValue: (provided) => {
    return {
      ...provided,
      backgroundColor: '#e8e8e8',
      borderRadius: 5,
      ...overrides.multiValue,
    }
  },
  multiValueLabel: (provided) => {
    return {
      ...provided,
      fontSize: 14,
      lineHeight: '20px',
      color: '#555',
      ...overrides.multiValueLabel,
    }
  },
  menu: (provided) => {
    return {
      ...provided,
      borderRadius: 1,
      zIndex: 1000,
      ...overrides.menu,
      '&::after': {
        content: '" "',
        position: 'absolute',
        backgroundColor: '#e0e0e0',
        right: '0',
        top: '0',
        bottom: '0',
        width: '10px',
        zIndex: '-1',
      },
    }
  },
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    fontFamily: 'inherit',
    fontSize: '15px',
    lineHeight: '19px',
    backgroundColor: isSelected || isFocused ? '#f5fafd' : '#fff',
    color: isSelected || isFocused ? '#495c68' : '#303030',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'calc(100% - 10px)',

    ':active': {
      ...provided[':active'],
      backgroundColor: '#f5fafd',
      color: '#495c68',
    },
    ...overrides.option,
  }),
})

export default styles
