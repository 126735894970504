import { css } from '@emotion/react'
import Modal, { Size, IModalProps } from 'components/Modal'
import React from 'react'
import { spacings } from 'stylesheets/theme'

const modalStyle = css({
  '.mindr-modal-body': {
    marginTop: Math.round(spacings.grid_gap_basis_num / 2),
  },
})

interface IInviteUsersModalProps
  extends Omit<IModalProps, 'size' | 'children' | 'title'> {
  title?: string | React.ReactNode
  size?: Size
  children?: React.ReactNode
}

export default function SendRemindersModal({
  title = 'Remind invited users about your Mindr Mentorship Exchange Program',
  size = Size.small,
  children = null,
  ...props
}: IInviteUsersModalProps): JSX.Element {
  return (
    <Modal
      {...props}
      size={size}
      title={title}
      css={modalStyle}
      submitButton="Send reminders"
      cancelButton="No Reminders">
      {children}
    </Modal>
  )
}
