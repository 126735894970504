import { css } from '@emotion/react'
import { colors, mqDesktop, spacings } from 'stylesheets/theme'

export const flagsStyle = css({
  userSelect: 'none',
  backgroundColor: colors.backgrounds.white,
  padding: spacings.grid_gap_basis_num,
  maxWidth: '100%',
  width: 577,
  [mqDesktop]: {
    padding: spacings.grid_gap_basis_num * 3,
  },
})

export const groupStyle = css({
  paddingBottom: spacings.grid_gap_basis_num * 3,
})

export const groupIconStyle = css({
  width: spacings.grid_gap_basis_num * 1.5,
  height: spacings.grid_gap_basis_num * 1.5,
})

export const checkboxWithLabelStyle = css({
  display: 'flex',
  flexDirection: 'row',
  gap: spacings.grid_gap_basis_num * 0.5,
  width: '100%',
  minHeight: '22px',
  alignItems: 'center',
  marginTop: spacings.grid_gap_basis_num,
  '.checkbox-wrap': {
    // The +4 is to account for the "borders" on the checkboxes
    maxWidth: spacings.grid_gap_basis_num * 1.25 + 4,
    maxHeight: spacings.grid_gap_basis_num * 1.25 + 4,
  },
})

export const groupCheckboxWithLabelStyle = css({
  marginTop: 0,
  minHeight: '25px',
})

export const featureFlagListStyle = css({
  paddingLeft: spacings.grid_gap_basis_num * 2.25,
})

export const dataNoticeModalStyle = css({
  '&.mindr-modal': {
    width: 696,
  },
})
