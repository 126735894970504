import { ITableProps } from 'components/Table'
import ApplicantsTable from './ApplicantsTable'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import Button, { Variant } from 'components/Button/Button'
import { useMemo, useState } from 'react'
import Modal, { Size } from 'components/Modal'
import Pill, { PillColor } from 'components/Pill'
import moment from 'moment'
import { serverUTCTimeFormat } from 'javascripts/general'

interface PendingManagerApprovalTable {
  pendingManagerApprovalTableProps: ITableProps
  onAction: (url: string, params?: Record<string, any>) => Promise<void>
  sendApprovalreminderUrl: string
  lastSentAt?: string
}

export default function PendingManagerApprovalTable({
  pendingManagerApprovalTableProps,
  onAction,
  sendApprovalreminderUrl,
  lastSentAt,
}: PendingManagerApprovalTable): JSX.Element {
  const { updateTableData, updateTableFilters } =
    useMentorshipExchangeDetailsContext()
  const [openSendApprovalReminderModal, setOpenSendApprovalReminderModal] =
    useState(false)

  const lastSendOn = useMemo(() => {
    if (!lastSentAt) return ''
    return moment(
      (lastSentAt as string).replace('UTC', '+0000'),
      serverUTCTimeFormat,
    ).format('ddd, MMM Do, YYYY, h:mma')
  }, [lastSentAt])
  return (
    <>
      <ApplicantsTable
        afterUpdateTableData={(data) => {
          updateTableData('pending_manager_approval', data)
        }}
        afterUpdateSelectedFilters={(filters) => {
          updateTableFilters('pending_manager_approval', filters)
        }}
        {...pendingManagerApprovalTableProps}
        emptyStateChildren={
          <p css={{ fontWeight: 'normal' }}>No pending manager approvals</p>
        }
        secondaryActionNode={
          <Button
            variant={Variant.SECONDARY}
            onClick={() => setOpenSendApprovalReminderModal(true)}>
            Send Approval Reminder
          </Button>
        }
      />
      <Modal
        isOpen={openSendApprovalReminderModal}
        size={Size.large}
        largeTitle
        title="Send approval reminders to managers"
        submitButton="Send reminders"
        cancelButton="Cancel"
        onRequestClose={() => setOpenSendApprovalReminderModal(false)}
        onSave={() => onAction(sendApprovalreminderUrl)}
        actionRowLeftElement={
          lastSendOn ? (
            <Pill
              size="large"
              color={PillColor.GREEN}
              light
              textTransform={false}
              bold={false}>
              Reminder sent on {lastSendOn}
            </Pill>
          ) : undefined
        }>
        <p>
          Reminder emails will be sent to managers pending approval, except for
          those manually added. Are you sure you want to send reminders?
        </p>
      </Modal>
    </>
  )
}
