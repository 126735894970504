import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'

import PaginatedList, { PaginatedListItem } from 'components/PaginatedList'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import Card from 'components/Card'
import EmptyState from 'components/EmptyState'
import { ITableProps } from 'components/Table'

import noMinutesImg from 'images/minutes_empty_state.png'
import PlusIcon from 'images/icons/plus.svg'
import DownloadIcon from 'images/icons/download.svg'

import { TMeetingMinute } from './types'
import AdditionalNotes from './AdditionalNotes'
import MeetingMinutesList from './MeetingMinutesList'

interface MeetingMinutesTableProps extends ITableProps<TMeetingMinute> {
  addMinutesUrl: string
  additionalNotes: string | null
}

const emptyActionNodeIconStyle = css({
  marginRight: spacings.grid_gap_basis_num / 2,
})

const additionalNotesStyle = css({
  marginTop: spacings.grid_gap_basis_num * 2,
  paddingTop: spacings.grid_gap_basis_num * 2,
  borderTop: `1px solid ${colors.borders.gray}`,
})

/**
 * Shows card with registrants table
 * If there are no registrants, show empty state with invite users button to show modal
 */
export default function MeetingMinutesTable({
  tableData,
  tableMeta,
  selectedFilters,
  additionalNotes,
  addMinutesUrl,
}: MeetingMinutesTableProps): JSX.Element {
  const { url: exportUrl } = tableMeta
  const hasRows = tableData.rows && tableData.rows.length > 0
  return (
    <Card
      title="Meeting Minutes"
      actionNode={
        hasRows ? (
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            href={exportUrl.replace('.json', '.csv')}>
            <DownloadIcon />
            Export.csv
          </Button>
        ) : undefined
      }>
      {hasRows ? (
        <PaginatedList
          tableData={tableData}
          tableMeta={tableMeta}
          selectedFilters={selectedFilters}>
          <>
            <MeetingMinutesList />
            {additionalNotes && (
              <div css={additionalNotesStyle}>
                <PaginatedListItem>
                  <AdditionalNotes notes={additionalNotes} />
                </PaginatedListItem>
              </div>
            )}
          </>
        </PaginatedList>
      ) : (
        <EmptyState
          imgUrl={noMinutesImg}
          actionNode={
            <Button
              variant={Variant.SECONDARY}
              small
              as={ButtonComponentType.LINK}
              href={addMinutesUrl}
              startIcon={<PlusIcon css={emptyActionNodeIconStyle} />}>
              Add Minutes
            </Button>
          }>
          <p>Assign follow-up items to individual leaders</p>
        </EmptyState>
      )}
    </Card>
  )
}
