import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { css } from '@emotion/react'

import {
  FormFieldContainer,
  FormFieldsRow,
  FormHeader,
  FormSection,
} from 'components/Forms'
import DateTimeTZPickerRow from 'components/Inputs/DateTimeTZPickerRow'
import CommunityLeaders from 'components/CommunityLeaders'

import Button, { ButtonComponentType, Variant } from 'components/Button'
import MindrForm, { IMindrFormProps } from 'components/Forms/MindrForm'
import { Caption, Paragraph } from 'components/Typography'
import { MonthYearPicker } from 'components/Inputs'
import {
  IMentorshipCsvUploaderSettings,
  IMentorshipExchangePackage,
  ITeamMember,
  TCommunityType,
} from 'types'
import CsvFileInput from 'components/CsvFileInput'
import { formFieldName, monthYearFromName } from 'components/helper-functions'
import { colors, spacings } from 'stylesheets/theme'
import { IMentorshipActionUrls } from 'components/MentorshipExchangeDetails/MentorshipExchangeDetailsContext'
import EyeIcon from 'images/icons/eye.svg'
import Switch from 'components/Switch'
import Container from 'components/Container/Container'
import CommunityPicker, {
  TCommunityPickerValue,
} from 'components/CommunityPicker'
import MentorshipDetailPageRadioGroup from './MentorshipDetailPageRadioGroup'

const PADDING_LEFT = '60px'
const PADDING_RIGHT = '60px'
const DESIRED_WIDTH = '675px'
const WIDER_DESIRED_WIDTH = '941px'
const sectionCss = css({
  overflow: 'visible',
  width: `calc(${DESIRED_WIDTH} + ${PADDING_LEFT} + ${PADDING_RIGHT})`,
  '&::after': {
    content: '" "',
    display: 'block',
    height: 1,
    width: '100%',
  },
  '.input-component-wrapper': {
    '.input-component-wrapper': {
      marginBottom: 0,
    },
  },
  '.label-beside': {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    alignItems: 'center',

    '.input-wrapper': {
      flexGrow: 1,

      '.helper-text': {
        marginLeft: 9,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        position: 'absolute',
        left: '100%',
        top: 0,
        bottom: 0,
        width: '228px',
      },
    },
  },
  '.validation-error-message': {
    marginTop: spacings.grid_gap_basis_num,
    overflow: 'visible',
    whiteSpace: 'nowrap',
  },
})
const monthYearErrorCss = css({
  '.validation-error-message': {
    position: 'absolute',
  },
})
const widerSectionCss = css({
  width: `calc(${WIDER_DESIRED_WIDTH} + ${PADDING_LEFT} + ${PADDING_RIGHT})`,
  '.mindr-form & .input-component-wrapper': {
    maxWidth: 'none',
  },
})
const marginTopCss = css({
  '.mentorship-exchange-form .form-sections &': {
    marginTop: 36,
    maxWidth: 'none',
  },
})
const mmeLabelCss = css({
  width: 186,
  fontSize: 15,
  flexGrow: 0,
  flexShrink: 0,
  color: colors.text.text_5,
  '&::after': {
    marginBottom: 0,
  },
})

const extraSpaceAboveFileUploaderCss = css({
  'label.label::after': {
    marginBottom: 24,
  },
})

const programNameLabelCss = css({
  display: 'block',
  marginBottom: spacings.grid_gap_basis_num,
})

interface IMentorshipExchangeFormErrors {
  name: string[]
  open_date: string[]
  open_time: string[]
  deadline_date: string[]
  deadline_time: string[]
  group_id: string[]
}

interface IMentorshipExchangeFormProps extends IMindrFormProps {
  fieldName: string
  formVersion?: 'new_program' | 'edit_program'
  currentUserTimezone?: string
  program?: IMentorshipExchangePackage
  csvUploadSettings?: IMentorshipCsvUploaderSettings
  urls?: IMentorshipActionUrls
  leaders?: ITeamMember[]
  errors?: IMentorshipExchangeFormErrors
  enableSuperPower?: boolean
}

const MentorshipExchangeForm: React.FC<IMentorshipExchangeFormProps> = ({
  fieldName,
  formVersion,
  currentUserTimezone,
  action,
  method,
  id,
  program,
  csvUploadSettings,
  urls = {},
  leaders = [],
  errors = {} as IMentorshipExchangeFormErrors,
  enableSuperPower = false,
}): React.ReactElement => {
  const [managerApproval, setManagerApproval] = React.useState(
    program?.data.manager_approval_required,
  )
  const [groupType, setGroupType] = useState<TCommunityType>()

  useEffect(() => {
    setGroupType(program?.data?.group?.type)
  }, [])

  const onPreviewEmail = useCallback(
    (attribute_name, e) => {
      e.preventDefault()
      const previewEl = document.createElement('input')
      previewEl.setAttribute('type', 'hidden')
      previewEl.setAttribute('name', attribute_name)
      previewEl.setAttribute('value', 'true')
      const form = document.getElementsByClassName(
        'mentorship-exchange-form',
      )[0] as HTMLFormElement
      form.setAttribute('target', '_blank')
      form.appendChild(previewEl)
      form.submit()
      previewEl.remove()
      form.removeAttribute('target')
    },
    [window],
  )

  /*
  disabled =
  - program &&                                             // we must have a program
  - program.data.id &&                                     // the program has to be in the database first too
  - program.status !== 'scheduled' | 'about_to_open' &&    // we can edit the dates until applications_open
  - !enableSuperPower                                      // super powers have to be enabled by super admin with url param
  */
  const disableOpenDeadlineDateTimeInputs = useMemo(
    () =>
      program &&
      program?.data.id &&
      ['scheduled', 'about_to_open'].indexOf(program?.data.status) === -1 &&
      !enableSuperPower,
    [program, enableSuperPower],
  )

  const name = (keys: string[] = []) => formFieldName(fieldName, keys)

  const leadersList = leaders
    ? Array.from(leaders)
    : [{ fake_id: undefined, _destroy: false }]

  let month
  let year

  if (program) {
    const my = monthYearFromName(program.data.name, month, year)
    month = `${my.month}`
    year = `${my.year}`
  }

  const formLabel = program ? 'Update Program' : 'Create Program'
  const formButtonLabel =
    formVersion == 'edit_program' ? 'Update Program' : 'Create Program'

  const csvReadOnly = !!program?.data?.created_at && program?.data?.closed

  const csvStatusbutton =
    urls?.invitee_import_status_csv && csvUploadSettings?.initialValue ? (
      <Button
        as={ButtonComponentType.LINK}
        variant={Variant.SECONDARY}
        href={urls?.invitee_import_status_csv}>
        Check import statuses
      </Button>
    ) : null

  return (
    <MindrForm
      className="mentorship-exchange-form"
      id={id ?? 'mentorship-exchange-form'}
      action={action ?? '#'}
      method={method ?? 'POST'}
      encType="multipart/form-data"
      // onSubmit={handleSubmit}
      acceptCharset="UTF-8">
      <FormHeader title={formLabel}>
        <Button className="button primary" name="publish" type="submit">
          {formButtonLabel}
        </Button>
      </FormHeader>

      <div className="form-sections">
        <FormSection
          title="Program Details"
          id="program-details-form-section"
          css={[sectionCss, widerSectionCss, monthYearErrorCss]}>
          <label
            htmlFor={name(['name', 'month'])}
            className="label"
            css={programNameLabelCss}>
            Program name*
          </label>
          <FormFieldsRow
            verticalCenter
            css={{
              '.mentorship-exchange-form .form-sections &': {
                maxWidth: 'none',
              },
            }}>
            <label
              htmlFor={name(['name', 'month'])}
              className="label"
              css={mmeLabelCss}>
              Mindr Mentorship Exchange
            </label>
            <MonthYearPicker
              fieldName={name(['name'])}
              month={month}
              year={year}
              errors={errors?.name}
            />
            <Caption className="helper-text">
              Enter the month and year when the cohort’s live sessions begin
            </Caption>
          </FormFieldsRow>
        </FormSection>
        <FormSection
          title="Application Dates"
          description="Set the open date and deadline date for the application. The application invitation will be sent out on the open date."
          id="application-dates-form-section"
          css={sectionCss}>
          <div
            css={{
              display: 'flex',
              gap: spacings.grid_gap_basis_num * 2,
            }}>
            <DateTimeTZPickerRow
              fieldName={name(['open'])}
              labelDate="Application Open date*"
              labelTime="Time*"
              currentDate={program?.data.open_date}
              currentTime={program?.data.open_time}
              currentTimezone={program?.data.open_timezone}
              css={marginTopCss}
              currentUserTimezone={currentUserTimezone}
              timeErrors={errors?.open_time}
              dateErrors={errors?.open_date}
              disabled={disableOpenDeadlineDateTimeInputs}
            />
            <Button
              css={{
                marginTop: spacings.grid_gap_basis_num * 3,
                minWidth: spacings.grid_gap_basis_num * 10,
              }}
              className="submit-form-button link"
              type="Button"
              onClick={(e) => onPreviewEmail('preview', e)}
              startIcon={<EyeIcon />}
              target="_blank">
              Preview email
            </Button>
            {enableSuperPower ? (
              <input
                type="hidden"
                name={name(['enable_super_powers'])}
                value="1"
              />
            ) : null}
          </div>
          <DateTimeTZPickerRow
            fieldName={name(['deadline'])}
            labelDate="Application Deadline date*"
            labelTime="Time*"
            currentDate={program?.data.deadline_date}
            currentTime={program?.data.deadline_time}
            currentTimezone={program?.data.deadline_timezone}
            currentUserTimezone={currentUserTimezone}
            timeErrors={errors?.deadline_time}
            dateErrors={errors?.deadline_date}
            disabled={disableOpenDeadlineDateTimeInputs}
          />
        </FormSection>
        <FormSection
          title="Audience"
          id="audience-form-section"
          css={[sectionCss, widerSectionCss, extraSpaceAboveFileUploaderCss]}>
          <FormFieldContainer
            label={
              <>
                <b>
                  The invitation to apply will go out organization-wide, unless
                  you upload a CSV of desired recipients using the sample format
                  provided.
                </b>{' '}
                Download{' '}
                {
                  <Button
                    href={urls.audience_sample_csv}
                    target="_blank"
                    as={ButtonComponentType.LINK}
                    variant={Variant.LINK}
                    css={{ display: 'inline-block' }}>
                    this example
                  </Button>
                }{' '}
                to see the required format. Those who have previously received
                the invitation will not receive it again.
              </>
            }
            fieldId="audience">
            <CsvFileInput
              name={csvUploadSettings.name}
              type="csv-mentorship-import"
              label={csvUploadSettings.label}
              error={csvUploadSettings.error}
              cachedData={csvUploadSettings.cachedData}
              initialValue={csvUploadSettings.initialValue ?? null}
              readOnly={csvReadOnly}
              additionalComponentsWhenUnmodified={csvStatusbutton}
              hideFileFieldName
            />
          </FormFieldContainer>
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            href={urls?.invitee_import_log_csv}>
            View import log
          </Button>
        </FormSection>
        <FormSection
          title="Manager approval"
          description="After their direct report submits the application, managers will
                receive an email notifying them of the approval requirement for
                program participation. After toggling on manager approval, you
                have the option to turn on the budget requirement for
                participation."
          css={[sectionCss, widerSectionCss]}>
          <FormFieldContainer
            label={
              <Container>
                Is manager approval required for applicants to participate in
                the program?{' '}
                <Button
                  className="submit-form-button link"
                  type="Button"
                  onClick={(e) => onPreviewEmail('preview_manager_approval', e)}
                  startIcon={<EyeIcon />}
                  target="_blank">
                  Preview email
                </Button>
              </Container>
            }
            fieldId="manager_approval_required"
            css={{
              marginTop: spacings.grid_gap_basis_num * 3,
            }}>
            <Switch
              readOnly={
                program?.data.status != 'scheduled' &&
                program?.data.status != 'about_to_open' &&
                !!program
              }
              name={name(['manager_approval_required'])}
              controlName={name(['manager_approval_required'])}
              value="true"
              checked={managerApproval}
              defaultChecked={program?.data.manager_approval_required}
              onChange={(e) => setManagerApproval(e.target.checked)}
            />
          </FormFieldContainer>
          {managerApproval && (
            <FormFieldContainer
              label={
                <Container>
                  Does the manager need to approve the budget for participation?{' '}
                  <Button
                    className="submit-form-button link"
                    type="Button"
                    onClick={(e) =>
                      onPreviewEmail('preview_manager_approval_budget', e)
                    }
                    startIcon={<EyeIcon />}
                    target="_blank">
                    Preview email
                  </Button>
                </Container>
              }
              fieldId="budget_approval_required"
              css={{
                marginTop: spacings.grid_gap_basis_num * 3,
              }}>
              <Switch
                readOnly={
                  program?.data.status != 'scheduled' &&
                  program?.data.status != 'about_to_open' &&
                  !!program
                }
                name={name(['budget_approval_required'])}
                controlName={name(['budget_approval_required'])}
                value="true"
                defaultChecked={program?.data.budget_approval_required}
              />
            </FormFieldContainer>
          )}
        </FormSection>
        <FormSection
          title="Mentorship Detail Page"
          description="This page is shared with participants when they are notified of their match. Users will have access to their partner’s information, the reasoning behind the match, cohort details, and recommended resources."
          css={[sectionCss, widerSectionCss]}>
          <FormFieldContainer
            label={
              <Paragraph bold>
                Select details to display on the participant’s and their
                partner’s information cards
              </Paragraph>
            }
            fieldId="mentorship_detail_page_display_info"
            css={{
              marginTop: spacings.grid_gap_basis_num * 3,
            }}>
            <MentorshipDetailPageRadioGroup
              groupName={name(['display_detail_options'])}
              defaultChecked={program?.data?.display_detail_options}
            />
          </FormFieldContainer>
        </FormSection>
        <FormSection
          title="Leadership Team"
          id="leadership-team-form-section"
          css={[sectionCss, widerSectionCss]}>
          <FormFieldContainer label="" fieldId="audience">
            <CommunityLeaders
              rootEntity={name(['leaders'])}
              initialValues={leadersList}
            />
          </FormFieldContainer>
        </FormSection>

        {formVersion === 'new_program' ? null : (
          <>
            <FormSection
              title="Cohort"
              id="cohort-form-section"
              css={[sectionCss, widerSectionCss]}>
              <FormFieldContainer label="" fieldId="audience">
                <CommunityPicker
                  value={program?.data?.group}
                  name={name(['group_id_search'])}
                  inputName={name(['group_id'])}
                  communitiesUrl={urls.communities_search}
                  label="Mindr Mentorship Exchange Cohort"
                  error={errors?.group_id?.join(' ')}
                  wrapperClassName=""
                  communityId={program?.data?.group?.id ?? 0}
                  onChange={(
                    inputData: Record<string, TCommunityPickerValue>,
                  ) => {
                    const type = inputData[name(['group_id_search'])]
                    setGroupType(type?.type)
                  }}
                />
              </FormFieldContainer>
            </FormSection>
            <input
              type="hidden"
              name={name(['group_type'])}
              defaultValue={groupType}
            />
          </>
        )}
      </div>
    </MindrForm>
  )
}

export default MentorshipExchangeForm
