import { useMemo, useState } from 'react'
import { TPanelNumber } from './types'
import Modal, { Size } from 'components/Modal'
import Container from 'components/Container'
import Pill, { PillColor } from 'components/Pill'
import { Caption, Paragraph } from 'components/Typography'
import { css } from '@emotion/react'
import { colors } from 'stylesheets/theme'
import step9ImgWide from 'images/slack_installation/step_9_wide.png'
import step9aImgWide from 'images/slack_installation/step_9a_wide.png'
import step9bImgWide from 'images/slack_installation/step_9b_wide.png'
import TwoStatePanel from './TwoStatePanel'
import DecisionPanel from './DecisionPanel'
import QuestionIcon from 'images/slack_installation/question.png'
import InfoIcon from 'images/icons/info_filled.png'
import {
  SInfoIcon,
  SInfoCentered,
  SInfoWrapper,
  SNumberedList,
} from './NewStep9.style'
import NumberedCircleList from 'components/NumberedCircleList'

interface INewStep9Props {
  nextStep: () => void
}

const stepOneImageStyle = css({
  border: `1px solid ${colors.borders.gray}`,
  borderRadius: 6,
  width: '100%',
})

const stepABlock = css({
  marginBottom: 47.5,
})

export default function NewStep9({ nextStep }: INewStep9Props): JSX.Element {
  const [currentPanel, setCurrentPanel] = useState<TPanelNumber>('one')
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const panel1Content = useMemo(() => {
    return (
      <DecisionPanel
        heroImage={step9ImgWide}
        iconUrl={QuestionIcon}
        title="Does your workspace require apps to be approved by your admins?"
        onYes={() => {
          setIsModalOpen(true)
        }}
        onNo={() => {
          nextStep()
        }}>
        <Paragraph>
          If your workspace restricts adding new apps, please choose &apos;Yes,
          I need approval&apos; and follow the steps provided on the page. If
          you don&apos;t need approval, you can choose &apos;No, I don&apos;t
          need approval&apos; and proceed to the next step.
        </Paragraph>
      </DecisionPanel>
    )
  }, [])

  const iconStyle = useMemo(
    () => css({ backgroundImage: `url(${InfoIcon});` }),
    [InfoIcon],
  )
  const panel2Content = useMemo(() => {
    const style = css({
      textAlign: 'left',
      color: colors.text.text_5,
      lineHeight: '22px',
      fontSize: 15,

      strong: {
        color: colors.text.text_3,
      },
    })
    const items = [
      {
        content: (
          <>
            <Paragraph css={style}>
              Return to this integration guide and begin from
              <br />
              <strong>
                Step 1: &apos;Have you already created a &apos;Mindr
                Connect&apos; app?&apos;
              </strong>
            </Paragraph>
          </>
        ),
      },
      {
        content: (
          <Paragraph css={style}>
            Select &apos;Yes&apos; option as you have already created the Mindr
            Connect app
          </Paragraph>
        ),
      },
      {
        content: (
          <Paragraph css={style}>
            Follow the remaining steps and complete the webhook setup
          </Paragraph>
        ),
      },
    ]
    return (
      <div css={SInfoWrapper}>
        <div css={[SInfoIcon, iconStyle]} data-img={InfoIcon}></div>
        <h3 css={SInfoCentered}>Post-approval instructions</h3>
        <Paragraph css={SInfoCentered}>
          Please wait patiently as your admins process your request. The
          approval may take up to a few minutes or a few days. You will be
          notified of the approval once your request has been approved.
        </Paragraph>
        <Paragraph css={SInfoCentered}>
          Once you receive admin approval, follow these next steps:
        </Paragraph>
        <NumberedCircleList
          css={SNumberedList}
          items={items}></NumberedCircleList>
      </div>
    )
  }, [])

  const modal = useMemo(() => {
    const titleA =
      'Scroll down and click on &quot;Request to add new webbook&quot; button'
    const titleB =
      'Add comments (optional) and click on &quot;Submit Request&quot;'
    return (
      <Modal
        css={{ '.mindr-modal-actions .button': { marginRight: 0 } }}
        isOpen={isModalOpen}
        largeTitle
        title="Request admin approval for this app"
        size={Size.large}
        submitButton="I've submitted the request"
        cancelButton="Cancel"
        onRequestClose={() => {
          setIsModalOpen(false)
        }}
        onSave={() => {
          setCurrentPanel('two')
          setIsModalOpen(false)
        }}>
        <div css={stepABlock}>
          <Container>
            <Pill color={PillColor.GREEN} light size="large">
              Step A
            </Pill>
            <Caption>{titleA}</Caption>
          </Container>
          <img src={step9aImgWide} alt={titleA} css={stepOneImageStyle}></img>
        </div>
        <div>
          <Container>
            <Pill color={PillColor.GREEN} light size="large">
              Step B
            </Pill>
            <Caption>{titleA}</Caption>
          </Container>
          <img src={step9bImgWide} alt={titleB} css={stepOneImageStyle}></img>
        </div>
      </Modal>
    )
  }, [isModalOpen])

  return (
    <div>
      {modal}
      <TwoStatePanel
        panel1Content={panel1Content}
        panel2Content={panel2Content}
        currentPanel={currentPanel}></TwoStatePanel>
    </div>
  )
}
