import { css } from '@emotion/react'
import Container from 'components/Container'
import { SlickArrow } from 'components/HomePage/SlickArrow'
import { Caption } from 'components/Typography'
import useMediaQuery from 'hooks/useMediaQuery'
import Slider from 'react-slick'
import { breakpoints } from 'stylesheets/breakpoints'
import { spacings } from 'stylesheets/theme'
import { slickStyle } from './slickStyle'

interface IImageCarouselProps {
  images?: IImage[]
}

interface IImage {
  image: string
  image_alt?: string
}

const defaultImageContainer = css({
  alignItems: 'flex-start',
  width: 376,
  flexGrow: 0,
  flexShrink: 0,
})

const mobileImageContainer = css({
  width: '100%',
})

const imageStyle = css({
  width: 376,
  height: 376,
  borderRadius: spacings.radius_num / 2,
  objectFit: 'cover',
})

export default function ImageCarousel({
  images,
}: IImageCarouselProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  return (
    <div
      css={[
        slickStyle,
        css({
          '.slick-list': {
            width: isMobile ? `calc(100vw - 48px)` : 376,
          },
        }),
      ]}>
      <Slider
        {...{
          speed: 500,
          draggable: true,
          dots: true,
          slidesToScroll: 1,
          slidesToShow: 1,
          autoplay: false,
          arrows: !isMobile,
          nextArrow: <SlickArrow isNext />,
          prevArrow: <SlickArrow isNext={false} />,
        }}
        className="slider">
        {images.map((image) => {
          return (
            <Container
              key={image.image}
              direction="column"
              css={[defaultImageContainer, isMobile && mobileImageContainer]}>
              <img css={imageStyle} src={image.image} alt={image.image_alt} />
              <Caption>{image.image_alt}</Caption>
            </Container>
          )
        })}
      </Slider>
    </div>
  )
}
