import { css } from '@emotion/react'

import Widget, { WidgetProps } from './Widget'

import Icon from 'images/icons/people.svg'

import { colors } from 'stylesheets/theme'

interface EligiibleApplicantsWidgetProps
  extends Omit<WidgetProps, 'number' | 'icon'> {
  numPeople: number
}

const iconStyle = css({
  path: {
    fill: colors.green_dark,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.green_bg_light,
  },
})

export default function EligiibleApplicantsWidget({
  numPeople,
  ...props
}: EligiibleApplicantsWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<Icon height="18px" width="18px" css={iconStyle} />}
      number={numPeople}
      css={widgetStyle}
      {...props}
    />
  )
}
