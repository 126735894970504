import { ReactElement, ReactNode } from 'react'
import { css } from '@emotion/react'

import { SmallText } from 'components/Typography'

export interface WidgetProps {
  icon: ReactNode
  number: number
  title: string
  // Instead of showing "0" when the number is 0, show "-
  noZero?: boolean
  className?: string
}

const statisticWidgetStyle = css({
  background: '#ffffff',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
  borderRadius: '4px',
  padding: '20px 16px',
  display: 'flex',
  alignItems: 'center',
  height: '107px',
})

const numberStyle = css({
  marginBottom: 'calc(12px / 2)',
})

const iconStyle = css({
  minHeight: '56.49px',
  minWidth: '56.49px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '12px',
})

export default function Widget({
  icon,
  number,
  title,
  noZero,
  className,
}: WidgetProps): ReactElement {
  return (
    <div css={statisticWidgetStyle} className={`widget ${className}`}>
      <div className="icon-container" css={iconStyle}>
        {icon}
      </div>
      <div>
        <h2 css={numberStyle}>{noZero && !number ? '-' : number}</h2>
        <SmallText>{title}</SmallText>
      </div>
    </div>
  )
}
