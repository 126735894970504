import React from 'react'
import cx from 'classnames'

import './EventCallButton.scss'

import { TActionLocation, TEventCallType } from 'types'

interface IEventCallButton {
  type: TEventCallType
  location: TActionLocation
  onClick: () => void
  className?: string
}

const EventCallButton = ({
  type,
  location,
  onClick,
  className = '',
}: IEventCallButton): React.ReactElement => {
  return (
    <button
      className={cx('event-call-button button', `${type}-button`, {
        primary: location !== 'card',
        'not-styled-button': location === 'card',
        small: location === 'table-cell',
        'on-card': location === 'card',
        [className]: !!className,
      })}
      aria-label={type === 'call' ? 'Mark as Complete' : 'Register'}
      onClick={(event) => {
        event.preventDefault()
        onClick()
      }}
      data-qa={`featured ${
        type === 'call' ? 'call to action' : 'event'
      } register button`}>
      {location !== 'card' && (
        <>{type === 'call' ? 'Mark as Complete' : 'Register'}</>
      )}
    </button>
  )
}

export default EventCallButton
