import { spacings, colors } from 'stylesheets/theme'
import ArrowRightIcon from 'images/icons/arrow_right.svg'
import Container from 'components/Container'
import Pill, { PillColor } from 'components/Pill'
import { css } from '@emotion/react'
import { Paragraph } from 'components/Typography'
import Button, {
  Variant,
  ButtonComponentProps,
  ButtonComponentType,
} from 'components/Button'

interface StepProps extends ButtonComponentProps<ButtonComponentType.BUTTON> {
  stepNumber: number
  title: string
  selected?: boolean
}

const defaultStepStyle = css({
  display: 'flex',
  width: '364px',
  height: '74px',
  padding: `${spacings.grid_gap_basis} 16px`,
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: spacings.radius_num * 2,
  backgroundColor: colors.backgrounds.page,
  ':hover': {
    backgroundColor: colors.borders.gray,
  },
  '& :hover': {
    opacity: 1,
  },
})

const selectedStepStyle = css({
  backgroundColor: colors.teal_light,
})

export default function Step({
  stepNumber,
  title,
  selected = false,
  ...props
}: StepProps): JSX.Element {
  const styles = [defaultStepStyle]

  if (selected) {
    styles.push(selectedStepStyle)
  }

  return (
    <Button {...props} variant={Variant.UNSTYLED} css={styles}>
      <Container>
        <Pill size="large" color={PillColor.GREEN} dark={selected}>
          Step {stepNumber}
        </Pill>
        <Paragraph
          css={{
            width: spacings.grid_gap_basis_num * 19,
            fontSize: 13,
          }}>
          {title}
        </Paragraph>
      </Container>
      <ArrowRightIcon
        css={{
          width: spacings.grid_gap_basis_num * 2,
          height: '14px',
        }}
      />
    </Button>
  )
}
