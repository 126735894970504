import { css } from '@emotion/react'

interface IAdditionalNotesProps {
  notes: string
}

const notesHeader = css({
  fontSize: '15px',
  fontWeight: 'normal',
  lineHeight: 25.0 / 15.0,
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
})

const AdditionalNotes = ({ notes }: IAdditionalNotesProps): JSX.Element => (
  <>
    <h5 css={notesHeader}>Additional Notes</h5>
    <div
      className="mindr-rich-content"
      dangerouslySetInnerHTML={{ __html: notes }}
    />
  </>
)

export default AdditionalNotes
