import { css } from '@emotion/react'
import { colors, sizes, spacings } from 'stylesheets/theme'

export const modalStyle = css({
  '.mindr-modal-title': {
    color: colors.teal_dark,
  },
  '.mindr-modal-body': {
    marginTop: spacings.grid_gap_basis_num * 2, // 24px
  },
  '&.mindr-modal.large': {
    width: 696,
  },
  '.input-component-wrapper': {
    marginTop: spacings.grid_gap_basis_num * 2, // 24px
    'label.label': {
      fontWeight: 700,
      color: colors.text.text_5,
    },
    '.caption': {
      marginBottom: spacings.grid_gap_basis_num, // 12px
      color: colors.text.text_5,
    },
    textarea: {
      height: 106,
      minHeight: 106,
    },
  },
  '.mindr-modal-close svg': {
    width: sizes.modalCloseButton,
    height: sizes.modalCloseButton,
  },
  '.mindr-modal-actions': {
    marginTop: spacings.grid_gap_basis_num * 2, // 24px
    '.button': {
      marginRight: 0,
    },
  },
})
