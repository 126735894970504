import React, { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import $ from 'jquery'
import CohortPill from 'components/CohortPill'

import type { ICommunity, TCommunityType } from 'types'

export type {
  TCommunitySubscriberAllyAffiliate,
  TCommunitySubscriberNotificationFrequency,
} from 'types'
export { toAllyAffiliate } from 'types'
export type { ICommunity, TCommunityType }

type CommunityProps = {
  community: ICommunity
  hideSubscribeButton?: boolean
}

const Community: React.FC<CommunityProps> = ({
  community,
  hideSubscribeButton,
}) => {
  const wrapperRef = useRef()
  const [tabIndex, setTabindex] = useState(0)

  const communityType: TCommunityType = community.type || 'community'

  useEffect(() => {
    setTabindex(
      $(wrapperRef.current)?.closest('.slick-slide').hasClass('slick-cloned')
        ? -1
        : 0,
    )
  }, [(wrapperRef.current as HTMLDivElement)?.tabIndex])

  return (
    <div className="community-card-div" ref={wrapperRef}>
      <a
        aria-label="community card"
        tabIndex={tabIndex}
        href={community.link}
        className={classNames('community-card', {
          subscribed: community.subscribed,
          [`${communityType.toLowerCase()}-type-card`]: true,
        })}>
        <div
          className="community-image"
          style={{
            backgroundImage: `url(${community.thumbnail})`,
          }}
        />
        <img
          src={community.logo}
          alt={community.name}
          className="community-logo"
        />
        <div className="community-card-info">
          <h3 className="info-text">
            {community.name}
            <span className="spacing"></span>
          </h3>
        </div>
        {communityType === 'cohort' ? (
          <CohortPill className="subscribed-indicator" size="large" />
        ) : (
          <>
            {!hideSubscribeButton && (
              <div className="subscribed-indicator button-label">
                Subscribed
              </div>
            )}
          </>
        )}
      </a>
      {communityType !== 'cohort' && !hideSubscribeButton && (
        <button
          aria-label="community card"
          data-community-info={JSON.stringify(community)}
          tabIndex={tabIndex}
          className={classNames(
            'subscribe-community-btn not-styled-button on-card',
            {
              subscribed: community.subscribed,
            },
          )}>
          {!hideSubscribeButton && (
            <div
              id="subscribe_community_button"
              className="not-subscribed-content"
            />
          )}
        </button>
      )}
    </div>
  )
}

export default Community
