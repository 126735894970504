import React from 'react'
import SwitcherWithConfirmation, {
  ISwitcherWithConfirmation,
} from 'components/SwitcherWithConfirmation'
import { FormFieldContainer, FormFieldLabelVisibility } from 'components/Forms'
import { NumberField } from 'components/Inputs'
import { css } from '@emotion/react'

const disabledCss = css({
  opacity: 0.5,
})

interface RegistrationLimitProps {
  hasLimit: boolean
  limitSize?: number
  object: string
  label: string
  errors?: ISwitcherWithConfirmation['errors']
  limitSizeLabel: string
  readOnly?: boolean
}

/**
 * Control to set registration capacity limit
 */
export default function RegistrationLimit({
  hasLimit,
  limitSize,
  object,
  label,
  errors = [],
  limitSizeLabel,
  readOnly = false,
}: RegistrationLimitProps): JSX.Element {
  const classes = []
  if (readOnly) {
    classes.push(disabledCss)
  }
  return (
    <>
      <SwitcherWithConfirmation
        readOnly={readOnly}
        name={`${object}[registration_limit]`}
        label={label}
        defaultValue={hasLimit}
        toggleTarget={'.registration-limit-fields'}
      />
      <div className="preference-details registration-limit-fields">
        <FormFieldContainer
          css={classes}
          fieldId="event-call-registration-limit"
          label={limitSizeLabel}
          error={!!errors.length}
          description="Enter a registration capacity number"
          labelVisibility={FormFieldLabelVisibility.HIDE}>
          <NumberField
            readOnly={readOnly}
            id="event-call-registration-limit"
            name={`${object}[registration_limit_size]`}
            defaultValue={limitSize}
            min={0}
            errors={errors}
          />
        </FormFieldContainer>
      </div>
    </>
  )
}
