import { css } from '@emotion/react'
import { calcGridWidth, spacings } from 'stylesheets/theme'

export const PAGE_PADDING = spacings.grid_gap_basis_num * 5
export const PAGE_WIDTH_AND_PADDING = calcGridWidth(12) + PAGE_PADDING * 2
export const PAGE_WIDTH = calcGridWidth(12)
export const DATE_BADGE_WIDTH = 67
export const DATE_BLOCK_AND_MARGIN = calcGridWidth(1, 1)

export const LIST_ICON_STYLE = css({
  marginRight: 0.5 * spacings.grid_gap_basis_num,
  position: 'relative',
  top: 1,
})

export const DEFAULT_NAVIGATION_STYLE = css({
  width: '100%',
  maxWidth: '100vw',
  marginLeft: 'auto',
  marginRight: 'auto',
  paddingLeft: calcGridWidth(1, 0) + 6,
  paddingRight: 0,
})

export const DESKTOP_NAVIGATION_STYLE = css({
  width: PAGE_WIDTH,
})
