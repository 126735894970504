import Button, { ButtonComponentType, Variant } from 'components/Button'
import FeedbackIcon from 'images/icons/feedback.svg'
import Container from 'components/Container'
import { Paragraph } from 'components/Typography'
import { colors, spacings, zIndex } from 'stylesheets/theme'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import { css } from '@emotion/react'

const desktopFooterStyle = css({
  width: '100%',
})

const defaultFooterStyle = css({
  alignItems: 'flex-start',
  backgroundColor: colors.backgrounds.gray,
  padding: spacings.grid_gap_basis_num * 2,
  zIndex: zIndex.footer,
  position: 'fixed',
  bottom: 0,
})

const mobileFooterStyle = css({
  position: 'relative',
  marginTop: 4 * spacings.grid_gap_basis_num,
  // marginLeft: '-1rem',
  // marginRight: '-1rem',
})

interface IFooterProps {
  isResponsivePage?: boolean
}

export default function Footer({
  isResponsivePage = false,
}: IFooterProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  return (
    <Container
      css={[
        defaultFooterStyle,
        (!isMobile || !isResponsivePage) && desktopFooterStyle,
        isMobile && isResponsivePage && mobileFooterStyle,
      ]}>
      <Container
        css={{
          flexWrap: 'wrap',
          flex: '50%',
          justifyContent: 'center',
        }}>
        <Button
          as={ButtonComponentType.LINK}
          variant={Variant.LINK}
          css={
            isMobile &&
            isResponsivePage &&
            css({
              flex: '100%',
            })
          }
          href="mailto:support@mindrglobal.com?subject=Support Request: Mindr Connect">
          Support
        </Button>
        <Button
          as={ButtonComponentType.LINK}
          variant={Variant.LINK}
          css={
            isMobile &&
            isResponsivePage &&
            css({
              flex: '100%',
            })
          }
          href="https://www.mindrglobal.com/legal">
          Terms of use
        </Button>
        <Button
          as={ButtonComponentType.LINK}
          variant={Variant.LINK}
          css={
            isMobile &&
            isResponsivePage &&
            css({
              flex: '100%',
            })
          }
          href="https://www.linkedin.com/company/mindr-global">
          Social
        </Button>
      </Container>
      <Container
        css={{
          flexWrap: 'wrap',
          flex: '50%',
        }}>
        <Paragraph
          css={{
            fontSize: 12,
            lineHeight: 2,
            color: colors.text.text_3,
          }}>
          <FeedbackIcon
            css={{
              marginRight: spacings.grid_gap_basis_num / 2,
            }}
          />
          Got Feedback?
        </Paragraph>
        <Container
          css={{
            gap: spacings.grid_gap_basis_num / 2,
            alignItems: 'baseline',
          }}>
          <Paragraph>Email</Paragraph>
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            href="mailto:feedback@mindrglobal.comF">
            feedback@mindrglobal.com
          </Button>
        </Container>
      </Container>
    </Container>
  )
}
