import Modal, { IModalProps, Size } from 'components/Modal'
import { Caption } from 'components/Typography'
import { useCallback, useState } from 'react'
import {
  SInquiryModal,
  SMobileInquiryModal,
  SInquiryTextarea,
  SMobileCloseButton,
  SMobileButtonRow,
  SMobileTitle,
  SCaption,
  SMobileModalContent,
} from './LeadrInquiryModal.styles'
import { ILeadrInquiryModalStrings } from './LeadrInquiryModal.types'
import useMediaQuery from 'hooks/useMediaQuery'
import { breakpoints } from 'stylesheets/breakpoints'
import Input from 'components/Input'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import CloseIcon from 'images/icons/close.svg'
import Container from 'components/Container'

interface ILeadrInquiryModalProps
  extends Omit<
    IModalProps,
    'children' | 'onRequestClose' | 'onSave' | 'size' | 'title' | 'largeTitle'
  > {
  onModalSave: (modalText: string) => void
  onModalClose: () => void
  defaultText?: string
  strings: ILeadrInquiryModalStrings
}

export default function LeadrInquiryModal({
  onModalClose,
  onModalSave,
  defaultText = '',
  strings,
  ...props
}: ILeadrInquiryModalProps): JSX.Element {
  const textareaId = 'inquiry-text'
  const [modalText, setModalText] = useState<string>(defaultText)
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)

  const modalSave = useCallback(() => {
    onModalSave(modalText)
  }, [modalText])

  return (
    <Modal
      {...props}
      css={[SInquiryModal, isMobile && SMobileInquiryModal]}
      size={Size.large}
      title={!isMobile && strings.modals.inquiry.title}
      largeTitle
      submitButton={!isMobile && strings.modals.inquiry.submit_button}
      cancelButton={!isMobile && strings.modals.inquiry.cancel_button}
      onRequestClose={onModalClose}
      onSave={modalSave}>
      <Container direction="column" css={SMobileModalContent}>
        {isMobile && (
          <>
            <Container justify="space-between" css={SMobileButtonRow}>
              <Button
                className="mindr-modal-close"
                css={SMobileCloseButton}
                as={ButtonComponentType.BUTTON}
                variant={Variant.UNSTYLED}
                onClick={() => {
                  onModalClose()
                }}>
                <CloseIcon />
              </Button>
              <Button
                as={ButtonComponentType.LINK}
                variant={Variant.LINK}
                onClick={() => {
                  modalSave()
                }}>
                {strings.modals.inquiry.submit_button}
              </Button>
            </Container>
            <h3 className="mindr-modal-title large" css={SMobileTitle}>
              {strings.modals.inquiry.title}
            </h3>
          </>
        )}
        <Caption css={SCaption}>{strings.modals.inquiry.description}</Caption>
        <Input
          id={textareaId}
          type="textarea"
          name={textareaId}
          value={modalText}
          css={SInquiryTextarea}
          hideName
          onChange={(value: { [textareaId]: string }) => {
            setModalText(value[textareaId])
          }}
        />
      </Container>
    </Modal>
  )
}
