import { css } from '@emotion/react'
import { Paragraph } from 'components/Typography'
import { colors, spacings } from 'stylesheets/theme'

interface IDateBadgeProps {
  month: string
  date: string
  day: string
  className?: string
}

export default function DateBadge({
  month,
  date,
  day,
  className = '',
}: IDateBadgeProps): JSX.Element {
  const style = css({
    backgroundColor: colors.blue,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 67,
    minWidth: 67,
    maxWidth: 67,
    height: 84,
    borderRadius: spacings.radius_num / 2,
    '*': {
      color: colors.backgrounds.white,
    },
    p: {
      fontSize: '11px',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  })

  return (
    <div css={style} className={className}>
      <Paragraph>{month}</Paragraph>
      <h1>{date}</h1>
      <Paragraph>{day}</Paragraph>
    </div>
  )
}
