import useMediaQuery, { useComplexMediaQuery } from 'hooks/useMediaQuery'
import EventCallHeader from './EventCallHeader'
import { breakpoints } from 'stylesheets/breakpoints'
import { css } from '@emotion/react'
import {
  boxShadow,
  calcGridWidth,
  colors,
  sizes,
  spacings,
  zIndex,
} from 'stylesheets/theme'
import Container from 'components/Container/Container'
import { Caption } from 'components/Typography'
import EventCallContent from './EventCallContent'
import SpeakersSlider from './SpeakersSlider'
import RecommendedResources from './RecommendedResources'
import Agenda from './Agenda'
import DetailsFooter from 'components/DetailsFooter'
import Footer from 'components/Footer'
import EventAction from 'components/EventAction'
import NavigationTabs from 'components/NavigationTabs/NavigationTabs'
import {
  AgendaItem,
  ICommunity,
  IEventCall,
  ISpaeker,
  Resource,
  ResourceType,
} from 'types'
import {
  DEFAULT_NAVIGATION_STYLE,
  DESKTOP_NAVIGATION_STYLE,
  PAGE_PADDING,
  PAGE_WIDTH,
  PAGE_WIDTH_AND_PADDING,
} from './constants'
import { useShowGridOverlay } from 'hooks/useShowGridOverlay'

const imageStyle = css({
  width: '100%',
  height: 'auto',
  borderRadius: spacings.radius_num / 2,
  objectFit: 'cover',
})

const defaultContentWrapper = css({
  padding: `${spacings.grid_gap_basis_num * 2}px 0`,
  paddingLeft: calcGridWidth(1, 1) + PAGE_PADDING,
  paddingRight: PAGE_PADDING,
  paddingBottom: spacings.grid_gap_basis_num * 4,
  width: '100%',
  maxWidth: '100vw',
  marginLeft: 'auto',
  marginRight: 'auto',
})

const mobileContentWrapper = css({
  padding: `${spacings.grid_gap_basis_num * 2}px`,
  paddingBottom: spacings.grid_gap_basis_num * 4,
})

const desktopContentWrapper = css({
  width: PAGE_WIDTH_AND_PADDING,
})

const defaultDetailContainer = css({
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  width: '100%',
  gap: spacings.grid_gap_basis_num * 2,
})

const mobileDetailContainer = css({
  flexWrap: 'wrap-reverse',
  justifyContent: 'center',
  width: '100%',
})

const defaultImageContainer = css({
  alignItems: 'flex-start',
  width: calcGridWidth(4),
  flexGrow: 0,
  flexShrink: 0,
})

const mobileImageContainer = css({
  width: '100%',
})

const defaultTimelineResourcesContainer = css({
  padding: `${spacings.grid_gap_basis_num * 2}px ${PAGE_PADDING}px ${
    spacings.grid_gap_basis_num * 2
  }px ${calcGridWidth(1, 1) + PAGE_PADDING}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  gap: spacings.grid_gap_basis_num * 2,
  justifyContent: 'flex-start',
  width: '100%',
  maxWidth: '100%',
  span: {
    whiteSpace: 'unset',
  },
})

const desktopTimelineResourcesContainer = css({
  width: PAGE_WIDTH,
  maxWidth: `calc(100% - ${PAGE_PADDING * 2}px)`,
  paddingLeft: calcGridWidth(1, 1),
  paddingRight: 0,
})

const mobileTimelineResourcesContainer = css({
  padding: spacings.grid_gap_basis_num * 2,
  maxWidth: '100vw',
})

const defaultPrevNextContainer = css({
  padding: `${spacings.grid_gap_basis_num * 2}px ${PAGE_PADDING}px ${
    spacings.grid_gap_basis_num * 2
  }px ${calcGridWidth(1, 1) + PAGE_PADDING}px`,
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: sizes.footerHeight,
  width: '100%',
  maxWidth: '100%',
})

const desktopPrevNextContainer = css({
  width: PAGE_WIDTH,
  maxWidth: `calc(100% - ${PAGE_PADDING * 2}px)`,
  paddingLeft: calcGridWidth(1, 1),
  paddingRight: 0,
})

const mobilePrevNextContainer = css({
  padding: spacings.grid_gap_basis_num * 2,
  marginBottom: 0,
  maxWidth: '100vw',
})

interface Event {
  title: string
  subtitle: string
  description: string
  location_url?: string
  allies_welcome?: boolean
  date: string
  image: string
  image_alt?: string
  deadline?: string
  deadline_reached?: boolean
  capacity_reached?: boolean
  closed?: boolean
}

interface EventPageProps {
  event: Event
  datetime: string
  host: ICommunity
  cohosts?: ICommunity[]
  isCohort?: boolean
  locationInPerson?: boolean
  location?: string
  locationVirtual?: boolean
  speakers?: ISpaeker[]
  resources?: Resource<ResourceType>[]
  timelines?: AgendaItem[]
  prev?: IEventCall
  next?: IEventCall
  viewAllUrl?: string
  eventData?: IEventCall
  registerEvent?: boolean
  invitable?: boolean
  descriptionHtml?: string
  cohortTabVisible?: boolean
}

export default function EventPage({
  event,
  datetime,
  host,
  cohosts,
  isCohort = false,
  locationInPerson,
  location,
  locationVirtual,
  speakers,
  resources,
  timelines,
  prev,
  next,
  viewAllUrl,
  eventData,
  registerEvent,
  invitable,
  descriptionHtml,
  cohortTabVisible = false,
}: EventPageProps): JSX.Element {
  const { isDesiredWidth: isMobile } = useMediaQuery(breakpoints.xs)
  const isFullDesktop = useComplexMediaQuery({ minWidth: breakpoints.xlg })
    .meetsDetails.minWidth
  return (
    <div css={{ maxWidth: '100vw' }}>
      {useShowGridOverlay().overlay}
      {!isMobile && (
        <NavigationTabs
          cohortTabVisible={cohortTabVisible}
          activeTab="events"
          css={[
            DEFAULT_NAVIGATION_STYLE,
            isFullDesktop && DESKTOP_NAVIGATION_STYLE,
          ]}
        />
      )}
      <EventCallHeader
        isMobile={isMobile}
        date={event.date}
        title={event.title}
        datetime={datetime}
        data={eventData}
        registerEvent={registerEvent}
        invitable={invitable}
        registrationDeadline={event.deadline}
        deadlineReached={event.deadline_reached}
        capacityReached={event.capacity_reached}
        closed={event.closed}
        closedAt={eventData.closed_at}
      />
      <Container
        css={[
          defaultContentWrapper,
          isFullDesktop && desktopContentWrapper,
          isMobile && mobileContentWrapper,
        ]}>
        <Container
          css={[defaultDetailContainer, isMobile && mobileDetailContainer]}>
          <EventCallContent
            css={{
              maxWidth: isMobile ? '100%' : calcGridWidth(5),
              flex: isMobile ? 1 : undefined,
            }}
            host={host}
            cohosts={cohosts}
            subtitle={event.subtitle}
            isCohort={isCohort}
            locationInPerson={locationInPerson}
            location={location}
            locationVirtual={locationVirtual}
            locationUrl={event.location_url}
            alliesWelcome={event.allies_welcome}
            descriptionHtml={descriptionHtml}
          />
          <Container
            direction="column"
            css={[defaultImageContainer, isMobile && mobileImageContainer]}>
            <img css={imageStyle} src={event.image} alt={event.image_alt} />
            <Caption>{event.image_alt}</Caption>
          </Container>
        </Container>
      </Container>
      {!!speakers.length && (
        <SpeakersSlider isMobile={isMobile} speakers={speakers} />
      )}
      {(!!timelines.length || !!resources.length) && (
        <Container
          css={{
            width: '100%',
            backgroundColor: colors.backgrounds.page,
          }}>
          <Container
            css={[
              defaultTimelineResourcesContainer,
              isFullDesktop && desktopTimelineResourcesContainer,
              isMobile && mobileTimelineResourcesContainer,
            ]}>
            {!!timelines.length && (
              <div
                css={{
                  position: 'relative',
                  left: isMobile ? 1 : 0,
                  flex: 1,
                  minWidth: 335,
                  maxWidth: isMobile ? undefined : '50%',
                }}>
                <Agenda timelines={timelines} />
              </div>
            )}
            {!!resources.length && (
              <div
                css={{
                  position: 'relative',
                  left: isMobile ? 1 : 0,
                  flex: 1,
                  minWidth: 335,
                  maxWidth: isMobile ? undefined : '50%',
                }}>
                <RecommendedResources resources={resources} />
              </div>
            )}
          </Container>
        </Container>
      )}
      <Container css={{ width: '100%' }}>
        <div
          css={[
            defaultPrevNextContainer,
            isFullDesktop && desktopPrevNextContainer,
            isMobile && mobilePrevNextContainer,
          ]}>
          <DetailsFooter
            prev={prev}
            next={next}
            type="event"
            viewAllUrl={viewAllUrl}
            isMobile={isMobile}
          />
        </div>
      </Container>
      <Footer isResponsivePage />
      {isMobile && (
        <Container
          css={{
            padding: `${spacings.grid_gap_basis_num * 2}px ${
              spacings.grid_gap_basis_num
            }px`,
            bottom: 0,
            position: 'sticky',
            backgroundColor: colors.backgrounds.white,
            boxShadow: boxShadow.footerContainer,
            justifyContent: 'center',
            zIndex: zIndex.footer,
          }}>
          <EventAction
            data={eventData}
            invitable={invitable}
            location="banner"
            doRegister={registerEvent}
            css={{
              width: '100%',
            }}
          />
        </Container>
      )}
    </div>
  )
}
