import React, { useRef, useState } from 'react'
import produce, { enableES5 } from 'immer'

enableES5()
import { v4 as uuidv4 } from 'uuid'

// styles
import './EventSpeakers.scss'

// components
import EventSpeakerRow from './EventSpeakerRow'

// utils
import { useAdditionalSectionRows } from 'javascripts/general'

export interface IEventSpeaker {
  fake_id: string | undefined
  id?: number
  name?: string
  title?: string
  company?: string
  contact_details?: string
  bio?: string
  fileName?: string
  cached_avatar_data?: string
  _destroy?: boolean
  errors?: Record<string, string[]>
  imageData?: string
}

interface IEventSpeakers {
  initialValues: IEventSpeaker[]
  rootEntity: string
}

const EventSpeakers: React.FC<IEventSpeakers> = ({
  initialValues,
  rootEntity,
}) => {
  const [eventSpeakers, changeEventSpeakers] = useState(
    initialValues.map((eventSpeaker) => ({
      ...eventSpeaker,
      fake_id: uuidv4(),
    })),
  )

  const componentRef = useRef()

  const addRow = () => {
    changeEventSpeakers(
      produce((draft) => {
        draft.push({ fake_id: uuidv4() })
      }),
    )
  }

  const cleanRows = () => {
    changeEventSpeakers(
      produce((draft) => {
        draft.forEach((row) => (row._destroy = true))
      }),
    )
  }

  const renderedComponentIndex = eventSpeakers.findIndex(
    (speaker) => !speaker._destroy,
  )

  useAdditionalSectionRows(
    renderedComponentIndex !== -1,
    componentRef,
    addRow,
    cleanRows,
  )

  return (
    <div className="speakers additional-section-component" ref={componentRef}>
      {eventSpeakers.map((speaker, index) => (
        <EventSpeakerRow
          key={speaker.id || speaker.fake_id}
          speaker={speaker}
          index={index}
          changeEventSpeakers={changeEventSpeakers}
          renderedComponentIndex={renderedComponentIndex}
          rootEntity={rootEntity}
        />
      ))}
      <button className="link" type="button" onClick={addRow}>
        + Add Person
      </button>
    </div>
  )
}

export default EventSpeakers
