import getStartedImg from 'images/mentorship_exchange/get_started.png'
import { colors, spacings } from 'stylesheets/theme'
import Container from 'components/Container'
import { Paragraph, SectionTitle } from 'components/Typography'
import Button, { Variant } from 'components/Button'
import MentorshipApplicationWrapper from './MentorshipApplicationWrapper'
import ApplicationFormContext from './ApplicationFormContext'
import { css } from '@emotion/react'
import { useContext } from 'react'

export interface GetStartedPageProps {
  orgName: string
  programName: string
  onGetStarted?: () => void
}

const BoxStyle = css({
  position: 'relative',
  padding: spacings.grid_gap_basis_num * 2,
  overflow: 'hidden',
})

const ContainerSytle = css({
  gap: spacings.grid_gap_basis_num * 7,
  maxWidth: 1161,
})

const MobileContainerStyle = css({
  flexDirection: 'column-reverse',
})

const ImageStyle = css({
  width: 410,
  height: 282,
})

const MobileImageStyle = css({
  width: 232,
  height: 160,
})

export default function GetStartedPage({
  orgName,
  programName,
  onGetStarted = () => {},
}: GetStartedPageProps): JSX.Element {
  const { isMobile } = useContext(ApplicationFormContext)
  const containerStyle = [ContainerSytle]
  if (isMobile) {
    containerStyle.push(MobileContainerStyle)
  }
  return (
    <MentorshipApplicationWrapper>
      <div css={BoxStyle}>
        <Container css={containerStyle}>
          <Container
            direction="column"
            css={{
              alignItems: isMobile ? 'center' : 'flex-start',
              gap: spacings.grid_gap_basis_num * 3,
            }}>
            <SectionTitle
              css={{
                color: colors.teal_dark,
              }}>
              Mindr Mentorship Exchange Candidate Questionnaire
            </SectionTitle>
            <div>
              <h1
                css={{
                  marginBottom: spacings.grid_gap_basis_num * 2,
                  fontSize: 30,
                }}>
                Welcome to Mindr Mentorship Exchange
              </h1>
              <h3>You’re about to embark on an impactful mentorship journey</h3>
            </div>
            <Paragraph>
              The Mindr Mentorship Exchange is a proven program that pairs
              colleagues across the {orgName} community into reciprocal
              mentorship matches based on their complementary goals and skills.
              Selected pairs participate in a world-class curriculum of five
              virtual one-hour sessions starting in {programName}, in a variety
              of 1:1 and group formats. Sessions will cover topics such as
              effective goal-setting, overcoming roadblocks, expanding your
              circle of influence, building professional presence, and creating
              a culture of mentorship. Pairings are reciprocal, and candidates
              of any level of seniority or tenure must apply in order to be
              considered.
            </Paragraph>
            <Paragraph bold>
              The application should take 5-10 minutes to complete. Every
              prospective participant, irrespective of seniority, must complete
              it.
            </Paragraph>
            <Button
              variant={Variant.PRIMARY}
              css={{
                backgroundColor: colors.buttons.teal,
                borderColor: colors.buttons.teal,
              }}
              onClick={onGetStarted}>
              Get Started
            </Button>
          </Container>
          <img
            aria-label="Mindr Mentorship Exchange get started"
            src={getStartedImg}
            css={isMobile ? MobileImageStyle : ImageStyle}></img>
        </Container>
      </div>
    </MentorshipApplicationWrapper>
  )
}
