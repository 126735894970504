import Input from 'components/Input'
import Modal, { Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import { useState } from 'react'
import { IMentorshipExchangeApplication } from 'types'

interface RemoveModalProps {
  object: IMentorshipExchangeApplication
  isOpen?: boolean
  onClose: (id: number) => void
  onSave: (id: number, note: string) => void
}

export default function RemoveModal({
  object,
  isOpen = false,
  onClose,
  onSave,
}: RemoveModalProps): JSX.Element {
  const fieldName = 'remove_note'
  const [noteText, setNoteText] = useState('')
  const onChangeText = (value: { [fieldName]: string }) => {
    setNoteText(value[fieldName])
  }
  const onCloseModal = () => {
    onClose(object?.id ?? 0)
    setNoteText('')
  }
  const onSaveModal = () => {
    onSave(object?.id ?? 0, noteText)
    setNoteText('')
  }
  return (
    <Modal
      css={modalStyle}
      isOpen={isOpen}
      size={Size.large}
      onRequestClose={onCloseModal}
      onSave={onSaveModal}
      submitButton="Confirm"
      cancelButton="Cancel"
      title="Remove this applicant">
      <p>
        Removing this applicant means the applicant will not be matched or
        included in the upcoming cohort. This action can be undone any time
        before matching starts, by clicking on &quot;Restore&quot; in the
        &quot;Removed Applicants&quot; table.
      </p>
      <Input
        type="textarea"
        label="Optional"
        name={fieldName}
        value={noteText}
        hideName
        caption="Please leave a note explaining the reason for removal, to assist the mentor matching review team."
        captionPosition="between"
        onChange={onChangeText}
      />
    </Modal>
  )
}
