import { css } from '@emotion/react'

import Widget, { WidgetProps } from './Widget'

import Icon from 'images/icons/award.svg'

import { colors } from 'stylesheets/theme'

interface PrioritizedApplicantsWidgetProps
  extends Omit<WidgetProps, 'number' | 'icon'> {
  numPeople: number
}

const iconStyle = css({
  path: {
    stroke: colors.orange_dark,
    strokeWidth: 1.88,
  },
})

const widgetStyle = css({
  '.icon-container': {
    backgroundColor: colors.orange_bg_light,
  },
})

export default function PrioritizedApplicantsWidget({
  numPeople,
  ...props
}: PrioritizedApplicantsWidgetProps): JSX.Element {
  return (
    <Widget
      icon={<Icon height="18px" width="18px" css={iconStyle} />}
      number={numPeople}
      css={widgetStyle}
      {...props}
    />
  )
}
