import { css } from '@emotion/react'
import { spacings, colors, boxShadow, zIndex, Theme } from 'stylesheets/theme'
import Line from 'images/preview_branding/line.svg'

import HeaderNavContainer from 'components/HeaderNav/HeaderNavContainer'
import Logo from 'components/HeaderNav/Logo'
import ProvidedByLogo from 'components/HeaderNav/ProvidedByLogo'
import ButtonsContainer from 'components/HeaderNav/ButtonsContainer'
import Button, { ButtonComponentType } from 'components/Button'
import Avatar from 'components/Avatar/Avatar'
import { useContext } from 'react'
import BrandingContext from './BrandingContext'

export interface PreviewOrganizationHeaderNavProps {
  avatarUrl?: string
  userInitial?: string
  className?: string
}

const headerNavContainerStyle = css({
  backgroundColor: colors.backgrounds.white,
  top: 'auto',
  left: 'auto',
  position: 'inherit',
  width: 763,
  height: 26,
  paddingLeft: spacings.grid_gap_basis_num * 2,
  paddingRight: 20,
  img: { width: 20, height: 20 },
  borderTopRightRadius: '6px',
  borderTopLeftRadius: '6px',
  a: {
    fontSize: 12,
    lineHeight: '15px',
  },
})

const lineStyle = css({
  width: 60,
  height: 9,
  path: {
    strokeWidth: spacings.grid_gap_basis,
    stroke: colors.borders.gray,
  },
})

const previewHeaderWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  height: spacings.grid_gap_basis_num * 3 + 42,
  boxShadow: boxShadow.preview,
  zIndex: zIndex.previewHeaderNav,
})

const logoStyle = css({
  h3: {
    fontSize: spacings.grid_gap_basis,
    lineHeight: spacings.grid_gap_basis,
  },
  img: {
    width: spacings.grid_gap_basis_num * 2,
    height: spacings.grid_gap_basis_num * 2,
  },
})

const providedByLogo = css({
  width: 150,
  img: {
    width: 150,
  },
  left: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'none',
  position: 'initial',
})

const underHeaderSpaceStyle = css({
  backgroundColor: colors.backgrounds.white,
  width: 763,
  height: spacings.grid_gap_basis_num * 3,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 18,
})

const linesWrapperStyle = css({
  display: 'flex',
  alignItems: 'flex-start',
  gap: spacings.grid_gap_basis,
})

const darkNavContainerStyle = css({
  'h3, a': {
    color: colors.backgrounds.white,
    '&:hover': {
      color: colors.backgrounds.white,
      opacity: 0.7,
    },
  },
})

function LightLine() {
  return <Line css={lineStyle} />
}

export default function PreviewOrganizationHeaderNav({
  avatarUrl = null,
  userInitial = null,
  className = null,
}: PreviewOrganizationHeaderNavProps): JSX.Element {
  const { organization, color, logo, currentHigherContrastTheme } =
    useContext(BrandingContext)

  return (
    <div css={previewHeaderWrapper}>
      <HeaderNavContainer
        css={[
          headerNavContainerStyle,
          { backgroundColor: `#${color}` },
          ...(currentHigherContrastTheme === Theme.Dark
            ? [darkNavContainerStyle]
            : []),
        ]}
        className={className}>
        <div css={{ display: 'flex' }}>
          <Logo
            organization={organization}
            viewOnly
            css={logoStyle}
            logoUrl={logo}
          />
        </div>
        <ProvidedByLogo
          css={providedByLogo}
          viewOnly
          theme={currentHigherContrastTheme}
          color={color}
        />
        <ButtonsContainer>
          <Button as={ButtonComponentType.LINK}>Settings</Button>
          <Button as={ButtonComponentType.LINK}>Badges</Button>
          <Avatar avatarUrl={avatarUrl} userInitial={userInitial} size={25} />
        </ButtonsContainer>
      </HeaderNavContainer>
      <div css={underHeaderSpaceStyle}>
        <div css={linesWrapperStyle}>
          <Line
            css={[
              lineStyle,
              css({
                path: {
                  stroke: colors.text.text_9,
                },
              }),
            ]}
          />
          <LightLine />
          <LightLine />
          <LightLine />
        </div>
      </div>
    </div>
  )
}
