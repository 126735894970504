import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react'
import { IUser } from 'types'
import ApplicationFormContext from './ApplicationFormContext'
import ApplicationStepWrapper from './ApplicationStepWrapper'
import ApplicationFormFieldContainer from './ApplicationFormFieldContainer'
import Input from 'components/Input'
import CheckboxIcon from 'images/icons/checked.svg'
import Container from 'components/Container/Container'
import { Paragraph } from 'components/Typography'

const dropdownStyle = {
  control: {
    width: '537px',
  },
  menu: {
    width: '537px',
  },
}

const mobileDropdownStyle = {
  control: {
    maxWidth: '350px',
  },
  menu: {
    maxWidth: '350px',
  },
}

interface IManagerApprovalProps {
  requiresBudget?: boolean
}

export default function ManagerApproval({
  requiresBudget = false,
}: IManagerApprovalProps): JSX.Element {
  const inputName = 'manager'
  const { formData, loadMangerOptions, isMobile } = useContext(
    ApplicationFormContext,
  )
  const [managerOptions, setManagerOptions] = useState<IUser[]>([])
  const [currentManager, setManager] = useState<IUser | null>(
    formData[inputName] || null,
  )
  const [managerConfirmationChecked, setManagerConfirmationChecked] = useState(
    formData[inputName + '_confirm_checkbox'],
  )

  useEffect(() => {
    loadMangerOptions('', setManagerOptions)
  }, [])

  const handleOnChange = useCallback(
    (inputData: Record<string, unknown>) => {
      try {
        const selected = inputData[inputName] as IUser
        if (selected) {
          setManager(selected)
        }
      } catch (e) {
        console.log(
          'AJAX ERROR: User lookup failed. Unknown user:',
          e,
          inputData,
        )
      }
    },
    [inputName],
  )

  const description = useMemo(
    () =>
      requiresBudget ? (
        <Paragraph>
          As a final step, please add your manager. We&apos;ll simply reach out
          to your manager to approve a <b>$500 budget</b> as part of the
          eligibility requirements for participating in the program. We
          guarantee that your application will remain confidential and will not
          be shared with your manager.
        </Paragraph>
      ) : (
        "As a final step, please add your manager. We'll simply reach out to your manager to confirm your eligibility to participate in this cohort. We guarantee that your application will remain confidential and will not be shared with your manager."
      ),
    [requiresBudget],
  )

  return (
    <ApplicationStepWrapper
      title="Manager approval is required for all applicants to be considered for this cohort"
      description={description}
      requiredFields={[inputName, 'manager_confirmation_checked']}>
      <Container css={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <ApplicationFormFieldContainer
          label="Manager"
          fieldId={inputName}
          required>
          <Input
            name={inputName}
            type="async_select"
            placeholder="Name or email"
            getOptionLabel={(option) => `${option.full_name}`}
            getOptionValue={(option) => JSON.stringify(option)}
            loadOptions={loadMangerOptions}
            selectValue={currentManager}
            defaultOptions={managerOptions}
            onChange={handleOnChange}
            isClearable={false}
            getOptionKey={(o) => o.id}
            styleOverrides={isMobile ? mobileDropdownStyle : dropdownStyle}
            noOptionsMessage="No results were found."
          />
        </ApplicationFormFieldContainer>
        <Container>
          <label
            tabIndex={0}
            css={{
              width: 20,
              height: 20,
            }}>
            <Input
              type="checkbox"
              defaultChecked={formData[inputName + '_confirm_checkbox']}
              checked={managerConfirmationChecked}
              name={inputName + '_confirm_checkbox'}
              id={inputName + '_confirm_checkbox'}
              onChange={(input) => {
                setManagerConfirmationChecked(
                  input[inputName + '_confirm_checkbox'],
                )
              }}
            />
            <input
              type="hidden"
              value={managerConfirmationChecked}
              name={'manager_confirmation_checked'}
            />
            <CheckboxIcon />
          </label>
          <p>
            By selecting this box, I affirm that this person is my direct
            manager.
          </p>
        </Container>
      </Container>
    </ApplicationStepWrapper>
  )
}
