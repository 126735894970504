import React, { FC, useRef } from 'react'
import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import withReactContent from 'sweetalert2-react-content'
import cx from 'classnames'

// assets
import PencilIcon from '../../images/icons/pencil.svg'

// utils
import { defaultSwalParams } from 'javascripts/general'

// components
import ChangeAvatarModal from 'components/ChangeAvatarModal'

import './UploadAvatar.scss'

const SwalReact = withReactContent(Swal)

const UploadAvatar: FC<{
  avatar_url?: string
  size?: number
  className?: string
  removeDescription?: boolean
  userFullName?: string
  showErrorAsFlash?: boolean
}> = ({
  avatar_url,
  size = 144,
  className = '',
  removeDescription = false,
  userFullName,
  showErrorAsFlash = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement>()

  const onAvatarChange = async (e) => {
    try {
      await axios.patch('/dashboard/profile.json', {
        user: {
          avatar: e.target.value,
        },
        authenticity_token: window.authenticity_token,
      })
      location.reload()
    } catch (e) {
      window.flash('Something went wrong', 'alert')
    }
  }

  const handleChangeClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    const { isConfirmed } = await SwalReact.fire({
      ...defaultSwalParams,
      width: 466,
      title: 'Change your profile photo',
      confirmButtonText: 'Upload new photo',
      customClass: {
        ...defaultSwalParams.customClass,
        container: 'change-avatar-modal',
      },
      html: <ChangeAvatarModal avatar_url={avatar_url} />,
    })

    if (isConfirmed) {
      fileInputRef.current.click()
    }
  }

  const AvatarPlaceholder = () => {
    if (removeDescription) {
      return (
        <>
          <span className="default-user-avatar">
            {userFullName.toUpperCase().charAt(0)}
          </span>
          <div className={'hover-description'}>
            <PencilIcon />
          </div>
        </>
      )
    }

    return (
      <>
        <PencilIcon />
        <div className={'description'}>
          Upload profile <br />
          photo
        </div>
      </>
    )
  }

  return (
    <label
      className={cx(
        'to-be-cropped-wrapper upload-avatar-component file-input-wrapper round',
        className,
        { 'flash-error': showErrorAsFlash },
      )}
      tabIndex={0}
      title={avatar_url ? 'Change Profile Photo' : 'Add Profile Photo'}
      htmlFor={'userAvatar'}>
      <div
        className={cx('upload-avatar', {
          'with-description': !removeDescription,
        })}
        style={{ height: size, width: size, flex: `0 0 ${size}px` }}>
        {avatar_url ? (
          <div onClick={handleChangeClick}>
            <img src={avatar_url} alt="user-avatar" className={'preview'} />
            <div className={'hover-description'}>
              <PencilIcon />
              {!removeDescription && (
                <div className={'description'}>Change profile photo</div>
              )}
            </div>
          </div>
        ) : (
          <AvatarPlaceholder />
        )}
      </div>
      <input
        className={'hidden-file-field'}
        name={'userAvatar'}
        onChange={onAvatarChange}
        tabIndex={-1}
      />
      <input
        className="to-be-cropped"
        accept="image/*"
        tabIndex={-1}
        data-image-type="round"
        data-text-preview="false"
        data-text-title="Add Profile Photo"
        data-submit-button="Save photo"
        type="file"
        name="userAvatar"
        ref={fileInputRef}
        id="userAvatar"
      />
    </label>
  )
}

export default UploadAvatar
