import React, { useMemo, useState } from 'react'
import Slider from 'react-slick'
import axios from 'axios'
import { css } from '@emotion/react'

//components
import { colors, spacings } from 'stylesheets/theme'
import Switch from 'components/Switch'
import Community from 'components/Community'
import { ITableData } from 'components/Table'

//helpers
import { homepageSliderOptions } from 'components/helper-functions'
import { useEffectNotOnMount } from 'javascripts/general'

import Activities from './Activities'
import { SlickArrow } from './SlickArrow'
import CommunitiesSlider from './CommunitiesSlider'
import { PromotionCardType } from './PromotionCard'
import SandboxBanner from './SandboxBanner'

//assets
import CohortIcon from '../../images/icons/cohort_18.svg'

//interfaces
import type { ICommunity } from 'types'
import Modal, { IModalProps, Size } from 'components/Modal'
import { Paragraph } from 'components/Typography'
import Container from 'components/Container'
import Button, { ButtonComponentType, Variant } from 'components/Button/Button'

//services
async function fetchCommunities(subscriptions?: boolean) {
  try {
    const response = await axios.get('/communities.json', {
      params: {
        per_page: 8,
        ...(subscriptions ? { 'filter[my_communities]': true } : {}),
      },
    })
    return response.data.data
  } catch (error) {
    window.flash('Something went wrong!', 'alert')
  }
}

async function fetchEvents(subscriptions?: boolean) {
  try {
    const response = await axios.get('/events.json', {
      params: {
        per_page: 3,
        ...(subscriptions ? { 'filter[my_communities]': true } : {}),
      },
    })
    return response.data.data
  } catch (error) {
    window.flash('Something went wrong!', 'alert')
  }
}

async function fetchCalls(subscriptions?: boolean) {
  try {
    const response = await axios.get('/calls.json', {
      params: {
        per_page: 3,
        ...(subscriptions ? { 'filter[my_communities]': true } : {}),
      },
    })
    return response.data.data
  } catch (error) {
    window.flash('Something went wrong!', 'alert')
  }
}

interface IHomePage {
  managerApproval?: ManagerApprovalResponseObject
  communities: ITableData<ICommunity>
  events: ITableData
  calls: ITableData
  cohorts: ITableData | null
  promotionType: PromotionCardType | null
  promotionEmail?: string
  sandboxMode: boolean
  orgName: string
}

const sectionStyles = css({
  paddingTop: spacings.grid_gap_basis_num * 2,
  backgroundColor: colors.backgrounds.highlight,
})

const containerStyles = css({
  margin: '0 auto',
  padding: `0 ${spacings.grid_gap_basis_num * 5}px`,
  maxWidth: '1440px',
})

interface HomePageSectionProps {
  children: React.ReactNode
  className?: string
}

const HomePageSection = ({
  children,
  className,
}: HomePageSectionProps): JSX.Element => (
  <div css={sectionStyles} className={className}>
    <div css={containerStyles}>{children}</div>
  </div>
)

interface ManagerApprovalResponseObject {
  status: 'approved' | 'denied'
  program_name: string
  applicant_name: string
  submitted: boolean
}

interface ManagerApprovalResponseModalProps
  extends Omit<IModalProps, 'children' | 'title' | 'size'> {
  managerApproval: ManagerApprovalResponseObject
}

function ManagerApprovalResponseModal({
  managerApproval,
  ...props
}: ManagerApprovalResponseModalProps) {
  const { status, program_name, applicant_name, submitted } = managerApproval
  const title = useMemo(() => {
    if (!submitted) {
      return 'Thank you for your response!'
    } else {
      return 'You’ve already submitted your response'
    }
  }, [submitted])

  const content = useMemo(() => {
    if (status === 'approved') {
      return `You've approved ${applicant_name}'s candidacy for the Mindr Mentorship Exchange ${program_name} cohort. Participation in the program requires a 5-hour time commitment, divided into 5 mentorship sessions.`
    } else {
      return `You've denied ${applicant_name}'s candidacy for the Mindr Mentorship Exchange ${program_name} cohort. ${applicant_name} will not participate in this coming cohort. `
    }
  }, [])
  return (
    <Modal largeTitle title={title} size={Size.large} {...props}>
      <Container
        css={{
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 36,
        }}>
        <Paragraph>{content}</Paragraph>
        <Paragraph>
          If you have any questions, please reach out to {' '}
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            href="mailto:mentorship@mindrglobal.com"
            css={{
              display: 'unset',
            }}>
            mentorship@mindrglobal.com
          </Button>
        </Paragraph>
      </Container>
    </Modal>
  )
}

const HomePage: React.FC<IHomePage> = ({
  managerApproval,
  communities,
  events,
  calls,
  sandboxMode,
  cohorts,
  promotionType,
  promotionEmail,
  orgName,
}) => {
  const [managerApprovalModalOpen, setManagerApprovalModalOpen] = useState(
    !!managerApproval,
  )
  const [communitiesData, setCommunitiesData] =
    useState<ITableData<ICommunity>>(communities)
  const [eventsData, setEventsData] = useState(events)
  const [callsData, setCallsData] = useState(calls)
  const [subscriptions, setSubscriptions] = useState<boolean>(false)

  useEffectNotOnMount(() => {
    loadData()
  }, [subscriptions])

  const loadData = async () => {
    const fetchedCommunities = await fetchCommunities(subscriptions)
    const fetchedEvents = await fetchEvents(subscriptions)
    const fetchedCalls = await fetchCalls(subscriptions)

    setCommunitiesData(fetchedCommunities)
    setEventsData(fetchedEvents)
    setCallsData(fetchedCalls)
  }

  const sliderOptions = (slidesToShow: number) => ({
    ...homepageSliderOptions,
    draggable: false,
    nextArrow: <SlickArrow isNext />,
    prevArrow: <SlickArrow isNext={false} />,
    slidesToShow: slidesToShow > 4 ? 4 : slidesToShow,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: slidesToShow > 4 ? 4 : slidesToShow,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: slidesToShow > 3 ? 3 : slidesToShow,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: slidesToShow > 2 ? 2 : slidesToShow,
        },
      },
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  })

  const showSandboxBanner = sandboxMode && communities.rows.length >= 4

  return (
    <>
      {showSandboxBanner && (
        <HomePageSection>
          <SandboxBanner />
        </HomePageSection>
      )}
      <HomePageSection>
        <Switch
          controlName="subscriptions"
          checked={subscriptions}
          uncheckedText="All"
          checkedText="Mine"
          onChange={(e) => setSubscriptions(e.target.checked)}
        />
      </HomePageSection>
      <HomePageSection>
        <CommunitiesSlider
          orgName={orgName}
          communitiesData={communitiesData}
          subscriptions={subscriptions}
          promotionType={promotionType}
          promotionEmail={promotionEmail}
        />
      </HomePageSection>
      <Activities
        eventsData={eventsData}
        callsData={callsData}
        subscriptions={subscriptions}
      />
      {cohorts && cohorts.rows?.length ? (
        <div className="cohorts-wrapper container">
          <div className="section-header">
            <div className="header-left">
              <CohortIcon className="stroke-icon" />
              <p className="section-title">Cohorts</p>
              <a href="/cohorts" className="link view-all">
                View all
              </a>
            </div>
            <p className="section-total caption">
              {`(Showing ${cohorts.rows.length} of ${cohorts.paginator.total_entries})`}
            </p>
          </div>
          <div className="slider homepage-communities">
            <Slider {...sliderOptions(cohorts.rows.length || 1)}>
              {cohorts.rows.map((cohort) => {
                return (
                  <div
                    className="community-card-wrapper"
                    key={cohort.data.id as string}>
                    <Community
                      community={
                        { ...cohort.data, link: cohort.link } as ICommunity
                      }
                    />
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      ) : null}
      {managerApproval && (
        <ManagerApprovalResponseModal
          managerApproval={managerApproval}
          isOpen={managerApprovalModalOpen}
          onRequestClose={() => {
            setManagerApprovalModalOpen(false)
            window.history.pushState({}, '', '/')
          }}
          cancelButton="Close"
          onSave={() => {}}
        />
      )}
    </>
  )
}

export default HomePage
