import moment from 'moment-timezone'
import { useTableContext } from 'components/Table/TableContext'
import { PaginatedListItem } from 'components/PaginatedList'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import { Caption } from 'components/Typography'

interface QuestionsListProps {
  timezone: string
}

const questionWrapper = css({
  display: 'flex',
  flexDirection: 'column',
})

const questionDetail = css({
  lineHeight: '22px',
  color: colors.text.text_3,
  padding: spacings.grid_gap_basis_num / 2,
})

const nameDateWrapper = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: colors.text.text_5,
  marginTop: spacings.grid_gap_basis_num * 2,
})

const name = css({
  lineHeight: '16px',
  fontSize: '12px',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  letterSpacing: '0.5px',
})

/**
 * A list of submitted questions given table rows
 */
export default function QuestionsList({
  timezone,
}: QuestionsListProps): JSX.Element {
  const {
    tableData: { rows },
  } = useTableContext()
  return (
    <div>
      {rows.map((question) => {
        const startTime = moment
          .tz(question.data.created_at.toString(), timezone)
          .format('dddd, MMMM Do YYYY, h:mm a')
        return (
          <PaginatedListItem
            small
            css={questionWrapper}
            key={question.data.notes.toString()}>
            <Caption css={questionDetail}>
              {question.data.notes.toString()}
            </Caption>
            <div css={nameDateWrapper}>
              <p css={[questionDetail, name]}>{question.data.full_name}</p>
              <Caption css={questionDetail}>{startTime}</Caption>
            </div>
          </PaginatedListItem>
        )
      })}
    </div>
  )
}
