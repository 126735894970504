import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from '../modal-style'
import Input from 'components/Input'
import Pill, { PillColor } from 'components/Pill'
import { useEffect, useState } from 'react'

interface IRemoveNoteModalProps
  extends Omit<
    IModalProps,
    'size' | 'children' | 'submitButton' | 'cancelButton' | 'title' | 'onSave'
  > {
  removedBy?: string
  removedNote?: string
  onSave?: (removedNote: string) => void
}

export default function RemoveNoteModal({
  removedBy,
  removedNote,
  onSave,
  ...props
}: IRemoveNoteModalProps): JSX.Element {
  const [currentRemovedNote, setRemovedNote] = useState(removedNote)
  const fieldName = 'removed_note'

  useEffect(() => {
    setRemovedNote(removedNote)
  }, [removedNote])

  const RemovedByPill = () => (
    <Pill
      size="large"
      color={PillColor.GREEN}
      light
      textTransform={false}
      bold={false}>
      Removed by {removedBy}
    </Pill>
  )

  return (
    <Modal
      {...props}
      css={modalStyle}
      actionRowLeftElement={<RemovedByPill />}
      size={Size.large}
      submitButton="Save"
      cancelButton="Cancel"
      title="Notes"
      onRequestClose={() => {
        setRemovedNote(removedNote)
        props.onRequestClose && props.onRequestClose()
      }}
      onSave={() => onSave(currentRemovedNote)}>
      <Input
        type="textarea"
        name={fieldName}
        value={currentRemovedNote}
        onChange={(value: { [fieldName]: string }) => {
          setRemovedNote(value[fieldName])
        }}
        hideName
      />
    </Modal>
  )
}
