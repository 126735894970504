import { HTMLAttributes } from 'react'
import Input from 'components/Input'
import { Caption } from 'components/Typography'
import { useFeatureFlagsContext } from './FeatureFlagsContext'
import { checkboxWithLabelStyle } from './styles'

interface IFeatureFlagProps extends HTMLAttributes<HTMLDivElement> {
  featureId: string
}

export default function FeatureFlag({
  featureId,
  ...props
}: IFeatureFlagProps): JSX.Element {
  const { getFeature, updateFeature, checkboxName } = useFeatureFlagsContext()
  const feature = getFeature(featureId)

  return (
    <div {...props} css={checkboxWithLabelStyle}>
      <div className="checkbox-wrap">
        <Input
          type="checkbox"
          checked={feature.current_value}
          uncheckedValue={0}
          name={checkboxName(featureId)}
          onChange={(changes) => {
            updateFeature(
              featureId,
              changes[checkboxName(featureId)] as boolean,
            )
          }}
        />
      </div>
      <Caption>{feature.description}</Caption>
    </div>
  )
}
