import { Paragraph } from 'components/Typography'
import TableCellWrapper from './TableCellWrapper'

interface TextTableCellProps {
  value: string
  bold?: boolean
  link?: string
  className?: string
}

const TextTableCell = ({
  value,
  bold,
  link,
  className,
}: TextTableCellProps): JSX.Element => {
  return (
    <TableCellWrapper columnType="string" link={link} className={className}>
      <Paragraph bold={bold}>{value}</Paragraph>
    </TableCellWrapper>
  )
}

export default TextTableCell
