import React, { useMemo, useRef, useState } from 'react'
import Axios from 'axios'
import _ from 'lodash'
import { enableES5 } from 'immer'
import { TReportType } from 'types'
import ReportTable from './ReportTable'

enableES5()
//styles
import './Reports.scss'

// components
import { IMainTableFilters, ITableProps } from '../Table'
import Input from 'components/Input'
import AdvancementReport from './AdvancementReport'
import RetentionReport from './RetentionReport'
import moment from 'moment'

async function fetchData(url: string, params: Partial<IFilters>) {
  try {
    const response = await Axios.get(url, { params })
    return response.data
  } catch (error) {
    window.flash('Something went wrong!', 'alert')
  }
}

interface IReportsProps extends ITableProps {
  communityType: 'Cohort' | 'Community' | null
  reportTypes: TReportType[]
}

interface IFilters extends IMainTableFilters {
  type: string
  community_type: 'Cohort' | 'Community' | null
  'filter[active_only]': string | undefined
  'filter[include_without_surveys]': boolean | undefined
  page?: number
  component?: string
  data?: Record<string, any>
}

const Reports: React.FC<IReportsProps> = ({
  tableData,
  tableMeta,
  communityType,
  reportTypes,
  ...props
}) => {
  const [data, setData] = useState(tableData)
  const [meta, setMeta] = useState(tableMeta)
  const [selectedFilters, setSelectedFilters] = useState<IFilters>({
    community_type: communityType,
    'filter[active_only]': 'true',
    'filter[include_without_surveys]': undefined,
    type: 'engagement',
    ...props.selectedFilters,
  })
  const [loading, setLoading] = useState(false)

  const loadData = async (filters: Partial<IFilters>, page?: number) => {
    setLoading(true)
    const normalizedFilters = { ...filters }
    if (page) {
      normalizedFilters.page = page + 1
    }

    const { data, meta, selectedFilters } = await fetchData(
      tableMeta.url,
      normalizedFilters,
    )
    setData(data)
    setSelectedFilters((currentValue) => ({
      ...currentValue,
      ...selectedFilters,
    }))
    setMeta(meta)

    setLoading(false)
  }

  const { current: loadDataDebounced } = useRef(_.debounce(loadData, 2000))

  const changeFilters = (filter) => {
    setSelectedFilters((currentValue) => {
      const newValue = { ...currentValue, ...filter }
      loadDataDebounced(newValue)
      return newValue
    })
  }

  let exportLink = `${meta.url.replace('.json', '.csv')}?type=${
    selectedFilters.type
  }`

  if (selectedFilters['filter[*]']) {
    exportLink = `${exportLink}&filter[*]=${selectedFilters['filter[*]']}`
  }

  if (selectedFilters['filter[active_only]']) {
    exportLink = `${exportLink}&filter[active_only]=${selectedFilters['filter[active_only]']}`
  }

  if (selectedFilters['filter[include_without_surveys]']) {
    exportLink = `${exportLink}&filter[include_without_surveys]=${selectedFilters['filter[include_without_surveys]']}`
  }

  const selectedReportType: TReportType = useMemo(
    () => _.find(reportTypes, { value: selectedFilters.type }),
    [selectedFilters, reportTypes],
  )

  const renderReport = useMemo(() => {
    if (!!selectedReportType?.component) {
      switch (selectedReportType.component) {
        case 'AdvancementReport':
          const { advancement_results } = data
          return (
            <AdvancementReport
              setLoading={setLoading}
              loading={loading}
              filters={selectedReportType.data.filters}
              advancementResults={advancement_results}
              loadData={changeFilters}
              exportLink={exportLink}
            />
          )
        case 'RetentionReport':
          const { retention_results } = data
          return (
            <RetentionReport
              setLoading={setLoading}
              loading={loading}
              filters={selectedReportType.data.filters}
              retentionResults={retention_results}
              loadData={changeFilters}
              exportLink={exportLink}
            />
          )
        default:
          return null
      }
    } else {
      return (
        <ReportTable
          selectedReportType={selectedReportType}
          tableMeta={meta}
          tableData={data}
          loadData={loadDataDebounced}
          changeFilters={changeFilters}
          selectedFilters={selectedFilters}
          exportLink={exportLink}
        />
      )
    }
  }, [selectedReportType, data, loading])

  return (
    <div className="reports-index">
      <div className="type-block">
        <h3>Report Type</h3>
        <Input
          name="type"
          type="select"
          options={reportTypes}
          value={selectedFilters.type}
          onChange={(reportType) => {
            // Don't reload if the report type is the same
            if (reportType['type'] == selectedReportType.value) {
              return
            }

            let newSelectedFilters = {
              ...reportType,
            } as unknown as IFilters

            // this is a table
            // Always default "only active users" to true
            if (
              reportType['type'] != 'retention' &&
              reportType['type'] != 'advancement'
            ) {
              newSelectedFilters = {
                ...newSelectedFilters,
                'filter[active_only]': true,
              } as unknown as IFilters
            }
            setSelectedFilters(newSelectedFilters)

            if (reportType['type'] == 'retention') {
              // A year from today
              const defaultStartDate = moment()
                .subtract(1, 'year')
                .format('YYYY-MM-DD')
              // Today
              const defaultEndDate = moment().format('YYYY-MM-DD')
              newSelectedFilters = {
                ...newSelectedFilters,
                'filter[end_date]': defaultEndDate,
                'filter[start_date]': defaultStartDate,
              } as unknown as IFilters
            }
            loadData(newSelectedFilters)
          }}
        />
      </div>
      {renderReport}
    </div>
  )
}

export default Reports
