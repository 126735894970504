import { ReactNode } from 'react'
import { colors, spacings } from 'stylesheets/theme'
import { css } from '@emotion/react'

interface BannerProps {
  children: ReactNode
  className?: string
}

const BannerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  height: '65px',
  backgroundColor: colors.backgrounds.teal,
  width: '100%',
  borderRadius: spacings.radius,
  border: `1px solid ${colors.borders.gray}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: `calc(${spacings.grid_gap_basis} * 1.5)`,
  paddingRight: `calc(${spacings.grid_gap_basis} * 1.5)`,
})

export default function Banner({
  children,
  className = '',
}: BannerProps): JSX.Element {
  return (
    <div css={BannerStyle} className={className}>
      {children}
    </div>
  )
}
