import { ReactNode } from 'react'
import { css } from '@emotion/react'
import { colors, fonts } from 'stylesheets/theme'

interface CaptionProps {
  children: ReactNode
  className?: string
  bold?: boolean
}

const CaptionStyle = css({
  fontFamily: fonts.lato,
  fontSize: '13px',
  lineHeight: '18px',
  color: colors.text.text_5,
})

const CaptionBoldStyle = css({
  fontWeight: 700,
})

export default function Caption({
  children,
  className = '',
  bold = false,
}: CaptionProps): JSX.Element {
  return (
    <p
      className={className}
      css={[CaptionStyle, bold && CaptionBoldStyle].filter(Boolean)}>
      {children}
    </p>
  )
}
