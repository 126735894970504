import { IEventSpeaker } from '.'
import produce, { enableES5 } from 'immer'
import $ from 'jquery'
enableES5()
import Swal from 'sweetalert2/dist/sweetalert2.js'
import classNames from 'classnames'

// icons
import DeleteIcon from '../../images/icons/delete.svg'

// styles
import './EventSpeakers.scss'

// components
import Input from 'components/Input'

// utils
import { defaultSwalParams } from 'javascripts/general'

interface EventSpeakerRowProps {
  speaker: IEventSpeaker
  index: number
  changeEventSpeakers: React.Dispatch<React.SetStateAction<IEventSpeaker[]>>
  renderedComponentIndex: number
  rootEntity: string
}

export default function EventSpeakerRow({
  speaker,
  index,
  changeEventSpeakers,
  renderedComponentIndex,
  rootEntity,
}: EventSpeakerRowProps): JSX.Element {
  const {
    id,
    _destroy,
    fake_id,
    name,
    errors,
    title,
    company,
    fileName,
    bio,
    imageData,
  } = speaker

  const onInputChange = (editedObj) => {
    changeEventSpeakers(
      produce((draft) => {
        draft[index] = { ...draft[index], ...editedObj }
      }),
    )
  }

  const onAddBioClick = () => {
    Swal.fire({
      ...defaultSwalParams,
      confirmButtonText: 'Save',
      title: `${!!bio ? 'Edit' : 'Add'} Bio`,
      html: `<div class='add-bio-modal-body'>
        <div class='input-component-wrapper'>
          <label for='add-bio-textarea' class='label'>Bio</label>
          <div class='input-wrapper'>
            <textarea id='add-bio-textarea' class='has-max-length' data-max-length='750'>${
              bio || ''
            }</textarea>
          </div>
        </div>
      </div>`,
      onOpen: () => {
        window.initMaxLengthInputs()
      },
      preConfirm: () => {
        onInputChange({ bio: $('#add-bio-textarea').val() })
        return true
      },
    })
  }

  const showLabels = renderedComponentIndex === index

  return (
    <div
      className={classNames('speaker-row additional-section-row', {
        hidden: _destroy,
        first: showLabels,
      })}
      key={id || fake_id}>
      {!!id && (
        <input
          type="hidden"
          name={`${rootEntity}[speakers_attributes][${index}][id]`}
          value={id}
        />
      )}
      {_destroy && (
        <input
          type="hidden"
          name={`${rootEntity}[speakers_attributes][${index}][_destroy]`}
          value="true"
        />
      )}
      <Input
        name="name"
        inputName={`${rootEntity}[speakers_attributes][${index}][name]`}
        value={name}
        label={showLabels && 'Name'}
        onChange={onInputChange}
        error={errors?.name?.join(', ')}
        wrapperClassName="one"
        maxLength="150"
      />
      <Input
        name="title"
        inputName={`${rootEntity}[speakers_attributes][${index}][title]`}
        value={title}
        label={showLabels && 'Title'}
        onChange={onInputChange}
        error={errors?.title?.join(', ')}
        wrapperClassName="two"
        maxLength="150"
      />
      <Input
        name="company"
        inputName={`${rootEntity}[speakers_attributes][${index}][company]`}
        value={company}
        label={showLabels && 'Company'}
        onChange={onInputChange}
        error={errors?.company?.join(', ')}
        wrapperClassName="three"
        maxLength="150"
      />
      <Input
        name="file_name"
        inputName={`${rootEntity}[speakers_attributes][${index}][avatar]`}
        deleteInputName={`${rootEntity}[speakers_attributes][${index}][remove_avatar]`}
        value={fileName}
        label={showLabels && 'Headshot'}
        type="image"
        onChange={onInputChange}
        imageData={imageData}
        error={errors?.avatar?.join(', ')}
        textPreview
        textHeader="Add Headshot"
        wrapperClassName="files-component-wrapper four"
        imageType="round"
      />
      <div className="input-component-wrapper bio-component-wrapper five">
        {showLabels && <div className="label">Bio</div>}
        <div className="input-wrapper">
          <button className="link" type="button" onClick={onAddBioClick}>
            {!!bio ? 'Edit' : '+ Add'} Bio
          </button>
          <input
            type="hidden"
            value={bio || ''}
            name={`${rootEntity}[speakers_attributes][${index}][bio]`}
          />
        </div>
      </div>
      <button
        className={classNames('not-styled-button delete', {
          first: showLabels,
        })}
        type="button"
        onClick={() => {
          onInputChange({ _destroy: true })
        }}>
        <DeleteIcon />
      </button>
    </div>
  )
}
