import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from './modal-style'
import Spinner from 'images/icons/spinner.svg'

interface StartMatchingModalProps
  extends Omit<
    IModalProps,
    'size' | 'children' | 'submitButton' | 'cancelButton' | 'title'
  > {
  isMatching?: boolean
}

export default function StartMatchingModal({
  isMatching = false,
  ...props
}: StartMatchingModalProps): JSX.Element {
  return (
    <Modal
      {...props}
      css={modalStyle}
      size={Size.large}
      submitButton={isMatching ? undefined : 'Start matching'}
      cancelButton={isMatching ? undefined : 'Cancel'}
      title={
        isMatching
          ? 'Matching participants...'
          : 'Are you sure you want to begin automatic matching?'
      }>
      {isMatching ? (
        <Spinner
          css={{
            width: 178,
            height: 161,
          }}
        />
      ) : (
        <p>
          Before we start the matching process, please ensure the lists of
          prioritized and removed applicants are accurate. This action cannot be
          undone.
        </p>
      )}
    </Modal>
  )
}
