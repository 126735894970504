import Modal, { IModalProps, Size } from 'components/Modal'
import { modalStyle } from '../modal-style'
import Spinner from 'images/icons/spinner.svg'

interface RewordMatchReasoningModalProps
  extends Omit<
    IModalProps,
    'size' | 'children' | 'submitButton' | 'cancelButton' | 'title'
  > {
  isRewording?: boolean
}

export default function RewordMatchReasoningModal({
  isRewording = false,
  ...props
}: RewordMatchReasoningModalProps): JSX.Element {
  return (
    <Modal
      {...props}
      css={modalStyle}
      size={Size.large}
      submitButton={isRewording ? undefined : 'Yes. Reword now'}
      cancelButton={isRewording ? undefined : 'No. Leave it alone'}
      title={
        isRewording
          ? 'Rewording in progress...'
          : 'Reword match reasons from scratch'
      }>
      {isRewording ? (
        <>
          <p>
            <i>This could take up to 1 minute to complete...</i>
          </p>
          <Spinner
            css={{
              width: 178,
              height: 161,
            }}
          />
        </>
      ) : (
        <p>
          This action will recalculate and reword the match reasoning. Any
          manual changes to the existing reason will be lost.{' '}
          <i>
            The process will take <b>about 1 minute</b> to complete.
          </i>{' '}
          Do you want to reword all of the match reasonings now?
        </p>
      )}
    </Modal>
  )
}
