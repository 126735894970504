import { css } from '@emotion/react'
import Button, { ButtonComponentType, Variant } from 'components/Button/Button'
import Container from 'components/Container'
import { useMemo } from 'react'
import { calcGridWidth, colors, spacings } from 'stylesheets/theme'
import { ICommunity, IEventCall } from 'types'

type ObjectType = 'EventCall' | 'Community'

interface DetailsFooterEventCallProps {
  prev?: IEventCall
  next?: IEventCall
}

interface DetailsFooterCommunityProps {
  prev?: ICommunity
  next?: ICommunity
}

const containerStyle = css({
  alignItems: 'flex-start',
  minHeight: 220,
  gap: spacings.grid.gap,
})
const mobileContainerStyle = css({
  flexWrap: 'wrap',
})

const linkStyle = css({
  textDecoration: 'none',
  textAlign: 'center',
  display: 'block',
  '&:hover': {
    img: {
      outline: `2px solid ${colors.teal_dark}`,
    },
    h5: {
      color: colors.teal_dark,
    },
  },
})

function PrevNextButton({
  isMobile = false,
  link,
  imgUrl,
  title,
  className = '',
}) {
  return (
    <a
      href={link}
      css={[linkStyle, isMobile ? null : { width: calcGridWidth(2) }]}
      className={className}>
      <Container
        direction="column"
        css={isMobile ? null : { width: calcGridWidth(2) }}>
        <img
          src={imgUrl}
          css={{
            display: 'block',
            width: 78,
            height: 78,
            borderRadius: '50%',
            outline: '1px solid #f0f0f0',
          }}
        />
        <h5>{title}</h5>
      </Container>
    </a>
  )
}

export type DetailsFooterProps<T extends ObjectType> = {
  viewAllUrl: string
  type: 'event' | 'call' | 'community'
  className?: string
  isMobile?: boolean
} & (T extends 'EventCall'
  ? DetailsFooterEventCallProps
  : DetailsFooterCommunityProps)

export default function DetailsFooter<T extends ObjectType>({
  prev,
  next,
  viewAllUrl,
  type,
  className = '',
  isMobile = false,
}: DetailsFooterProps<T>): JSX.Element {
  const viewAllText = useMemo(() => {
    switch (type) {
      case 'event':
        return 'View all events'
      case 'call':
        return 'View all calls to action'
      case 'community':
        return 'View all communities'
    }
  }, [type])

  switch (type) {
    case 'event':
    case 'call':
      return (
        <Container
          direction="row"
          className={className}
          css={[containerStyle, isMobile ? mobileContainerStyle : null]}>
          <Container
            css={{
              width: isMobile ? '45%' : calcGridWidth(2),
              flex: isMobile ? 1 : undefined,
              minHeight: 1,
              order: 1,
            }}>
            {prev && (
              <PrevNextButton
                css={{
                  flex: 1,
                }}
                link={(prev as IEventCall).link}
                imgUrl={(prev as IEventCall).logo}
                title={(prev as IEventCall).title}
                isMobile={isMobile}
              />
            )}
          </Container>
          <Container
            css={{
              width: isMobile ? '100%' : calcGridWidth(6),
              flex: isMobile ? 2 : undefined,
              order: isMobile ? 3 : 2,
              justifyContent: 'center',
              minWidth: 300,
              marginTop: isMobile && spacings.grid_gap_basis_num * 2,
              margin: 'auto',
            }}>
            <Button
              as={ButtonComponentType.LINK}
              variant={Variant.LINK}
              href={viewAllUrl}>
              {viewAllText}
            </Button>
          </Container>
          <Container
            css={{
              width: isMobile ? '45%' : calcGridWidth(2),
              flex: isMobile ? 1 : undefined,
              minHeight: 1,
              order: isMobile ? 2 : 3,
            }}>
            {next && (
              <PrevNextButton
                css={{
                  flex: 1,
                }}
                link={(next as IEventCall).link}
                imgUrl={(next as IEventCall).logo}
                title={(next as IEventCall).title}
                isMobile={isMobile}
              />
            )}
          </Container>
        </Container>
      )
    case 'community':
      return (
        <Container className={className} css={containerStyle}>
          {prev && (
            <PrevNextButton
              css={{
                flex: 1,
                order: 1,
              }}
              link={(prev as ICommunity).link}
              imgUrl={(prev as ICommunity).logo}
              title={(prev as ICommunity).name}
            />
          )}
          <Container
            css={{
              flex: 2,
              order: isMobile ? 3 : 2,
              justifyContent: 'center',
              minWidth: 300,
              marginTop: isMobile && spacings.grid_gap_basis_num * 2,
            }}>
            <Button
              as={ButtonComponentType.LINK}
              variant={Variant.LINK}
              href={viewAllUrl}>
              {viewAllText}
            </Button>
          </Container>
          {next && (
            <PrevNextButton
              css={{
                flex: 1,
                order: isMobile ? 2 : 3,
              }}
              link={(next as ICommunity).link}
              imgUrl={(next as ICommunity).logo}
              title={(next as ICommunity).name}
              isMobile={isMobile}
            />
          )}
        </Container>
      )
  }
}
