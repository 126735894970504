export const mqDesktop = '@media (min-width:768px)'

export const colors = {
  leadr: {
    learning: '#45B5AA',
    empathy: '#4197DD',
    action: '#DE7C0D',
    direction: '#94519F',
    resilience: '#CC4072',
  },
  backgrounds: {
    blue: '#DDF6FC',
    teal: '#F1F7F6',
    orange: '#FFF7EE',
    white: '#ffffff',
    black: '#000000',
    gray: '#f0f0f0',
    page: '#f9f9f9',
    highlight: '#f4f4f4',
    yellow: '#FFFBD6',
  },
  borders: {
    gray: '#e7e7e7',
  },
  disabled: {
    gray: '#DADADA',
  },
  text: {
    text_9: '#999999',
    text_5: '#555555',
    text_3: '#333333',
    text_7c: '#7C7C7C',
  },
  states: {
    warning: '#756D23',
  },
  links: {
    blue: '#1A6A99',
    hover: {
      blue: '#277CB4',
    },
  },
  buttons: {
    teal: '#4AA199',
  },
  // background-main: #fafafa,
  // background-page: #f9f9f9,
  // background-grey: #f0f0f0,
  // background-highlight: #f4f4f4,

  green_dark: '#2E756E',
  green: '#1d5d57',
  green_bg_light: '#e3fffa',
  green_bg_very_light: '#f6fbfb',

  teal: '#45b5aa',
  teal_light: '#d8f0ed',
  teal_dark: '#2e756e',
  // teal-bg: #f6fbfb,
  // teal-image: #a8ddd8,

  blue: '#1e7cb3',
  blue_mid: '#DDF6FC',
  // blue-light: #56b4e9,
  // blue-dark: #155880,
  // blue-bg-light: #eef7fc,

  // orange: #f4a449,
  orange_dark: '#de7c0d',
  orange_bg_light: '#FFF7EE',
  // orange-light: #f8c489,
  // orange-extra-light: #fbdebd,

  red: '#c53c26',
  red_light: '#f8efef',
  // red-dark: #700404,
  // violet: #985ebc,

  purple_report: '#BB4FCC',
  purple: '#aa51b9',
  // purple-dark: #ab3abd,
  purple_light: '#fcf0ff',

  // yellow: #ad5e01,
  // yellow-light: #fff7ee,

  // // text
  // text-9: #999999,
  // text-5: #555555,
  // text-3: #333333,
  pink: '#CC4072',

  //widgets
  people_widget_background: '#ddf6fc',
  removed_registrant_widget_background: '#fff7ee',
  not_yet_matched_widget_background: '#FFF7EE',
  registrant_widget_background: '#ddf6fc',
  registration_capacity_widget_backgronud: '#fef1f9',
  registration_target_widget_background: '#edeef6',
  waitlist_widget_background: '#fff7ee',
  declined_widget_background: '#fef1f9',
  removed_applicant_widget_background: '#f8efef',
}

export const spacings = {
  grid: {
    columns: 12,
    column_width: 88,
    gap: 24,
  },
  grid_gap_basis: '12px',
  grid_gap_basis_num: 12,
  radius: '4px',
  large_radius: '9px',
  preview_radius: '6px',
  form_radius: '12px',
  radius_num: 4,
  rowFieldGap: 10,
  draggable_radius: '6px',
  tag_radius: '6px',
  widget_gap: 20,
  main_padding: '40px 60px 80px 60px',
}

export const fonts = {
  lato: '"Lato", sans-serif',
  poppins: '"Poppins", sans-serif',
}

export const sizes = {
  pageWidth: spacings.grid_gap_basis_num * 120,
  mainHorizontalPadding: spacings.grid_gap_basis_num * 5,
  mainVerticalPadding: 40,
  avatarSize: 67,
  navBarWidth: 328,
  navBarCollapsedWidth: 100,
  navBarHorizontalLeftPadding: spacings.grid_gap_basis_num * 3.5,
  navBarHorizontalRightPadding: 20,
  headerHeight: '42px',
  modalCloseButton: 18,
  footerHeight: '73px',
  wideFieldWidth: 327,
  fieldWidth: 242,
  smallFieldWidth: 100,
  doubleFieldWidth: 294,
  tableRowMinHeight: 60,
  logModalPadding: '0px 56px',
}

export const zIndex = {
  previewHeaderNav: 3,
  headerNav: 5,
  colorPicker: 4,
  sidebar: 40,
  menu: 1000,
  footer: 6,
}

export const boxShadow = {
  preview: '0px 2px 6px 0px rgba(0, 0, 0, 0.10)',
  container: '0px 2px 6px 0px #0000001A',
  footerContainer: '0px 0px 4px 0px #00000033',
}

export enum Theme {
  Light,
  Dark,
  Unknown,
}

export const calcGridWidth = (columns: number, addGutter = 0): number => {
  const columnWidths = spacings.grid.column_width * columns
  const gapWidths = spacings.grid.gap * (Math.max(0, columns - 1) + addGutter)
  return columnWidths + gapWidths
}
