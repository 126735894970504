import { useState } from 'react'

import { RematchConfirmationModal } from './Modals'

import { IAcceptedMatch } from './types'
import { colors } from 'stylesheets/theme'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import Spinner from 'images/icons/spinner.svg'
import { TableCellSecondaryButton } from 'components/Table/components/TableCell'
import Container from 'components/Container/Container'

interface AcceptedMatchActionsCellProps {
  data: IAcceptedMatch
}

export default function AcceptedMatchActionsCell({
  data,
}: AcceptedMatchActionsCellProps): JSX.Element {
  const { onSetMatchFavorite, matchesLoading, ajaxWithUpdates } =
    useMentorshipExchangeDetailsContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [openRematchModal, setOpenRematchModal] = useState(false)

  if (matchesLoading[data.id]) {
    return (
      <Spinner
        css={{
          width: 20,
          height: 20,
        }}
      />
    )
  }

  return (
    <>
      {loading ? (
        <Spinner
          css={{
            width: 32,
            height: 32,
          }}
        />
      ) : (
        <Container
          css={{
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}>
          <TableCellSecondaryButton
            onClick={() => onSetMatchFavorite(data.id, !data.favorite)}
            className="ignore-goji">
            {data.favorite ? 'Unfavorite' : 'Favorite'}
          </TableCellSecondaryButton>
          <TableCellSecondaryButton
            css={{
              color: colors.red,
              borderColor: colors.red,
              '&:not([disabled]):focus, &:not([disabled]):hover': {
                color: colors.red,
                borderColor: colors.red,
              },
            }}
            onClick={() => setOpenRematchModal(true)}
            className="ignore-goji"
            disabled={data.favorite}>
            Rematch
          </TableCellSecondaryButton>
        </Container>
      )}
      <RematchConfirmationModal
        key={data.id}
        isOpen={openRematchModal}
        onRequestClose={() => setOpenRematchModal(false)}
        onSave={() => {
          setLoading(true)
          ajaxWithUpdates(
            data.rematch_url,
            {},
            'post',
            () => {
              setLoading(false)
              setOpenRematchModal(false)
            },
            () => {
              setLoading(false)
              setOpenRematchModal(false)
            },
          )
        }}></RematchConfirmationModal>
    </>
  )
}
