import React, { useCallback, useState } from 'react'
import _ from 'lodash'
import axios from 'axios'

// styles
import './Leadership.scss'

// components
import CommunitiesTiles, { LoadMoreButton } from 'components/CommunitiesTiles'
import EmptyPlaceholder from 'components/EmptyPlaceholder'

// interfaces
import { ITableData } from 'components/Table'

interface ICommunities {
  initialData: ITableData
}

const fetchData = _.debounce(async (url, params, callback) => {
  try {
    const {
      data: { data },
    } = await axios.get(url, {
      params,
    })

    callback(data)
  } catch (e) {
    window.flash('Something went wrong!', 'alert')
  }
}, 400)

const Communities: React.FC<ICommunities> = ({ initialData }) => {
  const [communitiesData, setCommunitiesData] = useState(initialData)

  const loadData = useCallback(() => {
    fetchData(
      '/dashboard/leaderships.json',
      { page: communitiesData.paginator.current_page + 1 },
      (data) => {
        setCommunitiesData((currentData) => ({
          paginator: data.paginator,
          rows: [...currentData.rows, ...data.rows],
        }))
      },
    )
  }, [communitiesData, setCommunitiesData])

  return (
    <div className="leadership">
      <div className="section-header">
        <div className="header-left">
          <p className="section-title">Leadership Dashboards</p>
          <p className="section-total caption">{`( Total ${communitiesData.paginator?.total_entries} )`}</p>
        </div>
      </div>
      <CommunitiesTiles communitiesData={communitiesData} hideSubscribeButton />
      {!communitiesData.rows.length && (
        <EmptyPlaceholder text="You do not lead any communities yet." />
      )}
      {!!communitiesData.rows.length &&
        communitiesData.paginator.current_page !=
          communitiesData.paginator.total_pages && (
          <LoadMoreButton onClick={loadData} />
        )}
    </div>
  )
}

export default Communities
