import Container from 'components/Container'
import { useContext, useMemo } from 'react'
import LeadrAssessmentContext from './LeadrAssessmentContext'
import { colors, spacings } from 'stylesheets/theme'
import { Paragraph, SectionTitle } from 'components/Typography'
import Button, { Variant } from 'components/Button'
import { css } from '@emotion/react'
import leadrGetStartedImg from 'images/leadr_get_started.png'
import Tooltip from 'components/Tooltip'

interface WelcomeStepProps {
  onLetsGo?: () => void
}

const BoxStyle = css({
  position: 'relative',
  padding: spacings.grid_gap_basis_num * 2,
  overflow: 'hidden',
})

const ContainerSytle = css({
  gap: spacings.grid_gap_basis_num * 7,
  maxWidth: 1161,
})

const MobileContainerStyle = css({
  flexDirection: 'column-reverse',
  gap: spacings.grid_gap_basis_num * 4,
})

const ImageStyle = css({
  width: 306.62,
  height: 437.5,
  alignSelf: 'self-end',
})

const MobileImageStyle = css({
  width: 142,
})

const tooltipStyle = css({
  verticalAlign: 'baseline',
})

export default function WelcomeStep({
  onLetsGo = () => {},
}: WelcomeStepProps): JSX.Element {
  const { isMobile } = useContext(LeadrAssessmentContext)
  const containerStyle = useMemo(
    () => [ContainerSytle, isMobile && MobileContainerStyle],
    [isMobile],
  )
  return (
    <div css={BoxStyle}>
      <Container css={containerStyle}>
        <Container
          direction="column"
          css={{
            alignItems: 'flex-start',
            gap: spacings.grid_gap_basis_num * 3,
            maxWidth: 557,
          }}>
          <SectionTitle
            css={{
              color: colors.teal_dark,
            }}>
            LEADR™ Assessment
          </SectionTitle>
          <div>
            <h1
              css={{
                marginBottom: spacings.grid_gap_basis_num * 2,
                fontSize: 30,
              }}>
              Welcome to Mindr&apos;s LEADR™ Index
            </h1>
          </div>
          <Paragraph>
            Mindr’s LEADR™ Index uncovers the unique leadership skills you bring
            to your workplace. It is built on Mindr’s philosophy of cultivating
            well-rounded leaders at every level of an organization, irrespective
            of title, role, or tenure, and focuses on five key dimensions of
            leadership: &nbsp;
            <Tooltip
              css={tooltipStyle}
              alignment="overlap"
              triggerElement={<Button variant={Variant.LINK}>Learning</Button>}
              text="Learning: Being curious, asking questions, approaching work with a growth mindset, having a commitment to lifelong learning"
            />
            ,&nbsp;
            <Tooltip
              css={tooltipStyle}
              alignment="overlap"
              triggerElement={<Button variant={Variant.LINK}>Empathy</Button>}
              text="Empathy: Considering matters from multiple perspectives, thinking about the impacts of actions on others, being aware of the needs of those around you"
            />
            ,&nbsp;
            <Tooltip
              css={tooltipStyle}
              alignment="overlap"
              triggerElement={<Button variant={Variant.LINK}>Action</Button>}
              text="Action: Acting decisively, being prepared to test and iterate strategies, adopting a solutions-oriented mindset"
            />
            ,&nbsp;
            <Tooltip
              css={tooltipStyle}
              alignment="overlap"
              triggerElement={<Button variant={Variant.LINK}>Direction</Button>}
              text="Direction: Motivating and guiding others to act, influencing behavior change in others, considering others’ challenges and developing strategies and direction"
            />
            ,&nbsp;
            <Tooltip
              css={tooltipStyle}
              alignment="overlap"
              triggerElement={
                <Button variant={Variant.LINK}>Resilience</Button>
              }
              text="Resilience: Adapting to shifting circumstances, showing determination in the face of challenges, embracing failure as an opportunity to improve"
            />
            .<br></br>
            <br></br>
            The purpose of this assessment is to help you recognize and hone
            your leadership capabilities in order to make the most impactful
            contribution to your team and organization. After completing the
            assessment, you will receive a detailed report outlining your unique
            LEADR™ Profile. Your results will provide insight into your
            strengths as well as areas for growth and development.
            <br></br>
            <br></br>
            Leadership skills and strengths may evolve over time, so you may
            find that you have a different LEADR™ Profile at different points in
            your career.
            <br></br>
            <br></br>
            <b>The assessment should take 15-20 minutes to complete.</b> Please
            answer each question in the context of your current position in your
            current workplace.
          </Paragraph>
          <Button
            variant={Variant.PRIMARY}
            css={{
              backgroundColor: colors.buttons.teal,
              borderColor: colors.buttons.teal,
            }}
            onClick={onLetsGo}>
            Let&apos;s Go
          </Button>
        </Container>
        <img
          aria-label="Mindr's LEADR™ Index get started"
          src={leadrGetStartedImg}
          css={isMobile ? MobileImageStyle : ImageStyle}></img>
      </Container>
    </div>
  )
}
