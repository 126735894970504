import Container from 'components/Container'
import { useLeadrCumulativeReportContext } from './LeadrCumulativeReport.context'
import { Paragraph } from 'components/Typography'
import {
  SColumns,
  SColumnsSmallGap,
  SPanel,
  SSeparator,
  SSectionTitles,
  SSectionWrap,
  SLink,
  SColumnsNarrow,
  SArchetypeTrigger,
} from './LeadrCumulativeReport.styles'
import { translateAllEnLiterals } from 'utils/translateEnLiterals'
import LeadrResultChart from 'components/LeadrIndexReport/LeadrResultChart'
import { IArchetype } from 'components/LeadrArchetype/LeadrArchetype.types'
import LeadrArchetype from 'components/LeadrArchetype'
import MembershipFilters, {
  DEFAULT_FILTER_OPTIONS,
} from 'components/MembershipFilters'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import { useCallback, useState } from 'react'
import { useContentDimensions } from 'hooks/useDimensions'
import { TUserFilters } from 'state/Users'
import { ajaxUpdateUserFilters } from './LeadrCumulativeReport.ajax'
import { IReportResults } from './LeadrCumulativeReport.types'
import LeadrArchetypeModal from './LeadrArchetypeModal'
import { Size } from 'components/Modal'
import { DEFAULT_ASSESSMENT_LEADR_RESULTS } from 'components/LeadrIndexReport/LeadrIndexReport.constants'

enum Breakpoints {
  NARROW = 1200,
  WIDE = 1201,
}

export default function LeadrCumulativeReportComponent(): JSX.Element {
  const {
    strings,
    archetypes,
    reportResults,
    setResults,
    userFilters,
    setUserFilters,
    filterOptions = DEFAULT_FILTER_OPTIONS,
    urls,
  } = useLeadrCumulativeReportContext()

  const closestBreakpoint = useContentDimensions(
    ...(Object.values(Breakpoints).filter(Number.isInteger) as number[]),
  )
  const isNarrow = closestBreakpoint === Breakpoints.NARROW

  const updateFilters = useCallback(
    (updatedFilters: Partial<TUserFilters>) => {
      const newFilters = {
        ...userFilters,
        ...updatedFilters,
      }
      setUserFilters(newFilters)
      ajaxUpdateUserFilters({
        strings,
        urls,
        userFilters: newFilters,
        onSuccess: (report: IReportResults) => {
          setResults(report)
        },
      })
    },
    [strings, urls, setResults, userFilters],
  )

  const [clickedArchetype, setClickedArchetype] = useState<IArchetype>()

  return (
    <div className="cumulative-report-wrapper">
      <Container
        selector=".main > .content .cumulative-report-wrapper &"
        direction="column"
        alignment="start"
        className="header">
        <h1>{strings.titles.page}</h1>
        <Paragraph>
          {strings.descriptions.page}{' '}
          <Button
            css={SLink}
            as={ButtonComponentType.LINK}
            href={urls.page_link}
            target="_blank"
            variant={Variant.LINK}>
            {strings.descriptions.page_link}
          </Button>
          {strings.descriptions.page_after_link}
        </Paragraph>
      </Container>

      <MembershipFilters
        hideSearch
        cityAndState
        displayCount
        initialCount={reportResults?.details.org_participants}
        currentCount={reportResults?.details.selection_participants}
        searchPlaceholder=""
        filters={userFilters ?? {}}
        filterOptions={filterOptions}
        updateFilters={updateFilters}
        updateButtonText={strings?.buttons.update_results}
      />

      <div css={[SColumns, isNarrow ? SColumnsNarrow : null]}>
        <Container
          direction="column"
          className="column"
          alignment="start"
          css={SPanel}>
          <h3 css={SSectionTitles}>{strings.titles.diagram_section}</h3>
          <Paragraph>
            {translateAllEnLiterals(
              strings?.descriptions.diagram_section ?? '',
              {
                participant_count:
                  reportResults?.details.org_participants ?? '',
                organization_name: reportResults?.details.org_name ?? '',
              },
            )}
          </Paragraph>

          <hr css={SSeparator} />

          <Container
            direction="column"
            justify="center"
            alignment="center"
            css={SSectionWrap}>
            <LeadrResultChart
              strings={strings}
              results={
                reportResults?.by_component ?? DEFAULT_ASSESSMENT_LEADR_RESULTS
              }
              showSliceTooltipOnHover
            />
          </Container>
        </Container>

        <Container
          direction="column"
          className="column"
          alignment="start"
          css={SPanel}>
          <h3 css={SSectionTitles}>{strings.titles.profiles_section}</h3>
          <Paragraph>{strings.descriptions.profiles_section}</Paragraph>

          <hr css={SSeparator} />

          <Container
            direction="column"
            alignment="center"
            justify="center"
            css={SSectionWrap}>
            <div css={SColumnsSmallGap}>
              {archetypes?.map((archetype: IArchetype): JSX.Element => {
                return (
                  <Container
                    key={`archetype-${archetype.leadr}`}
                    css={SArchetypeTrigger}
                    onClick={() => {
                      setClickedArchetype(archetype)
                    }}>
                    <LeadrArchetype archetype={archetype}></LeadrArchetype>
                    <Paragraph>
                      <strong>
                        {archetype.name} (
                        {reportResults?.by_archetype[archetype.leadr]})
                      </strong>
                    </Paragraph>
                  </Container>
                )
              })}
            </div>
          </Container>
        </Container>
      </div>

      <LeadrArchetypeModal
        strings={strings}
        archetype={clickedArchetype}
        size={Size.large}
        isOpen={!!clickedArchetype}
        onSave={() => {}}
        onRequestClose={() => {
          setClickedArchetype(undefined)
        }}
      />
    </div>
  )
}
