import _ from 'lodash'

import Input from 'components/Input'
import { useCallback, useEffect, useState } from 'react'
import { IAllFilterOptions, defaultAllFilterOptions } from './types'
import { locationToString } from './helper'

interface IFilterItemInputProps {
  name: string
  type?: string
  filterInputs?: any[]
  filtersUrl?: string
  onFilterItemChange?: (filterInputs: any) => void
  className?: string
  allOptions?: IAllFilterOptions
}

export default function FilterItemInput({
  name,
  type,
  filterInputs,
  onFilterItemChange,
  allOptions = defaultAllFilterOptions,
  ...props
}: IFilterItemInputProps): JSX.Element {
  const [options, setOptions] = useState([])

  const removePreviouslySelected = useCallback(
    (items) => {
      const stringtifiedFilterInputs = filterInputs.map((input) =>
        JSON.stringify(input),
      )
      return items.filter(
        (optionValue) =>
          !stringtifiedFilterInputs.includes(JSON.stringify(optionValue)),
      )
    },
    [filterInputs],
  )

  const filterFilters = useCallback(
    (data) => {
      switch (type) {
        case 'locations':
          return removePreviouslySelected(
            data[type]?.map((l) => ({
              label: locationToString(l),
              value: l,
            })) || [],
          )
        case 'titles':
        case 'departments':
          return removePreviouslySelected(
            data[type]?.map((option) => ({
              label: option,
              value: option,
            })) || [],
          )
        default:
          return []
      }
    },
    [type, removePreviouslySelected, filterInputs],
  )

  const performSearch = useCallback(
    (search, items) => {
      const terms: RegExp[] = (_.isString(search) ? search : '')
        .split(/\s+/)
        .map((term) => new RegExp(term.toLowerCase()))

      if (type === 'locations') {
        return _.filter(
          items,
          (item: { label: string; value: { city: string; state: string } }) => {
            for (const term of terms) {
              if (
                term.test(item?.label?.toLowerCase()) ||
                term.test(item?.value?.city?.toLowerCase()) ||
                term.test(item?.value?.state?.toLowerCase())
              ) {
                return true
              }
            }
          },
        )
      } else {
        return _.filter(items, (item: { label: string; value: string }) => {
          for (const term of terms) {
            if (
              term.test(item.label.toLowerCase()) ||
              term.test(item.value.toLowerCase())
            ) {
              return true
            }
          }
        })
      }
    },
    [type],
  )

  const searchOptions = useCallback(
    (search, callback) => {
      callback && callback(performSearch(search, options))
    },
    [type, allOptions, filterInputs],
  )

  useEffect(() => {
    setOptions(filterFilters(allOptions))
  }, [type, allOptions, filterInputs])

  return (
    <Input
      readOnly={!type}
      css={{
        width: 537,
      }}
      className={props.className}
      name={name}
      type="async_select"
      isMulti
      selectValue={filterInputs}
      getOptionLabel={(option) => (option ? option.label : '')}
      getOptionValue={(option) => (option ? option.value : '')}
      getOptionKey={(o) => (o ? o.value : '')}
      loadOptions={searchOptions}
      defaultOptions={options}
      onChange={(inputData) => {
        onFilterItemChange(inputData[name])
      }}
      isClearable={false}
      {...props}
    />
  )
}
