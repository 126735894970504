import { css } from '@emotion/react'
import DefaultAvatar from './DefaultAvatar'
import { useMemo } from 'react'

interface AvatarProps {
  profilePath?: string
  userInitial?: string
  avatarUrl?: string
  size?: number
}

const avatarStyle = css({
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  objectFit: 'cover',
})

export default function Avatar({
  profilePath = null,
  userInitial = null,
  avatarUrl = null,
  size = 30,
}: AvatarProps): JSX.Element {
  const avatar = useMemo(() => {
    if (!avatarUrl) {
      return null
    } else if (avatarUrl.startsWith('http')) {
      return avatarUrl
    } else if (avatarUrl.startsWith('/')) {
      return avatarUrl
    } else {
      return `/${avatarUrl}`
    }
  }, [avatarUrl])
  return (
    <a
      aria-label="avatar"
      href={profilePath}
      css={{
        textDecoration: 'none',
      }}>
      {!!avatarUrl ? (
        <img
          aria-label="user avatar image"
          css={[
            avatarStyle,
            css({
              height: size,
              width: size,
            }),
          ]}
          src={avatar}></img>
      ) : (
        <DefaultAvatar userInitial={userInitial} size={size} />
      )}
    </a>
  )
}
