import { css } from '@emotion/react'
import Card from 'components/Card'
import Table, { ITableProps } from 'components/Table'
import { colors, sizes } from 'stylesheets/theme'

const logCardStyle = css({
  '.loading-indicator': {
    padding: sizes.logModalPadding,
  },
  '.GojiCustomTable-wrapper': {
    boxShadow: 'none',
    '.GojiCustomTable tr': {
      th: {
        border: 'none',
        padding: 10,
      },
      td: {
        backgroundColor: colors.backgrounds.page,
        border: 'none',
        padding: 10,
      },
      'td:first-of-type .GojiCustomTable__cell-value:first-of-type': {
        paddingLeft: 10,
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '15px',
        lineHeight: '25px',
        display: 'flex',
        alignItems: 'center',
        color: colors.text.text_5,
      },

      'td:last-child .GojiCustomTable__cell-value:last-child': {
        paddingRight: 10,
      },
    },
  },
})

export default function CommunicationLog({
  tableData,
  tableMeta,
  ...props
}: ITableProps): JSX.Element {
  return (
    <Card
      title="Communication Log"
      subtitle="After initiating invitations, please refresh the page in a few minutes, as it may take 1-5 minutes to process invitations.">
      <div css={logCardStyle}>
        <Table tableData={tableData} tableMeta={tableMeta} {...props} />
      </div>
    </Card>
  )
}
