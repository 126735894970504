import React from 'react'

import Pill, { PillColor } from 'components/Pill'

import type { TStatus } from 'types'

interface IStatusPill {
  type: TStatus
  children: React.ReactNode
}

const StatusPill: React.FC<IStatusPill> = ({ type, children }) => {
  switch (type) {
    case 'deadline':
      return (
        <Pill size="small" color={PillColor.BLUE}>
          {children}
        </Pill>
      )
    case 'spots':
      return (
        <Pill size="small" color={PillColor.GREEN}>
          {children}
        </Pill>
      )
    default:
      return <Pill size="small">{children}</Pill>
  }
}

export default StatusPill
