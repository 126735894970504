import React from 'react'

import { FormFieldContainer } from 'components/Forms'
import Switch from 'components/Switch'

import { TEventCallType } from 'types'

interface AlliesProps {
  alliesParticipate: boolean
  object: TEventCallType
  label: string
}

/**
 * Control to set allies participation for the event/CTA
 * Are allies encouraged to participate?
 */
export default function Allies({
  alliesParticipate,
  object,
  label,
}: AlliesProps): JSX.Element {
  const controlName = `${object}[allies_welcome]`
  const controlId = `${object}-allies-welcome`

  return (
    <FormFieldContainer fieldId={controlId} label={label}>
      <Switch
        id={controlId}
        controlName={controlName}
        defaultChecked={!!alliesParticipate}
      />
    </FormFieldContainer>
  )
}
