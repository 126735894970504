import { css } from '@emotion/react'

import { colors, fonts } from 'stylesheets/theme'

export const defaultLinkStyle = css({
  fontFamily: fonts.lato,
  textDecoration: 'none',
  color: colors.links.blue,
  fontWeight: 'bold',
  fontSize: '15px',
  lineHeight: '22px',
  cursor: 'pointer',
  border: 'none',
  padding: 0,
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  '&[disabled]': {
    opacity: 0.6,
    cursor: 'not-allowed',
  },
  '&:hover': {
    color: colors.links.hover.blue,
  },
})

const Link = (
  props: React.AnchorHTMLAttributes<HTMLAnchorElement>,
): React.ReactElement => {
  return <a css={defaultLinkStyle} {...props}></a>
}

export default Link
