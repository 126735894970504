import { css } from '@emotion/react'
import StarIcon from 'images/icons/star.svg'
import { colors } from 'stylesheets/theme'
import Button, { ButtonComponentType, Variant } from 'components/Button/Button'
import Container from 'components/Container/Container'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'

interface PrioritizationCellProps {
  data: any
}

const unprioritizedStyle = css({
  path: {
    fill: 'none',
  },
  ':hover': {
    path: {
      fill: colors.text.text_5,
    },
  },
})

const prioritizedStyle = css({
  path: {
    fill: colors.text.text_5,
  },
  ':hover': {
    path: {
      fill: 'none',
    },
  },
})

const baseStyle = css({
  width: 20,
  height: 20,
})

export default function PrioritizationCell({
  data,
}: PrioritizationCellProps): JSX.Element {
  const {
    setPrioritizeModalObject,
    setPrioritizeNoteModalObject,
    setDeprioritizeModalObject,
  } = useMentorshipExchangeDetailsContext()
  const { prioritization } = data
  const style = Boolean(prioritization)
    ? [baseStyle, prioritizedStyle]
    : [baseStyle, unprioritizedStyle]
  return (
    <Container>
      <StarIcon
        css={style}
        onClick={
          Boolean(prioritization)
            ? () => {
                setDeprioritizeModalObject(data)
              }
            : () => {
                setPrioritizeModalObject(data)
              }
        }
      />
      {Boolean(prioritization) && (
        <Button
          as={ButtonComponentType.LINK}
          variant={Variant.LINK}
          className="link ignore-goji"
          onClick={() => {
            setPrioritizeNoteModalObject(data)
          }}>
          Notes
        </Button>
      )}
    </Container>
  )
}
