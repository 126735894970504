import moment from 'moment-timezone'
import DotMenu from 'components/DotMenu'
import type {
  IMentorshipExchangePackage,
  IUser,
  MentorshipExchangeDotMenuTypes,
} from 'types'
import { useMentorshipExchangeDetailsContext } from './MentorshipExchangeDetailsContext'
import ExtendDeadlineModal from './Modals/ExtendDeadlineModal'
import ActivityLogModal from './Modals/ActivityLogModal'
import { useCallback, useMemo, useState } from 'react'
import axios, { AxiosResponse } from 'axios'
import InviteUsersModal from './Modals/InviteUsersModal'
import MentorshipExchangeHeader from './MentorshipExchangeHeader'
import { css } from '@emotion/react'
import { colors, spacings } from 'stylesheets/theme'
import SendRemindersModal from './Modals/SendRemindersModal'
import Button, { ButtonComponentType, Variant } from 'components/Button'
import Modal, { Size } from 'components/Modal'
import RewordMatchReasoningModal from './Modals/MatchResultPageModals/RewordMatchReasoningModal'
import { Paragraph } from 'components/Typography'

import DownloadIcon from '../../images/icons/download.svg'

const modal696Css = css({
  '&.small': {
    width: 696,
  },
})
const buttonIconCss = css({
  marginRight: spacings.grid_gap_basis_num / 2,
})
const inviteModalPTagCss = css({
  marginBottom: spacings.grid_gap_basis_num * 2,
})
const inviteUsersHrCss = css({
  marginTop: spacings.grid_gap_basis_num * 2,
  marginBottom: spacings.grid_gap_basis_num * 2,
  border: `1px solid ${colors.borders.gray}`,
})
const sendRemindersCss = css({
  marginTop: spacings.grid_gap_basis_num + 2,
  h3: {
    fontSize: spacings.grid_gap_basis_num * 1.5,
  },
})
const downloadRowCss = css({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  justifyContent: 'start',
  alignItems: 'center',
  marginTop: spacings.grid_gap_basis_num + 2,
})
const reminderModalContentCss = css({
  marginTop: spacings.grid_gap_basis_num * 2,
  marginBottom: spacings.grid_gap_basis_num * 2,
})

const hrCss = css({
  borderTop: '1px solid #e7e7e7',
  marginTop: spacings.grid_gap_basis_num * 2,
  marginBottom: spacings.grid_gap_basis_num * 2,
})

export interface IMentorshipExchangeDotMenuProps {
  type: MentorshipExchangeDotMenuTypes
}

export interface IMentorshipExtendDeadlineResponse {
  errors: {
    deadline_date: string[]
    deadline_time: string[]
  }
}

interface IAjaxResponse {
  success: boolean
  errors?: string[]
  msgs?: string[]
}

function canRemind(exchange: IMentorshipExchangePackage) {
  return (
    exchange.data.status === 'application_open' ||
    exchange.data.status === 'application_extended'
  )
}

function canExtendDeadline(exchange: IMentorshipExchangePackage) {
  return canRemind(exchange) || exchange.data.status === 'application_closed'
}

export default function MentorshipExchangeDotMenu({
  type,
}: IMentorshipExchangeDotMenuProps): JSX.Element {
  const { exchange, tables, counts, urls, ajaxWithUpdates } =
    useMentorshipExchangeDetailsContext()

  const [openExtendDeadlineModal, setOpenExtendDeadlineModal] = useState(false)
  const [openActivityLogModal, setOpenActivityLogModal] = useState(false)
  const [openInviteUsersModal, setOpenInviteUsersModal] = useState(false)
  const [openSendRemindersModal, setOpenSendRemindersModal] = useState(false)
  const [openStartFreshModal, setOpenStartFreshModal] = useState(false)
  const [openRemoveBoostsModal, setOpenRemoveBoostsModal] = useState(false)
  const [openRewordModal, setOpenRewordModal] = useState(false)
  const [extendDeadlineErrors, setExtendDeadlineErrors] = useState([])
  const [isRewording, setIsRewording] = useState(false)

  const formatted_deadline_datetime = useMemo(() => {
    return `${moment(exchange.data.deadline_date).format(
      'ddd, MMM Do, YYYY',
    )} at ${moment.utc(exchange.data.deadline_time).format('h:mma')} ${
      exchange.data.deadline_timezone_abbr
    }`
  }, [])

  const extendDeadline = useCallback(
    (deadlineDate, deadlineTime) => {
      const url = urls.extend_deadline
      const params = {
        deadline_date: deadlineDate,
        deadline_time: deadlineTime,
      }
      axios
        .post(url, {
          ...params,
          authenticity_token: window.authenticity_token,
        })
        .catch(() => {
          window.flash('Unable to extend deadline.', 'error')
        })
        .then(
          ({
            data: { errors },
          }: AxiosResponse<IMentorshipExtendDeadlineResponse>) => {
            if (errors?.deadline_date?.length) {
              setExtendDeadlineErrors(errors.deadline_date)
            } else {
              window.location.reload()
            }
          },
        )
    },
    [urls],
  )

  const menuItems = []

  menuItems.push({
    label: 'Export all applications',
    url: `/dashboard/mentorship_exchanges/${exchange.data.id}/mentorship_program_application.csv`,
  })

  if (canRemind(exchange)) {
    menuItems.push({
      label: 'Send reminders',
      onClick: () => setOpenSendRemindersModal(true),
    })
  }
  if (canExtendDeadline(exchange)) {
    menuItems.push({
      label: 'Extend deadline',
      disableCloseMenuOnClick: true,
      onClick: () => setOpenExtendDeadlineModal(true),
    })
  }

  if (type == 'pre-matching') {
    menuItems.push({
      label: 'View activity log',
      onClick: () => setOpenActivityLogModal(true),
    })
  }

  if (
    exchange.data.status === 'application_open' ||
    exchange.data.status === 'application_extended'
  ) {
    menuItems.push({
      label: 'Invite users',
      onClick: () => setOpenInviteUsersModal(true),
    })
  }

  if (type != 'pre-matching') {
    menuItems.push({
      label: 'View activity log',
      onClick: () => setOpenActivityLogModal(true),
    })
    menuItems.push({
      label: 'Start fresh',
      onClick: () => setOpenStartFreshModal(true),
    })
    menuItems.push({
      label: 'Remove all boosts',
      onClick: () => setOpenRemoveBoostsModal(true),
    })
    // NOTE: intentionally leaving this commented out for now. it will be uncommented in a future iteration
    // menuItems.push({
    //   label: 'Reword reasons',
    //   onClick: () => setOpenRewordModal(true),
    // })
  }

  const onSaveStartRewording = () => {
    const params = {
      authenticity_token: window.authenticity_token,
    }
    setIsRewording(true)
    axios
      .post(urls.start_rewording, { ...params })
      .then((value: AxiosResponse<IAjaxResponse>) => {
        const { data } = value
        if (data.success) {
          const msg =
            data.msgs && Array.isArray(data.msgs)
              ? data.msgs.join(' ')
              : 'Rewording has been started and will complete in a couple of minutes.'
          window.flash(msg, 'success')
        } else {
          throw new Error(data.errors?.join(' ') ?? '')
        }
        window.location.reload()
      })
      .catch((reason: any) => {
        let msg = 'We could not start the rewording process. Please try again.'
        if (reason instanceof Error) {
          const error = reason as Error
          msg = error.message ? error.message : msg
        }
        setIsRewording(false)
        window.flash(msg, 'alert')
      })
  }

  const onSaveInvitedUsers = (users: IUser[]) => {
    const params = {
      user_ids: users.map((user: IUser) => user.id),
      authenticity_token: window.authenticity_token,
    }
    axios
      .post(urls.invite_additional_users, { ...params })
      .then((value: AxiosResponse<IAjaxResponse>) => {
        const { data } = value
        if (data.success) {
          const msg =
            data.msgs && Array.isArray(data.msgs)
              ? data.msgs.join(' ')
              : 'Invitations have been sent to all selected users.'
          window.flash(msg, 'success')
        } else {
          throw new Error(data.errors?.join(' ') ?? '')
        }
        setOpenInviteUsersModal(false)
      })
      .catch((reason: any) => {
        let msg = 'The selected users were NOT invited. Please try again.'
        if (reason instanceof Error) {
          const error = reason as Error
          msg = error.message ? error.message : msg
        }
        window.flash(msg, 'alert')
      })
  }

  const onSaveSendReminders = () => {
    const params = {
      authenticity_token: window.authenticity_token,
    }
    axios
      .post(urls.send_reminders, { ...params })
      .then((value: AxiosResponse<IAjaxResponse>) => {
        const { data } = value
        if (data.success) {
          const msg =
            data.msgs && Array.isArray(data.msgs)
              ? data.msgs.join(' ')
              : `Reminders have been sent to all ${
                  counts.need_reminders ?? 0
                } users.`
          window.flash(msg, 'success')
        } else {
          throw new Error(data.errors?.join(' ') ?? '')
        }
      })
      .catch((reason: any) => {
        let msg = `All ${
          counts.need_reminders ?? 0
        } users were NOT reminded. Please try again.`
        if (reason instanceof Error) {
          const error = reason as Error
          msg = error.message ? error.message : msg
        }
        window.flash(msg, 'alert')
      })
    setOpenSendRemindersModal(false)
  }

  return (
    <>
      <DotMenu menuItems={menuItems} />
      <ExtendDeadlineModal
        originalDeadline={formatted_deadline_datetime}
        isOpen={openExtendDeadlineModal}
        onRequestClose={() => setOpenExtendDeadlineModal(false)}
        onExtendDeadline={extendDeadline}
        timezone={exchange.data.deadline_timezone}
        errors={extendDeadlineErrors}
      />
      <ActivityLogModal
        isOpen={openActivityLogModal}
        onRequestClose={() => setOpenActivityLogModal(false)}
        onSave={() => setOpenActivityLogModal(false)}
        tableComponentProps={{
          ...tables.activity_logs,
        }}
      />
      <InviteUsersModal
        title="Invite potential applicants"
        isOpen={openInviteUsersModal}
        onRequestClose={() => setOpenInviteUsersModal(false)}
        onSave={onSaveInvitedUsers}>
        <p css={inviteModalPTagCss}>Here are the details for this program:</p>
        <MentorshipExchangeHeader
          exchange={exchange}
          headerType="h2"
          headerVariant="modal"
          disableStatusPill={true}
        />
        <hr css={inviteUsersHrCss} />
      </InviteUsersModal>
      <SendRemindersModal
        largeTitle
        size={Size.small}
        css={modal696Css}
        title="Remind potential applicants"
        isOpen={openSendRemindersModal}
        onRequestClose={() => setOpenSendRemindersModal(false)}
        onSave={onSaveSendReminders}>
        <Paragraph css={reminderModalContentCss}>
          We currently have <b>{counts.need_reminders}</b> users who have yet to
          apply for the program. Would you like to send reminders to these
          users?
        </Paragraph>
        <div css={downloadRowCss}>
          <Button
            as={ButtonComponentType.LINK}
            variant={Variant.LINK}
            href={urls.need_reminding}
            title="Download CSV of email addresses">
            <DownloadIcon css={buttonIconCss} /> Download users.csv
          </Button>
        </div>
        <hr css={hrCss} />
        <Paragraph css={reminderModalContentCss}>
          Here are the details for this program:
        </Paragraph>
        <MentorshipExchangeHeader
          css={sendRemindersCss}
          exchange={exchange}
          headerType="h3"
          headerVariant="modal"
          disableStatusPill={true}
        />
      </SendRemindersModal>
      <Modal
        largeTitle
        isOpen={openRemoveBoostsModal}
        size={Size.small}
        title="Remove boosts from all users"
        onRequestClose={() => setOpenRemoveBoostsModal(false)}
        onSave={() => {
          window.location.href = urls.remove_all_boosts
          // modal closes already, because the page refreshes
        }}
        submitButton="Yes. Remove boosts"
        cancelButton="No. Leave the boosts">
        <p>
          Are you sure you want to remove all boosts from all users? This action
          is irreversible.
        </p>
      </Modal>
      <Modal
        largeTitle
        isOpen={openStartFreshModal}
        size={Size.small}
        title="Start fresh with matching"
        onRequestClose={() => setOpenStartFreshModal(false)}
        onSave={() => {
          ajaxWithUpdates(
            // ajax url
            urls.reset_previously_matched_for_all,
            // params
            {},
            // method
            'get',
            // onUpdate
            () => {
              setOpenStartFreshModal(false)
            },
            // onError
            () => {
              setOpenStartFreshModal(false)
            },
          )
        }}
        submitButton="Yes. Reset it"
        cancelButton="No. Leave it alone">
        <p>
          Are you sure you want to start fresh with matching? This action is
          irreversible. This will mean previously rejected pairings can now be
          paired up again. You will not lose any assigned boosts.
        </p>
      </Modal>
      <RewordMatchReasoningModal
        isOpen={openRewordModal}
        onRequestClose={() => setOpenRewordModal(false)}
        onSave={onSaveStartRewording}
        isRewording={isRewording}></RewordMatchReasoningModal>
    </>
  )
}
